import { Autocomplete, InputLabel, TextField } from '@mui/material'
import { Fragment, useContext } from 'react'
import { InputForm, SelectForm, InputFile, InputAutoComplete } from '..'
import { AuthContext } from '../../context/auth'
import SimpleError from '../errors/simple'
import useStyles from './style'
import { listCountries, transfromLocalePhone } from '../../utils/locale'

/* need for works
  name
  values
  type
  label
  *if is select or multiple
  options
  *if is autocomplete 
  options
  valuefield
*/
const DinamicForm = ({
  styles = null,
  errorDate = null,
  inputs,
  accept = '',
  handleChange,
  values = {},
  errors = {},
  onAccept = null,
  objectValueLocation = null,
  containerStyle = null,
  disabledErrorInput
}) => {
  const { state } = useContext(AuthContext)
  const classes = useStyles()
  return (
    <Fragment>
      {inputs.map((input, index) => {
        if (input.type == 'number') {
          return (
            <InputForm
              variant="outlined"
              // size="small"
              color="secondary"
              onBlur={input.onBlur}
              name={input.name}
              label={input.label}
              onChange={handleChange}
              className={input.style}
              disabled={input.disabled == 'true' ? true : false}
              value={values[input.name] ? values[input.name] : ''}
              error={errors[input.name] ? true : false}
              helperText={errors[input.name] || ''}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/, '')
                  .replace(/(\..*)\./g, '$1')
              }}
              key={index}
            />
          )
        } else if (input.type == 'phone') {
          return (
            <InputForm
              variant="outlined"
              // size="small"
              color="secondary"
              onBlur={input.onBlur}
              name={input.name}
              label={input.label}
              onChange={(e) => {
                handleChange({ target: { name: input.name, value: e } })
              }}
              fullWidth={input.fullWidth}
              disabled={input.disabled == 'true' ? true : false}
              className={input.style}
              value={values[input.name] ? values[input.name] : null}
              error={errors[input.name] ? true : false}
              helperText={errors[input.name] || ''}
              defaultCountry={transfromLocalePhone[state.locale] || listCountries.fr}
              phone
              autoFormat={false}
              key={index}
            />
          )
        } else if (input.type == 'simpleAutocomplete') {
          return (
            <Autocomplete
              key={index}
              value={values[input.name]
                ? input?.options?.find((aux) => values[input.name] === aux?.id)
                : null}
              className={input.classAutoComplete ?? ''}
              options={input.options || []}
              onChange={(e, value) => {
                handleChange({
                  target: {
                    name: input.name,
                    value: value?.id,
                  },
                })
              }}
              getOptionLabel={(val) => {
                return val?.name ?? ''
              }}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    fullWidth={input.fullWidth}
                    label={input.label}
                    variant="outlined"
                    // // size="small"
                    className={classes.multiple + ' ' + input.className}
                    color="secondary"
                    error={errors[input.name] ? true : false}
                  />
                  <SimpleError message={errors[input.name]} error={errors[input.name] ? true : false}/>
                </>
              )}
            />
          )
        } else if (input.type == 'select') {
          return (
            <SelectForm
              variant="outlined"
              // size="small"
              color="secondary"
              name={input.name}
              label={input.label}
              onChange={handleChange}
              options={input.options}
              onBlur={input.onBlur}
              fullWidth={input.fullWidth}
              className={input.style}
              isLocalData={input.isLocalData}
              disabled={input.disabled == 'true' ? true : false}
              value={
                values[input.name] < 0 || values[input.name] == undefined
                  ? ''
                  : values[input.name]
              }
              key={index}
            />
          )
        } else if (input.type == 'multiple') {
          return (
            <SelectForm
              disableCloseOnSelect
              limitTags={5}
              multiple
              value={
                values[input.name]
                  ? input.options.filter((aux) =>
                      Object.values(values[input.name]).includes(aux.id)
                    )
                  : []
              }
              // size="small"
              label={input.label}
              onChange={(e, value) => {
                handleChange({
                  target: {
                    name: input.name,
                    value: value.map((item) => item?.id),
                  },
                })
              }}
              options={input.options || []}
              onBlur={input.onBlur}
              fullWidth={input.fullWidth}
              className={input.style}
              disabled={input.disabled == 'true' ? true : false}
              getOptionLabel={(val) => {
                return val?.name
              }}
              key={index}
            />
          )
        } else if (input.type === 'autocomplete') {
          return (
            <InputAutoComplete
              value={
                values[input.name]
                  ? input.options.find((aux) => values[input.name] === aux.id)
                  : null
              }
              // size="small"
              label={input.label}
              onChange={(e, value) => {
                handleChange({
                  target: {
                    name: input.name,
                    value: value ? value[input.valueField] : '',
                  },
                })
              }}
              options={input.options}
              onBlur={input.onBlur}
              fullWidth={input.fullWidth}
              className={input.style}
              renderOption={(props, option, index) => {
                const key = `listItem-${index}-${option.id}`

                return option.name ? (
                  <li {...props} key={key}>
                    {option.name}
                  </li>
                ) : (
                  ''
                )
              }}
              disabled={input.disabled == 'true' ? true : false}
              getOptionLabel={(val) => val.name ?? ''}
              key={index}
            />
          )
        } else if (input.type == 'date') {
          const change =
            onAccept !== null
              ? {
                  onAccept: onAccept,
                  onChange: (e) => {
                    handleChange({ target: { name: input.name, value: e } })
                  },
                }
              : {
                  onChange: (e) => {
                    handleChange({ target: { name: input.name, value: e } })
                  },
                }

          return (
            <InputForm
              // size="small"
              // sizeDate={sizeDate}
              errorDate={errorDate}
              color="secondary"
              onBlur={input.onBlur}
              name={input.name}
              label={input.label}
              {...change}
              className={input.style}
              fullWidth={input.fullWidth}
              disabled={input.disabled == 'true' ? true : false}
              value={values[input.name] || null}
              error={errors[input.name] ? true : false}
              date
              key={index}
              {...(input?.allDate !== undefined && { allDate: true })}
            />
          )
        } else if (input.type == 'file') {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '8px',
              }}
              className={containerStyle ?? ''}
            >
              <InputLabel htmlFor={input.name} className={input.styleInput}>
                {input.label}
              </InputLabel>
              <InputFile
                accept={accept}
                name={input.name}
                change={handleChange}
                className={input.style}
                disabled={input.disabled == 'true' ? true : false}
                value={values[input.name] || null}
                format={input.format}
                error={errors}
              />
            </div>
          )
        } else {
          return (
            <InputForm
              variant="outlined"
              size={'medium'}
              color="secondary"
              name={input.name}
              onChange={handleChange}
              multiline={input.type === 'textArea' ? true : false}
              withMaxLength={input.type === 'textArea' ? false : true}
              textarea={input.type === 'textArea' ? 'true' : 'false'}
              rows={input.rows || 5}
              label={input.label}
              onBlur={input.onBlur}
              className={styles === null ? input.style : styles}
              fullWidth={input.fullWidth}
              disabled={input.disabled == 'true' ? true : false}
              value={
                objectValueLocation
                  ? values[input.name]?.[objectValueLocation]
                    ? values[input.name]?.[objectValueLocation]
                    : ''
                  : values[input.name]
                  ? values[input.name]
                  : ''
              }
              // style={
              //   styles === null
              //     ? input.type === 'textArea'
              //       ? { }
              //       : { marginBottom: !input.rB && '16px' }
              //     : {}
              // }
              style={{marginBottom: errors[input.name] && !disabledErrorInput ? '-16px' : ''}}
              error={errors[input.name] && !disabledErrorInput ? true : false}
              helperText={errors[input.name] || ''}
              key={index}
              inputProps={input?.inputProps}
              InputProps={input?.InputProps ? input?.InputProps : {}}
            />
          )
        }
      })}
    </Fragment>
  )
}

export default DinamicForm
