import I18n from 'i18n-js'
import { images } from '../../../../utils'
import constants from '../cardTab/enum'
import { dataCard } from './lists'

const handleNA = (value) => (value ? value : 'NA')
const handleHasValue = (value) => value ? 'img' : 'negativeImg'
export const physiologiqueItem = (item) => {
  let cards = []
  const psysiological = [...(item.physiological ?? [])]
  const cardsPA = psysiological?.slice(1, 3)

  psysiological?.slice(0,11)?.forEach((it, index) => {
    if ([365, 366].includes(it.id)) {
      const PAHasValue = cardsPA?.[0]?.value || cardsPA?.[1]?.value
      const hasValue = handleHasValue(PAHasValue)

      cards[1] = {
        id: 1,
        unit: cardsPA?.[0]?.unit,
        name: dataCard?.[1]?.name,
        value:
          PAHasValue &&
          `${handleNA(cardsPA?.[0]?.value)} / ${handleNA(cardsPA?.[1]?.value)}`,
        description: it?.description,
        images: dataCard?.[1]?.[hasValue],
      }
    } else {
      const hasValue = handleHasValue(Array.isArray(it.value) ? it.value?.length > 0: it.value)
      const lastValues = index > 5
      cards = [
        ...cards,
        {
          id: index,
          name: lastValues
            ? I18n.t(`patient.form.${it?.name}`)
            : dataCard?.[index]?.name,
          value: it?.value,
          unit: it?.unit || dataCard?.[index]?.unit,
          images: dataCard?.[index]?.[hasValue],
          description: it?.description,
          arrowHide: lastValues || index === 0,
          type: index < 9 ? lastValues ? constants?.audio : null : constants.images,
        },
      ]
    }
  })

  return cards
}

export const handleFragilite = (item) => {
  const newItem = []

  item[1].forEach((item) => {
    const { id } = item
    switch (id) {
      case 372:
        newItem[0] = {
          ...item,
          icon: images.punch,
        }
        break
      case 324:
        newItem[1] = {
          ...item,
          icon: images.Ind3,
        }
        break
      case 405:
        newItem[2] = {
          ...item,
          icon: images.levelPatient,
        }
        break
      case 403:
        newItem[3] = {
          ...item,
          icon: images.balance,
          vc: true,
        }
        break
      case 380:
        newItem[4] = {
          ...item,
          icon: images.tired,
          vc: true,
        }
        break
      default:
        break
    }
  })

  return {
    [item[0]]: newItem.filter((item) => item && item !== null),
  }
}
