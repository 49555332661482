import { colors } from "../../../../../../utils";

const useDetailCardStyles = () => ({
    title: {
        fontWeight: 500,
        margin: '10px 0',
        color: colors.disabled,
        borderBottom: `1px solid ${colors.greyDarken}`,
    },
    name: {
        color: colors.disabled,
        display: 'block',
        width: '100%',
        wordBreak: 'break-word',
    },
    chartText: {
        fontWeight: '500',
        marginTop: '20px',
        color: colors.disabled,
        borderBottom: `1px solid ${colors.greyDarken}`,
    },
    graphic: {
      width: '100%',
      marginTop: '10px',
    },
})

export { useDetailCardStyles }