import {
  Alert,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import i18n from 'i18n-js'
import React from 'react'
import DinamicForm from '../dinamicForm'
import useStyles from './styles'

const HtmlFile = ({ onChange, value, structure, listOfInformation, name }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <Box classes={classes.notification}>
          <Box>
            <Stack>
              <List>
                <Alert
                  variant="outlined"
                  severity="info"
                  className={classes.alertIcon}
                >
                  {listOfInformation?.map((item, index) => (
                    <ListItem className={classes.listItem} key={index}>
                      <ListItemText
                        variant="caption"
                        className={classes.infoTitle}
                      >
                        * {i18n.t(item.text)}
                      </ListItemText>
                      {item.child && (
                        <List>
                          <Grid container>
                            {item?.child?.map((child, childId) => (
                              <Grid item lg={4} key={`${child}${childId}`}>
                                <ListItem className={classes.listItemInfoText}>
                                  <ListItemText
                                    variant="caption"
                                    className={classes.infoText}
                                  >
                                    <span className={classes.labels}>
                                      {child.beforeText ?? ''}
                                    </span>
                                    {i18n.t(child.text)}
                                  </ListItemText>
                                </ListItem>
                              </Grid>
                            ))}
                          </Grid>
                        </List>
                      )}
                    </ListItem>
                  ))}
                </Alert>
              </List>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item lg={6}>
        <DinamicForm inputs={name.slice(0,1)} handleChange={onChange} values={value} />
      </Grid>
      <Grid item lg={6} >
        <DinamicForm inputs={name.slice(1,2)} handleChange={onChange} values={value} />
      </Grid>
      <Grid item lg={6}>
        <Typography variant="body1" color="primary">
          HTML
        </Typography>
        <TextField
          name="html"
          value={value.html ?? ''}
          className={classes.htmlEditor}
          multiline
          rows={8}
          onChange={(e) => onChange(e)}
        />
      </Grid>

      <Grid item lg={6} sx={{ overflow: 'hidden' }}>
        <Typography variant="body1" color="primary">
          {i18n.t('general.preview')}
        </Typography>
        <iframe className={classes.iframe} srcDoc={structure} />
      </Grid>
    </Grid>
  )
}

export default HtmlFile
