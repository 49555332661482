import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '60px',
  },
  textAreaContainer: {
    width: '100%',
    marginTop: '16px',
    minHeight: '169px',
  },
  form: {
    background: colors.white,

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  inputMedium: { width: 'calc(50% - 8px)' },
  inputLarge: { width: '100%' },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
  },
  selectInput: {
    '&.MuiFormControl-root': {
      maxHeight: '40px',
    },
  },
})
export default useStyles
