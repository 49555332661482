import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
  },
  background: {
    background: colors.greyLighten,
    position: 'fixed',
    width: '100%',
    minHeight: '100%',
  },
  loadingBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    zIndex: 100,
    minHeight: '100vh',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      top: '50vh',
      left: '50vw',
    },
  },
})

export default useStyles
