import { makeStyles } from '@mui/styles'
import colors from '../../../../utils/colors'
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoContainer: {
    margin: '24px 0',
    background: colors.greyLighten
  },
  info: {
    '& .MuiOutlinedInput-root': {
      color: colors.greyFull,
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '1%',
    },
  },
  input: {
    '&.MuiTypografy-root': {
      '-webkit-text-fill-color': colors.disabledDarken
    }
  },
  imageContainer: {
    border: `1px solid ${colors.greyFull}`,
    padding: '20px 15px 5px 15px',
    borderRadius: '5px',
  },
  inputsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: 30,
  },
  selectInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectInput: {
    display: 'flex',
    alignItems: 'center',
  },
  labelContainer: {
    marginRight: '24px',
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
  },
  alignStart: {
    alignItems: 'start',
  },
  label: { 
    '&.MuiTypography-root': {
      marginLeft: '16px' 
    }
  },
  inputContainer: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
  },
  containerInputImage: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  errorMessage: {
    position: 'absolute',
    bottom: -15
  },
  imageLocaleText: {
    '&.MuiTypography-root': {
      fontSize: ".8em",
      color: colors.greyFull,
      marginTop: '5px',
    }
  },
  form: {
    background: colors.white,
    width: 'calc(100% - 47px)',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  formLocal: {
    background: 'transparent',
    width: 190,
    '&.MuiButton-outlined': {
      background: 'transparent',
    }
  },
  marginTop: {
    '&.MuiButton-root': {
      marginTop: '20px',
    }
  },
  blockInstructions: {
    '&.MuiPaper-root': {
      color: colors.greyLighten
    }
  },
  buttonsUrl: {
    float: 'right',
    '&.MuiButton-root': {
      padding: 4,
    },
  },
  mt_8: {
    marginTop: -8
  }
}))

export default useStyles
