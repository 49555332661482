import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  submit: { color: colors.white },
  input: {
    // height: '40px',
    '&&::-ms-reveal': {
      display: 'none',
    },
  },
  errorContainer: {
    color: colors.danger,
  },
  slider: {
    '& span:nth-last-child(2)': {
      left: '92% !important',
    },
    '& .MuiSlider-markLabel': {
      transform: 'translate(0)',
    }
  }
})

export default useStyles
