import { colors } from '../../utils'

const styles = {
  bodyModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 19,
    p: 4,
  },
  bodyTitle: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  textTitle: {
    fontSize: '28px',
  },
  bodyButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '36px',
  },
  buttonOne: {
    marginRight: '12px',
    '&.MuiButton-root': {
      color: colors.disabled,
      border: colors.disabled + ' solid 1px',
      borderRadius: '2px',
      '&:hover': {
        color: colors.blueLighten,
      },
      transition: '.2s',
    },
  },
  buttonTwo: {
    '&.MuiButton-root': {
      borderRadius: '2px',
    },
  },
  alertStyles: {
    '& .alertError': {
      position: 'static',
      marginTop: '12px',
    },
  },
}

export default styles
