export const sxStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: 4,
}
