import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiInput-root:hover:not(.Mui-disabled):before': {
      borderBottom: `3px solid ${colors.lightPrimary}`,
    },
    '& .MuiInputBase-root': {
      background: colors.inputGray,
    },
    '& .MuiInput-input': {
      height: '40px',
      padding: '4px 35px 4px 10px',
      color: colors.blueInput,
    },
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      margin: '0px 20px',
    },
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  fullWidth: {
    width: '100%',
    height: '10px',
  },
  buttonContainer: {
    '&.MuiBox-root': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px'
    },
  },
  submit: {
    color: colors.white,
    // ...globalStyles.sendSubmit,
  },
  return: {
    color: colors.lightPrimary
  },
  link: { color: colors.lightPrimary },
}))

export default useStyles
