import { Box, Button, Checkbox, Icon, Typography } from '@mui/material'
import { Icons } from '../../../../../../utils'
import { useStyles } from '../styles'
import { useState, forwardRef } from 'react'
import I18n from 'i18n-js'
import { TeleExpertiseTypes } from './constants'
import { download } from '../../../../../../utils/tools'
import { CustomeRecord } from '../../../../../../components/customeRecord'

const Items = (props) => {
  const styles = useStyles()

  return (
    <Box sx={styles.items} key={props.index}>
      <Box
        sx={styles.body}
        onClick={(e) => {
          e.stopPropagation()
          props?.onChange({
            name: `${props?.keyItem}-${0}`,
            value: !props?.checkList?.[`${props?.keyItem}-${0}`],
          })
        }}
      >
        <Checkbox
          sx={[
            styles.check,
            !props?.checkList?.[`${props?.keyItem}-${0}`]
              ? styles.unCheckImg
              : {},
          ]}
          checked={!!props?.checkList?.[`${props?.keyItem}-${0}`]}
          onChange={(e, checked) => {
            e.stopPropagation()
            props?.onChange({
              name: `${props?.keyItem}-${0}`,
              value: checked,
            })
          }}
        />

        <Icon sx={styles.iconFolder} className="fileIcon">
          {Icons(props?.icon ?? 'file')}
        </Icon>

        <Typography sx={styles.description} className="descriptionItem">
          {I18n.t(`patient.teleExpertise.selects.${props?.keyItem}`)}
        </Typography>
      </Box>

      <Box sx={styles.footerButton}>
        {props?.type ? (
          <Box sx={styles.audioContainer}>
            <CustomeRecord audioUrl={props?.url ?? ''} />
            {/* <ReactAudioPlayer
              controlsList="nodownload noplaybackrate"
              className={classes.audioPlayer}
              src={props?.url ?? ''}
              controls
            /> */}

            {/* <IconButton
              onClick={() => {
                download(props?.url ?? '', I18n.t(`patient.teleExpertise.selects.${props?.keyItem}`), true)
              }}
              sx={styles.download}
            >
              {Icons('download')}
            </IconButton> */}
          </Box>
        ) : (
          <Button
            padding="4px 20px"
            onClick={(e) => {
              e.stopPropagation()
              download(
                props?.url ?? '',
                I18n.t(`patient.teleExpertise.selects.${props?.keyItem}`),
                true
              )
            }}
          >
            {I18n.t('general.download')}
          </Button>
        )}
      </Box>
    </Box>
  )
}
// eslint-disable-next-line react/display-name
const ListFile = forwardRef(
  ({ values, getInternalData, someThingIsSelected }, ref) => {
    const styles = useStyles()
    const [checkList, setCheckList] = useState({})
    // const [expand, setExpand] = useState({})

    const onChange = ({ name, value }) => {
      const copyChecks = structuredClone(checkList)
      const isSelected = Object.values({ ...copyChecks, [name]: value })?.some(
        (item) => item
      )

      someThingIsSelected(isSelected, { ...copyChecks, [name]: value })
      setCheckList((prev) => ({
        ...prev,
        [name]: value,
      }))
    }

    // const handleExpand = ({ name, value }) => {
    //   setExpand((prev) => ({
    //     ...prev,
    //     [name]: value,
    //   }))
    // }

    const getData = () => {
      getInternalData({
        data: checkList,
        clean: () => {
          setCheckList({})
          someThingIsSelected(false, {})
        },
      })
    }

    const selectOrUnselectAll = ({ name, length, value }) => {
      let copyChecks = structuredClone(checkList)
      setCheckList((prev) => ({
        ...prev,
        [name]: value,
      }))

      new Array(length)?.fill(1)?.forEach((_, index) => {
        copyChecks = {
          ...copyChecks,
          [`${name}-${index}`]: !!value,
        }
      })
      someThingIsSelected(value, { ...copyChecks })

      setCheckList(copyChecks)
    }

    return (
      <Box sx={styles.container}>
        {Object.entries(values ?? {})?.length > 0 ? (
          <Box>
            {/* DOCUMENTS AND AUDIO */}
            <Box sx={styles.containerDocuments}>
              {Object.entries(values?.[TeleExpertiseTypes.DOCUMENT] ?? {})?.map(
                ([key, item], index) => (
                  <Items
                    key={key}
                    onChange={onChange}
                    keyItem={key}
                    url={item?.value?.[0]}
                    checkList={checkList}
                    index={index}
                  />
                )
              )}

              {Object.entries(values?.[TeleExpertiseTypes.AUDIO] ?? {})?.map(
                ([key, item], index) => (
                  <Items
                    key={key}
                    onChange={onChange}
                    keyItem={key}
                    checkList={checkList}
                    index={index}
                    icon="audio"
                    url={item?.value?.[0]}
                    type={TeleExpertiseTypes.AUDIO}
                  />
                )
              )}
            </Box>

            {/* IMAGES */}
            <Box sx={styles.imagesContainer}>
              {Object.entries(values?.[TeleExpertiseTypes?.IMAGE] ?? {})?.map(
                ([key, item], index) => (
                  <Box key={index} sx={[styles.flex]}>
                    <Box sx={styles.titleContainer}>
                      <Typography sx={styles.title}>
                        {I18n.t(`patient.teleExpertise.selects.${key}`)}
                      </Typography>

                      <Box sx={styles.selectContainer}>
                        <Button
                          padding="8px 5px"
                          variant="text"
                          sx={styles.select}
                          onClick={() => {
                            selectOrUnselectAll({
                              name: key,
                              length: item?.value?.length,
                              value: true,
                            })
                          }}
                        >
                          {I18n.t(`patient.teleExpertise.selectAll`)}
                        </Button>

                        <Button
                          padding="8px 5px"
                          variant="text"
                          sx={styles.select}
                          onClick={() => {
                            selectOrUnselectAll({
                              name: key,
                              length: item?.value?.length,
                              value: false,
                            })
                          }}
                        >
                          {I18n.t(`patient.teleExpertise.unselectAll`)}
                        </Button>
                      </Box>
                    </Box>

                    {item?.value?.map((itemUrls, indexUrls) => (
                      <Box
                        onClick={() => {
                          onChange({
                            name: `${key}-${indexUrls}`,
                            value: !checkList?.[`${key}-${indexUrls}`],
                          })
                        }}
                        sx={[
                          styles.previewImages,
                          // checkList?.[`${key}-${indexUrls}`]
                          //   ? styles.selected
                          //   : {},
                        ]}
                        key={`${index}-${indexUrls}`}
                      >
                        {/* {!!checkList?.[`${key}-${indexUrls}`] && (
                          <Icon sx={styles.check}>{Icons('check')}</Icon>
                        )} */}
                        <Checkbox
                          sx={[
                            styles.check,
                            !checkList?.[`${key}-${indexUrls}`]
                              ? styles.unCheckImg
                              : {},
                          ]}
                          checked={!!checkList?.[`${key}-${indexUrls}`]}
                          onChange={(e, checked) => {
                            e.stopPropagation()
                            onChange({
                              name: `${key}-${indexUrls}`,
                              value: checked,
                            })
                          }}
                        />
                        <img src={itemUrls} alt="" />
                      </Box>
                    ))}
                  </Box>
                )
              )}
            </Box>
          </Box>
        ) : (
          // Object.entries(values ?? {})?.map(([key, item], index) => (
          //   <Box key={index}>
          //     {item?.value?.length > 1 ? (
          //       <Accordion
          //         sx={styles.removeAccordionStyles}
          //         expanded={!!expand[key]}
          //         onChange={() => {
          //           handleExpand({ name: key, value: !expand?.[key] })
          //         }}
          //       >
          //         <AccordionSummary aria-controls={key} id={key}>
          //           <Icon sx={styles.arrow}>{Icons('arrowDown')}</Icon>

          //           <Typography>
          //             {I18n.t(`patient.teleExpertise.selects.${key}`)}
          //           </Typography>
          //         </AccordionSummary>

          //         <AccordionDetails sx={styles.accordionDetail}>
          //           <Box sx={[styles.flex, styles.imagesContainer]}>
          //             {item?.value?.map((itemUrls, indexUrls) => (
          //               <Box
          //                 onClick={() => {
          //                   onChange({
          //                     name: `${key}-${indexUrls}`,
          //                     value: !checkList?.[`${key}-${indexUrls}`],
          //                   })
          //                 }}
          //                 sx={[
          //                   styles.previewImages,
          //                   checkList?.[`${key}-${indexUrls}`]
          //                     ? styles.selected
          //                     : {},
          //                 ]}
          //                 key={`${index}-${indexUrls}`}
          //               >
          //                 {/* {!!checkList?.[`${key}-${indexUrls}`] && (
          //                 <Icon sx={styles.check}>{Icons('check')}</Icon>
          //               )} */}
          //                 <Checkbox
          //                   sx={[
          //                     styles.check,
          //                     !checkList?.[`${key}-${indexUrls}`]
          //                       ? styles.unCheck
          //                       : {},
          //                   ]}
          //                   checked={!!checkList?.[`${key}-${indexUrls}`]}
          //                   onChange={(e, checked) => {
          //                     e.stopPropagation()
          //                     onChange({
          //                       name: `${key}-${indexUrls}`,
          //                       value: checked,
          //                     })
          //                   }}
          //                 />
          //                 <img src={itemUrls} alt="" />
          //               </Box>
          //             ))}
          //           </Box>
          //         </AccordionDetails>
          //       </Accordion>
          //     ) : (
          //       <Box
          //         key={index}
          //         sx={[styles.flex, styles.accordionStyles, styles.end]}
          //       >
          //         <Box sx={styles.flex}>
          //           <Checkbox
          //             checked={!!checkList?.[`${key}-0`]}
          //             onChange={(e, checked) => {
          //               e.stopPropagation()
          //               onChange({ name: `${key}-0`, value: checked })
          //             }}
          //           />

          //           <Typography>
          //             {I18n.t(`patient.teleExpertise.selects.${key}`)}
          //           </Typography>
          //         </Box>

          //         <IconButton
          //           onClick={(e) => {
          //             e.stopPropagation()
          //             window.open(item.value ?? '', 'blank')
          //           }}
          //           sx={styles.show}
          //         >
          //           {Icons('show')}
          //         </IconButton>
          //       </Box>
          //     )}
          //   </Box>
          // ))
          <Typography variant="body1" color="textSecondary">
            {I18n.t('general.notFound')}
          </Typography>
        )}

        <button
          ref={ref}
          style={styles.button}
          onClick={() => {
            getData()
          }}
        />
      </Box>
    )
  }
)

export { ListFile }
