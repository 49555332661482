import I18n from 'i18n-js'
import { isEmpty } from './validator'

export const CreateIndicatorValidator = (indicator, locale) => {
  if (
    !isEmpty(indicator[locale]?.name) &&
    !isEmpty(indicator.id_unit) &&
    !isEmpty(indicator[locale]?.description) &&
    !isEmpty(indicator.id_datasource) &&
    !isEmpty(indicator.acronym)
  ) {
    return false
  }
  return true
}

export const recomendation_interpretatonValidator = (annotations) => {
  if (annotations === undefined || Object.values(annotations).length === 0) {
    return false
  } else {
    if (
      Object.values(annotations).filter((annotation) => !annotation.completed)
        .length > 0
    ) {
      return true
    } else {
      return false
    }
  }
}

export const AnnotationsValidator = (annotation) => {
  const errors = {}
  // value
  if (annotation.value != undefined) {
    if (isEmpty(annotation.value)) errors.value = I18n.t('validators.required')
  }

  //interpretation
  if (annotation.interpretation != undefined) {
    if (isEmpty(annotation.interpretation))
      errors.interpretation = I18n.t('validators.required')
  }
  if (annotation.interpretation === undefined || annotation.value === undefined)
    errors.null = 'none'
  return errors
}

export default {
  CreateIndicatorValidator,
  AnnotationsValidator,
  recomendation_interpretatonValidator,
}
