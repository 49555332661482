import { makeStyles } from '@mui/styles'
import { colors } from '../../utils'

const useSxStyles = () => ({
  itemIcon: ({ open, mark }) => ({
    color: 'white',
    minWidth: '40px',
    '&.MuiListItemIcon-root': {
      ...(!open && { display: 'block' }),
      textAlign: open ? 'start' : 'center',
      color: mark ? colors.markIcon : colors.white,
      '& svg': {
        borderRadius: '50%',
        padding: '10px',
      },
      fontSize: '12px',
      width: 'fit-content',
      minWidth: '40px',
    },
  }),
  iconOpen: ({ mark }) => ({
    padding: '15px 20px',
    color: mark ? colors.markIcon : colors.white,
    background: mark ? colors.markBackground : 'transparent',
    '&.MuiListItem-root': {},
  }),
  iconClose: ({ mark }) => ({
    // paddingLeft: '25px',
    paddingTop: '10px',
    paddingBottom: '10px',
    // display: 'block',
    color: colors.white,
    '& svg': {
      background: mark ? colors.markBackground : 'transparent',
    },
  }),
})
const useStyles = makeStyles({
  itemIcon: {
    color: 'white',
    minWidth: '40px',
    '&.MuiListItemIcon-root': {
      color: colors.white,
      minWidth: '40px',
    },
  },
  subitemText: {
    paddingLeft: '40px',
    '& span': {
      fontSize: '.9rem',
      color: colors.white,
    },
  },
  collapse: {
    background: 'rgba(0,0,0,.1)',
  },
  itemText: {
    color: colors.white,
  },
})

export { useStyles, useSxStyles }
