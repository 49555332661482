export const sidebarOptions = [
  {
    name: 'sidebar.dashboard',
    slug: '/dashboard',
    icon: 'home',
    parentId: null,
    id: 1,
    pageId: 1,
  },
  {
    name: 'sidebar.homePredict',
    slug: '/dashboard/homepredict',
    icon: 'homePredict',
    parentId: null,
    id: 19,
    pageId: 19,
  },
  {
    name: 'sidebar.patients',
    slug: '/dashboard/patients',
    icon: 'patients',
    parentId: null,
    id: 18,
    pageId: 18,
  },
  {
    name: 'sidebar.mstaff',
    slug: '/dashboard/medical-staff',
    icon: 'stethoscope',
    parentId: null,
    id: 2,
    pageId: 2,
  },
  {
    name: 'sidebar.mcenter',
    slug: '/dashboard/medical-center',
    icon: 'hospital',
    parentId: null,
    id: 3,
    pageId: 3,
  },
  {
    name: 'sidebar.users',
    slug: '/dashboard/users',
    icon: 'people',
    parentId: null,
    id: 4,
    pageId: 4,
  },
  {
    name: 'sidebar.configuration',
    slug: null,
    icon: 'settings',
    parentId: null,
    id: 5,
    pageId: 5,
    childrens: [
      'indicators',
      'questionaries',
      'parameter',
      'units',
      'types-center-medical',
      'types-staff',
      'specialities',
      'relationships',
      'profiles',
      'providers',
      'health-conditions',
      'smart-test',
    ],
  },

  /* separation parent-children */

  {
    name: 'sidebar.dashboard',
    slug: '/dashboard',
    icon: '',
    parentId: 2,
    id: 6,
    pageId: 6,
  },

  {
    name: 'sidebar.indicator',
    slug: '/dashboard/indicators',
    icon: '',
    parentId: 5,
    id: 7,
    pageId: 7,
  },
  {
    name: 'sidebar.questionaries',
    slug: '/dashboard/questionaries',
    icon: '',
    parentId: 5,
    id: 8,
    pageId: 8,
  },
  {
    name: 'sidebar.smartTest',
    slug: '/dashboard/smart-test',
    icon: '',
    parentId: 5,
    id: 20,
    pageId: 20,
  },
  {
    name: 'sidebar.frequencie',
    slug: '/dashboard',
    icon: '',
    parentId: 5,
    id: 9,
    pageId: 9,
  },
  {
    name: 'sidebar.healthcondition',
    slug: '/dashboard/health-conditions',
    icon: '',
    parentId: 5,
    id: 10,
    pageId: 10,
  },
  {
    name: 'sidebar.provider',
    slug: '/dashboard/providers',
    icon: '',
    parentId: 5,
    id: 11,
    pageId: 11,
  },
  {
    name: 'sidebar.profile',
    slug: '/dashboard/profiles',
    icon: '',
    parentId: 5,
    id: 12,
    pageId: 12,
  },
  {
    name: 'sidebar.relation',
    slug: '/dashboard/relationships',
    icon: '',
    parentId: 5,
    id: 13,
    pageId: 13,
  },
  {
    name: 'sidebar.specialty',
    slug: '/dashboard/specialities',
    icon: '',
    parentId: 5,
    id: 14,
    pageId: 14,
  },
  {
    name: 'sidebar.typestaff',
    slug: '/dashboard/types-staff',
    icon: '',
    parentId: 5,
    id: 15,
    pageId: 15,
  },
  {
    name: 'sidebar.typecenter',
    slug: '/dashboard/types-center-medical',
    icon: '',
    parentId: 5,
    id: 16,
    pageId: 16,
  },
  {
    name: 'sidebar.unities',
    slug: '/dashboard/units',
    icon: '',
    parentId: 5,
    id: 17,
    pageId: 17,
  },
  {
    name: 'sidebar.parameter',
    slug: '/dashboard/parameter',
    icon: '',
    parentId: 5,
    id: 21,
    pageId: 21,
  },
]
