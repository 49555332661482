import {
  Box,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
} from '@mui/material'
import I18n from 'i18n-js'
import { useContext, useState } from 'react'
import { useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Icons } from '../../../utils'
import useStyle from '../form/style'
import { languages } from '../../../utils'
import { healthConditions } from '../../../services'
import { AlertModal, CrudsBtn } from '../../../components'
import { AuthContext } from '../../../context/auth'
import TranslateSelected from '../../../components/translateBox/translateSelected'
const Show = () => {
  const { state } = useContext(AuthContext)
  const classes = useStyle()
  const [openModal, setOpenModal] = useState(false)
  const { id } = useParams()
  const history = useHistory()
  const [item, setItem] = useState()
  const [flat, setFlat] = useState(false)
  const [childrens, setChildrens] = useState()
  const [languageColOne, setLenguageColOne] = useState('')
  const [languageColTwo, setLenguageColTwo] = useState('')
  const [languageListColOne, setLanguageListColOne] = useState([])
  const [languageListColTwo, setLanguageListColTwo] = useState([])
  
  const getData = async () => {
    const res = await healthConditions.detail(id)

    if (res.data !== null) {
      handleChildrenLength(res.data.healthCondition)
      let translations = [{}]

      let newhealthConsition = { ...res.data.healthCondition }

      res.data.healthCondition.translations?.forEach((a) => {
        if (a.locale === state.locale) {
          translations[0] = a
        } else {
          translations.push(a)
        }
      })
      newhealthConsition = {
        ...newhealthConsition,
        translations,
      }

      setItem(newhealthConsition)
      setLenguageColOne(state.locale)
      setLenguageColTwo(languages.find((a) => a.locale !== state.locale).locale)
      handleChangeLenguaje1(
        { target: { value: state.locale } },
        undefined,
        newhealthConsition
      )
      handleChangeLenguaje2(
        {
          target: {
            value: languages.find((a) => a.locale !== state.locale).locale,
          },
        },
        undefined,
        newhealthConsition
      )
    }
  }

  useEffect(() => {
    if (!flat) {
      getData()
      setFlat(true)
    }
  }, [])

  const handleDeleteItem = () => {
    setOpenModal(true)
  }
  const handleDelete = async () => {
    const res = await healthConditions.destroy(id)

    if (res.data !== null) {
      history.push('/dashboard/health-conditions')
    }
  }

  const handleChangeLenguaje1 = (e, _, value = undefined) => {
    let langList = []

    const list = value ? value : { ...item }

    list?.children?.forEach((val) => {
      const existValue = val.translations?.find(
        (v) => v.locale === e.target.value
      )
      if (existValue) {
        langList.push(existValue)
      }
    })

    setLanguageListColOne(langList)

    setLenguageColOne(e.target.value)
  }

  const handleChangeLenguaje2 = (e, _, value = undefined) => {
    let langList = []

    const list = value ? value : { ...item }

    list?.children?.forEach((val) => {
      const existValue = val.translations?.find(
        (v) => v.locale === e.target.value
      )
      
      if (existValue?.name) {
        langList.push(existValue)
      }
    })

    setLanguageListColTwo(langList)

    setLenguageColTwo(e.target.value)
  }

  const handleChildrenLength = (items) => {
    let newChildren = {}

    items?.children?.forEach((item) => {
      item.translations?.forEach((val) => {
        if(val?.name) {
          newChildren = {
            ...newChildren,
            [val.locale]:
              newChildren?.[val?.locale] >= 0 ? newChildren[val.locale] + 1 : 1,
          }
        }
      })
    })

    setChildrens({ ...newChildren })
  }

  return (
    <Box p={4} m={4}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.healthConditions')} /{' '}
          <Link
            to="/dashboard/health-conditions"
            className={classes.textBreadcrum}
          >
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
          {I18n.t('cruds.healthCondition.detail')}
        </Typography>
      </Box>
      {/* header */}
      <Box className={classes.bodyContainer}>
        <Box className={classes.titleBarContainer}>
          <Box className={classes.titleContainer}>
            <Typography variant="subtitle1" color="textSecondary">
              {I18n.t('breadcrumb.healthCondition')}
            </Typography>
            <Typography variant="h6" color="primary">
              {I18n.t('cruds.healthCondition.show')}
            </Typography>

            <Typography
              variant="h5"
              color="primary"
              className={classes.title}
            ></Typography>
          </Box>
          <Box className={classes.buttonsContainer}>
            <IconButton
              color="secondary"
              onClick={() =>
                history.push(`/dashboard/health-conditions/edit/${item.id}`, {
                  state: {
                    hash: 'edit',
                  },
                })
              }
            >
              {Icons('edit')}
            </IconButton>
            <IconButton onClick={handleDeleteItem}>
              {Icons('delete')}
            </IconButton>
          </Box>
        </Box>
      </Box>

      {item === undefined ? (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      ) : (
        <>
          <Grid container mt={0} pb={3} spacing={4} justifyContent="center">
            <TranslateSelected
              onChange={handleChangeLenguaje1}
              languageSelected={languageColOne}
              childrens={childrens[languageColOne]}
              list={languageListColOne}
              title={
                item?.translations?.find((val) => val.locale === languageColOne)
                  ?.name
              }
              languages={languages}
            />

            <TranslateSelected
              onChange={handleChangeLenguaje2}
              languageSelected={languageColTwo}
              childrens={childrens[languageColTwo]}
              list={languageListColTwo}
              title={
                item?.translations?.find((val) => val.locale === languageColTwo)
                  ?.name
              }
              languages={languages}
            />
          </Grid>

          <AlertModal
            open={openModal}
            close={() => {
              setOpenModal(false)
            }}
            title={I18n.t('components.warningAtention')}
            message={I18n.t('components.deleteItem')}
            SuccessText={I18n.t('components.yes')}
            success={handleDelete}
          />

          <Box className={classes.cancelBtn}>
            <CrudsBtn
              variant="text"
              text={I18n.t('components.cancel')}
              component={Link}
              to="/dashboard/health-conditions"
            />
          </Box>
        </>
      )}
    </Box>
  )
}

export default Show
