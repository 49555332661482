import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
// import ReactECharts from 'echarts-for-react'
import './styles.css'

const InformationItem = () => (
  <div className="indicatorBar-information-item">
    <h3>0 Min</h3>
    <h4>Sommeil profond</h4>
  </div>
)

const IndicatorBar = ({
  image,
  title,
  measured,
  value,
  text,
  min,
  max,
  point,
  informationList = [],
}) => {
  const [setIsModalVisible] = useState(false)

  const showModal = () => {
    // Modal.destroyAll()
    setIsModalVisible(true)
  }

  return (
    <>
      <div className={`indicatorBar ${min == '-' ? 'disabled' : ''}`}>
        <h2 className="indicatorBar-title">{title}</h2>
        <div className="indicatorBar-body" onClick={showModal}>
          <div className="indicatorBar-icon">
            <img src={`/images/indicatorBar/${image}`} />
            {value && <h3>{value}</h3>}
            {measured && <h4>{measured}</h4>}
          </div>
          <div className="indicatorBar-progress">
            {text && (
              <h3 className="indicatorBar-progress-metrics-top">{text}</h3>
            )}
            <div className="indicatorBar-progress-bar"></div>
            <div className="indicatorBar-progress-metrics">
              <h6 className="indicatorBar-progress-metrics-min">{min}</h6>
              <h6 className="indicatorBar-progress-metrics-max">{max}</h6>
              {point && (
                <div
                  className="indicatorBar-progress-metrics-point"
                  style={{ left: point }}
                ></div>
              )}
            </div>
          </div>
          {informationList.length > 0 && (
            <div className="indicatorBar-information">
              {[0, 1, 2, 3].map((item, key) => (
                <InformationItem key={key} {...item} />
              ))}
            </div>
          )}
        </div>
      </div>
      {/* <Modal title={title} footer={null} visible={isModalVisible} onCancel={handleCancel}>
        <ReactECharts
          option={{
            tooltip: {
              trigger: 'axis',
            },
            legend: {
              data: ['max temp', 'min temp'],
            },
            toolbox: {
              show: true,
              feature: {
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true },
              },
            },
            color: ['#55ce63', '#009efb'],
            calculable: true,
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: [
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                  'Sunday',
                ],
              },
            ],
            yAxis: [
              {
                type: 'value',
                axisLabel: {
                  formatter: '{value} °C',
                },
              },
            ],

            series: [
              {
                name: 'max temp',
                type: 'line',
                color: ['#000'],
                data: [11, 11, 15, 13, 12, 13, 10],
                markPoint: {
                  data: [{ type: 'max', name: 'Max' }],
                },
                itemStyle: {
                  normal: {
                    lineStyle: {
                      shadowColor: 'rgba(0,0,0,0.3)',
                      shadowBlur: 10,
                      shadowOffsetX: 8,
                      shadowOffsetY: 8,
                    },
                  },
                },
                markLine: {
                  data: [{ type: 'average', name: 'Average' }],
                },
              },
              {
                name: 'min temp',
                type: 'line',
                data: [1, -2, 2, 5, 3, 2, 0],
                markPoint: {
                  data: [{ type: 'min', name: 'Min' }],
                },
                itemStyle: {
                  normal: {
                    lineStyle: {
                      shadowColor: 'rgba(0,0,0,0.3)',
                      shadowBlur: 10,
                      shadowOffsetX: 8,
                      shadowOffsetY: 8,
                    },
                  },
                },
                markLine: {
                  data: [{ type: 'average', name: 'Average' }],
                },
              },
            ],
          }}
          notMerge={true}
          lazyUpdate={true}
          theme={'theme_name'}
        />
      </Modal> */}
    </>
  )
}

IndicatorBar.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  measured: PropTypes.string,
  value: PropTypes.string,
  text: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  point: PropTypes.string,
  informationList: PropTypes.array,
}

IndicatorBar.defaultProps = {
  image: 'heart.png',
  min: '-',
  max: '-',
  informationList: [],
}
export default IndicatorBar
