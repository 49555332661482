const pagesKeys = {
  main: 1,
  medicalstaff: 2,
  medicalcenter: 3,
  users: 4,
  indicator: 7,
  questionaries: 8,
  frequencies: 9,
  healthconditions: 10,
  providers: 11,
  profiles: 12,
  relations: 13,
  specialities: 14,
  typestaff: 15,
  typecenter: 16,
  units: 17,
  parameter: 21,
  patients: 18,
  homePredict: 19,
  smartTest: 20,
  myProfile: 22,
}

export default pagesKeys
