import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('observations')
  return res
}

const detail = async (id) => {
  const res = await GET(`observations?patient_id=${id}`)
  return res
}

const create = async (body) => {
  const res = await POST('observations', body)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  const data = { name: update }
  const res = await PUT(`observations/${id}`, data)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`observations/${id}`)
  return res
}

export default {
  detail,
  list,
  create,
  edit,
  destroy,
}
