const languages = [
  { name: 'languages.french', locale: 'fr' },
  { name: 'languages.portuguese', locale: 'pt' },
  { name: 'languages.spanish', locale: 'es' },
  { name: 'languages.english', locale: 'en' },
  { name: 'languages.spanishCo', locale: 'es-CO' },
]

export default languages

export const listCountries = {
  fr: 'fr',
  pt: 'pt',
  es: 'es',
  en: 'gb',
}
