export const medicalCenterFormData = (body) => {
  let formData = new FormData()

  Object.entries(body).forEach(([key, value]) => {
    if (key === 'persons' && value?.length > 0) {
      value.forEach((person) => {
        Object.entries(person).forEach(([personName, personValue]) => {
          formData.append(`${key}[][${personName}]`, personValue)
        })
      })
    } else if (key === 'kits') {
      value.forEach((kitName, indexKit) => {
        Object.entries(kitName).forEach(([sensorsName, sensorsVal]) => {
          Object.entries(sensorsVal).forEach(
            ([MacEtiquetaName, MacEtiquetaVal1]) => {
              formData.append(
                `${key}[${indexKit}][${sensorsName}][${MacEtiquetaName}]`,
                MacEtiquetaVal1
              )
            }
          )
        })
      })
    } else if (key === 'divice') {
      Object.values(value ?? {}).forEach((diviceName, indexdivice) => {
        formData.append(
          `license_aminogram[${indexdivice}][sensorOne][id_aminogram]`,
          diviceName.id_aminogram
        )
        formData.append(
          `license_aminogram[${indexdivice}][sensorTwo][license_aminogram]`,
          diviceName.license_aminogram
        )
      })
    } else {
      formData.append(key, value)
    }
  })
  
  return formData
}
