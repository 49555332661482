import { makeStyles } from '@mui/styles'

const useStyle = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-menuIcon': {
      display: 'none',
    },
  },
})

export default useStyle
