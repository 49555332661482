const useSortForSend = (data, locale, type = 1) => {
  let questions

  const questionsId = Object.keys(data[locale].questions)

  questions = Object.values(data[locale].questions).map((question, index) => {
    const title = Object.values(data.traductions).map((traduction) => {
      return {
        value: traduction.questions[questionsId[index]]?.title || '',
        locale: traduction.locale,
      }
    })
    title.push({ value: question.title, locale: locale })

    const optionsId = Object.keys(question.options)

    const questionData = {
      title: title,
      question_type_id: question.type,
      required: question.required ?? false,
      id: question.id || null,
      options: Object.values(question.options).map((option, num) => {
        /* Search all the titles from traductions */
        const title = Object.values(data.traductions).map((traduction) => {
          return {
            value:
              traduction.questions[questionsId[index]]?.options?.[
                optionsId[num]
              ]?.title || '',
            locale: traduction.locale,
          }
        })
        /* push the title option from the locale language */
        title.push({ value: option.title || '', locale: locale })
        const optionData = {
          title: title,
        }
        if (type > 1) {
          optionData.id = option.id || null
        }
        return optionData
      }),
    }
    if (type > 1) {
      questionData.id = question.id || null
    }
    return questionData
  })

  return {
    questions: questions,
  }
}

export default useSortForSend
