import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    marginTop: '3rem',
  },
  mbxl: {
    '&.MuiGrid-root': {
      marginBottom: '2rem',
    },
  },
  mbxxl: {
    marginBottom: '2rem',
  },
  title: {
    width: 'auto',
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    '&.MuiTypography-root': {
      lineHeight: '2rem',
      marginRight: 10,
    },
  },
}))
