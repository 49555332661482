import { apiUrl } from '../utils/constants'

export const isValidToken = async () => {
  const state = JSON.parse(localStorage.getItem('state'))

  if (state?.token) {
    const response = await fetch(apiUrl + 'verify-token', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${state.token}`,
        'Content-Type': 'application/json',
      },
    })

    if (response.status === 401) {
      // window.location = '/logout'
      return { data: null, error: response }
    }

    return { data: response, error: null }
  }
}
