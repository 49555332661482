import { useContext, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Box, Fade, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import { providers, questionaries, smartTests } from '../../../services'
import { InformationGeneral } from '../steps'
import { pagesKeys, actionKeys } from '../../../utils'
import { canAccess } from '../../../router/permissions'
import {
  StepperCreate,
  CrudsBtn,
  Loading,
  AlertModal,
} from '../../../components'
import {
  stepOneValidator,
  stepThreeValidator,
  stepTwoValidator,
} from '../../../validators/smartTestValidator'
import { LanguageForm } from '../steps'
import notAllHaveTraductions from '../../../hooks/traductions/notAllHaveTraductions'
import { useSortForSend, useSortResponse } from '../../../hooks/smartTest'
import useStyles from './style'
import DaD from '../steps/dnd'
import { isNumber } from '../../../validators/validator'
import { editKeysImages, fieldsNeedTranslateArray, nameImages } from '../list'

const sortData = (data, nameField) => {
  let sortitems = []

  data.map((item) => {
    sortitems = [...sortitems, { id: item.id, name: item[nameField] }]
  })
  return sortitems
}

const EditSmartTest = () => {
  const classes = useStyles()
  //traslations
  const [fieldsNeedTranslation] = useState([
    {
      name: 'name',
      multiline: false,
      label: 'cruds.smartTest.form.name',
    },
    {
      name: 'description',
      multiline: true,
      label: 'cruds.smartTest.form.description',
    },
    {
      name: 'instructional_picture',
      multiline: false,
      label: 'cruds.smartTest.form.instructionTest',
      file: true,
    },
    {
      name: 'data_sensors',
      multiline: false,
      label: 'cruds.smartTest.form.instructionSensors',
      file: true,
    },
  ])
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const locale = state.locale
  const [loading, setLoading] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const [smartTest, setSmartTest] = useState({
    type: 2,
  })
  const [disabledStepTwo, setDisabledStepTwo] = useState(true)
  const [disabledStepOne, setDisabledStepOne] = useState(true)
  const [errors, setErrors] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const { id } = useParams()
  //selects data
  const [providersList, setProvidersList] = useState([])
  const [questionariesList, setQuestionariesList] = useState([])
  const [smartTestList, setSmartTestList] = useState([])
  const [stepTypes, setStepTypes] = useState([])
  const [images, setImages] = useState('')
  const [oldImage, setOldImage] = useState(null)
  const [traductions, setTraductions] = useState(null)
  const [originalImages, setOriginalIamges] = useState({})
  const [steps] = useState([
    {
      step: 'cruds.smartTest.steps.stepOne.step',
      label: 'cruds.smartTest.steps.stepOne.label',
    },
    {
      step: 'cruds.smartTest.steps.stepTwo.step',
      label: 'cruds.smartTest.steps.stepTwo.label',
    },
    {
      step: 'cruds.smartTest.steps.stepThree.step',
      label: 'cruds.smartTest.steps.stepThree.label',
    },
  ])

  //methods
  const haveSmartQuestionaries = () => {
    if (
      Object.values(smartTest[locale]?.blocks || []).filter(
        (block) => block.block_type === 1
      )?.length > 0
    )
      return true
    else return false
  }
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleDeleteImages = () => {
    setOriginalIamges({})
  }

  const handleImages = (name, value) => {
    if(nameImages.includes(name) && value){
      const objectURL = URL.createObjectURL(value) 
      setOriginalIamges(prev => ({...prev, [editKeysImages[name]]: objectURL}))

      if (name === 'url_image') setImages('')
    }
  }
  
  const handleChange = (e) => {
    const { name, value } = e.target

    if (fieldsNeedTranslateArray.includes(name)) {
      setSmartTest(prev => ({
        ...prev,
        [locale]: {
          ...prev[locale],
          [name]: value,
          locale: locale,
        },
      }))
    } else {
      if (name === 'position') {
        if ((isNumber(value) && value > 0) || value === '') {
          setSmartTest(prev => ({ ...prev, [name]: value }))
        }
      } else {
       setSmartTest(prev => ({ ...prev, [name]: value }))
      }
    }
    handleImages(name, value)
  }
  
  const checkTraductions = () => {
    if (notAllHaveTraductions(smartTest.traductions, false, false, true)) {
      setOpenModal(true)
    } else {
      handleSubmitData()
    }
  }
  const appendData = (name, data, formdta) => {
    data[name].map((item) => {
      formdta.append(`${name}[]`, JSON.stringify(item))
    })
  }
  
  const handleSubmitData = async () => {
    setLoading(true)
    /* setDisabledStepOne(true) */
    const body = useSortForSend(smartTest, locale)
    let formsmartTest = new FormData()
    const datasmartTest = { ...body }
    delete datasmartTest.steps
    Object.values(datasmartTest).map((item, index) => {
      if (Array.isArray(item)) {
        if (
          Object.keys(datasmartTest)[index] != 'instructional_picture' &&
          Object.keys(datasmartTest)[index] != 'data_sensors'
        ) {
          appendData(
            Object.keys(datasmartTest)[index],
            datasmartTest,
            formsmartTest
          )
        }
        if (Object.keys(datasmartTest)[index] === 'instructional_picture') {
          item.forEach(val => {
            if (val.value && traductions?.[val?.locale]?.instructional_picture !== val.value) {
            formsmartTest.append('instructional_picture[][locale]', val.locale)
            formsmartTest.append('instructional_picture[][value]', val.value)
          }})
        }
        if (Object.keys(datasmartTest)[index] === 'data_sensors') {
          item.forEach(val => {
            if (val.value && traductions?.[val?.locale]?.data_sensors !== val.value) {
            formsmartTest.append('data_sensors[][locale]', val.locale)
            formsmartTest.append('data_sensors[][value]', val.value)
          }})
        }
      } else {
        formsmartTest.append(
          Object.keys(datasmartTest)[index],
          Object.values(datasmartTest)[index]
        )
      }
    })

    if (
      smartTest?.url_image?.name !== null &&
      smartTest?.url_image?.name?.trim() !== '' &&
      images === ''
    )
      formsmartTest.append('image', smartTest.url_image)

    const response = await smartTests.edit(formsmartTest, id)
    if (response.data) {
      const steps = body.steps
      steps.map(async (step) => {
        step.smart_test_id = id
        if (step.id) {
          await smartTests.editStep(step, step.id)
        } else {
          await smartTests.createStep(step)
        }
      })
      setActiveStep(activeStep + 1)
      setDisabledStepOne(false)
    }
    setLoading(false)
  }

  //fetch
  const getQuestonaries = async () => {
    const questionariesResponse = await questionaries.listActive()
    setQuestionariesList(sortData(questionariesResponse, 'name') || [])
  }

  const getSmartTests = async () => {
    const smartTestResponse = await smartTests.listActive('?test_type_id=1')
    const stepTypes = await smartTests.stepTypes()
    setStepTypes(stepTypes.data?.stepTypes || [])
    setSmartTestList(sortData(smartTestResponse, 'title') || [])
  }

  const getSelects = async () => {
    //providers
    const providersResponse = await providers.list()
    setProvidersList(
      providersResponse.data
        ? sortData(providersResponse.data.providers, 'description')
        : []
    )

    //questionaries
    await getQuestonaries()

    //smart tests
    await getSmartTests()

    //this test
    const smartResponse = await smartTests.show(id)
    const sort = useSortResponse(smartResponse?.data, locale)

    setImages(smartResponse?.data?.url_image || null)
    setOldImage(smartResponse?.data?.url_image || null)
    setOriginalIamges({
      url_image: smartResponse?.data?.url_image,
      url_instructional: smartResponse?.data?.url_instructional,
      url_sensors: smartResponse?.data?.url_sensors,
       ...sort.traductions ?? {}
    })
    setTraductions(sort.traductions || null)

    setSmartTest(sort)
  }

  const handleDisabled = () => {
    switch (activeStep) {
      case 0: return disabledStepOne
      case 1: return disabledStepTwo
      case 2: return !stepThreeValidator(smartTest?.traductions)
    }
  }

  const accessAsync = async () => {
    if (canAccess(actionKeys.edit, pagesKeys.smartTest, state)) {
      await getSelects()
      setLoading(false)
    } else {
      history.push('/dashboard')
    }
  }
  
  useEffect(() => {
    accessAsync()
  }, [])

  /* validator */
  useEffect(() => {
    const validator = stepOneValidator(
      smartTest,
      locale,
      smartTest.updateProvider
    )
    if (Object.values(validator).length > 0) {
      setDisabledStepOne(true)
    } else {
      setDisabledStepOne(false)
    }
    setErrors(validator)
    setDisabledStepTwo(stepTwoValidator(smartTest[locale]?.blocks))
  }, [smartTest])

  useEffect(() => {
    if (!loading) {
      /* delete blocks if the type of smart test change */
      let newSmartTest = {...smartTest}
      if (newSmartTest[locale]?.blocks) {
        delete newSmartTest[locale].blocks
        /* delete the trasnlations if exist */
        if (newSmartTest.traductions) {
          Object.values(newSmartTest.traductions).map((traduction) => {
            if (traduction.blocks) {
              delete newSmartTest.traductions[traduction.locale].blocks
            }
          })
        }
      }
      delete newSmartTest.url_image
      /* delete number of sensors */
      if (newSmartTest.sensors_quantity) delete newSmartTest.sensors_quantity
      /* delete images */
      if (newSmartTest[locale]?.instructional_picture) {
        delete newSmartTest[locale].instructional_picture
        /* delete the trasnlations if exist */
        if (newSmartTest.traductions) {
          Object.values(newSmartTest.traductions).map((traduction) => {
            if (traduction.instructional_picture) {
              delete newSmartTest.traductions[traduction.locale]
                .instructional_picture
            }
          })
        }
      }
      if (newSmartTest[locale]?.data_sensors) {
        delete newSmartTest[locale].data_sensors
        /* delete the trasnlations if exist */
        if (newSmartTest.traductions) {
          Object.values(newSmartTest.traductions).map((traduction) => {
            if (traduction.data_sensors) {
              delete newSmartTest.traductions[traduction.locale].data_sensors
            }
          })
        }
      }
  
      setSmartTest(newSmartTest)
      handleDeleteImages()
    }
  }, [smartTest.type])
  //steps

  return (
    <Box p={4} m={4}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.smartTest')} /{' '}
          <Link to="/dashboard/smart-test" className={classes.textBreadcrum}>
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
          {I18n.t('cruds.smartTest.edit.indicator')}
        </Typography>
      </Box>
      {/* header */}
      <Box className={classes.bodyContainer}>
        <Box className={classes.titleBarContainer}>
          <Box className={classes.titleContainer}>
            <Typography variant="subtitle1" color="textSecondary">
              {I18n.t('cruds.smartTest.edit.title')}
            </Typography>
            <Typography variant="h6" color="primary" className={classes.textBreak}>
              {smartTest[locale]?.name && smartTest[locale]?.name != ''
                ? smartTest[locale]?.name
                : I18n.t('cruds.smartTest.edit.subtitle')}
            </Typography>
          </Box>
          <Box className={classes.stepperContainer}>
            <StepperCreate activeStep={activeStep} steps={steps} />
          </Box>
        </Box>
      </Box>

      {!loading ? (
        <>
          {/* body */}
          <Box className={classes.body}>
            <Fade in={activeStep === 0} timeout={500}>
              <div>
                {activeStep === 0 && (
                  <InformationGeneral
                    values={smartTest}
                    setValues={setSmartTest}
                    change={handleChange}
                    providers={providersList}
                    locale={locale}
                    errors={errors}
                    oldImage={oldImage}
                    detailImages={originalImages}
                  />
                )}
              </div>
            </Fade>
            <Fade in={activeStep === 1} timeout={500}>
              <div>
                {activeStep === 1 && (
                  <DaD
                    values={smartTest}
                    setValues={setSmartTest}
                    questionariesList={questionariesList}
                    smartTestList={smartTestList}
                    getQuestonaries={getQuestonaries}
                    getSmartTests={getSmartTests}
                    locale={locale}
                    blockType={stepTypes.filter(
                      (type) => type.test_type.id === smartTest.type
                    )}
                  />
                )}
              </div>
            </Fade>
            <Fade in={activeStep === 2} timeout={500}>
              <div>
                {activeStep === 2 && (
                  <LanguageForm
                    items={
                      smartTest.type === 2
                        ? fieldsNeedTranslation.slice(0, 2)
                        : fieldsNeedTranslation
                    }
                    values={smartTest}
                    locale={locale}
                    setCreateData={setSmartTest}
                    traductions={traductions}
                    detailImages={originalImages}
                    setDetailImages={setOriginalIamges}
                  />
                )}
              </div>
            </Fade>
            <Fade in={activeStep === 3} timeout={500}>
              <div>
                {activeStep === 3 && (
                  <Box className={classes.finishContainer}>
                    <CheckCircle className={classes.chekCircle} />
                    <Typography variant="h5" color="primary">
                      {I18n.t('components.success')}
                    </Typography>
                    <Typography variant="body1" color="primary">
                      {I18n.t('cruds.smartTest.edit.created')}
                    </Typography>
                  </Box>
                )}
              </div>
            </Fade>
          </Box>

          {/* footer */}
          <Box
            className={
              classes.footer +
              ' ' +
              (activeStep < 3 ? classes.between : classes.center)
            }
          >
            {activeStep < 3 && (
              <CrudsBtn
                variant="text"
                text={I18n.t('components.cancel')}
                component={Link}
                to="/dashboard/smart-test"
              />
            )}

            <Box>
              {activeStep > 0 && activeStep < 3 && (
                <CrudsBtn
                  variant="outlined"
                  text={I18n.t('components.back')}
                  className={classes.button}
                  click={handleBackStep}
                />
              )}
              {activeStep < 3 && (
                <CrudsBtn
                  disabled={handleDisabled()}
                  text={I18n.t('components.next')}
                  className={classes.button}
                  click={activeStep < 2 ? handleNextStep : checkTraductions}
                />
              )}
              {activeStep === 3 && (
                <Box className={classes.endButtonContainer}>
                  {smartTest.type === 1 && haveSmartQuestionaries() && (
                    <CrudsBtn
                      text={I18n.t('cruds.smartTest.goToQuestions')}
                      component={Link}
                      to={`/dashboard/smart-test/questions/${id}`}
                    />
                  )}
                  <CrudsBtn
                    text={I18n.t('cruds.smartTest.edit.return')}
                    component={Link}
                    variant={
                      smartTest.type === 1 && haveSmartQuestionaries()
                        ? 'outlined'
                        : 'contained'
                    }
                    to="/dashboard/smart-test/"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Loading />
      )}

      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('components.traductions.missingTraductionTitle')}
        message={I18n.t('components.traductions.missingTraductiontext')}
        SuccessText={I18n.t('components.yes')}
        success={handleSubmitData}
      />
    </Box>
  )
}

export default EditSmartTest
