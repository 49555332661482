import React, { useState, useContext } from 'react'
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  IconButton,
} from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import I18n from 'i18n-js'
import { AuthContext } from '../../../../context/auth'
import { canAccess } from './../../../../router/permissions'
import { Loader, SnackBar } from '../../../../components'
import { actionKeys, Icons, pagesKeys } from '../../../../utils'
import { isEmail } from '../../../../validators/validator'
import patients from '../../../../services/patients'
import useStyles from '../../../../styles'
import { LoadingButton } from '@mui/lab'

function DialogPdf(props) {
  //state
  const { state } = useContext(AuthContext)
  const pageId = pagesKeys.patients
  //information
  const classes = useStyles()
  const [isLooadingDialog, setIsLoadingDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [emails, setEmails] = useState()

  //snackBar
  const [snackBar, setSnackBar] = useState({})
  const [showSnackBar, setShowSnackBar] = useState(false)

  const handleSnackBar = () => {
    setShowSnackBar(false)
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const seeDocument = (url) => {
    if (url) {
      window.open(url)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setEmails(undefined)
  }

  const handleSendEmail = async (e) => {
    e.preventDefault()
    setIsLoadingDialog(true)
    const body = {
      email: [emails],
      is_actual: props.isActualSP,
      history_id: props.historyId,
    }
    const response = await patients.sendEmail(props.patientId, body)
    if (response.data) {
      setIsLoadingDialog(false)
      setSnackBar({
        text: I18n.t('patient.detail.pdf.success'),
        severity: 'success',
      })
      setShowSnackBar(true)
      handleCloseDialog()
    } else {
      setIsLoadingDialog(false)
      setSnackBar({
        text: I18n.t('patient.detail.pdf.errorSend'),
        severity: 'warning',
      })
      setShowSnackBar(true)
      handleCloseDialog()
    }
  }

  function renderBody() {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} className={classes.gridItem}>
          <LoadingButton
            variant="outlined"
            className={classes.buttongeneral}
            loading={props.loadingDownload}
            loadingPosition="start"
            padding="8px 15px"
            startIcon={Icons('download')}
            onClick={() => {
              props.downloadData(props.patientId, props.dateTug)
            }}
          >
            {I18n.t('general.dataDowload')}
          </LoadingButton>
          {canAccess(actionKeys.sendReport, pageId, state) && props.url && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              padding="8px 15px"
              borderRadius={4}
              onClick={handleClickOpenDialog}
              startIcon={<PictureAsPdfIcon />}
            >
              {I18n.t('patient.detail.tabSmartPredict.pdf.smartPredictReport')}
            </Button>
          )}
          {props.download && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={Icons('download')}
              padding="8px 15px"
              onClick={() => seeDocument(props.download)}
              style={{ marginLeft: '10px' }}
            >
              {I18n.t('patient.detail.tabSmartPredict.pdf.IheXdmReport')}
            </Button>
          )}
        </Grid>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth={true}
          maxWidth={'sm'}
          aria-labelledby="form-dialog-title"
          className={classes.dialogContainer}
        >
          {isLooadingDialog ? (
            <Loader />
          ) : (
            <form onSubmit={handleSendEmail}>
              <DialogTitle id="form-dialog-title">
                {I18n.t('patient.detail.tabSmartPredict.pdf.documentOption')}
              </DialogTitle>
              <DialogContent>
                <Box className={classes.btnContainer}>
                  <IconButton
                    onClick={() => seeDocument(props.url)}
                    className={classes.pdf}
                  >
                    {Icons('file-pdf')}
                    <Typography className={classes.pdfText}>
                      {I18n.t('patient.detail.tabSmartPredict.pdf.downloadPdf')}
                    </Typography>
                  </IconButton>
                </Box>
                <DialogContentText>
                  {I18n.t('patient.detail.pdf.description')}
                </DialogContentText>
                <TextField
                  value={emails || ''}
                  autoFocus
                  margin="dense"
                  id="emails"
                  name="emails"
                  // size="small"
                  error={emails != undefined ? isEmail(emails) : false}
                  label={`${I18n.t('patient.form.email')}`}
                  type="email"
                  fullWidth
                  onChange={(event) => {
                    setEmails(event.target.value)
                  }}
                />
                <Box style={{ minHeight: 0, height: 5 }}>
                  {emails != undefined && isEmail(emails) ? (
                    <Typography color="error" variant="caption">
                      {I18n.t('validators.invalidEmail')}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  padding="15px"
                  onClick={handleCloseDialog}
                  color="primary"
                >
                  {I18n.t('components.cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={emails ? isEmail(emails) : true}
                >
                  {I18n.t('components.send')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Dialog>
        <SnackBar
          text={snackBar.text}
          severity={snackBar.severity}
          show={showSnackBar}
          HandleShow={handleSnackBar}
          time={3000}
        />
      </Grid>
    )
  }

  return renderBody()
}

export default DialogPdf
