import { useTheme } from '@mui/material'
import { colors } from '../../utils'

const useStyles = () => {
  const { breakpoints } = useTheme()
  return {
    root: {
      margin: '32px 0',
      border: colors.greyDarken + ' solid 1px',
      borderRadius: '5px',
      padding: '16px 16px',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    body: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      columnGap: '48px',
      paddingTop: '16px',
      [breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        columnGap: '0',
      },
    },
    kitContainer: {
      marginBottom: '16px',
     
      ['& .MuiIconButton-label']: {
        color: colors.danger,
      },
      [breakpoints.down('md')]: {
        width: '100%',
        marginBottom: '16px',
      },
    },
    cardHeader: { padding: '12px 16px',},
    edit: {
      ['& .MuiIconButton-label']: {
        color: colors.blueLighten,
      },
    },
    infoText: {
      display: 'flex',
      color: colors.blueLighten,
      alignItems: 'center',
      '&:first-child': { marginBottom: '8px' },
      '&:last-child': { marginTop: '8px' },
      ['& img']: {
        marginRight: '8px',
      },
      textOverflow: 'ellipsis',
    },
  }
}

export { useStyles }
