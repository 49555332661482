import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('indicators')
  return res
}
const show = async (id) => {
  const res = await GET(`indicators/${id}`)
  return res
}
const create = async (body) => {
  const res = await POST('indicators', body)
  return res
}

const edit = async (body, id) => {
  const res = await PUT(`indicators/${id}`, body)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`indicators/${id}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
  show,
}
