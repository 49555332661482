import { makeStyles } from '@mui/styles'
import colors from '../utils/colors'

const customeTheme = {
  contained: (color) => {
    return {
      background: color.primary,
      color: `${color.white}`,
      '& .MuiIcon-root': {
        marginLeft: '15px',
        background: `${color.white}66`,
        height: 'max-content',
      },
      '&:hover': {
        background: `${color.primary}ee`,
      },
      '& :disabled': {
        background: `${color.disabled}33`,
        color: `${color.disabled}33`,
        '& .MuiIcon-root': {
          background: `${color.disabled}33`,
        },
      },
      '&:disabled': {
        background: `${color.disabled}33`,
        color: `${color.disabled}33`,
        '& .MuiIcon-root': {
          background: `${color.disabled}33`,
        },
      },
      '& .MuiSvgIcon-root': {
        fontWeight: '600',
        fontSize: '20px',
        color: color.white,
      },
    }
  },
  outlined: {
    color: colors.blueInput,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.blueInput,
    border: `1px solid ${colors.blueInput}`,
    '&:hover': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: `${colors.blueInput}ee`,
      color: `${colors.blueInput}ee`,
      border: `1px solid ${colors.blueInput}`,
    },
  },
  text: (color) => {
    return {
      color: color.primary,
      '&:hover': {
        color: `${color.primary}ee`,
      },
      '& :disabled': {
        color: `${color.disabled}33`,
        '& .MuiIcon-root': {
          color: `${color.disabled}33`,
        },
      },
      '&:disabled': {
        color: `${color.disabled}33`,
        '& .MuiIcon-root': {
          color: `${color.disabled}33`,
        },
      },
      '& .MuiSvgIcon-root': {
        fontWeight: '600',
        fontSize: '20px',
        color: color.primary,
      },
    }
  },
}
const general = () => {
  return {
    width: {
      lg: 'fit-content',
      xl: 'fit-content',
      md: '100%',
      sm: '100%',
      xs: '100%',
    },
    fontSize: '13px',
    lineHeight: '.8',
  }
}

const buttonStyle = ({ colors, text, loading }) => {
  return {
    [loading ? '&.MuiLoadingButton-root' : '&.MuiButton-root']: {
      ...general({ color: colors, text }),
    },
  }
}

const globalStyles = {
  generalOutlined: general({
    color: colors.blueInput,
    text: true,
    outlined: true,
  }),
  sendSubmit: buttonStyle({ colors: colors.blueInput }),
  loading: buttonStyle({ colors: colors.blueInput, loading: true }),
  errorButton: buttonStyle({ colors: colors.danger }),
  textButton: buttonStyle({ colors: colors.blueInput, text: true }),
}

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  downloadZip: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    margin: 'auto',
    marginBottom: '20px',
  },
  buttongeneral: {
    '&.MuiLoadingButton-root': {
      background: 'transparent',
      borderRadius: '4px',
      // color: colors.primary,
      fontSize: '12px',
      textTransform: 'capitalize',
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  inputWidth: {
    width: '24%',
    margin: '8px 0',
    [theme.breakpoints.down('md')]: {
      width: '49%',
    },
  },
  imageProfile: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    marginRight: '10px',
    objectFit: 'cover',
    border: `1px solid ${colors.disabledBorder}`,
  },
  date: {
    '&.MuiFormControl-root.MuiTextField-root': {
      width: 200,
    },
    '& .MuiOutlinedInput-input': {
      // height: 8,
      fontSize: '.9em',
      // color: colors.greyPlaceholder
    },
    '& .MuiInputLabel-root': {
      // top: -5,
      // color: colors.greyPlaceholder,
    },
  },
  btn: {
    '&.MuiButton-root': {
      marginLeft: 10,
      borderRadius: 0,
      textTransform: 'none',
      boxShadow: 'none',
    },
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      maxWidth: 500,
    },
    '& .MuiDialogTitle-root': {
      padding: '16px 44px',
    },
    '& .MuiDialogContent-root': {
      padding: '10px 44px',
      borderBottom: `1px solid ${colors.disabledBorder}`,
      borderTop: `1px solid ${colors.disabledBorder}`,
    },
    '& .MuiDialogActions-root': {
      padding: '10px 34px',
    },
  },
  btnContainer: {
    margin: '16px 0',
    display: 'flex',
    gap: 10,
    '& .MuiIconButton-root': {
      boxShadow: `0 0 2px ${colors.greyPlaceholder}`,
      borderRadius: 5,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2em',
    },
  },
  pdf: {
    '&.MuiIconButton-root': {
      color: colors.alert,
      padding: 20,
      display: 'flex',
      gap: 10,
    },
  },
  pdfText: {
    '&.MuiTypography-root': {
      fontSize: '.85rem',
      color: colors.black,
    },
  },
  deleteBtn: {
    '&.MuiButton-root': {
      marginLeft: 10,
      borderColor: colors.blueLighten,
      borderRadius: 0,
      textTransform: 'none',
      boxShadow: 'none',
      minWidth: 30,
    },
  },
  paddingbtn: {
    '&.MuiButton-root': {
      padding: 7,
    },
  },
  searchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 50,
  },
  vdsiSearchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 20,
  },
  search: {
    flexGrow: 1,
  },
  spacing: {
    '&.MuiFormControl-root.MuiTextField-root': {
      marginLeft: 10,
    },
  },
  root: {
    width: '95vw',
    margin: 'auto',
  },
  positionRelative: {
    position: 'relative',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  observationInput: {
    '&.MuiTextField-root': {
      width: '90%',
    },
  },
  iconAdd: {
    '&.MuiIconButton-root': {
      marginTop: -5,
      marginLeft: 5,
      '& .MuiSvgIcon-root': {
        color: colors.primaryLighten,
        fontSize: '1.4em',
      },
    },
  },
  mainContainer: {
    padding: '30px',
  },
  subTitleVdsi: {
    '&.MuiTypography-root': {
      width: '100%',
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 10,
    },
  },
  vdsiContainer: {
    padding: '30px',
    width: '70%',
    margin: 'auto',
  },
  cardContainer: {
    padding: '30px 20px',
    margin: '10px',
  },
  minHeightLoading: {
    minHeight: '20vh',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollableContainer: {
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'visible',
  },
  chipContainer: {
    padding: '10px',
    textAlign: 'center',
    margin: '5px',
  },
  sbContainer: {
    '& div': {
      '& div': {
        textAlign: 'center',
        fontWeight: 600,
      },
    },
  },
  sliderContainer: {
    padding: '30px 30px 0 30px',
  },
  indicatorContainer: {
    padding: '15px',
    minHeight: 45,
    background: colors.disabled,
  },
  titleDialog: {
    flex: '1',
    marginLeft: '1rem',
    color: '#fff',
  },
  textAlign: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subTitle: {
    '&.MuiTypography-root': {
      fontWeight: 400,
      color: colors.breadcrumColor
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dFlex: {
    display: 'flex',
  },
  noneShadow: {
    boxShadow: 'none',
  },
  mauto: {
    margin: '0 auto',
  },
  mbxs: {
    marginBottom: '5px',
  },
  mbsm: {
    marginBottom: '10px ',
  },
  mbmd: {
    marginBottom: '15px ',
  },
  mblg: {
    marginBottom: '20px ',
  },
  mbxl: {
    marginBottom: '30px ',
  },
  mbxlGrid: {
    '&.MuiGrid-root': {
      marginBottom: '2rem',
    },
  },
  mbxxl: {
    marginBottom: '50px ',
  },
  mrxs: {
    marginRight: '5px',
  },
  mrsm: {
    marginRight: '10px',
  },
  mrmd: {
    marginRight: '15px',
  },
  mrlg: {
    marginRight: '20px',
  },
  mrxl: {
    marginRight: '30px',
  },
  pdxs: {
    padding: '5px',
  },
  ptxxl: {
    paddingTop: '40px ',
  },

  fontColorPrimary: {
    color: colors.primary,
  },
  fontColorSecondary: {
    color: colors.secondary,
  },
  fontColorSuccess: {
    color: colors.success,
  },
  fontColorInfo: {
    color: colors.info,
  },
  fontColorInfoDarken: {
    color: colors.infoDarken,
  },
  fontColorWarning: {
    color: colors.warning,
  },
  fontColorDanger: {
    color: colors.danger,
  },
  fontColorDangerLighten: {
    color: colors.dangerLighten,
  },
  fontColorDisabled: {
    color: colors.disabled,
  },
  fontColorWhite: {
    color: colors.white,
  },
  fontColorDisabledLighten: {
    color: colors.disabledLighten,
  },
  fontWeightBold: {
    fontWeight: 800,
  },
  borderBottomSuccess: {
    borderBottom: '4px solid ' + colors.success,
  },
  borderBottomDanger: {
    borderBottom: '4px solid ' + colors.danger,
  },
  borderBottomWarning: {
    borderBottom: '4px solid ' + colors.warning,
  },
  borderBottomDisabled: {
    borderBottom: '4px solid ' + colors.disabled,
  },
  backgroundColorWhite: {
    backgroundColor: '#ffffff',
  },
  backgroundColorPrimary: {
    backgroundColor: colors.primary,
  },
  backgroundColorSecondary: {
    backgroundColor: colors.secondary,
  },
  backgroundColorSuccess: {
    backgroundColor: colors.success,
  },
  backgroundColorInfo: {
    backgroundColor: colors.info,
  },
  backgroundColorWarning: {
    backgroundColor: colors.warning,
  },
  backgroundColorDangerCare: {
    backgroundColor: colors.dangerCare,
  },
  backgroundColorDanger: {
    backgroundColor: colors.danger,
  },
  backgroundColorWarningOrange: {
    backgroundColor: colors.warningOrange,
  },
  backgroundColorDisabled: {
    backgroundColor: colors.disabled,
  },
  backgroundColorDisabledLigthen: {
    backgroundColor: colors.disabledLighten,
  },
  mapMarker: {
    backgroundColor: colors.secondary,
    color: 'white',
    padding: '5px',
    display: 'inline-flex',
    textAlign: 'left',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
  },
  tightText: {
    lineHeight: '1em ',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  circledText: {
    borderRadius: '50%',
    width: '1.5rem',
    height: '1.5rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    color: colors.white,
    fontSize: '10px',
  },
  indicatorIcon: {
    fontSize: '120px',
    marginLeft: '-20px',
    padding: '10px 0',
    display: 'inline-block',
    height: '1em',
    overflow: 'visible',
    verticalAlign: '-0.125em',
  },
  indicatorIconKforce: {
    '&.MuiSvgIcon-root': {
      width: '65px',
      fontSize: '80px',
      padding: '10px 0',
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  indicatorKforce: {
    width: '70px',
    height: '80px',
    fontSize: '40px',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    borderRadius: '100px 0 0 100px',
  },
  kforceGood: {
    backgroundColor: colors.primary,
    color: 'white',
  },
  kforceOk: {
    backgroundColor: colors.success,
    color: 'white',
  },
  kforceWarning: {
    backgroundColor: colors.warning,
    color: 'white',
  },
  kforceCare: {
    backgroundColor: colors.care,
    color: 'white',
  },
  kforceDanger: {
    backgroundColor: colors.danger,
    color: 'white',
  },
  indicatorImgTable: {
    width: '2rem',
    pointerEvents: 'none',
  },
  descriptionGuion: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  indicatorBatteryIcon: {
    fontSize: '150px',
    marginLeft: '-50px',
    width: '125px',
  },
  indicatorHeight: {
    minHeight: '150px',
  },
  timeLineValuesIndicator1: {
    flexGrow: '0',
    maxWidth: '10.6667%',
    flexBasis: '10.66667%',
    textAlign: 'center',
  },
  timeLineValuesIndicator2: {
    flexGrow: '0',
    maxWidth: '28.6667%',
    flexBasis: '28.66667%',
    textAlign: 'center',
  },
  tooltipIndDescription: {
    maxWidth: '300',
    background: '#fff',
    borderColor: '#000',
    border: '1px solid',
    color: '#000',
    fontSize: '.8rem',
  },
  tooltipDescription: {
    border: '0',
    borderColor: '#fff',
    marginBottom: '1rem',
  },
  IconRotatedIndicators: {
    transform: 'rotate(-45deg)',
    fontSize: '25px',
    paddingRight: '5px',
    position: 'relative',
    width: '1em ',
  },
  textIndicators: {
    fontSize: '0.8rem',
  },
  hideOverflow: {
    overflow: 'hidden',
  },
  equalLineHeight: {
    '& p': {
      lineHeight: '26px',
    },
    '& svg': {
      minHeight: '26px',
    },
  },
  getupColor: {
    color: '#FFCD00',
  },
  sleepColor: {
    color: colors.primary,
  },
  sizeImgIndicator: {
    width: '50px',
    zIndex: '0',
  },
  bathroomColor: {
    color: '#2B78E4',
  },
  underlineSeparator: {
    borderBottom: '1px solid',
  },
  cardColorSuccess: {
    '&.MuiPaper-root': {
      backgroundColor: colors.successLighten + ' ',
      color: colors.success + ' ',
    },
  },
  cardColorWarning: {
    '&.MuiPaper-root': {
      backgroundColor: colors.info + ' ',
      color: colors.infoDarken + ' ',
    },
  },
  cardColorDanger: {
    '&.MuiPaper-root': {
      backgroundColor: colors.dangerLighten + ' ',
      color: colors.danger + ' ',
    },
  },
  cardColorDisabled: {
    '&.MuiPaper-root': {
      backgroundColor: colors.disabledLighten + ' ',
      color: colors.disabled + ' ',
    },
  },
  popoverImgIndicator: {
    maxWidth: '30rem',
  },
  containerImgIndTable: {
    display: 'inline-block',
    marginRight: '5px',
    marginTop: '5px',
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  //new IDP styles
  breadcrum: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttomsBreadcrumb: {
    ['&:last-child']: { marginLeft: '8px' },
    '&.MuiButton-root': {
      padding: '13px 15px',
      height: 'fit-content',
    },
  },
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  labelsContainer: { display: 'flex', width: '100%', position: 'relative' },
  labels: {
    position: 'relative',
    left: '-24px',
  },
  preLastLabel: { position: 'relative', left: '-13px' },
  lastLabel: { position: 'absolute', right: '-24px' },

  balanceTabs: {
    display: 'flex',
    alignItems: 'center',
    ['& svg']: { marginRight: '8px', fontSize: '20px' },
  },
  firstIndicatorsContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '32px',
    rowGap: '24px',
    marginBottom: '30px',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
      rowGap: '24px',
    },
  },
  firstIndicatorsContainerSmartDetect: {
    '&.MuiGrid-root': {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '32px',
      rowGap: '24px',
      marginBottom: '30px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        rowGap: '24px',
      },
    },
  },
  eyesContainer: {
    display: 'grid',
    columnGap: '16px',
    rowGap: '16px',
    marginBottom: '24px',
    gridTemplateColumns: ' 1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: { gridTemplateColumns: '1fr 1fr' },
  },
}))

export default useStyles

export const useSxStyles = ({ select }) => {
  return {
    questionnaireDates: {
      minWidth: '150px',
      marginTop: '1px',
      background: colors.white,
      '& fieldset': {
        top: select ? '0' : '-5px',
        '& legend': {
          display: select ? 'none' : 'block',
        },
      },
      '& .MuiInputLabel-root': {
        // transform: select ? 'none' : 'translate(14px, 7px) scale(1)',
        '&.Mui-focused': {
          // transform: 'translate(14px, -9px) scale(.75)',
        },
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '0px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '7.5px 14px',
      },
    },
    downloadQuestionnaires: {
      background: colors.blueInput,
      color: colors.white,
      borderRadius: '0px',
      padding: '10px',
      paddingTop: '9px',
      marginTop: '1px',
      fontSize: '1.2rem',
      '&.Mui-disabled': {
        backgroundColor: colors.disabledBorder,
      },
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
      '&:hover': {
        background: `${colors.blueInput}ee`,
      },
    },
    boxDownload: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'end',
      flexWrap: 'wrap',
      rowGap: '10px',
    },
    selectDate: {
      width: '190px',
    },
    selectDateText: {
      fontSize: '12px',
      marginBottom: '5px',
      color: colors.disabled,
    },
  }
}

export { globalStyles, customeTheme, general }
