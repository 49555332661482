import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: '-24px',
    marginRight: '-24px',
    minHeight: '60vh',
    boxShadow: `0 1px 2px ${colors.greyDarken}, 0 -1px 2px ${colors.greyDarken}`,
    marginBottom: '-24px',
    [theme.breakpoints.down('md')]: {
      boxShadow: 'none',
      flexDirection: 'column',
    },
  },
  tabsComponent: {
    borderRight: colors.disabledLighten2 + ' solid 1px',
    '& .MuiTabs-indicator': {
      left: 0,
      width: 4
    }
  },
  TabContainer: {
    flex: 1,
    padding: '16px 30px',
    [theme.breakpoints.down('md')]: {
      marginTop: '24px',
    },
  },
  tab: {
    '&.MuiTab-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
        marginLeft: '14px',
        alingItems: 'center',
        width: 'auto',
        ['& svg']: {
          marginTop: '5px',
          marginRight: '5px',
          padding: 0,
        },
   
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
  },
}))
export default useStyles
