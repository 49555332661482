import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoContainer: {
    margin: '24px 0',
  },
  info: {
    '& .MuiOutlinedInput-root': {
      color: colors.disabledDarken,
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '1%',
    },
  },
  inputsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginBottom: '16px',
  },
  selectInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectInput: {
    display: 'flex',
    alignItems: 'center',
  },
  labelContainer: {
    marginRight: '24px',
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
  },
  label: { 
    '&.MuiTypography-root': {
      marginLeft: '16px'
    }
   },
  inputContainer: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
  },
  form: {
    background: colors.white,
    width: '100%',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
}))

export default useStyles
