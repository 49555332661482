import { Box, Button, Icon, Typography } from '@mui/material'
import { useStyles } from './styles'
import { Icons } from '../../utils'
import { AuthLayout } from '../authLayout'
import { removeLogOutModal } from './methods'

const LogOutModal = () => {
  const styles = useStyles()

  const logOut = () => {
    removeLogOutModal()
  }

  return (
    <AuthLayout container={styles.container} formContainer={styles.modal}>
      <Box sx={styles.actions}>
        <Typography sx={styles.title}>La session a expiré</Typography>

        <Typography sx={styles.subtitle}>
          Veuillez vous reconnecter pour des raisons de sécurité
        </Typography>

        <Button
          variant="contained"
          sx={styles.submit}
          onClick={() => {
            logOut()
          }}
        >
          ALLER Á LA CONNEXION
          <Icon>{Icons('arrow_forward')}</Icon>
        </Button>
      </Box>
    </AuthLayout>
  )
}

export { LogOutModal }
