import { colors } from "../../../../../../../utils"

const useDetailModalStyles = () => {
    return {
        container: ({ haveData }) => ({
            maxHeight: '90vh',
            overflow: 'auto',
            width: haveData ? '80vw' : 'auto',
            minWidth: '700px',
            padding: '0'
        }),
        body: () => ({
            borderTop: `1px solid ${colors.greyStrong}`,
            paddingRight: '24px',
            paddingLeft: '24px',
            // paddingBottom: '24px'
        }),
        image: {
            width: '100%',
            // maxWidth: '400px'
        },
        separator: {
            borderLeft: `1px solid ${colors.greyStrong}`,
            paddingBottom: '24px'
        },
        name: {
            color: colors.disabled,
            display: 'block',
            width: 'calc(100% - 20px)',
            paddingBottom: '20px',
            wordBreak: 'break-word',
        },
        titleSection: {
            width: '100%',
            fontWeight: 500,
            margin: '10px 0',
            color: colors.disabled,
            borderBottom: `1px solid ${colors.greyDarken}`,
        },
        removeTitle: {
            marginTop: 0
        }
    }
}

export { useDetailModalStyles }
