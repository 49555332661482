import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { Loader, CardSmartPredict } from '../../../../components'
import useStyles from '../../../../styles'

function FirtsIndicators(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [indicatorsfirts, setFirts] = useState([])
  // const indicatorTemporal =
  //   props?.temporalParameters?.find((parameter) => parameter?.id == 324) || {}
  
  useEffect(() => {
    async function onLoad() {
      try {
        setIsLoading(true)
        if ( /* props.firstIndicators &&  */props.tugRisque) {
          let firtsData = [props.tugRisque]
          
          // if (Object.values(indicatorTemporal).length > 0) {
          //   firtsData.push(indicatorTemporal)
          // }
          setFirts(firtsData.filter((data) => data.id != 320))
        }
      } catch (e) {
        //error
      }
      setIsLoading(false)
    }
    onLoad()
  }, [props.date])
  let cardsData = {
    itemOne: {
      graph: props?.firstIndicators?.graph,
      value: props?.firstIndicators?.value,
      icon: <FontAwesomeIcon icon={faClock} />,
      evolution_graph: props?.firstIndicators?.evolution_graph
    },
    itemTwo: {
      icon: <FontAwesomeIcon icon={faExclamationCircle} />,
    },
  }
  indicatorsfirts.map((indicator) => {
    if (indicator.id == 298) {
      cardsData.itemTwo = {
        ...cardsData.itemTwo,
        name: indicator.name,
        value: indicator.value,
        status: indicator.status,
        id: indicator.id,
        graph: indicator.graph
      }
      }
    }
  )

  function renderFirtsIndicators() {
    return (
      <Box className={classes.firstIndicatorsContainer}>
        {Object.values(cardsData).map((data, index) => (
          <CardSmartPredict key={index} data={data} onClick={props.onClick}/>
        ))}
      </Box>
    )
  }

  function renderBody() {
    return <>{renderFirtsIndicators()}</>
  }

  return isLoading ? <Loader /> : renderBody()
}

export default FirtsIndicators
