import { makeStyles } from '@mui/styles'
import { colors } from '../../../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerSections: {
    flex: 1,
    '&:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
    overflow: 'hidden'
  },
  between: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonsContainer: { display: 'flex', columnGap: '16px' },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '150px',
    alignItems: 'center',
    width: '40%',
    margin: '0 auto',
    padding: '32px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  chekCircle: {
    color: colors.lightPrimary,
    '&.MuiSvgIcon-root': {
      fontSize: '50px',
    },
  },
}))

export default useStyles
