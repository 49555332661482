import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  container: {
    border: colors.greyFull + ' solid 1px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerContainer: {
    background: colors.greyLighten,
    position: 'absolute',
    marginTop: '-11px',
    marginLeft: '9px',
    padding: ' 0 4px',
  },
  headertitle: {
    "&.MuiTypography-root": {
    fontSize: '.72rem',
    color: colors.greyFull,
    },
  },
  bodyContainer: {
    marginTop: '15px',
    marginLeft: '15px',
    marginBottom: '4px',
  },
  footerContainer: {
    color: colors.greyFull,
    marginLeft: '15px',
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    color: colors.disabled,
    marginLeft: '16px',
  },
  icon: {
    marginRight: '8px',
    "&.MuiSvgIcon-root": {
    fontSize: '19px',
    },
  },
  bodyContainerForm: {
    marginTop: '15px',
    marginLeft: '15px',
    marginBottom: '4px',
    display:'flex',
    alignItems: 'center'
  },
})

export default useStyles
