import contextkeys from '../../utils/contextkeys'

export const initialState = localStorage.getItem('state')
  ? JSON.parse(localStorage.getItem('state'))
  : {}

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case contextkeys.requestLogin:
      return {
        ...state,
      }
    case contextkeys.loginSuccess:
      return {
        ...state,
        ...action.payload,
      }

    case contextkeys.logout:
      return {}

    case contextkeys.loginError:
      return {
        ...state,
        error: action.payload,
      }

    case contextkeys.getUser:
      return {
        ...state,
        user: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
