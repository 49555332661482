import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'
const useStyles = makeStyles((theme) => ({
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  titleBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    color: colors.lightPrimary,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {},
  },
  bodyContainer: {
    marginTop: '32px',
  },
  itemText: {
    width: '95%',
    wordBreak: 'break-word'
  },
  paper: { padding: '16px', marginBottom: '16px', borderRadius: '2px' },
  titleContainer: {
    width: '29.5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: '16px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginTop: '8px',
    },
  },
  informationCenter: {
    margin: '8px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: '24px',
    [theme.breakpoints.down('md')]: { gridTemplateColumns: '1fr 1fr' },
  },
  item: {
  },
  medicalContacts: {
    margin: '32px 0',
  },
  iframe: {
    '-moz-transform': 'scale(1)',
    '-moz-transform-origin': '0 0',
    '-o-transform': 'scale(1)',
    '-o-transform-origin': '0 0',
    '-webkit-transform': ' scale(1)',
    '-webkit-transform-origin': '0 0',
    width: '100%',
    height: '250px',
    zoom: '1',
    border: '1px solid #888',
    overflow: 'hidden',
    borderRadius: 4,
  },
  fullIframe: {
    '-moz-transform': 'scale(.9)',
    '-moz-transform-origin': '0 0',
    '-o-transform': 'scale(.9)',
    '-o-transform-origin': '0 0',
    '-webkit-transform': ' scale(.9)',
    '-webkit-transform-origin': '0 0',
    width: '110%',
    height: '100%',
    zoom: '1',
    border: '1px solid #888',
    overflow: 'hidden',
    borderRadius: 4,
  },
  gridLegalDocument: {
    '&.MuiGrid-item': {
      maxHeight: '350px',
      marginBottom: '16px',
      overflow: 'hidden',
      position: 'relative',
      paddingBottom: 20,
    },
  },
  fullscreen: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      bottom: '30px',
      right: '30px',
    },
  },
  fullscreenExit: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      top: '30px',
      right: '30px',
    },
  },
  btnFile: {
    '&.MuiButtonBase-root': {
      borderRadius: '4px',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  medicalStaff: {
    marginBottom: '40px',
  },

  dataGrid: {
    marginTop: '16px',
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  addressContainer: {
    margin: '24px 0',
  },
  mapContainer: {
    marginTop: '16px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  cancel: {
    color: colors.blueLighten,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  completeCard: {
    width: '100%',
  },
  contactsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    margin: '24px 0',
  },
  listContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listContainer3fr: {
    display: 'grid',
    flexWrap: "wrap",
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  summary: {
    '&.MuiButtonBase-root.MuiAccordionSummary-root': {
      padding: '5px 16px',
    },
  },
  accordionDetails: {
    // [theme.breakpoints.up('lg')]: {
    //   padding: '8px 16px',
    // },
    '&.MuiAccordionDetails-root': {
      maxHeight: '230px',
    },
  },
  listItem: {
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  containerDocument: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 300,
    boxShadow: `0 0 4px ${colors.greyStrong}`,
    padding: '2px 0px 2px 0',
  },
  nameDocument: {
    '&.MuiTypography-root': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  iconDocument: {
    '&.MuiIconButton-root': {
      padding: 4,
      marginRight: 5,
      '& .MuiSvgIcon-root': {
        fontSize: 35,
        paddingLeft: '0px',
        color: colors.greyStrong
      }
    },
  },
  containerNameDocument:{
    paddingTop: '5px',
  },
  btnViewDocument: {
    '&.MuiButton-root': {
      padding: '5px 0'

    },
    '&.MuiButtonBase-root': {
      // color: colors.white,
      // '& .MuiSvgIcon-root': {
      //   color: colors.white,
      // },
    },
  },
  sticker: {
    borderRadius: '4px',
    padding: '3px 10px 3px 10px',
    fontSize: '.75rem',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    lineHeight: 1,
    color: colors.white,
    position: 'relative',
    top: '2px',
    background: colors.disabledLighten2,
    display: 'inline-block',
    maxWidth: '250px',
    ['& p']: {
      fontSize: '.72rem',
    },
  },
}))

export default useStyles
