import { colors } from '../../utils'

const useStyles = () => {
  return {
    container: () => {
      return {
        height: '100%',
        width: '100%',
        padding: '0 5px',
        background: colors.disabledDarken2,
        display: 'flex',
        gap: "5px",
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .progress-bar': {
          cursor: 'pointer',
          background: `${colors.white}66`,
          height: '3px',
          borderRadius: '40px',
          overflow: 'hidden',
          width: 'calc(100% - 25px)',
        },
      }
    },
    icon: {
      width: '15px',
      height: '15px',
      background: colors.white,
      '&:hover': {
        background: `${colors.white}dd`,
      },
      borderRadius: '50%',
      fontSize: '15px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  }
}

export { useStyles }
