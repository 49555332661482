import { makeStyles } from '@mui/styles'

export const useStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}))
