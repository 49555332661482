import { Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import useStyles from './style'

const SnackBar = ({ text, severity, show, HandleShow, time, anchor }) => {
  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: anchor?.vertical ?? 'top',
        horizontal: anchor?.horizontal ?? 'center',
      }}
      open={show}
      autoHideDuration={time}
      onClose={() => {
        HandleShow(false)
      }}
      className={classes.alert}
    >
      <Alert severity={severity}>{text}</Alert>
    </Snackbar>
  )
}

export default SnackBar
