import { makeStyles } from '@mui/styles'
import colors from '../../../../utils/colors'

const useStyles = makeStyles((theme) => ({
  //cards
  cardsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    position: 'relative',
  },
  summary: {
    ['& .MuiAccordionSummary-content']: { margin: '8px 0' },
  },
  breakWord: {
    '&.MuiTypography-root': {
      wordBreak: 'break-all'
    }
  },
  cardTitle: {
    padding: '0',
  },
  completeCard: {
    width: '49%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '16px',
    },

    ['&:last-child']: {
      width: '100%',
      marginTop: '16px',
    },
  },

  //list
  listContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },

  sticker: {
    borderRadius: '4px',
    padding: '3px 10px 3px 10px',
    fontSize: '.75rem',
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    lineHeight: 1,
    color: colors.white,
    position: 'relative',
    top: '2px',
    background: colors.disabledLighten2,
    display: 'inline-block',
    maxWidth: '250px',
    ['& p']:{
      fontSize:'.72rem'
    }
  },
  primary: { background: colors.primary },
  title: {
    display: 'flex',
    alignItems: 'start',
    ['& span']: { marginLeft: '5px' },
    ['& img']: { marginLeft: '5px' },
  },
  accordionDetails: {
    [theme.breakpoints.up('lg')]: {
      padding: '8px 16px',
    },
  },
  listItem: {
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },

  //documents
  buttonDocument: {
    textTransform: 'none',
    ['&:last-child']: { marginLeft: '8px' },
  },
}))

export default useStyles
