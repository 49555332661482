import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const profileStyles = makeStyles({
  tableCell: {
    width: '50%',
    borderRight: `8px solid ${colors.greyLighten}`,
    padding: '8px 0 4px 16px',
    verticalAlign: 'top',
  },
})

export default profileStyles
