export const rolStaff = [
  {
    id: 1,
    name: 'home',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 2,
    name: 'medicalStaff',
    actions: [1, 2],
  },
  {
    id: 3,
    name: 'medicalCenter',
    actions: [6],
  },
  {
    id: 18,
    name: 'patients',
    actions: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13, 14],
  },
  {
    id: 22,
    name: 'myProfile',
    actions: [4],
  },
]

// {
//   id: 19,
//   name: 'homePredict',
//   actions: [1, 2, 6, 7],
// },
