import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Checkbox,
  IconButton,
} from '@mui/material'
import Pagination from '@mui/lab/Pagination'
import I18n from 'i18n-js'
import images from '../../utils/Images'
import SearchBar from '../searchBar'
import Icons from '../../utils/icons'
import useStyles from './style'

const ProfileSettingsForm = ({
  values,
  setCreateProfil,
  dataIndicators,
  helpMessage = null,
  text = true
}) => {
  const [data, setData] = useState([])
  const [elements, setElements] = useState([])
  const [selected, setSelected] = useState([])
  const [paginationOne, setPaginationOne] = useState(0)
  const [paginationTwo, setPaginationTwo] = useState(0)
  const [search, setSearch] = useState({ target: '' })
  const [page, setPage] = useState(0)
  const numberOfPages = Math.ceil(elements.length / 5)
  const numberOfPagesSelected = Math.ceil(selected.length / 5)
  const itemsPerpage = 5
  
  //getData
  const getData = async () => {
    if (values.indicators) {
      const items = dataIndicators.map((indicator) => {
        if (values.indicators.find((value) => value == indicator.id)) {
          indicator.selected = true
        } else {
          indicator.selected = false
        }
        return indicator
      })
      setData(items)
      setElements(items.filter((dat) => !dat.selected))
      setSelected(items.filter((dat) => dat.selected))
    } else {
      const items = dataIndicators.map((indicator) => {
        indicator.selected = false
        return indicator
      })
      setData(items)
      setElements(items.filter((item) => !item.selected))
    }
  }
  //searchBar
  const handleChangeSearch = (e) => {
    setSearch({ target: { value: e.target.value } })
    const searcheableData = data.filter((dat) => {
      if (!selected.find((sele) => sele.id == dat.id)) {
        return dat
      }
    })

    if (e.target.value) {
      const results = searcheableData.filter((dat) =>
        dat.name?.toLowerCase().includes(e.target.value.trim().toLowerCase())
      )
      setElements(results)
      setPaginationOne(0)
    } else {
      setElements(searcheableData)
      setPaginationOne(page)
    }
  }
  const handleCheckstate = (state, type = 0, id = null) => {
    if (type == 0) {
      return data.map((item) => {
        item.selected = state
        return item
      })
    } else if (type == 1) {
      return data.map((item) => {
        if (!selected.find((e) => e.id == item.id)) {
          item.selected = state
        }
        return item
      })
    } else if (type == 2) {
      return data.map((item) => {
        if (item.id == id) {
          item.selected = state
        }
        return item
      })
    }
  }

  //select all items
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setData(handleCheckstate(true))
      if (selected.length == 0) {
        setElements(handleCheckstate(true))
      } else {
        const selection = handleCheckstate(true).filter((select) => {
          if (!selected.find((e) => e.id == select.id)) {
            return select
          }
        })
        setElements(selection)
      }
    } else if (selected.length == 0) {
      setData(handleCheckstate(false))
      setElements(handleCheckstate(false))
    } else {
      const isSelected = handleCheckstate(false, 1)
      const selection = isSelected.filter((select) => {
        if (!selected.find((e) => e.id == select.id)) {
          return select
        }
      })
      setData(isSelected)
      setElements(selection)
    }
    handleChangeSearch(search)
  }
  //select one item
  const handleCheckOne = (e) => {
    if (selected.length == 0) {
      if (e.target.checked) {
        setData(handleCheckstate(true, 2, e.target.value))
        setElements(handleCheckstate(true, 2, e.target.value))
      } else {
        setData(handleCheckstate(false, 2, e.target.value))
        setElements(handleCheckstate(false, 2, e.target.value))
      }
    } else {
      if (e.target.checked) {
        const isSelected = handleCheckstate(true, 2, e.target.value)
        const selection = isSelected.filter((select) => {
          if (!selected.find((e) => e.id == select.id)) {
            return select
          }
        })
        setData(isSelected)
        setElements(selection)
      } else {
        const isSelected = handleCheckstate(false, 2, e.target.value)
        const selection = isSelected.filter((select) => {
          if (!selected.find((e) => e.id == select.id)) {
            return select
          }
        })
        setData(isSelected)
        setElements(selection)
      }
    }
    handleChangeSearch(search)
  }
  //paginations of tables
  const handleChangePaginationOne = (e, value) => {
    setPaginationOne(value - 1)
    setPage(value - 1)
  }
  const handleChangePaginationTwo = (e, value) => {
    setPaginationTwo(value - 1)
  }
  //button to send item
  const handleSubmitSelection = () => {
    setElements(data.filter((dat) => !dat.selected))
    setSelected(data.filter((dat) => dat.selected))
    setCreateProfil({
      ...values,
      indicators: data
        .filter((dat) => dat.selected)
        .map((e) => {
          return e.id
        }),
    })
    document.getElementById('search').value = ''
    setPaginationOne(0)
    setPaginationTwo(0)
  }
  const handleDeleteSelection = (id) => {
    const isSelected = data.map((item) => {
      if (item.id == id) {
        item.selected = false
      }
      return item
    })
    setData(isSelected)
    setElements(isSelected.filter((select) => !select.selected))
    setSelected(isSelected.filter((dat) => dat.selected))
    setCreateProfil({
      ...values,
      indicators: isSelected
        .filter((dat) => dat.selected)
        .map((select) => {
          return select.id
        }),
    })
  }
  useEffect(() => {
    return getData()
  }, [])
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.titleBarContainer}>
        {helpMessage !== null && <Typography variant="body2" className={classes.help}>{helpMessage}</Typography>}
        <Box className={classes.titleContainer}>
          <Typography variant="subtitle1" color="textPrimary">
            {text ? I18n.t('cruds.profiles.create.indicatorsTitle') : ''}
          </Typography>
        </Box>
        <Box className={classes.barContainer}>
          <SearchBar small change={handleChangeSearch} />
        </Box>

      </Box>
      <Box className={classes.InfoContainer}>
        <Box className={classes.tableSelection}>
          <Table size="small" className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    onChange={handleSelectAll}
                    indeterminate={
                      elements.find((element) => element.selected) &&
                      elements.find((element) => !element.selected)
                        ? true
                        : false
                    }
                    disabled={elements.length == 0}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {I18n.t('cruds.profiles.create.indicators')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {elements.length > 0 &&
                elements
                  .slice(
                    paginationOne * itemsPerpage,
                    paginationOne * itemsPerpage + itemsPerpage
                  )
                  .map((value, index) => {
                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            size="small"
                            checked={value.selected}
                            value={value.id}
                            onChange={handleCheckOne}
                          />
                        </TableCell>
                        <TableCell>{value.name}</TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
          {elements.length > 5 && (
            <Box className={classes.pagination}>
              <Pagination
                count={numberOfPages}
                onChange={handleChangePaginationOne}
                id="pagination"
              />
            </Box>
          )}
        </Box>
        <Box className={classes.separation}>
          <IconButton onClick={handleSubmitSelection}>
            <img src={images.Arrow} />
          </IconButton>
        </Box>
        <Box className={classes.tableSelected}>
          {selected.length == 0 ? (
            <Box className={classes.message}>
              <img src={images.Hand} className={classes.image} />
              <Typography variant="body1" color="secondary" align="center">
                {I18n.t('cruds.profiles.create.indicatorsSelection')}
              </Typography>
            </Box>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell colSpan={2} className={classes.tableCell}>
                    {I18n.t('cruds.profiles.create.indicators')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selected
                  .slice(
                    paginationTwo * itemsPerpage,
                    paginationTwo * itemsPerpage + itemsPerpage
                  )
                  .map((value, index) => {
                    return (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell>{value.name}</TableCell>
                        <TableCell align="right" padding="checkbox">
                          <IconButton
                            className={classes.delete}
                            onClick={() => {
                              handleDeleteSelection(value.id)
                            }}
                            value={value.id}
                          >
                            {Icons('delete')}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          )}
          {selected.length > 5 ? (
            <Box className={classes.pagination}>
              <Pagination
                count={numberOfPagesSelected}
                onChange={handleChangePaginationTwo}
                id="pagination"
              />
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ProfileSettingsForm
