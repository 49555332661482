import { makeStyles } from '@mui/styles'
import colors from '../utils/colors'
const useStyles = makeStyles({
  items: {
    width: '100%',
  },
  dateTooltip: {
    '&.MuiTypography-root': {
      fontSize: '14px'
    }
  },
  alignItemsCenter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  },
  show: {
    '&.MuiIconButton-root': {
      color: colors.blueLighten
    }
  },
  notFound: {
    '&.MuiTypography-root': {
      padding: '10px 15px',
    }
  },
  inputWidth: {
    '&.MuiTextField-root': {
      width: '100%',
      '& .MuiOutlinedInput-input': {
        paddingLeft: '10px',
      }
    },
  },
  wfull: {
    '&.MuiTypography-root': {
      width: '85%',
    }
  },  
  flex: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexGrow: {
    flexGrow: 1,
    width: 5
  },
  tableContainer: { background: colors.white },
  table: { borderRadius: '2px' },
  tableHead: { background: colors.grey },
  tableRow: {
    height: '48px',
    background: colors.white,
    '&:hover': {
      background: colors.grey,
    },
    transition: '.2s',
  },
  tableCell: {
    width: '50%',
    borderRight: `8px solid ${colors.greyLighten}`,
    padding: '0 0 0 16px',
  },
  divactions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  tableCellActions: {
    padding: '0 0 0 16px',
  },
  delete: {
    color: colors.dangerLighten,
  },
  alert: {
    marginTop: '70px',
  },
  containerDm: {
    float: 'right',
    padding: '10px',
  },
  cancel: {
    '&.MuiButton-root': {
      color: colors.disabled,
      border: colors.disabled + ' solid 1px',
      marginLeft: '52%',
      borderRadius: '2px',
      '&:hover': {
        color: colors.blueLighten,
      },
      transition: '.2s',
    },
  },
  cancelDM: {
    '&.MuiButton-root': {
      color: colors.disabled,
      border: colors.disabled + ' solid 1px',
      // marginLeft: '45%',
      borderRadius: '2px',
      '&:hover': {
        color: colors.blueLighten,
      },
      transition: '.2s',
    },
  },
  submit: {
    '&.MuiButton-root': {
      marginLeft: 10,
      borderRadius: '2px',
    },
  },
  warning: { background: colors.white, minWidth: '300px' },
  loading: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
  },
  loadingBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,.1)',
    width: '100vw',
    minHeight: '100vh',
  },
  circularProgress: {
    position: 'absolute',
    top: '50vh',
    left: '46%',
  },
  search: {
    flexGrow: 2,
    marginRight: '15px',
  },
  title: {
    flexGrow: 4,
    color: colors.lightPrimary
  },
  itemFlex: {
    width: '100%',
    display: 'flex',
    // marginBottom: '10px',
    '&.MuiGrid-root': {
      marginBottom: '10px',
    },
  },
  footer: {
    minHeight: '50px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: -7.5,
    bottom: -1.5,
  },
  datagrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  },
  pagination: {
    ['&.MuiTableCell-root']: {
      border: 'none',
    },
  },
})

export default useStyles
