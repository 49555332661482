import { TextField, Autocomplete } from '@mui/material'
// import { Autocomplete } from '@mui/lab'
import useStyles from './style'

const InputAutoComplete = ({ label, options, className, ...rest }) => {
  const classes = useStyles()
  return (
    <Autocomplete
      {...rest}
      className={classes.multiple + ' ' + className}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={rest.fullWidth}
          label={label}
          variant="outlined"
          size={rest.size}
          className={classes.input + ' ' + className}
          color="secondary"
        />
      )}
    />
  )
}

export default InputAutoComplete
