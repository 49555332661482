import StringCrypto from 'string-crypto'

const { encryptString, decryptString } = new StringCrypto()
const key = 'sFAH@jhd/(¡=sHBHJBSJBAH9uq89e3%$#"adsacjsakn!'
export const encryptData = (data) => {
  return encryptString(JSON.stringify(data), key)
}

export const decryptData = (data) => {
  return JSON.parse(decryptString(data, key))
}
