import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: 0,
    top: 0,
    width: '40vw',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  logo: {
    width: '33%',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
  },
  gridChildren: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },
  sadFace: {
    fontSize: 40,
    position: 'relative',
    top: 4,
  },
  button:{
    color: colors.white
  }
}))

export default useStyles
