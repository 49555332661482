export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const toFile = (base64, type, name = "file") => {
  return new Promise((resolve, reject) => {
    fetch(base64)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], name, { type: type })
        resolve(file)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
