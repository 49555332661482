import { makeStyles } from '@mui/styles'
import { colors } from '../../utils'
export const useSxStyles = () => {
  return {
    toggleGroup: {
      display: 'flex',
      margin: '20px 0',
      '& .MuiToggleButton-root': {
        fontSize: '10px',
        flex: 1,
        borderColor: colors.blueInput,
        '&:hover': {
          borderColor: colors.blueInput + 'dd',
        },
        color: colors.blueInput,
        '&.Mui-selected': {
          color: colors.white,
          background: colors.blueInput,
          '&:hover': {
            background: colors.blueInput + 'dd',
          },
        },
      },
    },
    submit: {
      width: 'fit-content',
    }
  }
}
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: 0,
    top: 0,
    width: '40vw',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  logo: {
    width: '33%',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
  },
  gridChildren: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },
}))

export default useStyles
