import { Box } from '@mui/material'
import I18n from 'i18n-js'
import { useEffect, useState } from 'react'
import { CrudsBtn } from '../../../components'
import { recomendation_interpretatonValidator } from '../../../validators/createIndicatorValidator'
import FormCard from './formCard'
import useStyles from './styles'

const setInitialSize = (size) => {
  const items = []
  for (let i = 0; i < size; i++) {
    items.push({ id: i })
  }
  return items
}

const setInformation = (locale, values, index, id) => {
  if (values[locale].annotations) {
    const informationKey = Object.keys(values[locale].annotations)[index]
    const information = Object.values(values[locale].annotations)[index]
    delete values[locale].annotations[informationKey]
    values[locale].annotations[id] = information
    return information
  } else return {}
}

/* component */
const Annotations = ({ values, setValues, locale, setDisabledStepTwo }) => {
  const [numberAnnotations, setNumberAnnotations] = useState(
    Object.values(values[locale]?.annotations || []).length
  )
  const [annotations, setAnnotations] = useState(
    setInitialSize(numberAnnotations)
  )

  //methods

  const addAnnotation = () => {
    setAnnotations([...annotations, { id: numberAnnotations }])
    setNumberAnnotations(numberAnnotations + 1)
  }

  const handleDeleteAnnotation = (id) => {
    const deletedAnnotation = annotations.filter(
      (annotation) => id != annotation.id
    )
    setAnnotations(deletedAnnotation)
    delete values[locale].annotations[id]
    if (Object.values(values[locale].annotations) < 1) {
      setDisabledStepTwo(false)
    }
    setDisabledStepTwo(
      recomendation_interpretatonValidator(values[locale].annotations)
    )
  }

  useEffect(() => {
    setDisabledStepTwo(
      recomendation_interpretatonValidator(values[locale].annotations)
    )
  }, [values[locale].annotations])
  const classes = useStyles()
  return (
    <Box>
      <Box className={classes.body}>
        {annotations.map((annotation, index) => (
          <FormCard
            key={annotation.id}
            id={annotation.id}
            destroy={handleDeleteAnnotation}
            values={values}
            setValues={setValues}
            annotationValue={setInformation(
              locale,
              values,
              index,
              annotation.id
            )}
            locale={locale}
          />
        ))}
      </Box>
      <Box className={classes.footer}>
        <CrudsBtn
          text={I18n.t('cruds.indicators.annotation.button')}
          variant="contained"
          click={addAnnotation}
        />
      </Box>
    </Box>
  )
}

export default Annotations
