import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Snackbar,
  CircularProgress,
  Hidden,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import I18n from 'i18n-js'
import {
  SearchBar,
  CrudsBtn,
  LanguageSelector,
  AlertMenu,
} from '../../components'
import indicators from '../../services/indicators'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import {
  Icons,
  languages,
  actionKeys,
  pagesKeys,
  rowsPerPage,
} from '../../utils'
import useStyles from '../crudsStyles'
import profileStyles from './style'
const Indicators = () => {
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  //permissions
  const pageId = pagesKeys.indicator
  //list
  const [profileList, setProfileList] = useState([])
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [languageColOne, setLanguageColOne] = useState(languages[0].locale)
  const [languageColTwo, setLenguageColTwo] = useState(languages[1].locale)

  //delete
  const [anchor, setAnchor] = useState(null)
  const [deleteItem, setDeleteItem] = useState()
  const [showAlert, setShowAlert] = useState(false)

  //list
  const getProfileList = async () => {
    const res = await indicators.list()
    setProfileList(res.data?.indicators)
    setItems(res.data?.indicators)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  useEffect(() => {
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getProfileList()
    }
  }, [])
  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }
  const searchItems = () => {
    if (search.trim() != '') {
      const filter = profileList?.filter((e) => {
        if (e.translations.length > 0) {
          if (
            e.translations
              .find((lang) => lang.locale == languageColOne)
              ?.name?.toLowerCase()
              .includes(search.toLowerCase()) ||
            e.translations
              .find((lang) => lang.locale == languageColTwo)
              ?.name?.toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return e
          }
        }
      })
      filter != ''
        ? setItems(filter)
        : setItems([{ error: I18n.t('general.notFound') }])
    } else {
      setItems(profileList)
    }
    setPage(0)
  }
  useEffect(searchItems, [search])
  const handleChangeLenguajeOne = (e) => {
    setLanguageColOne(e.target.value)
  }
  const handleChangeLenguajeTwo = (e) => {
    setLenguageColTwo(e.target.value)
  }
  //create
  const CreateItem = () => {
    history.push('/dashboard/indicators/create')
  }

  //delete
  const handleDeleteClick = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
  }
  const handleDeleteItem = async () => {
    await indicators.destroy(deleteItem)
    setAnchor(null)
    getProfileList()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
  }

  const classes = useStyles()
  const classesProfile = profileStyles()
  return (
    <Box p={4} m={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.indicators')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('breadcrumb.indicators')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar
                  change={handleChangeSearchbar}
                  search={searchItems}
                />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.indicators.list.button')}
              size="medium"
              click={CreateItem}
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} search={searchItems} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguajeOne}
                        language={languageColOne}
                        languages={languages}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classesProfile.tableCell}
                    >
                      <LanguageSelector
                        change={handleChangeLenguajeTwo}
                        language={languageColTwo}
                        languages={languages}
                      />
                    </TableCell>
                    {(canAccess(actionKeys.edit, pageId, state) ||
                      canAccess(actionKeys.delete, pageId, state)) && (
                      <TableCell align="center">
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('general.actions')}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {items != '' && (
                  <TableBody>
                    {(rowsPerPage.indicators > 0
                      ? items?.slice(
                          page * rowsPerPage.indicators,
                          page * rowsPerPage.indicators + rowsPerPage.indicators
                        )
                      : items
                    )?.map((item, index) => {
                      const { translations } = item
                      if (item.error) {
                        return (
                          <TableRow className={classes.tableRow} key={index}>
                            <TableCell align="left" colSpan={4}>
                              <Typography variant="h6" color="primary">
                                {item.error}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        const infoLangOne = translations.find(
                          (lang) => lang.locale == languageColOne
                        )
                        const infoLangTwo = translations.find(
                          (lang) => lang.locale == languageColTwo
                        )
                        return (
                          !item.error && (
                            <TableRow className={classes.tableRow} key={index}>
                              <TableCell className={classesProfile.tableCell}>
                                <Typography variant="body2" color="textPrimary">
                                  {infoLangOne?.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {infoLangOne?.description}
                                </Typography>
                              </TableCell>
                              <TableCell className={classesProfile.tableCell}>
                                <Typography variant="body2" color="textPrimary">
                                  {infoLangTwo?.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {infoLangTwo?.description}
                                </Typography>
                              </TableCell>
                              {(canAccess(actionKeys.edit, pageId, state) ||
                                canAccess(
                                  actionKeys.delete,
                                  pageId,
                                  state
                                )) && (
                                <TableCell
                                  align="right"
                                  className={classes.tableCellActions}
                                >
                                  <div className={classes.divactions}>
                                    {canAccess(
                                      actionKeys.edit,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          history.push(
                                            `/dashboard/indicators/edit/${item.id}`
                                          )
                                        }}
                                      >
                                        {Icons('edit')}
                                      </IconButton>
                                    )}

                                    {canAccess(
                                      actionKeys.delete,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        className={classes.delete}
                                        onClick={(e) => {
                                          handleDeleteClick(e)
                                          setDeleteItem(item.id)
                                        }}
                                      >
                                        {Icons('delete')}
                                      </IconButton>
                                    )}
                                  </div>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )
                      }
                    })}
                    <tr>
                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage.indicators]}
                        count={items?.length}
                        page={page}
                        rowsPerPage={rowsPerPage.indicators}
                        onPageChange={handleChangePage}
                        className={classes.pagination}
                      />
                    </tr>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={() => {
          setShowAlert(false)
        }}
        className={classes.alert}
      >
        <Alert severity="success">{I18n.t('components.alertDelete')}</Alert>
      </Snackbar>
      <AlertMenu
        anchor={anchor}
        close={handleDeleteClose}
        action={handleDeleteItem}
      />
      {items == '' && (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      )}
    </Box>
  )
}

export default Indicators
