import { Box, Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import I18n from 'i18n-js'
import styles from './styles'

const TableWithoutMargins = ({ rows = [], columns = [] }) => {
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        hideFooterPagination
        disableSelectionOnClick
        disableColumnMenu
        columns={columns}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {I18n.t('patient.detail.tabTip.noResults')}
            </Stack>
          ),
        }}
        sx={styles.stylesTable}
        disableRowSelectionOnClick
      />
    </Box>
  )
}

export default TableWithoutMargins
