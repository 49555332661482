import { enumSelectSmartest } from "../../utils/enum"

const typeSteps = [
  { id: 1, name: 'smart_questionnaire' },
  { id: 2, name: 'xsens' },
  { id: 3, name: 'questionnaire' },
  { id: 4, name: 'smart_test' },
  { id: 5, name: 'kforce_strength' },
  { id: 6, name: 'kforce_balance' },
]
const sortTraductions = (name, data, locale, type = 1, order = 0) => {
  let information = []
  if (type == 1) {
    information = Object.values(data.traductions).map((traduction) => {
      return { value: traduction[name] || '', locale: traduction.locale }
    })
    information.push({ value: data[locale][name] || '', locale: locale })
  }
  if (type == 2) {
    information = Object.values(data.traductions).map((traduction) => {
      return {
        value:
          Object.values(traduction[name]).find((block) => parseInt(block.order) === parseInt(order))
            .instructions || '',
        locale: traduction.locale,
      }
    })
    information.push({
      value:
        Object.values(data[locale][name]).find((block) => parseInt(block.order) === parseInt(order))
          .instructions || '',
      locale: locale,
    })
  }
  return information
}
const sortBlocksTraductions = (blocks, data, locale) => {
  let information = Object.values(blocks).map((block, index) => {
    
    const info = {
      instructions: sortTraductions('blocks', data, locale, 2, parseInt(block.order)),
      position: index + 1,
      smart_step_type_id: block.block_type,
    }
    if (block.id != undefined) info.id = block.id
    if(block.indicator === true) info.data_source_id = parseInt(block.data_source_id)
    if (block.block_type === 3) {
      info.stepable_type = typeSteps.find(
        (step) => step.id === block.block_type
      ).name
      info.stepable_id = block.questionarie_id
    }
    if (block.block_type === 4) {
      info.stepable_type = typeSteps.find(
        (step) => step.id === block.block_type
      ).name
      info.stepable_id = block.smartTest_id
    }

    info.required = block.required
    
    return info
  })
  return information
}

const useSortForSend = (data, locale) => {
  const blocks = data[locale].blocks
  
  const body = {
    test_type_id: data.type,
    title: sortTraductions('name', data, locale),
    description: sortTraductions('description', data, locale),
    steps: sortBlocksTraductions(blocks, data, locale),
    ...(data.type === 2
      ? {
          updates_provider: enumSelectSmartest.find(
            (item) => item.id === data.updateProvider
          )?.action,
          position: data.position
        }
      : {updates_provider: 'recordless'}),
    active: 1,
  }
  
  if(data.providers_id && data.updateProvider !== 3) body.provider_id = data.providers_id
  else if(data.providers_id) body.provider_id = ""
  
  if (data.type == 1) {
    body.instructional_picture = sortTraductions(
      'instructional_picture',
      data,
      locale
    )

    body.data_sensors = sortTraductions('data_sensors', data, locale)

    if(data.sensors_quantity) body.sensors_quantity = data.sensors_quantity
  }
  return body
}

export default useSortForSend
