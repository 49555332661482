import { useEffect, useState } from 'react'
import { Box, Paper, IconButton, Fade } from '@mui/material'
import I18n from 'i18n-js'
import { InputForm, SelectForm, Switch } from '../../../../../../../components'
import { Icons, Smartquestions } from '../../../../../../../utils'
import {
  SimpleSelection,
  MultipleSelection,
  OpenSelection,
} from './typeQuestions'
import { questionValidator } from '../../../../../../../validators/smartTestValidator'
import useStyles from './style'

const TypeQuestion = ({
  cardItems,
  setCardItems,
  questionarieValues,
  id,
  deleteOption,
  setDeleteOption,
}) => {
  if (cardItems.type) {
    if (cardItems.type == 1) {
      return (
        <Fade in={cardItems.type == 1} timeout={400}>
          <SimpleSelection
            cardItems={cardItems}
            setCardItems={setCardItems}
            questionarieValues={questionarieValues}
            questionId={id}
            deleteOption={deleteOption}
            setDeleteOption={setDeleteOption}
          />
        </Fade>
      )
    } else if (cardItems.type == 2) {
      return (
        <Fade in={cardItems.type == 2} timeout={400}>
          <MultipleSelection
            cardItems={cardItems}
            setCardItems={setCardItems}
            questionarieValues={questionarieValues}
            questionId={id}
            deleteOption={deleteOption}
            setDeleteOption={setDeleteOption}
          />
        </Fade>
      )
    } else if (cardItems.type == 3) {
      return (
        <Fade in={cardItems.type == 3} timeout={400}>
          <OpenSelection cardItems={cardItems} setCardItems={setCardItems} />
        </Fade>
      )
    }
  }
  return <></>
}

const CardQuestion = ({
  setQuestionarie,
  questionarieValues,
  id,
  locale,
  remove,
  values,
  questionTypes,
  required = false,
}) => {
  const classes = useStyles()
  const [deleteOption, setDeleteOption] = useState(0)
  const [cardItems, setCardItems] = useState(
    values != undefined ? values : { type: '' }
  )

  const renameQuestionTypes = questionTypes.map((question) => {
    return {
      ...question,
      name: Smartquestions.find((qtion) => qtion.id === question.id).name,
    }
  })
  const [enabled, setEnabled] = useState(required ?? false)

  const handleEnabled = () => {
    setEnabled(!enabled)
  }

  const Handlechange = (e) => {
    if (e.target.name == 'type') {
      cardItems.options = {}
    }
    setCardItems({
      ...cardItems,
      [e.target.name]: e.target.value,
    })
  }
  useEffect(() => {
    if (questionValidator(cardItems)) {
      cardItems.completed = true
    } else {
      cardItems.completed = false
    }
    setQuestionarie({
      ...questionarieValues,
      [locale]: {
        ...questionarieValues[locale],
        questions: {
          ...questionarieValues[locale].questions,
          [id]: { ...cardItems, required: enabled },
        },
      },
    })
  }, [cardItems, deleteOption, enabled])
  return (
    <Paper className={classes.container}>
      <Box className={classes.headerContainer}>
        <InputForm
          variant="outlined"
          // size="small"
          name="title"
          color="secondary"
          label={
            I18n.t('cruds.smartTest.questions.form.nameQuestionLabel') + '(*)'
          }
          value={cardItems.title || ''}
          onChange={Handlechange}
          className={classes.inputsHeader}
        />
        <SelectForm
          variant="outlined"
          // size="small"
          name="type"
          label={
            I18n.t('cruds.smartTest.questions.form.typeofQuestionLabel') + '(*)'
          }
          color="secondary"
          options={renameQuestionTypes}
          isLocalData
          onChange={Handlechange}
          value={cardItems.type || ''}
          className={classes.inputsHeader}
        />
      </Box>
      <Box className={classes.bodyContainer}>
        <TypeQuestion
          cardItems={cardItems}
          setCardItems={setCardItems}
          questionarieValues={questionarieValues}
          id={id}
          deleteOption={deleteOption}
          setDeleteOption={setDeleteOption}
        />
      </Box>
      <Box className={classes.footerContainer}>
        <Switch onClick={handleEnabled} enable={enabled} />
        <IconButton
          size="small"
          onClick={() => {
            remove(id)
          }}
          className={classes.iconFooter}
        >
          {Icons('delete')}
        </IconButton>
      </Box>
    </Paper>
  )
}

export default CardQuestion
