import { List, Drawer, Box } from '@mui/material'
import clsx from 'clsx'
import I18n from 'i18n-js'
import DrawerList from '../drawerList'
import images from '../../utils/Images'
import Styles from './styles'

const Aside = ({
  open,
  drawerWidth,
  drawerFocus,
  drawerLeave,
  sidebarOptions,
  getOptions,
  handleOptions,
}) => {
  const classes = Styles((drawerWidth = { drawerWidth }))
  return (
    <div>
      <Drawer
        variant="permanent"
        className={clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        onPointerEnter={drawerFocus}
        onPointerLeave={drawerLeave}
      >
        <Box mt={open ? 3 : 1} mb={open ? 6 : 4}>
          {open && (
            <a href="/dashboard">
              <img
                className={classes.LogoOpen}
                src={images.LogoOpen}
                alt={I18n.t('alt.logoIdp')}
              />
            </a>
          )}
          {!open && (
            <img
              className={classes.LogoClose}
              src={images.LogoClose}
              alt={I18n.t('alt.logoIdp')}
            />
          )}
        </Box>
        <List>
          <DrawerList
            drawerOpen={open}
            sidebarOptions={sidebarOptions}
            getOptions={getOptions}
            handleOptions={handleOptions}
          />
        </List>
      </Drawer>
    </div>
  )
}

export default Aside
