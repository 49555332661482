import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    padding: '32px 32px',
  },
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  body: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
  },
  button: {
    marginLeft: '8px',
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: { maxWidth: '70%' },
})

export default useStyles
