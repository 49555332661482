import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignContent: 'flex-start',
      height: '250px',
      padding: '0 16px',
    },
  },
  inputText: {
    alignSelf: 'center',
    background: 'white',
    // height: '35px',
    "&.MuiFormControl-root": {
    display: 'flex',
    marginBottom: '5px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
        // height: '40px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  inputsContainer: {
    width: '47%',
    marginBottom:'10px',
    minHeight: '61px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  inputBig: {
    "&.MuiFormControl-root": {
    width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
      marginBottom: 0,
    },
  },
  inputLarge: {
    "&.MuiFormControl-root": {
    width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
      marginBottom: 0,
    },
  },
  inputSmall: {
    width: '31%',
    marginBottom: 20,
    alignSelf: 'start',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  snackbar: { position: 'fixed', top: '13vh', left: '50vw' },
}))

export default useStyles
