import { Box, IconButton, Paper, Typography } from '@mui/material'
import I18n from 'i18n-js'
import { Link } from 'react-router-dom'
import { Icons, stepEnum } from '../../utils'
import useStyles from './styles'

const ShowSmartStepCard = ({ step }) => {
  const classes = useStyles()
  const { position, step_type, instructions, smart_test_id, id } = step
  return (
    <Paper className={classes.root}>
      <Box className={classes.sections}>
        <Typography variant="h5" color="primary">
          {position}
        </Typography>
      </Box>
      <Box className={classes.sections} id="type">
        <Typography variant="body2" color="textPrimary">
          <strong>{I18n.t('cruds.smartTest.form.blockType')}</strong>
        </Typography>
        <Typography variant="body2" color="textPrimary">
          {I18n.t(stepEnum[step_type.name]) || ''}
        </Typography>
      </Box>
      <Box className={classes.sections} id="instructions">
        {step_type.id === 1 && (
          <Box>
            <Typography variant="body2" color="textPrimary">
              <strong>
                {I18n.t('cruds.smartTest.questions.numberOfQuestions')}
              </strong>
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {step.questions?.length || I18n.t('general.dataNotFound')}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography variant="body2" color="textPrimary">
            <strong>{I18n.t('cruds.smartTest.form.instructions')}</strong>
          </Typography>
          <Typography variant="body2" color="textPrimary">
            {instructions || I18n.t('general.dataNotFound')}
          </Typography>
        </Box>
      </Box>
      {step_type.id === 1 && (
        <Box className={classes.sections}>
          <div>
            <IconButton
              component={Link}
              target={'_blank'}
              to={`/dashboard/smart-test/questions/${smart_test_id}/edit/${id}`}
              color="secondary"
            >
              {Icons('edit')}
            </IconButton>
          </div>
        </Box>
      )}
    </Paper>
  )
}

export default ShowSmartStepCard
