import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import I18n from 'i18n-js'
import styles from './styles'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import AlertError from '../alertError'
import { isEmail } from '../../validators/validator'
import SimpleError from '../errors/simple'

const ModalLogin = ({
  open,
  close,
  buttonOne = () => close(),
  onSubmit,
  error = false,
}) => {
  const [data, setData] = useState()
  const [viewError, setViewError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleChange = (e) => {
    setData({ ...(data ?? {}), [e.target.name]: e.target.value })
  }

  return (
    <Modal open={open} onClose={close}>
      <Box sx={styles.bodyModal}>
        <Box sx={styles.bodyTitle}>
          <Typography sx={styles.textTitle}>
            {I18n.t('patient.detail.tabTip.modal.login')}
          </Typography>
        </Box>

        <form onSubmit={(e) => onSubmit(e, data)}>
          <TextField
            label={I18n.t('patient.detail.tabTip.modal.username')}
            margin="normal"
            name="username"
            // size="small"
            fullWidth
            onChange={handleChange}
            onInput={(e) => {
              isEmail(e.target.value) ? setViewError(true) : setViewError(false)
            }}
          />
          <SimpleError
            error={viewError}
            message={
              data?.username === ''
                ? I18n.t('validators.emplyEmail')
                : I18n.t('validators.invalidEmail')
            }
          />
          <TextField
            label={I18n.t('patient.detail.tabTip.modal.password')}
            margin="normal"
            name="password"
            // size="small"
            fullWidth
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{
                      '&.MuiButtonBase-root': {
                        padding: '4px',
                      },
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={styles.alertStyles}>
            <AlertError
              text={I18n.t(
                'patient.detail.tabTip.modal.incorrectPasswordUsernamePlease'
              )}
              open={error}
            />
          </Box>

          <Box sx={styles.bodyButton}>
            <Button
              variant="outlined"
              color="secondary"
              sx={styles.buttonOne}
              size="small"
              onClick={buttonOne}
            >
              {I18n.t('components.cancel')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={styles.buttonTwo}
              size="small"
              type="submit"
            >
              {I18n.t('patient.detail.tabTip.tabOne.sent')}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  )
}

export default ModalLogin
