import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import clsx from 'clsx'
import I18n from 'i18n-js'
import { Icons } from '../../utils'
import useStyles from './style'
const CardSmartPredict = ({ data, onClick }) => {
  const classes = useStyles()
  
  return (
    <Card className={classes.root} elevation={3}>
      <CardContent className={classes.body}>
        {!!data?.evolution_graph && (
          <IconButton onClick={() => {onClick(data?.evolution_graph)}} className={classes.graphic}>
            {Icons('graph')}
          </IconButton>
        )}
        <Box className={classes.icon}>{data.icon}</Box>
        <Box
          className={
            classes.information + ' ' + (data.id ? classes.centerInfo : '')
          }
        >
          {data.id && (
            <>
              <Typography
                variant="caption"
                color="textPrimary"
                style={{ marginLeft: '8px' }}
              >
                {data.value}
              </Typography>

              <Typography variant="caption" color="textPrimary" id="tugTitle">
                <strong>
                  {data.id
                    ? data.name
                    : I18n.t('patient.detail.tugInformation')}
                </strong>
              </Typography>
            </>
          )}

          {!data.id && (
            <Box className={classes.dataContainer}>
              <div className={classes.containerText}>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  className={classes.text}
                >
                  <strong>
                    {data.id
                      ? data.name
                      : I18n.t('patient.detail.tugInformation')}
                  </strong>
                </Typography>

                <Typography
                  className={classes.text}
                  variant="body2"
                  color="textPrimary"
                >
                  {data.value ? (
                    data.value
                  ) : (
                    <span>{I18n.t('general.dataNotFound')}</span>
                  )}
                </Typography>
              </div>

              {data.graph && (
                <Box className={classes.containerImg}>
                  <img
                    src={data.graph}
                    alt=""
                    className={`${classes.imgPosition}`}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
        {data.id && (
          <Box
            className={clsx(classes.notification, {
              [classes.success]: data.status == 'good',
              [classes.warning]: data.status == 'warning',
              [classes.danger]: data.status == 'danger',
              [classes.ok]: data.status == 'ok',
              [classes.care]: data.status == 'care',
              [classes.disabled]: data.status == '',
            })}
          />
        )}
      </CardContent>
    </Card>
  )
}

export default CardSmartPredict
