import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import patients from '../../../../services/patients'
import {
  TeleExpertiseEquivalenceTypes,
  TeleExpertiseTypes,
} from '../teleExpertise/components/listFife/constants'

const useGetData = (props) => {
  const { id } = useParams()
  const [files, setFiles] = useState({})
  const [flag, setFlag] = useState(false)
  useEffect(() => {
    if (id && !flag && props?.tab == 6) {
      patients.getPatientsFile(id).then((response) => {
        let body = {}

        if (response?.data?.smart_predict || response?.data?.vdsi) {
          body = {
            ...body,
            [TeleExpertiseTypes.DOCUMENT]: {
              ...(response?.data?.smart_predict && {
                smartPredict: {
                  value: [response?.data?.smart_predict],
                },
              }),
              ...(response?.data?.vdsi && {
                vdsi: {
                  value: [response?.data?.vdsi],
                  type: TeleExpertiseTypes.DOCUMENT,
                },
              }),
            },
          }
        }
        response?.data?.teleconsultation?.forEach((item) => {
          const value =
            item?.value instanceof Array ? item?.value : [item.value]
            
          if (value?.length > 0 && !!item?.value) {
            body = {
              ...body,
              [TeleExpertiseEquivalenceTypes[item.field]]: {
                ...(body?.[TeleExpertiseEquivalenceTypes[item.field]] ?? {}),
                [item.field]: {
                  value,
                  type: TeleExpertiseEquivalenceTypes[item.field],
                },
              },
            }
            // teleconsultation = {
            //   ...teleconsultation,
            //   [item.field]: {
            //     value,
            //     type: TeleExpertiseEquivalenceTypes[item.field],
            //   },
            // }
          }
        })

        setFiles((prev) => ({
          ...prev,
          ...body,
        }))

        setFlag(true)
      })
    }
  }, [props?.tab])

  return { id, files }
}

export { useGetData }
