import GoogleMapReact from 'google-map-react'
import MapMarker from '../mapMarker'
import { ApiKeyMaps } from '../../utils/constants'
/* 
 TYPE FUNCTION:
 type=1: show all the centers and center the map in the main point
 type=2: center the map in the main point whithout centers and dont show any center
 type=3: center the map in the main point and show the center in that point
*/
const Map = ({ center, data = null, type = 1, size }) => {
  return (
    <div style={{ width: '100%', height: size?.height || '200px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: ApiKeyMaps }}
        center={center}
        zoom={11}
      >
        {type == 1
          ? data.map((loc, index) => {
              return loc.latitud && loc.longitud ? (
                <MapMarker
                  key={index}
                  lat={parseFloat(loc.latitud)}
                  lng={parseFloat(loc.longitud)}
                  text={loc.name}
                />
              ) : (
                ''
              )
            })
          : ''}
        {type == 3 && center.lat && center.lng && (
          <MapMarker lat={center.lat} lng={center.lng} text={center.name} />
        )}
      </GoogleMapReact>
    </div>
  )
}

export default Map
