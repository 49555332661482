import useStyles from './style'
const MapMarker = ({ lat, lgn, text }) => {
  const classes = useStyles()
  return (
    <div lat={lat} lgn={lgn} className={classes.marker}>
      {text}
    </div>
  )
}

export default MapMarker
