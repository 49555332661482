import { Box, Fade, Typography } from '@mui/material'
import I18n from 'i18n-js'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  AlertModal,
  CrudsBtn,
  Loading,
  StepperCreate,
} from '../../../../components'
import { AuthContext } from '../../../../context/auth'
import notAllHaveTraductions from '../../../../hooks/traductions/notAllHaveTraductions'
import { smartTests } from '../../../../services'
import { QuestionsEdition, LanguajeSettingsForm } from './steps'
import {
  useSortForSend,
  useSortResponse,
} from '../../.././../hooks/smartTest/questionaries'
import useStyles from './style'
import { CheckCircle } from '@mui/icons-material'

const QuestionsForm = () => {
  const classes = useStyles()
  /* state */
  const { state } = useContext(AuthContext)

  const steps = [
    {
      step: 'cruds.smartTest.steps.questions.stepOne.step',
      label: 'cruds.smartTest.steps.questions.stepOne.label',
    },
    {
      step: 'cruds.smartTest.steps.questions.stepTwo.step',
      label: 'cruds.smartTest.steps.questions.stepTwo.label',
    },
  ]

  /* data */
  const { id, stepId } = useParams()
  const [questionTypes, setQuestionTypes] = useState([])
  const [questionarie, setQuestionarie] = useState({ [state.locale]: {} })
  const [loading, setLoading] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const [openModal, setOpenModal] = useState(false)

  /* methods */
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getData = async () => {
    const smartTestResponse = await smartTests.show(id)

    const questionTypeResponse = await smartTests.questionTypes()

    setQuestionTypes(questionTypeResponse.data || {})

    const currentStep = smartTestResponse.data?.steps || []

    const stepData = currentStep.find((step) => step.id == stepId) || {}

    setQuestionarie(useSortResponse(stepData, state.locale))
    
    if (smartTestResponse.data) setLoading(false)
  }

  const checkTraductions = () => {
    if (
      notAllHaveTraductions(
        questionarie.traductions,
        true,
        false,
        false,
        questionarie[state.locale]
      )
    ) {
      setOpenModal(true)
    } else {
      handleSubmitData()
    }
  }

  const addQuestions = async (questions) => {
    const questionsId = questions.map(async (body) => {
      if (!body.id) {
        const smartResponse = await smartTests.createQuestion(body)
        return await smartResponse.data.question
      } else {
        await smartTests.editQuestion(body, body.id)
        return body.id
      }
    })

    return await Promise.all(questionsId).then((id) => id)
  }
  
  const handleSubmitData = async () => {
    const bodys = useSortForSend(questionarie, state.locale).questions
    await addQuestions(bodys).then(async (questionsId) => {
      const addQuestionsResponse = await smartTests.addQuestions(
        questionsId.filter((id) => id),
        stepId
      )
      if (addQuestionsResponse.data) {
        setActiveStep(activeStep + 1)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [])

  if (loading) {
    return <Loading />
  }
  return (
    <Box p={4} m={4} className={classes.root}>
      {/* header */}
      <Box className={classes.header}>
        <Box className={classes.headerSections}>
          <Typography variant="h5" color="primary">
            {I18n.t('cruds.smartTest.questions.edit.title')}
          </Typography>
        </Box>
        <Box className={classes.headerSections}>
          <StepperCreate activeStep={activeStep} steps={steps} />
        </Box>
      </Box>
      {/* body */}
      <Box>
        <Fade in={activeStep == 0} timeout={500}>
          <Box>
            {activeStep == 0 && (
              <QuestionsEdition
                locale={state.locale}
                questionarieValues={questionarie}
                setQuestionarie={setQuestionarie}
                setDisabled={setDisabled}
                questionTypes={questionTypes}
              />
            )}
          </Box>
        </Fade>

        <Fade in={activeStep == 1} timeout={500}>
          <Box>
            {activeStep == 1 && (
              <LanguajeSettingsForm
                items={[]}
                values={questionarie}
                locale={state.locale}
                setCreateData={setQuestionarie}
              />
            )}
          </Box>
        </Fade>
        <Fade in={activeStep == 2} timeout={500}>
          <Box>
            {activeStep == 2 && (
              <Box className={classes.finishContainer}>
                <CheckCircle className={classes.chekCircle} />
                <Typography variant="h5" color="primary">
                  {I18n.t('components.success')}
                </Typography>
                <Typography variant="body1" color="primary">
                  {I18n.t('cruds.questionaries.create.created')}
                </Typography>
              </Box>
            )}
          </Box>
        </Fade>
      </Box>
      {/* footer */}
      <Box className={activeStep < 2 ? classes.between : classes.center}>
        {activeStep < 2 && (
          <CrudsBtn
            variant="text"
            text={I18n.t('components.cancel')}
            click={() => {
              window.open('about:blank', '_self')
              window.close()
            }}
          />
        )}
        <Box className={classes.buttonsContainer}>
          {activeStep > 0 && activeStep < 2 && (
            <CrudsBtn
              variant="outlined"
              text={I18n.t('components.back')}
              click={handleBackStep}
            />
          )}
          {activeStep < 2 && (
            <CrudsBtn
              text={I18n.t('components.next')}
              click={activeStep < 1 ? handleNextStep : checkTraductions}
              disabled={disabled}
            />
          )}
          {activeStep === 2 && (
            <CrudsBtn
              text={I18n.t('cruds.smartTest.questions.edit.return')}
              click={() => {
                window.open('about:blank', '_self')
                window.close()
              }}
            />
          )}
        </Box>
      </Box>
      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('components.traductions.missingTraductionTitle')}
        message={I18n.t('components.traductions.missingTraductiontext')}
        SuccessText={I18n.t('components.yes')}
        success={handleSubmitData}
      />
    </Box>
  )
}

export default QuestionsForm
