import { useState } from 'react'
import { images } from '../../utils'
import I18n from 'i18n-js'

// maximo 255 caracteres a ambos campos
const useBody = () => {
  const [modalForm, setModalForm] = useState({
    open: false,
    title: I18n.t('cruds.medicalCenters.biody.title'),
  })
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({})
  const [listCards, setListCards] = useState({})
  const [ids, setIds] = useState(1)
  const [anchor, setAnchor] = useState({
    open: null,
    id: null,
  })
  const field = [
    {
      name: 'id_aminogram',
      label: 'ID (*)',
      fullWidth: true,
      inputProps: { maxLength: 255 },
      icon: (
        <img src={images.biodyXpert3} width={216 * 0.15} height={262 * 0.15} />
      ),
    },
    {
      name: 'license_aminogram',
      label: I18n.t('cruds.medicalCenters.biody.form.license') + ' (*)',
      fullWidth: true,
      inputProps: { maxLength: 255 },
      icon: (
        <img src={images.biodyXpert3} width={216 * 0.15} height={262 * 0.15} />
      ),
    },
  ]

  const resetListCard = (listCard) => {
    let localId = 1
    for (const item of Object.values(listCard ?? {})) {
      if (item?.id > localId) {
        localId = item.id
      }
    }
    setIds(parseInt(localId) + 1)
    setListCards(listCard)
  }

  const openModal = (payload) => {
    setModalForm((prev) => ({ ...prev, open: true }))
    setAnchor((prev) => ({ ...prev, id: payload?.id ?? null }))
  }

  const closeModal = () => {
    setModalForm((prev) => ({ ...prev, open: false }))
    closeAlert()
    setData({})
    setErrors({})
  }

  const openAnchor = ({ anchor, id }) => {
    setAnchor((prev) => ({ ...prev, open: anchor, id }))
  }

  const closeAlert = () => {
    setAnchor((prev) => ({ ...prev, open: null, id: null }))
  }

  const onChange = ({ target }) => {
    const { name, value } = target
    setData((prev) => ({ ...prev, [name]: value }))
    let errors = ''
    if (!value) {
      errors = I18n.t('validators.required')
    }

    setErrors((prev) => ({
      ...prev,
      [name]: errors,
    }))
  }

  const safe = (e) => {
    e.preventDefault()
    const localId = anchor?.id ?? ids
    setListCards((prev) => ({
      ...prev,
      [localId]: {
        id: localId,
        id_aminogram: data.id_aminogram,
        license_aminogram: data.license_aminogram,
      },
    }))
    if (!anchor?.id) {
      setIds((prev) => prev + 1)
    }
    closeModal()
  }

  const edit = ({ id }) => {
    openModal({ id })
    setData({
      id_aminogram: listCards[id].id_aminogram,
      license_aminogram: listCards[id].license_aminogram,
    })
  }

  const deleteDivice = () => {
    const listCardCopy = { ...listCards }
    delete listCardCopy[anchor.id]
    setListCards(listCardCopy)
    closeAlert()
  }

  const disabledButton = () =>
    !(data?.id_aminogram?.length >= 8) ||
    !(data?.license_aminogram?.length >= 8)

  return {
    modalForm,
    data,
    errors,
    field,
    listCards,
    anchor,
    resetListCard,
    deleteDivice,
    openAnchor,
    closeAlert,
    edit,
    closeModal,
    openModal,
    onChange,
    safe,
    disabledButton,
  }
}

export { useBody }
