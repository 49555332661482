import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import styles from './styles'
import I18n from 'i18n-js'
import { intervalToDuration } from 'date-fns'
import { useState } from 'react'
import { Icons } from '../../../../../utils'
import patientService from '../../../../../services/patients'
const PackMassageCards = ({
  item,
  onChange,
  value,
  orderData,
  searchItems,
  fav,
}) => {
  const [viewMessage, setViewMessage] = useState(false)
  const [loadingStar, setLoadingStar] = useState(false)

  const returnTime = (time, less = false) => {
    if (!time) return
    if (time === 0)
      return 0 + ' ' + I18n.t('patient.detail.tabTip.tapTwo.hours')

    const { months, days, hours } = intervalToDuration({
      start: 0,
      end: time * 3600000,
    })

    return (
      (time < 0 && less ? '-' : '') +
      (months > 0
        ? months + ' ' + I18n.t('patient.detail.tabTip.tapTwo.months') + ' '
        : '') +
      (days > 0
        ? days + ' ' + I18n.t('patient.detail.tabTip.tapTwo.days') + ' '
        : '') +
      (hours > 0
        ? hours + ' ' + I18n.t('patient.detail.tabTip.tapTwo.hours')
        : '')
    )
  }
  const handleFav = async (id) => {
    const res = await patientService.changeFavorite({ conseil_id: id })

    if (res?.data) {
      const resAux = fav ? await orderData() : await searchItems()
      if (resAux) setLoadingStar(false)
    }
  }
  return (
    <Paper elevation={3} sx={styles.message.stylesPaper}>
      <Grid container>
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Checkbox
            sx={{ padding: '0px' }}
            onChange={() => onChange(item.id)}
            checked={value.includes(item.id)}
          />
        </Grid>
        <Grid item xs={9}>
          <Typography sx={{ fontWeight: 600, fontSize: '17px', width: '100%' }}>
            {item.title}
          </Typography>
          <Typography sx={{ width: '100%' }}>
            {I18n.t('patient.detail.tabTip.tapTwo.packageDetails')}:
            {' ' +
              item?.quantity +
              ' ' +
              I18n.t('patient.detail.tabTip.tapTwo.messagesIn') +
              ' '}
            {returnTime(item?.totalTime)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setViewMessage(!viewMessage)}>
            {viewMessage ? Icons('show') : Icons('hidden')}
          </IconButton>
          {loadingStar ? (
            <CircularProgress size={20} sx={{ margin: '10px', padding: 0 }} />
          ) : (
            <IconButton
              onClick={() => {
                handleFav(item?.id)
                setLoadingStar(true)
              }}
            >
              {item?.favorite ? Icons('star') : Icons('starBorder')}
            </IconButton>
          )}
        </Grid>
      </Grid>

      {viewMessage &&
        item?.messages?.map((element, key) => (
          <Box
            container
            key={key}
            sx={{
              marginTop: '16px',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{ fontWeight: 600, marginRight: '26px', marginLeft: '4px' }}
              >
                {key > 9 ? key + 1 : '0' + (key + 1)}
              </Typography>
              <Typography sx={{ marginBottom: '4xp' }}>
                {element.content}
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: '8px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Typography>
                {I18n.t('patient.detail.tabTip.tapTwo.deliveryTime')}:{' '}
                {returnTime(element?.time, true)}
              </Typography>
            </Box>
          </Box>
        ))}
    </Paper>
  )
}

export default PackMassageCards
