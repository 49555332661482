import React, { useState, useEffect } from 'react'
import { Grid, Tabs, Tab, Typography, Grow } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import I18n from 'i18n-js'
import { TabPanel, Loader } from '../../../../components'
import useStyles from '../../../../styles'
import CardTab from '../cardTab'
function TableIndicators({ open, close }) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [tab, setTab] = useState(0)
  const [grow, setGrow] = useState(false)

  const All = [
    {
      image: open?.find((item) => item?.value_type === 'image'),
      indicators: open?.filter((item) => item?.id !== 564),
    },
    {
      image: close.find((item) => item.value_type === 'image'),
      indicators: close.filter((item) => item.id !== 565),
    },
  ]
  useEffect(() => {
    setGrow(true)
    async function onLoad() {
      try {
        setIsLoading(true)
      } catch (e) {
        //error
      }
      setIsLoading(false)
    }
    onLoad()
  }, [])

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  function renderBody() {
    return (
      <div className={classes.mbxxl}>
        <Grow in={grow} timeout={2000}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            centered
          >
            <Tab
              label={
                <span className={classes.balanceTabs}>
                  <FontAwesomeIcon icon={faEye} />
                  {I18n.t('patient.detail.tabSmartDetect.openEyes')}
                </span>
              }
            />

            <Tab
              label={
                <span className={classes.balanceTabs}>
                  <FontAwesomeIcon icon={faEyeSlash} />{' '}
                  {I18n.t('patient.detail.tabSmartDetect.closeEyes')}
                </span>
              }
            />
          </Tabs>
        </Grow>
        {All.map((IndicatorEye, key) => {
          if (IndicatorEye.image != undefined) {
            return (
              <TabPanel value={tab} index={key} key={key}>
                <Grid container spacing={4}>
                  <CardTab mt={2} data={IndicatorEye.indicators} />
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} md={8}>
                        {IndicatorEye.image?.name && IndicatorEye.image?.value && (
                          <>
                            <h3 style={{ textAlign: 'center' }}>
                              {IndicatorEye.image?.name}
                            </h3>
                            <img
                              style={{ width: '100%' }}
                              src={IndicatorEye.image?.value}
                              alt={IndicatorEye.image?.name}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            )
          } else {
            return (
              <TabPanel value={tab} index={key} key={key}>
                <Typography variant="body1" color="textSecondary">
                  {I18n.t('general.notFound')}
                </Typography>
              </TabPanel>
            )
          }
        })}
      </div>
    )
  }

  return isLoading ? <Loader /> : renderBody()
}

export default TableIndicators
