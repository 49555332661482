export const formatDate = (date) => {
  const formatedDate = new Date(date || '')
  formatedDate.setMinutes(
    formatedDate.getMinutes() + formatedDate.getTimezoneOffset()
  )
  return formatedDate
}

export const formatDateHour = (date) => {
  let splitDate = date.split(' ')
  let dateFormated = splitDate[0]
  let hourFormated = splitDate[1]
  let splitHour = hourFormated.split(':')

  let hour = splitHour[0]
  let minutes = splitHour[1]
  let time = hour > 12 ? `${hour - 12}:${minutes} PM`: `${hour}:${minutes} AM`
  return `${dateFormated} ${time}`
}
