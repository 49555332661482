import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles((theme) => ({
  textEmail: {},
  headerContainer: {
    border: colors.disabledLighten + ' solid 1px',
    borderRadius: '4px',
    padding: '8px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
    },
  },
  titleContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  headerTitle: {
    padding: 0,
  },
  informationContainer: {
    width: '69%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: '16px',
    },
  },
  textHeaderContainer: {
    display: 'flex',
  },
  iconHeaderText: {
    marginRight: '8px',
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconLock: {
    marginRight: '16px',
    //fontSize: '30px'
  },

  bodyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '16px 0',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    
  },
  resetPasswordContainer: {
    background: colors.white,
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '47%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '3px',
    height: '274px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      border: 'none',
      height: '350px',
    },
  },
  resetPasswordHeader: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingTop: '24px',
    color: colors.disabledDarken,
  },
  resetPasswordBody: { paddingLeft: '24px' },
  resetPasswordActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 4px 16px 0',    
  },
  textContainer: {
    color: colors.disabledDarken,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10px',
    },
    
  },
  textContainerTitle: {
    marginBottom: '16px',
  },
  editUserContainer: {
    width: '47%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '3px',
    border: colors.disabledLighten + ' solid 1px',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '15px',
    },
  },
  editUserHeader: {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    justifyContent: 'flex-start',
    paddingTop: '24px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '16px',
      marginBottom: '16px',
    },
  },
  button: {
    borderRadius: '0 2px 2px 0',
    fontSize: '14px',
    textTransform: 'none',
  },
  submit: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    padding: '0 4px 16px 0',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '8px',
    },
    [theme.breakpoints.down('md')]: {
      '& button': {
        marginRight: '16px',
      },
    },
  },
  loading: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
  snackbar: {
    position: 'fixed',
    top: '13vh',
    left: '50vw',
  },
}))

export default useStyles
