import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import I18n from 'i18n-js'
import { useContext, useState } from 'react'
import { DinamicForm } from '../../../../components'
import { AuthContext } from '../../../../context/auth'
import { canAccess } from '../../../../router/permissions'
import { actionKeys, Icons, pagesKeys } from '../../../../utils'
import useStyles from './styles'

const Step2 = ({
  form,
  handleForm,
  doctors,
  errors,
  onChange,
  existObservation = null,
}) => {
  const classes = useStyles()

  const { state } = useContext(AuthContext)

  const [observation, setObservation] = useState({
    observation: '',
  })
  
  const inputs = [
    {
      name: 'observation',
      label: I18n.t('patient.form.observation'),
      type: 'textArea',
    },
  ]

  const handleChange = ({ target }) => {
    setObservation({ observation: target.value })
  }

  const handleDelte = (key) => {
    let target = {
      target: {
        name: 'observation',
        value: form.observation.filter((item, ind) => ind !== key),
      },
    }
    onChange(target)
  }

  const handleAdd = () => {
    if (observation.observation.trim() !== '') {
      let target = {
        target: {
          name: 'observation',
          value: form.observation
            ? [...form.observation, observation.observation]
            : [observation.observation],
        },
      }

      setObservation({ ...observation, observation: '' })

      onChange(target)
    }
  }

  const [change, setChange] = useState(null)

  const handleAddEnter = () => {
    let newPatients = [...form.observation]

    newPatients[change] = observation.observation

    let target = {
      target: {
        name: 'observation',
        value: [...newPatients],
      },
    }

    onChange(target)

    setChange(null)

    setObservation({ ...observation, observation: '' })
  }

  const handleEdite = (text, id) => {
    setChange(id)

    setObservation({ observation: text })
  }

  return (
    <Grid item>
      <Typography variant="h6" color="primary" my={2}>
        {I18n.t('patient.show.principalDoctor')}
      </Typography>

      <TextField
        variant="outlined"
        name="main_doctor"
        onChange={(e) => {
          handleForm({
            target: { name: 'main_doctor', value: [e.target.value] },
          })
        }}
        // size="small"
        color="secondary"
        value={form.main_doctor || ''}
        select
        fullWidth
        className={classes.input}
      >
        {doctors?.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={option.id}
              className={classes.selectItems}
            >
              <Box className={classes.titleContainer}>
                <Typography
                  variant="body1"
                  color="initial"
                  className={classes.text}
                >
                  {(option.name || '') + ' ' + (option.lastname || '')}
                </Typography>
                <Typography
                  variant="body1"
                  color="initial"
                  className={classes.text}
                >
                  <strong>{I18n.t('patient.tableheader.speciality')}</strong>:{' '}
                  {option.type_staff?.name || I18n.t('general.dataNotFound')}
                </Typography>
              </Box>
            </MenuItem>
          )
        })}
      </TextField>
      <Box height="30px" />

      {canAccess(actionKeys.createObservation, pagesKeys.patients, state) ? (
        <Grid container>
          <Grid item xs={12} mb={2}>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subtitle}
            >
              {I18n.t('patient.show.observation')}
            </Typography>
          </Grid>

          <Grid item lg={6} mb={10}>
            <Box className={classes.contentObservation}>
              <DinamicForm
                inputs={inputs.splice(0, 1)}
                handleChange={handleChange}
                values={observation}
                errors={errors}
                styles={classes.observation}
              />

              <Button
                variant="contained"
                className={classes.add}
                color="secondary"
                onClick={() =>
                  change === null ? handleAdd() : handleAddEnter()
                }
              >
                {change === null
                  ? I18n.t('patient.show.add')
                  : I18n.t('patient.show.update')}
              </Button>
            </Box>
          </Grid>

          <Grid item lg={6} className={classes.observationList}>
            {form.observation &&
              form.observation.length > 0 &&
              form.observation?.map((item, key) => (
                <Box key={key} className={classes.observationItem}>
                  <Box className={classes.observationIcon}>
                    <IconButton
                      color="secondary"
                      onClick={() => handleEdite(item, key)}
                    >
                      {Icons('edit')}
                    </IconButton>

                    <IconButton onClick={() => handleDelte(key)}>
                      {Icons('delete')}
                    </IconButton>
                  </Box>

                  <Typography variant="body1">{item}</Typography>
                </Box>
              ))}

            {existObservation?.map((item, key) => (
              <Box key={key} className={classes.observationItem}>
                <Typography variant="body1" className={classes.dr}>{item.name}</Typography>

                <Box className={classes.userInf} mb={1} mr={2}>
                  <Typography variant="body2" className={classes.author}>
                    {item.medical}
                  </Typography>

                  {item.date !== null && item.date !== '' && (
                    <Typography
                      variant="caption"
                      className={classes.dateAuthor}
                    >
                      {item.date}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Box height={120} />
      )}
    </Grid>
  )
}

export default Step2
