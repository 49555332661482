import { Hidden } from '@mui/material'
import I18n from 'i18n-js'
import images from '../../utils/Images'
import useStyles from './style'

const ImageWelcome = () => {
  const classes = useStyles()
  return (
    <Hidden smDown>
      <div className={classes.content}>
        <img
          src={images.LoginImg}
          alt={I18n.t('alt.welcomeIdp')}
          className={classes.img}
        />
      </div>
    </Hidden>
  )
}

export default ImageWelcome
