import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  labelContainer: {
    marginRight: '24px',
    marginTop: '8px',
    width: '15%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
  },
  label: { "&.MuiTypography-root":{marginLeft: '16px'}, },
  questionContainer: {
    width: '100%',
  },
  card: { padding: '16px 16px', marginBottom: '16px' },
  inputsHeader: {
    width: '50%',
    [theme.breakpoints.down('sm')]: { width: '100%' },
  },
  optionsContainer: { display: 'flex', flexDirection: 'column' },
}))

export default useStyles
