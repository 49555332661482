import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputLabelContainer: {
    display: 'flex',
    marginBottom: '16px',
  },
  inputLabel: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formSmallContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '55px',
  },
  textAreaContainer: {
    width: '100%',
    margin: '10px 0',
    minHeight: '169px',
  },
  form: {
    background: colors.white,

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  smallInput: {
    width: '32%',
    marginRight: '16px',
  },
  lastInput: {
    width: '32%',
  },
  mediumInput: {
    width: 'calc(50% - 8px)',
  },
  largeInput: {
    width: '100%',
  },
  mediumInputTextArea: {
    width: 'calc(50% - 8px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      ['&:last-child']: { marginTop: '24px' },
    },
  },
  lastTextFields: {
    marginTop: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: { flexWrap: 'wrap' },
  },
  inputsContainer: {
    width: 'calc(50% - 8px)',
    minHeight:'55px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  acronymInput: {
    width: '32%',
    marginRight: '16px',
  },
  acronymStyle: {
    width: '100%',
  },
  acronymInputLeft: {
    marginLeft: '16px',
  },
  smallAcronymInput: {
    width: '100%',
  },
}))

export default useStyles
