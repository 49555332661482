import { Box, TextField, Typography } from '@mui/material'
import I18n from 'i18n-js'
import useStyles from './style'
import { isEmpty } from '../../validators/validator'

const InformationGeneralQuestionary = ({ change, values, locale }) => {
  const classes = useStyles()
  return (
    <Box className={classes.formContainer}>
      <Box className={classes.nameContainer}>
        <TextField
          variant="outlined"
          name="name"
          label={I18n.t('cruds.questionaries.create.nameLabel')}
          defaultValue={values[locale]?.name ? values[locale].name : ''}
          onChange={change}
          placeholder={I18n.t('cruds.questionaries.create.indicator')}
          color="secondary"
          error={
            values[locale]?.name === undefined
              ? false
              : isEmpty(values[locale]?.name) || false
          }
          // size="small"
          className={classes.form}
        />
        {values[locale]?.name === undefined
          ? false
          : isEmpty(values[locale]?.name) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.required')}
              </Typography>
            )}
      </Box>
      <TextField
        variant="outlined"
        name="instructions"
        label={I18n.t('cruds.questionaries.create.descriptionLabel')}
        defaultValue={
          values[locale]?.instructions ? values[locale].instructions : ''
        }
        multiline
        rows={4}
        onChange={change}
        color="secondary"
        fullWidth
        error={
          values[locale]?.instructions === undefined
            ? false
            : isEmpty(values[locale]?.instructions) || false
        }
        className={classes.form}
      />
      {values[locale]?.instructions === undefined
        ? false
        : isEmpty(values[locale]?.instructions) && (
            <Typography variant="caption" color="error">
              {I18n.t('validators.required')}
            </Typography>
          )}
    </Box>
  )
}

export default InformationGeneralQuestionary
