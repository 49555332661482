import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import I18n from 'i18n-js'
import useStyles from './style'
const Switch = ({onClick, enable}) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.container, {
        [classes.disabled]: !enable,
        [classes.enabled]: enable,
      })}
      onClick={onClick}
    >
      <div
        className={clsx(classes.indicator, {
          [classes.indicatorEnabled]: enable,
          [classes.ml]: enable,
          [classes.mr]: !enable
        })}
      />
      <Typography
        variant="caption"
        className={clsx(classes.text, {
          [classes.textEnabled]: enable,
        })}
      >
        {enable ? I18n.t('components.required') : I18n.t('components.noRequired')}
      </Typography>
    </div>
  )
}

export default Switch
