import { Box, IconButton, Modal, Typography } from '@mui/material'
import { colors, Icons } from '../../utils'

const style = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: colors.white,
    borderRadius: '5px',
    padding: '20px 0'
  },
  closeIcon: {
  },
  modalTitle: {
    fontSize: '18px',
    fontWeight: '500',
    paddingTop: '8px',
    marginBottom: '10px',
  },
  border: {
    borderTop: `1px solid ${colors.greyStrong}`,
    marginTop: '10px',
    paddingTop: '10px',
    paddingRight: '24px',
    paddingLeft: '24px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-evenly',
    padding: '0px 20px'
  },
  detail: {
    width: 'calc(100% - 20px)'
  },
  close: {
    width: '20px'
  }
}

const BaseModal = ({ children, open, onClose, title, sxBody, modalProps }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box {...modalProps} sx={[style.container, modalProps?.sx]}>
        <Box sx={style.header}>
          <Box sx={style.detail}>
            <Typography sx={style.modalTitle}>{title}</Typography>
          </Box>

          <Box sx={style.close}>
            <IconButton sx={style.closeIcon} onClick={onClose}>{Icons('close')}</IconButton>
          </Box>
        </Box>

        <Box sx={sxBody ?? style.border}>{children}</Box>
      </Box>
    </Modal>
  )
}

export default BaseModal
