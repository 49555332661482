import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material"
import { Icons } from "../../../../../../utils"
import { DetailCard } from "../detailCard"
import useStyle from "../../style"
import constants from '../../enum'

const IconCard = (props) => {
    const classes = useStyle()

    return props.list?.map((val, key) => (
        <Grid key={key} item lg={props.lg ?? 6} md={props.md ?? 12} sm={12} xs={12}>
          <Card className={classes.card}>
            {!val?.arrowHide && (
              <IconButton
                onClick={() => props.handleArrow(val?.id)}
                className={classes.arrow}
              >
                {props.listArrow.indexOf(val?.id) !== -1
                  ? Icons('arrowUp')
                  : Icons('arrowDown')}
              </IconButton>
            )}

            <CardContent className={classes.content}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <img className={classes.ear} src={val?.images} alt="" />
                </Grid>
                <Grid
                  item
                  xs={8}
                  mt={
                    val?.type === constants.images && !val?.value?.[0]
                      ? 2
                      : 0
                  }
                  sx={{
                    position:
                      val?.type === constants.images ? '' : 'relative',
                  }}
                >
                  {props.handleTypeCard(val)}

                  {(val?.type !== constants.images ||
                    !val?.value?.[0]) && (
                      <Typography
                        variant="subtitle2"
                        component="span"
                        className={classes.name}
                      >
                        {val?.name}
                      </Typography>
                    )}
                </Grid>
              </Grid>
              {DetailCard(
                props.listArrow.indexOf(val?.id) !== -1,
                val?.description,
                classes,
                val.evolution_graph
              )}
            </CardContent>
          </Card>
        </Grid>
      ))
}

export { IconCard }
