import { makeStyles } from '@mui/styles'
import { colors } from '../../../../utils'

const useStyles = makeStyles({
  card: {
    width: '100%',
    // minHeight: 170,
    '&.MuiCard-root': {
      borderRadius: 5,
      background: colors.grey,
      position: 'relative',
      border: `1px solid ${colors.disabledLighten}`
    },
    marginBottom: 20
  },
  cardContent: {
    '&.MuiCardContent-root': {
      marginBottom: 50
    }
  },
  content: {
    '&.MuiTypography-root': {
      display: 'inline',
      wordBreak: 'break-word',
      padding: 10,
      color: colors.disabledDarken,
    },
  },
  userInf: {
    width: 150,
    position: 'absolute',
    right: 0,
    bottom: 0,
    '& .MuiTypography-root': {
      color: colors.disabledDarken,
    },
  },
  author: {
    float: 'right'
  },
  date: {
    '&.MuiTypography-root': {
      float: 'right',
      display: 'block',
    },
  },
})

export default useStyles
