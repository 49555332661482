export const head = (name) => {
  return `<!DOCTYPE html> <html lang="en"> <head> <meta charset="UTF-8" /> <meta http-equiv="X-UA-Compatible" content="IE=edge" /> <meta name="viewport" content="width=device-width, initial-scale=1.0" /> <title>${name}</title> <style> html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, big, del, dfn, em, img, q, s,strong, sub, sup, tt, var, b,u, i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody, tfoot, thead, tr, th, td, article, footer, header, hgroup { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; } /* HTML5 display-role reset for older browsers */ article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; } body { line-height: 1; } ol, ul { list-style: none; } blockquote, q { quotes: none; } blockquote:before, blockquote:after, q:before, q:after { content: ""; content: none; } table { border-collapse: collapse; border-spacing: 0; }body { font-family: "Roboto", sans-serif; color: #4a4a4a; background-color: #f6f6f6; } h1 { font-size: 24px; color: #4a4a4a; font-weight: bold; } h2 { font-size: 20px; } p { font-size: 14px; color: black; line-height: 20px; } strong { font-weight: bold; color: #2d2d2d; }footer {  margin: 0 60px; position: absolute !important; bottom: 40px !important; left: 0px !important; right: 0px !important; height: 20px !important; } </style> </head>`
}

export const header = () => {
  return `<header> <img src="{{idp_logo}}" alt="{{idp_logo}}" width="122.4" height="55.2" style="display: inline-block" /> </header>`
}

export const footer = () => {
  return `<footer> <div style="display: inline-block"><p style="font-size: 14px; color: #4a4a4a"> IDP Santé, 2 rue de la Coulée, 49 000 Angers / <a href="info@idpsante.com" style="text-decoration: none; color: #125ed0" >info@idpsante.com</a > </p> </div> </footer>`
}

export const htmlContent = (body, name) => {
  return `${head(
    name
  )} <body> <div style=" padding: 24px 72px 30px 72px; background: white; width: 650px; margin: 0 auto; position: relative; "> ${header()} <h2 style="margin: 60px 0 20px 0; text-align: center;font-size: 28px;font-weight: bold;color: #4f6228; min-height: 30px; word-break: break-all">${name}</h2> <main style=" padding-bottom: 50px; min-height: 23vh;"> ${body}</main> ${footer()} </div> </body> </html> `
}

export const extractContent = (html) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const main = doc.querySelector('main')

  return main?.innerHTML || ''
}
