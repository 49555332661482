const rowsPerPage = {
  units: 10,
  parameter: 10,
  typeCenter: 10,
  typeStaff: 10,
  specialities: 10,
  relations: 10,
  providers: 10,
  healthconditions: 10,
  profiles: 10,
  questionaries: 10,
  indicators: 10,
}

export default rowsPerPage
