import {
  Box,
  Button,
  Checkbox,
  Grid,
  Radio,
  TextField,
  Typography,
} from '@mui/material'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import ptLocale from 'date-fns/locale/pt'
import I18n from 'i18n-js'
import { useContext, useRef, useState } from 'react'
import { AuthContext } from '../../../../../context/auth/context'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import TimePicker from '@mui/lab/TimePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import patientService from '../../../../../services/patients'
import dayjs from 'dayjs'
import styles from './styles'
import { colors } from '../../../../../utils'
import { useEffect } from 'react'

const localeMap = {
  en: enLocale,
  fr: frLocale,
  es: esLocale,
  pt: ptLocale,
  'es-CO': esLocale,
}

const validateFrenchNumber = (number) => {
  const pattern = new RegExp(/^((\+)33|0|0033)[1-9](\d{2}){4}$/)
  return pattern.test(number) ? true : false
}
const FormDateProgrammed = ({
  bodyData,
  setChangeVies,
  patient,
  setOpenSnackBar,
}) => {
  const ref = useRef()
  const [data, setData] = useState({ date: null })
  const [acceptCondition, setAcceptCondition] = useState(false)
  const [shipmentType, setShipmentType] = useState()
  const { state } = useContext(AuthContext)
  let now = new Date()
  now.setDate(now.getDate() + 1)
  const [dateError, setDateError] = useState(undefined)
  useEffect(() => {
    setData({ date: null })
  }, [shipmentType])

  const saveData = (e) => {
    setData({ ...(data ?? {}), date: e })
  }

  const submitData = async (event) => {
    event.preventDefault()
    const currentDate = dayjs();
    const givenDate = dayjs(data?.date);
    const differenceInHours = givenDate.diff(currentDate, 'hour');
    const differencesInMinutes = givenDate.diff(currentDate, 'minute') % 60;

    const body = {
      ...(bodyData ?? {}),
      target: patient?.phone
        ? validateFrenchNumber(patient?.phone)
          ? patient?.phone
          : patient?.email
        : patient?.email,
      hours: shipmentType === 1 ? '0' : differenceInHours.toString(),
      minute: shipmentType === 1 ? '0' : differencesInMinutes.toString(),
    }
    
    const res = await patientService.postConseilsSend(body)
    if (res?.data) {
      setOpenSnackBar(true)
      setChangeVies(false)
    }
  }
  useEffect(() => {
    const timeTextField = ref?.current?.querySelector('[name="time"]')
    if(timeTextField) {
      timeTextField.placeholder = I18n.t('general.timeFormat')
    }
  },[ref.current])
  return (
    <Box sx={styles.form.containerBody}>
      <form onSubmit={submitData}>
        <Typography sx={styles.form.titleStyles}>
          {I18n.t(
            'patient.detail.tabTip.tapTwo.whenDoYouWantToSendThisMessage'
          )}
        </Typography>
        <Box sx={styles.containerNow}>
          <Radio
            sx={styles.whiteElection}
            onClick={() => setShipmentType(1)}
            checked={shipmentType !== undefined && shipmentType === 1}
          />
          <Typography sx={{ color: colors.white }}>
            {I18n.t('patient.detail.tabTip.form.now')}
          </Typography>
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
        >
          <Radio
            sx={styles.whiteElection}
            onClick={() => setShipmentType(2)}
            checked={shipmentType !== undefined && shipmentType === 2}
          />

          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={localeMap[state.locale]}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <DatePicker
                  inputFormat={I18n.t('general.dateFormat') || 'dd-MM-yyyy'}
                  disablePast
                  value={data?.date}
                  onChange={saveData}
                  onError={(e) => setDateError(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      name="date"
                      fullWidth
                      sx={styles.form.inputStyles}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  value={data?.date}
                  onChange={saveData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      ref={ref}
                      size="small"
                      name="time"
                      placeholder={I18n.t('general.timeFormat')}
                      fullWidth
                      sx={styles.form.inputStyles}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '20px' }}>
          <Checkbox
            sx={styles.whiteElection}
            checked={acceptCondition}
            onClick={() => setAcceptCondition(!acceptCondition)}
          />
          <Typography sx={{ color: colors.white, textAlign: 'left' }}>
            {I18n.t('patient.detail.tabTip.form.certifyThatHaveObtained')}
          </Typography>
        </Box>
        <Box sx={styles.form.containerButton}>
          <Box onClick={() => setChangeVies(false)}>
            <Typography sx={styles.form.linkComeBack}>
              {I18n.t('patient.detail.tabTip.tapTwo.backToListOfMessages')}
            </Typography>
          </Box>
          <Button
            disabled={
              Boolean(dateError) ||
              (shipmentType === 2 && Boolean(!data?.date)) ||
              Boolean(!bodyData?.conseil_ids) ||
              Boolean(bodyData?.conseil_ids?.length === 0) ||
              Boolean(!acceptCondition)
            }
            type="submit"
            variant="contained"
            sx={styles.form.stylesButton}
          >
            {I18n.t('patient.detail.tabTip.tapTwo.schedule')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default FormDateProgrammed
