import React, { createContext, useReducer, useState } from 'react'
import { initialState, AuthReducer } from './reducer'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState)
  const [user, setUser] = useState(state)
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
