import { Box, Typography } from '@mui/material'
import useStyles from './styles'

const SimpleError = ({ error = false, message, height = null}) => {
  const classes = useStyles()

  return (
    <Box className={!height && classes.container}>
        { error && <Typography className={classes.text}>{message}</Typography>}
    </Box>
    )
}

export default SimpleError
