import { makeStyles } from '@mui/styles'
import colors from '../../../../utils/colors'

const useStyles = makeStyles((theme) => ({
  firstColumn: {
    display: 'flex',
    columnGap: '32px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      rowGap: '36px',
    },
  },
  mt: {
    '&.MuiTextField-root': {
      marginTop: 15,
    },
  },
  position: {
    '&.MuiTextField-root': {
      width: '49%',
    },
  },
  containerInputImage: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  alignEnd: {alignItems: 'end',},
  wFull: {
    width: '100%',
  },
  wFileImage: {
    width: 'calc(100% - 47px)',
  },
  file: ({ error }) => ({
    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
      '-webkit-text-fill-color': colors.greyStrong,
      fontSize: '.9em',
      width: '80%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      background: colors.white,
      border: error ? `1px solid ${colors.orangeStrong}` : '',
      borderRadius: 3,
      '& svg': {
        color: colors.greyStrong,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      background: `linear-gradient(to bottom, transparent 50%, ${colors.white} 50%)`,
    },
  }),
  errorMessage: {
    position: 'absolute',
    bottom: -15
  },
  inputLabel: {
    '&.MuiInputLabel-root': {
      marginTop: 20,
    },
  },
  sections: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    [theme.breakpoints.down('md')]: {
      rowGap: '36px',
    },
  },
  tabs: {
    border: colors.greyDarken + ' 1px solid',
    borderRadius: '2px',
    background: colors.white,
    '&.MuiTabs-root': {
      minHeight: '50px',
    },
    '& .MuiTabs-indicator': {
      height: 4,
    },
    ['& button']: {
      minHeight: 34,
      ['& .MuiTab-wrapper']: {},
      ['&:last-child']: {
        borderLeft: colors.greyDarken + ' solid 1px',
      },
    },
  },
  secondColumn: {
    margin: '36px 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '32px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      rowGap: '36px',
    },
  },
  firstBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  switchWidth: {
    width: '48%',
    minWidth: 200,
    marginRight: '4%',
  },
  marginTop: {
    marginTop: 28,
  },
  containerprovider: {
    width: '48%',
    minWidth: 200,
  },
  secondBox: {
    display: 'flex',
  },
  sensorsLabel: { width: '40%' },
  sensorsContainer: {
    flex: 1,
    [theme.breakpoints.down('md')]: {
      marginLeft: '3px',
    },
  },
  thirdColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '32px',
  },
  buttonsUrl: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: 0,
    '&.MuiButton-root': {
      justifyContent: 'end',
      padding: 4,
    },
  },
  fR: {
    float: 'right',
    '&.MuiButton-root': {
      padding: '4px 4px 0 0',
      height: 29
    },
  },
  h20: {
    '&.MuiButton-root': {
    height: 20
  },
  }
}))

export default useStyles
