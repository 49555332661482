import { useEffect } from 'react'
import { useStyles } from './styles'
import Icons from '../../utils/icons'
import { useRef } from 'react'
import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { colors } from '../../utils'

const CustomeRecord = ({ audioUrl }) => {
  const styles = useStyles()
  const audioRef = useRef(new Audio(audioUrl))
  const [isPlaying, setIsPlaying] = useState(false)
  // const [volume, setVolume] = useState(1.0) // 1.0 represents full volume
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  // const handleVolumeChange = (event) => {
  //   const newVolume = event.target.value
  //   setVolume(newVolume)
  //   audioRef.current.volume = newVolume
  // }

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime)
  }

  const handleProgressClick = (event) => {
    const progressBar = event.target
    const rect = progressBar.getBoundingClientRect()
    const clickX = event.clientX - rect.left
    const percentage = clickX / rect.width
    const seekTime = percentage * duration
    audioRef.current.currentTime = seekTime
    setCurrentTime(seekTime)
  }
  const handleAudioEnd = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  };

  useEffect(() => {
    const audioElement = audioRef.current
    audioElement.addEventListener('timeupdate', handleTimeUpdate)
    audioElement.addEventListener('loadedmetadata', () => {
      setDuration(audioElement.duration)
    })
    audioElement.addEventListener('ended', handleAudioEnd);

    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate)
      audioElement.removeEventListener('ended', handleAudioEnd);
    }
  }, [])

  const progressBarStyle = {
    background: colors.primaryLighten,
    width: `${(currentTime / duration) * 100}%`,
    height: 'inherit',
  }

  return (
    <Box sx={styles.container()}>
      <IconButton sx={styles.icon} onClick={handlePlayPause}>
        {Icons(isPlaying ? 'pause' : 'play')}
      </IconButton>
      {/* <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
        /> */}
      <Box className="progress-bar" onClick={handleProgressClick}>
        <Box className="progress-bar__fill" style={progressBarStyle}></Box>
      </Box>
    </Box>
  )
}

export { CustomeRecord }
