import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import I18n from 'i18n-js'
import React, { useEffect, useState } from 'react'
import CardTab from '../cardTab'
import { physiologiqueItem } from '../methods'
import { useStyle } from './styles'

const Physilogique = ({ data }) => {
  const [dataCard, setDataCard] = useState([])
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [dateList, setDateList] = useState([])
  const classes = useStyle()

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }

  const handleChange = ({ target }) => {
    setSelectedDate(target.value)
    const index = data.findIndex((item) => item.created_at === target.value)
    setDataCard(physiologiqueItem(data[index] ?? {}))
  }

  useEffect(() => {
    let list = []
    data.forEach((item) => {
      list.push(item.created_at)
    })

    data?.[0] && setSelectedDate(data[0]?.created_at)

    setDateList(list.sort((a, b) => b - a))
    setDataCard(physiologiqueItem(data[0] ?? {}))
  }, [])
  
  return (
    <Grid container spacing={2}>
      {dateList?.length > 0 && (
        <>
          <Grid item lg={12}>
            <Box className={classes.filterContainer}>
              <Typography variant="h6" color="primary" mr={2}>
                {I18n.t('patient.detail.tabSmartPredict.dataForDate')}
              </Typography>

              <FormControl variant="standard" className={classes.formControl}>
                <Select
                  id="select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={selectedDate}
                  onChange={handleChange}
                >
                  {dateList?.length > 0 &&
                    dateList?.map((date, item) => {
                      return (
                        <MenuItem
                          selected={item == 0 ? true : false}
                          key={item}
                          value={date}
                        >
                          {date &&
                            format(
                              new Date(date + 'Z'),
                              I18n.t('general.dateTimeFormat')
                            )}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item lg={12}>
            <CardTab form={true} data={dataCard ?? []} lg={4} />
          </Grid>
        </>
      )}
      {dateList?.length === 0 && (
        <Grid item lg={12}>
          <Typography variant="body1" color="textSecondary">
            {I18n.t('general.notFound')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default Physilogique
