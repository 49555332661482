import { Box, Card, CardContent, Typography } from '@mui/material'
import { format } from 'date-fns'
import I18n from 'i18n-js'
import { formatDateHour } from '../../../../hooks/dates/formatDate'
import useStyles from './styles'

const Observations = ({ data, author = '', date = null }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" className={classes.content}>
          {data}
        </Typography>
      </CardContent>

      <Box className={classes.userInf} mb={1} mr={2}>
        <Typography variant="body2" className={classes.author}>
          {author}
        </Typography>

        {date !== null && date !== '' && (
          <Typography variant="caption" className={classes.date}>
            {formatDateHour(
              format(new Date(date + 'Z'), I18n.t('general.dateTimeFormat'))
            )}
          </Typography>
        )}
      </Box>
    </Card>
  )
}

export default Observations
