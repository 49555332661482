import { Fragment, useState, useContext, useEffect } from 'react'
import {
  Grid,
  Typography,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
  Hidden,
} from '@mui/material'
import I18n from 'i18n-js'
import { format, parseISO } from 'date-fns'
import {
  AccountCircle,
  ExpandMore,
  Description,
  Email,
} from '@mui/icons-material'
import { AuthContext } from '../../../../context/auth'
import { images } from '../../../../utils'
import { SendEmail, SnackBar } from '../../../../components'
import patientService from '../../../../services/patients'
import { Icons } from '../../../../utils'
import useStyles from './style'

const InformationGeneral = ({ patient, documents }) => {
  //state
  const { state } = useContext(AuthContext)
  const classes = useStyles()

  //doctors
  const doctors =
    patient?.medical_staff?.filter((doctor) => !doctor.pivot.main_doctor) || []
  const mainDoctor = patient?.medical_staff?.find(
    (doctor) => doctor.pivot.main_doctor
  )
    ? [patient?.medical_staff?.find((doctor) => doctor.pivot.main_doctor)]
    : []
  const medicalstaffs = patient?.medical_staff || []
  //health conditions
  const healthConditions = patient?.health_conditions || []

  // Documents
  const [email, setEmail] = useState()
  const [showModal, setShowModal] = useState(false)
  const [sendByMyself, setSendByMyself] = useState(false)
  const [loader, setLoader] = useState(false)
  const [documentId, setDocumentID] = useState()
  const [snackBar, setSanckBar] = useState({})
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [locale, setLocale] = useState('')
  const myEmail = state.email

  //methods
  const handleCloseModal = () => {
    setShowModal(false)
    setEmail(undefined)
    setSendByMyself(false)
    setLoader(false)
  }
  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseSnackBar = () => {
    setShowSnackBar(false)
  }
  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSendByMyself = () => {
    if (sendByMyself) {
      setSendByMyself(false)
      setEmail(undefined)
    } else {
      setSendByMyself(true)
      setEmail(myEmail)
    }
  }
  const handleSendEmail = async (e) => {
    e.preventDefault()
    setLoader(true)
    const body = {
      legal_document_id: documentId,
      email: email,
      send_my_email: false,
    }
    const response = await patientService.sendLegalDocuments(patient.id, body)
    if (response.data) {
      handleCloseModal()
      setShowSnackBar(true)
      setSanckBar({
        text: I18n.t('patient.detail.document.success'),
        severity: 'success',
      })
    } else {
      handleCloseModal()
      setShowSnackBar(true)
      setSanckBar({
        text: I18n.t('patient.detail.document.error'),
        severity: 'warning',
      })
    }
  }

  useEffect(() => {
    if (state.locale === 'fr') setLocale('fr')
    else if (state.locale === 'es') setLocale('es')
    else if (state.locale === 'pt') setLocale('pt')
  }, [state.locale])

  const addressZise = () => {
    switch (locale) {
      case 'es':
        return 9
      case 'pt':
        return 12
      case 'fr':
        return 3
      default:
        return 3
    }
  }
  return (
    <Grid container spacing={4}>
      {/* Information */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              {I18n.t('patient.show.personalInfo')}
            </Typography>
          </Grid>

          {/* First row */}
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {I18n.t('patient.patientInfo.email')}
            </Typography>
            <Typography variant="body1" noWrap>
              {patient?.email || I18n.t('general.dataNotFound')}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {I18n.t('patient.patientInfo.weight')} /{' '}
              {I18n.t('patient.patientInfo.height')}
            </Typography>
            <Typography variant="body1">
              {patient?.weight ? patient?.weight : 'N/A'} /{' '}
              {patient?.height ? patient?.height : 'N/A'} kg/cm
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {I18n.t('patient.patientInfo.hand')}
            </Typography>
            <Typography variant="body1">
              {patient?.right_hand == 1
                ? I18n.t('patient.hands.right')
                : I18n.t('patient.hands.left')}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {I18n.t('patient.patientInfo.birth')}
            </Typography>
            <Typography variant="body1">{patient?.birth}</Typography>
          </Grid>

          {/* Second row */}

          <Grid item xs={3}>
            <Typography variant="subtitle2">
              {I18n.t('patient.patientInfo.phone')}
            </Typography>
            <Typography variant="body1">
              {patient?.phone || I18n.t('general.dataNotFound')}
            </Typography>
          </Grid>

          <Grid item xs={locale !== 'es' ? 5 : 3}>
            <Typography variant="subtitle2">
              {I18n.t('patient.patientInfo.medicalCenter')}
            </Typography>
            <Typography variant="body1">
              {patient?.medical_centers?.[0]?.name ||
                I18n.t('general.dataNotFound')}
            </Typography>
          </Grid>

          {locale === 'es' ? (
            <>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.dni')}
                </Typography>
                <Typography variant="body1">
                  {patient?.dni ?? I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.nuss')}
                </Typography>
                <Typography variant="body1">
                  {patient?.nuss ?? I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.country')}
                </Typography>
                <Typography variant="body1">
                  {patient?.country || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid item xs={4}>
              <Typography variant="subtitle2">
                {I18n.t('patient.patientInfo.SSN')}
              </Typography>
              <Typography variant="body1">
                {patient?.SSN || I18n.t('general.dataNotFound')}
              </Typography>
            </Grid>
          )}

          {locale === 'fr' && (
            <>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.country')}
                </Typography>
                <Typography variant="body1">
                  {patient?.country || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.department')}
                </Typography>
                <Typography variant="body1">
                  {patient?.state_id || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.community')}
                </Typography>
                <Typography variant="body1">
                  {patient?.community_id || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.form.codeInsee')}
                </Typography>
                <Typography variant="body1">
                  {patient?.code_insee || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>
            </>
          )}

          {/* Portugal */}
          {locale === 'pt' && (
            <>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.country')}
                </Typography>
                <Typography variant="body1">
                  {patient.country || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.form.cc')}
                </Typography>
                <Typography variant="body1">
                  {patient.cc || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.form.validate_to')}
                </Typography>
                <Typography variant="body1">
                  {patient.expire_date || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.form.nif')}
                </Typography>
                <Typography variant="body1">
                  {patient.nif || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.form.niss')}
                </Typography>
                <Typography variant="body1">
                  {patient.niss || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.form.sns')}
                </Typography>
                <Typography variant="body1">
                  {patient.sns || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {I18n.t('patient.patientInfo.comments')}
                </Typography>
                <Typography variant="body1" className={classes.breakWord}>
                  {patient.comments || I18n.t('general.dataNotFound')}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={addressZise()}>
            <Typography variant="subtitle2">
              {I18n.t('patient.patientInfo.address')}
            </Typography>
            <Typography variant="body1">
              {patient.place_birth || I18n.t('general.dataNotFound')}
            </Typography>
          </Grid>

          {locale === 'fr' && (
            <Grid item xs={3}>
              <Typography variant="subtitle2">
                {I18n.t('patient.form.zipCode')}
              </Typography>
              <Typography variant="body1" className={classes.breakWord}>
                {patient.zip_code || I18n.t('general.dataNotFound')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.cardsContainer}>
        {/* health conditions */}
        <Accordion className={classes.completeCard} elevation={2}>
          <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMore />}
          >
            <Typography
              variant="h6"
              color="primary"
              className={classes.cardTitle}
            >
              {I18n.t('patient.show.healthInfo')}
            </Typography>
          </AccordionSummary>
          <Divider />

          {healthConditions.length > 0 ? (
            healthConditions.map((healthCondition) => (
              <Fragment key={healthCondition.id}>
                <AccordionDetails>
                  <Typography variant="body1" color="textPrimary">
                    {healthCondition.name}
                  </Typography>
                </AccordionDetails>
                <Divider />
              </Fragment>
            ))
          ) : (
            <AccordionDetails>
              <Typography variant="body1" color="textPrimary">
                {I18n.t('general.notFound')}
              </Typography>
            </AccordionDetails>
          )}
        </Accordion>

        {/* Documents */}
        <Box className={classes.completeCard}>
          <Accordion elevation={2}>
            <AccordionSummary
              className={classes.summary}
              expandIcon={<ExpandMore />}
            >
              <Typography
                variant="h6"
                color="primary"
                className={classes.cardTitle}
              >
                {I18n.t('patient.show.documents')}
              </Typography>
            </AccordionSummary>
            <Divider />
            {documents.length > 0 ? (
              documents.map((document) => (
                <Fragment key={document.id}>
                  <AccordionDetails>
                    <ListItem
                      alignItems="flex-start"
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <Description />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box className={classes.title}>
                            <strong>{document.name || ''}</strong>
                            {document.accepted && (
                              <Tooltip
                                placement="right"
                                title={
                                  document.accepted
                                    ? I18n.t('patient.detail.document.accepted')
                                    : I18n.t('patient.detail.document.declined')
                                }
                              >
                                {
                                  <img
                                    src={
                                      document.accepted
                                        ? images.Active
                                        : images.Inactive
                                    }
                                  />
                                }
                              </Tooltip>
                            )}
                          </Box>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {I18n.t('patient.detail.document.firmdate') +
                                ': '}
                            </Typography>
                            {document.signature_date &&
                            document.signature_date != ''
                              ? format(
                                  parseISO(document.signature_date),
                                  I18n.t('general.dateTimeFormat')
                                )
                              : I18n.t('general.dataNotFound')}

                            <br />
                            <Typography variant="caption" color="textSecondary">
                              {document.description}
                            </Typography>

                            <br />
                            <span>
                              <Button
                                startIcon={Icons('description')}
                                variant="text"
                                size="small"
                                onClick={() =>
                                  window.open(
                                    encodeURI(document.document_url),
                                    '_blank'
                                  )
                                }
                                color="primary"
                                className={classes.buttonDocument}
                              >
                                {I18n.t('patient.detail.document.view')}
                              </Button>
                              {document.accepted && (
                                <Button
                                  startIcon={<Email />}
                                  variant="text"
                                  size="small"
                                  onClick={() => {
                                    handleOpenModal()
                                    setDocumentID(document.id)
                                  }}
                                  color="secondary"
                                  className={classes.buttonDocument}
                                >
                                  {I18n.t('patient.detail.document.send')}
                                </Button>
                              )}
                            </span>
                          </Fragment>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </AccordionDetails>
                  <Divider />
                </Fragment>
              ))
            ) : (
              <AccordionDetails>
                <Typography variant="body1" color="textPrimary">
                  {I18n.t('general.notFound')}
                </Typography>
              </AccordionDetails>
            )}
          </Accordion>
        </Box>

        {/* medical staff */}
        <Accordion
          className={classes.completeCard}
          elevation={2}
          defaultExpanded
        >
          <AccordionSummary
            className={classes.summary}
            expandIcon={<ExpandMore />}
          >
            <Typography
              variant="h6"
              color="primary"
              className={classes.cardTitle}
            >
              {I18n.t('patient.show.medicalInfo')}
            </Typography>
          </AccordionSummary>
          <Divider />
          <div className={classes.listContainer}>
            {medicalstaffs.length > 0 ? (
              mainDoctor.concat(doctors).map((medicalStaff) => (
                <Fragment key={medicalStaff.id}>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItem
                      alignItems="flex-start"
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <AccountCircle />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box className={classes.title}>
                            <strong>
                              {(medicalStaff.name || '') +
                                ' ' +
                                (medicalStaff.lastname || '')}
                            </strong>
                            {medicalStaff.pivot.main_doctor && (
                              <span
                                className={
                                  classes.sticker + ' ' + classes.primary
                                }
                              >
                                {I18n.t(
                                  'patient.detail.tabInformation.mainDoctor'
                                )}
                              </span>
                            )}
                          </Box>
                        }
                        secondary={
                          <Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {I18n.t(
                                'cruds.medicalCenters.contacts.form.email'
                              ) + ': '}
                            </Typography>
                            {medicalStaff.email}
                            <br />
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {I18n.t(
                                'cruds.medicalCenters.contacts.form.phone'
                              ) + ': '}
                            </Typography>
                            {medicalStaff.phone}
                            <br />
                            <span className={classes.sticker}>
                              <Typography
                                variant="body2"
                                component="span"
                                noWrap
                              >
                                {medicalStaff.speciality?.name}
                              </Typography>
                            </span>
                          </Fragment>
                        }
                      />
                    </ListItem>
                  </AccordionDetails>
                  <Hidden lgUp>
                    <Divider />
                  </Hidden>
                </Fragment>
              ))
            ) : (
              <AccordionDetails>
                <Typography variant="body1" color="textPrimary">
                  {I18n.t('general.notFound')}
                </Typography>
              </AccordionDetails>
            )}
          </div>
        </Accordion>
      </Grid>
      <SendEmail
        title={I18n.t('general.sendEmail')}
        description={I18n.t('patient.detail.document.sendDocument')}
        open={showModal}
        close={handleCloseModal}
        email={email}
        change={handleChangeEmail}
        loading={loader}
        submit={handleSendEmail}
        switchEmail={sendByMyself}
        setSwitch={handleSendByMyself}
      />
      <SnackBar
        text={snackBar.text}
        severity={snackBar.severity}
        show={showSnackBar}
        HandleShow={handleCloseSnackBar}
        time={3000}
      />
    </Grid>
  )
}
export default InformationGeneral
