import { useState } from 'react'
import { Tab, Tabs, Hidden, Fade } from '@mui/material'

import useStyles from './style'
import I18n from 'i18n-js'
const VerticalTab = ({ items }) => {
  const classes = useStyles()
  const [showTab, setShowTab] = useState(0)
  const handleChangeTab = (_, newValue) => {
    setShowTab(newValue)
  }
  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Tabs
          orientation="vertical"
          value={showTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
          className={classes.tabsComponent}
        >
          {items.map((item, index) => (
            <Tab
              key={index}
              label={I18n.t(item.label)}
              className={classes.tab}
              icon={item.icon}
            />
          ))}
        </Tabs>
      </Hidden>
      <Hidden mdUp>
        <Tabs
          orientation="horizontal"
          value={showTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
          // className={classes.tabsComponent}
        >
          {items.map((item, index) => (
            <Tab
              key={index}
              label={I18n.t(item.label)}
              className={classes.tab}
            />
          ))}
        </Tabs>
      </Hidden>

      <div className={classes.TabContainer}>
        {items.map((item, index) => (
          <Fade in={showTab == index} key={index} timeout={400}>
            <div hidden={showTab != index}>{item.info}</div>
          </Fade>
        ))}
      </div>
    </div>
  )
}

export default VerticalTab
