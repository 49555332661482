import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = (widthBar, color = 'secondary') => {
  const styles = makeStyles({
    iconDisabled: {
      backgroundColor: colors.white,
      zIndex: 1,
      border: '#ccc solid 1px',
      width: '25px',
      height: '25px',
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ccc',
    },
    iconActive: {
      background: color == 'secondary' ? colors.lightPrimary : colors.primary,
      border:
        color == 'secondary'
          ? colors.lightPrimary
          : colors.primary + ' solid 1px',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    iconComplete: {
      border:
        color == 'secondary'
          ? colors.lightPrimary
          : colors.primary + ' solid 1px',
      background: color == 'secondary' ? colors.lightPrimary : colors.primary,
    },
    roundedIcon: {
      '&.MuiSvgIcon-root': {
        fontSize: '21px',
      },
    },
    roundedActive: { color: colors.white },
    roundedComplete: { color: colors.white },

    stepper: {
      background: 'none',
      padding: '10px 0 0 0px',
    },
    step: {
      '&.MuiStep-root': {
        padding: 0,
        width: 'auto',
      }
    },
    stepLabel: {
      '&.MuiStepLabel-root': {
        display: 'block',
      }
    },
    stepEnd: {
      '&.MuiStep-root': {
        padding: 0,
        position: 'relative',
        maxWidth: '27px',
      },
    },
    textStep: {
      textAlign: 'start',
      display: 'block',
      textTransform: 'uppercase',
    },
    textLabelStep: { textAlign: 'start', display: 'block', color: colors.lightPrimary},
    connector: {
      '&.MuiStepConnector-root': {
        left: `-${widthBar}px`,
        right: '0',
        width: `${widthBar}px`,
        top: 13,
        position: 'absolute',
      },
      '&.Mui-active': {
        borderColor:  colors.lightPrimary
      }
    },
    connectorActive: {
      '& span': {
        borderColor: color === 'secondary' ? colors.lightPrimary : colors.primary,
      },
    },
    connectorComplete: {
      '& span': {
        borderColor: color === 'secondary' ? colors.lightPrimary : colors.primary,
      },
    },
  })
  return styles()
}

export default useStyles
