import { languages } from '../../../utils'
const getQuestionsLocales = (locale, questions) => {
  const localeQuestions = questions?.map((question) => {
    const options = question.options.map((option) => {
      return {
        completed: true,
        id: option.id,
        title:
          option.translations.find(
            (translation) => translation.locale == locale
          )?.title || '',
      }
    })
    return {
      completed: true,
      id: question.id,
      required: question.required,
      title:
        question.translations.find(
          (translation) => translation.locale == locale
        )?.title || '',
      type: question.question_type.id,
      options: Object.assign({}, options),
    }
  })
  return localeQuestions
}

const useSortResponse = (apiResponse, locale) => {
  const localeQuestions = getQuestionsLocales(locale, apiResponse.questions)
  let traductions = {}
  languages.map((translation) => {
    if (translation.locale != locale) {
      const questions = getQuestionsLocales(
        translation.locale,
        apiResponse.questions
      )
      traductions[translation.locale] = {
        locale: translation.locale,
        questions: questions,
      }
    }
  })

  let data = {
    [locale]: {
      questions: Object.assign({}, localeQuestions),
    },
    traductions: traductions,
  }
  if (apiResponse.translations) {
    return data
  } else {
    return {}
  }
}

export default useSortResponse
