import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Snackbar,
  CircularProgress,
  Hidden,
  Switch,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import I18n from 'i18n-js'
import {
  SearchBar,
  ModalForm,
  CrudsBtn,
  LanguageSelector,
  AlertMenu,
} from '../../components'
import typeStaff from '../../services/typeStaff'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import {
  Icons as icons,
  languages,
  actionKeys,
  pagesKeys,
  rowsPerPage,
} from '../../utils'
import useStyles from '../crudsStyles'
import { handleError } from './errors'

const TypeStaff = () => {
  const classes = useStyles()
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  //permissions
  const pageId = pagesKeys.typestaff
  //list
  const [data, setData] = useState([])
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [languageColOne, setLanguageColOne] = useState(languages[0].locale)
  const [languageColTwo, setLenguageColTwo] = useState(languages[1].locale)
  const [body, setBody] = useState({})
  const [errors, setErrors] = useState({})
  //create
  const [showAlert, setShowAlert] = useState(false)
  //edit
  const [editId, setEditId] = useState()
  //delete
  const [anchor, setAnchor] = useState(null)
  const [deleteItem, setDeleteItem] = useState()
  const [successDelete, setSuccessDelete] = useState(false)
  //modal
  const [openModal, setOpenModal] = useState(false)
  const [create, setCreate] = useState(true)
  const [disabledButton, setDisableButton] = useState(false)

  const [inputs, setInputs] = useState([])
  //list
  const getData = async () => {
    const res = await typeStaff.list()
    setData(res.data?.typeStaff)
    setItems(res.data?.typeStaff)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  useEffect(() => {
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
  }, [])
  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }
  const searchItems = () => {
    if (search.trim() != '') {
      const filter = data?.filter((e) => {
        if (e.translations.length > 0) {
          if (
            e.translations
              ?.find((lang) => lang.locale == languageColOne)
              ?.name?.toLowerCase()
              ?.includes(search.toLowerCase()) ||
            e.translations
              ?.find((lang) => lang.locale == languageColTwo)
              ?.name?.toLowerCase()
              ?.includes(search.toLowerCase())
          ) {
            return e
          }
        }
      })
      filter != ''
        ? setItems(filter)
        : setItems([{ error: I18n.t('general.notFound') }])
    } else {
      setItems(data)
    }
    setPage(0)
  }
  useEffect(searchItems, [search])
  const handleChangeLenguaje1 = (e) => {
    setLanguageColOne(e.target.value)
  }
  const handleChangeLenguaje2 = (e) => {
    setLenguageColTwo(e.target.value)
  }
  //create
  const handleChange = ({ target }) => {
    const { name, value } = target
    const newBody = { ...body, [name]: value ?? '' }
    const error = handleError({
      [name]: value ?? '',
    })

    setBody(newBody)
    setDisableButton(languages.some((lang) => !newBody[lang.locale]))
    setErrors({
      ...errors,
      [name]: error[name] ?? '',
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let data = {
      name: languages.map((lang) => ({
        locale: lang.locale,
        value: body[lang.locale],
      })),
    }

    let response
    if (body.code) data.code = body.code
    if (body.code_system) data.code_system = body.code_system
    data.main_doctor = body.main_doctor ?? false
    
    setDisableButton(true)
    if (create) {
      response = await typeStaff.create(data)
    } else {
      response = await typeStaff.edit({
        id: editId,
        update: data,
      })
    }
    if (response.data) {
      setOpenModal(false)
      getData()
      setSearch('')
      document.getElementById('search').value = ''
      setShowAlert(true)
    } else {
      setDisableButton(false)
    }
  }

  //delete
  const handleDeleteClick = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
  }
  const handleDeleteItem = async () => {
    await typeStaff.destroy(deleteItem)
    setAnchor(null)
    getData()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
    setSuccessDelete(true)
  }
  //modal
  const openModalForm = (e, data = null) => {
    if (data) {
      const oldValues = {
        code: data.code,
        code_system: data.code_system,
        main_doctor: data.main_doctor,
      }

      data?.translations?.forEach((lang) => {
        oldValues[lang.locale] = lang.name
      })

      let objectLocale = { ...oldValues }

      delete objectLocale.code
      delete objectLocale.code_system
      delete objectLocale.main_doctor

      const error = { ...handleError(objectLocale) }

      setErrors(error)
      setDisableButton(Object.values(error ?? {}).length)
      setEditId(data.id)
      setBody(oldValues)
      setCreate(false)
    } else {
      setDisableButton(true)
      setBody({})
      setCreate(true)
    }
    setOpenModal(true)
  }
  const closeModal = () => {
    setOpenModal(false)
    setBody({})
    setErrors({})
  }

  useEffect(() => {
    let inputModel = [
      ...languages.map((lang) => ({
        name: lang.locale,
        label: `${I18n.t('general.name')} *`,
        InputProps: {
          startAdornment: icons(lang.locale, true),
        },
        style: classes.inputWidth,
        rB: true,
      })),
      {
        name: 'code',
        label: I18n.t('cruds.typeStaff.code'),
        type: 'text',
        style: classes.inputWidth,
        rB: true,
      },
      {
        name: 'code_system',
        label: I18n.t('cruds.typeStaff.codeSystem'),
        type: 'text',
        style: classes.inputWidth,
        rB: true,
      },
    ]

    setInputs(inputModel)
  }, [])
  return (
    <Box p={4} m={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.typeStaff')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('breadcrumb.typeStaff')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar
                  change={handleChangeSearchbar}
                  search={searchItems}
                />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.typeStaff.button')}
              size="medium"
              click={openModalForm}
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} search={searchItems} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguaje1}
                        language={languageColOne}
                        languages={languages}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguaje2}
                        language={languageColTwo}
                        languages={languages}
                      />
                    </TableCell>
                    {(canAccess(actionKeys.edit, pageId, state) ||
                      canAccess(actionKeys.delete, pageId, state)) && (
                      <TableCell align="center">
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('general.actions')}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {items != '' && (
                  <TableBody>
                    {(rowsPerPage.typeStaff > 0
                      ? items?.slice(
                          page * rowsPerPage.typeStaff,
                          page * rowsPerPage.typeStaff + rowsPerPage.typeStaff
                        )
                      : items
                    )?.map((item, index) => {
                      const { translations } = item
                      if (item.error) {
                        return (
                          <TableRow className={classes.tableRow} key={index}>
                            <TableCell align="left" colSpan={4}>
                              <Typography variant="h6" color="primary">
                                {item.error}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        return (
                          !item.error && (
                            <TableRow className={classes.tableRow} key={index}>
                              <TableCell className={classes.tableCell}>
                                {
                                  translations.find(
                                    (lang) => lang.locale == languageColOne
                                  )?.name
                                }
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {
                                  translations.find(
                                    (lang) => lang.locale == languageColTwo
                                  )?.name
                                }
                              </TableCell>
                              {(canAccess(actionKeys.edit, pageId, state) ||
                                canAccess(
                                  actionKeys.delete,
                                  pageId,
                                  state
                                )) && (
                                <TableCell
                                  align="right"
                                  className={classes.tableCellActions}
                                >
                                  <div className={classes.divactions}>
                                    {canAccess(
                                      actionKeys.edit,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        color="secondary"
                                        onClick={(e) => {
                                          openModalForm(e, item)
                                        }}
                                      >
                                        {icons('edit')}
                                      </IconButton>
                                    )}

                                    {canAccess(
                                      actionKeys.delete,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        className={classes.delete}
                                        onClick={(e) => {
                                          handleDeleteClick(e)
                                          setDeleteItem(item.id)
                                        }}
                                      >
                                        {icons('delete')}
                                      </IconButton>
                                    )}
                                  </div>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )
                      }
                    })}
                    <tr>
                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage.typeStaff]}
                        count={items && items?.length}
                        page={page}
                        rowsPerPage={rowsPerPage.typeStaff}
                        onPageChange={handleChangePage}
                        className={classes.pagination}
                      />
                    </tr>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <ModalForm
        open={openModal}
        close={closeModal}
        disabled={disabledButton}
        values={body}
        errors={errors}
        change={handleChange}
        submit={handleSubmit}
        tittle={
          create
            ? I18n.t('components.createItem')
            : I18n.t('components.editItem')
        }
        type={2}
        inputs={inputs}
        btnLabel={create}
        content={
          <div className={`${classes.flex} `}>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.wfull}
            >
              {I18n.t('cruds.typeStaff.personalType')}
            </Typography>
            {/* <div className={classes.flexGrow} /> */}
            <Switch
              onClick={() =>
                handleChange({
                  target: { name: 'main_doctor', value: !body.main_doctor },
                })
              }
              checked={body.main_doctor ?? false}
            />
          </div>
        }
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={() => {
          setShowAlert(false)
          setSuccessDelete(false)
        }}
        className={classes.alert}
      >
        <Alert severity="success">
          {successDelete
            ? I18n.t('components.alertDelete')
            : I18n.t('components.alert')}
        </Alert>
      </Snackbar>
      
      <AlertMenu
        anchor={anchor}
        close={handleDeleteClose}
        action={handleDeleteItem}
      />
      {items == '' && (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      )}
    </Box>
  )
}

export default TypeStaff
