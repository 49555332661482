import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Snackbar,
  CircularProgress,
  Hidden,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import I18n from 'i18n-js'
import {
  SearchBar,
  LanguageSelector,
  CrudsBtn,
  AlertMenu,
} from '../../components'
import healthConditions from '../../services/healthConditions'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import {
  Icons,
  languages,
  actionKeys,
  pagesKeys,
  rowsPerPage,
} from '../../utils'
import useStyles from '../crudsStyles'

const HealthConditions = () => {
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  //permissions
  const pageId = pagesKeys.healthconditions
  //list
  const [data, setData] = useState([])
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [languageColOne, setLanguageColOne] = useState(languages[0].locale)
  const [languageColTwo, setLenguageColTwo] = useState(languages[1].locale)
  //create
  const [showAlert, setShowAlert] = useState(false)
  //edit
  //delete
  const [anchor, setAnchor] = useState(null)
  const [deleteItem, setDeleteItem] = useState()
  const [successDelete, setSuccessDelete] = useState(false)
  //list
  const getData = async () => {
    const res = await healthConditions.list()

    if (res?.data !== null) {
      if (res.data?.healthConditions?.length > 0) {
        setData(res.data?.healthConditions)
        setItems(res.data?.healthConditions)
      }
    }

    setLoading(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  useEffect(() => {
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
  }, [])
  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }
  const searchItems = () => {
    if (search.trim() != '') {
      const filter = data?.filter((e) => {
        if (e.translations.length > 0) {
          if (
            e.translations
              .find((lang) => lang.locale == languageColOne)
              .name.toLowerCase()
              .includes(search.toLowerCase()) ||
            e.translations
              .find((lang) => lang.locale == languageColTwo)
              .name.toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return e
          }
        }
      })
      filter != ''
        ? setItems(filter)
        : setItems([{ error: I18n.t('general.notFound') }])
    } else {
      setItems(data)
    }
    setPage(0)
  }
  useEffect(searchItems, [search])
  const handleChangeLenguaje1 = (e) => {
    setLanguageColOne(e.target.value)
  }
  const handleChangeLenguaje2 = (e) => {
    setLenguageColTwo(e.target.value)
  }
  //create

  //edit
  //delete
  const handleDeleteClick = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
  }
  const handleDeleteItem = async () => {
    await healthConditions.destroy(deleteItem)
    setAnchor(null)
    getData()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
    setSuccessDelete(true)
  }
  //modal

  const classes = useStyles()
  return (
    <Box p={4} m={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.healthConditions')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('breadcrumb.healthCondition')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar
                  change={handleChangeSearchbar}
                  search={searchItems}
                />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.healthcondition.button')}
              size="medium"
              click={() =>
                history.push(`${history.location.pathname}/create`, {
                  state: {
                    hash: 'create',
                  },
                })
              }
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} search={searchItems} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguaje1}
                        language={languageColOne}
                        languages={languages}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguaje2}
                        language={languageColTwo}
                        languages={languages}
                      />
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {I18n.t('components.modal.children')}
                    </TableCell>
                    {(canAccess(actionKeys.edit, pageId, state) ||
                      canAccess(actionKeys.delete, pageId, state)) && (
                      <TableCell align="center">
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('general.actions')}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {items?.length > 0 && (
                  <TableBody>
                    {(rowsPerPage.healthconditions > 0
                      ? items?.slice(
                          page * rowsPerPage.healthconditions,
                          page * rowsPerPage.healthconditions +
                            rowsPerPage.healthconditions
                        )
                      : items
                    )?.map((item, index) => {
                      const { translations, children } = item
                      if (item.error) {
                        return (
                          <TableRow className={classes.tableRow} key={index}>
                            <TableCell align="left" colSpan={4}>
                              <Typography variant="h6" color="primary">
                                {item.error}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        return (
                          !item.error && (
                            <TableRow className={classes.tableRow} key={index}>
                              <TableCell className={classes.tableCell}>
                                {
                                  translations.find(
                                    (lang) => lang.locale == languageColOne
                                  )?.name
                                }
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {
                                  translations.find(
                                    (lang) => lang.locale == languageColTwo
                                  )?.name
                                }
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tableCell}
                              >
                                {children?.[0]?.translations.length
                                  ? children.length
                                  : 0}
                              </TableCell>
                              {(canAccess(actionKeys.edit, pageId, state) ||
                                canAccess(
                                  actionKeys.delete,
                                  pageId,
                                  state
                                )) && (
                                <TableCell
                                  align="center"
                                  className={classes.tableCellActions}
                                >
                                  <div className={classes.divactions}>
                                    {canAccess(
                                      actionKeys.edit,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          history.push(
                                            `/dashboard/health-conditions/show/${item.id}`,
                                            {
                                              state: item,
                                            }
                                          )
                                        }}
                                      >
                                        {Icons('show')}
                                      </IconButton>
                                    )}

                                    {canAccess(
                                      actionKeys.delete,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        className={classes.delete}
                                        onClick={(e) => {
                                          handleDeleteClick(e)
                                          setDeleteItem(item.id)
                                        }}
                                      >
                                        {Icons('delete')}
                                      </IconButton>
                                    )}
                                  </div>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )
                      }
                    })}
                    <tr>
                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage.healthconditions]}
                        count={items && items?.length}
                        page={page}
                        rowsPerPage={rowsPerPage.healthconditions}
                        onPageChange={handleChangePage}
                        className={classes.pagination}
                      />
                    </tr>
                  </TableBody>
                )}

                {items?.length <= 0 && loading && (
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.notFound}
                  >
                    {I18n.t('general.notFound')}
                  </Typography>
                )}
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={() => {
          setShowAlert(false)
          setSuccessDelete(false)
        }}
        className={classes.alert}
      >
        <Alert severity="success">
          {successDelete
            ? I18n.t('components.alertDelete')
            : I18n.t('components.alert')}
        </Alert>
      </Snackbar>

      <AlertMenu
        anchor={anchor}
        close={handleDeleteClose}
        action={handleDeleteItem}
      />
      {!loading && (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      )}
    </Box>
  )
}

export default HealthConditions
