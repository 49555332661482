import { Alert, Snackbar } from '@mui/material'

const AlertError = ({ open, close = () => {}, text }) => {
  return (
    <Snackbar
      sx={{ width: '100%' }}
      open={open}
      autoHideDuration={2000}
      onClose={() => close()}
      className="alertError"
    >
      <Alert severity="error">{text}</Alert>
    </Snackbar>
  )
}
export default AlertError
