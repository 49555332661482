import { makeStyles } from '@mui/styles'
import { colors } from '../../../utils'

const useStyles = makeStyles({
    container: {
        height: 12
    },
    text: {
        '&.MuiTypography-root': {
            color: colors.error,
            fontSize: '.75em',
            marginTop: 2,
            marginLeft: 10
        }
    }
})

export default useStyles