import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  multiple: {
    background: 'transparent',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
})

export default useStyles
