export const rolAdmin = [
  {
    id: 1,
    name: 'home',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 2,
    name: 'medicalStaff',
    actions: [1, 2, 3, 4, 5, 6],
  },
  {
    id: 3,
    name: 'medicalCenter',
    actions: [1, 2, 3, 4, 5, 6],
  },
  {
    id: 4,
    name: 'users',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 8,
    name: 'questionaries',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 7,
    name: 'indicators',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 12,
    name: 'profils',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 10,
    name: 'healtconditions',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 11,
    name: 'providers',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 13,
    name: 'specialities',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 14,
    name: 'specialities',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 15,
    name: 'typeStaff',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 16,
    name: 'typeCenterMedical',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 17,
    name: 'units',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 20,
    name: 'smartTest',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 21,
    name: 'parameters',
    actions: [1, 2, 3, 4, 5],
  },
  {
    id: 22,
    name: 'myProfile',
    actions: [4]
  }
]
