import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  actionContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

export default useStyles
