import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'
const useStyles = makeStyles({
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  circularProgress: {
    position: 'absolute',
    top: '50vh',
    left: '46%',
  },
  emailText: {
    width: 250,
    overflowWrap: 'break-word'
  }
})

export default useStyles
