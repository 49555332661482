import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { withStyles } from '@mui/styles'
import useStyles from '../../styles'
import colors from '../../utils/colors'

const ColorCircularProgress = withStyles({
  root: {
    color: colors.primary,
  },
})(CircularProgress)

export default function Loader({size = 64, removeMH = false}) {
  const classes = useStyles()
  return (
    <div className={`${classes.loadingContainer} ${!removeMH && classes.minHeightLoading}`}>
      <ColorCircularProgress size={size} />
    </div>
  )
}
