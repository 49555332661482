import fetchIntercept from 'fetch-intercept'
import { createLogOutElement } from '../components/logoutModal/methods'
export const unregister = fetchIntercept.register({
  request: function (url, config) {
    return [url, config]
  },

  requestError: function (error) {
    return Promise.reject(error)
  },

  response: async function (response) {
    if (response.status === 401) {
      createLogOutElement()
      // window.location = '/logout'
      return Promise.reject(response)
    }
    return response
  },

  responseError: function (error) {
    return Promise.reject(error)
  },
})
