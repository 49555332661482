import { colors } from '../../../../../utils'

const styles = {
  form: {
    containerBody: {
      background: colors.blueSent,
      borderRadius: '16px',
      padding: '40px 25px',
    },
    titleStyles: {
      color: '#fff',
      fontSize: '20px',
      marginBottom: '16px',
      fontWeight: 600,
    },
    inputStyles: {
      background: colors.white,
      '& .MuiInputBase-root': {
        '&.MuiOutlinedInput-root': {
          borderRadius: '0px',
        },
      },
      '& .MuiFormLabel-root': {
        // color: colors.black,
        '&.Mui-focused': { color: colors.white },
      },
      '& .MuiInputAdornment-root': {
        '& .MuiButtonBase-root': {
          '& .MuiSvgIcon-root': {
            fontSize: '1.8rem',
          },
          color: colors.greenBlue,
        },
        padding: '8px',
      },
    },
    stylesTitlesOne: {
      marginTop: '16px',
      color: colors.white,
      fontSize: '1rem',
    },
    stylesTitlesTwo: {
      color: colors.white,
      fontSize: '1rem',
    },
    containerButton: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    stylesButton: {
      marginTop: '16px',
      background: colors.greenBlue,
    },
    linkComeBack: {
      color: colors.white,
      marginTop: '20px',
      fontSize: '14px',
      cursor: 'pointer',
    },
  },
  indexStyles: {
    containerTab: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    titleStyles: {
      color: colors.blueSent,
      fontSize: '20px',
      marginLeft: '24px',
    },
  },
  config: {
    titleStyles: {
      color: colors.blueSent,
      fontSize: '20px',
      marginTop: '10px',
    },
    containerButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    stylesButton: {
      marginTop: '16px',
      background: colors.greenBlue,
    },
  },
  message: {
    stylesPaper: {
      borderRadius: '13px',
      display: 'flex',
      margin: '12px 4px',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '16px',
    },
    containerMEssage: {
      height: '50vh',
      overflow: 'auto',
    },
  },
  containerNow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  whiteElection: {
    '&.Mui-checked': {
      color: colors.white,
    },
  },
}

export default styles
