import { colors } from '../../utils'
const useStyles = () => {
  return {
    container: {
      overflow: 'hidden',
      width: '100vw',
      height: '100vh',
      background: `linear-gradient(to right, ${colors.primary}66  0%, ${colors.secondary}66 100%)`,
      position: 'fixed',
      top: '0%',
      left: '0%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000000000,
    },
    modal: {
      margin: 'auto',
      borderRadius: '10px',
      alignItems: 'center',
      minHeight: '350px',
      height: 'calc(100vh - 350px)',
    },
    submit: {
      color: colors.white,
      fontSize: '12px',
      background: colors.blueInput,
      display: 'flex',
      alignItems: 'center',
      '& .MuiIcon-root': {
        height: 'fit-content',
        marginLeft: '15px',
      },
      '& .MuiSvgIcon-root': {
        fontWeight: '600',
        fontSize: '20px',
        color: colors.white,
        background: `${colors.white}66`,
      },
      '&:hover': {
        background: `${colors.blueInput}ee`,
      },
      borderRadius: '0px',
    },
    actions: {
      padding: '60px 0px 20px',
    },
    title: {
      height: 'fit-content',
      fontSize: '25px',
      color: colors.lightPrimary,
      fontWeight: '500',
      width: '100%',
    },
    subtitle: {
      width: '100%',
      height: 'fit-content',
      fontSize: '12px',
      color: colors.blueInput,
      marginBottom: '50px',
    },
  }
}

export { useStyles }
