import { Grid, Typography } from "@mui/material"
import BaseModal from "../../../../../../../components/baseModal"
import { DetailCard } from "../../../../cardTab/components"
import { useDetailModalStyles } from "./detailModalStyles"
import I18n from "i18n-js"
import { graphIds } from "../../../../smartPredict/constants"

const DetailModal = (props) => {
    const styles = useDetailModalStyles()
    const existMoreInformation = (!!props.description || !!props.evolution_graph)
    const showOnlyGraph = graphIds.includes(props.id)
    
    return (
        <BaseModal {...props.modalProps} title={props.title} sxBody={styles.body} modalProps={{ sx: styles.container({ haveData: !showOnlyGraph }) }}>
            <Grid container spacing={existMoreInformation ? 2 : 0} mt={0} justifyContent='center'>
                <Grid item xs={existMoreInformation ? 7 : 12}>
                    <img
                        src={props?.graph}
                        alt=""
                        style={styles.image}
                    />

                    {
                        props.evolution_graph && !showOnlyGraph && (
                            <>
                                <Typography variant="body1" sx={styles.titleSection}>
                                    {I18n.t('patient.detail.tabSmartPredict.cardTab.description')}
                                </Typography>

                                <Typography
                                    variant="subtitle2"
                                    component="span"
                                    sx={styles.name}
                                >
                                    {props?.description}
                                </Typography>
                            </>
                        )
                    }
                </Grid>

                {existMoreInformation && !showOnlyGraph &&
                    <Grid item xs={5} sx={styles.separator}>
                        {
                            DetailCard(
                                true,
                                props.evolution_graph ? '' : props?.description,
                                styles.removeTitle,
                                props.evolution_graph)
                        }
                    </Grid>
                }
            </Grid>

        </BaseModal>
    )
}

export { DetailModal }