import I18n from 'i18n-js'
import { isEmpty, isPngImage, isNumber, isImage } from './validator'

export const stepOneValidator = (
  smartTest,
  locale /* , provider = false */
) => {
  const errorsAux = {}
  /* title */
  if (smartTest[locale]?.name === undefined)
    errorsAux.empty = I18n.t('validators.required')
  else if (isEmpty(smartTest[locale]?.name))
    errorsAux.name = I18n.t('validators.required')

  /* description */
  if (smartTest[locale]?.description === undefined)
    errorsAux.empty = I18n.t('validators.required')
  else if (isEmpty(smartTest[locale]?.description))
    errorsAux.description = I18n.t('validators.required')

  /* providers */
  // if (provider) {
  if (isEmpty(smartTest.providers_id) && smartTest.updateProvider !== 3)
    errorsAux.providers_id = I18n.t('validators.required')
  // }

  //Icon Image
  if (smartTest.type === 2) {
    if (smartTest.url_image !== undefined) {
      if (smartTest.url_image?.type && !isPngImage(smartTest.url_image.type))
        errorsAux.url_image = I18n.t('validators.image')
      else if (smartTest.url_image?.size && smartTest.url_image.size > 1000000)
        errorsAux.url_image = I18n.t('validators.imageSize')
      if (isEmpty(smartTest.url_image?.name?.trim()))
        errorsAux.url_image = I18n.t('validators.required')
      if (smartTest.url_image === null) errorsAux.url_image = null
    }
    if (smartTest.updateProvider === undefined)
      errorsAux.updateProvider = I18n.t('validators.required')
    if (smartTest.position === undefined || smartTest.position === '')
      errorsAux.position = I18n.t('validators.required')
  }

  /* idp smart */
  if (smartTest.type === 1) {
    if (smartTest.url_image?.type && !isPngImage(smartTest.url_image.type))
      errorsAux.url_image = I18n.t('validators.image')
    else if (smartTest.url_image?.size && smartTest.url_image.size > 1000000)
      errorsAux.url_image = I18n.t('validators.imageSize')
    /* sensors */
    // if (isEmpty(smartTest.sensors_quantity))
    //   errorsAux.sensors_quantity = I18n.t('validators.required')
    /* Image 1 */
    if (isEmpty(smartTest[locale]?.instructional_picture)) {
      if (isEmpty(smartTest.url_instructional))
        errorsAux.instructional_picture = I18n.t('validators.required')
    } else if (!isImage(smartTest[locale]?.instructional_picture?.type)) {
      errorsAux.instructional_picture = I18n.t('validators.image')
    }

    /* Image 2 */
    if (
      !isImage(smartTest[locale]?.data_sensors?.type) &&
      smartTest[locale]?.data_sensors
    ) {
      errorsAux.data_sensors = I18n.t('validators.image')
    }
    delete errorsAux.providers_id
  }

  return errorsAux
}

export const stepTwoValidator = (blocks) => {
  /* true disable the button
    false enable the button
  */
  if (blocks != undefined && Object.values(blocks).length > 0) {
    if (
      Object.values(blocks).filter((block) => !block.completed).length === 0
    ) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

export const stepThreeValidator = (traductions) => {
  const listImageType = ['instructional_picture', 'data_sensors']

  let notAllowed = 0

  Object.entries(traductions ?? {}).forEach(([, localeValue]) => {
    Object.entries(localeValue).forEach(
      ([nameTranductions, valueTaductions]) => {
        if (
          listImageType.includes(nameTranductions) &&
          valueTaductions &&
          !isImage(valueTaductions?.type) &&
          valueTaductions?.name
        ) {
          notAllowed++
        }
      }
    )
  })

  return notAllowed === 0
}

export const blockValidator = (block) => {
  const errorsAux = {}

  if (
    block.indicator &&
    block.indicator === true &&
    !isNumber(block.data_source_id)
  )
    errorsAux.questionarie_id = I18n.t('validators.required')

  //type 1
  if (block.block_type === 3) {
    if (isEmpty(block.questionarie_id))
      errorsAux.questionarie_id = I18n.t('validators.required')
  }
  //type 2
  if (block.block_type === 4) {
    if (isEmpty(block.smartTest_id))
      errorsAux.smartTest_id = I18n.t('validators.required')
  }
  //type block
  if (isEmpty(block.block_type))
    errorsAux.block_type = I18n.t('validators.required')

  return errorsAux
}

/* smart questionarie module */

/* step one */
export const stepOneQuestionarieValidator = (questions) => {
  /* disabled button true, enabled false */
  if (questions) {
    if (Object.values(questions).length > 0) {
      if (
        Object.values(questions).find((question) => question.completed == false)
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    return true
  }
}

/* question */
export const questionValidator = (question) => {
  if (question.options) {
    if (Object.values(question.options).length > 0) {
      if (
        Object.values(question.options).find(
          (option) =>
            option.completed == false || Object.values(option).length === 0
        )
      ) {
        return false
      } else {
        if (!isEmpty(question.title) && !isEmpty(question.type)) {
          if (question.type != 3) {
            if (Object.values(question.options).length > 1) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

/* options */

export const optionsValidator = (option) => {
  if (!isEmpty(option.title)) {
    return true
  } else {
    return false
  }
}
