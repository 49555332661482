import { isEmail, isEmpty, isPhoneNumber } from './validator'
import { rol } from '../utils'

export const MyProfileValidator = (user, useRolId, oldEmail = null) => {
  if (useRolId === rol.center) {
    if (
      !isEmpty(user?.name) &&
      !isEmpty(user?.birth) &&
      !isEmpty(user?.phone)&&
      !isEmpty(user?.email) &&
      !isEmail(user?.email) && 
      (user?.existEmail 
        || user?.existEmail === undefined
        || !user?.existEmail && oldEmail === user?.email
      )
    ) {
      if (!isPhoneNumber(user.phone)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    if (
      !isEmpty(user?.name) &&
      !isEmpty(user?.lastname) &&
      !isEmpty(user?.birth) &&
      !isEmpty(user?.phone) &&
      !isEmpty(user?.email) &&
      !isEmail(user?.email) && 
      (user?.existEmail 
        || user?.existEmail === undefined
        || !user?.existEmail && oldEmail === user?.email
      )
    ) {
      if (!isPhoneNumber(user.phone)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
}
