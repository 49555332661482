import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Switch,
  Typography,
  Box,
} from '@mui/material'
import I18n from 'i18n-js'
import { Loader } from '..'
import { isEmail } from '../../validators/validator'

const SendEmail = ({
  title,
  description,
  open,
  close,
  email,
  change,
  loading,
  submit,
  setSwitch,
  switchEmail,
}) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth={'sm'}
      aria-labelledby="form-dialog-title"
    >
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={submit}>
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
            <TextField
              value={email || ''}
              autoFocus
              margin="dense"
              name="email"
              error={email != undefined ? isEmail(email) : false}
              label={`${I18n.t('patient.form.email')}`}
              type="email"
              fullWidth
              onChange={change}
              disabled={switchEmail}
            />
            <Box style={{ minHeight: 0, height: 5 }}>
              {email != undefined && isEmail(email) ? (
                <Typography color="error" variant="caption">
                  {I18n.t('validators.invalidEmail')}
                </Typography>
              ) : (
                ''
              )}
            </Box>
          </DialogContent>
          <span style={{ marginLeft: '12px' }}>
            <Switch color="primary" checked={switchEmail} onClick={setSwitch} />
            <Typography variant="caption" color="textPrimary">
              {I18n.t('components.sendMyself')}
            </Typography>
          </span>
          <DialogActions>
            <Button
              padding="15px"
              variant="text"
              onClick={close}
              color="primary"
            >
              {I18n.t('components.cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={email ? isEmail(email) : true}
            >
              {I18n.t('components.send')}
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}

export default SendEmail
