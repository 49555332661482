import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('profiles')
  return res
}
const show = async (id) => {
  const res = await GET(`profiles/${id}`)
  return res
}
const create = async (body) => {
  const { name, description, id_provider, indicators } = body
  const data = {
    name: name,
    description: description,
    id_provider: id_provider,
  }
  const res = await POST('profiles', data)
  if (res.data == null) {
    return res
  } else {
    const send = await POST(
      `profiles/${res.data.profile.id}/update-indicators`,
      {
        id_indicators: indicators,
      }
    )
    return send
  }
}

const edit = async (body) => {
  const { id, name, description, id_provider, indicators } = body
  const data = {
    name: name,
    description: description,
    id_provider: id_provider,
  }
  const res = await PUT(`profiles/${id}`, data)
  if (res.data == null) {
    return res
  } else {
    const send = await POST(`profiles/${id}/update-indicators`, {
      id_indicators: indicators,
    })
    return send
  }
}

const destroy = async (id) => {
  const res = await DELETE(`profiles/${id}`)
  return res
}

export default {
  list,
  show,
  create,
  edit,
  destroy,
}
