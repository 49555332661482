import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('providers')
  return res
}

const create = async (body) => {
  const data = { description: body }
  const res = await POST('providers', data)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  const data = { description: update }
  const res = await PUT(`providers/${id}`, data)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`providers/${id}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
}
