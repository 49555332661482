import { makeStyles } from '@mui/styles'
const styles = ({ drawerWidth }) => {
  const useStyles = makeStyles((theme) => ({
    navbar: {
      height: '67px',
      transition: '.15s',
      '&.MuiAppBar-root': {
        background: 'white',
        width: `calc(100% - ${drawerWidth}px)`,
        boxShadow: '0px .2px 5px #d1d1d1',
      },
      iconMenu: {
        "&.MuiIconButton-root": {
          display: 'flex',
          margingLeft: '100px',
        }
      }
    },
    user: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    infoContainer: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'flex-end',
      right: 0,
    },
    offset: theme.mixins.toolbar,
    
  }))
  return useStyles()
}

export default styles
