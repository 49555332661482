import { useEffect, useState, useContext } from 'react'
import I18n from 'i18n-js'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Box, Grid, Typography, Button, CircularProgress } from '@mui/material'
import { Card } from '@mui/material'

import { CrudsBtn } from '../../../components'
import medicalStaff from '../../../services/medicalStaff'
import { AuthContext } from '../../../context/auth'
import { canAccess } from '../../../router/permissions'
import { actionKeys, pagesKeys } from '../../../utils'
import { Icons } from '../../../utils'
import useStyles from './styles'
import { dateFormat } from '../../../utils/date'
const Show = () => {
  const classes = useStyles()
  const params = useParams()

  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const [data, setData] = useState(null)

  //permissions
  const pageId = pagesKeys.medicalstaff
  useEffect(() => {
    const getData = async () => {
      if (params.id) {
        const json = await medicalStaff.getOne(params.id)
        setData(json)
      }
    }
    getData()
  }, [])
  if (!data)
    return (
      <Box p={4}>
        <CircularProgress className={classes.circularProgress} />
      </Box>
    )
  return (
    <Box p={4}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.medicalStaff')} /{' '}
            <Link
              to="/dashboard/medical-staff"
              className={classes.textBreadcrum}
            >
              {I18n.t('breadcrumb.dashboard')}
            </Link>{' '}
            /{I18n.t('breadcrumb.detail')}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                variant="h5"
                color="primary"
                style={{ textTransform: 'capitalize' }}
              >
                {data.name} {data.lastname}
              </Typography>
            </Grid>
            <Grid item>
              {canAccess(actionKeys.create, pageId, state) && (
                <CrudsBtn
                  text={I18n.t('medicalstaff.editShort')}
                  size="medium"
                  click={() =>
                    history.push(`${history.location.pathname}/edit`)
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Card bgcolor="white">
            <Box p={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    {I18n.t('medicalstaff.show.personalInfo')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.first_name')}:
                  </Typography>
                  <Typography variant="body1">{data.name}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.last_name')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.lastname
                      ? data.lastname
                      : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.gender')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.sex
                      ? I18n.t('medicalstaff.show.male')
                      : I18n.t('medicalstaff.show.female')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.age')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.age !== null && data.birth
                      ? data.age
                      : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.date_of_birth')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.birth
                      ? dateFormat(data.birth)
                      : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.codeInsee')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.code_insee
                      ? data.code_insee
                      : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.phone_number')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.phone ? data.phone : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.mail_address')}:
                  </Typography>
                  <Typography variant="body1" className={classes.emailText}>
                    {data.email}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    {I18n.t('medicalstaff.show.professionalInfo')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.staff_type')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.type_staff?.name
                      ? data.type_staff?.name
                      : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                {data?.medical_centers[0]?.name && (
                  <Grid item xs={3}>
                    <Typography variant="subtitle2">
                      {I18n.t('medicalstaff.form.medical_center')}:
                    </Typography>
                    <Typography variant="body1">
                      {data.medical_centers[0]
                        ? data.medical_centers[0].name
                        : I18n.t('general.dataNotFound')}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.code')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.type_staff?.code
                      ? data.type_staff?.code
                      : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.codeSystem')}:
                  </Typography>
                  <Typography variant="body1">
                    {data.type_staff?.code_system
                      ? data.type_staff?.code_system
                      : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="subtitle2">
                    {I18n.t('medicalstaff.form.rpps')}:
                  </Typography>
                  <Typography variant="body1" className={classes.emailText}>
                    {data?.rpps ? data?.rpps : I18n.t('general.dataNotFound')}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '20px' }}>
                  <Button
                    variant="outlined"
                    padding="8px 15px"
                    onClick={() => history.goBack()}
                    startIcon={Icons('arrow_back')}
                  >
                    {I18n.t('medicalstaff.back')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Show
