import { Box, Modal } from '@mui/material'
import React from 'react'
import { sxStyles } from './styles'

const GenericModal = ({ open, close, children }) => {
  return (
    <Modal open={open} onClose={close}>
      <Box sx={sxStyles}>{children}</Box>
    </Modal>
  )
}

export default GenericModal
