const colors = {
  primary: '#00706f',
  darkPrimary: '#004E4D',
  lightPrimary: '#12A088',
  primaryLighten: '#00c337',
  secondary: '#008434',
  success: '#00D100',
  successLighten: '#ADF1B1',
  borderInputs: '#188040',
  successDarken: '#006800',
  successSoft: '#69AD70',
  markBackground: '#00000022',
  breadcrumColor: '#00000099',
  markIcon: '#1EE74E',
  info: '#FFE2C2',
  warning: '#FFCD00',
  warningLighten: '#FFE1C1',
  warningDarken: '#7f6600',
  danger: '#B7070D',
  dangerLighten: '#e56a76',
  dangerDarken: '#5b0306',
  care: '#FF8027',
  error: '#D31e1e',
  alert: '#C3263B',
  disabled: '#666666',
  grayMedium: '#707070',
  disabledStrong: '#6C6C6C',
  disabledLighten: '#f2f2f2',
  disabledLighten2: '#cabdc3',
  disabledBorder: '#d2d2d2',
  disabledDarken: '#333333',
  disabledDarken2: '#3A3A3A',
  disabledFull: '#999999',
  systemDefault: '#f8f8f8',
  infoDarken: '#FF9A28',
  white: '#ffffff',
  greyLighten: '#f1f3f6',
  grey: '#fafafa',
  greyDarken: '#c2c2c2',
  greyStrong: '#7d7a7a',
  greyBlue: '#DFEDE6',
  greyPlaceholder: '#A2A2A2',
  greyDivider: '#0E0E0E',
  greyFull: '#b3b4b6',
  greySoft: '#919191',
  grayLight: '#F0F0F0',
  greyColumn: '#FAFAFA',
  inputGray: '#E9E9E9',
  blueLighten: '#1890ff',
  blueInput: '#63799B',
  blueHover: 'rgb(16, 100, 178)',
  blueSent: '#63799B',
  headerGray: '#e5e6e6',
  greenBlue: '#12A088',
  green: '#4a8522',
  greenLighten: '#26C36D',
  orangeStrong: '#ff4b00',
  red: '#F21313',
  redCanceled: '#AC0707',
  black: '#000000',
}

export default colors
