export const handleDataUser = (editUser) => {
    const body = {...editUser}
    const { id_medical_center, id_medical_staff, id_related, id_patient } = editUser

    if(id_medical_center == null) delete body.id_medical_center
    if(id_medical_staff == null) delete body.id_medical_staff
    if(id_related == null) delete body.id_related
    if(id_patient == null) delete body.id_patient

    delete body.existEmail
    delete body.age
    delete body.next_change_password
    delete body.rol
    delete body.verified

    return body
  }
  