import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('questionnaires')
  return res
}

const listActive = async () => {
  const res = await GET('questionnaires')
  if (res.data) {
    return res.data.questionnaires.filter((aux) => aux.status === 'Actif')
  } else return res
}

const show = async (id) => {
  const res = await GET(`questionnaires/${id}`)
  return res
}
const create = async (body) => {
  const res = await POST('questionnaires', body)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  const res = await PUT(`questionnaires/${id}`, update)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`questionnaires/${id}`)
  return res
}
const changeStatus = async (body) => {
  const { id, status } = body
  const data = { status: status }
  const res = await POST(`questionnaires/${id}/update-status`, data)
  return res
}
export default {
  list,
  create,
  edit,
  destroy,
  show,
  changeStatus,
  listActive,
}
