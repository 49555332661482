import { Box, IconButton, Typography } from "@mui/material"
import ReactAudioPlayer from "react-audio-player"
import useStyle, { useStylesSx } from "../../style"
import constants from '../../enum'
import { Icons } from "../../../../../../utils"
import { download, saveArrayZip } from "../../../../../../utils/tools"
import { useState } from "react"
import { Loader } from "../../../../../../components"
import I18n from "i18n-js"


const NoValue = ({ classes }) => (
    <Typography
        variant="subtitle2"
        component="span"
        className={`${classes.name} ${classes.strong}`}
    >
        N/A
    </Typography>
)

const HandleTypeCard = (props) => {
    const [downloadImages, setDownloadImages] = useState(false)
    const { type, value, unit, name } = props.target
    const styles = useStylesSx()
    const classes = useStyle()

    if (value) {
        switch (type) {
            case constants.audio:
                return (
                    <Box sx={styles.audioContainer}>
                        <ReactAudioPlayer
                            controlsList="nodownload noplaybackrate"
                            className={classes.audioPlayer}
                            src={value}
                            controls
                        />

                        <IconButton
                            onClick={() => {
                                download(value, name, true)
                            }}
                            sx={styles.download}
                        >
                            {Icons('download')}
                        </IconButton>
                    </Box>
                )
            case constants.images:
                return value?.[0] ? (
                    <>
                        {value?.length > 0 && (
                            <Box className={classes.mountImages}>
                                <Box sx={styles.downloadImages}>
                                    {!downloadImages ? (
                                        <IconButton
                                            disabled={downloadImages}
                                            onClick={() => {
                                                setDownloadImages(true)
                                                saveArrayZip(name, value).then(() => {
                                                    setDownloadImages(false)
                                                })
                                            }}
                                        >
                                            {Icons('download')}
                                        </IconButton>
                                    ) : (
                                        <Loader removeMH size={20} />
                                    )}
                                </Box>

                                <IconButton
                                    onClick={() => {
                                        props.setImgList({
                                            title: name,
                                            list: value,
                                        })
                                        props.setOpenModal(true)
                                    }}
                                    className={`${classes.moreImages} ${classes.strong}`}
                                >
                                    {Icons('more-img')}
                                </IconButton>
                                <Typography
                                    variant="subtitle2"
                                    component="span"
                                    className={`${classes.name} ${classes.strong}`}
                                >
                                    {value?.length} {I18n.t('general.images')}
                                </Typography>
                            </Box>
                        )}
                        <Typography
                            variant="subtitle2"
                            component="span"
                            className={classes.name}
                        >
                            {name}
                        </Typography>
                    </>
                ) : (
                    <NoValue classes={classes} />
                )
            default:
                return (
                    <Typography
                        variant="subtitle2"
                        component="span"
                        className={`${classes.name} ${classes.strong}`}
                    >
                        {`${value} ${unit}`}
                    </Typography>
                )
        }
    }
    return <NoValue classes={classes} />
}

export { HandleTypeCard }
