import { makeStyles } from '@mui/styles'
import { colors } from '../../../../../../../../utils'

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: { color: colors.disabled },
  inputName: {
    width: '38%',
  },
  fullWidth: { width: '100%' },
  inputValue: { width: '20%' },
  inputNumberValue: { width: '29%' },
  action: {
    color: colors.danger,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '60px',
  },
  infoContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    columnGap: '16px',
  },
}))

export default useStyles
