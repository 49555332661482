import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('countries')
  return res
}

const create = async (body) => {
  const res = await POST('countries', body)
  return res
}

const edit = async (body) => {
  const { id, update } = body

  const res = await PUT(`countries/${id}`, update)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`countries/${id}`)
  return res
}
const changeStatus = async (id, status) => {
  const res = await POST(`countries/${id}/update-status`, status)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
  changeStatus,
}
