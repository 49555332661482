import { useEffect, useState, useContext, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Typography, Box, Button, Fade, CircularProgress } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import { pagesKeys, actionKeys } from '../../../utils'
import { canAccess } from '../../../router/permissions'
import { useSortForSend } from '../../../hooks/questionaries'
import {
  StepperCreate,
  LanguageSettingsForm,
  InformationGeneralQuestionary,
  QuestionaryOptions,
  AlertModal,
} from '../../../components'
import {
  stepOneValidator,
  stepTwoValidator,
} from '../../../validators/createQuestionaryValidator'
import { questionaries } from '../../../services'
import notAllHaveTraductions from '../../../hooks/traductions/notAllHaveTraductions'
import useStyles from './style'

const CreateQuestionarie = () => {
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const locale = state.locale
  const [fieldsNeedTranslation] = useState([
    {
      name: 'name',
      multiline: false,
      label: 'cruds.questionaries.create.name',
    },
    {
      name: 'instructions',
      multiline: true,
      label: 'cruds.questionaries.create.instructions',
    },
  ])
  const [steps] = useState([
    {
      step: 'cruds.questionaries.create.step.first',
      label: 'cruds.questionaries.create.stepLabel.first',
    },
    {
      step: 'cruds.questionaries.create.step.second',
      label: 'cruds.questionaries.create.stepLabel.second',
    },
    {
      step: 'cruds.questionaries.create.step.third',
      label: 'cruds.questionaries.create.stepLabel.third',
    },
  ])
  const [activeStep, setActiveStep] = useState(0)
  const [createQuestionarie, setCreateQuestionarie] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChangeCreate = (e) => {
    if (fieldsNeedTranslation.find((field) => field.name == e.target.name)) {
      setCreateQuestionarie({
        ...createQuestionarie,
        [locale]: {
          ...createQuestionarie[locale],
          [e.target.name]: e.target.value,
          locale: locale,
        },
      })
    } else {
      setCreateQuestionarie({
        ...createQuestionarie,
        [e.target.name]: e.target.value,
      })
    }
  }
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    activeStep == 0
      ? setDisabled(stepTwoValidator(createQuestionarie[locale].questions))
      : ''
  }
  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    activeStep == 1
      ? setDisabled(stepOneValidator(createQuestionarie, locale))
      : ''
  }
  const checkTraductions = () => {
    if (
      notAllHaveTraductions(
        createQuestionarie.traductions,
        true,
        false,
        false,
        createQuestionarie[locale]
      )
    ) {
      setOpenModal(true)
    } else {
      handleSubmitData()
    }
  }
  const handleSubmitData = async () => {
    setLoading(true)
    setDisabled(true)
    const res = await questionaries.create(
      useSortForSend(createQuestionarie, locale)
    )
    if (res.data) {
      setActiveStep(activeStep + 1)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!canAccess(actionKeys.create, pagesKeys.questionaries, state)) {
      history.push('/dashboard')
    }
    activeStep == 0
      ? setDisabled(stepOneValidator(createQuestionarie, locale))
      : setDisabled(stepTwoValidator(createQuestionarie[locale].questions))
  }, [createQuestionarie])
  const classes = useStyles()
  return (
    <Box p={4} m={4}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.questionaries')} /{' '}
          <Link
            to="/dashboard/questionaries/"
            className={classes.textBreadcrum}
          >
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
          /{I18n.t('cruds.questionaries.create.indicator')}
        </Typography>
      </Box>

      <Box className={classes.bodyContainer}>
        <Box className={classes.titleBarContainer}>
          <Box className={classes.titleContainer}>
            <Typography variant="subtitle1" color="textSecondary">
              {I18n.t('cruds.questionaries.create.title')}
            </Typography>
            <Typography variant="h6" color="primary" className={classes.nameText}>
              {createQuestionarie[locale]?.name
                ? createQuestionarie[locale].name
                : I18n.t('cruds.questionaries.create.indicator')}
            </Typography>
          </Box>
          <Box className={classes.stepperContainer}>
            <StepperCreate activeStep={activeStep} steps={steps} />
          </Box>
        </Box>

        {loading ? (
          <CircularProgress color="secondary" className={classes.loading} />
        ) : (
          <>
            <Box className={classes.inputsContainer}>
              <Fade in={activeStep == 0} timeout={500}>
                <Box>
                  {activeStep == 0 && (
                    <InformationGeneralQuestionary
                      change={handleChangeCreate}
                      values={createQuestionarie}
                      locale={locale}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 1} timeout={500}>
                <Box>
                  {activeStep == 1 && (
                    <QuestionaryOptions
                      questionarieValues={createQuestionarie}
                      setQuestionarie={setCreateQuestionarie}
                      setDisabled={setDisabled}
                      locale={locale}
                      questionarie
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 2} timeout={500}>
                <Box>
                  {activeStep == 2 && (
                    <LanguageSettingsForm
                      items={fieldsNeedTranslation}
                      values={createQuestionarie}
                      locale={locale}
                      setCreateData={setCreateQuestionarie}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 3} timeout={500}>
                <Box>
                  {activeStep == 3 && (
                    <Box className={classes.finishContainer}>
                      <CheckCircle className={classes.chekCircle} />
                      <Typography variant="h5" color="primary">
                        {I18n.t('components.success')}
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {I18n.t('cruds.questionaries.create.created')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Fade>
            </Box>

            <Box
              className={
                activeStep < 3
                  ? classes.actionsContainer
                  : classes.actionsContainerEnd
              }
            >
              {activeStep < 3 && (
                <Box>
                  <Button
                    variant="text"
                    color="secondary"
                    className={classes.buttonText}
                    component={Link}
                    to="/dashboard/questionaries/"
                  >
                    {I18n.t('components.cancel')}
                  </Button>
                </Box>
              )}
              <Box>
                {activeStep < 3 ? (
                  <Fragment>
                    {activeStep > 0 && (
                      <Button
                        disabled={activeStep < 2 ? false : disabled}
                        variant="outlined"
                        color="secondary"
                        className={classes.buttonContained}
                        onClick={handleBackStep}
                      >
                        {I18n.t('components.back')}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={
                        activeStep < 2 ? handleNextStep : checkTraductions
                      }
                      disabled={disabled}
                    >
                      {I18n.t('components.next')}
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    component={Link}
                    to="/dashboard/questionaries"
                  >
                    {I18n.t('cruds.questionaries.return')}
                  </Button>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('components.traductions.missingTraductionTitle')}
        message={I18n.t('components.traductions.missingTraductiontext')}
        SuccessText={I18n.t('components.yes')}
        success={handleSubmitData}
      />
    </Box>
  )
}

export default CreateQuestionarie
