import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Paper,
  IconButton,
  Typography,
  Switch,
  TextField,
  Grid,
} from '@mui/material'
import { Add, Cached, DragHandle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { DinamicForm, Switch as Switched } from '../../../../../components'
import { Icons, stepEnum } from '../../../../../utils'
import useStyles from './style'
import SimpleError from '../../../../../components/errors/simple'
import { isNumber } from '../../../../../validators/validator'

const Drag = ({
  smartTestList,
  questionariesList,
  handleDelete,
  id,
  order,
  blockType,
  onChange,
  data,
  getSmartTests,
  getQuestonaries,
  type,
}) => {
  const classes = useStyles()
  const renamedTypes = blockType?.map((type) => ({
    id: type.id,
    name: stepEnum[type.name],
  }))
  const [autoCompleteData, setAutoCompleteData] = useState({})

  //inputs
  const inputs = [
    {
      name: 'block_type',
      label: I18n.t('cruds.smartTest.form.blockType') + '(*)',
      type: 'select',
      options: renamedTypes || [],
      style: classes.blockInput,
      isLocalData: true,
    },
    {
      name: autoCompleteData.name || '',
      label: autoCompleteData.label || '',
      type: 'autocomplete',
      options: autoCompleteData.options || [],
      style: classes.selectInput,
      valueField: 'id',
    },
    {
      name: 'instructions',
      label: I18n.t('cruds.smartTest.form.instructions'),
      type: 'textArea',
      fullWidth: true,
      rows: data.block_type === 3 || data.block_type === 4 ? 3 : 4,
    },
  ]

  const handleChange = ({ target }) => {
    onChange(target, id, order)
  }

  const handleTypeBlock = () => {
    if (data.indicator === undefined && type === 2) {
      handleChange({
        target: {
          name: 'indicator',
          value: false,
        },
      })
    }

    if (data.block_type === 3) {
      setAutoCompleteData({
        name: 'questionarie_id',
        label: I18n.t('cruds.smartTest.form.questionarieSelect') + '(*)',
        options: questionariesList,
        linkCreate: '/dashboard/questionaries/create',
        linkEdit: `/dashboard/questionaries/edit/${data.questionarie_id}`,
        disabled: data.questionarie_id ? false : true,
      })
    } else if (data.block_type === 4) {
      setAutoCompleteData({
        name: 'smartTest_id',
        label: I18n.t('cruds.smartTest.form.smartTestSelect') + '(*)',
        options: smartTestList,
        linkCreate: '/dashboard/smart-test/create',
        linkEdit: `/dashboard/smart-test/edit/${data.smartTest_id}`,
        disabled: data.smartTest_id ? false : true,
      })
    }
  }

  useEffect(() => {
    handleTypeBlock()
  }, [questionariesList, smartTestList, data])

  return (
    <Paper className={classes.root}>
      <Box className={classes.sections}>
        <DragHandle color="disabled" className={classes.dragIcon} />
        <Typography variant="h5" color="primary">
          {order + 1}
        </Typography>
      </Box>

      <Grid container spacing={1} className={classes.sections}>
        <Grid item lg={4}>
          <DinamicForm
            inputs={inputs.slice(0, 1)}
            handleChange={handleChange}
            values={data}
            // errors={errors}
          />

          {type === 2 && (
            <Box className={classes.flex}>
              <Typography className={classes.indicator}>
                {I18n.t('cruds.smartTest.indicator')}
              </Typography>

              <Switch
                onClick={() =>
                  handleChange({
                    target: {
                      name: 'indicator',
                      value: !data.indicator,
                    },
                  })
                }
                checked={data.indicator ? data.indicator : false}
              />

              {data.indicator && (
                <Box className={classes.dataSourceId}>
                  <TextField
                    value={data.data_source_id ? data.data_source_id : ''}
                    label={I18n.t('cruds.smartTest.dataSourceId') + '*'}
                    name="data_source_id"
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: 'data_source_id',
                          value:
                            isNumber(e.target.value) || e.target.value === ''
                              ? e.target.value
                              : data.data_source_id,
                        },
                      })
                    }}
                    // size="small"
                    error={data.data_source_id === ''}
                  />

                  <SimpleError
                    error={data.data_source_id === ''}
                    message={I18n.t('validators.required')}
                  />
                </Box>
              )}
            </Box>
          )}
        </Grid>

        <Grid item lg={8}>
          <Box className={classes.subSections}>
            <Box className={classes.divider}>
              {data.block_type === 3 || data.block_type === 4 ? (
                <DinamicForm
                  inputs={inputs.slice(1, 2)}
                  handleChange={handleChange}
                  values={data}
                  // errors={errors}
                />
              ) : (
                <DinamicForm
                  inputs={inputs.slice(2, 3)}
                  handleChange={handleChange}
                  values={data}
                  // errors={errors}
                />
              )}
              <Box className={classes.buttonsContainer}>
                {(data.block_type === 3 || data.block_type === 4) && (
                  <Fragment>
                    <IconButton
                      onClick={
                        data.block_type === 3 ? getQuestonaries : getSmartTests
                      }
                      color="primary"
                    >
                      <Cached />
                    </IconButton>
                    <IconButton
                      component={Link}
                      target={'_blank'}
                      to={autoCompleteData.linkCreate || ''}
                      color="secondary"
                    >
                      <Add />
                    </IconButton>
                    <IconButton
                      disabled={autoCompleteData.disabled}
                      component={Link}
                      target={'_blank'}
                      to={autoCompleteData.linkEdit || ''}
                    >
                      {Icons('show')}
                    </IconButton>
                  </Fragment>
                )}
                <IconButton onClick={() => handleDelete(id)}>
                  {Icons('delete')}
                </IconButton>
              </Box>
            </Box>
            {(data.block_type === 3 || data.block_type === 4) && (
              <Box className={classes.sections}>
                <DinamicForm
                  inputs={inputs.slice(2, 3)}
                  handleChange={handleChange}
                  values={data}
                  // errors={errors}
                />
              </Box>
            )}
          </Box>
          <Box
            mt={data.block_type === 3 || data.block_type === 4 ? 2 : 5}
            className={classes.flex}
          >
            <Box className={classes.flexGrow} />
            {type === 2 && (
              <Box mr={1}>
                <Switched
                  onClick={() =>
                    handleChange({
                      target: {
                        name: 'required',
                        value: !data.required,
                      },
                    })
                  }
                  enable={data.required}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Drag
