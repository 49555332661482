import { useEffect, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import {
  RadioButtonUnchecked,
  AddCircle,
  HighlightOff,
  CheckBoxOutlineBlank,
} from '@mui/icons-material'
import I18n from 'i18n-js'
import { InputForm } from '../../../../../../../../components'
import { optionsValidator } from '../../../../../../../../validators/smartTestValidator'
import useStyles from './style'

const OptionQuestion = ({
  id,
  create,
  remove,
  cardItems,
  setCardItems,
  type,
  values,
  sizeArrayOptions,
  numberTitle,
}) => {
  const [option, setOption] = useState(values)

  const handleNumberOfOptions = () => {
    if (numberTitle == sizeArrayOptions && sizeArrayOptions < 3) {
      return {
        0: () => {
          create()
        },
      }
    } else if (numberTitle == sizeArrayOptions && sizeArrayOptions > 2) {
      return {
        0: () => {
          create()
        },
        1: () => {
          delete cardItems.options[id]
          remove(id)
        },
      }
    } else {
      return {
        0: () => {
          delete cardItems.options[id]
          remove(id)
        },
      }
    }
  }
  const handleIcon = () => {
    if (numberTitle == sizeArrayOptions && sizeArrayOptions < 3) {
      return { 1: <AddCircle color="secondary" /> }
    } else if (numberTitle == sizeArrayOptions && sizeArrayOptions > 2) {
      return { 1: <AddCircle color="secondary" />, 2: <HighlightOff /> }
    } else {
      return { 1: <HighlightOff /> }
    }
  }
  const handleChange = (e) => {
    setOption({ ...option, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (optionsValidator(option) || type === 3) {
      option.completed = true
    } else {
      option.completed = false
    }
    setCardItems({
      ...cardItems,
      options: { ...cardItems.options, [id]: option },
    })
  }, [option])
  const actions = handleNumberOfOptions()
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.infoContainer}>
        {type == 1 && <RadioButtonUnchecked color="disabled" />}
        {type == 2 && <CheckBoxOutlineBlank color="disabled" />}
        <InputForm
          name="title"
          variant="outlined"
          color="secondary"
          label={
            type === 3
              ? I18n.t('cruds.smartTest.form.thirdOption')
              : I18n.t('cruds.smartTest.questions.form.option') +
                ' ' +
                (numberTitle || '') +
                '(*)'
          }
          onChange={handleChange}
          value={option?.title != undefined ? option.title : ''}
          size="small"
          disabled={type === 3 ? true : false}
          className={type === 3 ? classes.fullWidth : classes.inputName}
        />
      </Box>
      {type < 3 && (
        <Box className={classes.buttonsContainer}>
          {Object.values(handleIcon()).map((icon, index) => {
            return (
              <IconButton
                onClick={actions[index]}
                size="small"
                className={classes.action}
                key={index}
              >
                {icon}
              </IconButton>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default OptionQuestion
