import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { Grid, Typography, MenuItem, FormControl, Select } from '@mui/material'
import { Loader, VerticalTab } from '../../../../components'
import I18n from 'i18n-js'
import DialogPdf from './dialogPdf'
import TimeLine from './timeLine'
import FirtsIndicators from './firtsIndicators'
import IndicatorBalance from '../indicatorBalance'
import { images } from '../../../../utils'
import Stadistics from '../../../../components/cardStadistics'
import CardTab from '../cardTab'
import { handleFragilite } from '../methods'
import { useStyles } from './styles'
import BaseModal from '../../../../components/baseModal'
import { GraphicWithPreview } from '../components/graphicWithPreview'

function SmartPredict(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [date, setDate] = useState(null)
  const [dateselected, setDateSelected] = useState()
  const [open, setOpen] = useState(false)

  const [indicatorsDates, setDates] = useState(null)
  // const [otherIndicators, setOtherIndicators] = useState(null)
  const [timeline, setTimeline] = useState(null)
  const [firstIndicators, setFirstIndicators] = useState(null)
  const [tugRisque, setTugRisque] = useState(null)
  // const [temporalParameters, setTemporalParameters] = useState(null)
  const [isActualSP, setIsActualSP] = useState(true)
  const [historyId, setHistoryId] = useState(null)
  const [urlPDF, setUrlPDF] = useState({
    pdf: null,
    zip: null,
  })
  const [stadistic, setStadistic] = useState({})
  const [dataCard, setDataCard] = useState({})
  const [chartModal, setChartModal] = useState({
    open: false,
    image: '',
  })
  const findDate = props.lastUpdates?.find((date) => date?.id == dateselected)
  const updateDate = findDate?.value
  const dateTug = findDate?.dateTug

  useEffect(() => {
    async function onLoad() {
      try {
        setIsLoading(true)
        setDates(props?.arrayDates || [])

        setDateSelected(props.arrayDates?.[0])
        setDate(
          props.arrayDates?.[0] ? new Date(props.arrayDates?.[0]) : undefined
        )
      } catch (e) {
        //error
      }
      setIsLoading(false)
    }
    onLoad()
  }, [])

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true)
      if (date != null) {
        let data = await loadDataSmartPredictByDate(date)

        if (data) {
          const {
            risque_chute,
            id,
            url_report,
            fragilite,
            equilibre,
            infTab,
            ihe_xdm_url,
            bioimpedancia

          } = data
          // setOtherIndicators(data.profiles)
          setStadistic({
            fragilite: {
              image: images.StadisticsStand,
              bad: I18n.t(
                'patient.detail.tabSmartPredict.stadistic.fragilite.bad'
              ),
              stable: I18n.t(
                'patient.detail.tabSmartPredict.stadistic.fragilite.stable'
              ),
              good: I18n.t(
                'patient.detail.tabSmartPredict.stadistic.fragilite.good'
              ),
              ...fragilite.fragility_score,
            },
            equilibre: {
              image: images.StadisticsArmsUp,
              bad: I18n.t(
                'patient.detail.tabSmartPredict.stadistic.equilibre.bad'
              ),
              good: I18n.t(
                'patient.detail.tabSmartPredict.stadistic.equilibre.good'
              ),
              ...equilibre.inidice_stabilite,
            },
            risque_chute: {
              image: images.StadisticsFall,
              bad: I18n.t(
                'patient.detail.tabSmartPredict.stadistic.risqueChute.bad'
              ),
              good: I18n.t(
                'patient.detail.tabSmartPredict.stadistic.risqueChute.good'
              ),
              ...risque_chute.tug_risque,
            },
          })

          let newInf = { 
            ...infTab,
            bioimpedancia
          }

          Object.entries(infTab)?.map((item) => {
            if (item[0] === 'fragilite') {
              newInf = {
                ...newInf,
                ...handleFragilite(item),
              }
            }
          })

          setDataCard(newInf)

          setTimeline(risque_chute.timeline)
          setFirstIndicators(risque_chute.temps_tug)
          setTugRisque(risque_chute.tug_risque)
          // setTemporalParameters(temporalParameters)
          setHistoryId(id)
          setUrlPDF({
            pdf: url_report,
            zip: ihe_xdm_url,
          })
          setIsActualSP(false)
          if (date) {
            if (
              props.arrayDates[0] ===
              format(date, I18n.t('general.dateFormatGeneral'))
            ) {
              setIsActualSP(true)
            }
          }
        }
      }
      setIsLoading(false)
    }
    onLoad()
  }, [date])

  const classes = useStyles()
  
  const loadDataSmartPredictByDate = async (dateselected) => {
    if (dateselected != 'Invalid Date') {
      let dateformat = format(
        new Date(dateselected),
        I18n.t('general.dateFormatGeneral')
      )
      let data = []
      props.patient.smartPredictIndicators.forEach((element) => {
        if (dateformat == element.date_tug) {
          data = element
          return
        }
      })
      return data
    }
  }

  const handleChange = (event) => {
    setDateSelected(event.target.value)
    setDate(new Date(event.target.value))
  }
  const handleCloseChart = () => {
    setChartModal({
      open: false,
      image: '',
    })
  }
  const openTheChart = (evolution_graph) => {
    setChartModal({
      open: true,
      image: evolution_graph,
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  function renderBody() {
    const items = [
      {
        label: 'patient.detail.tabSmartPredict.fragility',
        info: (
          <CardTab
            data={
              dataCard.fragilite
                ? dataCard.fragilite?.filter(
                    (item) => !!item?.value || !!item?.graph
                  )
                : []
            }
          />
        ),
        icon: '',
      },
      {
        label: 'patient.detail.subTabs.tabBalance',
        info: (
          <IndicatorBalance
            patient={props.patient}
            dates={
              date ? format(date, I18n.t('general.dateFormatGeneral')) : ''
            }
          />
        ),
        icon: '',
      },
      {
        label: 'patient.detail.tabSmartPredict.marcha',
        info: (
          <CardTab
            data={
              dataCard.marche
                ? dataCard.marche?.filter((item) => !!item?.value || !!item?.graph)
                : []
            }
          />
        ),
        icon: '',
      },
      {
        label: 'patient.detail.tabSmartPredict.psychological',
        info: (
          <CardTab
            value={false}
            data={
              dataCard.psychologique
                ? dataCard.psychologique?.filter((item) => !!item?.graph)
                : []
            }
          />
        ),
        icon: '',
      },
      {
        label: 'patient.detail.tabSmartPredict.impedance',
        info: (
          <GraphicWithPreview
            data={
              dataCard.bioimpedancia
                ? dataCard.bioimpedancia
                .filter(item => !!item.graph && !!item.id)
                .map(item => ({
                  ...item,
                  ...(!item.description && !item.evolution_graph) && {removeCard:true},
                }))
                : []
            }
          />
        ),
        icon: '',
      },
    ]
    
    return (
      <div style={{ flex: 1 }}>
        <BaseModal
          title={I18n.t('general.evolutionChart')}
          open={chartModal.open}
          onClose={handleCloseChart}
        >
          <img src={chartModal.image} />
        </BaseModal>
        <Grid container direction="row" justifyContent="flex-start">
          {indicatorsDates?.length > 0 && (
            <>
              <Grid item lg={6} md={6} xs={12}>
                <Typography variant="body" className={classes.title}>
                  {I18n.t('patient.detail.tabSmartPredict.dataForDate')}
                </Typography>
                <FormControl variant="standard" className={classes.formControl}>
                  <Select
                    id="select"
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={dateselected}
                    onChange={handleChange}
                  >
                    {date &&
                      indicatorsDates?.map((date, item) => {
                        return (
                          <MenuItem
                            selected={item == 0 ? true : false}
                            key={item}
                            value={date}
                          >
                            {format(
                              new Date(date + 'Z'),
                              I18n.t('general.dateTimeFormat')
                            )}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ width: '100%' }}
                  mt={1}
                >
                  {props.lastUpdates?.length > 0
                    ? `${I18n.t('general.lastUpdate')}: ${format(
                        new Date(updateDate),
                        I18n.t('general.dateTimeFormat')
                      )}`
                    : ''}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                {/* {(urlPDF.pdf || urlPDF.zip) && ( */}
                <DialogPdf
                  url={urlPDF.pdf}
                  download={urlPDF.zip}
                  patientId={props.patient.id}
                  isActualSP={isActualSP}
                  historyId={historyId}
                  date={updateDate}
                  downloadData={props.downloadData}
                  loadingDownload={props.loadingDownload}
                  dateTug={dateTug}
                />
                {/* )} */}
              </Grid>
              <Grid item lg={12}>
                <Grid container>
                  {Object.values(stadistic)?.map((item, ind) => (
                    <Grid key={ind} item lg={4} md={6} sm={6} xs={12}>
                      <Stadistics data={item} onClick={openTheChart} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        {date != null && (
          <>
            <FirtsIndicators
              patientId={props.patient.id}
              firstIndicators={firstIndicators}
              tugRisque={tugRisque}
              date={date}
              onClick={openTheChart}
            />

            <TimeLine
              patientId={props.patient.id}
              timeline={timeline}
              date={date}
            />
          </>
        )}

        {indicatorsDates?.length > 0 && (
          <>
            <VerticalTab items={items} />
            {/* <GraphicIndicator
              ban={props.ban}
              setBan={props.setBan}
              patientId={props.patient.id}
              date={date}
            /> */}
          </>
        )}

        {indicatorsDates?.length === 0 && (
          <Typography variant="body1" color="textSecondary">
            {I18n.t('general.notFound')}
          </Typography>
        )}
      </div>
    )
  }

  return isLoading ? <Loader /> : renderBody()
}

export default SmartPredict
