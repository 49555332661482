import { useEffect, useState } from 'react'
import patientsService from '../../../../services/patients'

export const useQuestionnairesDates = (id) => {
  const [data, setData] = useState({
    loading: false,
    array: [],
  })

  useEffect(() => {
    setData((prev) => ({ ...prev, loading: true }))
    patientsService
      .getQuestionnaireDates(id)
      .then((response) => {
        if (!response.error) {
          setData((prev) => ({ ...prev, array: response.data.dates }))
        }
      })
      .finally(() => {
        setData((prev) => ({ ...prev, loading: false }))
      })
  }, [])
  return data
}
