import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const Styles = ({ drawerWidth }) => {
  const useStyles = makeStyles({
    drawerOpen: {
      width: drawerWidth,
      background: `linear-gradient( ${colors.primary}  0%, ${colors.secondary} 100%)`,
      transition: '.15s',
      overflowX: 'hidden',
      overflowY: 'auto',
      // color: colors.white,
      '&&::-webkit-scrollbar': { width: '4px' },
      '&&::-webkit-scrollbar-thumb': {
        background: 'rgba(0,0,0,.3)',
        borderRadius: '3px',
      },
    },
    drawerClose: {
      width: 78,
      background: `linear-gradient( ${colors.primary} 0%, ${colors.secondary} 100%)`,
      transition: '.15s',
      overflowX: 'hidden',
      color: colors.white,
    },
    LogoOpen: {
      width: '152px',
      display: 'block',
      margin: 'auto',
    },
    LogoClose: {
      width: '38px',
      display: 'block',
      margin: 'auto',
    },
  })

  return useStyles()
}

export default Styles
