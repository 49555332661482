import { GET, PUT, POST, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('users')
  return res
}

const create = async (body) => {
  const res = await POST('register', body)
  return res
}
const destroy = async (body) => {
  const res = await DELETE(`users/${body}`)
  return res
}

const show = async (id) => {
  const res = await GET(`users/${id}`)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  
  const res = await PUT(`users/${id}`, update)
  return res
}

const checkEmail = async (email) => {
  const res = await GET(`check-email/User/${email}`)
  return res
}
const resetPassword = async (body) => {
  const { id, password } = body
  const res = await POST(`user/${id}/change-password`, password)
  return res
}
export default {
  show,
  edit,
  resetPassword,
  list,
  create,
  destroy,
  checkEmail,
}
