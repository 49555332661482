import { forwardRef, useState } from 'react'
import OptionQuestion from '../../optionQuestion'

const initialLengthofOptions = (values) => {
  if (values.options) {
    if (Object.values(values.options).length > 0) {
      return Object.values(values.options).length
    } else {
      return 2
    }
  } else {
    return 2
  }
}

const setInitialOptions = (numberOfOptions) => {
  const options = []
  for (let i = 0; i < numberOfOptions; i++) {
    options.push({ id: i })
  }
  return options
}

const sendInformation = (cardItems, index, id) => {
  if (cardItems.options) {
    const informationKey = Object.keys(cardItems.options)[index]
    const information = Object.values(cardItems.options)[index]
    delete cardItems.options[informationKey]
    if (information != undefined) {
      cardItems.options[id] = information
      return information
    } else {
      cardItems.options[id] = {}
      return {}
    }
  } else return {}
}

// eslint-disable-next-line react/display-name
const SimpleSelection = forwardRef(({
  cardItems,
  setCardItems,
  questionarieValues,
  questionId,
  deleteOption,
  setDeleteOption,
  questionTypes,
}, ref) => {
  const [numberOfOptions, setNumberOfOptions] = useState(
    initialLengthofOptions(cardItems)
  )
  const [options, setOptions] = useState(setInitialOptions(numberOfOptions))
  const [sizeArrayOptions, setSizeArrayOptions] = useState(options.length)

  const handleCreateOption = () => {
    setSizeArrayOptions(options.length + 1)
    setOptions([...options, { id: numberOfOptions }])
    setNumberOfOptions(numberOfOptions + 1)
  }
  const handleRemoveOption = (id) => {
    //minus one of the length of the options array
    setSizeArrayOptions(options.length - 1)
    //filter whithout option of id
    const optionsRemoved = options.filter((option) => option.id != id)
    //get the traduction field of that field
    const traductions = Object.values(questionarieValues.traductions || {})
    const traductionskeys = Object.keys(questionarieValues.traductions || {})
    //if is the last option minus one of number of options
    if (id + 1 == numberOfOptions) {
      setNumberOfOptions(numberOfOptions - 1)
    }
    setOptions([...optionsRemoved])
    //delete the traduction of the field
    if (traductions.length > 0) {
      traductions.map((traduction, index) => {
        delete questionarieValues.traductions?.[traductionskeys[index]]
          ?.questions?.[questionId]?.options?.[id]
      })
    }
    //this is a flag
    setDeleteOption(deleteOption + 1)
  }
  return (
    <div ref={ref}>
      {options.map((option, index) => {
        return (
          <OptionQuestion
            id={option.id}
            sizeArrayOptions={sizeArrayOptions}
            create={handleCreateOption}
            remove={handleRemoveOption}
            cardItems={cardItems}
            setCardItems={setCardItems}
            values={sendInformation(cardItems, index, option.id)}
            type={cardItems.type}
            key={option.id}
            questionId={questionId}
            numberTitle={index + 1}
            questionTypes={questionTypes}
          />
        )
      })}
    </div>
  )
})

export default SimpleSelection
