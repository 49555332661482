import { Box, IconButton, MenuItem, TextField } from '@mui/material'
import I18n from 'i18n-js'
import { Icons } from '../../../../../utils'

const SelectItem = ({
  change,
  label,
  list,
  translation,
  value,
  clear,
  deleteFalse,
  width,
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <TextField
        // size="small"
        margin="normal"
        value={value}
        onChange={change}
        select
        sx={{
          width: width ?? { xs: '25ch', md: '19ch', lg: '25ch' },
          marginRight: '16px',
          '& .MuiSvgIcon-root': {
            '&.MuiSelect-icon': {
              display: !deleteFalse && value && 'none',
            },
          },
        }}
        label={label}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                display: deleteFalse || !value ? 'none' : 'flex',
              }}
              onClick={clear}
            >
              {Icons('clear')}
            </IconButton>
          ),
        }}
      >
        {list?.map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {translation
              ? I18n.t(`patient.detail.tabTip.${option.key}`)
              : option.name}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}

export default SelectItem
