import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router'
import { Box, IconButton, Typography } from '@mui/material'
import { Cached } from '@mui/icons-material'
import I18n from 'i18n-js'
import { canAccess } from '../../router/permissions'
import { AuthContext } from '../../context/auth'
import homePredict from '../../services/homePredict'
import { SearchBar, CardHomePredict, SnackBar, Loading } from '../../components'
import { pagesKeys, actionKeys } from '../../utils'
import useStyles from './style'
const HomePredict = () => {
  const classes = useStyles()

  //state
  const { state } = useContext(AuthContext)
  const pageId = pagesKeys.homePredict
  const history = useHistory()

  const [loading, setloading] = useState(true)
  const [data, setData] = useState({})
  const [rotate, setRotate] = useState(false)

  //snackBar
  const [message, setMessage] = useState({ text: '', variant: 'success' })
  const [show, setShow] = useState(false)

  //search
  const [search, setSearch] = useState('')

  //fetch
  const getResponse = async () => {
    const res = await homePredict.list()
    if (res.data) {
      setData(res.data.filter((dat) => dat.patient_home_predict))
    } else {
      setData([])
      setMessage({
        text: 'homePredict.errorUpdatePatients',
        variant: 'warning',
      })
      setShow(true)
    }
  }
  //methods
  const refreshData = async () => {
    setRotate(true)
    await getResponse()
    setRotate(false)
  }

  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }

  const filterData = (data) => {
    return data.filter(
      (item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.lastname.toLowerCase().includes(search.toLowerCase())
    )
  }
  useEffect(() => {
    const accessAsync = async () => {
      if (canAccess(actionKeys.list, pageId, state)) {
        await getResponse()
        setloading(false)
      } else {
        history.push('/dashboard')
      }
    }

    accessAsync()
  }, [])
  
  if (!loading) {
    return (
      <Box p={4}>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.homePredict')} /{' '}
          {I18n.t('breadcrumb.dashboard')}
        </Typography>

        <Box className={classes.header}>
          <Box className={classes.titleContainer}>
            <Typography variant="h5" color="primary">
              {I18n.t('homePredict.title') + ' '}
            </Typography>
            {canAccess(actionKeys.refresh, pageId, state) && (
              <IconButton color="primary" onClick={refreshData} size="small">
                <Cached
                  id="rotate"
                  className={rotate ? classes.iconRotate : ''}
                />
              </IconButton>
            )}
          </Box>
          <Box className={classes.searchBar}>
            <SearchBar change={handleChangeSearchbar} />
          </Box>
        </Box>
        <Box mt={4} className={classes.body}>
          {filterData(data).length > 0 ? (
            filterData(data).map((item) => {
              return (
                <CardHomePredict
                  data={item}
                  key={item.id}
                  refresh={canAccess(actionKeys.refresh, pageId, state)}
                />
              )
            })
          ) : (
            <Typography variant="body1" color="textSecondary">
              {I18n.t('general.notFound')}
            </Typography>
          )}
        </Box>
        <SnackBar
          text={I18n.t(message.text)}
          severity={message.variant}
          show={show}
          HandleShow={setShow}
          time={1500}
        />
      </Box>
    )
  } else {
    return <Loading />
  }
}

export default HomePredict
