import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
  formLogin: {
    width: '90%',
    // '& .MuiInput-root:hover:not(.Mui-disabled):before': {
    //   borderBottom: `3px solid ${colors.lightPrimary}`,
    // },
    '& .MuiInputBase-root': {
      background: colors.inputGray,
    },
    '& .MuiInput-input': {
      height: '40px',
      padding: '4px 35px 4px 10px',
      color: colors.blueInput,
    },
  },
  submit: {
    color: colors.white,
    fontWeight: '400',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    // ...globalStyles.sendSubmit,
  },
  inputPassword: {
    '&.MuiBox-root .MuiInputBase-root': {
      paddingRight: 0,
    },
  },
  arrowIcon: {
    padding: '4px',
    fontSize: '20px',
    fontWeight: '800',
    '& .MuiSvgIcon-root': {
      '& path': {
        stroke: 'white',
        strokeWidth: '1px',
      },
      fontSize: '1em',
      fontWeight: '800',
    }
  },
  link: {
    '&.MuiButton-root': {
      color: colors.lightPrimary,
    },
  },
  error04Item: { width: '100%' },
  icon: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: 0,
      background: 'none',
      borderRadius: 0,
    },
  },
}))

export default useStyles
