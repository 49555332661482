import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  TextField,
  Typography,
} from '@mui/material'
import './styles.css'
import { useBody } from './body'
import { useStyles } from './styles'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import I18n from 'i18n-js'
import { parametersTypes } from './contants'
import { Icons } from '../../../../utils'
import InputFile from '../../../../components/file'

const ModalParameters = (props) => {
  const body = useBody({ defaultValue: props.defaultValue })
  const styles = useStyles()

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: '700px',
        },
      }}
      sx={styles.container}
      open={!!props.open}
      onClose={() => {
        body.closeModal()
        props.onClose()
      }}
    >
      <Box className="dividerB p3">
        <Typography variant="h6" fontWeight="medium">
          {I18n.t('breadcrumb.parameter')}:{' '}
          <span className="span-title">{body.data?.general?.key ?? ''}</span>
        </Typography>
      </Box>

      <Box className="p3">
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          id="key"
          label={I18n.t('components.modal.key')}
          type="text"
          name="key"
          fullWidth
          onChange={(e) => {
            const test =
              new RegExp('^[a-zA-Z0-9_]+$', 'gi').test(e.target.value) ||
              e.target.value === ''

            if (test) {
              const { name, value } = e.target
              body.handleForm({ type: 'general', name, value })
            }
          }}
          error={!!body.errors?.general?.key}
          helperText={body.errors?.general?.key}
          value={body.data?.general?.key ?? ''}
        />
      </Box>

      <TabContext value={body.tabValue}>
        <TabList
          // variant="fullWidth"
          className="dividerB"
          onChange={body.handleTabs}
        >
          <Tab
            label={I18n.t('cruds.parameter.text')}
            value={parametersTypes.text}
          />
          <Tab
            label={I18n.t('cruds.parameter.file')}
            value={parametersTypes.file}
          />
          <Tab
            label={I18n.t('cruds.parameter.json')}
            value={parametersTypes.json}
          />
        </TabList>

        <DialogContent dividers sx={styles.dialogContent}>
          <TabPanel className="rp gap2" value={parametersTypes.text}>
            <Grid container spacing={3}>
              {body.sortLanguages.map((item, key) => (
                <Grid key={key} item xs={6}>
                  <TextField
                    variant="outlined"
                    id={item.locale}
                    name={item.locale}
                    label={`${I18n.t('components.modal.valueOptional')} ${
                      item.locale === body.state.locale ? '(*)' : ''
                    }`}
                    type="text"
                    onChange={(e) => {
                      const { name, value } = e.target
                      body.handleForm({
                        type: 'text',
                        name,
                        value,
                        noRequired: item.locale !== body.state.locale,
                      })
                    }}
                    fullWidth
                    inputProps={{
                      maxLength: 250,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {Icons(item.locale, true)}
                        </InputAdornment>
                      ),
                    }}
                    error={!!body.errors?.text?.[item.locale]}
                    helperText={body.errors?.text?.[item.locale]}
                    value={body.data?.text?.[item.locale] ?? ''}
                  />
                </Grid>
              ))}
            </Grid>
          </TabPanel>

          <TabPanel className="rp flex fw gap2" value={parametersTypes.file}>
            <Grid container spacing={3}>
              {body.sortLanguages.map((item, key) => (
                <Grid xs={6} item key={key}>
                  <Box className="flex fullW alignC">
                    <InputFile
                      removeMargins
                      label={`${I18n.t('components.modal.valueOptional')} ${
                        item.locale === body.state.locale ? '(*)' : ''
                      }`}
                      className="r-mt"
                      name={item.locale}
                      onChange={(e) => {
                        body.handleForm({
                          type: 'file',
                          name: item.locale,
                          value: e,
                          noRequired: item.locale !== body.state.locale,
                        })
                      }}
                      errorMessage={body.errors?.file?.[item.locale]}
                      externalError={!!body.errors?.file?.[item.locale]}
                      value={body.data?.file?.[item.locale] ?? ''}
                      all
                      startIcon={item.locale}
                    />

                    {body.data?.file?.[item.locale] && (
                      <IconButton
                        className="colorblueInput"
                        onClick={() => body.openLink(item)}
                      >
                        {Icons('show')}
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </TabPanel>

          <TabPanel className="rp" value={parametersTypes.json}>
            <Grid container spacing={3}>
              {body.sortLanguages?.map((item, key) => (
                <Grid key={key} item xs={6}>
                  <Box className="flex fullW gap">
                    {/* <Icon className="mt2">{Icons(item.locale, true)}</Icon> */}

                    <TextField
                      variant="outlined"
                      id={item.locale}
                      name={item.locale}
                      label={`${I18n.t('components.modal.valueOptional')} ${
                        item.locale === body.state.locale ? '(*)' : ''
                      }`}
                      type="text"
                      multiline
                      rows={4}
                      onChange={(e) => {
                        const { name, value } = e.target
                        body.handleForm({
                          type: 'json',
                          name,
                          value,
                          noRequired: item.locale !== body.state.locale,
                        })
                      }}
                      fullWidth
                      error={!!body.errors?.json?.[item.locale]}
                      helperText={body.errors?.json?.[item.locale]}
                      value={body.data?.json?.[item.locale] ?? ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {Icons(item.locale, true)}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        </DialogContent>

        <DialogActions className="py3">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => {
              body.closeModal()
              props.onClose()
            }}
          >
            {I18n.t('components.cancel')}
          </Button>

          <LoadingButton
            onClick={(e) => {
              e.preventDefault()
              props.submit({
                type: body.tabValue,
                data: { ...body.data.general, ...body.data?.[body.tabValue] },
                successSend: (value) => {
                  if (value) {
                    body.closeModal()
                  }
                },
              })
            }}
            loading={!!props.loadButton}
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            disabled={!!props.loadButton || body.disabledButton()}
          >
            {props.isUpdate
              ? I18n.t('components.update')
              : I18n.t('components.increment')}
          </LoadingButton>
        </DialogActions>
      </TabContext>
    </Dialog>
  )
}

export { ModalParameters }
