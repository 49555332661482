import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  select: {
    '& > div': {
      display: 'inline-flex', // this shows the icon in the SelectInput but not the dropdown
    },
    '&.MuiOutlinedInput-input': {
      border: '2px solid #000'
    }
  },
})

export default useStyles
