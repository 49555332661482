import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from '@mui/material'
import { Phone, MailOutline } from '@mui/icons-material'
import { ModalForm, CrudsBtn, AlertMenu } from '..'
import { Icons } from '../../utils'
import contactMedical from '../../services/contactMedical'
import useStyles from './styles'
const AddPerson = ({
  validator,
  data,
  setData,
  title,
  subtitle,
  button,
  personFields = [],
}) => {
  const classes = useStyles()

  //form
  const [showForm, setShowForm] = useState(false)
  const [createPerson, setCreatePerson] = useState({})
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [currentField, setCurrentField] = useState('')
  const [errors, setErrors] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [deleteEmail, seteDeleteEmail] = useState()
  const [Hasclose, setHasClose] = useState(false)
  const [editedEmail, setEditedEmail] = useState()
  const handleOpenForm = () => {
    setShowForm(true)
    setHasClose(false)
  }
  const handleCloseForm = () => {
    setIsEdit(false)
    setShowForm(false)

    createPerson.phone ? setCreatePerson({ phone: '' }) : setCreatePerson({})
    setErrors({})
    setHasClose(true)
  }
  const handleChange = (e) => {
    if (e.target) {
      setCreatePerson({ ...createPerson, [e.target.name]: e.target.value })
      setCurrentField(e.target.name)
    } else {
      setCreatePerson({ ...createPerson, phone: e })
      setCurrentField('phone')
    }
  }
  const handleEditPerson = (email) => {
    setEditedEmail(email)
    setIsEdit(true)
    const update = Object.values(data.persons).find((dat) => dat.email == email)
    setCreatePerson(update)
    handleOpenForm()
  }
  const handleDeleteClick = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
    seteDeleteEmail('')
  }
  const handleDeletePerson = async () => {
    handleDeleteClose()
    const deleted = Object.values(data.persons).find(
      (dat) => dat.email == deleteEmail
    )
    if (deleted.id) {
      await contactMedical.destroy(deleted.id)
    }
    const deletePerson = data.persons.filter(
      (person) => person.email != deleteEmail
    )
    setData({
      ...data,
      persons: deletePerson,
    })
  }

  const handleSendPerson = async (e) => {
    e.preventDefault()
    if (!isEdit) {
      setData({
        ...data,
        persons: [...(data.persons || []), createPerson],
      })
    } else {
      const updatePerson = data.persons.map((person) => {
        if (person.email == editedEmail) {
          return createPerson
        } else {
          return person
        }
      })
      setData({
        ...data,
        persons: updatePerson,
      })
    }

    handleCloseForm()
  }
  useEffect(() => {
    const validationAsync = async () => {
      const validations = await validator(
        createPerson,
        currentField,
        errors,
        data,
        isEdit
      )
      if (validations.valid) {
        setDisabledSubmit(false)
      } else {
        setDisabledSubmit(true)
      }
      if (!Hasclose) {
        setErrors(validations.errors)
      }
    }

    validationAsync()
  }, [createPerson])

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.titleContainer}>
          <Typography variant="subtitle1" color="textSecondary">
            {title}
          </Typography>
         
        </Box>
        <CrudsBtn text={button} click={handleOpenForm} />
      </Box>
      <Box className={classes.body}>
        {data.persons &&
          data.persons.map((person, index) => {
            return (
              <Card className={classes.personContainer} key={index}>
                <CardHeader
                  title={(person.name || '') + ' ' + (person.lastname || '')}
                  className={classes.cardHeader}
                  action={
                    <Box>
                      <IconButton
                        color="secondary"
                        className={classes.edit}
                        onClick={() => handleEditPerson(person.email)}
                      >
                        {Icons('edit')}
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          seteDeleteEmail(person.email)
                          handleDeleteClick(e)
                        }}
                      >
                        {Icons('delete')}
                      </IconButton>
                    </Box>
                  }
                />

                <CardContent>
                  <Box className={classes.infoText} id="phone">
                    <Phone />
                    <Typography variant="body1" color="textPrimary">
                      {person.phone}
                    </Typography>
                  </Box>
                  <Box className={classes.infoText}>
                    <MailOutline />
                    <Typography variant="body1" color="textPrimary">
                      {person.email?.length > 28
                        ? person.email.slice(0, 28) + '...'
                        : person.email}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            )
          })}
      </Box>

      <ModalForm
        inputs={personFields}
        open={showForm}
        close={handleCloseForm}
        tittle={subtitle}
        type={2}
        values={createPerson}
        change={handleChange}
        submit={handleSendPerson}
        disabled={disabledSubmit}
        errors={errors}
      />

      <AlertMenu
        anchor={anchor}
        close={handleDeleteClose}
        action={handleDeletePerson}
      />
    </Box>
  )
}

export default AddPerson
