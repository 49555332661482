const TeleExpertiseTypes = {
  DOCUMENT: 'DOCUMENT',
  AUDIO: 'AUDIO',
  IMAGE: 'IMAGE',
}
const TeleExpertiseEquivalenceTypes = {
  smartPredict: TeleExpertiseTypes.DOCUMENT,
  right_ear: TeleExpertiseTypes.IMAGE,
  left_ear: TeleExpertiseTypes.IMAGE,
  extended: TeleExpertiseTypes.AUDIO,
  lungs: TeleExpertiseTypes.AUDIO,
  heart: TeleExpertiseTypes.AUDIO,
  vdsi: TeleExpertiseTypes.DOCUMENT,
}

export { TeleExpertiseEquivalenceTypes, TeleExpertiseTypes }