import { Menu, MenuItem, ListItemIcon, Typography } from '@mui/material'

const BaseMenu = ({
  anchorEl,
  setAnchorEl,
  menuItems,
  click,
  sx,
  oncesYouClickDoNotCloseIt,
}) => {
  const open = Boolean(anchorEl)
  const handleClose = (item) => {
    click && click(item)
    if (!oncesYouClickDoNotCloseIt) {
      setAnchorEl(null)
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        setAnchorEl(null)
      }}
      sx={sx}
    >
      {menuItems
        ? menuItems?.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose(item)
              }}
            >
              {item?.children ? (
                item?.children
              ) : (
                <>
                  <Typography>{item.label ?? ''}</Typography>
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                </>
              )}
            </MenuItem>
          ))
        : null}
    </Menu>
  )
}

export default BaseMenu
