import {
  Box,
  CircularProgress,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import I18n from 'i18n-js'
import { useCallback, useContext, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AlertModal, CrudsBtn, StepperCreate } from '../../../components'
import Step1 from './steps/step1'
import Steps2 from './steps/step2'
import useStyles from './style'
import { Icons, languages } from '../../../utils'
import { AuthContext } from '../../../context/auth'
import { handleErrors, handleSendData } from '../method'
import healthConditions from '../../../services/healthConditions'
import { CheckCircle } from '@mui/icons-material'
import { useEffect } from 'react'

const Form = () => {
  const { id } = useParams()
  const { state } = useContext(AuthContext)
  const [activeStep, setActiveStep] = useState(0)
  const classes = useStyles()
  const [values, setValues] = useState({})
  const [length, setLegth] = useState({})
  const [errors, setErrors] = useState()
  const [arrFiles, setArrFiles] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [finish, setFinish] = useState(false)
  const [flat, setFlat] = useState(false)
  const [current, setCurrent] = useState(
    languages.find((lang) => lang.locale !== state.locale).locale
  )
  const [flatTranslate, setFlatTranslate] = useState(false)
  const [btnD, setBtnD] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [childId, setChildId] = useState(null)
  const [steps] = useState([
    {
      step: 'cruds.smartTest.steps.stepOne.step',
      label: 'cruds.healthCondition.generalInformation',
    },
    {
      step: 'cruds.smartTest.steps.stepTwo.step',
      label: 'cruds.healthCondition.translate',
      styles: classes.textArea,
    },
  ])
  const [inputs, setInputs] = useState([
    {
      name: state.locale,
      label: I18n.t('cruds.healthCondition.category'),
      fullWidth: true,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            {Icons(state.locale, true)}
          </InputAdornment>
        ),
      },
      style: classes.input,
    },
    {
      name: `${state.locale}File`,
      label: I18n.t('cruds.healthCondition.subCategory'),
      type: 'textArea',
      fullWidth: true,
    },
  ])

  const getData = async () => {
    setIsLoading(true)
    let objLength = {}

    languages.forEach((locale) => {
      objLength = {
        ...objLength,
        [locale.locale]: 0,
      }
    })

    setLegth(objLength)

    if (id) {
      const res = await healthConditions.detail(id)

      if (res.data) {
        let obj = {}
        let objLength = {}
        let arrayChildId = []

        res.data.healthCondition.translations.forEach((item) => {
          obj = {
            ...obj,
            [item.locale]: item.name,
          }
        })

        res.data.healthCondition.children.forEach((item) => {
          arrayChildId = [...arrayChildId, item.id]
          item.translations.forEach((item2) => {
            const localeFile = `${item2.locale}File`
            obj = {
              ...obj,
              [localeFile]: obj[[localeFile]]
                ? `${obj[[localeFile]]},${item2.name}`
                : item2.name,
            }
          })
        })

        Object.entries(obj).forEach(([key, item]) => {
          if (key.includes('File')) {
            objLength = {
              ...objLength,
              ...handleLength(item, key),
            }
          }
        })

        setLegth(objLength)
        setValues(obj)
        setChildId(arrayChildId)
      }
    }

    setIsLoading(false)
  }

  const handleFileLocale = (values) =>
    setArrFiles(values?.split(',').filter((item) => item.trim() !== ''))

  const handleSteps = (value) => {
    if (value < 0) setBtnD(true)

    if (activeStep === 0 && value > 0) {
      handleFileLocale(values?.[`${state.locale}File`])

      let maxTranslateItem = {}
      let newObjectLenght = {}

      Object.entries(values).forEach(([key, item]) => {
        if (key.includes('File')) {
          newObjectLenght = {
            ...newObjectLenght,
            ...handleLength(item, key),
          }

          maxTranslateItem = {
            ...maxTranslateItem,
            [key]: item
              .split(',')
              .splice(0, length[state.locale])
              .filter((item) => item.trim() !== '')
              .join(','),
          }
        } else {
          maxTranslateItem = {
            ...maxTranslateItem,
            [key]: item,
          }
        }
      })
      setLegth(newObjectLenght)
      setValues(maxTranslateItem)
    } else if (activeStep === 1 && value > 0) {
      const validateSend = []
      Object.entries(values).forEach(([key, value]) => {
        if (!key.includes('File')) {
          if (value === '' || length[key] < length[state.locale]) {
            validateSend.push(key)
          }
        }
      })

      if (validateSend?.length > 0) {
        setOpenModal(true)
        setActiveStep(1)
      } else {
        handleSend()
      }
    }
    if (activeStep < 1 || value < 0) {
      setActiveStep(activeStep + value)
    }
  }

  const handleSend = async () => {
    setIsLoading(true)
    const resp = await handleSendData(values, childId, length, state.locale)
    let optionResp

    if (id) {
      optionResp = await healthConditions.edit({
        id,
        update: resp,
      })
    } else {
      optionResp = await healthConditions.create(resp)
    }

    if (optionResp?.data) {
      setFinish(true)
      setActiveStep(activeStep + 1)
    }
    setIsLoading(false)
  }

  const handleChangeLang = (_, value) => setCurrent(value)

  const handleChange = async ({ target }, modify = false) => {
    const { name, value, current } = target

    if (name === 'file') {
      const resp = await handleFile(current, value)
      const valueFile = current.includes(state.locale)
        ? resp
        : resp.split(',').splice(0, length[state.locale]).join(',')

      setLegth({ ...length, ...handleLength(valueFile, current) })

      setValues({ ...values, [current]: valueFile })
    } else if (modify) {
      handleFileLocale(value)

      let copyValues = {}
      let copyLength = {}

      Object.entries(values).forEach(([key, val]) => {
        if (key.includes('File')) {
          if (!key.includes(state.locale)) {
            let arrayFilter = val
              .split(',')
              .filter((item, i) => i !== modify.index && item.trim() !== '')

            copyLength = {
              ...copyLength,
              ...handleLength(arrayFilter.join(), key),
            }

            copyValues = {
              ...copyValues,
              [key]: arrayFilter.join(','),
            }
          } else {
            copyLength = { ...copyLength, ...handleLength(value, key) }

            copyValues = {
              ...copyValues,
              [key]: value,
            }
          }
        } else {
          copyValues = {
            ...copyValues,
            [key]: val,
          }
        }
      })

      setValues(copyValues)
      setLegth(copyLength)
    } else {
      const valueFile = name.includes(state.locale)
        ? value
        : value
            .split(',')
            .filter((item) => item.trim() !== '')
            .splice(0, length[state.locale])
            .join(',')

      if (name.includes('File')) {
        setLegth({ ...length, ...handleLength(valueFile, name) })
      }

      setValues({
        ...values,
        [name]: valueFile,
      })
    }
  }

  const handleLength = (value, name = null) => {
    let lenght = value.split(',').filter((item) => item.trim() !== '').length

    return {
      [name.replace('File', '')]: lenght,
    }
  }

  const handleFile = (name, value) => {
    if (
      value.type === 'application/vnd.ms-excel' ||
      value.type === 'text/csv'
    ) {
      const reader = new FileReader()

      return new Promise((res) => {
        reader.onload = function (e) {
          let currentValue =
            values[name] && values[name] !== '' ? values[name] : ''
          currentValue = currentValue
            .split(',')
            .filter((item) => item.trim() !== '')
            .join(',')

          let text = e.target.result

          let first = text.slice(0, text.indexOf('\r\n'))

          if (first.includes(',')) {
            first = first
              .split(',')
              ?.map((item) => {
                if (item.includes('"')) {
                  return item.replace(/"/g, '')
                }
                return item
              })
              ?.filter((item) => item !== '')
              ?.join(',')

            currentValue = handleUnion(',', currentValue, first)
          } else {
            text = text
              ?.split('\r\n')
              ?.map((item) => {
                if (item.includes('"')) {
                  return item.replace(/"/g, '')
                }

                return item
              })
              ?.filter((item) => item.trim() !== '')
              ?.join(',')

            currentValue = handleUnion(',', currentValue, text)
          }

          res(
            currentValue
              .split(',')
              .filter((item) => item.trim() !== '')
              .join(',')
          )
        }

        reader.readAsText(value)
      })
    }
  }

  const handleUnion = (union, fileExist, newFile) => {
    return fileExist !== '' ? fileExist + union + newFile : newFile
  }

  const handleDisabled = () =>
    length?.[state.locale] <= 0 ||
    values[state.locale] === undefined ||
    values[state.locale] === ''

  const addTranslate = () => {
    let langTranslate = []
    let valueTranslate = {}

    languages.forEach((values) => {
      if (values.locale !== state.locale) {
        valueTranslate = {
          ...valueTranslate,
          [values.locale]: '',
          [`${values.locale}File`]: '',
        }

        langTranslate.push({
          name: values.locale,
          label: I18n.t('cruds.healthCondition.category'),
          fullWidth: true,
          // error: errors?.[values.locale],
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                {Icons(values.locale, true)}
              </InputAdornment>
            ),
          },
          styles: `${classes.inputM} ${classes.input}`,
        })
      }
    })

    setInputs([...inputs, ...langTranslate])
    setValues({ ...values, ...valueTranslate })
  }

  const handleError = useCallback(() => {
    const errors = handleErrors(values)
    setErrors(errors)

    const newInputs = inputs?.map((item) => {
      if (errors?.[item?.name] && item?.name?.includes(state.locale)) {
        return {
          ...item,
          error: true,
        }
      }
      return {
        ...item,
        error: false,
      }
    })

    setInputs(newInputs)
  }, [values])

  useEffect(() => {
    if (!flat) {
      getData()
      setFlat(true)
    }
  }, [flat])

  useEffect(() => handleError(), [handleError])

  useEffect(() => {
    if (!flatTranslate) {
      addTranslate()
      setFlatTranslate(true)
    }
  }, [flatTranslate])
  return (
    <Box p={4} m={4}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.healthConditions')} /{' '}
          <Link
            to="/dashboard/health-conditions"
            className={classes.textBreadcrum}
          >
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
            {id
              ? I18n.t('cruds.healthCondition.edit')
              : I18n.t('cruds.healthCondition.create')}
        </Typography>
      </Box>
      {/* header */}
      <Box className={classes.bodyContainer}>
        <Box className={classes.titleBarContainer}>
          <Box className={classes.titleContainer}>
            <Typography variant="subtitle1" color="textSecondary">
              {I18n.t('breadcrumb.healthCondition')}
            </Typography>
            <Typography variant="h6" color="primary">
              {id
                ? I18n.t('cruds.healthCondition.edit')
                : I18n.t('cruds.healthCondition.create')}
            </Typography>

            <Typography
              variant="h5"
              color="primary"
              className={classes.title}
            ></Typography>
          </Box>
          <Box className={classes.stepperContainer}>
            <StepperCreate activeStep={activeStep} steps={steps} />
          </Box>
        </Box>
      </Box>

      {isLoading ? (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      ) : (
        <>
          {activeStep !== 2 && !finish ? (
            <Box
              className={`${classes.card} ${
                activeStep > 0 && classes.cardLeftCorner
              }`}
            >
              {activeStep > 0 && (
                <Tabs
                  value={current}
                  onChange={handleChangeLang}
                  className={classes.tabs}
                >
                  {languages.map((values) => {
                    if (values.locale !== state.locale) {
                      return (
                        <Tab
                          key={values.locale}
                          icon={Icons(values.locale)}
                          iconPosition="start"
                          label={
                            <Typography className={classes.tabText}>
                              {I18n.t(values.name)}
                            </Typography>
                          }
                          value={values.locale}
                          className={classes.tab}
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                </Tabs>
              )}
              {activeStep === 0 && (
                <Step1
                  inputs={inputs.slice(0, 2)}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  length={length?.[state.locale]}
                  current={`${state.locale}File`}
                  locale={state.locale}
                />
              )}

              {activeStep === 1 && (
                <Steps2
                  inputs={inputs.slice(
                    inputs.findIndex((item) => item.name === current),
                    inputs.findIndex((item) => item.name === current) + 1
                  )}
                  values={values}
                  file={arrFiles}
                  locale={state.locale}
                  onChange={handleChange}
                  length={length}
                  errors={errors}
                  current={current}
                />
              )}

              <Box className={classes.containerFlex}>
                <Box className={classes.fullRight} />

                <Box mt={3}>
                  {activeStep > 0 && (
                    <CrudsBtn
                      text={I18n.t('components.back')}
                      click={() => handleSteps(-1)}
                      variant="outlined"
                    />
                  )}

                  <CrudsBtn
                    disabled={handleDisabled() && btnD === true}
                    text={I18n.t('components.next')}
                    className={classes.button}
                    click={() => handleSteps(1)}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <Box className={classes.finishContainer}>
                <CheckCircle className={classes.chekCircle} />
                <Typography variant="h5" color="primary">
                  {I18n.t('components.success')}
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {!id
                    ? I18n.t('cruds.healthCondition.created')
                    : I18n.t('cruds.healthCondition.edited')}
                </Typography>
              </Box>

              <Box className={classes.endButtonContainer}>
                <CrudsBtn
                  text={I18n.t('cruds.healthCondition.return')}
                  to={'/dashboard/health-conditions'}
                  component={Link}
                  variant="contained"
                />
              </Box>
            </>
          )}
        </>
      )}

      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('components.traductions.missingTraductionTitle')}
        message={I18n.t('components.traductions.missingTraductiontext')}
        SuccessText={I18n.t('components.yes')}
        success={handleSend}
      />
    </Box>
  )
}

export default Form
