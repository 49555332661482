import { useContext } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import Dashboard from '../pages/dashboard'
import ForgotPassword from '../pages/forgotPassword'
import Login from '../pages/login'
import TwoFactor from '../pages/twoFactor'
import ResetPassword from '../pages/resetPassword'
import { AuthContext } from '../context/auth'
import I18n from 'i18n-js'
import { Fr, Pt, Es, En } from '../locale'
import { localeDefault } from '../utils/constants'
import Error404 from '../pages/error404'
import Error401 from '../pages/error401'
import Twilio from '../pages/twilio'
import ChoseLoginMethod from '../pages/choseLoginMethod'
import { transfromLocale } from '../utils/locale'
const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
    }
  />
)

const LayoutRouter = () => {
  const { state } = useContext(AuthContext)
  const isLoggedIn = state?.token
  I18n.translations = {
    fr: Fr,
    pt: Pt,
    es: Es,
    en: En,
  }
  I18n.locale = state?.locale ? transfromLocale[state.locale]: localeDefault
  I18n.fallbacks = true

  return (
    <Router>
      <Switch>
        <RestrictedRoute
          path="/dashboard"
          component={Dashboard}
          isLoggedIn={isLoggedIn}
        />
        <Route exact path="/resetpassword/:token" component={ResetPassword} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/twofactor" component={TwoFactor} />
        <Route exact path="/twilio" component={Twilio} />
        <Route exact path="/login-method" component={ChoseLoginMethod} />
        <Route exact path="/" component={Login} />
        <Route exact path="/logout" component={Error401} />
        <Route path="/dashboard/" component={Error404} />
        <Route path="/" component={Error404} />
      </Switch>
    </Router>
  )
}

export default LayoutRouter
