import React from 'react'
import useStyles from './styles'
import clsx from 'clsx'

const ImageLink = ({images, right = false, left = false, big = false}) => {
    const classes = useStyles()

  return (
    <div className={clsx(classes.container, {
        [classes.Right]: right,
        [classes.Left]: left,
        [classes.Big]: big,
    })}>
        <a href={images} target="blank"><img src={images} alt="" className={classes.images}/></a>
    </div>
  )
}

export default ImageLink