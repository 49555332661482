import { useEffect, useState } from 'react'
import { Box, Typography, Paper } from '@mui/material'
import I18n from 'i18n-js'
import { Icons } from '../../utils'
import { InputForm } from '..'
import useStyles from './style'

const BlockTranslate = ({ translation, data, setData, id, sx = {} }) => {
  const [questionTranslation, setQuestionTranslation] = useState(
    data[translation.locale]?.blocks?.[id]
      ? data[translation.locale]?.blocks[id]
      : { instructions: '' }
  )

  const handleChange = (e) => {
    setQuestionTranslation({
      ...questionTranslation,
      [e.target.name]: e.target.value,
    })
  }
  useEffect(() => {
    setQuestionTranslation(
      data[translation.locale]?.blocks?.[id]
        ? data[translation.locale]?.blocks[id]
        : { instructions: '' }
    )
  }, [id])

  useEffect(() => {
    if (data[translation.locale]) {
      setData({
        ...data,
        [translation.locale]: {
          ...data[translation.locale],
          blocks: {
            ...data[translation.locale].blocks,
            [id]: questionTranslation,
          },
        },
      })
    } else {
      data[translation.locale] = {
        name: '',
        description: '',
        blocks: { [id]: questionTranslation },
      }
    }
  }, [questionTranslation])
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.labelContainer}>
        {Icons(translation.locale)}
        <Typography
          className={classes.label}
          variant="caption"
          color="textPrimary"
        >
          {I18n.t(translation.name)}
        </Typography>
      </Box>

      <Box className={classes.questionContainer} >
        <Paper className={classes.card} sx={sx}>
          <InputForm
            label={I18n.t('cruds.smartTest.form.instructions')}
            name="instructions"
            variant="outlined"
            color="secondary"
            // size="small"
            rows={4}
            value={questionTranslation.instructions || ''}
            textarea
            className={classes.textArea}
            withMaxLength={false}
            onChange={handleChange}
            multiline
          />
        </Paper>
      </Box>
    </Box>
  )
}

export default BlockTranslate
