import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  actionContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
})

export default useStyles
