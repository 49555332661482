import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'
const useStyles = makeStyles((theme) => ({
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  titleBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  description: {
    width: '100%',
    '&.MuiFormControl-root': {
      margin: '8px 0',
    },
    background: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  bodyContainer: {
    marginTop: '32px',
  },
  addres: {
    width: '100%',
    "&.MuiFormControl-root": {
    margin: '8px 0',
    },
    background: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  identifier: {
    '&.MuiFormControl-root': {
      margin: '16px 0',
    },
    background: 'none',
    width: '50%',
  },
  identifierContainer: {
    display: 'flex',
    margin: '8px 0',
  },
  inputAutocomplete: {
    width: '50%',
    margin: '8px 0',
    '&.MuiFormControl-root.MuiTextField-root': {
      width: '100%',
      margin: '8px 0',
    }
  },
  nameHtml:{
    width: "100%",
    "&.MuiFormControl-root": {
    margin: '8px 0',
    background: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '8px',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '16px',
  },
  addressTitle: {
    "&.MuiTypography-root": {
      marginBottom: '16px',
    },
  },
  centerName: {
    "&.MuiTypography-root": {
      marginBottom: '8px',
    },
  },
  addressSuBtitle: {
    "&.MuiTypography-root": {
      marginTop: "32px",
    },
  },
  inputSize: {
    width: '48%',
    "&.MuiFormControl-root": {
      margin: '8px 0',
      },
    background: 'none',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  mapContainer: {
    // marginTop: '24px',
  },
  formWidth: {
    width: '49%',
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '150px',
    alignItems: 'center',
    width: '40%',
    margin: '0 auto',
    padding: '32px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  chekCircle: {
    color: colors.lightPrimary,
    '&.MuiSvgIcon-root': {
      fontSize: '50px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerEnd: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'center',
  },
  cancel: {
    color: colors.blueInput,
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}))

export default useStyles
