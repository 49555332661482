import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@mui/material'
import { ModalForm, CrudsBtn, AlertMenu } from '..'
import { Icons, images } from '../../utils'
import useStyles from './styles'
import I18n from 'i18n-js'
const AddSensor = ({
  validator,
  data,
  setData,
  title,
  subtitle,
  button,
  kitFields = [],
}) => {
  const classes = useStyles()

  //form
  const [showForm, setShowForm] = useState(false)
  const [kitData, setKitData] = useState({})
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [currentField, setCurrentField] = useState('')
  const [errors, setErrors] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const [deletePosition, setDeletePosition] = useState()
  const [Hasclose, setHasClose] = useState(false)
  const [editedPosition, setEditedPosition] = useState()
  const handleOpenForm = () => {
    setShowForm(true)
    setHasClose(false)
  }
  const handleCloseForm = () => {
    setIsEdit(false)
    setShowForm(false)
    setKitData({})
    setErrors({})
    setHasClose(true)
  }
  const handleChange = (e) => {
    let etiquete
    if (e.target.name === 'sensorOne') {
      etiquete = 1
    } else if (e.target.name === 'sensorTwo') {
      etiquete = 2
    } else {
      etiquete = 3
    }
    setKitData({
      ...kitData,
      [e.target.name]: { mac: e.target.value, etiquete: etiquete },
    })
    setCurrentField(e.target.name)
  }
  const handleEditKit = (position) => {
    setEditedPosition(position)
    setIsEdit(true)
    const update = data.kits[position]
    setKitData(update)
    handleOpenForm()
  }
  const handleDeleteClick = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
    setDeletePosition('')
  }
  const handleDeleteKit = async () => {
    handleDeleteClose()

    const deleted = data.kits.filter((kit, index) => {
      if (index != deletePosition) {
        return kit
      }
    })

    setData({
      ...data,
      kits: deleted,
    })
  }

  const handleSendKit = async (e) => {
    e.preventDefault()
    if (!isEdit) {
      setData({
        ...data,
        kits: [...(data.kits || []), kitData],
      })
    } else {
      const updateKit = data.kits.map((kit, index) => {
        if (index == editedPosition) {
          return kitData
        } else {
          return kit
        }
      })
      setData({
        ...data,
        kits: updateKit,
      })
    }

    handleCloseForm()
  }
  useEffect(() => {
    const validationAsync = async () => {
      const validations = await validator(kitData, currentField, errors)

      if (validations.valid) {
        setDisabledSubmit(false)
      } else {
        setDisabledSubmit(true)
      }
      
      if (!Hasclose) {
        setErrors(validations.errors)
      }
    }

    validationAsync()
  }, [kitData])

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.titleContainer}>
          <Typography variant="subtitle1" color="textSecondary">
            {title}
          </Typography>
        </Box>
        <CrudsBtn text={button} click={handleOpenForm} />
      </Box>
      <Box className={classes.body}>
        {data.kits &&
          data.kits.map((kit, index) => {
            return (
              <Card className={classes.kitContainer} key={index}>
                <CardHeader
                  title={'Kit ' + (index + 1)}
                  className={classes.cardHeader}
                  action={
                    <Box>
                      <IconButton
                        color="secondary"
                        className={classes.edit}
                        onClick={() => handleEditKit(index)}
                      >
                        {Icons('edit')}
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          setDeletePosition(index)
                          handleDeleteClick(e)
                        }}
                      >
                        {Icons('delete')}
                      </IconButton>
                    </Box>
                  }
                />
                <Divider />
                <CardContent>
                  <Box className={classes.infoText}>
                    <img
                      src={images.SensorXSense}
                      width={216 * 0.1}
                      height={262 * 0.1}
                    />
                    <Typography variant="body1" color="textPrimary">
                      <strong>
                        {I18n.t('cruds.medicalCenters.sensors.form.sensor') +
                          ' 1: '}
                      </strong>
                      {kit.sensorOne?.mac || I18n.t('general.dataNotFound')}
                    </Typography>
                  </Box>
                  <Box className={classes.infoText}>
                    <img
                      src={images.SensorXSense}
                      width={216 * 0.1}
                      height={262 * 0.1}
                    />
                    <Typography variant="body1" color="textPrimary">
                      <strong>
                        {I18n.t('cruds.medicalCenters.sensors.form.sensor') +
                          ' 2: '}
                      </strong>
                      {kit.sensorTwo?.mac || I18n.t('general.dataNotFound')}
                    </Typography>
                  </Box>
                  <Box className={classes.infoText}>
                    <img
                      src={images.SensorXSense}
                      width={216 * 0.1}
                      height={262 * 0.1}
                    />
                    <Typography variant="body1" color="textPrimary">
                      <strong>
                        {I18n.t('cruds.medicalCenters.sensors.form.sensor') +
                          ' 3: '}
                      </strong>
                      {kit.sensorThree?.mac || I18n.t('general.dataNotFound')}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            )
          })}
      </Box>

      <ModalForm
        inputs={kitFields}
        open={showForm}
        close={handleCloseForm}
        tittle={subtitle}
        type={2}
        values={kitData}
        change={handleChange}
        submit={handleSendKit}
        disabled={disabledSubmit}
        errors={errors}
        icons
        objectValueLocation="mac"
      />

      <AlertMenu
        anchor={anchor}
        close={handleDeleteClose}
        action={handleDeleteKit}
      />
    </Box>
  )
}

export default AddSensor
