import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'

const useStyles = makeStyles((theme) => ({
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  saveButton: {
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  backButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  inputAutocomplete: {
    '& .MuiTextField-root': {
      background: colors.white,
      width: '100%',
    },
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '150px',
    alignItems: 'center',
    width: '40%',
    margin: '0 auto',
    rowGap: '10px',
    padding: '32px 0',
    marginTop: '30px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  footerEnd: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'center',
  },
  chekCircle: {
    color: colors.lightPrimary,
    '&.MuiSvgIcon-root': {
      fontSize: '50px',
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  textField: {
    '&.MuiTextField-root': {
      marginTop: '-10px',
    }
  },
  input: {
    background: colors.white,

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  gridSubtitle: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: 0,
      paddingLeft: 12
    }
  },
  gridItem: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingTop: 10,
      paddingLeft: 12
    }
  },
  detailContainer: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      fontSize: '1.3em',
      color: colors.disabledStrong,
    }
  },
  medicalIcon: {
    '& .MuiSvgIcon-root': {
      marginTop: 5
    }
  }
}))

export default useStyles
