import { DELETE, GET, POST, PUT } from '../utils/crud'

const list = async () => {
  const res = await GET('smart-tests/tests')
  return res
}

const listActive = async (filter = '') => {
  const res = await GET(`smart-tests/tests${filter}`)
  if (res.data) return res.data.filter((test) => test.active)
  else return res
}

const listTypeStaff = async () => {
  const res = await GET('type-staff')
  return res
}

const createTypeStaff = async (id, body) => {
  const res = await POST(`smart-tests/tests/${id}/type-staff`, body)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`smart-tests/tests/${id}`)
  return res
}

const stepTypes = async () => {
  const res = await GET('smart-tests/step-types')
  return res
}

const create = async (body) => {
  const res = await POST('smart-tests/tests', body, true)
  return res
}
const createStep = async (body) => {
  const res = await POST('smart-tests/steps', body)
  return res
}

const edit = async (body, id) => {
  body.append('_method', 'PUT')
  const res = await POST(`smart-tests/tests/${id}`, body, true)
  return res
}
const editStep = async (body, id) => {
  const res = await PUT(`smart-tests/steps/${id}`, body)
  return res
}

const show = async (id) => {
  const res = await GET(`smart-tests/tests/${id}`)
  return res
}

const createQuestion = async (body) => {
  const res = await POST('smart-tests/questions', body)
  return res
}

const editQuestion = async (body, id) => {
  const res = await PUT(`smart-tests/questions/${id}`, body)
  return res
}

const addQuestions = async (body, id) => {
  const res = await POST(`smart-tests/steps/${id}/add-questions`, {
    id_questions: body,
  })
  return res
}
const questionTypes = async () => {
  const res = await GET('smart-tests/question-types')
  return res
}

const deleteBlock = async (id) => {
  const res = await DELETE(`smart-tests/steps/${id}`)
  return res
}
export default {
  list,
  listActive,
  destroy,
  stepTypes,
  create,
  createStep,
  show,
  edit,
  editStep,
  createQuestion,
  addQuestions,
  questionTypes,
  editQuestion,
  deleteBlock,
  listTypeStaff,
  createTypeStaff,
}
