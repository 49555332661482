import { useEffect, useState, useContext } from 'react'
import I18n from 'i18n-js'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography, Hidden, CircularProgress } from '@mui/material'
import { IconButton, Switch } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

import { SearchBar, CrudsBtn, SnackBar, AlertMenu } from '../../components'
import medicalCenter from '../../services/medicalsCenter'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import { actionKeys, pagesKeys } from '../../utils'
import { Icons } from '../../utils'
import useStyles from '../crudsStyles'

const MedicalCenters = () => {
  const classes = useStyles()

  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()

  //permissions
  const pageId = pagesKeys.medicalcenter

  //search
  const [search, setSearch] = useState('')

  //list
  const [data, setData] = useState([])

  //delete
  const [centerDelete, setCenterDelete] = useState()
  const [anchor, setAnchor] = useState()

  //show alert
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState({
    text: '',
    variant: 'success',
  })

  const getData = async () => {
    const data = await medicalCenter.list()
    setData(data?.data?.medicalCenters)
  }

  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }

  // change status
  const changeStatus = async (id, status) => {
    const json = await medicalCenter.changeStatus(id, {
      status: status ? 0 : 1,
    })
    if (json.data) {
      setData([
        ...data.map((item) => {
          if (item.id == id)
            return {
              ...item,
              status: !item.status,
            }
          return item
        }),
      ])
      setMessage({
        text: 'components.changeStatus',
        variant: 'success',
      })
    } else {
      setMessage({
        text: 'components.errorStatus',
        variant: 'warning',
      })
    }
    setShow(true)
  }
  // remove

  const handleDeleteOpen = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
  }
  const remove = async () => {
    handleDeleteClose()
    const json = await medicalCenter.destroy(centerDelete)
    if (json.data) setData([...data.filter((item) => item.id !== centerDelete)])
    setMessage({
      text: 'components.alertDelete',
      variant: 'success',
    })
    setShow(true)
    setSearch('')
    document.getElementById('search').value = ''
  }

  useEffect(() => {
    const ac = new AbortController()
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
    return () => {
      ac.abort()
    }
  }, [])

  // Table
  const columns = [
    {
      field: 'fullName',
      headerName: I18n.t('cruds.medicalCenters.tableheader.fullName'),
      flex: 1,
    },
    {
      field: 'address',
      headerName: I18n.t('cruds.medicalCenters.tableheader.address'),
      flex: 1,
    },
    {
      field: 'email',
      headerName: I18n.t('cruds.medicalCenters.tableheader.email'),
      flex: 1,
    },
    {
      field: 'status',
      headerName: I18n.t('cruds.medicalCenters.tableheader.status'),
      align: 'center',
      headerAlign: 'center',
      width: 120,
      sortable: false,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) =>
        canAccess(actionKeys.edit, pageId, state) ? (
          <Switch
            color="primary"
            size="small"
            checked={row.status ? true : false}
            onChange={() =>
              changeStatus(row.id, row.status ? true : false)
            }
          />
        ) : (
          <div>
            {row.status ? Icons('point_active') : Icons('point_disabled')}
          </div>
        ),
    },
    {
      field: 'actions',
      headerName: I18n.t('general.actions'),
      headerAlign: 'center',
      width: 170,
      sortable: false,
      hide:
        canAccess(actionKeys.edit, pageId, state) ||
        canAccess(actionKeys.delete, pageId, state)
          ? false
          : true,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Grid container justifyContent="center">
          {canAccess(actionKeys.list, pageId, state) && (
            <IconButton
              onClick={() =>
                history.push(`${history.location.pathname}/${row.id}`)
              }
              color="secondary"
            >
              {Icons('description')}
            </IconButton>
          )}
          {canAccess(actionKeys.edit, pageId, state) && (
            <IconButton
              onClick={() =>
                history.push(`${history.location.pathname}/edit/${row.id}`)
              }
              color="secondary"
            >
              {Icons('edit')}
            </IconButton>
          )}
          {canAccess(actionKeys.delete, pageId, state) && (
            <IconButton
              onClick={(e) => {
                setCenterDelete(row.id)
                handleDeleteOpen(e)
              }}
            >
              {Icons('delete')}
            </IconButton>
          )}
        </Grid>
      ),
    },
  ]

  const rows = [
    ...data?.map((item) => {
      return {
        id: item.id,
        fullName: item.name,
        address: item?.address,
        email: item.email || '',
        status: item.status,
      }
    }),
  ]
  const filterData = (rows) => {
    return rows.filter(
      (item) =>
        item?.fullName?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(search?.toLowerCase()) ||
        item?.address?.toLowerCase().includes(search?.toLowerCase())
    )
  }

  return (
    <Box p={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.mcenter')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('sidebar.mcenter')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar change={handleChangeSearchbar} />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.medicalCenters.createButton')}
              size="medium"
              click={() => history.push(`${history.location.pathname}/create`)}
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <div style={{ width: '100%', background: 'white' }}>
              { (rows?.length > 0) &&
              (
                <DataGrid
                  columns={columns}
                  rows={filterData(rows)}
                  autoHeight
                  pagination
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableColumnMenu
                  disableSelectionOnClick
                  className={classes.datagrid}
                />
              ) ||
              (
                <CircularProgress className={classes.circularProgress} />
              )
              }
            </div>
          </Grid>
        )}
      </Grid>
      <SnackBar
        text={I18n.t(message.text)}
        severity={message.variant}
        show={show}
        HandleShow={setShow}
        time={1500}
      />
      <AlertMenu anchor={anchor} close={handleDeleteClose} action={remove} />
    </Box>
  )
}

export default MedicalCenters
