import { GET, POST } from '../utils/crud'

const login = async (body) => {
  // body.code_verification ? '' : (body.code_verification = '')
  const res = await POST('login', body)
  return res
}

const resetPassword = async (body) => {
  const res = await POST('user/reset-password', body)
  return res
}

const logout = async () => {
  const res = await POST('logout')
  return res
}

const userMe = async () => {
  const res = await GET('user/me')
  return res
}

const changePassword = async ({ user, body }) => {
  const res = await POST(`users/${user}/change-password`, body)
  return res
}

const generatePassword = async (body) => {
  const data = { email: body }
  const res = await POST('user/send-password-reset', data)
  return res
}

export {
  login,
  resetPassword,
  logout,
  userMe,
  changePassword,
  generatePassword,
}
