import { fr, pt, es, enGB } from 'date-fns/locale'

const dateLocales = {
  fr: fr,
  pt: pt,
  es: es,
  'es-CO': 'es',
  en: enGB,
}
export default dateLocales
