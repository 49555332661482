import { listStates } from '../../../services/states'

export const handleRemove = (value, errors) => {
  let error = { ...errors }

  delete error.cc
  delete error.nif
  delete error.niss
  delete error.sns
  delete error.comments
  delete error.state_id
  delete error.community_id
  delete error.dni
  delete error.nuss

  return error
}

export const handleCountryBirth = async (locale) => {
  let fields = {
    communities: undefined,
  }

  if (locale === 'fr') {
    const states = await listStates()
    fields = {
      states: states.data.states.sort((a, b) => a.name - b.name),
      locale: 'fr',
    }
  } else if (['es', 'pt'].includes(locale)) {
    fields = { ...fields, locale: locale }
  } else {
    fields = { ...fields, locale: '' }
  }

  return fields
}

