import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '32px 0',
    border: colors.greyDarken + ' solid 1px',
    borderRadius: '5px',
    padding: '16px 16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: '48px',
    paddingTop: '16px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      columnGap: '0',
    },
  },
  cardHeader: { padding: '12px 16px',},
  kitContainer: {
    marginBottom: '16px',
   
    ['& .MuiIconButton-label']: {
      color: colors.danger,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '16px',
    },
  },
  firstItem: {
    marginLeft: '3.33333%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },

  formWidth: {
    width: '49%',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '16px',
  },
  button: {
    color: colors.blueLighten,
  },
  edit: {
    ['& .MuiIconButton-label']: {
      color: colors.blueLighten,
    },
  },
  infoText: {
    display: 'flex',
    color: colors.blueLighten,
    alignItems: 'center',
    '&:first-child': { marginBottom: '8px' },
    '&:last-child': { marginTop: '8px' },
    ['& img']: {
      marginRight: '8px',
    },
    textOverflow: 'ellipsis',
  },
}))

export default useStyles
