import { decryptData } from '../utils/encrypt'
export const canAccess = (action, page, state) => {
  const permissions = state?.permissions ? decryptData(state.permissions) : []
  const pageActions = permissions?.find(
    (permission) => permission.id == page
  )?.actions
  if (pageActions?.find((pageAction) => pageAction == action)) {
    return true
  } else {
    return false
  }
}
