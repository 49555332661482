import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  sections: {
    '&:first-child': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '16px',
    },
  },
})

export default useStyles
