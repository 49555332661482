import {
  ExpandLess,
  ExpandMore,
  Settings,
  People,
  Home,
  Edit,
  DeleteOutline,
  Search,
  HighlightOff,
  Visibility,
  VisibilityOff,
  CheckCircleOutline,
  FiberManualRecord,
  PeopleAlt,
  PanTool,
  Accessibility,
  EmojiPeople,
  DirectionsRun,
  SportsHandball,
  CalendarToday,
  ArrowBack,
  ArrowForward,
  Event,
  AirlineSeatFlatAngled,
  RotateRight,
  QueuePlayNext,
  Warning,
  CheckCircleOutlined,
  Error,
  Help,
  ListAlt,
  AddCircle,
  Clear,
  Circle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PriorityHigh,
  Feed,
  Source,
  MedicalServices,
  CameraAlt,
  ForwardToInbox,
  PictureAsPdf,
  AutoDelete,
  FilterAlt,
  FilterAltOff,
  Print,
  Download,
  Filter,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  InsertDriveFileOutlined,
  GraphicEqOutlined,
  PlayArrow,
  Pause,
  OpenWith
} from '@mui/icons-material'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import GroupsIcon from '@mui/icons-material/Groups'
import { ReactComponent as Exhaustion } from '../assets/images/icons/exhaustion.svg'
import { ReactComponent as Grip } from '../assets/images/icons/grip.svg'
import { ReactComponent as Physical_activity } from '../assets/images/icons/physical_activity.svg'
import { ReactComponent as Slowness } from '../assets/images/icons/slowness.svg'
import { ReactComponent as Weight } from '../assets/images/icons/weight.svg'
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { Avatar, Icon } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {
  faHospitalAlt,
  faStethoscope,
  faTemperatureHigh,
  faTint,
  faHeartbeat,
  faFire,
  faShoePrints,
  faWalking,
  faGripHorizontal,
  faChartBar,
  faChartLine,
  faBlind,
  faFolderOpen,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FlagIcon from '@mui/icons-material/Flag'
import image from './Images'
import colors from './colors'

export const Icons = (icon, input = false) => {
  switch (icon) {
    case 'pause':
      return <Pause />
    case 'play':
      return <PlayArrow />
    case 'audio':
      return <GraphicEqOutlined />
    case 'file':
      return <InsertDriveFileOutlined />
    case 'clip':
      return <AttachFileIcon />
    case 'touch':
      return <TouchAppOutlinedIcon />
    case 'left':
      return <KeyboardArrowLeft />
    case 'right':
      return <KeyboardArrowRight />
    case 'camera':
      return <CameraAlt />
    case 'aspect-ration':
      return <OpenWith />
    case 'download':
      return <Download />
    case 'print':
      return <Print />
    case 'more-img':
      return <Filter />
    case 'filterOff':
      return <FilterAltOff />
    case 'filter':
      return <FilterAlt />
    case 'timeDelete':
      return <AutoDelete />
    case 'file-pdf':
      return <FontAwesomeIcon icon={faFilePdf} size="lg" />
    case 'pdf':
      return <PictureAsPdf />
    case 'sendEmail':
      return <ForwardToInbox />
    case 'document':
      return <Feed />
    case 'code':
      return <Source />
    case 'medical':
      return <MedicalServices />
    case 'fullscreen':
      return <FullscreenIcon />
    case 'fullscreenExit':
      return <FullscreenExitIcon />
    case 'circle':
      return <Circle />
    case 'info':
      return <PriorityHigh />
    case 'group':
      return <GroupsIcon />
    case 'flag':
      return <FlagIcon />
    case 'arrowDown':
      return <KeyboardArrowDown />
    case 'arrowUp':
      return <KeyboardArrowUp />
    case 'clear':
      return <Clear />
    case 'add':
      return <AddCircle />
    case 'help':
      return <Help />
    case 'danger':
      return <Error />
    case 'good':
      return <CheckCircleOutlined />
    case 'warning':
      return <Warning />
    case 'show':
      return <Visibility />
    case 'hidden':
      return <VisibilityOff />
    case 'expandMore':
      return <ExpandMore />
    case 'expandLess':
      return <ExpandLess />
    case 'settings':
      return <Settings />
    case 'people':
      return <People />
    case 'home':
      return <Home />
    case 'hospital':
      return <FontAwesomeIcon icon={faHospitalAlt} size="lg" />
    case 'stethoscope':
      return <FontAwesomeIcon icon={faStethoscope} size="lg" />
    case 'temperature':
      return <FontAwesomeIcon icon={faTemperatureHigh} size="lg" />
    case 'water':
      return <FontAwesomeIcon icon={faTint} size="lg" />
    case 'heart':
      return <FontAwesomeIcon icon={faHeartbeat} size="lg" />
    case 'fire':
      return <FontAwesomeIcon icon={faFire} size="lg" />
    case 'shoes':
      return <FontAwesomeIcon icon={faShoePrints} size="lg" />
    case 'walk':
      return <FontAwesomeIcon icon={faWalking} size="lg" />
    case 'gripIndicator':
      return <FontAwesomeIcon icon={faGripHorizontal} />
    case 'folder':
      return <FontAwesomeIcon icon={faFolderOpen} />
    case 'chart':
      return <FontAwesomeIcon icon={faChartBar} />
    case 'graph':
      return <FontAwesomeIcon icon={faChartLine} />
    case 'balance':
      return <FontAwesomeIcon icon={faBlind} />
    case 'patient':
      return <AirlineSeatFlatAngled />
    case 'edit':
      return <Edit />
    case 'delete':
      return <DeleteOutline htmlColor={colors.dangerLighten} />
    case 'search':
      return <Search color="disabled" />
    case 'close':
      return <HighlightOff />
    case 'check':
      return <CheckCircleOutline htmlColor={colors.success} />
    case 'point_active':
      return <FiberManualRecord htmlColor={colors.primary} />
    case 'point_disabled':
      return <FiberManualRecord htmlColor={colors.disabledLighten} />
    case 'description':
      return <Visibility htmlColor={colors.primary} />
    case 'patients':
      return <PeopleAlt />
    case 'hand':
      return <PanTool />
    case 'question':
      return <ListAlt />
    case 'table':
      return <Accessibility />
    case 'user':
      return <EmojiPeople />
    case 'run':
      return <DirectionsRun />
    case 'exercise':
      return <SportsHandball />
    case 'date':
      return <CalendarToday />
    case 'date_event':
      return <Event htmlColor={colors.disabledDarken} />
    case 'arrow_back':
      return <ArrowBack />
    case 'arrow_forward':
      return <ArrowForward />
    case 'exhaustion':
      return <Exhaustion width="30px" />
    case 'grip':
      return <Grip width="30px" />
    case 'physical_activity':
      return <Physical_activity width="30px" />
    case 'slowness':
      return <Slowness width="30px" />
    case 'weight':
      return <Weight width="30px" />
    case 'status':
      return <RotateRight />
    case 'homePredict':
      return <QueuePlayNext />
    case 'watch':
      return <AccessTimeIcon />
    case 'star':
      return <StarIcon />
    case 'starBorder':
      return <StarBorderIcon />
    case 'profile':
      return (
        <Avatar
          alt="french"
          className="personalIdp"
          src={image.patientProfile}
          style={
            input
              ? { width: '20px', height: '20px' }
              : { width: '28px', height: '28px' }
          }
        />
      )
    case 'fr':
      return (
        <Avatar
          alt="french"
          src={image.France}
          className="personalIdp"
          style={
            input
              ? { width: '20px', height: '20px' }
              : { width: '28px', height: '28px' }
          }
        />
      )
    case 'pt':
      return (
        <Avatar
          alt="portuguese"
          src={image.Portugal}
          className="personalIdp"
          style={
            input
              ? { width: '20px', height: '20px' }
              : { width: '28px', height: '28px' }
          }
        />
      )
    case 'es':
      return (
        <Avatar
          alt="spanish"
          src={image.Spanish}
          className="personalIdp"
          style={
            input
              ? { width: '20px', height: '20px' }
              : { width: '28px', height: '28px' }
          }
        />
      )
    case 'en':
      return (
        <Avatar
          alt="spanish"
          className="personalIdp"
          src={image.UK}
          style={
            input
              ? { width: '20px', height: '20px' }
              : { width: '28px', height: '28px' }
          }
        />
      )
    case 'es-CO':
      return (
        <Avatar
          alt="colombian"
          className="personalIdp"
          src={image.Colombian}
          style={
            input
              ? { width: '20px', height: '20px' }
              : { width: '28px', height: '28px' }
          }
        />
      )
    case 'homeBack':
      return (
        <Icon>
          <img
            src={image.HomeBack}
            style={{ width: '100%', objectFit: 'cover' }}
          />
        </Icon>
      )

    case 'homeOut':
      return (
        <Icon>
          <img
            src={image.HomeOut}
            style={{ width: '100%', objectFit: 'cover' }}
          />
        </Icon>
      )

    case 'clockBattery':
      return (
        <Icon>
          <img
            src={image.ClockBattery}
            style={{ width: '74%', objectFit: 'cover' }}
          />
        </Icon>
      )
    case 'homeTemperature':
      return (
        <Icon>
          <img
            src={image.HomeTemperature}
            style={{ width: '100%', objectFit: 'cover' }}
          />
        </Icon>
      )
  }
}

export default Icons
