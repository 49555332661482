import React, { useState, useEffect } from 'react'
import { Box, Grid, Slider, Tooltip, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import Zoom from '@mui/material/Zoom'
import I18n from 'i18n-js'
import { Loader } from '../../../../components'
import Images from '../../../../utils/Images'
import useStyles from '../../../../styles'

function TimeLine(props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [indicatorsLinear, setLinear] = useState([])

  useEffect(() => {
    async function onLoad() {
      try {
        if (props.date != null) {
          setIsLoading(true)
          setLinear(setOrderTimeLine(props.timeline))
        }
      } catch (e) {
        //error
      }
      setIsLoading(false)
    }
    onLoad()
  }, [props.date])

  const setOrderTimeLine = (arrayTimeLine) => {
    const aux = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arrayTimeLine.length; i++) {
      const r = arrayTimeLine[i]
      if (r.id === 315) aux[0] = r
      else if (r.id === 353) aux[1] = r
      else if (r.id === 354) aux[2] = r
      else if (r.id === 306) aux[3] = r
      else if (r.id === 318) aux[4] = r
      else if (r.id === 312) aux[5] = r
      else if (r.id === 313) aux[6] = r
      else if (r.id === 307) aux[7] = r
      else if (r.id === 319) aux[8] = r
      else if (r.id === 391) aux[9] = r
      else if (r.id === 392) aux[10] = r
      else if (r.id === 316) aux[11] = r
      else if (r.id === 355) aux[12] = r
      else if (r.id === 356) aux[13] = r
    }
    return aux
  }

  function renderValuesIndicators() {
    let band = false
    for (let index = 0; index < indicatorsLinear.length; index++) {
      const element = indicatorsLinear[index]
      if (element.value != '') {
        band = true
        break
      }
    }

    const marks = [
      {
        value: 1,
        label: '',
      },
      {
        value: 3,
        label: '',
      },
      {
        value: 6,
        label: '',
      },
      {
        value: 10,
        label: '',
      },
      {
        value: 14,
        label: '',
      },
      {
        value: 17,
        label: '',
      },
      {
        value: 19,
        label: '',
      },
      {
        value: 21,
        label: '',
      },
    ]

    const values = [1, 3, 6, 10, 14, 17, 19, 21]

    const ColoredSlider = withStyles((theme) => ({
      disabled: {
        color: theme.palette.primary.main,
      },
      rail: {
        color: theme.palette.primary.main,
      },
    }))(Slider)
    const labelsSteps = [
      { image: Images.Ind1, width: '10%' },
      { image: Images.Ind2, width: '14%' },
      { image: Images.Ind3, width: '20.5%' },
      { image: Images.Ind4, width: '20%' },
      { image: Images.Ind5, width: '15%' },
      { image: Images.Ind6, width: '8%' },
      { image: Images.Ind7, width: 'auto' },
      { image: Images.Ind8, width: 'auto' },
    ]
    if (band)
      return (
        <div>
          <Typography
            variant="h6"
            color="primary"
            className={classes.title}
          >
            {I18n.t('patient.detail.BilanTug')}
          </Typography>
          <Grid container className={classes.sliderContainer} id="timeLine">
            <Box className={classes.labelsContainer}>
              {labelsSteps.map((labels, index) => {
                const size = labelsSteps.length
                return (
                  <span
                    key={index}
                    className={
                      index < size - 2
                        ? classes.labels
                        : index < size - 1
                        ? classes.preLastLabel
                        : classes.lastLabel
                    }
                    style={{
                      width: labels.width,
                    }}
                  >
                    <img
                      src={labels.image}
                      className={classes.sizeImgIndicator}
                    />
                  </span>
                )
              })}
            </Box>
            <ColoredSlider
              track={false}
              aria-labelledby="track-false-range-slider"
              defaultValue={values}
              marks={marks}
              disabled={true}
              min={1}
              max={21}
            />
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={classes.mbxxl}
            >
              <Grid className={classes.timeLineValuesIndicator1}>
                {indicatorsLinear[0].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[0].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>
                      {indicatorsLinear[0].value + ' s'}
                    </p>
                  </Tooltip>
                ) : (
                  ''
                )}

                {/* <span className={classes.dFlex}>
                  {indicatorsLinear[1].value != '' && (
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className={classes.IconRotatedIndicators}
                    />
                  )}

                  {indicatorsLinear[1].value != '' ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={indicatorsLinear[1].description}
                      classes={{ tooltip: classes.tooltipIndDescription }}
                    >
                      <p className={classes.tooltipDescription}>
                        {indicatorsLinear[1].value + '°'}
                      </p>
                    </Tooltip>
                  ) : (
                    ''
                  )}

                  {indicatorsLinear[2].value != '' ? (
                    <>
                      <p className={classes.descriptionGuion}>-</p>
                      <Tooltip
                        TransitionComponent={Zoom}
                        title={indicatorsLinear[2].description}
                        classes={{ tooltip: classes.tooltipIndDescription }}
                      >
                        <p className={classes.tooltipDescription}>
                          {indicatorsLinear[2].value + '°'}
                        </p>
                      </Tooltip>
                    </>
                  ) : (
                    ''
                  )}
                </span> */}
              </Grid>

              <Grid className={classes.timeLineValuesIndicator2}>
                {indicatorsLinear[3].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[3].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>
                      {indicatorsLinear[3].value != ''
                        ? indicatorsLinear[3].value + ' s'
                        : ''}
                    </p>
                  </Tooltip>
                ) : (
                  ''
                )}

                {indicatorsLinear[4].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[4].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>{''}</p>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>

              <Grid className={classes.timeLineValuesIndicator1}>
                {indicatorsLinear[5].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[5].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>
                      {indicatorsLinear[5].value != ''
                        ? indicatorsLinear[5].value + ' s'
                        : ''}
                    </p>
                  </Tooltip>
                ) : (
                  ''
                )}

                {indicatorsLinear[6].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[6].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>{''}</p>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>

              <Grid className={classes.timeLineValuesIndicator2}>
                {indicatorsLinear[7].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[7].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>
                      {indicatorsLinear[7].value != ''
                        ? indicatorsLinear[7].value + ' s'
                        : ''}
                    </p>
                  </Tooltip>
                ) : (
                  ''
                )}

                {indicatorsLinear[8].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[8].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>{''}</p>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>

              <Grid className={classes.timeLineValuesIndicator1}>
                {indicatorsLinear[9].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[9].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>
                      {indicatorsLinear[9].value != ''
                        ? indicatorsLinear[9].value + ' s'
                        : ''}
                    </p>
                  </Tooltip>
                ) : (
                  ''
                )}

                {indicatorsLinear[10].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[10].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>{''}</p>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>

              <Grid className={classes.timeLineValuesIndicator1}>
                {indicatorsLinear[11].value != '' ? (
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={indicatorsLinear[11].description}
                    classes={{ tooltip: classes.tooltipIndDescription }}
                  >
                    <p className={classes.tooltipDescription}>
                      {indicatorsLinear[11].value != ''
                        ? indicatorsLinear[11].value + ' s'
                        : ''}
                    </p>
                  </Tooltip>
                ) : (
                  ''
                )}

                {/* <span className={classes.dFlex}>
                  {indicatorsLinear[12].value != '' && (
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className={classes.IconRotatedIndicators}
                    />
                  )}

                  {indicatorsLinear[12].value != '' ? (
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={indicatorsLinear[12].description}
                      classes={{ tooltip: classes.tooltipIndDescription }}
                    >
                      <p className={classes.tooltipDescription}>
                        {indicatorsLinear[12].value + '°'}
                      </p>
                    </Tooltip>
                  ) : (
                    ''
                  )}

                  {indicatorsLinear[13].value != '' ? (
                    <>
                      <p className={classes.descriptionGuion}>-</p>
                      <Tooltip
                        TransitionComponent={Zoom}
                        title={indicatorsLinear[12].description}
                        classes={{ tooltip: classes.tooltipIndDescription }}
                      >
                        <p className={classes.tooltipDescription}>
                          {indicatorsLinear[13].value + '°'}
                        </p>
                      </Tooltip>
                    </>
                  ) : (
                    ''
                  )}
                </span> */}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
    else return ''
  }

  return isLoading ? <Loader /> : renderValuesIndicators()
}

export default TimeLine
