import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  warning: {
    '&.MuiAlert-root': {
      background: colors.white,
      minWidth: '100px',
    },
  },
  loading: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
  },
  menu: {
    '& .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper': {
      background: `#000000cc`,
      padding: '20px 40px',
      maxWidth: '250px',
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  title: {
    '&.MuiTypography-root': {
      color: colors.white,
      fontSize: '14px',
      // textAlign: 'center'
    },
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    columnGap: '10px',
    marginTop: '20px',
    '& .accept': {
      '&.MuiButton-root': {
        textTransform: 'uppercase',
      },
    },
  },
  cancel: {
    '&.MuiButton-root': {
      textTransform: 'uppercase',
      color: colors.white,
      border: colors.white + ' solid 1px',
      borderRadius: '2px',
      '&:hover': {
        color: `${colors.white}aa`,
        border: `${colors.white}aa solid 1px`,
      },
      transition: '.2s',
      padding: '5px 10px',
    },
  },
})

export default useStyles
