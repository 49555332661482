import { colors } from '../../../../utils'

const useStyles = () => {
  return {
    container: {
      '& .flex': { display: 'flex' },
      '& .fw': { flexWrap: 'wrap' },
      '& .gap3': { gap: '24px' },
      '& .gap2': { gap: '16px' },
      '& .gap': { gap: '8px' },
      '&  .fullW': { width: '100%' },
      '&  .alignC': { alignItems: 'center' },
      '& .colorblueInput': { color: colors.blueInput },
      '& .p3': { padding: '24px' },
      '& .mb2': { marginBottom: '16px' },
      '& .m3': { margin: '24px' },
      '& .my2': { marginBottom: '16px', marginTop: '16px' },
      '& .my3': { marginBottom: '24px', marginTop: '24px' },
      '& .py2': { paddingBottom: '16px', paddingTop: '16px' },
      '& .py3': { paddingBottom: '24px', paddingTop: '24px' },
      '& .px3': { paddingLeft: '24px', paddingRight: '24px' },
      '& .mt2.mt2': { marginTop: '16px' },
      '& .mb3': { marginBottom: '24px' },
      '& .mb4': { marginBottom: '32px' },
      '& .rp': { padding: '0px' },
      '& .dividerB': { borderBottom: `1px solid ${colors.greyDivider}22` },
      '& .span-title': {
        fontSize: '16px',
        fontWeight: '400'
      },
      '& .paginationHeader': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        '& .MuiIconButton-root': {
          padding: '0px',
        },
        '& .MuiTypography-root': {
          fontSize: '1.3rem',
        },
      },
    },
    dialogContent: {
      height: 'auto',
      overflowY: 'auto',
      padding: '24px',
      '& .MuiFormControl-root': {
        marginTop: '0px',
      },
    },
  }
}

export { useStyles }
