import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '2px',
    maxWidth: '800px',
    padding: 0,
    position: 'relative'
  },
  //body
  body: {
    display: 'flex',
    ['&.MuiCardContent-root:last-child']: {
      padding: '0px 0px 0 16px',
    },
    height: '80px',
  },

  //icon
  icon: {
    fontSize: '50px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '70%',
    maxWidth: 250,
    height: 30,
    marginLeft: 5,
  },
  mb: {
    marginTop: 5,
  },
  imgPosition: {
    width: '100%',
    maxWidth: 400,
    // height: 30
  },
  graphic: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: '10px',
      top: '2px',
      fontSize: '1rem',
      padding: '4px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  //information
  information: {
    flex: 1,
    margin: '0px 0 0px 12px',
    ['& #tugTitle']: { marginLeft: '8px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  centerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  containerText: {
    width: '15%',
    marginRight: 10,
  },
  text: {
    display: 'block',
    width: '100%',
    '& span': {
      fontSize: '.85em',
    },
  },
  dataContainer: {
    marginLeft: 8,
    display: 'flex',
    flexWrap: 'wrap',
  },
  containerImg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  iconButtons: {
    marginTop: '2px',
    color: colors.green,
  },

  spaceIcons: {
    marginLeft: '4px',
  },
  //notification

  notification: {
    width: '70px',
    borderRadius: '50px 0px 0px 50px',
  },
  success: { background: colors.primary },
  warning: { background: colors.warning },
  danger: { background: colors.danger },
  disabled: { background: colors.disabled },
  ok: { background: colors.success },
  care: { background: colors.care },
}))

export default useStyles
