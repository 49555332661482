import { colors } from '../../utils'

export const useStyles = {
  iconButton: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    backgroundColor: colors.disabledBorder,
    position: 'relative',
  },
  iconSvg: {
    '&.MuiIcon-root': {
      fontSize: '1.8em',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  relative: {
    position: 'relative',
  },
  iconClose: {
    '&.MuiIconButton-root': {
      borderRadius: '50%',
      width: 10,
      height: 10,
      position: 'absolute',
      top: "-3px",
      right: "-3px",
      fontSize: '1em',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  iconText: {
    '&.MuiTypography-root': {
      width: '100%',
      fontSize: '.4em',
      marginTop: '-25px',
    },
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  existFile: {
    padding: 0,
    border: `1px solid ${colors.disabledBorder}`,
  },
}
