import { Box, MenuItem, TextField, Typography } from '@mui/material'
import I18n from 'i18n-js'
import { isEmpty } from '../../validators/validator'

import useStyles from './style'
const InformationGeneralForm = ({ change, values, locale, data }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <Box className={classes.flexContainer}>
          <TextField
            variant="outlined"
            name="name"
            onChange={change}
            defaultValue={values[locale]?.name ? values[locale].name : ''}
            color="secondary"
            error={
              values[locale]?.name === undefined
                ? false
                : isEmpty(values[locale]?.name) || false
            }
            placeholder={I18n.t('cruds.profiles.create.indicator')}
            label={I18n.t('cruds.profiles.create.nameLabel')}
            // size="small"
            className={classes.form}
          />
          {values[locale]?.name === undefined
            ? false
            : isEmpty(values[locale]?.name) && (
                <Typography variant="caption" color="error">
                  {I18n.t('validators.required')}
                </Typography>
              )}
        </Box>
        <TextField
          variant="outlined"
          name="provider"
          select
          onChange={change}
          color="secondary"
          // size="small"
          label={I18n.t('cruds.profiles.create.providerLabel')}
          className={classes.form + ' ' + classes.inputMedium + ' ' + classes.selectInput}
          value={values.provider ? values.provider : ''}
        >
          {data.map((provider, index) => {
            return (
              <MenuItem key={index} value={provider.id}>
                {provider.description}
              </MenuItem>
            )
          })}
        </TextField>
      </Box>

      <Box className={classes.textAreaContainer}>
        <TextField
          variant="outlined"
          name="description"
          multiline
          onChange={change}
          color="secondary"
          error={
            values[locale]?.description === undefined
              ? false
              : isEmpty(values[locale]?.description) || false
          }
          label={I18n.t('cruds.profiles.create.descriptionLabel')}
          rows={5}
          defaultValue={
            values[locale]?.description ? values[locale].description : null
          }
          className={classes.form + ' ' + classes.inputLarge}
        />
        {values[locale]?.description === undefined
            ? false
            : isEmpty(values[locale]?.description) && (
                <Typography variant="caption" color="error">
                  {I18n.t('validators.required')}
                </Typography>
              )}
      </Box>
    </Box>
  )
}

export default InformationGeneralForm
