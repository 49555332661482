import React from 'react'
import { Box } from '@mui/material'
const TabPanel = (props) => {
  const { children, value, index, className, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={className}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default TabPanel
