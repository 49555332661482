import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import { useBody } from './body'
import { useStyles } from './styles'
import ModalForm from '../modalForm'
import CrudsBtn from '../crudsBtn'
import I18n from 'i18n-js'
import { Icons, images } from '../../utils'
import AlertMenu from '../alertMenu'
import { forwardRef, useEffect, useImperativeHandle } from 'react'

// eslint-disable-next-line react/display-name
const BiodyXpert3 = forwardRef((props, ref) => {
  const body = useBody()
  const styles = useStyles()
  
  useImperativeHandle(ref, () => ({
    getData: () => body.listCards,
  }))

  useEffect(() => {
    body.resetListCard(props.listCards ?? {})
  }, [props?.listCards])

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Box sx={styles.titleContainer}>
          <Typography variant="subtitle1" color="textSecondary">
            {I18n.t('cruds.medicalCenters.biody.title')}
          </Typography>
        </Box>

        <CrudsBtn
          text={I18n.t('cruds.medicalCenters.biody.button')}
          click={() => {
            body.openModal()
          }}
        />
      </Box>

      <Box sx={styles.body}>
        {Object.values(body.listCards ?? {})?.map((item, index) => (
          <Card sx={styles.kitContainer} key={item.id}>
            <CardHeader
              title={`${I18n.t('cruds.medicalCenters.biody.form.id')} ${
                index + 1
              }`}
              sx={styles.cardHeader}
              action={
                <Box>
                  <IconButton
                    color="secondary"
                    sx={styles.edit}
                    onClick={() => {
                      body.edit({ id: item.id })
                    }}
                  >
                    {Icons('edit')}
                  </IconButton>

                  <IconButton
                    onClick={(e) => {
                      body.openAnchor({ anchor: e.currentTarget, id: item.id })
                    }}
                  >
                    {Icons('delete')}
                  </IconButton>
                </Box>
              }
            />

            <Divider />

            <CardContent>
              <Box sx={styles.infoText}>
                <img
                  src={images.biodyXpert3}
                  width={216 * 0.1}
                  height={262 * 0.1}
                />
                <Typography variant="body1" color="textPrimary">
                  <strong>ID: </strong>

                  {item.id_aminogram || I18n.t('general.dataNotFound')}
                </Typography>
              </Box>

              <Box sx={styles.infoText}>
                <img
                  src={images.biodyXpert3}
                  width={216 * 0.1}
                  height={262 * 0.1}
                />
                <Typography variant="body1" color="textPrimary">
                  <strong>
                    {I18n.t('cruds.medicalCenters.biody.form.license')}:{' '}
                  </strong>

                  {item.license_aminogram || I18n.t('general.dataNotFound')}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>

      <ModalForm
        inputs={body.field}
        open={body.modalForm.open}
        close={body.closeModal}
        tittle={body.modalForm.title}
        type={2}
        values={body.data}
        change={(e) => {
          body.onChange(e)
        }}
        submit={body.safe}
        disabled={body.disabledButton()}
        errors={body.errors}
        icons
      />

      <AlertMenu
        anchor={body.anchor.open}
        close={body.closeAlert}
        action={body.deleteDivice}
      />
    </Box>
  )
})

export { BiodyXpert3 }
