import { checkEmail as CheckEmail } from '../services/checkEmail'
import { imagesType } from '../utils/Images'
export const isEmpty = (data) => {
  if (!data) {
    return true
  } else return false
}

export const portugal = (data) => {
  if (data !== undefined && data === '') return true
  return false
}

export const isContent = (value) =>
  new RegExp(
    `^(?=.*[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ])[-_a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ .,'"¿?¡!:;+=()<>]+$`
  ).test(value) || value === ''

export const isEmail = (data) => {
  const pattern = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
  )

  if (pattern.test(data)) {
    return false
  } else {
    return true
  }
}
export const isMacAddress = (data) => {
  const pattern = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$')
  if (pattern.test(data)) {
    /* is a mac address */
    return false
  } else {
    return true
  }
}
export const isBiggerThan = (data, size) => {
  if (data) {
    if (data.length >= size) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export const hasEspecialCarathers = (data) => {
  const pattern = new RegExp('([&@$!%*?])')
  if (pattern.test(data)) {
    return true
  } else {
    return false
  }
}
export const hasUppercase = (data) => {
  const pattern = new RegExp('([A-Z])')
  if (data) {
    if (pattern.test(data)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export const hasLowercase = (data) => {
  const pattern = new RegExp('([a-z])')
  if (data) {
    if (pattern.test(data)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export const hasNumber = (data) => {
  const pattern = new RegExp('([0-9])')
  if (pattern.test(data)) {
    return true
  } else {
    return false
  }
}
export const hasEmplySpaces = (data) => {
  const pattern = new RegExp('([ ])')
  if (data) {
    if (pattern.test(data)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const isPngImage = (data) => {
  if (data === 'image/png') return true
  return false
}

export const isImage = (data) => {
  if (imagesType.includes(data)) return true
  return false
}

export const areEqual = (array1, array2) => {
  if (array1 === array2) {
    return true
  } else {
    return false
  }
}

export const isLicense = (data) => {
  const pattern = new RegExp('^[A-Z-0-9]*$', 'gi')
  if (data) {
    if (!pattern.test(data)) {
      return true
    }
    return false
  }

  return false
}

export const isCharacters = (data) => {
  const pattern = new RegExp('^[A-Z-ñÑ]{1}[A-Z-ñÑ ]*$', 'gi')
  if (data) {
    return pattern.test(data)
  }
  return false
}

export const numbersAndCharacters = (data) => {
  const pattern = new RegExp('^[A-Z0-9]*$', 'gi')
  if (data) {
    if (!pattern.test(data)) {
      return true
    }
    return false
  }

  return false
}

export const isSpecialCharacter = (data) => {
  const pattern = new RegExp(
    '([!@#$%+=_;:?."~/`\'<>,¿¡^&*(){|}[\\]\u00f1\u00d1])',
    'g'
  )
  if (data) {
    if (pattern.test(data)) {
      return true
    }
  }

  return false
}

export const isNumber = (data) => {
  const pattern = new RegExp('(^[0-9]+$)', 'gi')

  if (pattern.test(data)) {
    return true
  } else {
    return false
  }
}

export const isName = (data) => {
  const pattern = new RegExp('([@#$%+=;?¿"~/`<>,^&*{|}[\\]])', 'gi')

  if (data) {
    if (!/^[0-9]/.test(data)) {
      if (pattern.test(data)) {
        return true
      }
    } else {
      return true
    }
  }

  return false
}
export function validarJSON(jsonString) {
  try {
    const newValue = JSON.parse(jsonString)
    return { response: true, data: newValue }
  } catch (error) {
    return { response: false, data: null }
  }
}
export const isPhoneNumber = (value) =>
  !!/^\+[0-9][0-9]?[0-9]{9,10}$/g.exec(value ?? '')

export const isInvalidCharacter = (data) => {
  const pattern = new RegExp('([^@$!%*?&|a-z|A-Z|0-9])')
  if (data) {
    if (pattern.test(data)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export const checkEmail = async (type, email) => {
  const response = await CheckEmail(type, email)
  if (response.data) {
    return true
  } else {
    return false
  }
}
