import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    ['& button']: { marginLeft: '4px' },
  },
  //icon animation
  iconRotate: {
    animation: `$rotatation 2s linear infinite`,
  },
  '@keyframes rotatation': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  searchBar: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '16px',
    },
  },
  body: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '48px',
    rowGap: '16px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      columnGap: '0',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
}))

export default useStyles
