import { Box, Button, Menu, Typography } from '@mui/material'
import I18n from 'i18n-js'
import useStyles from './styles'
const AlertMenu = ({ anchor, close, action }) => {
  const classes = useStyles()
  return (
    <Menu
      className={classes.menu}
      id="deleteItem"
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={close}
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Box className={classes.container}>
        <Typography className={classes.title}>
          {I18n.t('components.deleteItem')}
        </Typography>

        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.cancel}
            onClick={close}
          >
            {I18n.t('components.cancel')}
          </Button>
          <Button className='accept' variant="contained-danger" size="small" onClick={action}>
            {I18n.t('components.delete')}
          </Button>
        </Box>
      </Box>
    </Menu>
  )
}

export default AlertMenu
