import { useEffect, useState } from 'react'
import { Paper, IconButton } from '@mui/material'
import I18n from 'i18n-js'
import { InputForm } from '../../../../components'
import { Icons } from '../../../../utils'
import { AnnotationsValidator } from '../../../../validators/createIndicatorValidator'
import useStyles from './styles'

const FormCard = ({
  id,
  destroy,
  values,
  setValues,
  locale,
  annotationValue,
}) => {
  const classes = useStyles()

  //data
  const [annotation, setAnnotation] = useState(annotationValue || {})
  const [errors, setErrors] = useState({})

  //methods
  const handleChangeAnnotation = (e) => {
    setAnnotation({ ...annotation, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    const validations = AnnotationsValidator(annotation)
    setErrors(validations)
    Object.values(validations).length === 0
      ? (annotation.completed = true)
      : (annotation.completed = false)

    setValues({
      ...values,
      [locale]: {
        ...values[locale],
        annotations: { ...values[locale].annotations, [id]: annotation },
      },
    })
  }, [annotation])
  return (
    <Paper className={classes.root}>
      <div className={classes.sections}>
        <InputForm
          label={I18n.t('cruds.indicators.create.valueLabel') + '(*)'}
          name="value"
          variant="outlined"
          color="secondary"
          // size="small"
          error={errors.value}
          helperText={errors.value || ''}
          value={annotation.value || ''}
          onChange={handleChangeAnnotation}
          className={classes.input}
        />
        <div>
          <IconButton
            aria-label=""
            // size="small"
            onClick={() => {
              destroy(id)
            }}
          >
            {Icons('delete')}
          </IconButton>
        </div>
      </div>
      <div className={classes.sections}>
        <InputForm
          label={I18n.t('cruds.indicators.create.interpretationLabel')}
          name="interpretation"
          variant="outlined"
          color="secondary"
          // size="small"
          rows={5}
          withMaxLength={false}
          error={errors.interpretation}
          helperText={errors.interpretation || ''}
          onChange={handleChangeAnnotation}
          value={annotation.interpretation || ''}
          className={classes.textArea}
          multiline
        />
        <InputForm
          label={I18n.t('cruds.indicators.create.recomendationLabel')}
          name="recomendation"
          variant="outlined"
          color="secondary"
          // size="small"
          rows={5}
          withMaxLength={false}
          onChange={handleChangeAnnotation}
          value={annotation.recomendation || ''}
          className={classes.textArea}
          multiline
        />
      </div>
    </Paper>
  )
}

export default FormCard
