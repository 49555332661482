import { makeStyles } from '@mui/styles'
import { colors } from '../../../../utils'
const useStyle = makeStyles((theme) => ({
  mountImages: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    '&.MuiCard-root': {
      background: colors.disabledLighten,
      boxShadow: `1px 2px 4px ${colors.greyStrong}`,
      position: 'relative',
      minHeight: 120,
      padding: '0 15px',
    },
  },
  graphic: {
    width: '100%',
    marginTop: '10px',
  },
  chartText: {
    '&.MuiTypography-root': {
      fontWeight: '500',
      marginTop: '20px',
      color: colors.disabled,
      borderBottom: `1px solid ${colors.greyDarken}`,
    },
  },
  moreImages: {
    '&.MuiIconButton-root': {
      marginLeft: '-10px',
      fontSize: '1.2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
  },
  earContainer: {
    width: '100%',
    height: '100px',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
  },
  ear: {
    height: '100px',
    width: '100%',
    objectFit: 'fill',
    borderRadius: '5px',
  },
  name: {
    '&.MuiTypography-root': {
      color: colors.disabled,
      display: 'block',
      width: '100%',
      wordBreak: 'break-word',
    },
  },
  date: {
    '&.MuiTypography-root': {
      textAlign: 'right',
    },
  },
  strong: {
    '&.MuiIconButton-root': {
      color: colors.disabledDarken,
      fontWeight: 700,
    },
    '&.MuiTypography-root': {
      color: colors.disabledDarken,
      fontWeight: 700,
    },
  },
  containerModal: {
    maxHeight: '400px',
  },
  imgItems: {
    width: '300px',
    height: '300px',
    overflow: 'hidden',
    borderRadius: '10px',
    boxShadow: `0 0 5px ${colors.disabledLighten}`,
  },
  imgModal: {
    width: '100%',
    height: 'inherit',
    objectFit: 'fill',
    borderRadius: '10px',
  },
  slider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& .MuiTypography-root': {
      fontWeight: '500',
      color: colors.disabledStrong,
    },
  },
  empty: {
    width: '40px',
    height: '5px',
  },
  status: {
    width: 10,
    height: 10,
    background: colors.blueLighten,
    borderRadius: 50,
    position: 'absolute',
    left: 13,
    top: 21,
  },
  contend: {
    '&.MuiCardContent-root': {
      position: 'relative',
    },
  },
  arrow: {
    '&.MuiIconButton-root': {
      position: 'absolute',
      right: 10,
      top: 20,
      width: 20,
      height: 20,
    },
  },
  img: {
    marginTop: 10,
    width: '90%',
    maxWidth: 500,
    // margin: 'auto'
  },
  widthImg: {
    marginTop: 10,
    maxWidth: 500,
    width: '100%',
  },
  img2: {
    width: '80%',
  },
  icon: {
    width: 30,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    maxWidth: 50,
    marginTop: 15,
  },
  title: {
    '&.MuiTypography-root': {
      fontWeight: 500,
      margin: '10px 0',
      color: colors.disabled,
      borderBottom: `1px solid ${colors.greyDarken}`,
    },
  },
  audioPlayer: {
    width: 'calc(100% + 50px)',
    height: 20,
    // marginTop: 20,
    marginLeft: -20,
  },
  success: { background: colors.primary },
  warning: { background: colors.warning },
  danger: { background: colors.danger },
  disabled: { background: colors.disabled },
  ok: { background: colors.success },
  care: { background: colors.care },
}))

export default useStyle

export const useStylesSx = () => {
  return {
    download: {
      fontSize: '1.2rem',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    downloadImages: {
      position: 'absolute',
      top: '5px',
      right: '10px'
    },
    audioContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }
}
