import { Box, Card, CardContent, Grid, IconButton, Typography } from "@mui/material"
import useStyle from "../../style"
import { format } from "date-fns"
import I18n from "i18n-js"
import { Icons } from "../../../../../../utils"
import { DetailCard } from "../detailCard"
import clsx from 'clsx'

const InformativeCard = (props) => {
    const classes = useStyle()

    return (
        props.list?.map((val, key) => (
            <Grid key={key} item lg={6} md={12} sm={12} xs={12} {...props.gridItemProps}>
                {
                    val.removeCard && val?.graph && (
                        <img
                            src={val?.graph}
                            alt=""
                            className={
                                val?.icon ? classes.widthImg : classes.img
                            }
                        />
                    )
                }

                {!val.removeCard && <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        {
                            !props.removeHeader && (
                                <Typography
                                    variant="subtitle2"
                                    component="span"
                                    className={classes.name}
                                    {...(props.titleProps ?? {})}
                                >
                                    {val?.name}
                                </Typography>
                            )
                        }


                        <Grid container mt={props.value ? 2 : 1}>
                            {props.value && !val?.vc && props.handleValues(val?.value_type, val)}

                            {
                                !props.removeHeader && (
                                    <>
                                        {!!val?.date && (
                                            <Grid item xs={props.value && !val?.vc ? 6 : 12}>
                                                <Typography
                                                    variant="subtitle2"
                                                    component="span"
                                                    className={`${classes.name} ${classes.date}`}
                                                >
                                                    {val?.date &&
                                                        format(
                                                            new Date(val?.date),
                                                            I18n.t('general.dateTimeFormat')
                                                        )}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {val?.icon && (
                                            <>
                                                {val?.graph && !val?.vc && (
                                                    <Grid item xs={2}>
                                                        <img
                                                            src={val?.icon}
                                                            alt=""
                                                            className={classes.icon}
                                                        />
                                                    </Grid>
                                                )}

                                                {!val?.graph && val?.vc && (
                                                    <Grid item xs={2}>
                                                        <img
                                                            src={val?.icon}
                                                            alt=""
                                                            className={classes.icon}
                                                        />
                                                    </Grid>
                                                )}

                                                {props.value &&
                                                    val?.vc &&
                                                    props.handleValues(val?.value_type, val)}
                                            </>
                                        )}
                                    </>
                                )
                            }

                            {val?.graph !== '' && (
                                <Grid item xs={val?.icon ? 10 : 12} align={'center'}>
                                    <img
                                        src={val?.graph}
                                        alt=""
                                        className={
                                            val?.icon ? classes.widthImg : classes.img
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>

                        {!props.removeArrow && (
                            <IconButton
                                onClick={() => props.handleArrow(val?.id)}
                                className={classes.arrow}
                            >
                                {props.listArrow.indexOf(val?.id) !== -1
                                    ? Icons('arrowUp')
                                    : Icons('arrowDown')}
                            </IconButton>
                        )}
                        {DetailCard(
                            props.listArrow.indexOf(val?.id) !== -1,
                            val?.description,
                            classes,
                            val.evolution_graph
                        )}
                        {DetailCard(
                            val?.interpretation && !val?.vc,
                            val?.interpretation,
                            classes,
                            val.evolution_graph
                        )}
                        {DetailCard(
                            val?.recommendation,
                            val?.recommendation,
                            classes,
                            val.evolution_graph
                        )}
                        {!props.removeStatusPoint && (
                            <Box
                                className={clsx([
                                    classes.status,
                                    classes.notification,
                                    {
                                        [classes.success]: val?.status == 'good',
                                        [classes.warning]: val?.status == 'warning',
                                        [classes.danger]: val?.status == 'danger',
                                        [classes.ok]: val?.status == 'ok',
                                        [classes.care]: val?.status == 'care',
                                        [classes.disabled]: val?.status == '',
                                    },
                                ])}
                            />
                        )}
                    </CardContent>
                </Card>}
            </Grid>
        ))
    )
}

export { InformativeCard }
