import { useContext, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Fade,
  Button,
  CircularProgress,
  Snackbar,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import { AccountCircle, Phone, MailOutline, LockOpen } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../context/auth'
import users from '../../services/users'
import CrudsBtn from '../../components/crudsBtn'
import ResetPasswordProfile from '../../components/resetPasswordProfile'
import ResetPasswordValidator from '../../validators/resetPasswordValidator'
import { MyProfileValidator } from '../../validators/myProfileValidator'
import useStyles from './style'
import FormEditProfile from '../../components/formEditProfile'
import { /* actionKeys, pagesKeys, */ rol } from '../../utils'
// import { canAccess } from '../../router/permissions'
import { handleDataUser } from '../../hooks/editUser'

const MyProfile = () => {
  const { user, setUser/* , state */ } = useContext(AuthContext)
  const [userInfo, setUserInfo] = useState({})
  const [showFormPassword, setShowFormPassword] = useState(false)

  const [showpassword, setShowpassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [disabledResetBtn, setDisabledResetBtn] = useState(true)
  const [resetPassword, setResetPassword] = useState({})
  const [requirements, setRequirements] = useState(0)
  const [resetErrors, setResetErrors] = useState({})
  const [openAlert, setOpenAlert] = useState(false)
  const [success, setSuccess] = useState(false)

  const [userRolId, setUserRolId] = useState()
  const [editUser, setEditUser] = useState()
  const [datasend, setDataSend] = useState(false)
  const [disabledEditData, setDisabledEditData] = useState(false)
  const [editEmail/* , setEditEmail */] = useState(true)
  const [oldEmail, setOldEmail] = useState()
  
  const getData = async () => {
    const res = await users.show(user.email)
    setUserInfo(res?.data?.user || {})
    setEditUser(res?.data?.user || {})
    setUserRolId(res.data?.user?.id_rol)
    setOldEmail(res?.data?.user?.email)
    // if(canAccess(actionKeys.edit, pagesKeys.myProfile, state)) setEditEmail(true)
  }
  const handleShowFormPassword = () => {
    setShowFormPassword(true)
  }
  const handleChangeResetPassword = (e) => {
    setResetPassword({ ...resetPassword, [e.target.id]: e.target.value })
  }
  useEffect(() => {
    const info = ResetPasswordValidator(resetPassword)
    setRequirements(info.valid)
    if (info.blankSpaces || !info.matches) {
      setDisabledResetBtn(true)
      setResetErrors(info)
    }
    if (
      !info.blankSpaces &&
      info.matches &&
      info.valid === 100 &&
      !info.confirmationEmply &&
      !info.invalidCharacter
    ) {
      setDisabledResetBtn(false)
      setResetErrors(info)
    }
    if (
      info.blankSpaces ||
      !info.matches ||
      info.valid < 100 ||
      info.confirmationEmply ||
      info.invalidCharacter
    ) {
      setDisabledResetBtn(true)
      setResetErrors(info)
    }
  }, [resetPassword])

  const handleSubmitResetPassword = async (e) => {
    e.preventDefault()
    setDisabledResetBtn(true)
    const body = {
      id: userInfo.email,
      password: resetPassword,
    }
    const res = await users.resetPassword(body)
    if (res.error?.code_error === '02') {
      setOpenAlert(true)
      setSuccess(false)
      setDisabledResetBtn(false)
    } else {
      setDisabledResetBtn(true)
      setOpenAlert(true)
      setSuccess(true)
      setShowFormPassword(false)
    }
  }

  const handleChangeUserData = async (e) => {
    if(e?.target?.id === 'email') {
      if(!editUser.existEmail && oldEmail !== e.target.value) {
        const res = await users.checkEmail(e.target.value)
        setEditUser({...editUser, [e.target.id]: e.target.value, existEmail: res.data === null})
      } else {
        setEditUser({ ...editUser, [e.target.id]: e.target.value, existEmail: false })
      }
    } else {
      if (e.target) {
        setEditUser({ ...editUser, [e.target.id]: e.target.value })
      } else {
        setEditUser({ ...editUser, phone: e })
      }
    }
    
  }
  
  const handleSubmitUserdata = async (e) => {
    e.preventDefault()
    setDisabledEditData(true)

    const body = {
      id: oldEmail,
      update: handleDataUser(editUser),
    }
    await users.edit(body)
    setDataSend(true)
    setDisabledEditData(false)
    setUser({ ...user, name: editUser.name, lastname: editUser.lastname })
    getData()
  }
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    setDisabledEditData(MyProfileValidator(editUser, userRolId, oldEmail))
  }, [editUser])
  const classes = useStyles()
  return (
    <Box mx={6} mt={4}>
      {Object.values(userInfo).length > 0 ? (
        <Fade in={Object.values(userInfo).length > 0} timeout={500}>
          <div>
            <Box className={classes.headerContainer}>
              <Box className={classes.titleContainer}>
                <Box>
                  <Typography
                    variant="h6"
                    color="initial"
                    className={classes.headerTitle}
                  >
                    {I18n.t('myProfile.title')}
                  </Typography>
                </Box>

                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.headerTitle}
                >
                  {userRolId && userRolId !== rol.center ? (
                    userInfo?.name + ' ' + userInfo?.lastname) : (
                    userInfo?.name
                    )
                  }
                </Typography>
              </Box>
              <Box className={classes.informationContainer}>
                {userInfo?.email && !editEmail && (
                  <Box className={classes.textHeaderContainer}>
                    <MailOutline
                      className={classes.iconHeaderText}
                      color="secondary"
                    />
                    <Typography variant="body1" color="textPrimary">
                      {userInfo?.email}
                    </Typography>
                  </Box>
                )}
                {userInfo?.phone && (
                  <Box className={classes.textHeaderContainer}>
                    <Phone
                      className={classes.iconHeaderText}
                      color="secondary"
                    />
                    <Typography variant="body1" color="textPrimary">
                      {userInfo?.phone}
                    </Typography>
                  </Box>
                )}
                {editUser?.rol?.name && (
                  <Box className={classes.textHeaderContainer}>
                    <AccountCircle
                      className={classes.iconHeaderText}
                      color="secondary"
                    />
                    <Typography variant="body1" color="textPrimary">
                      {editUser?.rol?.name}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box my={3}>
              <Box className={classes.bodyContainer}>
                <div className={classes.resetPasswordContainer}>
                  <Box className={classes.resetPasswordHeader}>
                    <LockOpen className={classes.iconLock} />
                    <Typography variant="h6" color="primary">
                      {I18n.t('myProfile.resetPasswordTitle')}
                    </Typography>
                  </Box>

                  <Box className={classes.resetPasswordBody}>
                    {showFormPassword && (
                      <Fade in={showFormPassword} timeout={500}>
                        <Box>
                          <ResetPasswordProfile
                            showpassword={showpassword}
                            setShowpassword={setShowpassword}
                            showConfirm={showConfirm}
                            setShowConfirm={setShowConfirm}
                            change={handleChangeResetPassword}
                            requirements={requirements}
                            errors={resetErrors}
                          />
                        </Box>
                      </Fade>
                    )}

                    {!showFormPassword && (
                      <Fade in={!showFormPassword} timeout={500}>
                        <Box>
                          <div className={classes.textContainerTitle}>
                            <Typography variant="body1" color="textSecondary">
                              {I18n.t('auth.textSecurityPoliciesTitle')}
                            </Typography>
                          </div>
                          <div className={classes.textContainer}>
                            <Typography variant="body2" color="textSecondary">
                              {I18n.t('auth.textSecurityPoliciesRule1')}
                            </Typography>
                          </div>
                          <div className={classes.textContainer}>
                            <Typography variant="body2" color="textSecondary">
                              {I18n.t('auth.textSecurityPoliciesRule2')}
                            </Typography>
                          </div>
                          <div className={classes.textContainer}>
                            <Typography variant="body2" color="textSecondary">
                              {I18n.t('auth.textSecurityPoliciesRule3')}
                            </Typography>
                          </div>
                        </Box>
                      </Fade>
                    )}
                  </Box>

                  <Box className={classes.resetPasswordActions}>
                    <Button
                      disabled={showFormPassword ? disabledResetBtn : false}
                      variant="outlined"
                      color="secondary"
                      className={classes.button}
                      onClick={
                        showFormPassword
                          ? handleSubmitResetPassword
                          : handleShowFormPassword
                      }
                    >
                      {I18n.t('components.update')}
                    </Button>
                  </Box>
                </div>
                <div className={classes.editUserContainer}>
                  <Box className={classes.editUserHeader}>
                    <Typography variant="h6" color="primary">
                      {I18n.t('myProfile.editProfileTitle')}
                    </Typography>
                  </Box>
                  <FormEditProfile
                    oldEmail={oldEmail}
                    editEmail={editEmail}
                    user={userInfo}
                    userRolId={userRolId}
                    change={handleChangeUserData}
                    openAlert={datasend}
                    setOpenAlert={setDataSend}
                    setUser={setEditUser}
                    setuserInfo={setUserInfo}
                    editUser={editUser}
                  />
                  <Box className={classes.submit}>
                    <CrudsBtn
                      text={I18n.t('components.update')}
                      click={handleSubmitUserdata}
                      disabled={disabledEditData}
                    />
                  </Box>
                </div>
              </Box>
            </Box>
          </div>
        </Fade>
      ) : (
        <CircularProgress color="secondary" className={classes.loading} />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => {
          setOpenAlert(false)
        }}
        className={classes.snackbar}
      >
        <Alert severity={success ? 'success' : 'warning'}>
          {success
            ? I18n.t('auth.resetPassSuccess')
            : I18n.t('auth.oldPasswordAlert')}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default MyProfile
