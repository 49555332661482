import I18n from 'i18n-js'
import { isEmail, isEmpty } from './validator'
const LoginValidator = (data) => {
  const error = {}
  error.email = isEmpty(data.email)

  error.password = isEmpty(data.password)

  if (!error.email) {
    error.isemail = isEmail(data.email)
    error.isemail
      ? (error.emailLeyend = I18n.t('validators.invalidEmail'))
      : (error.emailLeyend = '')
  }
  return error
}

export default LoginValidator
