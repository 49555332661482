//export const apiUrl = 'https://idpapi.nelumbo.com.co/api/' //DEV

//export const apiUrl = 'https://apiqa.idpsante.com/api/' //QA

export const apiUrl = 'https://api.idpsante.com/api/' //PROD

//export const apiUrl = 'https://dev-idp.orpea.net/api/' //ORPEA

export const localeDefault = 'fr'
export const ApiKeyMaps = 'AIzaSyCjFzaavWB-SKEKWZrtt16XXSEqbAEBkWM'
