import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles((theme) => ({
  container: {},
  sticky: {
    background: colors.greyLighten,
    boxShadow: '0 8px 6px -6px' + colors.greyDarken,
    borderTop: colors.greyLighten + ' solid .5px',
    position: 'sticky',
    top: '50.49px',
    height: '100px',
    marginBottom: '20px',
    padding: '0px 10px',
    paddingBottom: '1px',
    zIndex: 2,
  },
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardConatiner: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '30px',
    marginBottom: '40px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
  circularProgress: {
    position: 'absolute',
    top: '50vh',
    left: '46%',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '80px',
  },
  footer: {
    minHeight: '50px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginTop: '30px',
  },
  searchBar: {
    width: '50%',
  },
}))

export default useStyles
