import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  card: {
    width: '100%',
    display: 'grid',
    justifySelf: 'center',
  },
  cardHeader: {
    textDecoration: 'none',
  },
  cardLink: {
    textDecoration: 'none',
    '&:hover': { textDecoration: `underline ${colors.primary}` },
  },
  icons: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '5px',
    alignItems: 'space-between'
  },
  title: {
    '&.MuiTypography-root': {
      '& .title': {
        color: colors.lightPrimary,
        fontSize: '18px',
        fontWeight: '500'
      },
      '& .subtitle': {
        color: `${colors.disabledDarken}bb`,
        fontSize: '16px',
      },
      whiteSpace: 'nowrap',
      width: '100%',
      maxWidth: '200px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    color: colors.disabledDarken,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    width: '100%',
    '& .MuiTypography-root': {
      color: `${colors.disabledDarken}bb`,
      fontSize: '14px',
    },
    '& .MuiSvgIcon-root': {
      color: `${colors.disabledDarken}bb`,
      fontSize: '20px',
    },
  },
  textSeparation: {
    marginLeft: '5px',
  },
})

export default useStyles
