import { useEffect, useState } from 'react'
import { Box, Typography, Paper } from '@mui/material'
import I18n from 'i18n-js'
import { Icons } from '../../utils'
import { InputForm, OptionTranslate } from '..'
import useStyles from './style'

const setinitialValues = (options) => {
  let question = { title: '' }
  options.map((e, index) => {
    question = {
      ...question,
      options: { ...question.options, [index]: { title: '' } },
    }
  })
  return question
}

const QuestionTranslate = ({ translation, question, data, setData, id }) => {
  const options = Object.values(question.options)
  const [questionTranslation, setQuestionTranslation] = useState(
    data[translation.locale]?.questions?.[id]
      ? data[translation.locale]?.questions[id]
      : setinitialValues(options)
  )
  const [questionId, setQuestionId] = useState(id)
  const handleChange = (e) => {
    setQuestionTranslation({
      ...questionTranslation,
      [e.target.name]: e.target.value,
    })
  }
  useEffect(() => {
    setQuestionTranslation(
      data[translation.locale]?.questions?.[id]
        ? data[translation.locale]?.questions[id]
        : setinitialValues(options)
    )
    setQuestionId(id)
  }, [id])
  useEffect(() => {
    if (data[translation.locale]) {
      setData({
        ...data,
        [translation.locale]: {
          ...data[translation.locale],
          questions: {
            ...data[translation.locale].questions,
            [id]: questionTranslation,
          },
        },
      })
    } else {
      data[translation.locale] = {
        name: '',
        description: '',
        questions: { [id]: questionTranslation },
      }
    }
  }, [questionTranslation])
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.labelContainer}>
        {Icons(translation.locale)}
        <Typography
          className={classes.label}
          variant="caption"
          color="textPrimary"
        >
          {I18n.t(translation.name)}
        </Typography>
      </Box>

      <Box className={classes.questionContainer}>
        <Paper className={classes.card}>
          <InputForm
            variant="outlined"
            // size="small"
            name="title"
            onChange={handleChange}
            value={questionTranslation.title || ''}
            label={I18n.t('cruds.questionaries.create.nameQuestionLabel')}
            color="secondary"
            className={classes.inputsHeader}
          />

          <Box className={classes.optionsContainer}>
            {question.type !== 3 &&
              options.map((option, index) => {
                return (
                  <OptionTranslate
                    key={index}
                    id={index}
                    size="small"
                    variant="outlined"
                    type={question.type}
                    setQuestionTranslation={setQuestionTranslation}
                    questionTranslation={questionTranslation}
                    questionId={questionId}
                  />
                )
              })}
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default QuestionTranslate
