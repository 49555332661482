import { useContext, useEffect, useState } from 'react'
import { parametersTypes } from './contants'
import I18n from 'i18n-js'
import { AuthContext } from '../../../../context/auth'
import { languages } from '../../../../utils'
import { validarJSON } from '../../../../validators/validator'

const useBody = ({ defaultValue }) => {
  const { state } = useContext(AuthContext)
  const [tabValue, setTabValue] = useState(parametersTypes.text)
  const sortLanguages = languages.sort((a) => {
    if (a.locale === state.locale) {
      return -1
    } else {
      return 1
    }
  })
  const [pagination, setPagination] = useState({
    json: 0,
  })
  const [errors, setErrors] = useState({
    general: {},
    text: {},
    file: {},
    json: {},
  })
  const [data, setData] = useState({
    general: {},
    text: {},
    file: {},
    json: {},
  })
  const handlePagination = (value) => {
    setPagination((prev) => ({
      ...prev,
      json: prev.json + value,
    }))
  }

  const convertLink = (value) =>
    value ? (!value?.name ? value : URL.createObjectURL(value)) : null

  const openLink = (item) => {
    window.open(convertLink(data.file?.[item.locale]))
  }

  const handleTabs = (_, value) => {
    setTabValue(value)
  }
  function checkFormat(item) {
    // Verificar si el objeto tiene las propiedades esperadas
    return (
      Object.prototype.hasOwnProperty.call(item, 'key') &&
      Object.prototype.hasOwnProperty.call(item, 'value')
    )
  }

  const handleForm = ({ type, name, value, noRequired }) => {
    setErrors((prev) => ({
      ...prev,
      [type]: {
        ...(prev?.[type] ?? {}),
        [name]: !value && !noRequired ? I18n.t('validators.required') : '',
      },
    }))

    if (type === parametersTypes.json) {
      const newValue = validarJSON(value)
      if (newValue?.response) {
        setErrors((prev) => ({
          ...prev,
          [type]: {
            ...(prev?.[type] ?? {}),
            [name]:
              !Array.isArray(newValue.data) ||
              !newValue.data.every((item) => checkFormat(item))
                ? I18n.t('validators.jsonIsNotValidate')
                : '',
          },
        }))
      } else {
        setErrors((prev) => ({
          ...prev,
          [type]: {
            ...(prev?.[type] ?? {}),
            [name]: I18n.t('validators.jsonIsNotValidate'),
          },
        }))
      }
    }

    setData((prev) => ({
      ...prev,
      [type]: {
        ...(prev?.[type] ?? {}),
        [name]: value,
      },
    }))
  }

  const disabledButton = () =>
    !data?.[tabValue]?.[state.locale] ||
    !data.general?.key ||
    Object.values(errors?.[tabValue] ?? {})?.some((item) => item)

  const closeModal = () => {
    setData({
      general: {},
      text: {},
      file: {},
      json: {},
    })
    setErrors({
      general: {},
      text: {},
      file: {},
      json: {},
    })
  }

  useEffect(() => {
    if (defaultValue?.type) {
      let dataLocal = {}

      for (const item of languages) {
        let value = defaultValue?.[item.locale]
        
        if (defaultValue?.type == parametersTypes.json) {
          value = value?.replaceAll(',', ', \n')?.replaceAll('\\', '')?.replace(/^"|"$/g, '');
        }
        dataLocal = {
          ...dataLocal,
          [item.locale]: value ?? '',
        }
      }
      setTabValue(defaultValue?.type)
      setData((prev) => ({
        ...prev,
        general: {
          key: defaultValue.key,
        },
        [defaultValue.type]: dataLocal,
      }))
    }
  }, [defaultValue])

  return {
    tabValue,
    data,
    errors,
    state,
    pagination,
    sortLanguages,
    handlePagination,
    closeModal,
    handleForm,
    handleTabs,
    openLink,
    disabledButton,
  }
}

export { useBody }
