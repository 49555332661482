import { Box } from '@mui/material'
import ViewOne from './viewOne'
import patientService from '../../../../services/patients'
import { useEffect, useState } from 'react'
import ModalLogin from '../../../../components/modalLogin'
import ViewTwo from './viewTwo'
import { Loader } from '../../../../components'

const Council = ({ patient, setOpenSnackBar }) => {
  const [openModalLogin, setOpenModalLogin] = useState(null)
  const [activateModal, setActivateModal] = useState(false)
  const [changeView, setChangeVies] = useState(false)
  const [userCertipair, setUserCertipair] = useState(null)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    setIsLoad(true)
    patientService
      .getTokenCertipair()
      .then((data) => {
        if (
          data?.error?.code === '00' ||
          data?.error?.data?.status === 403 ||
          data?.error?.data?.status === 401
        ) {
          setOpenModalLogin(true)
          localStorage.removeItem('provider-token')
        }
        if (data?.data) {
          setOpenModalLogin(false)
          localStorage.setItem('provider-token', data.data.token)
          setUserCertipair(data?.data?.user_id)
        }
      })
      .finally(() => {
        setIsLoad(false)
      })
  }, [])

  const submitLogin = async (e, data) => {
    e.preventDefault()
    const res = await patientService.postLoginCartipair(data)

    if (res?.error?.data?.status === 403 || res?.error?.data?.status === 401)
      setActivateModal(true)

    if (res?.data) {
      localStorage.setItem('provider-token', res.data.token)
      setOpenModalLogin(false)
    }
  }

  return (
    <Box>
      {isLoad ? (
        <Loader />
      ) : changeView ? (
        <ViewTwo
          userCertipair={userCertipair}
          setChangeVies={setChangeVies}
          patient={patient}
          setOpenSnackBar={setOpenSnackBar}
        />
      ) : (
        <ViewOne
          create={() => setChangeVies(true)}
          openModalLogin={openModalLogin}
        />
      )}

      <ModalLogin
        open={Boolean(openModalLogin)}
        close={() => setOpenModalLogin(false)}
        onSubmit={submitLogin}
        error={activateModal}
        setError={setActivateModal}
      />
    </Box>
  )
}

export default Council
