import { makeStyles } from '@mui/styles'
import colors from '../../../../utils/colors'

const useStyles = makeStyles((theme) => ({
  inputWidth: {
    width: '24%',
    '&.MuiTextField-root': {
      marginLeft: '1%',
      marginBottom: 10
    },
    [theme.breakpoints.down('md')]: {
      width: '49%',
    },
  },
  inputWidth2: {
    width: '24%',
    '&.MuiTextField-root': {
      marginLeft: '1%',
      marginBottom: 25
    },
    [theme.breakpoints.down('md')]: {
      width: '49%',
    },
  },
  containerImage: {
    width: '100px',
  },
  firstFieldCenter: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  containerInputs: {
    width: 'calc(100% - 100px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputTextArea: {
    width: '74%',
    height: 60,
    '&.MuiTextField-root': {
      marginLeft: '1%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  textAreaContainer: {
    width: '100%',
  },
  loading: {
    '&.MuiCircularProgress-root.MuiCircularProgress-root.MuiCircularProgress-root':
      {
        width: '15px',
        height: '15px',
      },
  },
  texInput: {
    '& .MuiFormHelperText-root': {
      color: colors.error,
    },
  },
  inputAutocomplete: {
    '& .MuiTextField-root': {
      background: colors.white,
    },
    marginLeft: '1%',
    width: '24%',
    [theme.breakpoints.down('md')]: {
      width: '49%',
    },
  },
  date: {
    // '& .MuiOutlinedInput-input': {
    //   height: 8,
    // },
    // '& .MuiInputLabel-root': {
    //   top: -5,
    // },
  },
  observationsContainer: {
    width: '100%',
  },
  observation: {
    '&.MuiFormControl-root': {
      width: '100%',
      '&&::-webkit-scrollbar': { width: '4px' },
      '&&::-webkit-scrollbar-thumb': {
        background: 'rgba(0,0,0,.3)',
        borderRadius: '3px',
      },
    },
  },
  add: {
    '&.MuiButton-root': {
      float: 'right',
      marginTop: 20,
      borderRadius: 0,
    },
  },
  contentObservation: {
    width: '90%',
    position: 'relative',
  },
  userInf: {
    width: 150,
    position: 'absolute',
    right: 0,
    bottom: 0,
    '& .MuiTypography-root': {
      color: colors.disabledDarken,
    },
  },
  dr: {
    '&.MuiTypography-root': {
      marginBottom: 40,
    },
  },
  author: {
    float: 'right',
  },
  dateAuthor: {
    '&.MuiTypography-root': {
      float: 'right',
      display: 'block',
    },
  },
  observationList: {
    width: '90%',
    padding: '30px 20px',
    maxHeight: 300,
    background: colors.white,
    border: `1px solid ${colors.greyDarken}`,
    overflow: 'auto',
    '&&::-webkit-scrollbar': { width: '4px' },
    '&&::-webkit-scrollbar-thumb': {
      background: 'rgba(0,0,0,.3)',
      borderRadius: '3px',
    },
  },
  observationItem: {
    position: 'relative',
    boxShadow: `1px 2px ${colors.disabledLighten}`,
    border: `1px solid ${colors.disabledLighten2}`,
    borderRadius: 15,
    padding: 15,
    background: colors.grey,
    fontSize: 16,
    marginBottom: 10,
    '& .MuiTypography-root': {
      fontSize: '.8em',
      wordWrap: 'break-word',
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  flexGrow: {
    flexGrow: 1,
  },
  observationIcon: {
    // '&.MuiIconButton-root': {
    display: 'flex',
    position: 'absolute',
    top: 6,
    right: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '.8em',
    },
    // },
  },
  inputEdit: {
    '&.MuiTextField-root': {
      width: '90%',
    },
  },
  gPLNone: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingLeft: 0,
    },
  },
  bigInput: {
    width: '100%',
    margin: '8px 0 8px 1%',
  },
  firstFields: {
    display: 'flex',
    width: '100%',
    // justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    padding: '0 8px',
    rowGap: '16px',
    position: 'relative',
  },
  removePadding: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingLeft: 8,
    },
  },
  selectItems: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  title: {
    '&.MuiTypography-root': {
      margin: '25px 0 25px 18px',
    },
  },
  subtitle: { margin: '8px 0' },
  text: {
    textTransform: 'capitalize',
    width: '50%',
  },
  textTwo: {
    width: '33%',
  },
  input: {
    background: colors.white,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },

      '& .MuiChip-root': {
        maxHeight: '22px',
        '& .MuiChip-deleteIcon': {
          height: '16px',
          width: '16px',
        },
      },
      '& .MuiSelect-selectMenu': {
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
  },
  '@global': {
    '.MuiAutocomplete-option[aria-selected="true"]': {
      background: colors.greyFull,
    },
  },
}))

export default useStyles
