import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    height: '22px',
    margin: 2,
  },

  input: {
    // maxHeight: '40px',
    background: colors.white,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },

  multiple: {
    background: colors.white,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },

  '@global': {
    '.MuiAutocomplete-option[aria-selected="true"]': {
      background: colors.greyFull,
    },
  },
})

export default useStyles
