const listCountries = Object.freeze({
  fr: 'fr',
  pt: 'pt',
  en: 'gb',
  es: 'es',
  'es-CO': 'es-CO',
})

const transfromLocale = Object.freeze({
  fr: 'fr',
  pt: 'pt',
  en: 'gb',
  es: 'es',
  'es-CO': 'es',
})

const transfromLocalePhone = Object.freeze({
  fr: 'fr',
  pt: 'pt',
  en: 'gb',
  es: 'es',
  'es-CO': 'co',
})
const usersWithPermissions = (value, externalLocal) => {
  let locale = JSON.parse(localStorage.getItem('state'))?.locale
  if (externalLocal) {
    locale = externalLocal
  }
  return value.some(item => item == locale)
}
export { listCountries, usersWithPermissions, transfromLocale, transfromLocalePhone }
