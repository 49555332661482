import { Fragment, useState, useContext } from 'react'
import {
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import I18n from 'i18n-js'
import { AuthContext, loginUser } from '../../context/auth'
import { useSxStyles } from './style'
import { codeVerification } from '../../utils/enum'
import contextkeys from '../../utils/contextkeys'
import { AuthLayout } from '../../components/authLayout'

const ChoseLoginMethod = () => {
  const history = useHistory()
  const { state, dispatch, setUser } = useContext(AuthContext)
  const styles = useSxStyles()
  const [data, setData] = useState({ login_method: '' })
  const change = ({ target }) => {
    const { name, value } = target
    setData({
      ...data,
      [name]: value,
    })
  }

  const control = {
    value: data.login_method,
    onChange: (_, value) => {
      change({
        target: {
          name: 'login_method',
          value,
        },
      })
    },
    exclusive: true,
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    data.email = state.user.email
    data.password = state.user.password

    const res = await loginUser(dispatch, data, setUser)

    if (res?.data?.authenticator) {
      data.firstTime = true
      history.push('/twofactor')
      dispatch({ type: contextkeys.getUser, payload: data })
    } else if (res.error?.code_error_login) {
      dispatch({ type: contextkeys.getUser, payload: data })

      if (
        res.error?.code_error_login === '09' ||
        res.error?.code_error_login === '11'
      ) {
        history.push('/twilio')
      } else {
        history.push('/twofactor')
      }
    }
  }
  if (!state.user) {
    history.push('/')
  }

  return (
    <AuthLayout>
      <Fragment>
        <Typography variant="subtitle2" color="textPrimary">
          {I18n.t('auth.loginMethod')}
        </Typography>

        <Typography variant="caption" color="textSecondary">
          {I18n.t('auth.loginMethodSelected')}
        </Typography>

            <ToggleButtonGroup
              variant="outlined"
              size="small"
              sx={styles.toggleGroup}
              name="login_method"
              {...control}
            >
              <ToggleButton padding="4px 15px" value={codeVerification.twilio}>
                {I18n.t('auth.SMS')}
              </ToggleButton>
              <ToggleButton padding="4px 15px" value={codeVerification.google2factor}>
                {I18n.t('auth.authenticator')}
              </ToggleButton>
            </ToggleButtonGroup>

        <Button
          onClick={handleSubmit}
          disabled={!data.login_method}
          variant="contained"
          color="primary"
          sx={styles.submit}
          component={Link}
          to="/forgotpassword"
          fullWidth
        >
          {I18n.t('auth.signInButton')}
        </Button>
      </Fragment>
    </AuthLayout>
  )
  // (
  //   <Fragment>
  //     <ImageWelcome />
  //     <form onSubmit={handleSubmit} className={classes.container}>
  //       <Grid
  //         container
  //         spacing={8}
  //         direction="column"
  //         justifyContent="center"
  //         alignItems="center"
  //         alignContent="center"
  //         wrap="nowrap"
  //       >
  //         <Grid item>
  //           <Box mt={9}>
  //             <Link to="/">
  //               <img
  //                 src={images.Logo}
  //                 alt={I18n.t('alt.logoIdp')}
  //                 className={classes.logo}
  //               />
  //             </Link>
  //           </Box>
  //         </Grid>

  //         <Grid item className={classes.gridChildren}>
  //           <Typography variant="subtitle2" color="textPrimary">
  //             {I18n.t('auth.loginMethod')}
  //           </Typography>
  //           <Typography variant="caption" color="textSecondary">
  //             {I18n.t('auth.loginMethodSelected')}
  //           </Typography>

  //           <ToggleButtonGroup
  //             variant="outlined"
  //             size="small"
  //             sx={styles.toggleGroup}
  //             name="login_method"
  //             {...control}
  //           >
  //             <ToggleButton value={codeVerification.twilio}>
  //               twilio
  //             </ToggleButton>
  //             <ToggleButton value={codeVerification.google2factor}>
  //               google2factor
  //             </ToggleButton>
  //           </ToggleButtonGroup>

  //           <Button
  //             onClick={handleSubmit}
  //             disabled={!data.login_method}
  //             variant="contained"
  //             color="primary"
  //             sx={styles.submit}
  //             component={Link}
  //             to="/forgotpassword"
  //             fullWidth
  //           >
  //             {I18n.t('auth.signInButton')}
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </form>
  //   </Fragment>
  // )
}

export default ChoseLoginMethod
