import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'

const getColorStatus = (status) => {
  if (status == 1) {
    return 'warning'
  } else if (status == 2) {
    return 'danger'
  } else {
    return ''
  }
}

const Alert = ({ title, image, location, date, time, status }) => (
  <div className={`alert ${getColorStatus(status)}`}>
    <h3 className="text-primary">{title}</h3>
    <div className="alert-container">
      <div className="alert-body">
        <img src={`/images/alert/${image}`} alt={title} />
        <div className="alert-body-text">
          <h3>Dernière localisation: </h3>
          <h4>{location}</h4>
        </div>
      </div>
      <div className="alert-footer">
        <p>{date}</p>
        <p>{time}</p>
      </div>
    </div>
  </div>
)

Alert.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  attention: PropTypes.bool,
  status: PropTypes.number,
}

Alert.defaultProps = {
  title: '',
  image: 'bell.png',
  location: '',
  date: '',
  time: '--:--:--',
  attention: false,
  status: null,
}
export default Alert
