import { Box, Typography } from '@mui/material'
import I18n from 'i18n-js'
import React, { useRef } from 'react'
import SimpleError from '../../../../components/errors/simple'
import useStyles from '../style'
const CSV = ({ errors, handleChange, current }) => {
  const classes = useStyles()
  const file = useRef(null)

  const handleFile = ({ target }) => {
    handleChange(
      {
        target: { 
          name: target.name,
          value: target.files[0] ? target.files[0] : { name: ' ' },
          current
        },
      },
    )

    file.current.value = ''
  }

  return (
    <Box className={classes.containerFlex}>
      <Box className={classes.fullRight} />

      <>
        <Typography
          className={classes.file}
          onClick={() => file.current.click()}
        >
          {I18n.t('cruds.healthCondition.csv')}
        </Typography>

        <SimpleError
          error={errors?.url_image && errors?.url_image !== ''}
          message={errors?.url_image}
        />

        <input
          name="file"
          type="file"
          onChange={(e) => handleFile(e)}
          hidden
          ref={file}
          accept=".csv"
        />
      </>
    </Box>
  )
}

export default CSV
