import { useEffect, useState, Fragment, useContext, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
//material
import {
  Box,
  Typography,
  CircularProgress,
  Fade,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import InputFile from '../../../components/file'
//services
import {
  typeMedicalCenter,
  city,
  country,
  medicalcenters,
  users,
  parameter,
} from '../../../services'
import { canAccess } from '../../../router/permissions'
import { AuthContext } from '../../../context/auth'

//utils
import {
  languages,
  getLatAndLong,
  pagesKeys,
  actionKeys,
  images,
} from '../../../utils'
//validators
import {
  addPersonValidator,
  medicalCenterValidator,
  addKitValidator,
} from '../../../validators/medicalCentersValidator'
//components
import {
  DinamicForm,
  Map,
  AddPerson,
  CrudsBtn,
  SmartDevices,
} from '../../../components'
import useStyles from './style'
import { isEmail, numbersAndCharacters } from '../../../validators/validator'
import HtmlFile from '../../../components/htmlFile'
import { listOfInformation } from '../list'
import { htmlContent } from '../htmlCoontent'
import { BiodyXpert3 } from '../../../components/biodyXpert3'

const CreateCenter = () => {
  const classes = useStyles()
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  //center id
  const pageId = pagesKeys.medicalcenter
  const refF = useRef(null)
  const [listCard, setListCard] = useState({})
  const [loading, setLoading] = useState(true)
  const [createCenter, setCreateCenter] = useState({})
  const [disabledCities, setDisabledCities] = useState(true)
  const [mapDirection, setMapDirection] = useState({})
  const [currentField, setCurrentField] = useState('')
  const [errors, setErrors] = useState({})
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [completed, setCompleted] = useState(false)
  const [disableEmail, setDisableEmail] = useState(true)
  const [typeCenters, setTypeCenters] = useState([])
  const [countries, setCountries] = useState()
  const [cities, setCities] = useState()
  const [file, setFile] = useState()

  const [htmlFile, setHtmlFile] = useState({
    html: '',
    name: '',
    doc_desc: '',
  })

  const findCity = cities?.find((city) => city?.id == createCenter?.city)
  //fields
  const documentDetail = [
    {
      name: 'name',
      label: I18n.t('cruds.medicalCenters.form.nameOfTheConsentDocument'),
      style: classes.nameHtml,
      inputProps: { maxLength: 100 },
    },
    {
      name: 'doc_desc',
      label: I18n.t(
        'cruds.medicalCenters.form.descriptionOfTheConsentDocument'
      ),
      style: classes.description,
      type: 'textArea',
      rows: 2,
    },
  ]
  const fieldsCenterAdress = [
    {
      name: 'address',
      label: I18n.t('cruds.medicalCenters.form.address') + '(*)',
      style: classes.addres,
    },
    {
      name: 'country',
      label: I18n.t('cruds.medicalCenters.form.country') + '(*)',
      type: 'select',
      options: countries,
      style: classes.inputSize,
    },
    {
      name: 'city',
      label: I18n.t('cruds.medicalCenters.form.city') + '(*)',
      type: 'select',
      options: createCenter.country
        ? cities.filter((city) => city.id_country == createCenter.country)
        : [],
      style: classes.inputSize,
      disabled: disabledCities,
    },
  ]
  const fieldsCenterLicense = [
    {
      name: 'license',
      label: I18n.t('cruds.medicalCenters.form.license') + '(*)',
      style: classes.addres,
    },
  ]

  const contactFields = [
    {
      name: 'name',
      label: I18n.t('cruds.medicalCenters.contacts.form.name') + '(*)',
      style: classes.formWidth,
    },
    {
      name: 'lastname',
      label: I18n.t('cruds.medicalCenters.contacts.form.lastname') + '(*)',
      style: classes.formWidth,
    },
    {
      name: 'phone',
      label: I18n.t('cruds.medicalCenters.contacts.form.phone') + '(*)',
      type: 'phone',
      style: classes.formWidth,
    },

    {
      name: 'email',
      label: I18n.t('cruds.medicalCenters.contacts.form.email') + '(*)',
      style: classes.formWidth,
    },
  ]
  const kitFields = [
    {
      name: 'sensorOne',
      label: I18n.t('cruds.medicalCenters.sensors.form.sensor') + ' 1 (*)',
      fullWidth: true,
      icon: (
        <img src={images.SensorXSense} width={216 * 0.15} height={262 * 0.15} />
      ),
    },
    {
      name: 'sensorTwo',
      label: I18n.t('cruds.medicalCenters.sensors.form.sensor') + ' 2',
      fullWidth: true,
      icon: (
        <img src={images.SensorXSense} width={216 * 0.15} height={262 * 0.15} />
      ),
    },
    {
      name: 'sensorThree',
      label: I18n.t('cruds.medicalCenters.sensors.form.sensor') + ' 3',
      fullWidth: true,
      icon: (
        <img src={images.SensorXSense} width={216 * 0.15} height={262 * 0.15} />
      ),
    },
  ]
  const fieldsCenterInfo = [
    {
      name: 'name',
      label: I18n.t('cruds.medicalCenters.form.name') + '(*)',
      style: classes.inputSize,
    },
    {
      name: 'email',
      label: I18n.t('cruds.medicalCenters.form.email') + '(*)',
      style: classes.inputSize,
      onBlur: async () => {},
    },
    {
      name: 'id_medical_center_type',
      label: I18n.t('cruds.medicalCenters.form.typeCenter') + '(*)',
      type: 'select',
      options: typeCenters,
      style: classes.inputSize,
    },
    {
      name: 'phone',
      label: I18n.t('cruds.medicalCenters.form.phone') + '(*)',
      type: 'phone',
      style: classes.inputSize,
    },
  ]

  const [identifiers, setIdentifiers] = useState([
    {
      name: 'identifier',
      label: `${I18n.t('cruds.medicalCenters.identifiers')}`,
      className: classes.inputAutocomplete,
      classAutoComplete: classes.inputAutocomplete,
      type: 'simpleAutocomplete',
      options: [],
    },
    {
      name: 'identifier_number',
      style: classes.identifier,
    },
  ])

  const [optionFile, setOptionFile] = useState('0')

  //methods
  const handleChange = async ({ target }) => {
    const { name, value } = target

    if (name) {
      let createCenterCopy = {
        ...createCenter,
        ...(name === 'country' && { city: '' }),
      }

      let values = name === 'email' ? value.toLowerCase() : value
      let indentifierNames = ['identifier', 'identifier_number']

      if (indentifierNames.includes(name)) {
        const identifierName = indentifierNames?.find((item) => item !== name)

        setIdentifiers((prev) =>
          prev.map((item) => {
            if (item.name === 'identifier') {
              return {
                ...item,
                label: `${item.name} ${
                  value || createCenter[identifierName] ? '(*)' : ''
                }`,
              }
            }
            return item
          })
        )
        if (name === 'identifier_number') {
          if (!numbersAndCharacters(value) || value === '') {
            createCenterCopy = {
              ...createCenterCopy,
              [name]: value,
            }
          }
        } else {
          createCenterCopy = {
            ...createCenterCopy,
            [name]: value,
          }
        }
      } else {
        createCenterCopy = {
          ...createCenterCopy,
          [name]: values,
        }
      }

      setCreateCenter(createCenterCopy)

      setCurrentField(name)
      if (name == 'country') {
        setDisabledCities(false)
      }

      if (name === 'email' && !isEmail(value)) {
        const response = await users.checkEmail(values)

        if (response.data) {
          setErrors({
            ...errors,
            email: I18n.t('validators.emailExist'),
          })
          setDisableEmail(true)
        } else {
          setDisableEmail(false)
        }
      }
    } else {
      setCreateCenter({ ...createCenter, phone: { target: { name, value } } })
      setCurrentField('phone')
    }
  }

  const submitCenter = async () => {
    setLoading(true)
    setDisabledSubmit(true)
    let body = { ...createCenter }
    const divice = refF.current?.getData()
    if (Object.values(divice ?? {})?.length) {
      setListCard(divice)
      body = {
        ...body,
        divice,
      }
    }
    if (htmlFile.name && (file || htmlFile.html)) {
      body.doc_name = htmlFile.name
      body.doc_desc = htmlFile.doc_desc ?? ''

      if (optionFile === '0') {
        body.doc_file = file
        delete body.doc_html
      } else if (optionFile === '1') {
        body.doc_html = htmlContent(htmlFile.html, htmlFile.name)
        delete body.doc_file
      }
    }

    if (!body.identifier || !body.identifier_number) {
      delete body.identifier
      delete body.identifier_number
    }
    if (!body?.latitud) body.latitud = 0
    if (!body?.longitud) body.longitud = 0
    const response = await medicalcenters.create(body)
    if (response?.error !== null && response?.error !== undefined) {
      let err = {}

      if (response.error?.error?.phone !== undefined) {
        err.phone = I18n.t('validators.phoneExist')
      }

      if (response.error?.error?.address !== undefined) {
        err.address = I18n.t('validators.addressExist')
      }

      if (response.error?.error?.doc_file !== undefined) {
        err.docfile = I18n.t('validators.pdfIsNotValidate')
      }
      setErrors({
        ...errors,
        ...err,
      })
    }

    if (response?.data) {
    setListCard({})
      setCompleted(true)
    }
    setLoading(false)
  }

  //fetch
  const getSelects = async () => {
    const typecenterResponse = await typeMedicalCenter.list()
    setTypeCenters([
      ...typecenterResponse.data?.medicalCenterTypes.map((center) => {
        return {
          id: center.id,
          name: center.description,
        }
      }),
    ])

    const countryResponse = await country.list()
    setCountries(
      countryResponse.data.countries.filter((country) => {
        if (
          languages?.find((language) => language?.locale === country?.locale)
        ) {
          return country
        }
      })
    )

    const citiesResponse = await city.list()
    setCities(citiesResponse.data?.cities)

    parameter.identifierList().then((response) => {
      setIdentifiers((prev) =>
        prev.map((item) => {
          if (item.name === 'identifier') {
            return {
              ...item,
              options:
                response?.data?.values?.map((item) => ({
                  id: item,
                  name: item,
                })) || [],
            }
          }
          return item
        })
      )
    })
  }

  useEffect(() => {
    const accessAsync = async () => {
      if (!canAccess(actionKeys.create, pageId, state)) {
        history.push('/dashboard')
      } else {
        await getSelects()
        setLoading(false)
      }
    }

    accessAsync()
  }, [])

  //set map address
  useEffect(() => {
    const createC = async () => {
      if (createCenter.city) {
        const addressMap = `${
          createCenter.address ? createCenter.address : ''
        }, ${
          cities
            ?.find((city) => city?.id == createCenter?.city)
            ?.name?.split('.')?.[1]
        }, ${
          countries?.find((country) => country?.id == createCenter?.country)
            ?.name
        }`

        const direction = await getLatAndLong(addressMap)
        if (direction) {
          setMapDirection(direction)
          createCenter.latitud = direction.lat
          createCenter.longitud = direction.lng
        }
      }
    }

    createC()
  }, [createCenter.city, createCenter.address])

  //validateData
  useEffect(() => {
    const validationAsync = async () => {
      const errorsEmpty = Object.values(errors).every((item) => !item)
      const validations = await medicalCenterValidator(
        createCenter,
        currentField,
        errors
      )

      if (validations.valid && errorsEmpty) {
        setDisabledSubmit(false)
      } else {
        setDisabledSubmit(true)
      }
      setErrors(validations.errors)
    }

    validationAsync()
  }, [createCenter, file])

  const onChange = ({ target }) => {
    const { name, value } = target

    setHtmlFile({
      ...htmlFile,
      [name]: value,
    })
  }

  const handleFile = (e) => {
    setFile(e)
    if (errors.docfile) {
      const errorsCopy = { ...errors }
      delete errorsCopy.docfile
      setErrors(errorsCopy)
    }
  }
  return (
    <Fade in={true} timeout={500}>
      <Box p={4}>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.mcenter')} /{' '}
          <Link
            to="/dashboard/medical-center"
            className={classes.textBreadcrum}
          >
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
          {I18n.t('cruds.medicalCenters.create.indicator')}
        </Typography>

        {loading ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <>
            <Grid container spacing={2}>
              {!completed ? (
                <Fragment>
                  <Grid item lg={6} mt={4}>
                    <Box className={classes.titleBarContainer}>
                      <Box className={classes.titleContainer}>
                        <Typography variant="subtitle1" color="textSecondary">
                          {I18n.t('cruds.medicalCenters.create.titlePage')}
                        </Typography>
                        <Typography
                          variant="h6"
                          color="primary"
                          className={classes.addressTitle}
                        >
                          {createCenter?.license && !completed
                            ? createCenter.license
                            : createCenter?.name && completed
                            ? createCenter.name
                            : I18n.t(
                                'cruds.medicalCenters.create.licenseSubtitle'
                              )}
                        </Typography>
                      </Box>
                    </Box>
                    <DinamicForm
                      inputs={fieldsCenterLicense}
                      handleChange={handleChange}
                      values={createCenter}
                      errors={errors}
                    />
                    <Box className={classes.identifierContainer}>
                      <DinamicForm
                        inputs={identifiers}
                        handleChange={handleChange}
                        values={createCenter}
                        errors={errors}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} mt={4}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {I18n.t('cruds.medicalCenters.create.informationCenter')}
                    </Typography>
                    <Typography variant="h6" color="primary">
                      {createCenter?.name
                        ? createCenter.name
                        : I18n.t('cruds.medicalCenters.create.indicator')}
                    </Typography>
                    <Box mt={2} className={classes.inputsContainer}>
                      <DinamicForm
                        inputs={fieldsCenterInfo}
                        handleChange={handleChange}
                        values={createCenter}
                        errors={errors}
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={6} mt={4}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {I18n.t('cruds.medicalCenters.create.addressSubtitle')}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="primary"
                      className={classes.addressTitle}
                    >
                      {createCenter?.address
                        ? createCenter.address +
                          (createCenter.city
                            ? ' - ' +
                              (findCity?.name?.split('.')?.[1] ??
                                findCity?.name)
                            : '') +
                          ', ' +
                          (createCenter.country
                            ? countries?.find(
                                (country) =>
                                  country?.id == createCenter?.country
                              )?.name
                            : '')
                        : I18n.t('cruds.medicalCenters.create.addressTitle')}
                    </Typography>
                    <Box className={classes.inputsContainer}>
                      <DinamicForm
                        inputs={fieldsCenterAdress}
                        handleChange={handleChange}
                        values={createCenter}
                        errors={errors}
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={6} mt={1}>
                    {Object.values(mapDirection).length > 0 && (
                      <Box className={classes.mapContainer}>
                        <Map center={mapDirection} type={2} />
                      </Box>
                    )}
                  </Grid>

                  <Grid item lg={12} mt={4}>
                    <Box className={classes.formContainer}>
                      <Box>
                        <AddPerson
                          validator={addPersonValidator}
                          data={createCenter}
                          setData={setCreateCenter}
                          title={I18n.t('cruds.medicalCenters.contacts.title')}
                          subtitle={I18n.t(
                            'cruds.medicalCenters.contacts.subtitle'
                          )}
                          button={I18n.t(
                            'cruds.medicalCenters.contacts.button'
                          )}
                          personFields={contactFields}
                        />
                      </Box>
                      <Box>
                        <SmartDevices
                          kitFields={kitFields}
                          title={I18n.t('cruds.medicalCenters.sensors.title')}
                          subtitle={I18n.t(
                            'cruds.medicalCenters.sensors.subtitle'
                          )}
                          button={I18n.t('cruds.medicalCenters.sensors.button')}
                          validator={addKitValidator}
                          data={createCenter}
                          setData={setCreateCenter}
                        />
                      </Box>

                      <BiodyXpert3 listCards={listCard} ref={refF} />
                    </Box>
                  </Grid>

                  <Grid item lg={12} my={4}>
                    <Typography variant="h6" color="primary">
                      {I18n.t('cruds.medicalCenters.create.consentDocument')}
                    </Typography>

                    <Box>
                      <FormControl>
                        <RadioGroup
                          defaultValue="0"
                          name="radio-buttons-group"
                          row
                          onChange={(_, value) => {
                            setOptionFile(value)
                          }}
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="File"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="HTML"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    {optionFile === '0' ? (
                      <Grid container spacing={2}>
                        <Grid item lg={6}>
                          <DinamicForm
                            inputs={documentDetail}
                            handleChange={onChange}
                            values={htmlFile}
                            errors={errors}
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <InputFile
                            value={file?.name}
                            label={I18n.t(
                              'cruds.medicalCenters.create.consentDocument'
                            )}
                            onChange={handleFile}
                            accept=".pdf"
                            errorMessage={I18n.t('validators.invalidFormat')}
                            externalError={errors?.docfile}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <HtmlFile
                        name={documentDetail}
                        structure={htmlContent(htmlFile.html, htmlFile.name)}
                        value={htmlFile}
                        onChange={onChange}
                        listOfInformation={listOfInformation}
                      />
                    )}
                  </Grid>
                </Fragment>
              ) : (
                <Fade in={completed} timeout={500}>
                  <Box className={classes.finishContainer}>
                    <CheckCircle className={classes.chekCircle} />
                    <Typography variant="h5" color="primary">
                      {I18n.t('components.success')}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {I18n.t('cruds.medicalCenters.create.created')}
                    </Typography>
                  </Box>
                </Fade>
              )}
            </Grid>

            <Box className={!completed ? classes.footer : classes.footerEnd}>
              {!completed ? (
                <Fragment>
                  <CrudsBtn
                    text={I18n.t('components.cancel')}
                    variant={'text'}
                    component={Link}
                    to="/dashboard/medical-center"
                    className={classes.cancel}
                  />
                  <CrudsBtn
                    text={I18n.t('components.increment')}
                    disabled={!disabledSubmit && !disableEmail ? false : true}
                    click={submitCenter}
                  />
                </Fragment>
              ) : (
                <CrudsBtn
                  text={I18n.t('cruds.medicalCenters.return')}
                  component={Link}
                  to="/dashboard/medical-center"
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </Fade>
  )
}

export default CreateCenter
