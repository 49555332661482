import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material'
import I18n from 'i18n-js'
import languages from '../../utils/languages'
import { Icons } from '../../utils/icons'
import useStyles from './style'
import DinamicForm from '../dinamicForm'
import { isEmpty } from '../../validators/validator'
const ModalForm = ({
  tittle,
  change,
  disabled,
  submit,
  open,
  close,
  value,
  inputs,
  type = 1,
  values = {},
  errors,
  icons = false,
  objectValueLocation,
  btnLabel,
  content
}) => {
  const langs = []
  const classes = useStyles()
  
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle>
        {tittle}

        <IconButton onClick={close} className={classes.close}>
          {Icons('close')}
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={(e) => {
          submit(e, langs)
        }}
      >
        <DialogContent dividers className={classes.dialogContent}>
          {type == 1 ? (
            languages.map((language, index) => {
              langs.push({ locale: language.locale })
              return (
                <Box /* my={3} */ key={index}>
                  <TextField
                    variant="outlined"
                    id={`item${index}`}
                    label="Nom(*)"
                    placeholder={I18n.t(language.name)}
                    defaultValue={value != null ? value[`item${index}`] : value}
                    onChange={change}
                    // size="small"
                    color="secondary"
                    className={classes.form}
                    maxLength={12}
                    fullWidth
                    inputProps={{
                      maxLength: 250,
                    }}
                    error={
                      value[`item${index}`] === ''
                        ? false
                        : isEmpty(value[`item${index}`]) || false
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {Icons(language.locale, true)}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {value[`item${index}`] === ''
                    ? false
                    : isEmpty(value[`item${index}`]) && (
                        <Typography variant="caption" color="error">
                          {I18n.t('validators.required')}
                        </Typography>
                      )}
                  <input
                    type="hidden"
                    value={language.locale}
                    onChange={change}
                  />
                </Box>
              )
            })
          ) : (
            <Box my={1} className={classes.formContainer}>
              {icons ? (
                <Box className={classes.iconForm}>
                  <Box className={classes.sections}>
                    {inputs.map((input, index) => (
                      <Box key={index} style={{minHeight: '58px'}}>{input.icon}</Box>
                    ))}
                  </Box>
                  <Box className={classes.sections}>
                    <DinamicForm
                      inputs={inputs}
                      handleChange={change}
                      values={values}
                      errors={errors}
                      objectValueLocation={objectValueLocation}
                    />
                  </Box>
                </Box>
              ) : (
                <DinamicForm
                  inputs={inputs}
                  handleChange={change}
                  values={values}
                  errors={errors}
                />
              )}
            </Box>
          )}

          {content}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.cancel}
            size="small"
            onClick={close}
          >
            {I18n.t('components.cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.submit}
            size="small"
            type="submit"
            disabled={disabled}
          >
            {!btnLabel
              ? I18n.t('components.update')
              : I18n.t('components.increment')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ModalForm
