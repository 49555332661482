import { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import CardQuestion from '../questionsEdition/cardQuestion'
import { stepOneQuestionarieValidator } from '../../../../../../validators/smartTestValidator'
import useStyles from './style'
import I18n from 'i18n-js'

const intialLengthofquestions = (values, locale) => {
  if (values[locale].questions) {
    return Object.values(values[locale].questions).length
  } else {
    return 0
  }
}

const setInitialQuestions = (numberofQuestions) => {
  const questions = []
  for (let i = 0; i < numberofQuestions; i++) {
    questions.push({ id: i })
  }
  return questions
}

const sendInformation = (locale, questionarieValues, index, id) => {
  if (questionarieValues[locale].questions) {
    const informationKey = Object.keys(questionarieValues[locale].questions)[
      index
    ]
    const information = Object.values(questionarieValues[locale].questions)[
      index
    ]
    delete questionarieValues[locale].questions[informationKey]
    questionarieValues[locale].questions[id] = information
    return information
  } else return {}
}

const QuestionsEdition = (props) => {
  const classes = useStyles()
  const { questionarieValues, setQuestionarie, locale, setDisabled, questionTypes } = props
  const [numberofQuestions, setNumberofQuestions] = useState(
    intialLengthofquestions(questionarieValues, locale)
  )
  const [questions, setQuestions] = useState(
    setInitialQuestions(numberofQuestions, props)
  )

  /* methods */
  const handleCreateQuestion = () => {
    setQuestions([...questions, { id: numberofQuestions }])
    setNumberofQuestions(numberofQuestions + 1)
  }
  const handleRemoveQuestion = (id) => {
    const questionsRemoved = questions.filter((questions) => questions.id != id)
    setQuestions([...questionsRemoved])
    delete questionarieValues[locale].questions[id]
    if (questionarieValues.traductions) {
      Object.values(questionarieValues.traductions).map((traduction) => {
        traduction.questions = {}
      })
    }
    setDisabled(
      stepOneQuestionarieValidator(questionarieValues[locale].questions)
    )
  }

  useEffect(() => {
    setDisabled(
      stepOneQuestionarieValidator(questionarieValues[locale].questions)
    )
  }, [questionarieValues])
  return (
    <Box>
      <Box>
        {questions.length > 0 &&
          questions.map((question, index) => {
            return (
              <CardQuestion
                questionarieValues={questionarieValues}
                setQuestionarie={setQuestionarie}
                values={sendInformation(
                  locale,
                  questionarieValues,
                  index,
                  question.id
                )}
                locale={locale}
                id={question.id}
                key={question.id}
                required={questionarieValues?.[locale]?.questions?.[question?.id]?.required ?? false}
                remove={handleRemoveQuestion}
                questionTypes={questionTypes}
              />
            )
          })}
      </Box>
      <Box className={classes.actionContainer}>
        <Button variant="text" color="secondary" onClick={handleCreateQuestion}>
          {I18n.t('cruds.questionaries.create.addOption')}
        </Button>
      </Box>
    </Box>
  )
}

export default QuestionsEdition
