import { useRef, useState } from 'react'
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Fade,
  Grid,
  TextField,
} from '@mui/material'
import { AlertModal, DinamicForm } from '../../../../components'
import useStyles from './style'
import I18n from 'i18n-js'
import { smartTests } from '../../../../services'
import { Icons } from '../../../../utils'
import SimpleError from '../../../../components/errors/simple'
import ImageLink from '../../../../components/imageLink'

const InformationGeneral = ({
  values,
  change,
  providers,
  locale,
  errors,
  detailImages,
  oldImage = null,
}) => {
  const classes = useStyles({ error: errors.url_image })
  //inputs
  const [accept] = useState('.jpg,.png,.jpeg')
  const file = useRef(null)

  const [inputs] = useState([
    {
      name: 'name',
      label: I18n.t('cruds.smartTest.form.name') + '(*)',
      fullWidth: true,
    },
    {
      name: 'description',
      label: I18n.t('cruds.smartTest.form.description') + '(*)',
      type: 'textArea',
      rows: 3,
      fullWidth: true,
    },
    {
      name: 'providers_id',
      label: I18n.t('cruds.smartTest.form.providers') + '(*)',
      type: 'select',
      options: providers,
      fullWidth: true,
    },
    {
      name: 'sensors_quantity',
      label: I18n.t('cruds.smartTest.form.sensors'),
      type: 'select',
      options: [
        { name: '1', id: 1 },
        { name: '2', id: 2 },
        { name: '3', id: 3 },
        { name: '4', id: 4 },
        { name: '5', id: 5 },
      ],
      fullWidth: true,
    },
    {
      name: 'instructional_picture',
      type: 'file',
      format: 'photo',
      label: I18n.t('cruds.smartTest.form.instructionTest') + '(*)',
      styleInput: classes.inputLabel,
    },
    {
      name: 'data_sensors',
      type: 'file',
      format: 'photo',
      label: I18n.t('cruds.smartTest.form.instructionSensors'),
      styleInput: classes.inputLabel,
    },
    {
      name: 'updateProvider',
      label: I18n.t('cruds.smartTest.statusSelector') + '(*)',
      type: 'select',
      options: [
        {
          id: 1,
          name: I18n.t('cruds.smartTest.enum.create'),
          action: 'create',
        },
        {
          id: 2,
          name: I18n.t('cruds.smartTest.enum.update'),
          action: 'update',
        },
        {
          id: 3,
          name: I18n.t('cruds.smartTest.enum.recordless'),
          action: 'recordless',
        },
      ],
      fullWidth: true,
    },
    {
      name: 'position',
      label: I18n.t('cruds.smartTest.form.position') + '(*)',
      style: `${classes.mt} ${classes.position}`,
    },
  ])

  /* validations */
  const [openModal, setOpenModal] = useState(false)
  const [changedType, setChangedType] = useState({})

  /* methods */
  const useHandleChangeTypeTest = (e) => {
    if (
      values[locale]?.blocks &&
      Object.values(values[locale].blocks).length > 0
    ) {
      Object.values(values[locale].blocks).map(async (block) => {
        if (block.id) {
          await smartTests.deleteBlock(block.id)
        }
      })
    }
    if (e) {
      change({ target: { name: 'type', value: e } })
    } else change({ target: { name: 'type', value: changedType } })
  }

  const handleFileStyle = (value) =>
    value ? classes.wFileImage : classes.wFull

  const handleImageErrorMessage = (name) => {
    return (values?.[locale]?.[name] && errors?.[name]) ? true : false
  }
  return (
    <Box>
      <Box className={classes.firstColumn}>
        <Box className={classes.sections}>
          <Grid container spacing={1}>
            <Grid item lg={values.type === 2 ? 6 : 12}>
              <DinamicForm
                inputs={inputs.slice(0, 1)}
                handleChange={change}
                values={values[locale]}
                errors={errors}
                accept={accept}
              />
            </Grid>

            {values.type === 2 && (
              <Grid item lg={6}>
                <Box className={classes.containerInputImage}>
                  <Box className={handleFileStyle(detailImages?.url_image && !errors.url_image)}>
                    <TextField
                      value={
                        values?.url_image && !errors.url_image
                          ? values.url_image.name
                          : ' '
                      }
                      id="datatel"
                      type="text"
                      disabled
                      fullWidth
                      error={errors.url_image && errors.url_image !== ''}
                      className={classes.file}
                      label={
                        I18n.t('cruds.smartTest.form.icon') +
                        (values.type === 2 ? '(*)' : '')
                      }
                      onClick={() => file.current.click()}
                      InputProps={{
                        endAdornment: Icons('folder'),
                      }}
                    />

                    <SimpleError
                      error={errors.url_image && errors.url_image !== ''}
                      message={errors.url_image}
                      height={true}
                    />
                  </Box>

                  {(detailImages?.url_image && !errors.url_image) && (
                    <ImageLink images={detailImages?.url_image} left />
                  )}
                </Box>

                <input
                  name="url_image"
                  type="file"
                  onChange={(e) =>
                    e.target.files[0] &&
                    change({
                      target: {
                        name: e.target.name,
                        value: e.target.files[0],
                      },
                    })
                  }
                  hidden
                  ref={file}
                  accept=".png"
                />
              </Grid>
            )}
          </Grid>
          <DinamicForm
            inputs={inputs.slice(1, 2)}
            handleChange={change}
            values={values[locale]}
            errors={errors}
          />
          {values.type === 2 && (
            <DinamicForm
              inputs={inputs.slice(7, 8)}
              handleChange={change}
              values={values || null}
              errors={errors}
            />
          )}
        </Box>
        <Box className={classes.sections}>
          <Tabs
            value={values.type || 2}
            indicatorColor="secondary"
            variant="fullWidth"
            textColor="secondary"
            onChange={(e, newValue) => {
              if (
                values[locale]?.blocks &&
                Object.values(values[locale].blocks).length > 0
              ) {
                setOpenModal(true)
                setChangedType(newValue)
              } else {
                useHandleChangeTypeTest(newValue)
              }
            }}
            className={classes.tabs}
            // style={{ height: '38px' }}
          >
            <Tab label={I18n.t('cruds.smartTest.form.idpApp')} value={2} />
            <Tab label={I18n.t('cruds.smartTest.form.idpSmart')} value={1} />
          </Tabs>
          {values.type === 2 ? (
            <Box className={classes.firstBox} mt={oldImage !== null ? 5 : 0}>
              <Box className={classes.switchWidth}>
                <Typography mb={1} variant="body2" color="textSecondary">
                  {I18n.t('cruds.smartTest.form.actionSelect')}
                </Typography>

                <DinamicForm
                  inputs={inputs.slice(6, 7)}
                  handleChange={change}
                  values={values}
                />
              </Box>

              {values.updateProvider && values.updateProvider !== 3 && (
                <Fade in={values.type == 2} timeout={300}>
                  <Box
                    className={`${classes.containerprovider} ${classes.marginTop}`}
                  >
                    <DinamicForm
                      inputs={inputs.slice(2, 3)}
                      handleChange={change}
                      values={values}
                    />
                  </Box>
                </Fade>
              )}
            </Box>
          ) : (
            <Fade in={values.type == 1} timeout={300}>
              <Box className={classes.secondBox} mt={oldImage !== null ? 6 : 2}>
                {values.type == 1 && (
                  <>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.sensorsLabel}
                    >
                      {I18n.t('cruds.smartTest.form.sensorsLabel')}
                    </Typography>
                    <Box className={classes.sensorsContainer}>
                      <DinamicForm
                        inputs={inputs.slice(3, 4)}
                        handleChange={change}
                        values={values}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Fade>
          )}
        </Box>
      </Box>
      <Fade in={values.type == 1} timeout={300}>
        <Box>
          <Box className={classes.thirdColumn}>
            <Box
              className={`${classes.containerInputImage} ${classes.alignEnd}`}
            >
              <DinamicForm
                inputs={inputs.slice(4, 5)}
                handleChange={change}
                values={values[locale]}
                containerStyle={handleFileStyle(
                  detailImages.url_instructional &&
                    !errors.instructional_picture
                )}
                errors={handleImageErrorMessage('instructional_picture')}
                accept={accept}
              />

              {detailImages.url_instructional &&
                !errors.instructional_picture && (
                  <ImageLink images={detailImages.url_instructional} left />
                )}

              <Box className={classes.errorMessage}>
                <SimpleError
                  error={handleImageErrorMessage('instructional_picture')}
                  message={errors.instructional_picture}
                />
              </Box>
            </Box>

            <Box
              className={`${classes.containerInputImage} ${classes.alignEnd}`}
            >
              <DinamicForm
                inputs={inputs.slice(5, 6)}
                handleChange={change}
                values={values[locale]}
                containerStyle={handleFileStyle(
                  detailImages.url_sensors && !errors.data_sensors
                )}
                accept={accept}
                errors={handleImageErrorMessage('data_sensors')}
              />

              {detailImages.url_sensors && !errors.data_sensors && (
                <ImageLink images={detailImages.url_sensors} left />
              )}
              <Box className={classes.errorMessage}>
                <SimpleError
                  error={handleImageErrorMessage('data_sensors')}
                  message={errors.data_sensors}
                />
              </Box>
            </Box>
          </Box>
          <AlertModal
            open={openModal}
            close={() => {
              setOpenModal(false)
            }}
            title={I18n.t('components.warningAtention')}
            message={I18n.t('components.warningDelete')}
            SuccessText={I18n.t('components.yes')}
            success={useHandleChangeTypeTest}
          />
        </Box>
      </Fade>
    </Box>
  )
}

export default InformationGeneral
