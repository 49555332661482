import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  loading: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
})

export default useStyles