import { useEffect, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import {
  RadioButtonUnchecked,
  AddCircle,
  HighlightOff,
  CheckBoxOutlineBlank,
} from '@mui/icons-material'
import I18n from 'i18n-js'
import InputForm from '../inputForm'
import { OptionQuestionValidator } from '../../validators/createQuestionaryValidator'
import useStyles from './style'
import { isEmpty } from '../../validators/validator'
// import { isEmpty } from '../../validators/validator'

const OptionQuestion = ({
  name,
  score,
  id,
  create,
  remove,
  cardItems,
  setCardItems,
  type,
  values,
  sizeArrayOptions,
  numberTitle,
}) => {
  const [option, setOption] = useState(values)

  const handleNumberOfOptions = () => {
    if (numberTitle == sizeArrayOptions && sizeArrayOptions < 3) {
      return {
        0: () => {
          create()
        },
      }
    } else if (numberTitle == sizeArrayOptions && sizeArrayOptions > 2) {
      return {
        0: () => {
          create()
        },
        1: () => {
          delete cardItems.options[id]
          remove(id)
        },
      }
    } else {
      return {
        0: () => {
          delete cardItems.options[id]
          remove(id)
        },
      }
    }
  }
  const handleIcon = () => {
    if (numberTitle == sizeArrayOptions && sizeArrayOptions < 3) {
      return { 1: <AddCircle color="secondary" /> }
    } else if (numberTitle == sizeArrayOptions && sizeArrayOptions > 2) {
      return { 1: <AddCircle color="secondary" />, 2: <HighlightOff /> }
    } else {
      return { 1: <HighlightOff /> }
    }
  }
  const handleChange = (e) => {
    setOption({ ...option, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (OptionQuestionValidator(option) || type == 4 || type == 5) {
      option.completed = true
    } else {
      option.completed = false
    }
    setCardItems({
      ...cardItems,
      options: { ...cardItems.options, [id]: option },
    })
  }, [option])
  const actions = handleNumberOfOptions()
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      {type == 1 && <RadioButtonUnchecked color="disabled" />}
      {type == 2 && <CheckBoxOutlineBlank color="disabled" />}
      <Box className={classes.flexContainer}>
        <InputForm
          name="title"
          variant="outlined"
          color="secondary"
          label={
            type < 3
              ? I18n.t(name) + ' ' + numberTitle + ' (*)'
              : I18n.t('cruds.questionaries.label')
          }
          error={
            option?.title === undefined || type > 3
              ? false
              : isEmpty(option?.title) || false
          }
          onChange={handleChange}
          value={option?.title != undefined ? option.title : ''}
          // size="small"
          className={classes.inputName}
        />
        {option?.title === undefined || type > 3
          ? false
          : isEmpty(option?.title) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.required')}
              </Typography>
            )}
      </Box>
      <InputForm
        name="score"
        variant="outlined"
        color="secondary"
        // size="small"
        onInput={(e) => {
          e.target.value = e.target.value
            .replace(/[^0-9.]/, '')
            .replace(/(\..*)\./g, '$1')
        }}
        value={option?.score != undefined ? option.score : ''}
        className={type < 3 ? classes.inputValue : classes.inputNumberValue}
        label={I18n.t(score)}
        onChange={handleChange}
      />
      {type < 3 && (
        <Box className={classes.buttonsContainer}>
          {Object.values(handleIcon()).map((icon, index) => {
            return (
              <IconButton
                onClick={actions[index]}
                size="small"
                className={classes.action}
                key={index}
              >
                {icon}
              </IconButton>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default OptionQuestion
