import { Box, Grid, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { ListFile } from './components'
import { useStyles } from './styles'
import { createRef } from 'react'
import I18n from 'i18n-js'
import { useParams } from 'react-router-dom'
import { isContent } from '../../../../validators/validator'
import patients from '../../../../services/patients'
import { LoadingButton } from '@mui/lab'
import { TeleExpertiseEquivalenceTypes } from './components/listFife/constants'

const TeleExpertise = ({ files, sendData }) => {
  const ref = createRef()
  const [data, setData] = useState({})
  const styles = useStyles()
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  //   const [disabled, setDisabled] = useState(true)

  const onChange = ({ name, value }) => {
    if (isContent(value)) {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const someThingIsSelected = (_, value) => {
    setCount(Object.values(value ?? {}).filter((item) => item)?.length ?? 0)
  }
  const getInternalData = (internalData) => {
    setLoading(true)
    let body = { patient_id: id, text: data.reason, urls: [] }

    Object.entries(internalData?.data ?? {})?.forEach(([key, value]) => {
      if (value) {
        const [name, position] = key?.split('-')
        const transform = TeleExpertiseEquivalenceTypes[name]
        const file = files[transform]?.[name]?.value?.[parseInt(position)]

        if (file) {
          body = {
            ...body,
            urls: [...body.urls, file],
          }
        }
      }
    })

    patients
      .createOmnidoc(body)
      .then((response) => {
        if (response?.data?.redirect_url) {
          window.open(response.data.redirect_url, 'blank')
        }
        sendData(true)
        internalData.clean()
        setData({
          reason: '',
        })
      })
      .catch(() => {
        sendData(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box>
      <Grid container columnSpacing={4}>
        <Grid item xs={5}>
          <Typography sx={styles.title}>
            {I18n.t('patient.teleExpertise.reason.title')}
          </Typography>

          <TextField
            onChange={({ target }) => {
              onChange(target)
            }}
            name="reason"
            value={data.reason ?? null}
            multiline
            fullWidth
            minRows={12}
            maxRows={15}
          />

          <Box sx={styles.sendButton}>
            <Typography sx={styles.count}>
              {count
                ? `${I18n.t(`patient.teleExpertise.youHave`)} ${count} ${I18n.t(
                    `patient.teleExpertise.selectedFiles`
                  )}`
                : ''}
            </Typography>

            <Box sx={styles.containerButtons}>
              <LoadingButton
                loading={loading}
                color="secondary"
                variant="contained"
                disabled={!data.reason || loading}
                onClick={() => {
                  if (ref.current) {
                    ref.current.click()
                  }
                }}
              >
                {I18n.t(`patient.teleExpertise.submit`)}
              </LoadingButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography sx={styles.title}>
            {I18n.t('patient.teleExpertise.selects.title')}
          </Typography>

          <ListFile
            values={files}
            someThingIsSelected={someThingIsSelected}
            getInternalData={getInternalData}
            ref={ref}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export { TeleExpertise }
