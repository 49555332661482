import {
  hasEspecialCarathers,
  hasLowercase,
  hasUppercase,
  hasNumber,
  hasEmplySpaces,
  isBiggerThan,
  areEqual,
  isEmpty,
  isInvalidCharacter,
} from './validator'

const ResetPasswordValidator = (data) => {
  const minSize = 8
  const info = { valid: 0, blankSpaces: false, matches: true }

  hasEspecialCarathers(data.password) ? (info.valid = info.valid + 20) : ''
  hasUppercase(data.password) ? (info.valid = info.valid + 20) : ''
  hasLowercase(data.password) ? (info.valid = info.valid + 20) : ''
  hasNumber(data.password) ? (info.valid = info.valid + 20) : ''
  isBiggerThan(data.password, minSize) ? (info.valid = info.valid + 20) : ''
  info.blankSpaces = hasEmplySpaces(data.password)
  info.invalidCharacter = isInvalidCharacter(data.password)
  if (data.password && data.password_confirmation) {
    info.matches = areEqual(data.password, data.password_confirmation)
  }
  info.confirmationEmply = isEmpty(data.password_confirmation)

  return info
}

export default ResetPasswordValidator
