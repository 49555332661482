import { makeStyles } from '@mui/styles'
import colors from '../utils/colors'

const useStyles = makeStyles(() => ({
  indicator: {
    color: colors.disabled,
    '&.good': {
      color: colors.success,
    },
    '&.warning': {
      color: colors.warning,
    },
    '&.danger': {
      color: colors.danger,
    },
  },
  kforce: {
    color: colors.disabledLighten2,
    '&.good': {
      color: colors.success,
    },
    '&.ok': {
      color: colors.success,
    },
    '&.warning': {
      color: colors.warning,
    },
    '&.care': {
      color: colors.care,
    },
    '&.danger': {
      color: colors.danger,
    },
  },
}))

export default useStyles
