import {
  Box,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Slider,
} from '@mui/material'
import I18n from 'i18n-js'
import Icons from '../../utils/icons'
import useStyles from './style'
const CreateUserPassword = ({
  showpassword,
  setShowpassword,
  showConfirm,
  setShowConfirm,
  change,
  requirements,
  errors,
}) => {
  const marks = [
    { value: 0, label: I18n.t('auth.textSliderPasswordWeak') },
    { value: 100, label: I18n.t('auth.textSliderPasswordStrong') },
  ]
  const classes = useStyles()
  return (
    <div className={classes.resetContainer}>
      <div className={classes.formContent}>
        <Box className={classes.form}>
          <OutlinedInput
            id="password"
            placeholder={I18n.t('form.fieldPassword')}
            type={showpassword ? 'text' : 'password'}
            color="secondary"
            onChange={change}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowpassword(!showpassword)
                  }}
                 
                >
                  {showpassword ? Icons('show') : Icons('hidden')}
                </IconButton>
              </InputAdornment>
            }
            className={classes.inputText}
          />
          <OutlinedInput
            id="password_confirmation"
            placeholder={I18n.t('form.fieldConfirmPassword')}
            type={showConfirm ? 'text' : 'password'}
            onChange={change}
            color="secondary"
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowConfirm(!showConfirm)
                  }}
                >
                  {showConfirm ? Icons('show') : Icons('hidden')}
                </IconButton>
              </InputAdornment>
            }
            className={classes.inputText}
          />
        </Box>
        <div className={classes.errorContainer}>
          {(errors.blankSpaces ||
            errors.invalidCharacter ||
            (requirements > 0 && requirements < 100)) && (
            <Typography variant="caption" align="center" color='error'>
              {I18n.t('form.fieldValidationSecuritypasswords')}
            </Typography>
          )}
          {!errors.matches && (
            <Typography variant="caption" align="center" color='error'>
              {I18n.t('form.fieldValidationPasswords')}
            </Typography>
          )}
        </div>
        <div className={classes.information}>
          <div className={classes.textContainerTitle}>
            <Typography variant="body1" color="textSecondary">
              {I18n.t('auth.textSecurityPoliciesTitle')}
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary">
              {I18n.t('auth.textSecurityPoliciesRule1')}
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary">
              {I18n.t('auth.textSecurityPoliciesRule2')}
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography variant="body2" color="textSecondary">
              {I18n.t('auth.textSecurityPoliciesRule3')}
            </Typography>
          </div>
        </div>
        <Box className={classes.sliderContainer}>
          <Slider value={requirements} marks={marks} disabled size="small"/>
        </Box>
      </div>
    </div>
  )
}

export default CreateUserPassword
