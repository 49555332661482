import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    borderRadius: '4px',
    display: 'flex',
    padding: '16px 16px 16px 0',
    boxSizing: 'border-box',
    columnGap: '24px',
    justifyContent: 'space-between',
  },
  sections: {
    '&:first-child': {
      flex: 0.06,
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '4px',
    },
    '&#instructions': { flex: 1, display: 'flex', columnGap: '56px' },
    '&#type': { width: '15%' },
  },
  dragIcon: {
    marginTop: '4px',
    marginRight: '4px',
  },
})

export default useStyles
