import { colors } from "../../../../../utils"

const useGraphicWithPreviewStyles = () => {
    return {
        container: {
            position: 'relative'
        },
        image: {
            objectFit: 'contain',
            width: '100%',
        },
        contend: {
            position: 'relative',
        },
        expand: {
            position: 'absolute',
            bottom: '10px',
            right: '10px'
        },
        arrow: {
            '&.MuiIconButton-root': {
                position: 'absolute',
                right: 10,
                top: 20,
                width: 20,
                height: 20,
            },
        },
        title: {
            '&.MuiTypography-root': {
                fontWeight: 500,
                margin: '10px 0',
                color: colors.disabled,
                borderBottom: `1px solid ${colors.greyDarken}`,
            },
        },
        detail: {
            position: 'absolute',
            right: 10,
            top: 0,
        },
        header: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        name: {
            color: colors.disabled,
            display: 'block',
            width: '100%',
            wordBreak: 'break-word',
        },
        headerTitle: {
            fontWeight: 500,
        },
        containerSummary: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
        },
        summary: {
            '&.MuiAccordionSummary-root:hover': {
                cursor: 'default'
            }
        }
    }
}

export { useGraphicWithPreviewStyles }
