import { isEmpty } from './validator'

export const stepOneValidator = (profile, locale) => {
  if (
    !isEmpty(profile[locale]?.name) &&
    !isEmpty(profile[locale]?.instructions)
  ) {
    return false
  }
  return true
}
export const stepTwoValidator = (questions) => {
  if (questions) {
    if (Object.values(questions).length > 0) {
      if (
        Object.values(questions).find((question) => question.completed == false)
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    return true
  }
}
export const QuestionValidator = (questions) => {
  if (questions.options) {
    if (Object.values(questions.options).length > 0) {
      if (Object.values(questions.options).find((option) => option.completed == false)) 
      {
        return false
      } else {
        if (!isEmpty(questions.title) && !isEmpty(questions.type)) {
          if (questions.type != 4 && questions.type != 5) {
            if (Object.values(questions.options).length > 1) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        } else {
          return false
        }
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

export const OptionQuestionValidator = (option) => {
  if (!isEmpty(option.title)) {
    return true
  } else {
    return false
  }
}
