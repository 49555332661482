import { makeStyles } from '@mui/styles'
import { colors } from '../../../../../utils'

const useStyles = () => {
  return {
    container: {
      width: '100%',
    },
    containerDocuments: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      // paddingRight: '40px',
    },
    items: {
      cursor: 'pointer',
      position: 'relative',
      // flex: 1,
      width: 'calc(20% - 10px)',
      // height: '150px',
      display: 'flex',
      flexWrap: 'wrap',
      // alignItems: 'space-between',
      borderRadius: '2px',
      overflow: 'hidden',
      boxShadow: `0px 0px 3px 1px ${colors.grayMedium}`,
      '&:hover': {
        boxShadow: `0px 0px 3px 2px ${colors.grayMedium}`,
        '& .MuiCheckbox-root': {
          borderRadius: '0px',
          boxShadow: `0px 0px 3px 2px ${colors.grayMedium}`,
        },
        '& .descriptionItem': {
          '&.MuiTypography-root': {
            textShadow: `1px 1px ${colors.black}`,
            color: colors.black
          }
        },
        '& .fileIcon': {
          '& .MuiSvgIcon-root': {
            color: colors.black,
          }
        },
      },
    },
    constainerHeader: {},
    title: {
      fontSize: '18px',
      fontWeight: '500',
      // marginBottom: '10px',
    },
    body: {
      padding: '10px 10px 10px 10px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'end',
      gap: '10px',
      height: '100px',
    },
    description: {
      width: '90px',
      fontWeight: '400',
      fontSize: '12px',
      color: colors.grayMedium,
      wordBreak: 'break-word',
      textTransform: 'uppercase'
    },
    audioContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    audioPlayer: {
      height: 20,
      background: colors.grayMedium,
      // marginTop: 20,
    },
    button: {
      display: 'none',
    },
    iconFolder: {
      color: colors.grayMedium,
      fontSize: '30px',
      alignSelf: 'start',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    footerButton: {
      background: colors.disabledDarken2,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px 0px',
      // alignSelf: 'end',
      // height: '100%',
      '& .MuiButton-root': {
        color: colors.white,
        '&:hover': {
          color: `${colors.white}ee`,
        },
        fontWeight: '400',
      },
    },
    img: {
      width: '20px',
      height: '20px',
    },
    previewImages: {
      cursor: 'pointer',
      height: '140px',
      position: 'relative',
      width: 'calc(20% - 10px)',
      '& img': {
        borderRadius: '2px',
        objectFit: 'cover',
        width: '100%',
        height: '140px',
      },
    },
    select: {
      fontSize: '12px',
      color: colors.blueInput,
      fontWeight: '500',
    },
    selectContainer: {
      display: 'flex',
      // gap: '10px'
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    check: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      fontSize: '25px',
      padding: '0px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em',
      },
    },
    unCheck: {
      '& .MuiSvgIcon-root': {
        // background: colors.white,
        padding: 0,
        fontSize: '1em',
        color: colors.white,
        boxShadow: '0px 0px 3px 0px #000',
      },
    },
    unCheckImg: {
      '& .MuiSvgIcon-root': {
        borderRadius: '2px',
        background: colors.white,
        boxShadow: '0px 0px 3px 0px #000',
        padding: 0,
        margin: '3px 3px 0 0',
        fontSize: '.75em',
        color: colors.white,
      },
    },
    selected: {
      outline: `3px solid ${colors.blueLighten}55`,
    },
    flex: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: '10px',
    },
    imagesContainer: {
      // paddingRight: '40px',
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: '20px',
      paddingBottom: '20px',
      gap: '10px',
    },
    end: {
      justifyContent: 'space-between',
    },
    arrow: {
      margin: '0px 20px 0px 4px',
    },
    show: {
      color: colors.blueLighten,
    },
    accordionStyles: {
      boxShadow:
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      minHeight: '48px',
      paddingLeft: '10px',
    },
    accordionStylesItems: {
      paddingLeft: '30px',
    },
    accordionDetail: {
      padding: '0px',
    },
    removeAccordionStyles: {
      '& .MuiCheckBox-root': {
        color: colors.blueLighten,
      },
      '&:first-of-type': {
        borderTopLeftRadius: '0px',
        bordeTopRightRadius: '0px',
      },
      '&:last-of-type': {
        borderBottomLeftRadius: '0px',
        bordeBottomRightRadius: '0px',
      },
      background: 'transparent',
    },
  }
}

const classNameStyles = makeStyles({
  audioPlayer: {
    height: 20,
    margin: '4px 4px',
    // '&::-webkit-media-controls-seek-back-button': {
    //   // background: colors.grayMedium,
    //   color: colors.white,
    // },
    '&::-webkit-media-controls-panel': {
      background: colors.grayMedium,
      color: colors.white,
    },
    '&::-webkit-media-controls-mute-button': {
      display: 'none',
    },
    // marginTop: 20,
  },
})
export { useStyles, classNameStyles }
