import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '60px',
    '&.MuiSvgIcon-root':{
      marginTop: '9px'
    }
  },
  label: { color: colors.disabled },
  inputName: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  inputValue: { width: '20%' },
  inputNumberValue: { width: '29%' },
  action: {
    color: colors.danger,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '60px',
    alignItems: 'flex-start'
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '68%',
  }
}))

export default useStyles
