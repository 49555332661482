import { Autocomplete, Chip, Paper, TextField } from '@mui/material'
import I18n from 'i18n-js'
import { useState, useEffect } from 'react'

const styles = {
  caption: {
    width: '100%',
    wordBreak: 'break-all',
  },
  chip: {
    width: '100%',
    height: 'fit-content',
    wordBreak: 'break-all',
    background: '#00000011',
    '& .MuiChip-label': {
      textOverflow: 'clip',
      whiteSpace: 'break-spaces',
    },
  },
  chipUnFocus: {
    background: '#00000011',
  },
  input: {
    '& .MuiOutlinedInput-root': {
      maxHeight: '60px',
      borderRadius: '0px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    '& .MuiAutocomplete-tag': {
      maxWidth: 'calc(100%)',
    },
  },
}
const MultiSelect = (props) => {
  const [value, setValue] = useState([])
  const [isFocus, setIsFocus] = useState(false)
  const handleChange = (_, value) => {
    setValue(value)
    props.onChange({
      target: {
        name: props.name,
        value: value,
      },
    })
  }
  const filterItems = () => {
    let newList = []

    props.items?.forEach((item) => {
      if (!value.find((itemValue) => itemValue.value === item.value)) {
        newList = [...newList, item]
      }
    })

    return newList
  }

  useEffect(() => {
    setValue(props.selected ?? [])
  }, [props.selected])
  return (
    <Autocomplete
      sx={{ ...props.sx, ...(!isFocus ? styles.input : {}) }}
      noOptionsText={I18n.t('general.noOptions')}
      multiple
      options={filterItems() ?? []}
      getOptionLabel={(option) => option.label ?? ''}
      value={value}
      limitTags={1}
      PaperComponent={(props) => {
        return <Paper {...props} sx={{ margin: '7px 0' }} />
      }}
      disableCloseOnSelect
      autoComplete={false}
      filterSelectedOptions
      onFocus={() => {
        setIsFocus(true)
      }}
      onBlur={() => {
        setIsFocus(false)
      }}
      onChange={handleChange}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            key={index}
            sx={isFocus ? styles.chip : styles.chipUnFocus}
            variant="outlined"
            label={option.label}
            {...getTagProps({ index })}
          />
        ))
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // size='small'
          name={props.name}
          label={props.label}
          helperText={props?.errors?.[props?.name]}
          error={props?.errors?.[props?.name]}
        />
      )}
    />
  )
}

export default MultiSelect
