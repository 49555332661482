import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Snackbar,
  CircularProgress,
  Hidden,
  Switch,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import I18n from 'i18n-js'
import {
  SearchBar,
  CrudsBtn,
  LanguageSelector,
  AlertMenu,
} from '../../components'
import questionaries from '../../services/questionaries'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import {
  Icons,
  languages,
  actionKeys,
  pagesKeys,
  rowsPerPage,
} from '../../utils'
import useStyles from '../crudsStyles'
import questionariesStyles from './style'

const putInfoInTableCells = (classesQuestionarie, info) => {
  return (
    <Box className={classesQuestionarie.doubleCell}>
      <Box className={classesQuestionarie.firstTd}>
        <Typography variant="body2" color="textPrimary">
          {info?.name}
        </Typography>
        <Hidden mdDown>
          <Typography variant="caption" color="textSecondary" paragraph>
            {info?.instructions?.length > 100
              ? info?.instructions.substring(0, 100) + '...'
              : info?.instructions}
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography variant="caption" color="textSecondary" paragraph>
            {info?.instructions?.length > 30
              ? info?.instructions.substring(0, 30) + '...'
              : info?.instructions}
          </Typography>
        </Hidden>
      </Box>
    </Box>
  )
}

const Questionaries = () => {
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  //permissions
  const pageId = pagesKeys.questionaries
  //list
  const [questionariesList, setQuestionariesList] = useState([])
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [languageColOne, setLanguageColOne] = useState(languages[0].locale)
  const [languageColTwo, setLenguageColTwo] = useState(languages[1].locale)

  //delete
  const [anchor, setAnchor] = useState(null)
  const [deleteItem, setDeleteItem] = useState()
  const [showAlert, setShowAlert] = useState(false)
  const [isdelete, setIsdelete] = useState(false)

  //list
  const getQuestionariesList = async () => {
    const res = await questionaries.list()
    const sorrtedResponse = res.data?.questionnaires.sort((a, b) => {
      return a.id - b.id
    })
    setQuestionariesList(sorrtedResponse || [])
    setItems(sorrtedResponse || [])
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  useEffect(() => {
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getQuestionariesList()
    }
  }, [])
  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }
  const searchItems = () => {
    if (search.trim() != '') {
      const filter = questionariesList?.filter((e) => {
        if (e.translations.length > 0) {
          if (
            e.translations
              .find((lang) => lang.locale == languageColOne)
              ?.name?.toLowerCase()
              .includes(search.toLowerCase()) ||
            e.translations
              .find((lang) => lang.locale == languageColTwo)
              ?.name?.toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return e
          }
        }
      })
      filter != ''
        ? setItems(filter)
        : setItems([{ error: I18n.t('general.notFound') }])
    } else {
      setItems(questionariesList)
    }
    setPage(0)
  }
  useEffect(searchItems, [search])
  const handleChangeLenguajeOne = (e) => {
    setLanguageColOne(e.target.value)
  }
  const handleChangeLenguajeTwo = (e) => {
    setLenguageColTwo(e.target.value)
  }
  //create
  const CreateItem = () => {
    history.push('/dashboard/questionaries/create')
  }
  //change status
  const handleChangeStatus = async (item) => {
    const { id, status } = item
    let body = {}
    if (status === 'Actif') {
      body = {
        id: id,
        status: 0,
      }
    } else {
      body = {
        id: id,
        status: 1,
      }
    }
    await questionaries.changeStatus(body)
    setAnchor(null)
    getQuestionariesList()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
  }
  //delete
  const handleDeleteClick = (e) => {
    setIsdelete(true)
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
    setIsdelete(false)
  }
  const handleDeleteItem = async () => {
    await questionaries.destroy(deleteItem)
    setAnchor(null)
    getQuestionariesList()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
  }

  const classes = useStyles()
  const classesQuestionarie = questionariesStyles()
  return (
    <Box p={4} m={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.questionaries')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('breadcrumb.questionaries')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar
                  change={handleChangeSearchbar}
                  search={searchItems}
                />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.questionaries.list.button')}
              size="medium"
              click={CreateItem}
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} search={searchItems} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguajeOne}
                        language={languageColOne}
                        languages={languages}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      className={classesQuestionarie.tableCell}
                    >
                      <LanguageSelector
                        change={handleChangeLenguajeTwo}
                        language={languageColTwo}
                        languages={languages}
                      />
                    </TableCell>
                    <TableCell className={classesQuestionarie.statusHeader}>
                      <Typography variant="body1" color="textPrimary">
                        {I18n.t('components.status')}
                      </Typography>
                    </TableCell>
                    {(canAccess(actionKeys.edit, pageId, state) ||
                      canAccess(actionKeys.delete, pageId, state)) && (
                      <TableCell align="center">
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('general.actions')}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {items != '' && (
                  <TableBody>
                    {(rowsPerPage.questionaries > 0
                      ? items?.slice(
                          page * rowsPerPage.questionaries,
                          page * rowsPerPage.questionaries +
                            rowsPerPage.questionaries
                        )
                      : items
                    )?.map((item) => {
                      const { translations } = item
                      if (item.error || !item.translations) {
                        return (
                          <TableRow className={classes.tableRow} key={item.id}>
                            <TableCell align="left" colSpan={4}>
                              <Typography variant="h6" color="primary">
                                {item.error}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        const infoLangOne = translations.find(
                          (lang) => lang.locale == languageColOne
                        )
                        const infoLangTwo = translations.find(
                          (lang) => lang.locale == languageColTwo
                        )
                        return (
                          !item.error && (
                            <TableRow
                              className={classes.tableRow}
                              key={item.id}
                            >
                              <TableCell
                                className={classesQuestionarie.tableCell}
                              >
                                {putInfoInTableCells(
                                  classesQuestionarie,
                                  infoLangOne,
                                  item
                                )}
                              </TableCell>
                              <TableCell
                                className={classesQuestionarie.tableCell}
                              >
                                {putInfoInTableCells(
                                  classesQuestionarie,
                                  infoLangTwo,
                                  item
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classesQuestionarie.statusHeader}
                              >
                                <Switch
                                  color="primary"
                                  checked={
                                    item.status === 'Actif' ? true : false
                                  }
                                  onClick={() => {
                                    canAccess(actionKeys.edit, pageId, state)
                                      ? handleChangeStatus(item)
                                      : ''
                                  }}
                                  size="small"
                                />
                              </TableCell>
                              {(canAccess(actionKeys.edit, pageId, state) ||
                                canAccess(
                                  actionKeys.delete,
                                  pageId,
                                  state
                                )) && (
                                <TableCell
                                  align="right"
                                  className={classes.tableCellActions}
                                >
                                  <div className={classes.divactions}>
                                    {canAccess(
                                      actionKeys.edit,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          history.push(
                                            `/dashboard/questionaries/edit/${item.id}`
                                          )
                                        }}
                                      >
                                        {Icons('edit')}
                                      </IconButton>
                                    )}

                                    {canAccess(
                                      actionKeys.delete,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        className={classes.delete}
                                        onClick={(e) => {
                                          handleDeleteClick(e)
                                          setDeleteItem(item.id)
                                        }}
                                      >
                                        {Icons('delete')}
                                      </IconButton>
                                    )}
                                  </div>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )
                      }
                    })}
                    <tr>
                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage.questionaries]}
                        count={items?.length}
                        page={page}
                        rowsPerPage={rowsPerPage.questionaries}
                        onPageChange={handleChangePage}
                        className={classes.pagination}
                      />
                    </tr>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={() => {
          setShowAlert(false)
        }}
        className={classes.alert}
      >
        <Alert severity="success">
          {isdelete
            ? I18n.t('components.alertDelete')
            : I18n.t('components.alertEdit')}
        </Alert>
      </Snackbar>

      <AlertMenu
        anchor={anchor}
        close={handleDeleteClose}
        action={handleDeleteItem}
      />
      {items == '' && (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      )}
    </Box>
  )
}

export default Questionaries
