import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'
const useStyles = makeStyles((theme) => ({
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  header: {
    display: 'flex',
    [theme.breakpoints.down('md')]: { flexWrap: 'wrap' },
  },
  title: { margin: '24px 0', width: '50%' },
  stepper: {
    width: '50%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: { width: '100%', marginBottom: '16px' },
  },
  root: {
    minHeight: '40vh',
  },
  body: {
    margin: '16px 0',
  },
  footer: {
    display: 'flex',
    width: '100%',
  },
  justifyBetween: { justifyContent: 'space-between' },
  justifycenter: { justifyContent: 'center' },
  actions: {
    // marginTop: '24px',
    display: 'inline-block',
    ['& #back']: {
      marginRight: '16px',
    },
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '150px',
    alignItems: 'center',
    width: '40%',
    margin: '0 auto',
    padding: '32px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  chekCircle: {
    color: colors.lightPrimary,
    '&.MuiSvgIcon-root': {
      fontSize: '50px',
    },
  },
}))

export default useStyles
