import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles((theme) => ({
  resetContainer: {
    width: 'calc(100% - 60px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: colors.white,
    borderRadius: '5px',
    padding: '30px',
  },
  formContent: {
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .MuiSvgIcon-root': {
      color: colors.blueInput,
    },
  },
  inputText: {
    background: 'white',
    borderRadius: '2px',
    // height: '35px',
    width: '48.5%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: colors.lightPrimary,
      },
    },
    ['& input']: {
      // height: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  sliderContainer: {
    margin: '0 auto',
    width: '92.3%',
  },

  submitContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'start',
    justifySelf: '',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: colors.danger,
  },
  information: {
    margin: '8px 0',
  },
}))

export default useStyles
