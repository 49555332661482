import { Box, Typography } from '@mui/material'
import styles from './styles'
import PackMassageCards from './packMassageCards'
import SimpleMassageCards from './simpleMassageCards'
import I18n from 'i18n-js'

const MessageList = ({
  fav,
  list,
  check = false,
  radio = false,
  value = '',
  orderData,
  handleChange = () => {},
  searchItems,
}) => {
  return (
    <Box sx={styles.message.containerMEssage}>
      {check &&
        (list?.length > 0 ? (
          list?.map((item) => (
            <SimpleMassageCards
              fav={fav}
              key={item.id}
              item={item}
              handleChange={handleChange}
              value={value}
              orderData={orderData}
              searchItems={searchItems}
            />
          ))
        ) : (
          <Typography>
            {I18n.t('patient.detail.tabTip.tapTwo.noAdviceFound')}
          </Typography>
        ))}
      {radio &&
        (list?.length > 0 ? (
          list?.map((item) => (
            <PackMassageCards
              fav={fav}
              key={item.id}
              item={item}
              value={value}
              onChange={handleChange}
              orderData={orderData}
              searchItems={searchItems}
            />
          ))
        ) : (
          <Typography sx={{ marginTop: '16px' }}>
            {I18n.t('patient.detail.tabTip.tapTwo.noAdviceFound')}
          </Typography>
        ))}
    </Box>
  )
}

export default MessageList
