import { useEffect, useState } from 'react'
import { GeneratePassword } from '../../context/auth'
import ForgotPasswordValidator from '../../validators/forgotPasswordValidator'
import FormForgotPassword from '../../components/formForgotPassword'
import { AuthLayout } from '../../components/authLayout'

const ForgotPassword = () => {
  const [isDisabled, setDisabled] = useState(true)
  const [dataSend, setDataSend] = useState(false)
  const [errMsj, setErrMsj] = useState(false)
  const [message, setMessage] = useState({})
  const [email, setEmail] = useState()
  const handleChange = (e) => {
    setEmail(e.target.value)
  }
  useEffect(() => {
    const leyend = ForgotPasswordValidator(email)
    if (!leyend.email && !leyend.isemail) {
      setDisabled(false)
      setMessage(leyend)
    } else {
      setDisabled(true)
      setMessage(leyend)
    }
  }, [email])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setDisabled(true)
    const res = await GeneratePassword(email)
    if (res.data) {
      setErrMsj(false)
      setDisabled(false)
      setDataSend(true)
    } else {
      setDisabled(false)
      setErrMsj(true)
    }
  }
  return (
    <AuthLayout>
      <FormForgotPassword
        change={handleChange}
        submit={handleSubmit}
        isActive={isDisabled}
        dataSend={dataSend}
        message={message}
        errMsj={errMsj}
      />
    </AuthLayout>
  )
}

export default ForgotPassword
