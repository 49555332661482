import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'
const useStyles = makeStyles((theme) => ({
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  bodyContainer: {
    marginTop: '32px',
  },
  titleBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  textBreak: {
    '&.MuiTypography-root': {
      wordBreak: 'break-word',
    }
  },
  titleContainer: {
    width: '29.5%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '8px',
    },
  },
  stepperContainer: {
    width: '70%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  inputsContainer: {
    margin: '24px 0',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
  },
  actionsContainerEnd: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
  },

  buttonContained: {
    marginRight: '16px',
    borderRadius: '0 2px 2px 0',
    fontSize: '14px',
    padding: '7px 30px',
    textTransform: 'none',
  },
  buttonText: {
    fontSize: '14px',
    textTransform: 'none',
  },
  loading: {
    position: 'absolute',
    top: '50vh',
    left: '50vw',
  },
  loadingBg: {
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0,0,0,.1)',
    width: '100vw',
    minHeight: '100vh',
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '150px',
    alignItems: 'center',
    width: '40%',
    margin: '0 auto',
    padding: '32px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  chekCircle: {
    color: colors.lightPrimary,
    '&.MuiSvgIcon-root': {
      fontSize: '50px',
    }
  },

  body: {
    margin: '32px 0',
  },
  footer: {
    display: 'flex',
  },
  center: { justifyContent: 'center' },
  between: { justifyContent: 'space-between' },

  button: {
    ['&:last-child']: { marginLeft: '8px' },
  },
  endButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
}))

export default useStyles
