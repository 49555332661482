import { Box } from '@mui/material'
import I18n from 'i18n-js'
import React from 'react'
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { AlertModal, CrudsBtn } from '../../../../components'
import { existBlock, orderValue, reorder } from './methods'
import useStyles from './style'
import Drag from './drag'
import { blockValidator } from '../../../../validators/smartTestValidator'
import { languages } from '../../../../utils'
import { smartTests } from '../../../../services'

const DaD = ({
  values,
  setValues,
  blockType,
  questionariesList,
  smartTestList,
  getQuestonaries,
  getSmartTests,
  locale,
}) => {
  const classes = useStyles()
  const [blocks, setBlocks] = useState(existBlock(values[locale]?.blocks))
  const [numberofBlocks, setNumberofBlocks] = useState(
    orderValue(values[locale]?.blocks)
  )
  const [openModal, setOpenModal] = useState(false)
  const [order, setOrder] = useState()
  const translations = languages.filter((language) => language.locale != locale)

  const handleChange = (target, id, order) => {
    const { name, value } = target
    let newBlock = [...blocks]
    let completed = false
    let newValues = {}
    let indexValue = 0

    newBlock[order] = {
      ...newBlock[order],
      [name]: value,
    }

    const validator = blockValidator(newBlock[order])

    if (Object.values(validator).length > 0) {
      completed = false
    } else {
      completed = true
    }

    newBlock[order] = {
      ...newBlock[order],
      completed,
    }

    Object.values(values[locale]?.blocks)?.forEach((item, index) => {
      if (item.order === id) {
        indexValue = index

        newValues = {
          [index]: {
            ...item,
            [name]: value,
            completed,
          },
        }
      }
    })

    if (name === 'indicator') {
      if (value === true) {
        newBlock[order] = {
          ...newBlock[order],
          data_source_id: undefined,
        }

        newValues[indexValue] = {
          ...newValues[indexValue],
          data_source_id: undefined,
        }
      } else {
        delete newBlock[order].data_source_id
        delete newValues[indexValue].data_source_id
      }
    }

    if (name === 'block_type') {
      switch (value) {
        case 3:
          delete newValues[indexValue].smartTest_id
          delete newBlock[order].smartTest_id
          break
        case 4:
          delete newValues[indexValue].questionarie_id
          delete newBlock[order].questionarie_id
          // delete newValues[indexValue].indicator
          // delete newBlock[order].indicator
          break
        default:
          delete newValues[indexValue].questionarie_id
          delete newValues[indexValue].smartTest_id
          // delete newValues[indexValue].indicator
          delete newBlock[order].questionarie_id
          delete newBlock[order].smartTest_id
          // delete newBlock[order].indicator
      }
    }

    if(newBlock[order]?.required === undefined) {
      newBlock[order] = {
        ...newBlock[order],
        required: false
      }

      newValues[indexValue] = {
        ...newValues[indexValue],
        required: false
      }
    }

    setBlocks([...newBlock])

    setValues({
      ...values,
      [locale]: {
        ...values[locale],
        blocks: {
          ...values[locale].blocks,
          ...newValues,
        },
      },
    })
  }

  const handleCreateBlock = () => {
    setBlocks([...blocks, { order: `${numberofBlocks}`, completed: false }])

    const length = blocks.length

    for (let i = 0; i <= length; i++) {
      setValues({
        ...values,
        [locale]: {
          ...values[locale],
          blocks: values[locale].blocks
            ? {
                ...values[locale].blocks,
                [i]: { order: `${numberofBlocks}`, completed: false },
              }
            : {
                [i]: { order: `${numberofBlocks}`, completed: false },
              },
        },
      })
    }

    setNumberofBlocks(parseInt(numberofBlocks) + 1)
  }

  const handleDeleteBlocks = (order) => {
    setOrder(order)
    setOpenModal(true)
  }

  const handleDelete = async () => {
    let newValue = []
    let newBlocks = {}
    let objectExist = Object.values(values?.[locale]?.blocks)?.find(
      (item) => item?.id && item.order === order
    )

    let inDBExist = true

    Object.values(values[locale]?.blocks)?.forEach((item) => {
      if (item.order !== order) {
        newValue.push(item)
      }
    })

    newValue.forEach((item, index) => {
      newBlocks = {
        ...newBlocks,
        [index]: item,
      }
    })

    if (values.id && objectExist !== undefined) {
      inDBExist = false
      const res = await smartTests.deleteBlock(objectExist.id)

      if (res !== null) inDBExist = true
    }

    if (values?.traductions?.[translations[0]?.locale]?.blocks) {
      let newTraductions = []
      let newBTraductions = {}

      Object.values(
        values?.traductions?.[translations[0]?.locale]?.blocks
      )?.forEach((item) => {
        if (item.order !== order) {
          newTraductions.push(item)
        }
      })

      newTraductions.forEach((item, index) => {
        newBTraductions = {
          ...newBTraductions,
          [index]: item,
        }
      })

      if (inDBExist === true) {
        setBlocks(blocks.filter((item) => item.order !== order))

        setValues({
          ...values,
          [locale]: {
            ...values[locale],
            blocks: newBlocks,
          },
          traductions: {
            ...values.traductions,
            [translations[0]?.locale]: {
              ...values.traductions?.[translations[0]?.locale],
              blocks: newBTraductions,
            },
          },
        })
      }
    } else {
      if (inDBExist === true) {
        setBlocks(blocks.filter((item) => item.order !== order))

        setValues({
          ...values,
          [locale]: {
            ...values[locale],
            blocks: newBlocks,
          },
        })
      }
    }
  }

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result

          if (!destination) return
          if (source.index === destination.index) return

          const newBlock = reorder(blocks, source.index, destination.index)
          let newValue = {}

          setBlocks(newBlock)

          newBlock.forEach((item, index) => {
            newValue = {
              ...newValue,
              [index]: item,
            }
          })

          setValues({
            ...values,
            [locale]: {
              ...values[locale],
              blocks: newValue,
            },
          })
        }}
      >
        <Box className={classes.root}>
          <Droppable droppableId="dnd">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classes.sections}
              >
                {blocks?.map((block, index) => (
                  <Draggable
                    index={index}
                    key={block.order}
                    draggableId={block.order}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Drag
                          handleDelete={handleDeleteBlocks}
                          id={block.order}
                          order={index}
                          blockType={blockType}
                          onChange={handleChange}
                          data={block}
                          questionariesList={questionariesList}
                          smartTestList={smartTestList}
                          getQuestonaries={getQuestonaries}
                          getSmartTests={getSmartTests}
                          setOpenModal
                          type={values.type}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}

                {/* {deleteOldData()} */}

                {provided.placeholder}
              </Box>
            )}
          </Droppable>

          <Box className={classes.sections}>
            <CrudsBtn
              text={I18n.t('cruds.smartTest.create.newBlock')}
              click={handleCreateBlock}
            />
          </Box>
        </Box>
      </DragDropContext>

      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('components.warningAtention')}
        message={I18n.t('components.deleteMessage')}
        SuccessText={I18n.t('components.yes')}
        success={handleDelete}
      />
    </>
  )
}

export default DaD
