import { useEffect, useState, useContext, Fragment } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Typography, Box, Button, CircularProgress } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import { pagesKeys, actionKeys } from '../../../utils'
import { canAccess } from '../../../router/permissions'
import { smartTests } from '../../../services'
import { ProfileSettingsForm } from '../../../components'
import useStyles from './style'

const BilanesMedicalStaff = () => {
  const { state } = useContext(AuthContext)
  const { id } = useParams()
  const history = useHistory()
  const locale = state.locale
  const [activeStep, setActiveStep] = useState(0)
  const [createProfile, setCreateProfil] = useState({})
  const [apiResponse, setApiResponse] = useState({})
  const [title, setTitle] = useState('')
  
  const getApiResonse = async () => {
    const type_list = await smartTests.show(id)
    const list = await smartTests.listTypeStaff()

    setTitle(type_list?.data?.title)

    if (list.data !== null) {
      if (type_list?.data?.type_staff?.length > 0) {
        setCreateProfil({
          ...createProfile,
          indicators: type_list?.data?.type_staff.map((item) => item.id),
        })
      }

      setApiResponse(
        list.data.typeStaff.map((item) => ({
          ...item.translations.find((item) => item.locale === locale),
          id: item.id,
        }))
      )
    }
  }

  const handleSubmit = async () => {
    const body = {
      type_staff_ids: createProfile?.indicators
        ? createProfile?.indicators
        : [],
    }
    const res = await smartTests.createTypeStaff(id, body)

    if (res?.data !== null) setActiveStep(1)
  }

  useEffect(() => {
    if (!canAccess(actionKeys.create, pagesKeys.smartTest, state)) {
      history.push('/dashboard')
    } else {
      return getApiResonse()
    }
  }, [])

  const classes = useStyles()
  return (
    <Box p={4} m={4}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.smartTest')} /{' '}
          <Link to="/dashboard/smart-test/" className={classes.textBreadcrum}>
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
          {I18n.t('cruds.smartTest.associateStaffType.associate')}
        </Typography>
      </Box>
      {apiResponse.length > 0 ? (
        <Box className={classes.bodyContainer}>
          <Box className={classes.titleBarContainer}>
            <Box className={classes.titleContainer}>
              <Typography variant="h6" color="primary">
                {I18n.t('cruds.smartTest.associateStaffType.associate')}
              </Typography>
              <Typography variant="subtitle1" color="secondary">
                {I18n.t('cruds.smartTest.associateStaffType.smartTest')}: {title}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.inputsContainer}>
            <Box>
              {activeStep == 0 ? (
                <Box>
                  <ProfileSettingsForm
                    values={createProfile}
                    setCreateProfil={setCreateProfil}
                    dataIndicators={apiResponse}
                    helpMessage={I18n.t('cruds.smartTest.associateStaffType.help')}
                    text={false}
                  />
                </Box>
              ) : (
                <Box className={classes.finishContainer}>
                  <CheckCircle className={classes.chekCircle} />
                  <Typography variant="h5" color="primary">
                    {I18n.t('cruds.smartTest.associateStaffType.success')}
                  </Typography>
                  <Typography variant="body1" color="primary">
                    {I18n.t('cruds.smartTest.associateStaffType.ok')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            className={
              activeStep < 1
                ? classes.actionsContainer
                : classes.actionsContainerEnd
            }
          >
            {activeStep < 1 && (
              <Box>
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.buttonText}
                  component={Link}
                  to="/dashboard/smart-test/"
                >
                  {I18n.t('components.cancel')}
                </Button>
              </Box>
            )}
            <Box>
              {activeStep < 1 ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={handleSubmit}
                    // disabled={
                    //   createProfile?.indicators
                    //     ? createProfile?.indicators?.length <= 0
                    //     : true
                    // }
                  >
                    {I18n.t('components.next')}
                  </Button>
                </Fragment>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  component={Link}
                  to="/dashboard/smart-test"
                >
                  {I18n.t('cruds.smartTest.associateStaffType.returnList')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.loadingBg}>
          <CircularProgress color="secondary" className={classes.loading} />
        </Box>
      )}
    </Box>
  )
}

export default BilanesMedicalStaff
