import { GET } from '../utils/crud'

export const listStates = async () => {
  const res = await GET(`states`)
  return res
}

export const detailState = async (id) => {
  const res = await GET(`states/${id}`)
  return res
}

export const listCommunities = async () => {
  const res = await GET(`communities`)
  return res
}

export const detailCommunities = async (id) => {
    const res = await GET(`communities?state_id=${id}`)
    return res
}

export const detailCommunitiesId = async (id) => {
  const res = await GET(`communities/${id}`)
  return res
}

export const zipCodeCommunity = async (zip) => {
  const res = await GET(`communities?zip_code=${zip}`)
  return res
}