const mainValues = {
    birth: null,
    country_birth: null,
    height: null,
    lastname: null,
    name: null,
    phone: null,
    place_birth: null,
    right_hand: null,
    sex: null,
    weight: null,
}

const franceValues = {
    state_id: null,
    community_id: null,
}

const spainValues = {
    dni: null,
    nuss: null
}
export { mainValues, franceValues, spainValues }