import { Typography } from "@mui/material"
import I18n from "i18n-js"
import { useDetailCardStyles } from "./detailCardStyles"

const DetailCard = (condition, value, classs, graph) => {
  const classes = useDetailCardStyles()
  return (
    condition && (
      <>
        {
          !!value && (
            <>
              <Typography variant="body1" sx={{...classes.title}}>
                {I18n.t('patient.detail.tabSmartPredict.cardTab.description')}
              </Typography>
              <Typography
                variant="subtitle2"
                component="span"
                sx={classes.name}
              >
                {value}
              </Typography>
            </>
          )
        }
        {!!graph && (
          <>
            <Typography sx={{...classes.chartText, ...classs ?? {}}}>
              {I18n.t('general.evolutionChart')}
            </Typography>
            <img style={classes.graphic} src={graph} />
          </>
        )}
      </>
    )
  )
}

export { DetailCard }
