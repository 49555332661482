import { useContext, useEffect, useState, Fragment } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Fade,
  Button,
  CircularProgress,
} from '@mui/material'

import {
  AccountCircle,
  Phone,
  // MailOutline,
  CheckCircle,
} from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import users from '../../../services/users'
import { canAccess } from '../../../router/permissions'
import { pagesKeys, actionKeys } from '../../../utils'
import { CrudsBtn, FormEditUser } from '../../../components'
import { editUserValidator } from '../../../validators/createUserValidator'

import useStyles from './style'
import { handleDataUser } from '../../../hooks/editUser'

const EditUser = () => {
  const { state } = useContext(AuthContext)
  const { id } = useParams()
  const history = useHistory()
  const [userInfo, setUserInfo] = useState({})

  const [userRolId, setUserRolId] = useState()

  const [editUser, setEditUser] = useState()
  const [datasend, setDataSend] = useState(false)
  const [disabledEditData, setDisabledEditData] = useState(false)
  const [oldEmail, setOldEmail] = useState()
  const [loading, setLoading] = useState(true)

  const getData = async () => {
    setLoading(true)
    const res = await users.show(id)
    setUserInfo(res?.data.user || {})
    setEditUser(res?.data.user || {})
    setUserRolId(res.data?.user?.id_rol)
    setOldEmail(res?.data?.user?.email)
    setLoading(false)
  }
  
  const handleChangeUserData = async (e) => {
    if(e?.target?.name === 'email') {
      const res = await users.checkEmail(e.target.value)
      setEditUser({...editUser, [e.target.name]: e.target.value, existEmail: res.data === null})
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
    } else {
      if (e.target) {
        setEditUser({ ...editUser, [e.target.name]: e.target.value })
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value })
      } else {
        setEditUser({ ...editUser, phone: e })
        setUserInfo({ ...userInfo, phone: e })
      }
    }
  }
  
  const handleSubmitUserdata = async (e) => {
    e.preventDefault()
    setLoading(true)
    setDisabledEditData(true)
   
    const body = {
      id: oldEmail,
      update: handleDataUser(editUser),
    }
    const res = await users.edit(body)
    
    if (res.data) {
      setDataSend(true)
    }
    setDisabledEditData(false)
    setLoading(false)
  }
  useEffect(() => {
    if (editUserValidator(editUser, userRolId, oldEmail)) {
      setDisabledEditData(true)
    } else {
      setDisabledEditData(false)
    }
  }, [editUser])
  useEffect(() => {
    if (!canAccess(actionKeys.edit, pagesKeys.users, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
  }, [])
  const classes = useStyles()
  return (
    <Box mx={6} mt={4}>
      {Object.values(userInfo).length > 0 && !loading ? (
        <Fade in={Object.values(userInfo).length > 0} timeout={500}>
          <div>
            <Box className={classes.indicatorContainer}>
              <Typography variant="body2" color="textSecondary">
                {I18n.t('breadcrumb.indicators')} /{' '}
                <Link to="/dashboard/users" className={classes.textBreadcrum}>
                  {I18n.t('breadcrumb.dashboard')} /{' '}
                </Link>
                {I18n.t('cruds.users.edit.indicator')}
              </Typography>
            </Box>
            <Box className={classes.headerContainer}>
              <Box className={classes.titleContainer}>
                <Box>
                  <Typography
                    variant="h6"
                    color="initial"
                    className={classes.headerTitle}
                  >
                    {I18n.t('cruds.users.edit.titlePage')}
                  </Typography>
                </Box>

                <Typography
                  variant="h5"
                  color="primary"
                  className={classes.headerTitle}
                >
                  {(!editUser || editUser?.name === '') &&
                    (!editUser || editUser?.lastname === '') &&
                    I18n.t('cruds.users.edit.indicator')}

                  {`${editUser?.name ? editUser.name : ''} ${
                    editUser?.lastname ? editUser.lastname : ''
                  }`}
                </Typography>
              </Box>
              <Box className={classes.informationContainer}>
                {userInfo?.phone && (
                  <Box className={classes.textHeaderContainer}>
                    <Phone
                      className={classes.iconHeaderText}
                      color="secondary"
                    />
                    <Typography variant="body1" color="textPrimary">
                      {userInfo?.phone}
                    </Typography>
                  </Box>
                )}
                {userInfo?.rol?.name && (
                  <Box className={classes.textHeaderContainer}>
                    <AccountCircle
                      className={classes.iconHeaderText}
                      color="secondary"
                    />
                    <Typography variant="body1" color="textPrimary">
                      {userInfo?.rol?.name}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box my={3}>
              {!datasend ? (
                <Box className={classes.bodyContainer}>
                  <div className={classes.editUserContainer}>
                    <Box className={classes.editUserHeader}>
                      <Typography variant="h6" color="primary">
                        {I18n.t('myProfile.editProfileTitle')}
                      </Typography>
                    </Box>
                    <Box className={classes.editUserBody}>
                      <FormEditUser
                        oldEmail={oldEmail}
                        user={userInfo}
                        userRolId={userRolId}
                        change={handleChangeUserData}
                        setUser={setEditUser}
                        setuserInfo={setUserInfo}
                        existEmail={editUser?.existEmail}
                      />
                    </Box>
                  </div>
                </Box>
              ) : (
                <Fade in={datasend} timeout={500}>
                  <Box>
                    <Box className={classes.finishContainer}>
                      <CheckCircle className={classes.chekCircle} />
                      <Typography variant="h5" color="primary">
                        {I18n.t('components.success')}
                      </Typography>
                      <Typography variant="body1" color="primary">
                        {I18n.t('cruds.users.edit.created')}
                      </Typography>
                    </Box>
                  </Box>
                </Fade>
              )}
              <Box
                className={
                  !datasend
                    ? classes.bodyContainerBtns
                    : classes.bodyContainerBtnsEnd
                }
              >
                {!datasend ? (
                  <Fragment>
                    <Box>
                      <Button
                        variant="text"
                        color="secondary"
                        component={Link}
                        to="/dashboard/users"
                        className={classes.buttonCancel}
                      >
                        {I18n.t('components.cancel')}
                      </Button>
                    </Box>
                    <Box className={classes.submit}>
                      <CrudsBtn
                        className='endButton'
                        text={I18n.t('components.update')}
                        click={handleSubmitUserdata}
                        disabled={disabledEditData}
                      />
                    </Box>
                  </Fragment>
                ) : (
                  <CrudsBtn
                    text={I18n.t('cruds.users.return')}
                    component={Link}
                    to="/dashboard/users"
                  />
                )}
              </Box>
            </Box>
          </div>
        </Fade>
      ) : (
        <CircularProgress color="secondary" className={classes.loading} />
      )}
    </Box>
  )
}

export default EditUser
