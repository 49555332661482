import { useState } from 'react'
import OptionQuestion from '../../optionQuestion'

const initialLengthofOptions = (values) => {
  if (values.options) {
    if (Object.values(values.options).length > 0) {
      return Object.values(values.options).length
    } else {
      return 1
    }
  } else {
    return 1
  }
}

const setInitialOptions = (numberOfOptions) => {
  const options = []
  for (let i = 0; i < numberOfOptions; i++) {
    options.push({
      name: 'cruds.questionaries.option',
      score: 'cruds.questionaries.score',
      id: i,
    })
  }
  return options
}

const sendInformation = (cardItems, index, id) => {
  if (cardItems.options) {
    const informationKey = Object.keys(cardItems.options)[index]
    const information = Object.values(cardItems.options)[index]
    delete cardItems.options[informationKey]
    if (information != undefined) {
      cardItems.options[id] = information
      return information
    } else {
      cardItems.options[id] = {}
      return {}
    }
  } else return {}
}

const Numeric = ({ cardItems, setCardItems }) => {
  const [numberOfOptions, setNumberOfOptions] = useState(
    initialLengthofOptions(cardItems)
  )
  const [options, setOptions] = useState(setInitialOptions(numberOfOptions))

  const handleCreateOption = () => {
    setOptions([
      ...options,

      {
        name: 'cruds.questionaries.option',
        score: 'cruds.questionaries.score',
        id: numberOfOptions ,
      },
    ])
    setNumberOfOptions(numberOfOptions + 1)
  }
  const handleRemoveOption = (id) => {
    const optionsRemoved = options.filter((option) => option.id != id)
    setOptions([...optionsRemoved])
  }

  return (
    <div>
      {options.map((option, index) => {
        return (
          <OptionQuestion
            name={option.name}
            score={option.score}
            id={option.id}
            size={numberOfOptions}
            create={handleCreateOption}
            remove={handleRemoveOption}
            cardItems={cardItems}
            values={sendInformation(cardItems, index, option.id)}
            setCardItems={setCardItems}
            type={cardItems.type}
            key={option.id}
          />
        )
      })}
    </div>
  )
}

export default Numeric
