import { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Typography, TextField, IconButton } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import I18n from 'i18n-js'
import { languages, Icons, colors } from '../../../../utils'
import { BlockViewver, BlockTranslate, InputFile } from '../../../../components'
import useStyles from './style'
import ImageLink from '../../../../components/imageLink'
import { editKeysImages, nameImages } from '../../list'
import SimpleError from '../../../../components/errors/simple'
import { isImage } from '../../../../validators/validator'
import { listImage } from './list'
const ItemsLenght = (items, blocks) => {
  if (blocks) {
    const itemsBlocks = []
    Object.values(blocks).map(() => {
      itemsBlocks.push({
        name: 'title',
        annotation: true,
      })
    })
    return items.concat(itemsBlocks)
  } else {
    return items
  }
}
const setInitialvalues = (values, items, translations, locale) => {
  let data = {}
  if (values?.[locale].blocks) {
    //name and instructions
    translations?.map((translation) => {
      items?.map((item) => {
        data[translation?.locale] = {
          ...data[translation?.locale],
          [item.name]:
            values.traductions?.[translation?.locale]?.[item.name] || '',
          locale: translation?.locale,
        }
      })
      //blocks

      const orderBlocks = Object.values(values?.[locale]?.blocks)?.sort(
        (a, b) => a.order - b.order
      )
      let traductionBlocks = []
      if (values?.traductions?.[translation?.locale]?.blocks) {
        traductionBlocks = Object.values(
          values?.traductions[translation?.locale].blocks
        ).sort((a, b) => a.order - b.order)
      }

      Object.values(orderBlocks)?.map((blocks, blocksID) => {
        data[translation?.locale] = {
          ...data[translation?.locale],
          blocks: {
            ...data[translation?.locale]?.blocks,
            [blocksID]: {
              instructions:
                traductionBlocks.length > 0
                  ? traductionBlocks?.[blocksID]?.instructions || ''
                  : '',

              order: blocks.order,
            },
          },
        }
      })
    })
  } else {
    translations?.map((translation) => {
      items?.map((item) => {
        data[translation?.locale] = {
          ...data?.[translation?.locale],
          [item.name]:
            values.traductions?.[translation?.locale]?.[item?.name] || '',
          locale: translation?.locale,
        }
      })
    })
  }

  return data
}
const LanguageForm = ({
  items,
  values,
  locale,
  setCreateData,
  detailImages,
  setDetailImages,
}) => {
  const [itemSelected, setItemSelected] = useState(0)
  const itemsLanguage = ItemsLenght(items, values[locale].blocks)
  const [blockSelected, setBlockSelected] = useState(-items.length)
  const [images, setImages] = useState()
  const [imagesTranslate, setImagesTranslate] = useState({})
  const translations = languages?.filter(
    (language) => language.locale != locale
  )
  const [error] = useState({
    typeImage: I18n.t('validators.image'),
  })
  const [data, setData] = useState(
    setInitialvalues(values, items, translations, locale)
  )
  const blocks = Object.values(
    Object.values(values?.[locale]?.blocks)?.sort(
      (a, b) => a.order - b.order
    ) || {}
  )
  // const

  const handleNextItem = () => {
    setItemSelected(itemSelected + 1)
    setBlockSelected(blockSelected + 1)
  }
  const handleBeforeItem = () => {
    setItemSelected(itemSelected - 1)
    setBlockSelected(blockSelected - 1)
  }
  const handleChangeData = (e) => {
    const { name, value } = e.target

    if (nameImages.includes(itemsLanguage?.[itemSelected]?.name)) {
      if (value) {
        setImagesTranslate({
          ...imagesTranslate,
          [name]: {
            ...detailImages?.[name],
            [itemsLanguage?.[itemSelected]?.name]: URL.createObjectURL(value),
          },
        })
        setData({
          ...data,
          [name]: {
            ...data?.[name],
            [itemsLanguage?.[itemSelected]?.name]: value,
            locale: name,
          },
        })
      }
    } else {
      setData({
        ...data,
        [name]: {
          ...data?.[name],
          [itemsLanguage?.[itemSelected]?.name]: value,
          locale: name,
        },
      })
    }
  }

  const handleErrors = (locale) => {
    const image =
      values.traductions?.[locale]?.[itemsLanguage?.[itemSelected]?.name]

    if (image && !image?.name) return false

    return !isImage(image?.type) && image ? true : false
  }

  const handleUpdateDataTranslate = useCallback(() => {
    setDetailImages({ ...detailImages, ...imagesTranslate })
    setCreateData({ ...values, traductions: data })
  }, [data, imagesTranslate])

  useEffect(() => {
    handleUpdateDataTranslate()
  }, [handleUpdateDataTranslate])
  const classes = useStyles()

  useEffect(() => {
    setImages(
      detailImages?.[editKeysImages[[itemsLanguage?.[itemSelected]?.name]]]
    )
  }, [itemSelected])

  const principalContent = () => {
    if (itemsLanguage[itemSelected].annotation) {
      return (
        <BlockViewver number={blockSelected} block={blocks[blockSelected]} />
      )
    }

    if (images) {
      return (
        <div className={classes.imageContainer}>
          <div className={classes.containerInputImage}>
            <Typography className={classes.imageLocaleText}>
              {I18n.t(itemsLanguage[itemSelected].label)}
            </Typography>
            <div className={classes.mt_8}>
              <ImageLink images={images} left big />
            </div>
          </div>
        </div>
      )
    }

    return (
      <TextField
        variant="outlined"
        multiline={itemsLanguage[itemSelected].multiline}
        rows={itemsLanguage[itemSelected].multiline ? 4 : 0}
        label={
          listImage.includes(itemsLanguage?.[itemSelected]?.name)
            ? I18n.t('cruds.profiles.create.noImage')
            : I18n.t('cruds.profiles.create.translateLabel')
        }
        value={
          (itemsLanguage[itemSelected].file
            ? values[locale]?.[itemsLanguage[itemSelected]?.name]?.name
            : values[locale]?.[itemsLanguage[itemSelected]?.name]) || ''
        }
        helperText={I18n.t(itemsLanguage[itemSelected].label)}
        disabled
        className={classes.info}
        fullWidth
        inputProps={{
          className: classes.input,
        }}
      />
    )
  }

  return (
    <Fragment>
      <Box className={classes.headerContainer}>
        <Typography variant="body1" color="initial">
          {I18n.t('cruds.profiles.create.translateTitle')}
        </Typography>
        <Box className={classes.selectInputContainer}>
          <Box className={classes.selectInput}>
            <IconButton
              size="small"
              disabled={itemSelected == 0}
              onClick={handleBeforeItem}
            >
              <NavigateBefore />
            </IconButton>
            <Typography variant="h6">
              {`${itemSelected + 1}/${itemsLanguage.length}`}
            </Typography>
            <IconButton
              size="small"
              disabled={itemSelected == itemsLanguage.length - 1}
              onClick={handleNextItem}
            >
              <NavigateNext />
            </IconButton>
          </Box>
          <Typography variant="caption" color="textSecondary">
            {I18n.t('cruds.profiles.create.textToTranslate')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.infoContainer}>
        {principalContent()}
      </Box>
      <Box>
        {data &&
          translations.map((translation, index) => {
            if (itemsLanguage[itemSelected].annotation) {
              return (
                <BlockTranslate
                  translation={translation}
                  annotation={blocks[blockSelected]}
                  data={data}
                  id={blockSelected}
                  setData={setData}
                  key={index}
                  sx={{
                    background: colors.greyLighten,
                    boxShadow: 'none',
                  }}
                />
              )
            } else {
              return (
                <Box key={index} className={classes.inputsContainer}>
                  <Box className={classes.labelContainer}>
                    {Icons(translation.locale)}
                    <Typography
                      className={classes.label}
                      variant="caption"
                      color="textPrimary"
                    >
                      {I18n.t(translation?.name)}
                    </Typography>
                  </Box>
                  <Box className={classes.inputContainer}>
                    {itemsLanguage?.[itemSelected]?.file ? (
                      <div className={classes.containerInputImage}>
                        <InputFile
                          name={translation.locale}
                          change={handleChangeData}
                          className={classes.form}
                          value={
                            data?.[translation.locale]?.[
                              itemsLanguage?.[itemSelected]?.name
                            ] || ''
                          }
                          format={'photo'}
                          error={handleErrors(translation.locale)}
                        />

                        {!handleErrors(translation.locale) &&
                          detailImages?.[translation.locale]?.[
                            itemsLanguage?.[itemSelected]?.name
                          ] && (
                            <div className={classes.mt_8}>
                              <ImageLink
                                images={
                                  detailImages?.[translation.locale]?.[
                                    itemsLanguage?.[itemSelected]?.name
                                  ]
                                }
                                left
                              />
                            </div>
                          )}
                        <Box className={classes.errorMessage}>
                          <SimpleError
                            error={handleErrors(translation.locale)}
                            message={error.typeImage}
                          />
                        </Box>
                      </div>
                    ) : (
                      <TextField
                        // size="small"
                        name={translation.locale}
                        multiline={itemsLanguage[itemSelected].multiline}
                        rows={itemsLanguage[itemSelected].multiline ? 4 : 0}
                        variant="outlined"
                        value={
                          data[translation.locale]?.[
                            itemsLanguage[itemSelected]?.name
                          ] || ''
                        }
                        onChange={handleChangeData}
                        color="secondary"
                        className={classes.form}
                      />
                    )}
                  </Box>
                </Box>
              )
            }
          })}
      </Box>
    </Fragment>
  )
}

export default LanguageForm
