import { colors, images } from '../../utils'

const useStyles = () => {
  return {
    container: {
      width: '100vw',
      height: '100vh',
      background: `url(${images.background})`,
      backgroundSize: '100vw 100vh',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    formContainer: {
      width: { xl: '430px', lg: '430px', md: '430px', sm: '60%', xs: '60%' },
      padding: '60px 0px 20px',
      background: colors.white,
      borderRadius: '10px',
      height: 'calc(100vh - 100px)',
      maxHeight: '520px',
    },
    colorText: {
      color: colors.lightPrimary,
      fontWright: '500',
    },
    title: {
      fontSize: '35px',
    },
    subtitle: {
      fontSize: '30px',
    },
    containerChildren: {
      // margin: '20px 0px',
    },
    body: {
      width: 'calc(100% - 80px)',
      height: 'calc(100% - 80px)',
      overflowY: 'auto',
      padding: '0 40px',
    },
    return: {
      width: 'fit-content',
    },
    containerLogo: (data) => {
      return {
        marginTop: '20px',
        display: 'flex',
        padding: '0 40px',
        justifyContent: 'end',
        alignItems: 'center',
        '& img': {
          cursor: !data?.cursor ? 'pointer' : data?.cursor,
          width: '130px',
          height: '55px',
        },
      }
    },
  }
}

export { useStyles }
