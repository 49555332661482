import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async (body) => {
  let res
  if (body) {
    var queryString = Object.keys(body)
      .map((key) => key + '=' + body[key])
      .join('&')
    res = await GET(`medical-staff?${queryString}`)
  } else {
    res = await GET('medical-staff')
  }
  return res?.data?.medicalStaff
}

const getOne = async (id) => {
  const res = await GET(`medical-staff/${id}`)
  return res.data.medicalStaff
}

const create = async (body) => {
  const res = await POST('medical-staff', body)
  return res
}

const update = async (id, body) => {
  const res = await PUT(`medical-staff/${id}`, body)
  return res
}

const addCenter = async (id, centerId) => {
  const res = await POST(`medical-staff/${id}/add-medical-centers`, {
    id_medical_centers: [centerId],
  })
  return res
}

const removeCenter = async (id, centers) => {
  const res = await POST(`medical-staff/${id}/remove-medical-centers`, {
    id_medical_centers: centers,
  })
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`medical-staff/${id}`)
  return res
}

const changeStatus = async (id, body) => {
  const res = await POST(`medical-staff/${id}/update-status`, body)
  return res
}

const verifyEmail = async (email) => {
  const res = await GET(`check-email/MedicalStaff/${email}`)
  return res
}

const doctors = async () => {
  const res = await GET('medical-staff/search/doctor')
  return res.data
}

const others = async () => {
  const res = await GET('medical-staff/search/others')
  return res.data
}

export default {
  list,
  getOne,
  create,
  update,
  destroy,
  changeStatus,
  addCenter,
  removeCenter,
  doctors,
  others,
  verifyEmail,
}
