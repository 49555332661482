import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('medical-center-types')
  return res
}

const create = async (body) => {
  const res = await POST('medical-center-types', body)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  const res = await PUT(`medical-center-types/${id}`, update)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`medical-center-types/${id}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
}
