import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  content: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '59.8vw',
    minHeight: '100vh',
  },
  img: {
    width: '100%',
    height: '100vh',
    objectFit: 'cover',
  },
})

export default useStyles
