import { isEmpty } from './validator'

export const stepOneValidator = (profile, locale) => {
  if (
    !isEmpty(profile[locale]?.name) &&
    !isEmpty(profile.provider) &&
    !isEmpty(profile[locale]?.description)
  ) {
    return false
  }
  return true
}
export const stepTwoValidator = (profile) => {
  if (profile.indicators?.length > 0) {
    return false
  }
  return true
}
