export const isFirefox = () => {
  var sUsrAg = navigator.userAgent

  if (sUsrAg.indexOf('Chrome') > -1) {
    return false
  } else if (sUsrAg.indexOf('Safari') > -1) {
    return false
  } else if (sUsrAg.indexOf('Opera') > -1) {
    return false
  } else if (sUsrAg.indexOf('Firefox') > -1) {
    return true
  } else if (sUsrAg.indexOf('MSIE') > -1) {
    return false
  }
}
