import { colors } from '../../utils'

const styles = {
  stylesTable: {
    border: 'none',

    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
    },
    '&.MuiDataGrid-root': {
      '& .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
      '& .cell:focus,.MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-cell': {
        background: colors.greyColumn,

        border: 'none',
      },
      '& .MuiDataGrid-row': {
        margin: '4px 0px',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
    },
  },
}

export default styles
