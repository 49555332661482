import { GET, POST, DELETE } from '../utils/crud'
import { medicalCenterFormData } from '../utils/formData'

const list = async () => {
  const res = await GET('medical-centers')
  return res
}
const show = async (id) => {
  const res = await GET(`medical-centers/${id}`)
  return res
}
const create = async (body) => {
  const formData = await medicalCenterFormData(body)
  
  if (body.persons) {
    if (body.persons.length > 0) {
      const res = await POST('medical-centers', formData, true, {'Accept': 'multipart/form-data'})
      if (res.data) {
        const data = { contact_medicals: body.persons }
        await POST(
          `medical-centers/${res.data.medicalCenter.id}/add-create-contact`,
          data
        )
      }
      return res
    } else {
      const res = await POST('medical-centers', formData, true, {'Accept': 'multipart/form-data'})
      return res
    }
  } else {
    const res = await POST('medical-centers', formData, true, {'Accept': 'multipart/form-data'})
    return res
  }
}

const edit = async (body) => {
  const { id, update, persons } = body

  const formData = await medicalCenterFormData(update)

  if (persons) {
    if (persons.length > 0) {
      const res = await POST(`medical-centers/${id}`, formData, true)
      if (res.data) {
        const data = { contact_medicals: persons }
        await POST(`medical-centers/${id}/add-update-contact`, data)
      }
      return res
    } else {
      const res = await POST(`medical-centers/${id}`, formData, true)
      return res
    }
  } else {
    const res = await POST(`medical-centers/${id}`, formData, true)
    return res
  }
}

const destroy = async (id) => {
  const res = await DELETE(`medical-centers/${id}`)
  return res
}
const changeStatus = async (id, status) => {
  const res = await POST(`medical-centers/${id}/update-status`, status)
  return res
}

const checkEmail = async (email) => {
  const res = await GET(`check-email/MedicalCenter/${email}`)
  return res
}

const medicalCenterDocument = async (id) => {
  const res = await GET(`legal-documents?medical_center_id=${id}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
  show,
  changeStatus,
  checkEmail,
  medicalCenterDocument,
}
