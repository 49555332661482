import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles({
  root: {
    borderRadius: '2px',
    maxWidth: '800px',
  },
  //body
  body: {
    display: 'flex',
    padding: '0 0 0 16px',
  },
  //avatar
  avatarContainer: {
    margin: '8px 0 8px 0',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    borderRadius: '50px',
    height: '40px',
    width: '40px',
    overflow: 'hidden',
    marginBottom: '8px',
  },
  success: {
    border: colors.primary + ' solid 2px',
  },
  warning: { border: colors.warning + ' solid 2px' },
  danger: { border: colors.danger + ' solid 2px' },
  disabled: { border: colors.disabled + ' solid 2px' },
  img: { width: '100%', objectFit: 'cover' },

  //icon animation
  iconRotate: {
    animation: `$rotatation 2s linear infinite`,
  },
  '@keyframes rotatation': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  //information
  information: {
    flex: 1,
    margin: '8px 0 8px 12px',
  },

  iconButtons: {
    marginTop: '2px',
    color: colors.green,
  },

  spaceIcons: {
    marginLeft: '4px',
  },

  //notifications
  notifications: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '120px',
    ['& svg']: {
      fontSize: '40px',
      color: colors.white,
    },
    ['& p']: {
      color: colors.white,
    },
  },
  notificationSuccess: { background: colors.primary },
  notificationWarning: { background: colors.warning },
  notificationDanger: { background: colors.danger },
  notificationDisabled: { background: colors.disabled },

  //footer
  footer: {
    display: 'flex',
    justifyContent: 'space-between',

    ['&.MuiCardContent-root:last-child']: {
      padding: '16px 24px',
    },
  },
  indicator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ['& span']: { color: colors.primary },
    ['& svg']: { color: colors.disabledLighten },
  },
})

export default useStyles
