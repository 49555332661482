import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  input: {
    background: colors.white,
    // maxHeight: '40px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  textArea: {
    background: colors.white,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
})

export default useStyles
