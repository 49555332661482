import { useEffect, useState, useContext, Fragment } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Typography, Box, Button, Fade, CircularProgress } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import { pagesKeys, actionKeys } from '../../../utils'
import { canAccess } from '../../../router/permissions'
import {
  StepperCreate,
  InformationGeneralForm,
  ProfileSettingsForm,
  LanguageSettingsForm,
  AlertModal,
} from '../../../components'
import {
  stepOneValidator,
  stepTwoValidator,
} from '../../../validators/createProfileValidator'
import { profiles, indicators, providers } from '../../../services'
import notAllHaveTraductions from '../../../hooks/traductions/notAllHaveTraductions'
import useStyles from './style'
// import { LoadingButton } from '@mui/lab'
const EditProfile = () => {
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const { id } = useParams()
  const locale = state.locale
  const [fieldsNeedTranslation] = useState([
    { name: 'name', multiline: false, label: 'cruds.profiles.create.name' },
    {
      name: 'description',
      multiline: true,
      label: 'cruds.profiles.create.description',
    },
  ])
  const [steps] = useState([
    {
      step: 'cruds.profiles.create.step.first',
      label: 'cruds.profiles.create.stepLabel.first',
    },
    {
      step: 'cruds.profiles.create.step.second',
      label: 'cruds.profiles.create.stepLabel.second',
    },
    {
      step: 'cruds.profiles.create.step.third',
      label: 'cruds.profiles.create.stepLabel.third',
    },
  ])
  const [loadingPage, setLoadingPage] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [createProfile, setCreateProfil] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [apiResponse, setApiResponse] = useState({})
  const [openModal, setOpenModal] = useState(false)

  const getApiResponse = async () => {
    const providersResponse = await providers.list()
    const indicatorsResponse = await indicators.list()
    const profileResponse = await profiles.show(id)
    setApiResponse({
      ...apiResponse,
      providers: providersResponse.data?.providers || [],
      indicators: indicatorsResponse.data?.indicators || [],
      profile: profileResponse.data?.profile || [],
    })
  }

  const handleChangeCreate = (e) => {
    if (fieldsNeedTranslation.find((trans) => trans.name == e.target.name)) {
      setCreateProfil({
        ...createProfile,
        [locale]: {
          ...createProfile[locale],
          [e.target.name]: e.target.value,
          locale: locale,
        },
      })
    } else {
      setCreateProfil({
        ...createProfile,
        [e.target.name]: e.target.value,
      })
    }
  }
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    activeStep == 0 ? setDisabled(stepTwoValidator(createProfile)) : ''
  }

  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    activeStep == 1 ? setDisabled(stepOneValidator(createProfile, locale)) : ''
  }
  const checkTraductions = () => {
    if (notAllHaveTraductions(createProfile.traductions)) {
      setOpenModal(true)
    } else {
      handleSubmitData()
    }
  }
  const handleSubmitData = async () => {
    let name, description
    setLoadingPage(false)
    if (Object.values(createProfile.traductions).length == 0) {
      name = [{ value: createProfile[locale].name, locale: locale }]
      description = [
        {
          value: createProfile[locale].description,
          locale: locale,
        },
      ]
    } else {
      name = Object.values(createProfile.traductions).map((traduction) => {
        return { value: traduction.name || '', locale: traduction.locale }
      })
      name.push({ value: createProfile[locale].name, locale: locale })

      description = Object.values(createProfile.traductions).map(
        (traduction) => {
          return {
            value: traduction.description || '',
            locale: traduction.locale,
          }
        }
      )

      description.push({
        value: createProfile[locale].description,
        locale: locale,
      })
    }
    const body = {
      id: id,
      name: name,
      description: description,
      id_provider: createProfile.provider,
      indicators: createProfile.indicators,
    }
    setDisabled(true)
    const res = await profiles.edit(body)
    if (res.data) {
      setActiveStep(activeStep + 1)
    }
    setLoadingPage(true)
  }
  useEffect(() => {
    if (!canAccess(actionKeys.edit, pagesKeys.profiles, state)) {
      history.push('/dashboard')
    } else {
      return getApiResponse()
    }
  }, [])

  useEffect(() => {
    setLoadingPage(false)
    const indicatorsData = apiResponse.profile?.indicators?.map((indicator) => {
      return indicator.id
    })
    const localeData = apiResponse.profile?.translations?.find(
      (info) => info.locale == locale
    )
    let traductions = {}
    apiResponse.profile?.translations?.map((field) => {
      if (field.locale != locale) {
        traductions[field.locale] = {
          name: field.name,
          description: field.description,
          locale: field.locale,
        }
      }
    })
    const information = {
      [locale]: {
        name: localeData?.name,
        description: localeData?.description,
        locale: localeData?.locale,
      },
      indicators: indicatorsData,
      provider: apiResponse.profile?.id_provider,
      traductions: traductions,
    }
    setCreateProfil(information)
    setLoadingPage(true)
  }, [apiResponse])

  useEffect(() => {
    activeStep == 0
      ? setDisabled(stepOneValidator(createProfile, locale))
      : setDisabled(stepTwoValidator(createProfile))
  }, [createProfile])
  const classes = useStyles()
  return (
    <Box p={4} m={4}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.profiles')} /{' '}
          <Link to="/dashboard/profiles/" className={classes.textBreadcrum}>
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
          {I18n.t('cruds.profiles.edit.indicator')}
        </Typography>
      </Box>

      <Box className={classes.bodyContainer}>
        <Box className={classes.titleBarContainer}>
          <Box className={classes.titleContainer}>
            {apiResponse.profile ? (
              <Fragment>
                <Typography variant="subtitle1" color="textSecondary">
                  {I18n.t('cruds.profiles.edit.title')}
                </Typography>
                <Typography variant="h6" color="primary">
                  {createProfile[locale]?.name
                    ? createProfile[locale].name
                    : I18n.t('cruds.profiles.create.indicator')}
                </Typography>
              </Fragment>
            ) : (
              ''
            )}
          </Box>

          <Box className={classes.stepperContainer}>
            <StepperCreate activeStep={activeStep} steps={steps} />
          </Box>
        </Box>
        {loadingPage && createProfile.indicators ? (
          <>
            <Box className={classes.inputsContainer}>
              <Fade in={activeStep == 0} timeout={500}>
                <Box>
                  {activeStep == 0 && (
                    <InformationGeneralForm
                      change={handleChangeCreate}
                      values={createProfile}
                      locale={locale}
                      data={apiResponse.providers}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 1} timeout={500}>
                <Box>
                  {activeStep == 1 && (
                    <ProfileSettingsForm
                      values={createProfile}
                      setCreateProfil={setCreateProfil}
                      dataIndicators={apiResponse.indicators}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 2} timeout={500}>
                <Box>
                  {activeStep == 2 && (
                    <LanguageSettingsForm
                      items={fieldsNeedTranslation}
                      values={createProfile}
                      locale={locale}
                      setCreateData={setCreateProfil}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 3} timeout={500}>
                <Box>
                  {activeStep == 3 && (
                    <Box className={classes.finishContainer}>
                      <CheckCircle className={classes.chekCircle} />
                      <Typography variant="h5" color="primary">
                        {I18n.t('components.success')}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {I18n.t('cruds.profiles.edit.edited')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Fade>
            </Box>
            <Box
              className={
                activeStep < 3
                  ? classes.actionsContainer
                  : classes.actionsContainerEnd
              }
            >
              {activeStep < 3 && (
                <Box>
                  <Button
                    variant="text"
                    color="secondary"
                    className={classes.buttonText}
                    component={Link}
                    to="/dashboard/profiles/"
                  >
                    {I18n.t('components.cancel')}
                  </Button>
                </Box>
              )}
              <Box>
                {activeStep < 3 ? (
                  <Fragment>
                    {activeStep > 0 && (
                      <Button
                        disabled={activeStep < 2 ? false : disabled}
                        variant="outlined"
                        color="secondary"
                        className={classes.buttonContained}
                        onClick={handleBackStep}
                      >
                        {I18n.t('components.back')}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={
                        activeStep < 2 ? handleNextStep : checkTraductions
                      }
                      disabled={disabled}
                    >
                      {I18n.t('components.next')}
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    component={Link}
                    to="/dashboard/profiles"
                  >
                    {I18n.t('cruds.profiles.return')}
                  </Button>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <CircularProgress color="secondary" className={classes.loading} />
        )}
      </Box>
      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('components.traductions.missingTraductionTitle')}
        message={I18n.t('components.traductions.missingTraductiontext')}
        SuccessText={I18n.t('components.yes')}
        success={handleSubmitData}
      />
    </Box>
  )
}

export default EditProfile
