import { useEffect, useState } from 'react'
import { Box, Paper, IconButton, Typography } from '@mui/material'
import I18n from 'i18n-js'
import { questions } from '../../utils/questionsKeys'
import { InputForm, SelectForm, Switch } from '../'
import { Icons } from '../../utils'
import useStyles from './style'
import { SimpleSelection, MultipleSelection, Numeric } from '../'
import { QuestionValidator } from '../../validators/createQuestionaryValidator'
import { isEmpty } from '../../validators/validator'

const typeQuestion = (
  cardItems,
  setCardItems,
  questionarieValues,
  id,
  deleteOption,
  setDeleteOption
) => {
  if (cardItems.type) {
    if (cardItems.type == 1) {
      return (
        <SimpleSelection
          cardItems={cardItems}
          setCardItems={setCardItems}
          questionarieValues={questionarieValues}
          questionId={id}
          deleteOption={deleteOption}
          setDeleteOption={setDeleteOption}
        />
      )
    } else if (cardItems.type == 2) {
      return (
        <MultipleSelection
          cardItems={cardItems}
          setCardItems={setCardItems}
          questionarieValues={questionarieValues}
          questionId={id}
          deleteOption={deleteOption}
          setDeleteOption={setDeleteOption}
        />
      )
    } else if (cardItems.type === 4 || cardItems.type === 5) {
      return <Numeric cardItems={cardItems} setCardItems={setCardItems} />
    }
  }
}

const CardQuestion = ({
  setQuestionarie,
  questionarieValues,
  id,
  locale,
  remove,
  values,
}) => {
  const classes = useStyles()
  const [deleteOption, setDeleteOption] = useState(0)
  const [cardItems, setCardItems] = useState(
    values != undefined ? values : { type: '' }
  )
  const [mandatory, setMandatory] = useState(
    values != undefined ? values.mandatory : false
  )

  const Handlechange = (e) => {
    if (e.target.name == 'type') {
      cardItems.options = {}
    }
    setCardItems({
      ...cardItems,
      [e.target.name]: e.target.value,
      mandatory: mandatory,
    })
  }
  const handleChangeMandatory = () => {
    setMandatory(!mandatory)
    setCardItems({ ...cardItems, mandatory: !mandatory })
  }
  useEffect(() => {
    if (QuestionValidator(cardItems)) {
      cardItems.completed = true
    } else {
      cardItems.completed = false
    }
    setQuestionarie({
      ...questionarieValues,
      [locale]: {
        ...questionarieValues[locale],
        questions: { ...questionarieValues[locale].questions, [id]: cardItems },
      },
    })
  }, [cardItems, deleteOption])
  return (
    <Paper className={classes.container}>
      <Box className={classes.headerContainer}>
        <Box className={classes.flexContainer}>
          <InputForm
            variant="outlined"
            // size="small"
            name="title"
            color="secondary"
            error={
              cardItems.title === undefined
                ? false
                : isEmpty(cardItems.title) || false
            }
            label={
              I18n.t('cruds.questionaries.create.nameQuestionLabel') + '(*)'
            }
            value={cardItems.title || ''}
            onChange={Handlechange}
            className={classes.inputsHeader}
          />
          {cardItems.title === undefined
            ? false
            : isEmpty(cardItems.title) && (
                <Typography variant="caption" color="error">
                  {I18n.t('validators.required')}
                </Typography>
              )}
        </Box>
      <Box className={classes.flexContainer}>
        <SelectForm
          variant="outlined"
          // size="small"
          name="type"
          label={
            I18n.t('cruds.questionaries.create.typeofQuestionLabel') + '(*)'
          }
          color="secondary"
          options={questions}
          onChange={Handlechange}
          value={cardItems.type || ''}
          isLocalData
          className={classes.inputsHeader}
        />
        </Box>
      </Box>
      <Box className={classes.bodyContainer}>
        {typeQuestion(
          cardItems,
          setCardItems,
          questionarieValues,
          id,
          deleteOption,
          setDeleteOption
        )}
      </Box>
      <Box className={classes.footerContainer}>
        <Box>
          <Switch onClick={handleChangeMandatory} enable={mandatory} />
        </Box>
        <IconButton
          size="small"
          onClick={() => {
            remove(id)
          }}
          className={classes.iconFooter}
        >
          {Icons('delete')}
        </IconButton>
      </Box>
    </Paper>
  )
}

export default CardQuestion
