const getAnnotationsLocale = (locale, annotations) => {
  const localeAnnotations = annotations?.map((annotation) => {
    return {
      completed: true,
      id: annotation.id,
      value: annotation.value || '',
      recomendation:
        Object.values(annotation.translations).find(
          (translation) => translation.locale == locale
        )?.recommendation || '',
      interpretation:
        Object.values(annotation.translations).find(
          (translation) => translation.locale == locale
        )?.interpretation || '',
    }
  })
  return localeAnnotations
}

const useSortResponse = (apiResponse, locale) => {
  const localedata = apiResponse.translations?.find(
    (info) => info.locale == locale
  )

  const localeAnnotations = getAnnotationsLocale(
    locale,
    apiResponse.recommendations
  )
  let traductions = {}
  apiResponse?.translations?.map((translation) => {
    if (translation.locale != locale) {
      const annotations = getAnnotationsLocale(
        translation.locale,
        apiResponse.recommendations
      )
      traductions[translation.locale] = {
        name: translation.name || '',
        description: translation.description || '',
        locale: translation.locale,
        annotations: annotations,
      }
    }
  })

  let data = {
    [locale]: {
      name: localedata?.name || '',
      description: localedata?.description || '',
      locale: localedata?.locale,
      annotations: Object.assign({}, localeAnnotations),
    },
    traductions: traductions,

    id_unit: apiResponse?.id_unit || '',
    id_datasource: apiResponse?.id_datasource,
    acronym: apiResponse?.acronym,
    value_type: apiResponse?.value_type,
  }
  if (apiResponse.translations) {
    return data
  } else {
    return {}
  }
}

export default useSortResponse
