import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

export const stylesPatient = {
  downloadCsv: {
    marginRight: '10px',
  },
  csv: {
    padding: '5px 10px',
    color: colors.white,
  },
  downloadContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& .MuiIconButton-root': {
      padding: '0px',
    },
    '& .MuiSvgIcon-root': {
      padding: '0px',
      color: colors.white,
    },
    // justifyContent: 'space-between'
  },
  menu: {
    '& .MuiPaper-root .MuiList-root.MuiList-root': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      borderBottom: `1px solid ${colors.disabledDarken}`,
      '& li:not(:last-child)': {
        borderBottom: 'none',
      },
      paddingTop: '0px',
      paddingBottom: '0px',
      '&:last-child': {
        padding: '5px 0px',
        background: colors.blueInput,
      },
    },
  },
  label: {
    '& .MuiFormControlLabel-label': {
      wordBreak: 'break-all',
    },
  },
  download: {
    borderRadius: '0px',
    color: colors.white,
    background: colors.blueLighten,
  },
  checkbox: {
    padding: '0px',
    marginRight: '10px',
    '& .MuiBox-root': {
      width: '15px',
      height: '15px',
    },
  },
  icons: {
    display: 'flex',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
      color: colors.disabled,
    },
    '& .MuiTypography-root': {
      fontWeight: '500',
      fontSize: '14px',
    },
    alignItens: 'center',
    '& .MuiDataGrid-root': {
      alignItens: 'center',
    },
  },
  exportLayout: {
    background: colors.disabledDarken,
  },
  clip: {
    marginRight: '10px',
    marginLeft: '10px',
    color: colors.white,
    borderRadius: '0px',
    boxShadow: `1px 1px 2px ${colors.disabledClip}`,
  },
  activeClip: {
    background: colors.blueLighten,
    '&:hover': {
      background: colors.blueLighten + 'dd',
    },
  },
  disabledClip: {
    background: colors.black,
    '&:hover': {
      background: colors.black + 'dd',
    },
  },
}

const useStyles = makeStyles({
  titleHeader: {
    fontSize: '14px',
    color: `${colors.black}DE`,
    fontWeight: '600',
    '&.MuiTypography-root': {
      fontSize: '14px',
      color: `${colors.black}DE`,
      fontWeight: '600',
    },
  },
  searchContainer: {
    '& .MuiGrid-root': {
      marginLeft: '0px',
    },
    '&.MuiGrid-item': {
      width: '100%',
    },
    '& .MuiAutocomplete-root': {
      background: colors.white,
      input: {
        '& .MuiOutlinedInput-root': {
          height: '53px',
          display: 'flex',
          flexDirection: 'column',
        },
        '& .MuiAutocomplete-tag': {
          maxWidth: 'calc(100% - 22px)',
        },
      },
    },
  },
  table: {
    '& .MuiDataGrid-columnHeader': {
      height: '80px',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      whiteSpace: 'initial ',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'initial ',
      lineHeight: 1.2,
    },
    '& .gray': {
      background: colors.headerGray,
    },
  },
  dateTooltip: {
    '&.MuiTypography-root': {
      fontSize: '12px',
    },
  },
  kforce: {
    color: colors.disabledLighten2,
    '&.good': {
      color: colors.success,
    },
    '&.ok': {
      color: colors.success,
    },
    '&.warning': {
      color: colors.warning,
    },
    '&.care': {
      color: colors.care,
    },
    '&.danger': {
      color: colors.danger,
    },
  },
  circle: {
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& svg': {
      width: '45px',
      height: '45px',
    },
  },
  circle2: {
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& svg': {
      width: '38px',
      height: '38px',
    },
    '& p': {
      position: 'absolute',
      color: colors.white,
    },
  },
})

export default useStyles
