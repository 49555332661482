import { apiUrl } from './constants'
import './interceptor'

const notTypeHeader = () => {
  const header =
    localStorage.getItem('state') && localStorage.getItem('state') !== ''
      ? {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('state')).token
          }`,
          'Content-Type': 'application/json',
        }
      : {
          'Content-Type': 'application/json',
        }

  return header
}

const typeHeader = (content = {}) => {
  const header =
    localStorage.getItem('state') && localStorage.getItem('state') !== ''
      ? {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('state')).token
          }`,
          ...content,
        }
      : {}

  return header
}

async function GET(url, provider) {
  let response
  try {
    if (provider) {
      response = await fetch(apiUrl + url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('state')).token
          }`,

          'Content-Type': 'application/json',
          'provider-token': `${localStorage.getItem('provider-token')}`,
        },
      })
    } else {
      response = await fetch(apiUrl + url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem('state')).token
          }`,

          'Content-Type': 'application/json',
        },
      })
    }

    const json = await response.json()

    if (response.status <= 299) return { data: json, error: null }
    return { data: null, error: json }
  } catch (error) {
    return { data: null, error: error }
  }
}

async function POST(url, body, type_data = false, contentType = {}) {
  try {
    const response = await fetch(apiUrl + url, {
      method: 'POST',
      headers: type_data ? typeHeader(contentType) : notTypeHeader(),
      body: type_data ? body : JSON.stringify(body),
    })
    const json = await response.json()
    if (response.status <= 299) return { data: json, error: null }
    return { data: null, error: json }
  } catch (error) {
    return { data: null, error: error }
  }
}

async function PUT(url, body, type_data = false) {
  try {
    const response = await fetch(apiUrl + url, {
      method: 'PUT',
      headers: type_data ? typeHeader() : notTypeHeader(),
      body: type_data ? body : JSON.stringify(body),
    })
    const json = await response.json()
    if (response.status <= 299) return { data: json, error: null }
    return { data: null, error: json }
  } catch (error) {
    return { data: null, error: body }
  }
}

async function DELETE(url) {
  try {
    const response = await fetch(apiUrl + url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('state')).token
        }`,
        'Content-type': 'application/json',
      },
    })
    const json = await response.json()
    if (response.status <= 299) return { data: json, error: null }
    return { data: null, error: json }
  } catch (error) {
    return { data: null, error: error }
  }
}

export const downloadBlob = async (url) => {
  try {
    const response = await fetch(`${apiUrl}${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('state')).token
        }`,
        'Content-Type': 'application/json',
      },
    })
    const json = await response.blob()

    if (response.status <= 299) return { data: json, error: null }
    return { data: null, error: json }
  } catch (error) {
    return { data: null, error: error }
  }
}

export { GET, POST, PUT, DELETE }
