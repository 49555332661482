import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import I18n from 'i18n-js'
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import { AuthContext } from '../../../context/auth'
import {
  Loading,
  TabPanel,
  CrudsBtn,
  SearchBar,
  SnackBar,
  Loader,
} from '../../../components'
import InformationGeneral from './informationGeneral'
import SmartPredict from './smartPredict'
// import SmartDetect from './smartDetect'
import smartpredictService, {
  anonymizedZip,
} from '../../../services/smartPredict'
import patientsService from '../../../services/patients'
import { observations, medicalcenters } from '../../../services'
import { canAccess } from '../../../router/permissions'
import { Icons, pagesKeys, actionKeys, images } from '../../../utils'
import useStyles, { useSxStyles } from '../../../styles'
import Observations from './observations'
import { DinamicForm } from '../../../components'
import { sx } from './observations/sx'
import { detailCommunitiesId, detailState } from '../../../services/states'
import BilansVDSI from './bilansVDSI'
import FilterDate from './bilansVDSI/Filter/filter'
import Physilogique from './bilanPhysio'
import { downloadZip } from '../../../utils/tools'
import { LoadingButton } from '@mui/lab'
import { useQuestionnairesDates } from './hooks'
import { KeyboardArrowDown } from '@mui/icons-material'
import Council from './council'
import { TeleExpertise } from './teleExpertise'
import { useGetData } from './hooks/useGetData'

const CustomeLoader = () => (
  <Box pr={1}>
    <Loader size={14} removeMH />
  </Box>
)
const Breadcrumb = ({ patient, state, questionnaireDates }) => {
  const homePredict = canAccess(actionKeys.detail, pagesKeys.homePredict, state)
  const editPatient = canAccess(actionKeys.edit, pagesKeys.patients, state)
  const [selectQuestionnaireDate, setSelectQuestionnaireDate] = useState('')
  const [load, setLoad] = useState(false)
  const classes = useStyles()
  const styles = useSxStyles({ select: selectQuestionnaireDate })

  const handleDownloadAnonimus = async () => {
    setLoad(true)
    if (selectQuestionnaireDate && patient?.id) {
      const responseDownload = await anonymizedZip(
        patient.id,
        selectQuestionnaireDate
      )

      if (!responseDownload.error) {
        downloadZip(responseDownload.data)
      }
    }
    setLoad(false)
  }

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.patient')} /{' '}
          <Link to="/dashboard/patients" className={classes.textBreadcrum}>
            {' '}
            {I18n.t('breadcrumb.dashboard')}
          </Link>{' '}
          / {I18n.t('breadcrumb.detail')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.profileContainer}>
        <Box
          component="img"
          className={classes.imageProfile}
          src={patient?.photo ?? images.patientProfile}
        />
        <Box className={classes.grow}>
          <Typography variant="h5" color="primary">
            {patient.name}
          </Typography>

          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.subTitle}
          >
            {patient.gender || I18n.t('general.dataNotFound')} -{' '}
            {`${patient.age} ${I18n.t(
              'patient.detail.tabInformation.years'
            )}` || I18n.t('general.dataNotFound')}{' '}
            - IMC: {patient.imc || I18n.t('general.dataNotFound')}
          </Typography>
        </Box>
        <Box sx={styles.boxDownload}>
          {!!questionnaireDates?.array?.length && (
            <Box sx={styles.selectDate}>
              <Typography sx={styles.selectDateText}>
                {I18n.t('general.dataGeneralDowload')}
              </Typography>

              <FormControl sx={styles.questionnaireDates} size="small">
                {!selectQuestionnaireDate && (
                  <InputLabel id="demo-simple-select-label">
                    {I18n.t('general.selectDate')}
                  </InputLabel>
                )}
                <Select
                  id="demo-simple-select-label"
                  label={I18n.t('general.selectDate')}
                  IconComponent={
                    questionnaireDates?.loading || load
                      ? CustomeLoader
                      : KeyboardArrowDown
                  }
                  value={selectQuestionnaireDate}
                  onChange={({ target }) => {
                    const { value } = target
                    setSelectQuestionnaireDate(value)
                  }}
                >
                  {questionnaireDates?.array?.map((date, item) => {
                    return (
                      <MenuItem
                        selected={item == 0 ? true : false}
                        key={item}
                        value={date}
                      >
                        {date
                          ? format(new Date(date), I18n.t('general.dateFormat'))
                          : ''}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <IconButton
                disabled={!selectQuestionnaireDate}
                onClick={() => {
                  handleDownloadAnonimus()
                }}
                sx={styles.downloadQuestionnaires}
              >
                {Icons('download')}
              </IconButton>
            </Box>
          )}
          {homePredict && (
            <CrudsBtn
              startIcon={Icons('homePredict')}
              text={I18n.t('sidebar.homePredict')}
              variant="outlined"
              className={classes.buttomsBreadcrumb}
            />
          )}
          {editPatient && (
            <CrudsBtn
              variant="outlined"
              text={I18n.t('cruds.patients.edit.indicator')}
              className={classes.buttomsBreadcrumb}
              component={Link}
              to={`/dashboard/patients/edit/${patient.id}`}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

const Patient = () => {
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [generalSnackbar, setGeneralSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  })
  const { id } = useParams()
  const classes = useStyles()
  const { state } = useContext(AuthContext)
  const pageId = pagesKeys.patients
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [load, setLoad] = useState(true)
  const [patient, setPatient] = useState({})
  const [documents, setDocuments] = useState([])
  const [observationArr, setObservationArr] = useState([])
  const [newObservation, setNewObservation] = useState([])
  const [arrayDates, setArrayDates] = useState([])
  const [lastUpdates, setLastUpdates] = useState([])
  const [index, setIndex] = useState()
  const [vdsiList, setVdsiList] = useState([])
  const [vdsiListCopy, setVdsiListCopy] = useState([])
  const [teleconsultations, setTeleconsultations] = useState([])
  const questionnaireDates = useQuestionnairesDates(id)
  const [loadingDownload, setLoadingDownload] = useState(false)
  // const [smartDetectData, setSmartDetect] = useState([])
  const [tab, setTab] = useState(0)
  const { files } = useGetData({ tab })
  const [dateselected, setDateSelected] = useState({
    startDate: '',
    endDate: '',
    search: '',
  })

  const [message, setMessage] = useState({
    text: '',
    variant: 'success',
    open: false,
  })
  const [ban, setBan] = useState({})
  const input = [
    {
      name: 'startDate',
      label: I18n.t('patient.form.startDate'),
      type: 'date',
      style: `${classes.inputWidth} ${classes.date}`,
    },
    {
      name: 'endDate',
      label: I18n.t('patient.form.endDate'),
      type: 'date',
      style: `${classes.inputWidth} ${classes.date} ${classes.spacing}`,
    },
  ]
  // let centreId = props.match.params.idcentre
  let patientId = id
  let informatonIndex
  if (
    canAccess(actionKeys.viewTabSmartPredict, pageId, state) &&
    canAccess(actionKeys.viewTabSmartDetect, pageId, state)
  ) {
    informatonIndex = 1
  } else if (
    !canAccess(actionKeys.viewTabSmartPredict, pageId, state) &&
    !canAccess(actionKeys.viewTabSmartDetect, pageId, state)
  ) {
    informatonIndex = 0
  } else if (
    !canAccess(actionKeys.viewTabSmartPredict, pageId, state) ||
    !canAccess(actionKeys.viewTabSmartDetect, pageId, state)
  ) {
    informatonIndex = 1
  }
  const sendData = (value) => {
    if (value) {
      setGeneralSnackbar({
        open: true,
        type: 'success',
        message: 'patient.teleExpertise.request.success',
      })
    } else {
      setGeneralSnackbar({
        open: true,
        type: 'error',
        message: 'patient.teleExpertise.request.error',
      })
    }
  }

  const closeGeneralSnackbar = () => {
    setGeneralSnackbar((prev) => ({ ...prev, open: false }))
  }

  const loadData = useCallback(async () => {
    handleClearDate()
    const informationIndexUpOne = informatonIndex + index
    try {
      if (!ban.ban1) {
        setLoad(true)
        setBan({ ...ban, ban1: true })
        let patient = await loadPatient(patientId)
        setPatient(patient)
      }

      if (patientId) {
        if (isLocale('fr') && tab === informatonIndex + 1 && !ban.ban6) {
          setLoad(true)
          setBan({ ...ban, ban6: true })
          const vdsi = await patientsService.getVDSIList(patientId)
          if (vdsi.data) {
            const sortVDSIArray = vdsi.data.patient_vdsi
              ? vdsi.data.patient_vdsi?.sort((a, b) => {
                  const c = new Date(a.updated_at).getTime()
                  const d = new Date(b.updated_at).getTime()

                  if (c < d) return 1
                  else if (c > d) return -1
                  return 0
                })
              : []
            setVdsiList(sortVDSIArray)
            setVdsiListCopy(sortVDSIArray)
          }
        }

        if (tab === informatonIndex && !ban.ban7) {
          setLoad(true)
          setBan({ ...ban, ban7: true })
          const data = await patientsService.getTeleconsultations(patientId)
          if (data.data) {
            setTeleconsultations(data.data.teleconsultations)
          }
        }
        if (tab === informationIndexUpOne && !ban.ban3) {
          setLoad(true)
          setBan({ ...ban, ban3: true })
          const documentsJson = await patientsService.documents(patientId)

          if (documentsJson?.data?.[0]?.signature_url) {
            setDocuments([documentsJson.data[0]])
          } else {
            const legalDocumentOfTheMedicalCenter =
              await medicalcenters.medicalCenterDocument(
                patient.medical_centers[0].id
              )

            const datalegalDocumentOfTheMedicalCenter =
              legalDocumentOfTheMedicalCenter?.data?.[0]

            const legalDocumentPreview = await patientsService.documentPatient(
              datalegalDocumentOfTheMedicalCenter?.id,
              patientId
            )
            setDocuments([
              {
                id: datalegalDocumentOfTheMedicalCenter?.id,
                name: datalegalDocumentOfTheMedicalCenter.name,
                description: datalegalDocumentOfTheMedicalCenter.description,
                document_url: legalDocumentPreview.data.preview,
                accepted: false,
              },
            ])
          }
        }

        if (tab === informationIndexUpOne + 1 && !ban.ban5) {
          setLoad(true)
          setBan({ ...ban, ban5: true })
          const observation = await observations.detail(id)
          const data = observation?.data?.observations

          const sortListForDate =
            data
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              ?.map((item) => ({
                ...item,
                created_at: new Date(item.created_at),
              })) ?? []

          setObservationArr(sortListForDate)

          setNewObservation(sortListForDate)
        }
      }
      setLoad(false)
    } catch (e) {
      //error
      setLoad(false)
    }
    setIsLoading(false)
  }, [tab, patientId])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    if (!canAccess(actionKeys.detail, pageId, state)) {
      history.push('/dashboard')
    }
  }, [])
  useEffect(() => {
    setIndex(isLocale('fr') ? 2 : 1)
  }, [])
  const loadPatient = async (patientId) => {
    let patient = await (await smartpredictService.patient(patientId)).data
    let patientDetail = await (await patientsService.detail(patientId)).data
    let historySP = []
    if (canAccess(actionKeys.viewTabSmartPredict, pageId, state))
      historySP = await smartpredictService.history(patientId)
    const dates = []
    const lastUpdate = []
    historySP.forEach((data) => {
      if (data.date_tug != '') dates.push(data.date_tug)
      if (data.updated_at != '')
        lastUpdate.push({
          id: data.date_tug,
          value: data.updated_at,
          dateTug: data.date_tug,
        })
    })

    setArrayDates(dates)
    setLastUpdates(lastUpdate)

    let countDoctors = []
    let doctors_selected = []
    for (let i in patientDetail.patient.medical_staff) {
      if (patientDetail.patient.medical_staff[i].pivot.main_doctor) {
        countDoctors.push(
          patientDetail.patient.medical_staff[i].pivot.id_medical_staff
        )
        doctors_selected.push(patientDetail.patient.medical_staff[i])
      }
    }

    let newData = {}

    if (state.locale === 'fr') {
      const resS = await handleStateACommunity(
        patientDetail.patient.state_id,
        'state'
      )
      const resC = await handleStateACommunity(
        patientDetail.patient.community_id,
        'community'
      )

      newData = {
        code_insee: patient.insee_code,
        state_id: resS,
        community_id: resC,
        zip_code: patient.zip_code,
      }
    } else if (state.locale === 'pt') {
      newData = {
        cc: patientDetail.patient.cc,
        nif: patientDetail.patient.nif,
        niss: patientDetail.patient.niss,
        sns: patientDetail.patient.sns,
        comments: patientDetail.patient.comments,
        expire_date: patientDetail.patient.expire_date,
      }
    } else if (state.locale === 'es') {
      newData = {
        dni: patientDetail.patient.dni,
        nuss: patientDetail.patient.nuss,
      }
    }

    return {
      id: patient.id,
      name: patient.name + ' ' + patient.lastname,
      lastname: patient.lastname,
      nameSome: patient.name,
      gender: patient.sex
        ? I18n.t('patient.patientInfo.male')
        : I18n.t('patient.patientInfo.female'),
      age: patient.age,
      QRcode:
        patient.QRcode == null
          ? I18n.t('general.dataNotFound')
          : patient.QRcode,
      status: patient.system_status ? 'good' : 'danger',
      fragilityScore: patient.patient_smart_predict
        ? patient.patient_smart_predict.fragility_score
        : I18n.t('general.dataNotFound'),
      battery: '',
      right_hand: patient.right_hand,
      hand:
        patient.right_hand === 1
          ? I18n.t('patient.detail.rightHanded')
          : I18n.t('patient.detail.leftHanded'),
      height: patient.height ? patient.height : I18n.t('general.dataNotFound'),
      weight: patient.weight ? patient.weight : I18n.t('general.dataNotFound'),
      email: patientDetail.patient.email
        ? patientDetail.patient.email
        : I18n.t('general.dataNotFound'),
      phone: patientDetail.patient.phone
        ? patientDetail.patient.phone
        : I18n.t('general.dataNotFound'),
      birth: patientDetail.patient.birth
        ? format(
            parseISO(patientDetail.patient.birth),
            I18n.t('general.dateFormat')
          )
        : I18n.t('general.dataNotFound'),
      place_birth: patientDetail.patient.place_birth
        ? patientDetail.patient.place_birth
        : I18n.t('general.dataNotFound'),
      country_birth: patientDetail.patient.country_birth
        ? patientDetail.patient.country_birth
        : I18n.t('general.dataNotFound'),
      country: patientDetail.patient.country
        ? patientDetail.patient.country.name
        : I18n.t('general.dataNotFound'),
      SSN: patientDetail.patient.SSN
        ? patientDetail.patient.SSN
        : I18n.t('general.dataNotFound'),
      estimote_id: patientDetail.patient.estimote_id,
      medical_staff: patientDetail.patient.medical_staff,
      doctors: countDoctors,
      doctors_selected: doctors_selected,
      relateds: patientDetail.patient.relateds,
      health_conditions: patientDetail.patient.health_conditions,
      medical_centers:
        patientDetail.patient.medical_centers !== undefined
          ? patientDetail.patient.medical_centers
          : null,
      smartPredictIndicators: historySP,
      ...newData,
      imc: patientDetail.patient.imc,
      photo: patientDetail.patient.photo_url,
    }
  }

  const handleStateACommunity = async (id, name) => {
    if (name === 'state') {
      if (id !== null && id) {
        const res = await detailState(id)

        return res.data.state.name
      }
    } else if (name === 'community') {
      if (id !== null && id) {
        const res = await detailCommunitiesId(id)
        return res.data.community.name
      }
    }
  }

  const handleTabChange = (_, newValue) => {
    setTab(newValue)
  }
  const handleChangeSearchbar = ({ target }) => {
    const { value } = target
    const { startDate, endDate } = dateselected

    // if (value.trim() !== '') {
    const start = startDate !== '' ? startDate : 0
    const end = endDate !== '' ? endDate : new Date().setHours(23, 59, 59, 59)

    setNewObservation(
      observationArr?.filter((item) => {
        const { medical_staff, created_at } = item
        const { name, lastname } = medical_staff

        const full_name = `${name?.toLowerCase()} ${lastname?.toLowerCase()}`
        return (
          full_name.includes(value?.toLowerCase()) &&
          start < Date.parse(created_at) &&
          end > Date.parse(created_at)
        )
      })
    )

    setDateSelected({
      ...dateselected,
      search: value?.toLowerCase(),
    })
  }

  const downloadData = async (patientId, date) => {
    setLoadingDownload(true)
    const dateToSend = format(date ? new Date(date) : new Date(), 'yyyy-MM-dd')
    const responseDownload = await anonymizedZip(patientId, dateToSend)

    if (!responseDownload.error) {
      downloadZip(responseDownload.data)
    }
    setLoadingDownload(false)
  }

  const serachDate = ({ name, value }) => {
    let ban = 0

    switch (name) {
      case 'startDate':
        ban =
          dateselected.endDate !== ''
            ? dateselected.endDate
            : new Date().setHours(23, 59, 59, 59)

        return {
          startDate:
            new Date(value).setHours(0, 0, 0, 0) <= ban
              ? new Date(value).setHours(0, 0, 0, 0)
              : dateselected.endDate,
          endDate:
            dateselected.endDate !== ''
              ? dateselected.endDate
              : new Date().setHours(23, 59, 59, 59),
        }
      case 'endDate':
        return {
          startDate: dateselected.startDate !== '' ? dateselected.startDate : 0,
          endDate:
            new Date(value).setHours(23, 59, 59, 59) >= dateselected.startDate
              ? new Date(value).setHours(23, 59, 59, 59)
              : dateselected.startDate,
        }
      default:
        return { error: 'Invalid Date' }
    }
  }

  const sendEmail = async (email, id) =>
    await patientsService.sendEmailVdsi(id, { email: [email] })

  const filterDate = (target) => {
    const { name } = target
    const { startDate, endDate } = serachDate(target)

    const filter = observationArr?.filter((item) => {
      const { medical_staff, created_at } = item
      const { name, lastname } = medical_staff

      const miliseconds = Date.parse(created_at)
      const full_name = `${name?.toLowerCase()} ${lastname?.toLowerCase()}`

      return (
        full_name?.includes(dateselected.search) &&
        startDate <= miliseconds &&
        endDate >= miliseconds
      )
    })

    setNewObservation([...filter])

    setDateSelected({
      ...dateselected,
      [name]: name === 'startDate' ? startDate : endDate,
    })
  }

  const handleDocumentFilter = ({ target }) => {
    const { name } = target
    const { startDate, endDate } = serachDate(target)

    setDateSelected({
      ...dateselected,
      [name]: name === 'startDate' ? startDate : endDate,
    })
  }

  const filterVDSIbyDate = () => {
    const filter = vdsiListCopy?.filter((item) => {
      const { created_at } = item
      const miliseconds = Date.parse(created_at)

      return (
        dateselected.startDate <= miliseconds &&
        (dateselected.endDate || new Date().setHours(23, 59, 59, 59)) >=
          miliseconds
      )
    })

    setVdsiList([...filter])
  }

  const isLocale = (locale) => state.locale === locale

  const handleChange = ({ target }) => {
    const { value } = target

    if (value !== '') filterDate(target)
  }

  const handleClose = () => {
    setMessage({
      ...message,
      open: false,
    })
  }
  const handleClearDate = () => {
    setNewObservation([...observationArr])
    setVdsiList([...vdsiListCopy])

    setDateSelected({
      startDate: '',
      endDate: '',
      search: '',
    })

    const searchId = document.getElementById('search')

    if (searchId) {
      searchId.value = ''
    }
  }

  function renderBody() {
    if (patient) {
      return (
        <Grid container spacing={2}>
          <SnackBar
            text={I18n.t(message.text)}
            severity={message.variant}
            show={message.open}
            HandleShow={handleClose}
            time={1500}
          />
          <Breadcrumb
            patient={patient}
            state={state}
            questionnaireDates={questionnaireDates}
          />
          <Grid item sm={12}>
            <Paper square>
              <Tabs value={tab} variant="scrollable" onChange={handleTabChange}>
                {canAccess(actionKeys.viewTabSmartPredict, pageId, state) && (
                  <Tab label={I18n.t('patient.detail.tabSmartPredict.name')} />
                )}
                <Tab
                  label={I18n.t('patient.detail.tabSmartPredict.physiologique')}
                />
                {isLocale('fr') && (
                  <Tab label={I18n.t('patient.detail.tabInformation.vdsi')} />
                )}

                <Tab label={I18n.t('patient.detail.tabInformation.name')} />

                <Tab
                  label={I18n.t('patient.detail.tabObservations.observation')}
                />
                {canAccess(actionKeys.viewCouncil, pageId, state) &&
                  isLocale('fr') && (
                    <Tab label={I18n.t('patient.detail.tabTip.tips')} />
                  )}

                {!!canAccess(actionKeys.teleExpertise, pageId, state) && (
                  <Tab
                    label={I18n.t(
                      'patient.detail.tabInformation.teleExpertise'
                    )}
                  />
                )}
              </Tabs>

              {load ? (
                <Loading />
              ) : (
                <>
                  {canAccess(actionKeys.viewTabSmartPredict, pageId, state) && (
                    <TabPanel
                      value={tab}
                      index={0}
                      className={classes.mainContainer}
                    >
                      <SmartPredict
                        ban={ban}
                        setBan={setBan}
                        patient={patient}
                        arrayDates={arrayDates}
                        lastUpdates={lastUpdates}
                        downloadData={downloadData}
                        loadingDownload={loadingDownload}
                      />
                    </TabPanel>
                  )}
                  <TabPanel
                    value={tab}
                    index={informatonIndex}
                    className={classes.mainContainer}
                  >
                    <Physilogique data={teleconsultations} />
                  </TabPanel>
                  {isLocale('fr') && (
                    <TabPanel
                      value={tab}
                      index={informatonIndex + 1}
                      className={classes.mainContainer}
                    >
                      <>
                        {vdsiListCopy?.length > 0 ? (
                          <Box className={classes.vdsiContainer}>
                            <Box className={classes.downloadZip}>
                              <LoadingButton
                                variant="outlined"
                                padding="8px 15px"
                                className={classes.buttongeneral}
                                loading={loadingDownload}
                                loadingPosition="start"
                                startIcon={Icons('download')}
                                onClick={() => {
                                  downloadData(
                                    patient.id,
                                    vdsiList?.[0]?.updated_at
                                  )
                                }}
                              >
                                {I18n.t('general.dataDowload')}
                              </LoadingButton>
                            </Box>
                            <FilterDate
                              input={input}
                              onChange={handleDocumentFilter}
                              dateselected={dateselected}
                              filterDate={filterVDSIbyDate}
                              clearDate={handleClearDate}
                            />
                            <BilansVDSI list={vdsiList} sendEmail={sendEmail} />
                          </Box>
                        ) : (
                          <Grid container columnSpacing={2} alignItems="center">
                            <Grid item lg={8}>
                              <Typography variant="body1" color="textSecondary">
                                {I18n.t('general.notFound')}
                              </Typography>
                            </Grid>
                            <Grid item lg={4}>
                              <LoadingButton
                                padding="8px 15px"
                                variant="outlined"
                                className={classes.buttongeneral}
                                loading={loadingDownload}
                                loadingPosition="start"
                                startIcon={Icons('download')}
                                onClick={() => {
                                  downloadData(patient.id, Date.now())
                                }}
                              >
                                {I18n.t('general.dataDowload')}
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    </TabPanel>
                  )}

                  <TabPanel
                    value={tab}
                    index={informatonIndex + index}
                    className={classes.mainContainer}
                  >
                    <InformationGeneral
                      patient={patient}
                      documents={documents}
                    />
                  </TabPanel>
                  <TabPanel
                    value={tab}
                    index={informatonIndex + 1 + index}
                    className={classes.mainContainer}
                  >
                    {observationArr?.length > 0 && (
                      <Box className={classes.searchContainer}>
                        <Box className={classes.search} />

                        <DinamicForm
                          handleChange={() => {}}
                          inputs={input.slice(0, 1)}
                          values={dateselected}
                          errorDate={false}
                          onAccept={(e) => {
                            handleChange({
                              target: { name: 'startDate', value: e },
                            })
                          }}
                        />

                        <DinamicForm
                          handleChange={() => {}}
                          inputs={input.slice(1, 2)}
                          values={dateselected}
                          errorDate={false}
                          onAccept={(e) => {
                            handleChange({
                              target: { name: 'endDate', value: e },
                            })
                          }}
                        />

                        <SearchBar
                          change={handleChangeSearchbar}
                          placeholder={I18n.t('general.placeholderMedical')}
                          sx={sx}
                        />

                        <Button
                          variant="contained"
                          className={classes.btn}
                          onClick={handleClearDate}
                        >
                          {I18n.t('patient.form.clearDate')}
                        </Button>
                      </Box>
                    )}
                    {(newObservation?.length > 0 &&
                      newObservation?.map((item, key) => (
                        <Observations
                          key={key}
                          data={item?.name}
                          author={`${item?.medical_staff?.name} ${item?.medical_staff?.lastname}`}
                          date={item?.created_at}
                        />
                      ))) || (
                      <Grid item lg={12}>
                        <Typography variant="body1" color="textSecondary">
                          {I18n.t('general.notFound')}
                        </Typography>
                      </Grid>
                    )}
                  </TabPanel>
                  {canAccess(actionKeys.viewCouncil, pageId, state) &&
                    isLocale('fr') && (
                      <TabPanel
                        value={tab}
                        index={informatonIndex + index + 2}
                        className={classes.mainContainer}
                      >
                        <Council
                          patient={patient}
                          openSnackBar={openSnackBar}
                          setOpenSnackBar={setOpenSnackBar}
                        />
                      </TabPanel>
                    )}
                  {!!canAccess(actionKeys.teleExpertise, pageId, state) &&
                    isLocale('fr') && (
                      <TabPanel
                        value={tab}
                        index={informatonIndex + index + 3}
                        className={classes.mainContainer}
                      >
                        <TeleExpertise files={files} sendData={sendData} />
                      </TabPanel>
                    )}
                </>
              )}
            </Paper>
          </Grid>
          <SnackBar
            show={generalSnackbar.open}
            HandleShow={closeGeneralSnackbar}
            severity={generalSnackbar.type}
            text={I18n.t(generalSnackbar.message)}
          />

          <SnackBar
            show={openSnackBar}
            HandleShow={setOpenSnackBar}
            severity={'success'}
            text={I18n.t(
              'patient.detail.tabTip.programmingWasSuccessfullyCreated'
            )}
          />
        </Grid>
      )
    }
  }

  return (
    <Grid container component="main" className={classes.mainContainer}>
      {isLoading ? <Loading /> : renderBody()}
    </Grid>
  )
}

export default Patient
