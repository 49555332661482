import { Box, Typography, TextField, Button, Grid } from '@mui/material'
import Alert from '@mui/material/Alert'
import { Link } from 'react-router-dom'
import I18n from 'i18n-js'
import useStyles from './style'
import { useHistory } from 'react-router'

const FormForgotPassword = ({
  change,
  submit,
  isActive,
  dataSend,
  message,
  errMsj,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box className={classes.container}>
      <Typography
        variant="subtitle2"
        className={classes.text}
        color="textPrimary"
      >
        {I18n.t('auth.forgetPassSubTitle')}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {I18n.t('auth.forgetPassDescription')}
      </Typography>

      <form onSubmit={submit}>
        <Box mt={2}>
          {!dataSend && (
            <TextField
              variant="standard"
              id="email"
              onChange={change}
              error={message.isemail}
              helperText={message.emailLeyend}
              placeholder={I18n.t('auth.placeholderForgetPassword')}
              // size="small"
              fullWidth
            />
          )}
          {dataSend && (
            <Alert severity="success">
              <Box mb={1}>
                <Typography variant="subtitle2" color="initial">
                  {I18n.t('auth.alert')}
                </Typography>
              </Box>
              {I18n.t('auth.forgetPassSuccessText')}
            </Alert>
          )}
        </Box>

        <Box mt={2}  className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isActive}
            // fullWidth
            className={classes.submit}
          >
            {!dataSend
              ? I18n.t('auth.textEmailSend')
              : I18n.t('auth.textEmailReSend')}
          </Button>

          <Box className={classes.fullWidth} />

          <Button
            variant="text"
            color="primary"
            padding="5px 10px"
            className={classes.return}
            onClick={() => {
              history.push('/')
            }}
          >
            {I18n.t('auth.reutnToLogin')}
          </Button>
        </Box>

        {errMsj && (
          <Box mt={3}>
            <Alert severity="error">{I18n.t('auth.invalidData')}</Alert>
          </Box>
        )}
        {dataSend && (
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
          >
            <Box component="div" mt={6}>
              <Button
                variant="text"
                className={classes.link}
                component={Link}
                to="/"
              >
                {I18n.t('auth.buttonTextBackLogin')}
              </Button>
            </Box>
          </Grid>
        )}
      </form>
    </Box>
  )
}

export default FormForgotPassword
