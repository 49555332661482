import I18n from 'i18n-js'
import { isCharacters, isEmail, isEmpty, isPhoneNumber } from './validator'

const MedicalStaffValidator = (data, currentField, oldErrors) => {
  let errors = {}
  // name
  if (isEmpty(data.name)) errors.name = I18n.t('validators.required')
  else if (!isCharacters(data.name)) errors.name = I18n.t('validators.name')
  else delete errors['name']

  // lastname
  if (isEmpty(data.lastname)) errors.lastname = I18n.t('validators.required')
  else if (!isCharacters(data.lastname))
    errors.lastname = I18n.t('validators.lastname')
  else delete errors['lastname']

  // birth
  if (isEmpty(data.birth)) errors.birth = I18n.t('validators.required')
  else delete errors['birth']

  // Email
  if (isEmpty(data.email)) errors.email = I18n.t('validators.required')
  else if (isEmail(data.email)) errors.email = I18n.t('validators.invalidEmail')
  else delete errors['email']

  // Speciality
  // if (isEmpty(data.id_speciality))
  //   errors.id_speciality = I18n.t('validators.required')
  // else delete errors['id_speciality']

  // Type Staff
  if (isEmpty(data.id_type_staff))
    errors.id_type_staff = I18n.t('validators.required')
  else delete errors['id_type_staff']

  //sex
  if (isEmpty(data.sex === 0 ? 'F' : data.sex))
    errors.sex = I18n.t('validators.required')
  else if (!isEmpty(data.sex) || data.sex === 0) delete errors['sex']

  // phone
  if (isEmpty(data.phone)) errors.phone = I18n.t('validators.required')
  else if (!isPhoneNumber(data.phone))
    errors.phone = I18n.t('validators.invalidPhone')
  else delete errors['phone']
  return {
    errors: currentField
      ? {
          ...oldErrors,
          [currentField]: errors[currentField],
        }
      : {
          ...errors,
        },
    valid: JSON.stringify(errors) == '{}' ? true : false,
  }
}

export default MedicalStaffValidator
