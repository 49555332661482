import { colors } from '../../../../utils'

const useStyles = () => {
  return {
    title: {
      fontSize: '18px',
      fontWeight: '500',
      marginBottom: '10px',
    },
    count: {
      color: colors.danger,
      fontSize: '12px',
      paddingLeft: '10px',
    },
    sendButton: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: '10px',
      '& .MuiButton-root': {
        borderRadius: '0',
      },
    },
    textArea: {
      minHeight: '200px',
      height: '100%',
    },
    containerButtons: {
      width: '100%',
      marginTop: '10px',
      '& .MuiButton-root': {
        textTransform: 'uppercase',
        background: colors.lightPrimary
      },
    },
  }
}

export { useStyles }
