import Typography from '@mui/material/Typography'
import { RadioButtonUnchecked, CheckBoxOutlineBlank } from '@mui/icons-material'
import I18n from 'i18n-js'
import { InputForm } from '..'
import { Smartquestions } from '../../utils'
import useStyles from './style'

const selectTypeOfOption = (question, classes) => {
  const options = Object.values(question.options) || []

  if (question.type == 1) {
    return options.map((option, index) => {
      return (
        <div className={classes.questionContainer} key={index}>
          <RadioButtonUnchecked className={classes.icon} />
          <Typography variant="body2" color="textSecondary">
            {option.title || ''}
          </Typography>
        </div>
      )
    })
  } else if (question.type == 2) {
    return options.map((option, index) => {
      return (
        <div className={classes.questionContainer} key={index}>
          <CheckBoxOutlineBlank className={classes.icon} />
          <Typography variant="body2" color="textSecondary">
            {option.title || ''}
          </Typography>
        </div>
      )
    })
  } else if (question.type == 4 || question.type == 5) {
    return options.map((option, index) => {
      return (
        <div className={classes.questionContainer} key={index}>
          <InputForm
            variant="outlined"
            value={option.title || ''}
            // size="small"
            disabled
          />
        </div>
      )
    })
  }
}

const QuestionViewer = ({ question, number, type = 1 }) => {
  const classes = useStyles()
  const questionName = Smartquestions.find(
    (questionName) => questionName.id === question.type
  )?.name
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.headertitle}
        >
          {I18n.t('cruds.profiles.create.translateLabel')}
        </Typography>
      </div>
      <div
        className={
          question.type < 3 ? classes.bodyContainer : classes.bodyContainerForm
        }
      >
        <Typography variant="subtitle2" color="textSecondary">
          {question.title}
        </Typography>
        {selectTypeOfOption(question, classes)}
      </div>
      <div className={classes.footerContainer}>
        <Typography variant="caption">
          {I18n.t('general.question') +
            ' ' +
            (number + 1) +
            (type === 2 ? `: ${I18n.t(questionName)}` : '')}
        </Typography>
      </div>
    </div>
  )
}

export default QuestionViewer
