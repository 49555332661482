import { Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box, Grid, Typography, Button } from '@mui/material'
import I18n from 'i18n-js'
import ImageWelcome from '../../components/imageWelcome'
import images from '../../utils/Images'
import useStyles from './style'
const Error404 = () => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <Fragment>
      <ImageWelcome />
      <div className={classes.container}>
        <Grid
          container
          spacing={10}
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
        >
          <Grid item>
            <Box mt={9}>
              <Link to="/dashboard">
                <img
                  src={images.Logo}
                  alt={I18n.t('alt.logoIdp')}
                  className={classes.logo}
                />
              </Link>
            </Box>
          </Grid>
          <Grid item className={classes.gridChildren}>
            <Box mt={7} mb={5}>
              <Typography variant="h2" color="textSecondary" align="center">
                {I18n.t('general.error404')}
              </Typography>
            </Box>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              {I18n.t('general.notFound404')}
            </Typography>
          </Grid>
          <Grid item className={classes.gridChildren}>
            <Box mt={4} width={400} mx="auto">
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.button}
                onClick={() => {
                  history.push('/dashboard')
                }}
              >
                {I18n.t('components.main')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}

export default Error404
