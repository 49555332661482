import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('health-conditions')
  return res
}

const detail = async (id) => {
  const res = await GET(`health-conditions/${id}`)
  return res
}

const create = async (body) => {
  const res = await POST('health-conditions', body)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  const res = await PUT(`health-conditions/${id}`, update)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`health-conditions/${id}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
  detail
}
