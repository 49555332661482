import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    height: '130px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignContent: 'flex-start',
      height: '250px',
      padding: '0 16px',
    },
  },
  profile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    // height: '130px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignContent: 'flex-start',
      // height: '250px',
      padding: '0 16px',
    },
  },
  containers: {
    width: '45%',
    minHeight: '56px',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 32px)',
    },
  },
  inputText: {
    alignSelf: 'start',
    background: 'white',
    height: '35px',
    width: '100%',
    display: 'flex',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
        // height: '40px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
    
    // '&.inputLarge': {
    //   width: '95%',
    // },
  },

  phoneContainer: {
    width: '45%',
  },
  inputPhone: {
    alignSelf: 'center',
    background: 'white',
    // height: '35px',
    display: 'flex',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
        // height: '40px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  snackbar: { position: 'fixed', top: '13vh', left: '50vw' },
}))

export default useStyles
