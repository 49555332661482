import { isEmpty, isEmail, isMacAddress, isName, isLicense } from './validator'
import I18n from 'i18n-js'

export const medicalCenterValidator = async (
  center,
  currentField,
  oldErrors,
  edit
) => {
  let errors = {}
  let oldErrorsCopy = { ...oldErrors }
  let currentFieldCopy = currentField
  //name
  if (isEmpty(center.name)) errors.name = I18n.t('validators.required')
  else if (isName(center.name)) errors.name = I18n.t('validators.name')
  else delete errors['name']

  //phone
  if (isEmpty(center.phone)) errors.phone = I18n.t('validators.required')
  else if (center.phone.length < 9)
    errors.phone = I18n.t('validators.invalidPhone')
  else delete errors['phone']

  //address
  if (isEmpty(center.address)) errors.address = I18n.t('validators.required')
  else delete errors['address']

  //city
  if (isEmpty(center.city)) errors.city = I18n.t('validators.required')
  else delete errors['city']

  //id_medical_center_type
  if (isEmpty(center.id_medical_center_type))
    errors.id_medical_center_type = I18n.t('validators.required')
  else delete errors['id_medical_center_type']

  //email
  if (isEmpty(center.email)) errors.email = I18n.t('validators.required')
  else if (isEmail(center.email))
    errors.email = I18n.t('validators.invalidEmail')
  else delete errors['email']

  //licence
  if (!edit) {
    if (isEmpty(center.license)) errors.license = I18n.t('validators.required')
    else if (isLicense(center.license)) errors.license = I18n.t('validators.license')
    else delete errors['license']
  }

  if(center.identifier && !center.identifier_number) {
    errors.identifier_number = I18n.t('validators.required')
    currentFieldCopy = 'identifier_number'
  }else {
    delete oldErrorsCopy['identifier_number']
  }

  if(!center.identifier && center.identifier_number) {
    errors.identifier = I18n.t('validators.required')
    currentFieldCopy = 'identifier'
  } else {
    delete oldErrorsCopy['identifier']
  }
  
  return {
    errors: {
      ...oldErrorsCopy,
      [currentFieldCopy]: errors[currentFieldCopy],
    },
    valid: Object.values(errors) == 0 ? true : false,
  }
}

export const addPersonValidator = async (
  person,
  currentField,
  oldErrors,
  center,
  edit
) => {
  const errors = {}
  //name
  if (isEmpty(person.name)) errors.name = I18n.t('validators.required')
  else delete errors['name']

  //lastname
  if (isEmpty(person.lastname)) errors.lastname = I18n.t('validators.required')
  else delete errors['lastname']

  //phone
  if (isEmpty(person.phone)) errors.phone = I18n.t('validators.required')
  else if (person.phone.length < 8) errors.phone = I18n.t('validators.required')
  else delete errors['phone']

  //email
  if (isEmpty(person.email)) errors.email = I18n.t('validators.required')
  else if (isEmail(person.email))
    errors.email = I18n.t('validators.invalidEmail')
  else if (
    center.persons?.find((perso) => perso.email == person.email) &&
    !edit
  )
    errors.email = I18n.t('validators.emailExist')
  else delete errors['email']

  return {
    errors: {
      ...oldErrors,
      [currentField]: errors[currentField],
    },
    valid: Object.values(errors) == 0 ? true : false,
  }
}

export const addKitValidator = async (kit, currentField, oldErrors) => {
  const errors = {}
  //sensorOne
  if (isEmpty(kit.sensorOne?.mac))
    errors.sensorOne = I18n.t('validators.required')
  if (isMacAddress(kit.sensorOne?.mac))
    errors.sensorOne = I18n.t('validators.invalidMacAddress')
  else delete errors['sensorOne']

  //sensorTwo
  if (kit.sensorTwo != undefined && kit.sensorTwo?.mac.length > 0) {
    if (isMacAddress(kit.sensorTwo?.mac))
      errors.sensorTwo = I18n.t('validators.invalidMacAddress')
    else delete errors['sensorTwo']
  } else {
    delete errors['sensorTwo']
  }
  //sensorThree
  if (kit.sensorThree != undefined && kit.sensorThree?.mac.length > 0) {
    if (isMacAddress(kit.sensorThree?.mac))
      errors.sensorThree = I18n.t('validators.invalidMacAddress')
    else delete errors['sensorThree']
  } else {
    delete errors['sensorThree']
  }
  return {
    errors: {
      ...oldErrors,
      [currentField]: errors[currentField],
    },
    valid: Object.values(errors) == 0 ? true : false,
  }
}
