const useSortForSend = (data, locale) => {
  let name, description, annotations

  if (Object.values(data.traductions).length == 0) {
    name = [{ value: data[locale].name, locale: locale }]

    description = [
      {
        value: data[locale].description,
        locale: locale,
      },
    ]
  } else {
    /* name */
    name = Object.values(data.traductions).map((traduction) => {
      return { value: traduction.name || '', locale: traduction.locale }
    })
    name.push({ value: data[locale].name || '', locale: locale })

    /* description */
    description = Object.values(data.traductions).map((traduction) => {
      return {
        value: traduction.description || '',
        locale: traduction.locale,
      }
    })

    description.push({
      value: data[locale].description,
      locale: locale,
    })

    /* annotations */
    if (
      data[locale].annotations != undefined &&
      Object.values(data[locale].annotations).length > 0
    ) {
      const annotationsId = Object.keys(data[locale].annotations)
      annotations = Object.values(data[locale].annotations).map(
        (annotation, index) => {
          /* recomendation */
          const recomendation = Object.values(data.traductions).map(
            (traduction) => {
              return {
                value:
                  traduction.annotations[annotationsId[index]]?.recomendation ||
                  '',
                locale: traduction.locale,
              }
            }
          )
          recomendation.push({
            value: annotation.recomendation || '',
            locale: locale,
          })

          /* interpretation */
          const interpretation = Object.values(data.traductions).map(
            (traduction) => {
              return {
                value:
                  traduction.annotations[annotationsId[index]]
                    ?.interpretation || '',
                locale: traduction.locale,
              }
            }
          )
          interpretation.push({
            value: annotation.interpretation || '',
            locale: locale,
          })
          return {
            id: annotation.id || null,
            value: annotation.value || '',
            recommendation: recomendation,
            interpretation: interpretation,
          }
        }
      )
    }
  }

  return {
    name: name,
    description: description,
    id_unit: data.id_unit,
    id_datasource: data.id_datasource,
    acronym: data.acronym,
    value_type: data.value_type || null,
    recommendations: annotations || [],
  }
}

export default useSortForSend
