import { useContext } from 'react'
import TextField from '@mui/material/TextField'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { AuthContext } from '../../context/auth'
import I18n from 'i18n-js'
import useStyles from './style'
import { dateLocales } from '../../utils'
import { MobileDatePicker } from '@mui/lab'
import { isFirefox } from '../../hooks/browser'
import { listCountries, transfromLocale } from '../../utils/locale'
import { isPhoneNumber } from '../../validators/validator'
import { Typography } from '@mui/material'

const InputForm = ({
  allDate = null,
  className = '',
  phone = false,
  date = false,
  withMaxLength,
  sizeDate = null,
  errorDate = null,
  maxLength,
  inputProps,
  onAccept = () => {},
  ...rest
}) => {
  const { state } = useContext(AuthContext)
  const classes = useStyles()

  if (!phone && !date) {
    return (
      <TextField
        {...rest}
        size='medium'
        // style={rest.error ? { marginBottom: '24px' } : {}}
        className={
          (!rest.multiline ? classes.input : classes.textArea) + ' ' + className
        }
        defaultCountry={transfromLocale[state.locale] || listCountries.fr}
        inputProps={
          withMaxLength && !inputProps
            ? { maxLength: maxLength ?? 250 }
            : { ...(inputProps ?? {}) }
        }
      />
    )
  } else if (phone) {
    return (
      <MaterialUiPhoneNumber
        {...rest}
        size='medium'
        error={rest?.value ? !isPhoneNumber(rest?.value) : false}
        helperText={
          rest?.value
            ? !isPhoneNumber(rest?.value) && (
                <Typography variant="caption" color="error">
                  {I18n.t('validators.invalidPhone')}
                </Typography>
              )
            : ''
        }
        disableDropdown={isFirefox()}
        style={{ height: 'max-content' }}
        // style={rest.error ? { marginBottom: '24px' } : {}}
        className={classes.input + ' ' + className}
        inputProps={{ maxLength: 250 }}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 14)
        }}
      />
    )
  } else if (date) {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={dateLocales[state.locale]}
      >
        <MobileDatePicker
          toolbarTitle=""
          onAccept={onAccept}
          renderInput={(props) => {
            return (
              <TextField
                {...props}
                error={errorDate === null ? props.error : errorDate}
                size={sizeDate !== null ? sizeDate : ''}
                inputvariant="outlined"
                className={classes.input + ' ' + className}
              />
            )
          }}
          inputFormat={I18n.t('general.dateFormat') || 'dd-MM-yyyy'}
          {...rest}
          size='medium'
          {...(allDate === null && { maxDate: new Date() })}
        />
      </LocalizationProvider>
    )
  }
}

export default InputForm
