import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  notification: {},
  listItem: {
    '&.MuiListItem-root': {
      flexWrap: 'wrap',
    },
  },
  infoTitle: {
    width: '100%',
    '&.MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '.85em',
      },
    },
  },
  infoText: {
    '&.MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '.8em',
      },
    },
  },
  listItemInfoText: {
    '&.MuiListItem-root': {
      paddingTop: 0,
    },
  },
  htmlEditor: {
    '&.MuiTextField-root': {
      width: '100%',
    },
  },
  alertIcon: {
    // marginBottom: '5px',
    '&.MuiPaper-root.MuiAlert-root': {
      // minHeight: 78,
      '& .MuiAlert-icon': {
        padding: '17px 0px',
      }
    },
  },
  labels: {
    fontWeight: 'bold',
  },
  iframe: {
    height: 200,
    width: '166%',
    '-moz-transform': 'scale(.60)',
    '-moz-transform-origin': '0 0',
    '-o-transform': 'scale(.60)',
    '-o-transform-origin': '0 0',
    '-webkit-transform': ' scale(.60)',
    '-webkit-transform-origin': '0 0',
    zoom: '1.8',
    border: '1px solid #888',
    borderRadius: 4,
  }
}))

export default useStyles

