import { isEmpty, isEmail, isPhoneNumber } from './validator'
import { rol } from '../utils'

export const CreateUserValidator = (user) => {
  if (
    !isEmpty(user.name) &&
    !isEmpty(user.lastname) &&
    !isEmpty(user.phone) &&
    !isEmpty(user.birth) &&
    !isEmpty(user.locale) &&
    !isEmpty(user?.email) &&
    !isEmail(user?.email) && 
    (user?.existEmail 
      || user?.existEmail === undefined
    )
  ) {
    if (!isPhoneNumber(user.phone) || isEmail(user.email)) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export const editUserValidator = (user, useRolId, oldEmail = null) => {
  if (useRolId === rol.center) {
    if (
      !isEmpty(user?.name) &&
      !isEmpty(user?.birth) &&
      !isEmpty(user?.phone) &&
      !isEmpty(user.locale)
    ) {
      if (!isPhoneNumber(user.phone)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } else {
    if (
      !isEmpty(user?.name) &&
      !isEmpty(user?.lastname) &&
      !isEmpty(user?.birth) &&
      !isEmpty(user?.phone) &&
      !isEmpty(user.locale) &&
      !isEmpty(user?.email) &&
      !isEmail(user?.email) && 
      (user?.existEmail 
        || user?.existEmail === undefined
        || !user?.existEmail && oldEmail === user?.email
      )
    ) {
      if (!isPhoneNumber(user.phone)) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
}
