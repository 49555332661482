import { useEffect, useState, useContext, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box, Typography, Fade, Button, CircularProgress } from '@mui/material'
import { LockOpen, CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import {
  CrudsBtn,
  FormCreateUser,
  CreateUserPassword,
} from '../../../components'
import ResetPasswordValidator from '../../../validators/resetPasswordValidator'
import { CreateUserValidator } from '../../../validators/createUserValidator'
import { AuthContext } from '../../../context/auth'
import { canAccess } from '../../../router/permissions'
import { pagesKeys, actionKeys } from '../../../utils'
import users from '../../../services/users'
import useStyles from './style'

const CreateUser = () => {
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()

  const [createUser, setCreateUser] = useState({})
  const [showpassword, setShowpassword] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [userPassword, setUserPassword] = useState({})
  const [requirements, setRequirements] = useState(0)
  const [resetErrors, setResetErrors] = useState({})
  const [disabledCreate, setDisabledCreate] = useState(true)
  const [success, setSucces] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChangeuserPassword = (e) => {
    setUserPassword({ ...userPassword, [e.target.id]: e.target.value })
  }

  const handleChangeUserData = async (e) => {
    if (e?.target?.name === 'email') {
      const res = await users.checkEmail(e.target.value)
      setCreateUser({
        ...createUser,
        [e.target.name]: e.target.value,
        existEmail: res.data === null,
      })
    } else {
      if (e.target) {
        setCreateUser({ ...createUser, [e.target.name]: e.target.value })
      } else {
        setCreateUser({ ...createUser, phone: e })
      }
    }
  }
  const handleSubmitUserdata = async () => {
    setLoading(true)
    setDisabledCreate(true)
    createUser.id_rol = 1
    const response = await users.create(Object.assign(createUser, userPassword))
    if (response.data) {
      setSucces(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!canAccess(actionKeys.create, pagesKeys.users, state)) {
      history.push('/dashboard/users')
    } else {
      const info = ResetPasswordValidator(userPassword)
      setRequirements(info.valid)
      if (info.blankSpaces || !info.matches) {
        setResetErrors(info)
      }
      if (
        !info.blankSpaces &&
        info.matches &&
        info.valid === 100 &&
        !info.confirmationEmply &&
        !info.invalidCharacter
      ) {
        setResetErrors(info)
      }
      if (
        info.blankSpaces ||
        !info.matches ||
        info.valid < 100 ||
        info.confirmationEmply ||
        info.invalidCharacter
      ) {
        setResetErrors(info)
      }
      if (
        !CreateUserValidator(createUser) &&
        !info.blankSpaces &&
        info.matches &&
        info.valid === 100 &&
        !info.confirmationEmply &&
        !info.invalidCharacter
      ) {
        setDisabledCreate(false)
      } else {
        setDisabledCreate(true)
      }
    }
  }, [createUser, userPassword])

  const classes = useStyles()
  return (
    <Box mx={6} mt={4}>
      <Fade in={true} timeout={500}>
        <div>
          <Box>
            <Typography variant="body2" color="textSecondary">
              {I18n.t('breadcrumb.indicators')} /{' '}
              <Link to="/dashboard/users" className={classes.textBreadcrum}>
                {I18n.t('breadcrumb.dashboard')} /{' '}
              </Link>
              {I18n.t('cruds.users.create.indicator')}
            </Typography>
          </Box>
          <Box className={classes.headerContainer}>
            <Box className={classes.titleContainer}>
              <Box>
                <Typography
                  variant="h6"
                  color="initial"
                  className={classes.headerTitle}
                >
                  {I18n.t('cruds.users.create.titlePage')}
                </Typography>
              </Box>

              <Typography
                variant="h5"
                color="primary"
                className={classes.headerTitle}
              >
                {(!createUser?.name || createUser?.name === '') &&
                  (!createUser?.lastname || createUser?.lastname === '') &&
                  I18n.t('cruds.users.create.indicator')}

                {createUser?.name &&
                  createUser.name !== '' &&
                  `${createUser?.name ? createUser.name : ''} `}
                {createUser?.lastname &&
                  createUser.lastname !== '' &&
                  `${createUser?.lastname ? createUser.lastname : ''}`}
              </Typography>
            </Box>
          </Box>

          {loading ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <>
              <Box my={3}>
                {!success ? (
                  <Box className={classes.bodyContainer}>
                    <div className={classes.editUserContainer}>
                      <Box className={classes.editUserHeader}>
                        <Typography variant="h6" color="primary">
                          {I18n.t('cruds.users.create.userInfoTitle')}
                        </Typography>
                      </Box>
                      <FormCreateUser
                        user={createUser}
                        change={handleChangeUserData}
                        setUser={setCreateUser}
                      />
                    </div>
                    <div className={classes.resetPasswordContainer}>
                      <Box className={classes.resetPasswordHeader}>
                        <LockOpen className={classes.iconLock} />
                        <Typography variant="h6" color="primary">
                          {I18n.t('cruds.users.create.userPasswordTitle')}
                        </Typography>
                      </Box>

                      <Box className={classes.resetPasswordBody}>
                        <Box>
                          <CreateUserPassword
                            showpassword={showpassword}
                            setShowpassword={setShowpassword}
                            showConfirm={showConfirm}
                            setShowConfirm={setShowConfirm}
                            change={handleChangeuserPassword}
                            requirements={requirements}
                            errors={resetErrors}
                          />
                        </Box>
                      </Box>
                    </div>
                  </Box>
                ) : (
                  <Fade in={success} timeout={500}>
                    <Box>
                      <Box className={classes.finishContainer}>
                        <CheckCircle className={classes.chekCircle} />
                        <Typography variant="h5" color="textPrimary">
                          {I18n.t('components.success')}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('cruds.users.create.created')}
                        </Typography>
                      </Box>
                    </Box>
                  </Fade>
                )}

                <Box
                  className={
                    !success
                      ? classes.buttonsContainer
                      : classes.buttonsContainerEnd
                  }
                >
                  {!success ? (
                    <Fragment>
                      <Button
                        variant="text"
                        color="secondary"
                        className={classes.buttonCancel}
                        component={Link}
                        to="/dashboard/users"
                      >
                        {I18n.t('components.cancel')}
                      </Button>
                      <CrudsBtn
                        className="endButton"
                        disabled={disabledCreate}
                        click={handleSubmitUserdata}
                        text={I18n.t('components.increment')}
                      />
                    </Fragment>
                  ) : (
                    <CrudsBtn
                      text={I18n.t('cruds.users.return')}
                      component={Link}
                      to="/dashboard/users"
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </div>
      </Fade>
    </Box>
  )
}

export default CreateUser
