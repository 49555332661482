import { useEffect, useState } from 'react'
import I18n from 'i18n-js'
import { InputForm } from '..'

import useStyles from './style'
const OptionTranslate = ({
  id,
  type,
  setQuestionTranslation,
  questionTranslation,
  questionId,
}) => {
  const [option, setOption] = useState(
    questionTranslation.options[id] || { title: '' }
  )

  const handleChange = (e) => {
    setOption({ [e.target.name]: e.target.value })
  }
  useEffect(() => {
    setOption(questionTranslation.options[id] || { title: '' })
  }, [questionId])
  useEffect(() => {
    setQuestionTranslation({
      ...questionTranslation,
      options: {
        ...questionTranslation.options,
        [id]: option,
      },
    })
  }, [option])
  const classes = useStyles()
  return (
    <InputForm
      // size="small"
      color="secondary"
      name="title"
      value={option.title}
      onChange={handleChange}
      label={
        (type == 4 || type == 5)
          ? I18n.t('cruds.questionaries.label')
          : I18n.t('cruds.questionaries.option') + ' ' + (id + 1)
      }
      variant="outlined"
      className={classes.input}
    />
  )
}

export default OptionTranslate
