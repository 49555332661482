const actionKeys = {
  view: 1,
  list: 2,
  create: 3,
  edit: 4,
  delete: 5,
  detail: 6,
  refresh: 7,
  sendReport: 8,
  viewTabSmartPredict: 9,
  viewTabSmartDetect: 10,
  editEmail: 11,
  createObservation: 12,
  viewCouncil: 13,
  teleExpertise: 14,
}

export default actionKeys
