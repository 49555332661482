import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  input: {
    width: '60%',
    "&.MuiFormControl-root": {
    marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: { width: '100%' },
  },
}))
export default useStyles
