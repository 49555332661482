import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles({
  cancel: {
    '&.MuiButton-root': {
      color: colors.disabled,
      border: colors.disabled + ' solid 1px',
      borderRadius: '2px',
      transition: '.2s',
    },
  },
  show: {
    '&.MuiIconButton-root': {
      color: colors.blueInput
    }
  },
  toggleButton: {
    '&.MuiToggleButtonGroup-root': {
      marginTop: '-8px',
      flex: 2,
      '& .MuiToggleButton-root.Mui-selected': {
        color: colors.white,
        backgroundColor: colors.blueInput
      },
      '& .MuiToggleButton-root': {
        color: colors.blueInput,
        borderColor: colors.blueInput
      }
    },
  },
  fileContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    alignItems: 'center',
  },
  wFull: {
    width: '100%',
  },
  wFileImage: {
    width: 'calc(100% - 47px)',
  },
  submit: {
    '&.MuiButton-root': {
      borderRadius: '2px',
    },
  },
  loadButton: {
    '&.MuiButton-root': {
      borderRadius: '2px',
    },
  },
  form: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  key: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .key': {
      flex: 8
    }
  },
  actionButtons: {
    '&.MuiDialogActions-root': {
      padding: 15,
    },
  },
  errorMsj: {
    '&.MuiTypography-root': {
      height: 20,
      display: 'block',
    },
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    ['& .MuiFormControl-root']: {
      // margin: '4px 0',
    },
  },
  iconForm: {
    display: 'flex',
    columnGap: '8px',
  },
  sections: {
    '&:first-child': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '8px 0',
    },
  },
  close: {
    position: 'absolute',
    right: 2,
    top: 1,
  },
  dialogContent: {
    '&.MuiDialogContent-root': {
      padding: '10px 25px',
      '& .MuiFormControl-root': {
        marginTop: '5px',
      },
    },
  },
})

export default useStyles
