import { MenuItem, TextField, Autocomplete } from '@mui/material'
import I18n from 'i18n-js'
import useStyles from './style'
const SelectForm = ({
  options = [],
  isLocalData = false,
  className = null,
  multiple = false,
  ...rest
}) => {
  
  const classes = useStyles()
  if (!multiple) {
    return (
      <TextField {...rest} select className={classes.input + ' ' + className}>
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.id}>
              {isLocalData ? I18n.t(option.name) : option.name}
            </MenuItem>
          )
        })}
      </TextField>
    )
  } else {
    return (
      <Autocomplete
        {...rest}
        className={className}
        multiple
        options={options || []}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth={rest.fullWidth}
            label={rest.label}
            variant="outlined"
            size={rest.size}
            className={classes.multiple + ' ' + className}
            color="secondary"
          />
        )}
      />
    )
  }
}

export default SelectForm
