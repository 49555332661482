import { useEffect, useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box, Typography, Fade } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import {
  healthConditions,
  country,
  medicalStaff,
  patients,
  observations,
} from '../../../services'
import { userMe } from '../../../services/auth'
import { canAccess } from '../../../router/permissions'
import Step1 from '../form/steps/step1'
import Step2 from '../form/steps/step2'
import { StepperCreate, CrudsBtn, Loading } from '../../../components'
import { validatorPatient } from '../../../validators/patientValidator'
import { pagesKeys, actionKeys } from '../../../utils'
import useStyles from './style'
import { detailCommunities, zipCodeCommunity } from '../../../services/states'
import {
  isNumber,
  numbersAndCharacters,
} from '../../../validators/validator'
import { handleCountryBirth } from '../methods'
import { format } from 'date-fns'
import { reverseDate } from '../../../utils/date'
import { extraFieldPt } from '../list'
import { toBase64 } from '../../../hooks/base64'
import { franceValues, mainValues, spainValues } from '../constants'
import { listCountries, usersWithPermissions } from '../../../utils/locale'
import { idByLocale } from '../../../utils/locales'

const CreatePatient = () => {
  const classes = useStyles()
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  //loading
  const [loading, setLoading] = useState(true)
  const [loadZipCode, setLoadZipCode] = useState(false)
  //data
  const [healthCondition, setHealthConditions] = useState([])
  const [countries, setCountries] = useState([])
  const [medicalStaffsDoctors, setMedicalstaffsDoctors] = useState([])
  const [patient, setPatient] = useState({})
  //validations
  const [currentField, setCurrentField] = useState('')
  const [errors, setErrors] = useState({
    ...mainValues,
    ...usersWithPermissions([listCountries.fr]) && franceValues,
    ...usersWithPermissions([listCountries.es]) && spainValues,
  })
  const [newFields, setNewFields] = useState(null)
  //stepper
  const [currentStep, setCurrentStep] = useState(0)
  
  const [patientLocale, setPatientLocale] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const isSameCountryOfTheDoctor = patientLocale == state.locale
  //methods
  const handleNext = () => {
    if (currentStep == 1) {
      SendPatient()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }
  const handleBack = () => {
    setCurrentStep(currentStep - 1)
  }
  const handleChangePatient = async ({ target }) => {
    const { name, value } = target
    if (name == 'country_birth') {
      const country = countries.find(item => item.id == value)
      setPatientLocale(country.locale)
    }
    setCurrentField(name)

    if (name === 'photo') {
      if (value) {
        toBase64(value).then((base64) => {
          setPatient(prev => ({ ...prev, [name]: base64 }))
        })
      } else {
        setPatient(prev => ({ ...prev, [name]: null }))
      }
    } else if (name === 'state_id') {
      if (value) {
        setPatient(prev => ({
          ...prev,
          [name]: value,
          zip_code: null,
          community_id: null,
        }))
        let communities = await detailCommunities(value.id)

        communities = await communities?.data?.commnunities?.sort(
          (a, b) => a.name - b.name
        )

        setNewFields({
          ...newFields,
          communities: communities,
        })
      } else {
        setNewFields({
          ...newFields,
          communities: undefined,
        })
        setPatient(prev => ({
          ...prev,
          [name]: null,
          zip_code: null,
          community_id: null,
        }))
        let error = { ...errors }

        delete error.community_id

        setErrors({ ...error, zip_code: undefined })
      }
    } else if (name === 'community_id') {
      setPatient(prev => ({
        ...prev,
        [name]: value,
        zip_code: value?.zip_code ?? '',
      }))
      setErrors({ ...errors, zip_code: undefined })
    } else if (name === 'zip_code') {
      if ((isNumber(value) && value.length <= 5) || value === '') {
        searchZipCode(value)
      }
    } else if (extraFieldPt.includes(name)) {
      if (
        (isNumber(value) &&
          ((name === 'cc' && value.length <= 8) ||
            (name === 'nif' && value.length <= 9) ||
            (name === 'niss' && value.length <= 11) ||
            (name === 'sns' && value.length <= 9) ||
            (name === 'nuss' && value.length <= 12))) ||
        (name === 'dni' && value.length <= 9 && !numbersAndCharacters(value))
      ) {
        setPatient(prev => ({
          ...prev,
          [name]: value,
        }))
      } else if (value === '') {
        setPatient(prev => ({
          ...prev,
          [name]: '',
        }))
      }
    } else if (name === 'phone') {
      if (value.length < 16) setPatient(prev => ({ ...prev, [name]: value }))
    } else {
      let newPatient = {
        [name]: name === 'email' ? value.toLowerCase() : value,
      }

      setPatient(prev => ({ ...prev, ...newPatient }))
    }
  }

  const searchZipCode = async (value) => {
    let errorsCopy = { ...errors }
    let patientCopy = { ...patient, zip_code: value }
    setPatient(patientCopy)

    if (value.length === 5) {
      setLoadZipCode(true)
      const zipCode = newFields?.communities?.find(
        (item) => `${item?.zip_code}` === value
      )

      if (zipCode) {
        delete errorsCopy.state_id
        delete errorsCopy.community_id
        delete errorsCopy.zip_code

        patientCopy = {
          ...patient,
          zip_code: value,
          community_id: zipCode,
        }
      } else if (!patient.state_id?.id) {
        delete errorsCopy.zip_code
        setErrors(errorsCopy)

        const zipCodeCommunities = await zipCodeCommunity(value)

        if (zipCodeCommunities.data?.commnunities?.length) {
          let zipcodeData = zipCodeCommunities.data?.commnunities[0]

          delete errorsCopy.state_id
          delete errorsCopy.community_id
          patientCopy = {
            ...patient,
            state_id: newFields.states.find(
              (item) => item.id === zipcodeData.state_id
            ),
            community_id: zipcodeData,
            zip_code: value,
          }

          const communitiesList = await detailCommunities(zipcodeData.state_id)

          setNewFields({
            ...newFields,
            communities: communitiesList.data?.commnunities,
          })
        } else errorsCopy.zip_code = I18n.t('validators.zipCode')
      } else errorsCopy.zip_code = I18n.t('validators.zipCodeNotAssociate')
      setLoadZipCode(false)
    } else if (value.length > 0)
      errorsCopy.zip_code = I18n.t('validators.invalidZipCode')
    else errorsCopy.zip_code = undefined

    setErrors(errorsCopy)
    setPatient(patientCopy)
  }
  const SendPatient = async () => {
    setLoading(true)

    let newPatient = { ...patient }
    if (!isSameCountryOfTheDoctor) {
      delete newPatient.state_id
      delete newPatient.community_id
      delete newPatient.zip_code
      delete newPatient.SSN
      delete newPatient.cc
      delete newPatient.expire_date
      delete newPatient.nif
      delete newPatient.niss
      delete newPatient.sns
      delete newPatient.comments
      delete newPatient.dni
      delete newPatient.nuss
    }
    if (!newPatient.photo) newPatient.photo = null

    if (newPatient.state_id && newPatient.state_id !== null) {
      newPatient = {
        ...newPatient,
        state_id: newPatient.state_id.id,
      }
    }

    if (newPatient?.expire_date && newPatient?.expire_date !== null) {
      newPatient = {
        ...newPatient,
        expire_date: reverseDate(
          format(new Date(newPatient.expire_date), I18n.t('general.dateFormat'))
        ),
      }
    }

    if (newPatient?.community_id && newPatient?.community_id !== null) {
      newPatient = {
        ...newPatient,
        community_id: newPatient.community_id.id,
      }
    }

    if (newPatient?.zip_code?.length < 5) newPatient.zip_code = ''

    const createPatient = await patients.create({ ...newPatient })

    if (createPatient.data) {
      const idPatient = createPatient.data.patient.id

      if (
        patient.id_medical_center !== null &&
        patient.id_medical_center !== undefined
      ) {
        await patients.addCenter(idPatient, patient.id_medical_center)
      }

      if (
        patient.main_doctor !== undefined &&
        patient.main_doctor?.length > 0
      ) {
        await patients.addMedicalStaff(idPatient, {
          main_doctor: patient.main_doctor[0],
        })
      }

      if (
        patient.observation !== undefined &&
        patient.observation?.length > 0
      ) {
        let body = {
          patient_id: idPatient,
          observations: patient.observation,
        }

        await observations.create(body)
      }

      await patients.associateAll(idPatient)

      if (patient.id_health_conditions?.length > 0) {
        await patients.addHealthConditions(
          idPatient,
          patient.id_health_conditions
        )
      }

      setCurrentStep(currentStep + 1)

    }
    setLoading(false)
  }

  //fetch
  const getSelects = async () => {
    handleCountryBirth(state.locale).then((response) => {
      setNewFields(response)
    })

    healthConditions.list().then((response) => {
      let arrayHealthConditions = []

      response.data.healthConditions.forEach((element) => {
        element.children.forEach((item) => {
          arrayHealthConditions.push(item)
        })
      })

      setHealthConditions(arrayHealthConditions)
    })

    country?.list().then((response) => {
      const localeId = idByLocale[state.locale]
      const countryFound = response?.data?.countries.find(item => item.id == localeId)
      setPatient(prev => ({
        ...prev,
        country_birth: countryFound?.id
      }))
      setPatientLocale(countryFound?.locale)
      setCountries(response?.data?.countries)
    })

    const responseMedicalStaffDoctors = await medicalStaff.doctors()

    setMedicalstaffsDoctors(responseMedicalStaffDoctors)

    const associateDoctor = responseMedicalStaffDoctors?.find(
      (item) => item.email === state.email
    )

    const me = await userMe()
    if (me.data) {
      setPatient(prev => ({
        ...prev,
        id_medical_center: me.data.user.id_medical_center,
        main_doctor: associateDoctor ? [associateDoctor?.id] : [],
      }))
    }
  }

  useEffect(async () => {
    if (!canAccess(actionKeys.create, pagesKeys.patients, state)) {
      return history.push('/dashboard')
    } else {
      await getSelects()
      setLoading(false)
    }
  }, [])

  // Validations
  useEffect(async () => {
    let zip_code = errors.zip_code
    const errorsAux = await validatorPatient(patient, state.locale, true, isSameCountryOfTheDoctor)
    setDisabled(Object.values({ ...errorsAux, zip_code })?.some(item => item))
    if (currentField)
      setErrors(prev => ({
        ...prev,
        [currentField]: errorsAux[currentField],
        ...({ zip_code } ?? {}),
      }))
  }, [patient])
  const steps = [
    {
      label: 'cruds.patients.labels.stepOne',
      step: 'cruds.patients.steps.stepOne',
    },
    {
      step: 'cruds.patients.steps.stepTwo',
      label: 'cruds.patients.labels.stepTwo',
    },
  ]
  return (
    <Box p={4}>
      {/* breadcrumb */}
      <Typography variant="body2" color="textSecondary">
        {I18n.t('breadcrumb.patient')} /{' '}
        <Link to="/dashboard/patients" className={classes.textBreadcrum}>
          {I18n.t('breadcrumb.dashboard')} /{' '}
        </Link>
        {I18n.t('cruds.patients.create.indicator')}
      </Typography>

      <Box className={classes.header}>
        {/* title */}
        <Box className={classes.title}>
          <Typography variant="h5" >
            {I18n.t('cruds.patients.create.title')}
          </Typography>
          <Typography variant="h6" color="primary">
            {patient.name || patient.lastname
              ? (patient.name || '') + ' ' + (patient.lastname || '')
              : I18n.t('cruds.patients.create.indicator')}
          </Typography>
        </Box>
        {/* stepper */}
        <Box className={classes.stepper}>
          <StepperCreate activeStep={currentStep} steps={steps} />
        </Box>
      </Box>

      <Box className={classes.root}>
        {loading ? (
          <Loading />
        ) : (
          <Fade in={!loading} timeout={500}>
            <Box>
              <Box className={classes.body}>
                <Fade in={currentStep == 0} timeout={500}>
                  <Box>
                    {currentStep == 0 && (
                      <Step1
                        removeEmail
                        setDisabled={null}
                        healthConditions={healthCondition}
                        countries={countries}
                        onChange={handleChangePatient}
                        patient={patient}
                        errors={errors}
                        setErrors={setErrors}
                        field={newFields}
                        loading={loadZipCode}
                        isSameCountryOfTheDoctor={isSameCountryOfTheDoctor}
                      />
                    )}
                  </Box>
                </Fade>
                <Fade in={currentStep == 1} timeout={500}>
                  <Box>
                    {currentStep == 1 && (
                      <Step2
                        handleForm={handleChangePatient}
                        form={patient}
                        errors={errors}
                        onChange={handleChangePatient}
                        doctors={medicalStaffsDoctors}
                      />
                    )}
                  </Box>
                </Fade>
                <Fade in={currentStep == 2} timeout={500}>
                  <Box>
                    {currentStep == 2 && (
                      <Box className={classes.finishContainer}>
                        <CheckCircle className={classes.chekCircle} />
                        <Typography variant="h5" color="textPrimary">
                          {I18n.t('components.success')}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('cruds.patients.create.created')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Fade>
              </Box>

              <Box
                className={
                  classes.footer +
                  ' ' +
                  (currentStep < 2
                    ? classes.justifyBetween
                    : classes.justifycenter)
                }
              >
                {currentStep < 2 && (
                  <CrudsBtn
                    text={I18n.t('components.cancel')}
                    variant="text"
                    component={Link}
                    to="/dashboard/patients"
                  />
                )}

                <Box className={classes.actions}>
                  {currentStep > 0 && currentStep < 2 && (
                    <CrudsBtn
                      id="back"
                      text={I18n.t('patient.back')}
                      variant="outlined"
                      click={handleBack}
                    />
                  )}
                  <CrudsBtn
                    size="large"
                    text={
                      currentStep < 2
                        ? I18n.t('patient.next')
                        : I18n.t('cruds.patients.return')
                    }
                    click={
                      currentStep < 2
                        ? handleNext
                        : () => history.push('/dashboard/patients')
                    }
                    disabled={!!disabled}
                  />
                </Box>
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    </Box>
  )
}

export default CreatePatient
