import { makeStyles } from '@mui/styles'
import { colors } from '../../../../../../../utils'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 16px',
    margin: '16px 0',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputsHeader: {
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '49%',
    },
  },
  bodyContainer: {
    margin: '16px 0',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  iconFooter: {
    marginLeft: '8px',
    color: colors.danger,
  },
}))

export default useStyles
