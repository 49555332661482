import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import styles from './styles'
import I18n from 'i18n-js'
import { Icons } from '../../../../../utils'
import patientService from '../../../../../services/patients'
import { useState } from 'react'

const SimpleMassageCards = ({
  fav,
  item,
  handleChange,
  value,
  orderData,
  searchItems,
}) => {
  const [loadingStar, setLoadingStar] = useState(false)
  const handleFav = async (id) => {
    const res = await patientService.changeFavorite({ conseil_id: id })

    if (res?.data) {
      const resAux = fav ? await orderData() : await searchItems()
      if (resAux) setLoadingStar(false)
    }
  }
  return (
    <Paper elevation={3} sx={styles.message.stylesPaper}>
      <Typography sx={{ fontWeight: 600, fontSize: '17px', width: '100%' }}>
        {item.title}
      </Typography>
      <Grid container sx={{ padding: '16px 0px' }}>
        <Grid item xs={2} lg={2}>
          <Checkbox
            onChange={() => handleChange(item.id)}
            checked={value.includes(item.id)}
          />
        </Grid>
        <Grid item xs={9} lg={9}>
          <Box>
            <Typography>{item?.content + ' '}</Typography>
            {item?.url && (
              <Typography>
                {I18n.t('patient.detail.tabTip.tapTwo.webLink') +
                  ' ' +
                  item?.url}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          {loadingStar ? (
            <CircularProgress size={20} sx={{ margin: '10px', padding: 0 }} />
          ) : (
            <IconButton
              onClick={() => {
                handleFav(item?.id)
                setLoadingStar(true)
              }}
            >
              {item?.favorite ? Icons('star') : Icons('starBorder')}
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SimpleMassageCards
