import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Snackbar,
  IconButton,
  Icon,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import I18n from 'i18n-js'
import useStyles from './style'
import { Icons } from '../../utils'
import { LoadingButton } from '@mui/lab'

const FormLogin = ({
  change,
  submit,
  message,
  errMsj,
  errors,
  resetLogin,
  sendEmail,
  openAlert,
  setOpenAlert,
  disabled,
  textError,
  loading,
}) => {
  const classes = useStyles()
  const [password, setPassword] = useState({
    type: 'password',
    icon: 'show',
  })
  const handleVissibility = () => {
    switch (password.type) {
      case 'password':
        setPassword({ type: 'text', icon: 'hidden' })
        break
      default:
        setPassword({ type: 'password', icon: 'show' })
        break
    }
  }

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        {!errors.error04 && !errors.error05 && (
          <Fragment>
            <form onSubmit={submit} className={classes.formLogin}>
              <Grid item xs={12}>
                <Box mt={6}>
                  <TextField
                    id="email"
                    variant="standard"
                    onChange={change}
                    placeholder={I18n.t('auth.placeholderUsername')}
                    error={message.isemail}
                    helperText={message.emailLeyend}
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box mt={2} mb={3} className={classes.inputPassword}>
                  <TextField
                    type={password.type}
                    id="password"
                    variant="standard"
                    onChange={change}
                    placeholder={I18n.t('auth.placeholderPassword')}
                    fullWidth
                    size="medium"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          className={classes.icon}
                          onClick={() => handleVissibility()}
                        >
                          {Icons(password.icon)}
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    type="submit"
                    className={classes.submit}
                    disabled={disabled}
                    padding="10px 10px"
                  >
                    {I18n.t('auth.signInButton')}
                    
                    {!loading && (
                      <Icon className={classes.arrowIcon}>
                        {Icons('arrow_forward')}
                      </Icon>
                    )}
                  </LoadingButton>
                </Grid>
                {errMsj && (
                  <Grid item>
                    <Box mt={2}>
                      <Alert severity="error">{I18n.t(textError)}</Alert>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Box component="div" mt={6}>
                    <Button
                      variant="text"
                      className={classes.link}
                      component={Link}
                      to="/forgotpassword"
                    >
                      {I18n.t('auth.forgetPassSubTitle')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Fragment>
        )}
        {errors.error04 && !errors.error05 && (
          <Fragment>
            <Grid item>
              <Box mt={6}>
                <Alert severity="warning">
                  <Box mb={1}>
                    <Typography variant="subtitle2" color="initial">
                      {I18n.t('auth.alert')}
                    </Typography>
                  </Box>
                  {I18n.t('auth.textNeedChangePassword')}
                </Alert>
              </Box>
            </Grid>
            <Grid item className={classes.error04Item}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                component={Link}
                to="/forgotpassword"
                fullWidth
              >
                {I18n.t('auth.textLinkChangePassword')}
              </Button>
            </Grid>
          </Fragment>
        )}

        {errors.error05 && (
          <Fragment>
            <Snackbar
              open={openAlert.alert}
              autoHideDuration={2000}
              onClose={() => {
                setOpenAlert(false)
              }}
            >
              <Alert severity="success">{I18n.t('auth.sendLinkSuccess')}</Alert>
            </Snackbar>
            <Grid item>
              <Box mt={6}>
                <Typography variant="body2" color="initial">
                  {I18n.t('auth.textGenerateNewPassword')}
                </Typography>
              </Box>
            </Grid>
            <Grid item className={classes.error04Item}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={sendEmail}
                disabled={openAlert.disabled}
                fullWidth
              >
                {I18n.t('auth.textEmailReSend')}
              </Button>
            </Grid>
            <Grid item>
              <Box component="div" mt={6}>
                <Button
                  variant="text"
                  className={classes.link}
                  onClick={resetLogin}
                >
                  {I18n.t('auth.buttonTextBackLogin')}
                </Button>
              </Box>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}

export default FormLogin
