import BalancePrimary from '../assets/images/balancePrimary.png'
import BalanceDanger from '../assets/images/balanceDanger.png'
import BalanceCare from '../assets/images/balanceCare.png'
import BalanceWarning from '../assets/images/balanceWarning.png'
import BalanceSuccess from '../assets/images/balanceSuccess.png'
import BalanceDisabled from '../assets/images/balanceDisabled.png'
import LoginImg from '../assets/images/loginImg.jpg'
import Logo from '../assets/images/LOGO_IDP_LOGIN.svg'
import LogoOpen from '../assets/images/IDP-sante-logo.svg'
import LogoClose from '../assets/images/IsotipoIDP.svg'
import Portugal from '../assets/images/portugal.png'
import France from '../assets/images/france.png'
import UK from '../assets/images/unitedKing.png'
import background from '../assets/images/background.png'
import Spanish from '../assets/images/spanish.png'
import Colombian from '../assets/images/colombian.png'
import Arrow from '../assets/images/arrow.svg'
import Hand from '../assets/images/icon1.svg'
import Active from '../assets/images/active.svg'
import Inactive from '../assets/images/inactive.svg'
import ClockBattery from '../assets/indicators/homePredict/clockBattery.svg'
import HomeBack from '../assets/indicators/homePredict/HomeBack.svg'
import HomeOut from '../assets/indicators/homePredict/homeOut.png'
import HomeTemperature from '../assets/indicators/homePredict/HomeTemperature.svg'
import Profile from '../assets/images/profile.png'
import Ind1 from '../assets/images/timeline/Pos1.svg'
import Ind2 from '../assets/images/timeline/Pos2.svg'
import Ind3 from '../assets/images/timeline/Pos3.svg'
import Ind4 from '../assets/images/timeline/Pos4.svg'
import Ind5 from '../assets/images/timeline/Pos5.svg'
import Ind6 from '../assets/images/timeline/Pos6.svg'
import Ind7 from '../assets/images/timeline/Pos8.svg'
import Ind8 from '../assets/images/timeline/Pos7.svg'
import SensorXSense from '../assets/images/sensorx-sense.png'
import StadisticsStand from '../assets/images/stadisticsStand.svg'
import StadisticsFall from '../assets/images/stadisticsFall.svg'
import StadisticsArmsUp from '../assets/images/stadisticsArmsUp.svg'
import IndicatorDiamond from '../assets/images/indicatorDiamond.png'
import IndicatorTriangle from '../assets/images/indicatorTriangle.png'
import bodyTemperature from '../assets/images/icons-physio/temp.svg'
import imc from '../assets/images/icons-physio/imc.svg'
import pression from '../assets/images/icons-physio/pression.svg'
import rythmeCardiaque from '../assets/images/icons-physio/bpm.svg'
import saturation from '../assets/images/icons-physio/saturation.svg'
import punch from '../assets/images/grip-test-score.svg'
import levelPatient from '../assets/images/physical-activity-level.svg'
import balance from '../assets/images/balance.svg'
import tired from '../assets/images/tired.svg'
import patientProfile from '../assets/images/patient-profile.png'
import cardiacTest from '../assets/images/icons-physio/cardiacTest.svg'
import lungTest from '../assets/images/icons-physio/lungTest.svg'
import extentTest from '../assets/images/icons-physio/extentTest.svg'
import graphic from '../assets/images/graphic.png'
import biodyXpert3 from '../assets/images/BIODY-XPERT-3-.png'

import bpmGray from '../assets/images/icons-physio/bpm-gray.svg'
import cardiacGray from '../assets/images/icons-physio/cardiac-gray.svg'
import imgGray from '../assets/images/icons-physio/imc-gray.svg'
import lungGray from '../assets/images/icons-physio/lung-gray.svg'
import mmhgGray from '../assets/images/icons-physio/mmhg-gray.svg'
import spo2Gray from '../assets/images/icons-physio/spo2-gray.svg'
import tempGray from '../assets/images/icons-physio/temp-gray.svg'
import wideRangeGray from '../assets/images/icons-physio/wide-range-gray.svg'
import leftEarGray from '../assets/images/icons-physio/left-ear.svg'
import leftEar from '../assets/images/icons-physio/left.svg'
import rightEarGray from '../assets/images/icons-physio/right-ear.svg'
import rightEar from '../assets/images/icons-physio/right.svg'

const images = {
  background,
  rightEar,
  leftEar,
  leftEarGray,
  rightEarGray,
  graphic,
  bpmGray,
  cardiacGray,
  biodyXpert3,
  imgGray,
  lungGray,
  mmhgGray,
  spo2Gray,
  tempGray,
  wideRangeGray,
  bodyTemperature,
  imc,
  punch,
  levelPatient,
  balance,
  tired,
  pression,
  rythmeCardiaque,
  saturation,
  IndicatorDiamond,
  IndicatorTriangle,
  StadisticsStand,
  StadisticsFall,
  StadisticsArmsUp,
  BalancePrimary,
  BalanceDanger,
  BalanceCare,
  BalanceWarning,
  BalanceSuccess,
  BalanceDisabled,
  LoginImg,
  Logo,
  LogoOpen,
  LogoClose,
  Portugal,
  France,
  Spanish,
  Colombian,
  Arrow,
  Hand,
  Active,
  Inactive,
  ClockBattery,
  HomeBack,
  HomeTemperature,
  Profile,
  HomeOut,
  Ind1,
  Ind2,
  Ind3,
  Ind4,
  Ind5,
  Ind6,
  Ind7,
  Ind8,
  SensorXSense,
  patientProfile,
  cardiacTest,
  lungTest,
  extentTest,
  UK
}

export default images

export const imagesFormat = ['.jpg', '.png', '.jpeg']

export const imagesType = ['image/jpg', 'image/png', 'image/jpeg']
