import { format } from 'date-fns'
import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async ({ I18n }) => {
  const res = await GET('patients-smart-predict-vdsi')
  const orderData = res?.data?.map((item /* index */) => {
    return {
      id: item?.id,
      name: item?.name,
      gender: item?.gender,
      age: item?.age || '',
      kforce: item?.k_score,
      system_status: item?.system_status,
      fragilityScore: item?.smart_predict?.fragility_score || null,
      score: item?.smart_predict?.fragility_score?.status
        ? I18n.t(
            `general.fragilityScore.${item?.smart_predict?.fragility_score?.status}`
          )
        : '',
      birthday: item?.birth
        ? format(
            new Date(`${item?.birth}T00:00:00`),
            I18n.t('general.dateFormat')
          )
        : '',
      sex: item?.gender ?? '',
      address: item?.address ?? '',
      phone: item?.phone ?? '',
      dateEnd: item?.smart_predict?.date_tug
        ? format(
            new Date(item?.smart_predict?.date_tug),
            I18n.t('general.dateTimeFormat')
          )
        : '',
      medical_staff: `${item?.smart_predict?.medical_staff?.name ?? ''} ${
        item?.smart_predict?.medical_staff?.lastname ?? ''
      }`,
      value: item?.vdsi_questions?.[0]?.answer?.value ?? '',
      vdsi: item?.vdsi_questions,
      fragilityIndicators:
        item?.smart_predict?.fragility_indicators?.map((r) => {
          return {
            id: r?.id,
            name: r?.name,
            description: r?.description,
            value: r?.value,
            date: r?.date,
            status: r?.status,
          }
        }) || [],
      homePredictAlerts: item?.smart_predict?.home_predict_alerts || {
        status: '',
        value: '',
      },
      nextAppointmentDate: item?.smart_predict?.next_appointment_date || {
        status: '',
        value: '',
        date: '',
      },
      tugRisque: item?.smart_predict?.tug_risque || null,
      tugTime: item?.smart_predict?.tug_time || '',
      dateTimeTug: item?.smart_predict?.date_tug || '',
      isTugDocumentSigned: item?.isTugDocumentSigned,
    }
  })
  return orderData
}

const detail = async (id) => {
  const res = await GET(`patients/${id}`)
  return res
}

const create = async (body) => {
  const res = await POST('patients', body)
  return res
}

const update = async (id, body) => {
  const res = await PUT(`patients/${id}`, body)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`patients/${id}`)
  return res
}

const changeStatus = async (id, body) => {
  const res = await POST(`patients/${id}/update-status`, body)
  return res
}

const addCenter = async (id, id_medical_center) => {
  const res = await POST(`patients/${id}/add-medical-center`, {
    id_medical_center,
  })
  return res
}

const associateAll = async (id) => {
  const res = await POST(`patients/${id}/medical-staff-center`)
  return res
}

const addMedicalStaff = async (id, body) => {
  const res = await POST(`patients/${id}/add-medical-staff`, body)
  return res
}

const addOneMedicalStaff = async (id, id_medical_staff) => {
  const res = await POST(`patients/${id}/add-medical-staff`, {
    id_medical_staff: id_medical_staff,
  })
  return res
}
const removeOneMedicalStaff = async (id, id_medical_staff) => {
  const res = await POST(`patients/${id}/remove-medical-staff`, {
    id_medical_staff: id_medical_staff,
  })
  return res
}

const addHealthConditions = async (id, id_health_conditions) => {
  const res = await POST(`patients/${id}/add-health-conditions`, {
    id_health_conditions,
  })
  return res
}

const verifyEmail = async (email) => {
  const res = await GET(`check-email/Patient/${email}`)
  return res
}

const tugTimeGraphic = async (patientId) => {
  const res = await GET(
    'patients-indicators/' + patientId + '/tug-time-graphic'
  )
  return res
}

const documents = async (patientId) => {
  const res = await GET('patient-legal-documents/' + patientId)
  return res
}

const sendEmail = async (id, body) => {
  const res = await POST(`patients-smart-predict/${id}/report`, body)
  return res
}
const sendLegalDocuments = async (id, body) => {
  const res = await POST(`patient-legal-documents/${id}/send-mail`, body)
  return res
}
const sendSmartDetect = async (id, body) => {
  const res = await POST(`patients-kforce/${id}/report`, body)
  return res
}

const documentPatient = async (documentId, patientId) => {
  const res = await GET(
    `legal-documents/${documentId}/preview?patient_id=${patientId}`
  )
  return res
}

const getVDSIList = async (patientId) => {
  const res = await GET(`patient-vdsi?patient_id=${patientId}`)
  return res
}

const sendEmailVdsi = async (id, body) => {
  const res = await POST(`patient-vdsi/${id}/mail`, body)
  return res
}

const getTeleconsultations = async (patientId) => {
  const res = await GET(`teleconsultations?patient_id=${patientId}`)
  return res
}

const getTokenCertipair = async () => {
  const res = GET('certipair/token')
  return res
}

const getQuestionnaireDates = async (patientId) =>
  await GET(`patient-questionnaires-dates?patient_id=${patientId}`)

const getPatientsFile = async (patientId) =>
  await GET(`patient-files/${patientId}`)

const getMessageHistory = async () => {
  const res = await GET('certipair/conseils/sended', true)
  return res
}

const postLoginCartipair = async (body) => {
  const res = await POST('certipair/login', body)

  return res
}

const getSearchConseils = async (body) => {
  const res = await POST(
    `certipair/conseils/search`,
    JSON.stringify(body),
    true,
    {
      'Content-Type': 'application/json',
      'provider-token': `${localStorage.getItem('provider-token')}`,
    }
  )
  return res
}
const getUtilsConseils = async () => {
  const res = await GET(`certipair/utils`, true)
  return res
}

const getConseilsFavorites = async () => {
  const res = await GET(`certipair/conseils/favorites`, true)
  return res
}

const postConseilsSend = async (body) => {
  const res = POST(`certipair/conseils/send`, JSON.stringify(body), true, {
    'Content-Type': 'application/json',
    'provider-token': `${localStorage.getItem('provider-token')}`,
  })
  return res
}

const changeFavorite = async (body) => {
  const res = await POST(`certipair/conseils/fav`, JSON.stringify(body), true, {
    'Content-Type': 'application/json',
    'provider-token': `${localStorage.getItem('provider-token')}`,
  })
  return res
}

const createOmnidoc = async (body) => await POST('omnidoc', body)

export default {
  list,
  detail,
  create,
  update,
  changeStatus,
  destroy,
  addCenter,
  addMedicalStaff,
  addHealthConditions,
  verifyEmail,
  tugTimeGraphic,
  documents,
  addOneMedicalStaff,
  removeOneMedicalStaff,
  sendEmail,
  sendLegalDocuments,
  sendSmartDetect,
  associateAll,
  documentPatient,
  getVDSIList,
  sendEmailVdsi,
  getTeleconsultations,
  getQuestionnaireDates,
  getMessageHistory,
  getTokenCertipair,
  postLoginCartipair,
  getSearchConseils,
  getUtilsConseils,
  getConseilsFavorites,
  postConseilsSend,
  changeFavorite,
  getPatientsFile,
  createOmnidoc,
}
