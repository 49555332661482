import i18n from 'i18n-js'
import { isEmpty } from '../../../validators/validator'

export const handleErrors = (errors) => {
  let error = {}

  Object.entries(errors).forEach(([key, value]) => {
    if (isEmpty(value)) {
      error[key] = i18n.t(`validators.required`)
    }
  })

  return error
}

export const handleSendData = (data, arrayId = null, length, locale) => {
  let body = {
    name: [],
    children: [],
  }

  Object.entries(data).forEach(([key, value]) => {
    if (!key.includes('File')) {
      body = {
        ...body,
        name: [
          ...body.name,
          {
            locale: key,
            value,
          },
        ],
      }
    } else {
      let temporalArray = []
      let splitValue = value?.split(',')

      splitValue?.forEach((item) => {
        temporalArray.push({
          name: [
            {
              locale: key.replace('File', ''),
              value: item,
            },
          ],
        })
      })

      if (splitValue[length[locale] - 1] === undefined) {
        for(let i = length[key.replace('File', '')]; i < length[locale]; i++) {
          temporalArray[i] = {
            name: [
              {
                locale: key.replace('File', ''),
                value: '',
              },
            ]
          }
        }
      }
      
      if (body.children.length > 0) {
        temporalArray.forEach((item, ind) => {
          body.children[ind] = {
            ...(arrayId ? { id: arrayId[ind] ? arrayId[ind] : null } : {}),
            name: body.children?.[ind]?.name?.[0]
              ? [...body.children?.[ind]?.name, item?.name[0]]
              : [item?.name[0]],
          }
        })
      } else {
        body.children = temporalArray
      }
    }
  })

  return body
}
