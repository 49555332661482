import { AuthProvider } from './context/auth'
import theme from './styles/theme'
import { ThemeProvider } from '@mui/material/styles'
import { LayoutRouter } from './router'
import './css/styles.css'

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <LayoutRouter />
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
