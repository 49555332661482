import I18n from "i18n-js"
import { languages } from "../../utils"

export const handleError = (body) => {
    let error = {}
    
    languages.forEach(item => {
        if(!body[item.locale]) {
            error[item.locale] = I18n.t('validators.required')
        }
    })
    
    return error
}