import { GET, POST } from '../utils/crud'

const list = async () => {
  const res = await GET('home-predict/patients')
  return res
}

const refreshItem = async (id) => {
  const res = await POST(`home-predict/update-main-indicators-patient/${id}`)
  return res
}

export default {
  list,
  refreshItem,
}
