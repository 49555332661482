export const listOfInformation = [
  {
    text: 'cruds.medicalCenters.informationList.info3.title'
  },
  {
    text: 'cruds.medicalCenters.informationList.info1.title',
  },
  {
    text: 'cruds.medicalCenters.informationList.info2.title',
    child: [
      {
        text: 'cruds.medicalCenters.informationList.info2.childs.c1',
        beforeText: `{{name}}: `,
      },
      {
        text:  'cruds.medicalCenters.informationList.info2.childs.c2',
        beforeText: `{{address}}: `,
      },
      {
        text:  'cruds.medicalCenters.informationList.info2.childs.c3',
        beforeText: `{{ide}}: `,
      },
      {
        text:  'cruds.medicalCenters.informationList.info2.childs.c5',
        beforeText: `{{city}}: `,
      },
      {
        text:  'cruds.medicalCenters.informationList.info2.childs.c6',
        beforeText: `{{date}}: `,
      },
      {
        text:  'cruds.medicalCenters.informationList.info2.childs.c7',
        beforeText: `{{signature}}: `,
      },
      {
        text:  'cruds.medicalCenters.informationList.info2.childs.c4',
        beforeText: `{{expire_date}}: `,
      },
    ],
  },
]
