import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material'
import I18n from 'i18n-js'
import { useEffect, useState } from 'react'
import colors from '../../../../../utils/colors'
import { SearchBar, TabPanel } from '../../../../../components'
import MessageList from './messageList'
import patientService from '../../../../../services/patients'
import { enumTypeMessage } from '../../../../../utils/enum'
import SelectItem from './selectItem'
import FormDateProgrammed from './formDateProgrammed'
import styles from './styles'

const ViewTwo = ({
  setChangeVies,
  patient,
  setOpenSnackBar,
  userCertipair,
}) => {
  const [tab, setTab] = useState(0)
  const [professions, setProfessions] = useState([])
  const [typeMessage, setTypeMessage] = useState([])
  const [listTips, setListTips] = useState([])
  const [listFavoriteTips, setListFavoriteTips] = useState([])
  const [filterFavorite, setFilterFavorites] = useState([])
  const [objFilterFav, setObjFilterFav] = useState({})
  const [applySearch, setApplySearch] = useState({})
  const [specialities, setSpecialities] = useState()
  const [bodyData, setBodyData] = useState()
  const [utilsConseils, setUtilsConseils] = useState()

  useEffect(() => {
    saveUtilsConseils()
  }, [])

  useEffect(() => {
    if (utilsConseils) orderData()
    setBodyData({})
    setObjFilterFav({ type: enumTypeMessage.simple.key })
    setApplySearch({ type: enumTypeMessage.simple.key })
  }, [tab, utilsConseils])

  useEffect(() => {
    if (specialities) searchItems()
  }, [applySearch, specialities])

  useEffect(() => {
    if (!objFilterFav?.type)
      setObjFilterFav({ type: enumTypeMessage.simple.key })
    const validateType = listFavoriteTips?.find(
      (item) => item?.type === objFilterFav?.type
    )

    const newFavorite = listFavoriteTips?.filter((item) => {
      return (
        item?.type === objFilterFav?.type &&
        (objFilterFav?.search
          ? item.title
              .toLowerCase()
              .includes(objFilterFav?.search?.toLowerCase())
          : true)
      )
    })

    setFilterFavorites(
      newFavorite.length === 0 &&
        (objFilterFav?.search === '' || objFilterFav?.search === undefined) &&
        validateType
        ? listFavoriteTips
        : newFavorite
    )
  }, [objFilterFav, listFavoriteTips])

  const saveUtilsConseils = async () => {
    const resService = await patientService.getUtilsConseils()

    const newSpecialities = resService?.data?.specialities?.map((item) => {
      return { id: item?.id, name: item?.name }
    })
    const newProfessions = resService?.data?.displaySpec?.map((item) => ({
      id: item?.id,
      name: item?.name,
      key: item?.specialities,
    }))
    setProfessions(newProfessions)
    setSpecialities(newSpecialities)
    setUtilsConseils(resService?.data)
  }
  const handleTabChange = (_, newValue) => {
    setTab(newValue)
  }

  const searchItems = async () => {
    const res = await patientService.getSearchConseils(applySearch)

    if (!res?.data) return

    const newConseils =
      res?.data?.search_conseils_results?.search_conseils?.map((item) => {
        const addTitle = specialities.find(
          (item_) => item_?.id === item?.speciality_id
        )?.name

        if (item?.type === enumTypeMessage.pack.key) {
          let totalTime = 0

          const newMessages = item?.messages?.[0]?.map((item_) => {
            totalTime = totalTime + item_.hours
            return {
              time: item_?.hours,
              content: item_?.content,
            }
          })

          return {
            favorite: JSON.parse(item?.conseil_favourite_ids).includes(
              userCertipair
            ),
            messages: newMessages ?? [],
            quantity: item?.messages?.[0]?.length ?? 0,
            totalTime: totalTime ?? 0,
            type: item?.type ?? null,
            title: item?.title + (' ' + addTitle ?? ''),
            used: false,
            id: item?.id,
          }
        }
        return {
          favorite: JSON.parse(item?.conseil_favourite_ids).includes(
            userCertipair
          ),
          title: item?.title + (' ' + addTitle ?? ''),
          used: false,
          id: item?.id,
          content: item?.messages?.[0]?.[0]?.content,
          url: item?.messages?.[0]?.[0]?.url ?? '',
        }
      })

    setListTips(newConseils)

    return true
  }

  const saveSearch = (e) => {
    if (tab === 1) {
      setObjFilterFav({ ...(objFilterFav ?? {}), search: e.target.value })
    } else {
      setApplySearch({ ...(applySearch ?? {}), search: e.target.value })
    }
  }

  const saveType = (e) => {
    setBodyData({})
    setListTips([])
    setFilterFavorites([])
    if (tab === 1) {
      setObjFilterFav({ ...(objFilterFav ?? {}), type: e.target.value })
    } else {
      setApplySearch({ ...(applySearch ?? {}), type: e.target.value })
    }
  }

  const saveSpecialities = (e) =>
    setApplySearch({
      ...(applySearch ?? {}),
      specialities: JSON.parse(e.target.value),
    })

  const orderData = async () => {
    const resConseilsFavorites = await patientService.getConseilsFavorites()

    if (!resConseilsFavorites?.data) return

    const newData =
      resConseilsFavorites?.data?.default_conseils?.favourite_conseils?.map(
        (item) => {
          const addTitle = specialities?.find(
            (item_) => item_?.id === item?.speciality_id
          )?.name

          if (item?.type === enumTypeMessage.pack.key) {
            let totalTime = 0

            const newMessages = item?.messages?.[0]?.map((item_) => {
              totalTime = totalTime + item_.hours
              return {
                time: item_?.hours,
                content: item_?.content,
              }
            })

            return {
              favorite: JSON.parse(item?.conseil_favourite_ids).includes(
                userCertipair
              ),
              messages: newMessages ?? [],
              quantity: item?.messages?.[0]?.length ?? 0,
              totalTime: totalTime ?? 0,
              type: item?.type ?? null,
              title: item?.title + (' ' + addTitle ?? ''),
              used: false,
              id: item?.id,
            }
          }

          return {
            favorite: JSON.parse(item?.conseil_favourite_ids).includes(
              userCertipair
            ),
            title: item?.title + (' ' + addTitle ?? ''),
            used: false,
            id: item?.id,
            type: item?.type ?? null,
            content: item?.messages?.[0]?.[0]?.content,
            url: item?.messages?.[0]?.[0]?.url ?? '',
          }
        }
      )

    setListFavoriteTips(newData)
    setTypeMessage(Object.values(enumTypeMessage))
    return true
  }

  const saveIdTips = (e) => {
    if (e?.target?.value) {
      setBodyData({
        ...(bodyData ?? {}),
        conseil_ids: [...(bodyData?.conseil_ids ?? []), e?.target?.value],
      })
    } else {
      if (bodyData?.conseil_ids?.includes(e)) {
        const newBody = bodyData?.conseil_ids?.filter((item) => item !== e)
        setBodyData({ ...bodyData, conseil_ids: newBody })
      } else {
        setBodyData({
          ...(bodyData ?? {}),
          conseil_ids: [...(bodyData?.conseil_ids ?? []), e],
        })
      }
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}>
      <Box sx={{ width: '100%' }}>
        <Paper>
          <Box sx={styles.indexStyles.containerTab}>
            <Typography sx={styles.indexStyles.titleStyles}>
              {I18n.t('patient.detail.tabTip.tapTwo.selectTheMessages')}
            </Typography>
            <Tabs
              sx={{ background: colors.grayLight }}
              value={tab}
              onChange={handleTabChange}
            >
              <Tab label={I18n.t('patient.detail.tabTip.tapTwo.all')} />
              <Tab label={I18n.t('patient.detail.tabTip.tapTwo.myFavorites')} />
            </Tabs>
          </Box>
          <Box>
            <TabPanel value={tab} index={0}>
              <Box>
                <SearchBar
                  sx={{ marginBottom: '12px' }}
                  value={applySearch.search ?? ''}
                  change={saveSearch}
                />
                <Grid container spacing={1.5} sx={{ marginBottom: '16px' }}>
                  <Grid item>
                    <SelectItem
                      margin={'none'}
                      clear={(e) => {
                        delete applySearch.specialities
                        setApplySearch({ ...applySearch })
                        e.preventDefault()
                      }}
                      change={saveSpecialities}
                      label={I18n.t('patient.detail.tabTip.specialty')}
                      list={professions}
                      value={
                        applySearch?.specialities
                          ? `[${applySearch?.specialities?.toString()}]`
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item>
                    <SelectItem
                      margin={'none'}
                      clear={(e) => {
                        delete applySearch.type
                        setApplySearch({ ...applySearch })
                        e.preventDefault()
                      }}
                      change={saveType}
                      label={I18n.t('patient.detail.tabTip.tapTwo.tip')}
                      list={typeMessage}
                      translation={true}
                      value={applySearch?.type ?? ''}
                      deleteFalse
                    />
                  </Grid>
                </Grid>
              </Box>
              <MessageList
                fav={false}
                searchItems={searchItems}
                list={listTips}
                radio={enumTypeMessage.pack.key === applySearch?.type}
                check={enumTypeMessage.simple.key === applySearch?.type}
                handleChange={saveIdTips}
                value={bodyData?.conseil_ids}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Box>
                <SearchBar
                  value={objFilterFav.search ?? ''}
                  change={saveSearch}
                />
                <SelectItem
                  clear={(e) => {
                    delete objFilterFav.type
                    setApplySearch({ ...applySearch })
                    e.preventDefault()
                  }}
                  change={saveType}
                  label={I18n.t('patient.detail.tabTip.tapTwo.tip')}
                  list={typeMessage}
                  translation={true}
                  value={objFilterFav?.type ?? ''}
                  deleteFalse
                />
              </Box>
              <MessageList
                fav={true}
                orderData={orderData}
                list={filterFavorite}
                radio={enumTypeMessage.pack.key === objFilterFav?.type}
                check={enumTypeMessage.simple.key === objFilterFav?.type}
                value={bodyData?.conseil_ids}
                handleChange={saveIdTips}
              />
            </TabPanel>
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          marginLeft: { xs: '0px', lg: '16px' },
          minWidth: { xs: '100%', lg: '440px' },
          maxWidth: { xs: '100%', lg: '440px' },
          marginTop: { xs: '16px', lg: '0px' },
        }}
      >
        <FormDateProgrammed
          setOpenSnackBar={setOpenSnackBar}
          patient={patient}
          setBodyData={setBodyData}
          bodyData={bodyData}
          setChangeVies={setChangeVies}
        />
      </Box>
    </Box>
  )
}

export default ViewTwo
