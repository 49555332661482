import { createTheme } from '@mui/material/styles'
import colors from '../utils/colors'
import { customeTheme, general } from '.'
import { Box } from '@mui/material'
import { Stop } from '@mui/icons-material'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.lightPrimary,
    },
    secondary: {
      main: colors.blueLighten,
    },
  },
  overrides: {
    MuiStepper: {
      root: {
        padding: 0,
      },
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.blueInput,
          height: 3,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colors.lightPrimary,
            fontSize: '500',
            borderRadius: '25px',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            padding: ownerState?.padding || '15px 20px',
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            ...(ownerState?.padding && { padding: ownerState?.padding }),
          }
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            // ...(ownerState.variant === 'contained' && globalStyles.sendSubmit),
            ...(ownerState.variant === 'contained-danger' && {
              ...customeTheme.contained({
                primary: colors.danger,
                disabled: colors.disabled,
                white: colors.white,
              }),
            }),
            ...general(),
            boxShadow: 'none',
            borderRadius: ownerState.borderRadius ?? 0,
            textTransform: 'capitalize',
            padding: ownerState?.padding ?? '15px 20px',
            '& .MuiSvgIcon-root': {
              fontSize: '25px',
            },
          }
        },
        contained: {
          ...customeTheme.contained({
            primary: colors.blueInput,
            disabled: colors.disabled,
            white: colors.white,
          }),
        },
        outlined: {
          ...customeTheme.outlined,
          // padding: '8px 15px',
          ':hover': {
            border: `1px solid ${colors.blueInput}`,
          },
        },
        text: ({ ownerState }) => ({
          ...customeTheme.text({
            primary: colors.blueInput,
            disabled: colors.disabled,
            white: colors.white,
          }),
          padding: ownerState?.padding ?? '8px 15px',
        }),
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...general(),
          background: colors.blueInput,
          ...(ownerState.variant === 'contained' && {
            '&:disabled': {
              '& .MuiIcon-root': {
                background: colors.disabled,
              },
            },
          }),
          borderRadius: '0px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }),
        contained: {
          ...customeTheme.contained({
            primary: colors.blueInput,
            disabled: colors.disabled,
            white: colors.white,
          }),
        },
        outlined: {
          ...customeTheme.outlined,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: (params) => {
          const isDisabled = params.ownerState.disabled
          const error = params.ownerState.error
          const hasIcon = !!params?.ownerState?.endAdornment
          const hasStartIcon = !!params?.ownerState?.startAdornment
          const multiline = !!params?.ownerState?.multiline

          return {
            background: colors.white,

            '& .Mui-focused fieldset': {
              borderColor: error ? colors.error : colors.lightPrimary,
            },
            ...(!hasIcon &&
              !hasStartIcon &&
              !multiline &&
              !isDisabled && {
                ':hover': {
                  boxShadow: `0px 2px ${
                    error ? colors.error : colors.borderInputs
                  }`,
                  borderRadius: '5px',
                },
                '&.Mui-error': {
                  boxShadow: `0px 2px ${colors.error}`,
                },
              }),
            // height: '40px',
            // padding: '16.5px 14px',
            color: colors.blueInput,
          }
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: (params) => {
          
          const isDisabled = params?.ownerState?.disabled
          const error = params?.ownerState?.error
          const hasIcon = !!params?.ownerState?.endAdornment
          const hasStartIcon = !!params?.ownerState?.startAdornment
          const multiline = !!params?.ownerState?.multiline
          const typeTel = params?.ownerState?.type === 'tel'

          return {
            ...((!!hasIcon || !!hasStartIcon || !!multiline) &&
              !typeTel &&
              !error &&
              !isDisabled && {
                ':hover': {
                  boxShadow: `0px 2px ${
                    error ? colors.error : colors.borderInputs
                  }`,
                  borderRadius: '5px',
                  '&.Mui-error': {
                    boxShadow: `0px 2px ${colors.error}`,
                  },
                },
              }),
          }
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: (params) => {
          const isDisabled = params.ownerState.disabled
          const error = params.ownerState.error
          const hasIcon = !!params.ownerState?.InputProps?.endAdornment
          const hasStartIcon = !!params?.ownerState?.InputProps?.startAdornment
          const multiline = !!params?.ownerState?.multiline
          const typeTel = params.ownerState.type === 'tel'

          return {
            '& :before': {
              borderBottom: `1px solid ${colors.borderInputs}`,
            },
            ...(!error && {
              ':hover': {
                '& :before': {
                  display: 'none',
                },
                '& :after': {
                  display: 'none',
                },
              },
              '& :hover': {
                '&:before': {
                  display: 'none',
                },
                '&:after': {
                  display: 'none',
                },
              },
            }),
            background: 'transparent',
            ...((!!hasIcon || !!hasStartIcon || !!multiline) &&
              !typeTel &&
              !error &&
              !isDisabled && {
                ':hover': {
                  boxShadow: `0px 2px ${
                    error ? colors.error : colors.borderInputs
                  }`,
                  borderRadius: '5px',
                  '&.Mui-error': {
                    boxShadow: `0px 2px ${colors.error}`,
                  },
                },
              }),
            '& .MuiInputLabel-root.Mui-focused': {
              color: error ? colors.error : colors.lightPrimary,
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: error ? colors.error : colors.lightPrimary,
              },
              ...((!!typeTel || error) && {
                ':hover': {
                  boxShadow: `0px 2px ${
                    error ? colors.error : colors.borderInputs
                  }`,
                },
              }),
              background: colors.white,
            },
            color: colors.blueInput,
          }
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: colors.blueInput,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: (
          <Box
            sx={{
              width: '20px',
              height: '20px',
              background: colors.white,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              boxShadow: `0px 0px 1px ${colors.greyDarken}, 0px 0px 3px ${colors.greyDarken}, 0px 0px 5px ${colors.greyDarken}`,
              '& .MuiSvgIcon-root': {
                width: '25px',
                height: '25px',
              },
            }}
          />
        ),
        checkedIcon: (
          <Box
            sx={{
              width: '20px',
              height: '20px',
              boxShadow: `0px 0px 1px ${colors.greyDarken}, 0px 0px 3px ${colors.greyDarken}, 0px 0px 5px ${colors.greyDarken}`,
              background: colors.white,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              '& .MuiSvgIcon-root': {
                width: '25px',
                height: '25px',
                color: colors.lightPrimary,
              },
            }}
          >
            <Stop />
          </Box>
        ),
      },
      styleOverrides: {
        root: {
          ':checked': {
            background: colors.danger,
          },
        },
      },
    },
  },
})

export default theme
