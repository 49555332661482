import I18n from 'i18n-js'
import { CrudsBtn } from '../../../components'
import { Icons } from '../../../utils'

export const addList = (center, classes) => {
  const listDetail = {
    detail: [
      {
        name: I18n.t('cruds.medicalCenters.form.name'),
        value: center.name,
      },
      {
        name: I18n.t('cruds.medicalCenters.form.phone'),
        value: center.phone,
      },
      {
        name: I18n.t('cruds.medicalCenters.form.typeCenter'),
        value: center.medical_center_type?.description,
      },
      {
        name: I18n.t('cruds.medicalCenters.form.email'),
        value: center.email,
      },
    ],
    placeDatil: [
      {
        name: I18n.t('cruds.medicalCenters.form.country'),
        value: center?.city_center?.country?.name,
      },
      {
        name: I18n.t('cruds.medicalCenters.form.city'),
        value: center?.city_center?.name,
      },
      {
        name: I18n.t('cruds.medicalCenters.form.address'),
        value: center.address,
      },
    ],
    documentDetail: [
      {
        name: I18n.t('cruds.medicalCenters.form.nameOfTheConsentDocument'),
        value: center.legal_document?.name
          ? center.legal_document.name
          : I18n.t('general.dataNotFound'),
      },
      {
        name: I18n.t(
          'cruds.medicalCenters.form.descriptionOfTheConsentDocument'
        ),
        value: center.legal_document?.description
          ? center.legal_document.description
          : I18n.t('general.dataNotFound'),
      },
    ],
  }

  if (center.legal_document?.document_path) {
    listDetail.documentDetail.push({
      name: I18n.t('cruds.medicalCenters.create.consentDocument'),
      value: (
        <CrudsBtn
          className={classes.btnViewDocument}
          text={I18n.t('patient.detail.document.view')}
          variant="text"
          href={center.legal_document?.document_path}
          target="_blank"
          color="primary"
          startIcon={Icons('description')}
        />
      ),
    })
  }
  return listDetail
}
