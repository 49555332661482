import { useState, useContext } from 'react'
import {  Button, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import I18n from 'i18n-js'
import { AuthContext, loginUser } from '../../context/auth'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import { useSxStyles } from './style'
import { isFirefox } from '../../hooks/browser'
import { listCountries, transfromLocalePhone } from '../../utils/locale'
import contextkeys from '../../utils/contextkeys'
import { AuthLayout } from '../../components/authLayout'
import { isPhoneNumber } from '../../validators/validator'

const Twilio = () => {
  const history = useHistory()
  const { state, dispatch, setUser } = useContext(AuthContext)
  const [data, setData] = useState({ phone: '' })
  const styles = useSxStyles()
  const [errorNumber, setErrorNumber] = useState(false)
  const handleChange = (e) => {
    setErrorNumber(false)
    setData({
      ...data,
      phone: e,
    })
  }

  const handleSubmit = async (e) => {
    // setDisabled(true)
    e.preventDefault()
    data.email = state.user.email
    data.password = state.user.password
    const res = await loginUser(dispatch, data, setUser)

    if (res?.data || res?.error?.code_error_login === '02') {
      if (res?.data) data.firstTime = true
      dispatch({ type: contextkeys.getUser, payload: data })
      // setDisabled(false)
      history.push('/twofactor')
    }
    if (
      res?.error?.code_error_login === '09' ||
      res?.error?.code_error_login === '11'
    ) {
      setErrorNumber(true)
    }
  }
  
  if (!state.user) {
    history.push('/')
  }

  return (
    <AuthLayout>
      <Typography variant="subtitle2" color="textPrimary">
        {I18n.t('auth.phoneNumber')}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {I18n.t('auth.enterValidatePhoneNumber')}
      </Typography>

      <MaterialUiPhoneNumber
        disableDropdown={isFirefox()}
        name="phone"
        color="secondary"
        sx={styles.phone}
        variant="outlined"
        label={I18n.t('cruds.users.inputs.phone')}
        // size="small"
        error={(data?.phone ? !isPhoneNumber(data?.phone) : false) || errorNumber}
        onChange={handleChange}
        value={data?.phone}
        autoFormat={false}
        defaultCountry={transfromLocalePhone[state.locale] || listCountries.fr}
        inputProps={{ maxLength: 250 }}
      />
      {data?.phone
        ? !isPhoneNumber(data?.phone) && (
            <Typography variant="caption" color="error">
              {I18n.t('validators.invalidPhone')}
            </Typography>
          )
        : ''}
      {errorNumber && (
        <Typography variant="caption" color="error">
          {I18n.t('validators.invalidPhone')}
        </Typography>
      )}
      <br />
      <Button
        onClick={handleSubmit}
        disabled={data?.phone?.length < 9}
        variant="contained"
        color="primary"
        sx={styles.submit}
        component={Link}
        to="/forgotpassword"
        fullWidth
      >
        {I18n.t('auth.signInButton')}
      </Button>
    </AuthLayout>
  )
}

export default Twilio
