import Button from '@mui/material/Button'

import useStyles from './style'
const CrudsBtn = ({
  text,
  size = 'medium',
  click = null,
  type = '',
  disabled = false,
  variant = 'contained',
  className = '',
  color = 'secondary',
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Button
      {...rest}
      variant={variant}
      className={`${
        (size == 'medium' ? classes.medium : classes.large) +
        ' ' +
        className +
        ' ' +
        (variant == 'contained' ? classes.color : '')
      }  
        ${classes.standard} ${variant == 'text' ? classes.text : ''}`}
      color={color}
      onClick={click}
      type={type}
      disabled={disabled}
      disableElevation
    >
      {text}
    </Button>
  )
}

export default CrudsBtn
