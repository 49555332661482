import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { AuthContext, logout as Logout } from '../../context/auth'
import Aside from '../../components/aside/index'
import NavBar from '../../components/navBar'
import DashboardContainer from '../../components/dashboardContainer'
import { sidebarOptions } from '../../router/sidebar'
import actionsKeys from '../../utils/actionsKeys'
import { decryptData } from '../../utils/encrypt'
import useStyles from './style'
import { isValidToken } from '../../services/validToken'
import { CircularProgress } from '@mui/material'

const Dashboard = () => {
  const classes = useStyles()
  const { state, dispatch, user } = useContext(AuthContext)
  const [load, setLoad] = useState(true)
  const [AsideWidth, setAside] = useState(238)
  const [open, setOpen] = useState(true)
  const [isFixed, setFixed] = useState(true)
  const history = useHistory()
  const [openOptions, setOpenOptions] = useState({
    settings: false,
    employees: false,
  })
  const filterOptionsSidebar = () => {
    const data = state?.permissions ? decryptData(state?.permissions) : []
    /* only pass the options of the server whith action:'view' */
    const serverOptions = data?.filter((permission) =>
      permission.actions.find((action) => action == actionsKeys.view)
    )
    /* save the server id of the options */
    const serverId = serverOptions?.map((e) => e.id)
    /* compare every option whith the serverid, if have a match will return the option */
    const sidebarOpts = sidebarOptions?.filter((option) => {
      if (serverId?.find((id) => id === option.pageId)) {
        return option
      }
    })
    /* divide sidebarOpts in parents and children */
    let parents = sidebarOpts?.filter((option) => option.parentId === null)
    let childrens = sidebarOpts?.filter((option) => option.parentId != null)
    let onlyParents = parents?.filter((parent) => parent.slug == null)

    if (onlyParents == '' && childrens != '') {
      const childrensId = childrens.map((e) => e.parentId)

      onlyParents = sidebarOptions.filter((option) => {
        if (childrensId.find((parentid) => parentid === option.id)) {
          return option
        }
      })

      parents = parents.concat(onlyParents)
    }
    return parents.concat(childrens)
  }

  const verifyToken = async (option, children) => {
    if (option.slug) {
      if (history.location.pathname !== option.slug) {
        setLoad(true)
        const vt = await isValidToken()

        if (vt.data) history.push(option.slug)

        if (!children) handleOptions()
        setLoad(false)
      }
    } else {
      handleOptions(option.id)
    }
  }

  const handleOptions = (optionId = -1) => {
    if (optionId == -1) {
      setOpenOptions({
        settings: false,
        employees: false,
      })
    } else {
      switch (optionId) {
        case 2:
          setOpenOptions({
            ...openOptions,
            employees: openOptions.employees ? false : true,
          })
          break

        case 5:
          setOpenOptions({
            ...openOptions,
            settings: openOptions.settings ? false : true,
          })
          break
        default:
          break
      }
    }
  }
  const getOptions = (optionId) => {
    switch (optionId) {
      case 2:
        return openOptions.employees
      case 5:
        return openOptions.settings
      default:
        return null
    }
  }
  const handleDrawer = () => {
    if (open) {
      setOpen(false)
      setFixed(false)
    } else {
      setOpen(true)
      setFixed(true)
    }
    AsideWidth > 78 ? setAside(78) : setAside(238)
  }
  const drawerHover = () => {
    if (!isFixed) {
      setOpen(true)
      setAside(238)
    }
  }
  const drawerLeave = () => {
    if (!isFixed) {
      setOpen(false)
      setAside(78)
    }
  }
  const logout = () => {
    Logout(dispatch)
    history.push('/')
  }

  useEffect(() => {
    setLoad(true)
    isValidToken()
      .then((response) => {
        if (!response?.data) logout()
        setLoad(false)
      })
      .catch(() => {
        // logout()
      })
  }, [])

  return (
    <div className={classes.root}>
      <Aside
        open={open}
        drawerWidth={AsideWidth}
        drawerFocus={drawerHover}
        drawerLeave={drawerLeave}
        sidebarOptions={filterOptionsSidebar()}
        getOptions={getOptions}
        handleOptions={verifyToken}
      />
      <NavBar
        drawerWidth={AsideWidth}
        handleDrawer={handleDrawer}
        logout={logout}
        user={user}
      />
      {load ? (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      ) : (
        <DashboardContainer drawerWidth={AsideWidth} />
      )}

      <div className={classes.background} />
    </div>
  )
}

export default Dashboard
