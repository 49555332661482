import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = (drawerWidth) => {
  const styles = makeStyles({
    container: {
      position: 'relative',
      zIndex: '2',
      marginTop: '60px',
      width: `calc(100% - ${drawerWidth}px)`,
      transition: '.15s',
    },
    page: { minHeight: `calc(82vh)` },
    footer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px 0 16px 0',
      boxShadow: '0 -5px 5px -7px ' + colors.greyStrong,
    },
  })
  return styles()
}

export default useStyles
