import { Route, Switch, useHistory } from 'react-router-dom'
import HealthConditions from '../pages/healthcondition'
import Home from '../pages/home'
import MedicalStaff from '../pages/medicalStaff'
import MedicalStaffForm from '../pages/medicalStaff/form/index'
import MedicalStaffShow from '../pages/medicalStaff/show/index'
import Patients from '../pages/patients'
import PatientDetail from '../pages/patients/detail'
import Indicators from '../pages/indicators'
import CreateIndicator from '../pages/indicators/create'
import EditIndicator from '../pages/indicators/edit'
import MyProfile from '../pages/myProfile'
import Profiles from '../pages/profiles/'
import CreateProfile from '../pages/profiles/create/'
import EditProfile from '../pages/profiles/edit'
import Providers from '../pages/providers'
import Questionaries from '../pages/questionaries'
import CreateQuestionarie from '../pages/questionaries/create'
import EditQuestionarie from '../pages/questionaries/edit'
import Relationship from '../pages/relationship'
import Specialities from '../pages/specialities'
import TypeMedicalCenter from '../pages/typeMedicalCenter'
import TypeStaff from '../pages/typeStaff'
import Units from '../pages/units'
import Users from '../pages/users'
import CreateUser from '../pages/users/create'
import EditUser from '../pages/users/edit'
import MedicalCenters from '../pages/medicalCenters'
import CreateCenter from '../pages/medicalCenters/create'
import EditCenter from '../pages/medicalCenters/edit'
import ShowCenter from '../pages/medicalCenters/show'
import HomePredict from '../pages/homePredict'
import CreatePatient from '../pages/patients/create'
import EditPatient from '../pages/patients/edit'
import SmartTest from '../pages/smartTest'
import CreateSmartTest from '../pages/smartTest/create'
import EditSmartTest from '../pages/smartTest/edit'
import Questions from '../pages/smartTest/questions'
import QuestionsForm from '../pages/smartTest/questions/form'
import Parameter from '../pages/parameter'
import Form from '../pages/healthcondition/form'
import Show from '../pages/healthcondition/show'
import BilanesMedicalStaff from '../pages/smartTest/bilanesMedicalStaff'

const DashboardRouter = () => {
  const history = useHistory()

  return (
    <Switch>
      <Route exact path="/dashboard/" component={Home} />

      <Route exact path="/dashboard/patients" component={Patients} />
      <Route
        exact
        path="/dashboard/patients/create"
        component={CreatePatient}
      />
      <Route exact path="/dashboard/patients/:id" component={PatientDetail} />
      <Route
        exact
        path="/dashboard/patients/edit/:id"
        component={EditPatient}
      />
      <Route exact path="/dashboard/medical-staff" component={MedicalStaff} />
      <Route
        exact
        path="/dashboard/medical-staff/create"
        component={MedicalStaffForm}
      />
      <Route
        exact
        path="/dashboard/medical-staff/:id/edit"
        component={MedicalStaffForm}
      />
      <Route
        exact
        path="/dashboard/medical-staff/:id"
        component={MedicalStaffShow}
      />
      <Route exact path="/dashboard/units" component={Units} />
      <Route exact path="/dashboard/parameter" component={Parameter} />
      <Route
        exact
        path="/dashboard/types-center-medical"
        component={TypeMedicalCenter}
      />
      <Route exact path="/dashboard/types-staff" component={TypeStaff} />
      <Route exact path="/dashboard/specialities" component={Specialities} />
      <Route exact path="/dashboard/relationships" component={Relationship} />
      <Route exact path="/dashboard/providers" component={Providers} />

      <Route
        exact
        path="/dashboard/health-conditions"
        component={HealthConditions}
      />
      <Route exact path="/dashboard/health-conditions/create" component={Form}/>
      <Route exact path="/dashboard/health-conditions/show/:id" component={Show}/>
      <Route exact path="/dashboard/health-conditions/edit/:id" component={Form}/>

      <Route exact path="/dashboard/profiles" component={Profiles} />
      <Route
        exact
        path="/dashboard/profiles/create"
        component={CreateProfile}
      />
      <Route
        exact
        path="/dashboard/profiles/edit/:id"
        component={EditProfile}
      />
      <Route exact path="/dashboard/questionaries" component={Questionaries} />
      <Route
        exact
        path="/dashboard/questionaries/create"
        component={CreateQuestionarie}
      />
      <Route
        exact
        path="/dashboard/questionaries/edit/:id"
        component={EditQuestionarie}
      />
      <Route exact path="/dashboard/indicators" component={Indicators} />
      <Route
        exact
        path="/dashboard/indicators/create"
        component={CreateIndicator}
      />
      <Route
        exact
        path="/dashboard/indicators/edit/:id"
        component={EditIndicator}
      />
      <Route
        exact
        path="/dashboard/medical-center"
        component={MedicalCenters}
      />
      <Route
        exact
        path="/dashboard/medical-center/create"
        component={CreateCenter}
      />
      <Route
        exact
        path="/dashboard/medical-center/edit/:id"
        component={EditCenter}
      />
      <Route
        exact
        path="/dashboard/medical-center/:id"
        component={ShowCenter}
      />

      <Route exact path="/dashboard/smart-test" component={SmartTest} />
      <Route
        exact
        path="/dashboard/smart-test/create"
        component={CreateSmartTest}
      />
      <Route
        exact
        path="/dashboard/smart-test/edit/:id"
        component={EditSmartTest}
      />
      <Route
        exact
        path="/dashboard/smart-test/bilanes-medical_staff/:id"
        component={BilanesMedicalStaff}
      />
      <Route
        exact
        path="/dashboard/smart-test/questions/:id"
        component={Questions}
      />
      <Route
        exact
        path="/dashboard/smart-test/questions/:id/edit/:stepId"
        component={QuestionsForm}
      />

      <Route exact path="/dashboard/users" component={Users} />
      <Route exact path="/dashboard/users/create" component={CreateUser} />
      <Route exact path="/dashboard/users/edit/:id" component={EditUser} />

      <Route exact path="/dashboard/homepredict" component={HomePredict} />
      <Route exact path="/dashboard/my-profile" component={MyProfile} />
      <Route
        path="/dashboard/"
        component={() => {
          history.push('/dashboard/')
          return ''
        }}
      />
    </Switch>
  )
}

export default DashboardRouter
