import { Fragment } from 'react'
import { Box, MenuItem, TextField, Typography } from '@mui/material'
import I18n from 'i18n-js'
import { indicatorKeys } from '../../utils'
import { SelectForm } from '..'
import useStyles from './style'
import { isEmpty } from '../../validators/validator'
const InformationGeneralIndicators = ({ change, values, locale, data }) => {
  const { dataSources, units } = data
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <Box className={classes.inputsContainer}>
          <TextField
            variant="outlined"
            name="name"
            onChange={change}
            error={
              values[locale]?.name === undefined
                ? false
                : isEmpty(values[locale]?.name) || false
            }
            defaultValue={values[locale]?.name ? values[locale].name : ''}
            color="secondary"
            label={I18n.t('cruds.indicators.create.nameLabel')}
            placeholder={I18n.t('cruds.indicators.create.indicator')}
            // size="small"
            className={classes.form}
          />
          {values[locale]?.name === undefined
            ? false
            : isEmpty(values[locale]?.name) && (
                <Typography variant="caption" color="error">
                  {I18n.t('validators.required')}
                </Typography>
              )}
        </Box>
        <Box className={classes.inputsContainer}>
          <TextField
            variant="outlined"
            name="id_datasource"
            select
            onChange={change}
            color="secondary"
            label={I18n.t('cruds.indicators.create.dataSourceLabel')}
            // size="small"
            className={classes.form}
            value={values.id_datasource ? values.id_datasource : ''}
          >
            {dataSources.map((dataSource, index) => {
              return (
                <MenuItem key={index} value={dataSource.id}>
                  {dataSource.name}
                </MenuItem>
              )
            })}
          </TextField>
        </Box>
      </Box>
      <Box className={classes.textAreaContainer}>
        <TextField
          variant="outlined"
          name="description"
          multiline
          onChange={change}
          color="secondary"
          error={
            values[locale]?.description === undefined
              ? false
              : isEmpty(values[locale]?.description) || false
          }
          label={I18n.t('cruds.indicators.create.descriptionLabel')}
          rows={5}
          defaultValue={
            values[locale]?.description ? values[locale].description : null
          }
          className={classes.form + ' ' + classes.largeInput}
        />
        {values[locale]?.description === undefined
          ? false
          : isEmpty(values[locale]?.description) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.required')}
              </Typography>
            )}
      </Box>

      <Box className={classes.formSmallContainer}>
        {true && (
          <Fragment>
            <TextField
              variant="outlined"
              name="id_unit"
              select
              onChange={change}
              color="secondary"
              label={I18n.t('cruds.indicators.create.unitLabel')}
              // size="small"
              className={classes.form + ' ' + classes.smallInput}
              value={values.id_unit ? values.id_unit : ''}
              // style={{ maxHeight: '40px' }}
            >
              {units.map((unit, index) => {
                return (
                  <MenuItem key={index} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                )
              })}
            </TextField>
            <Box className={classes.acronymInput + ' ' + classes.acronymInputLeft}>
              <TextField
                variant="outlined"
                name="acronym"
                onChange={change}
                defaultValue={values.acronym ? values.acronym : ''}
                color="secondary"
                error={
                  values.acronym === undefined
                    ? false
                    : isEmpty(values.acronym) || false
                }
                label={I18n.t('cruds.indicators.create.acronymLabel')}
                // size="small"
                className={classes.form + ' ' + classes.acronymStyle}
              />
              {values.acronym === undefined
                ? false
                : isEmpty(values.acronym) && (
                    <Typography variant="caption" color="error">
                      {I18n.t('validators.required')}
                    </Typography>
                  )}
            </Box>
            <SelectForm
              options={indicatorKeys}
              label={I18n.t('cruds.indicators.create.type')}
              name="value_type"
              isLocalData
              onChange={change}
              variant="outlined"
              // size="small"
              color="secondary"
              value={values.value_type ? values.value_type : ''}
              className={classes.lastInput}
            />
          </Fragment>
        )}
      </Box>
    </Box>
  )
}

export default InformationGeneralIndicators
