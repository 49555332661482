import EyeTabs from './EyeTabs'

function IndicatorBalance({ patient, dates }) {
  const kforceData = patient.smartPredictIndicators.find(
    (smart) => smart.date_tug == dates
  )
  
  function renderBody() {
    const open = Array.isArray(kforceData?.equilibre?.open) ? kforceData?.equilibre?.open : []
    const close = Array.isArray(kforceData?.equilibre?.close) ? kforceData?.equilibre?.close : []
    return <EyeTabs open={open} close={close} />
  }

  return renderBody()
}

export default IndicatorBalance
