import Geocode from 'react-geocode'
import { ApiKeyMaps } from './constants'

Geocode.setApiKey(ApiKeyMaps)
Geocode.setLanguage('en')
export const getLatAndLong = async (address) => {
  return await Geocode.fromAddress(address).then((response) => {
    const { lat, lng } = response.results[0].geometry.location
    const data = { lat: lat, lng: lng }
    return data
  })
}
