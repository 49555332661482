import { Fragment, useEffect, useState } from 'react'
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ProfileBtn from '../profileBtn'
import styles from './style'
import I18n from 'i18n-js'

const NavBar = ({ drawerWidth, handleDrawer, logout, user }) => {
  const classes = styles((drawerWidth = { drawerWidth }))
  const [name, setname] = useState(user.name)
  useEffect(() => {
    if (user.lastname) setname(`${user.name} ${user.lastname}`)
    else setname(user.name)
  }, [user.name])

  return (
    <Fragment>
      <AppBar position="fixed" elevation={0} className={classes.navbar}>
        <Toolbar>
          <IconButton aria-label="open drawer" onClick={handleDrawer}>
            <MenuIcon />
          </IconButton>
          <div className={classes.infoContainer}>
            <div className={classes.user}>
              <Typography variant="body2" color="initial" align="right">
                {I18n.t('general.welcome') + name}
              </Typography>
              
              <Typography variant="body2" color="initial">
                {user.email}
              </Typography>
            </div>
            <ProfileBtn logout={logout} />
          </div>
        </Toolbar>
      </AppBar>
    </Fragment>
  )
}

export default NavBar
