import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('parameters')
  return res
}

const identifierList = async () => {
  const res = await GET('parameter/identifier_medical_centers')
  return res
}

const create = async (body, type_data = false) => {
  const res = await POST('parameters', body, type_data)
  return res
}

const edit = async (body, type_data = false) => {
  const { id, update } = body
  let res
  if (type_data) {
    update.append('_method', 'PUT')
    res = await POST(`parameters/${id}`, update, type_data)
  } else {
    res = await PUT(`parameters/${id}`, update, type_data)
  }
  return res
}

const destroy = async (body) => {
  const res = await DELETE(`parameters/${body}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
  identifierList,
}
