import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  footer: {
    marginTop: '16px',
    
  },
})

export default useStyles
