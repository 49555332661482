const getQuestionsLocales = (locale, questions) => {
  const localeQuestions = questions?.map((question) => {
    const options = question.options.map((option) => {
      return {
        completed: true,
        id: option.id,
        score: option.score || '',
        title:
          option.translations.find(
            (translation) => translation.locale == locale
          )?.title || '',
      }
    })
    return {
      completed: true,
      id: question.id,
      title:
        question.translations.find(
          (translation) => translation.locale == locale
        )?.title || '',
      type: question.type,
      mandatory: question.mandatory || false,
      options: Object.assign({}, options),
    }
  })
  return localeQuestions
}

const useSortResponse = (apiResponse, locale) => {
  const localedata = apiResponse.translations?.find(
    (info) => info.locale == locale
  )

  const localeQuestions = getQuestionsLocales(locale, apiResponse.questions)
  let traductions = {}
  apiResponse?.translations?.map((translation) => {
    if (translation.locale != locale) {
      const questions = getQuestionsLocales(
        translation.locale,
        apiResponse.questions
      )
      traductions[translation.locale] = {
        name: translation.name || '',
        instructions: translation.instructions || '',
        locale: translation.locale,
        questions: questions,
      }
    }
  })

  let data = {
    [locale]: {
      name: localedata?.name || '',
      instructions: localedata?.instructions || '',
      locale: localedata?.locale,
      questions: Object.assign({}, localeQuestions),
    },
    traductions: traductions,
  }
  if (apiResponse.translations) {
    return data
  } else {
    return {}
  }
}

export default useSortResponse
