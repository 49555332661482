import { useState, Fragment } from 'react'
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import { Cached } from '@mui/icons-material'
import clsx from 'clsx'
import I18n from 'i18n-js'
import { Icons, images } from '../../utils'
import homePredict from '../../services/homePredict'
import { SnackBar } from '..'
import useStyles from './style'

const CardHomePredict = ({ data, refresh }) => {
  const classes = useStyles()
  const [rotate, setRotate] = useState(false)

  //snackBar
  const [message, setMessage] = useState({ text: '', variant: 'success' })
  const [show, setShow] = useState(false)

  //data
  const [patientInfo, setPatientInfo] = useState({
    name: data.name || '',
    lastname: data.lastname || '',
    address:
      data.patient_home_predict.main_indicators?.location ||
      I18n.t('general.dataNotFound'),
    dataIndicators: data.patient_home_predict.main_indicators,
    status:
      data.patient_home_predict.main_indicators.fragility_score.status ||
      'disabled',
    indicators: [
      { icon: 'temperature', info: '-' },
      { icon: 'water', info: '-' },
      { icon: 'heart', info: '-' },
      { icon: 'fire', info: '-' },
      { icon: 'shoes', info: '-' },
      { icon: 'walk', info: '-' },
    ],
  })
  //fetch
  const getData = async () => {
    setRotate(true)
    const res = await homePredict.refreshItem(data.id)
    if (res.data) {
      setPatientInfo({
        name: res.data.name || '',
        lastname: res.data.lastname || '',
        address: res.data.address || I18n.t('general.dataNotFound'),
        dataIndicators: res.data.patient_home_predict.main_indicators,
        status:
          res.data.patient_home_predict.main_indicators.fragility_score
            .status || 'disabled',
        indicators: [
          { icon: 'temperature', info: '-' },
          { icon: 'water', info: '-' },
          { icon: 'heart', info: '-' },
          { icon: 'fire', info: '-' },
          { icon: 'shoes', info: '-' },
          { icon: 'walk', info: '-' },
        ],
      })
    } else {
      setMessage({
        text: 'homePredict.errorUpdatePatient',
        variant: 'warning',
      })
      setShow(true)
    }
    setRotate(false)
  }

  return (
    <Fragment>
      <Card className={classes.root}>
        <CardContent className={classes.body}>
          <Box className={classes.avatarContainer}>
            <Box
              className={clsx(classes.avatar, {
                [classes.success]: patientInfo.status == 'good',
                [classes.warning]: patientInfo.status == 'warning',
                [classes.danger]: patientInfo.status == 'danger',
                [classes.disabled]:
                  patientInfo.status != 'good' &&
                  patientInfo.status != 'warning' &&
                  patientInfo.status != 'danger',
              })}
            >
              <img className={classes.img} src={images.Profile} />
            </Box>
            {refresh && (
              <IconButton color="primary" onClick={getData} size="small">
                <Cached
                  id="rotate"
                  className={rotate ? classes.iconRotate : ''}
                />
              </IconButton>
            )}
          </Box>

          <Box className={classes.information}>
            <Typography variant="subtitle1" color="initial" fontw="true">
              <strong>
                {(patientInfo.name || '') + ' ' + (patientInfo.lastname || '')}
              </strong>
            </Typography>
            <Typography variant="caption" color="initial">
              {patientInfo.address}
            </Typography>
            <Box className={classes.iconButtons}>
              <IconButton size="small" onClick={null}>
                {patientInfo.dataIndicators.is_home
                  ? Icons('homeBack')
                  : Icons('homeOut')}
              </IconButton>

              {patientInfo.dataIndicators.battery.length > 0 && (
                <Fragment>
                  <IconButton
                    size="small"
                    className={classes.spaceIcons}
                    onClick={null}
                  >
                    {Icons('clockBattery')}
                  </IconButton>
                  <Typography variant="body2" display="inline">
                    <strong>{patientInfo.dataIndicators.battery || ''}</strong>
                  </Typography>
                </Fragment>
              )}

              <IconButton
                size="small"
                className={classes.spaceIcons}
                onClick={null}
              >
                {Icons('homeTemperature')}
              </IconButton>
              <Typography variant="body2" display="inline">
                <strong>
                  {patientInfo.dataIndicators.room_temperature.toFixed() +
                    '°' || ''}
                </strong>
              </Typography>
            </Box>
          </Box>

          <Box
            className={clsx(classes.notifications, {
              [classes.notificationSuccess]: patientInfo.status == 'good',
              [classes.notificationWarning]: patientInfo.status == 'warning',
              [classes.notificationDanger]: patientInfo.status == 'danger',
              [classes.notificationDisabled]:
                patientInfo.status != 'good' &&
                patientInfo.status != 'warning' &&
                patientInfo.status != 'danger',
            })}
          >
            {patientInfo.status != 'good' &&
            patientInfo.status != 'warning' &&
            patientInfo.status != 'danger' ? (
              <Typography variant="body2">
                {I18n.t('general.dataNotFound')}
              </Typography>
            ) : (
              Icons(patientInfo.status)
            )}
          </Box>
        </CardContent>
        <Divider />
        <CardContent className={classes.footer}>
          {patientInfo.indicators.map((indicator, index) => {
            return (
              <Box className={classes.indicator} key={index}>
                {Icons(indicator.icon)}
                <Typography variant="caption">{indicator.info}</Typography>
              </Box>
            )
          })}
        </CardContent>
      </Card>
      <SnackBar
        text={I18n.t(message.text)}
        severity={message.variant}
        show={show}
        HandleShow={setShow}
        time={1500}
      />
    </Fragment>
  )
}

export default CardHomePredict
