import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import I18n from 'i18n-js'
import { AuthContext, logout as Logout } from '../../context/auth'
import useStyles from './style'
import { AuthLayout } from '../../components/authLayout'
import { useEffect } from 'react'
import { useState } from 'react'
const Error401 = () => {
  const [loading, setLoading] = useState(true)
  const { state } = useContext(AuthContext)
  const isLoggedIn = state?.token
  const classes = useStyles()
  const history = useHistory()
  const { dispatch } = useContext(AuthContext)

  const logout = () => {
    Logout(dispatch)
    history.goBack()
  }

  useEffect(() => {
    const localeState = localStorage.getItem('state')
    if(!localeState) {
      logout()
    } else {
      setLoading(false)
    }
  }, [isLoggedIn])

  return (
    <AuthLayout title={I18n.t('general.error401')} loading={loading}>
      <Box className={classes.containerLogOut}>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          {I18n.t('general.notFound401')}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => logout()}
        >
          {I18n.t('components.backLogin')}
        </Button>
      </Box>
    </AuthLayout>
  )
  // (
  //   <Fragment>
  //     <ImageWelcome />
  //     <div className={classes.container}>
  //       <Grid
  //         container
  //         spacing={10}
  //         direction="column"
  //         justifyContent="center"
  //         alignItems="center"
  //         alignContent="center"
  //         wrap="nowrap"
  //       >
  //         <Grid item>
  //           <Box mt={9}>
  //             <Link to="/dashboard">
  //               <img
  //                 src={images.Logo}
  //                 alt={I18n.t('alt.logoIdp')}
  //                 className={classes.logo}
  //               />
  //             </Link>
  //           </Box>
  //         </Grid>
  //         <Grid item className={classes.gridChildren}>
  //           <Box mt={7} mb={5}>
  //             <Typography variant="h2" color="textSecondary" align="center">
  //               {I18n.t('general.error401')}
  //             </Typography>
  //           </Box>
  //           <Typography
  //             variant="subtitle1"
  //             color="textSecondary"
  //             align="center"
  //           >
  //             {I18n.t('general.notFound401')}
  //           </Typography>
  //         </Grid>
  //         <Grid item className={classes.gridChildren}>
  //           <Box mt={4} width={400} mx="auto">
  //             <Button
  //               variant="contained"
  //               color="primary"
  //               size="large"
  //               fullWidth
  //               className={classes.button}
  //               onClick={() => logout()}
  //             >
  //               {I18n.t('components.backLogin')}
  //             </Button>
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </div>
  //   </Fragment>
  // )
}

export default Error401
