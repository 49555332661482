import { Box, Typography, Grid, IconButton } from '@mui/material'
import React from 'react'
import { Icons } from '../../utils'
import { isEmpty } from '../../validators/validator'
import useStyles from './styles'

const Stadistics = ({ data, onClick }) => {
  const { pointer, evolution_graph } = data
  const size = evolution_graph ? 10 : 12
  const classes = useStyles({ pointer })
  
  return (
    <Box className={classes.container}>
      <Grid container alignItems="start" columnSpacing={1}>
        <Grid item xl={size} lg={size} md={size} sm={size} justifyContent="center"><Typography className={classes.title}>{data?.name && data?.name !== "" ? data.name : "-"}</Typography></Grid>
        {!!evolution_graph && <Grid item xl={1} lg={1} md={1} sm={1}><IconButton onClick={() => {onClick(evolution_graph)}} className={classes.graphic}>{Icons('graph')}</IconButton></Grid>}
      </Grid>
        
      <img src={data.image} alt="" className={classes.img} />

      {(data?.graph !== '' && data?.graph) && (
        <>
          <Box className={classes.textContainer}>
            <Typography>{data.bad}</Typography>

            {!isEmpty(data?.stable) && (
              <Typography pl={1}>{data.stable}</Typography>
            )}

            <Typography>{data.good}</Typography>
          </Box>

          <img src={data.graph} alt="" className={classes.stadistics} />
        </>
      )}
    </Box>
  )
}

export default Stadistics
