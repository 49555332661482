import { useEffect, useState } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import medicalsCenter from '../../services/medicalsCenter'
import CardMap from '../../components/cardMap'
import SearchBar from '../../components/searchBar'
import I18n from 'i18n-js'
import useStyles from './style'

const Home = () => {
  const [data, setData] = useState()
  const [centers, setCenters] = useState([])
  const [pagination, setPagination] = useState(0)
  const [page, setPage] = useState(0)
  const cardsPerPage = 9
  const numberOfPages = Math.ceil(centers.length / 9)
  const getData = async () => {
    const res = await medicalsCenter.list()
    setData(res.data?.medicalCenters || [])
    setCenters(res.data?.medicalCenters || [])
  }
  useEffect(() => {
    getData()
  }, [])
  const handleChangeSearch = (e) => {
    if (e.target.value) {
      const results = data.filter((dat) =>
        dat.name.toLowerCase().includes(e.target.value.trim().toLowerCase())
      )
      setCenters(results)
      setPagination(0)
    } else {
      setCenters(data)
      setPagination(page)
    }
  }
  const handleChangePagination = (e, value) => {
    setPagination(value - 1)
    setPage(value - 1)
  }
  const classes = useStyles()

  return (
    <div>
      <Box px={4} className={classes.container}>
        <div className={classes.sticky}>
          <Box my={4} className={classes.headContainer}>
            <Typography variant="h4" color="primary">{I18n.t('sidebar.mcenter')}</Typography>
            <div className={classes.searchBar}>
              <SearchBar change={handleChangeSearch} />
            </div>
          </Box>
        </div>
        <div className={classes.cardConatiner}>
          {centers.length > 0 &&
            centers
              .slice(
                pagination * cardsPerPage,
                pagination * cardsPerPage + cardsPerPage
              )
              .map((center, index) => {
                return <CardMap key={index} center={center} data={data} />
              })}
        </div>
        {data ? (
          centers.length > 1 && (
            <div className={classes.pagination}>
              <Pagination
                shape="rounded"
                count={numberOfPages}
                onChange={handleChangePagination}
                id="pagination"
              />
            </div>
          )
        ) : (
          <CircularProgress className={classes.circularProgress} />
        )}
      </Box>
    </div>
  )
}

export default Home
