import { Fragment, useMemo } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useHistory } from 'react-router'
import OtpInput from 'react-otp-input'
import Alert from '@mui/material/Alert'
import I18n from 'i18n-js'
import useStyles from './style'
import { codeVerification } from '../../utils/enum'
import { useState } from 'react'
import { colors } from '../../utils'
import { isNumber } from '../../validators/validator'
import Loader from '../loader'

const FormTwoFactor = ({
  firstTime,
  change,
  submit,
  errMsj,
  auth,
  disabled,
  login_method,
  resendCode,
}) => {
  const [otp, setOtp] = useState('')
  const classes = useStyles()
  const isTwilio = useMemo(
    () => login_method === codeVerification.twilio,
    [login_method]
  )
  const history = useHistory()
  return (
    <Box mt={firstTime ? 4 : 0} mb={firstTime ? 4 : 0}>
      {firstTime && (
        <Fragment>
          <div className={classes.qr}>
            <img src={auth?.url_qr} className={classes.img} />
          </div>
          <Box mt={4}>
            <Typography variant="body2" color="initial">
              {I18n.t('auth.textOne2Factor')}
            </Typography>
            <br />
            <Typography variant="body2" color="initial">
              <strong>{I18n.t('auth.name')}</strong>{' '}
              {I18n.t('auth.textTwo2Factor')}
            </Typography>
            <br />
            <Typography variant="body2" color="initial">
              {I18n.t('auth.textTree2Factor')}
              <span className={classes.code}>{auth?.token_google}</span>
            </Typography>
          </Box>
        </Fragment>
      )}
      <Box>
        <Box mt={2}>
          <Typography className={classes.subtitle}>Saisir le code</Typography>

          {disabled ? (
            <Loader />
          ) : (
            <OtpInput
              value={otp}
              onChange={(otp) => {
                if (isNumber(otp) || otp === '') {
                  setOtp(otp)
                  change({
                    target: {
                      id: 'code_verification',
                      value: otp,
                    },
                  })
                }
              }}
              numInputs={6}
              containerStyle={{
                columnGap: '10px',
                justifyContent: 'space-between',
              }}
              inputStyle={{
                backgroundColor: colors.inputGray,
                height: '70px',
                flex: 1,
                border: 'none',
                color: !errMsj?.error ? colors.blueInput : colors.danger,
                fontSize: '40px',
                fontWeight: '500',
              }}
              // renderSeparator={<span>-</span>}
              renderInput={(props) => <input disabled={disabled} {...props} />}
            />
          )}
        </Box>

        <Box className={classes.containerButtons} mt={1}>
          {(!!isTwilio && !disabled) && (
            <Button
              className={classes.buttonsText}
              onClick={() => {
                history.push('/twilio')
              }}
              variant="text"
              color="primary"
            >
              {I18n.t('auth.changeNumber')}
            </Button>
          )}

          {(!!isTwilio && !disabled) && (
            <Button
              className={classes.buttonsText}
              onClick={() => {
                resendCode()
              }}
              variant="text"
              // className={classes.resend}
            >
              {I18n.t('auth.resendCode')}
            </Button>
          )}
        </Box>
        {!!errMsj?.error && (
          <Box mt={2}>
            <Alert className={classes.alert} severity="error">
              <Box className={classes.containerError}>
                <Typography>
                  {I18n.t(errMsj?.message ?? 'auth.invalidCode')}
                </Typography>

                <Button
                  variant="contained-danger"
                  onClick={() => {
                    submit(otp)
                  }}
                  className={classes.submit}
                >
                  {I18n.t('auth.tryAgain')?.toUpperCase()}
                </Button>
              </Box>
            </Alert>
          </Box>
        )}
      </Box>
      {firstTime && (
        <Fragment>
          <Box mt={4}>
            <Typography variant="body2" color="initial">
              {I18n.t('auth.textFour2Factor')}
            </Typography>
          </Box>
        </Fragment>
      )}
    </Box>
  )
}

export default FormTwoFactor
