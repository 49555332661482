import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  submit: { color: colors.white /* , ...globalStyles.errorButton */ },
  code: { backgroundColor: '#f4f4f4', border: 'solid 1px #d7cfcf' },
  qr: { width: '50%', margin: 'auto' },
  img: { width: '100%' },
  resend: {
    color: colors.lightPrimary,
  },
  containerButtons: {
    '&.MuiBox-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  alert: {
    '& .MuiAlert-message': {
      width: '100%',
    },
    '& .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  containerError: {
    '&.MuiBox-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiTypography-root': {
      // flex: .7,
      fontSize: '12px',
      fontWeight: '500',
      color: colors.danger,
    },
    // '& .MuiButton-root': {
    //   flex: .3
    // }
  },
  subtitle: {
    fontSize: '12px',
    color: colors.blueInput,
    marginBottom: '20px',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  buttonsText: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      fontSize: '12px',
      paddingLeft: '0',
      paddingRight: '0',
      width: 'fit-content',
      textAlign: 'start',
      lineHeight: '1.5'
    },
  },
  change: {
    // width: '200px',
    wordBreak: 'break-word',
  },
  changePhone: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    // marginTop: '10px',
  },
})

export default useStyles
