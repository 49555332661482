import {
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Slider,
  Snackbar,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import I18n from 'i18n-js'
import useStyles from './style'

const FormResetPassword = ({
  change,
  submit,
  showpassword,
  showpassword2,
  setShowpassword,
  setShowpassword2,
  requirements,
  errors,
  openAlert,
  setOpenAlert,
  success,
  dataSend,
  disabled,
}) => {
  const classes = useStyles()
  const marks = [
    { value: 0, label: I18n.t('auth.textSliderPasswordWeak') },
    { value: 100, label: I18n.t('auth.textSliderPasswordStrong') },
  ]
  return (
    <Box>
      <Typography variant="subtitle2" color="textPrimary">
        {I18n.t('auth.resetPassSubTitle')}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {I18n.t('auth.resetPassDescription')}
      </Typography>

      <form onSubmit={submit}>
        <Box mt={2}>
          <OutlinedInput
            id="password"
            placeholder={I18n.t('form.fieldPassword')}
            type={showpassword ? 'text' : 'password'}
            onChange={change}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowpassword(!showpassword)
                  }}
                >
                  {showpassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            className={classes.input}
          />
        </Box>
        <Box mt={1}>
          <OutlinedInput
            id="password_confirmation"
            placeholder={I18n.t('form.fieldConfirmPassword')}
            type={showpassword2 ? 'text' : 'password'}
            onChange={change}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowpassword2(!showpassword2)
                  }}
                >
                  {showpassword2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            className={classes.input}
          />
        </Box>
        <div className={classes.errorContainer}>
          {!errors.matches && (
            <Box mt={1}>
              <Typography variant="caption">
                {I18n.t('form.fieldValidationPasswords')}
              </Typography>
            </Box>
          )}
          {(errors.blankSpaces ||
            errors.invalidCharacter ||
            (requirements > 0 && requirements < 100)) && (
            <Box mt={1}>
              <Typography variant="caption">
                {I18n.t('form.fieldValidationSecuritypasswords')}
              </Typography>
            </Box>
          )}
        </div>

        <Box mt={2}>
          <Slider className={classes.slider} value={requirements} marks={marks} disabled size="small"/>
        </Box>
        <Box mt={1}>
          <Alert severity="info">
            <Typography variant="body1" color="textPrimary">
              {I18n.t('auth.textSecurityPoliciesTitle')}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {I18n.t('auth.textSecurityPoliciesRule1')}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {I18n.t('auth.textSecurityPoliciesRule2')}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {I18n.t('auth.textSecurityPoliciesRule3')}
            </Typography>
          </Alert>
        </Box>
        <Box mt={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            className={classes.submit}
            disabled={disabled}
          >
            {I18n.t('auth.sendRequest')}
          </Button>
        </Box>
        {openAlert && !success && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openAlert}
            autoHideDuration={4000}
            onClose={() => {
              setOpenAlert(false)
            }}
          >
            <Alert severity="warning">{I18n.t('auth.oldPasswordAlert')}</Alert>
          </Snackbar>
        )}
        {openAlert && success && (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openAlert}
            autoHideDuration={2000}
            onClose={dataSend}
          >
            <Alert severity="success">{I18n.t('auth.resetPassSuccess')}</Alert>
          </Snackbar>
        )}
      </form>
    </Box>
  )
}

export default FormResetPassword
