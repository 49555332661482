import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignContent: 'space-around',
    height: '250px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignContent: 'flex-start',
      height: '350px',
      padding: '0 16px',
    },
  },
  inputsContainer: {
    width: '47%',
    height: '56px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  inputText: {
    alignSelf: 'center',
    background: 'white',
    // height: '35px',
    width: '100%',
    display: 'flex',
    borderRadius: '2px',

    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  lastInputs: {
    width: '100%',
    marginLeft: '8px',
  },
  snackbar: { position: 'fixed', top: '13vh', left: '50vw' },
}))

export default useStyles
