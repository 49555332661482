import { useEffect, useState, useContext, Fragment } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { Typography, Box, Button, Fade, CircularProgress } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import { actionKeys, pagesKeys } from '../../../utils'
import { canAccess } from '../../../router/permissions'
import {
  StepperCreate,
  InformationGeneralIndicators,
  AlertModal,
} from '../../../components'
import { CreateIndicatorValidator } from '../../../validators/createIndicatorValidator'
import { indicators, dataSources, units } from '../../../services'
import notAllHaveTraductions from '../../../hooks/traductions/notAllHaveTraductions'
import useStyles from './style'
import Annotations from '../Annotations'
import useSortResponse from '../../../hooks/indicators/useSortResponse'
import LanguageForm from '../languageForm'
import useSortForSend from '../../../hooks/indicators/useSortForSend'
const EditIndicator = () => {
  const { state } = useContext(AuthContext)
  const { id } = useParams()
  const history = useHistory()
  const locale = state.locale
  const [fieldsNeedTranslation] = useState([
    { name: 'name', multiline: false, label: 'cruds.indicators.create.name' },
    {
      name: 'description',
      multiline: true,
      label: 'cruds.indicators.create.description',
    },
  ])
  const [steps] = useState([
    {
      step: 'cruds.indicators.create.step.first',
      label: 'cruds.indicators.create.stepLabel.first',
    },
    {
      step: 'cruds.indicators.create.step.second',
      label: 'cruds.indicators.create.stepLabel.second',
    },
    {
      step: 'cruds.indicators.create.step.third',
      label: 'cruds.indicators.create.stepLabel.third',
    },
  ])
  const [activeStep, setActiveStep] = useState(0)
  const [createIndicator, setCreateIndicator] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [disabledStepTwo, setDisabledStepTwo] = useState(false)
  const [apiResponse, setApiResponse] = useState({})
  const [openModal, setOpenModal] = useState(false)
const [loading, setLoading] = useState(false)
  const getApiResonse = async () => {
    setLoading(true)
    const dataSourceResponse = await dataSources.list()
    const unitResponse = await units.list()
    const indicatorResponse = await indicators.show(id)
    setApiResponse({
      ...apiResponse,
      dataSources: dataSourceResponse.data?.dataSources || [],
      units: unitResponse.data?.units || [],
      indicator: indicatorResponse.data?.indicator || [],
    })
    setLoading(false)
  }

  const handleChangeCreate = (e) => {
    if (fieldsNeedTranslation.find((field) => field.name == e.target.name)) {
      setCreateIndicator({
        ...createIndicator,
        [locale]: {
          ...createIndicator[locale],
          [e.target.name]: e.target.value,
          locale: locale,
        },
      })
    } else {
      setCreateIndicator({
        ...createIndicator,
        [e.target.name]: e.target.value,
      })
    }
  }
  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBackStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    activeStep == 1
      ? setDisabled(CreateIndicatorValidator(createIndicator, locale))
      : ''
  }
  const haveAnnotations = () => {
    let haveAnnotations = false
    if (createIndicator[locale].annotations == undefined) {
      haveAnnotations = false
    } else {
      if (Object.values(createIndicator[locale].annotations).length === 0) {
        haveAnnotations = false
      } else haveAnnotations = true
    }
    return haveAnnotations
  }
  const checkTraductions = () => {
    if (
      notAllHaveTraductions(
        createIndicator.traductions,
        false,
        haveAnnotations()
      )
    ) {
      setOpenModal(true)
    } else {
      handleSubmitData()
    }
  }
  const handleSubmitData = async () => {
    setLoading(true)
    const body = useSortForSend(createIndicator, locale)
    setDisabled(true)
    const res = await indicators.edit(body, id)

    if (res.data) {
      setActiveStep(activeStep + 1)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (apiResponse.indicator && Object.values(createIndicator).length === 0) {
      setCreateIndicator(useSortResponse(apiResponse.indicator, locale))
    }
  }, [apiResponse])

  useEffect(() => {
    if (!canAccess(actionKeys.edit, pagesKeys.indicator, state)) {
      history.push('/dashboard/')
    } else {
      return getApiResonse()
    }
  }, [])

  useEffect(() => {
    activeStep == 0
      ? setDisabled(CreateIndicatorValidator(createIndicator, locale))
      : ''
  }, [createIndicator])
  const classes = useStyles()
  return (
    <Box p={4} m={4}>
      <Box>
        <Typography variant="body2" color="textSecondary">
          {I18n.t('breadcrumb.indicators')} /{' '}
          <Link to="/dashboard/indicators/" className={classes.textBreadcrum}>
            {I18n.t('breadcrumb.dashboard')} /{' '}
          </Link>
          {I18n.t('cruds.indicators.edit.indicator')}
        </Typography>
      </Box>

      <Box className={classes.bodyContainer}>
        <Box className={classes.titleBarContainer}>
          <Box className={classes.titleContainer}>
            {apiResponse.indicator ? (
              <Fragment>
                <Typography variant="subtitle1" color="textSecondary">
                  {I18n.t('cruds.indicators.edit.title')}
                </Typography>
                <Typography variant="h6" color="primary">
                  {createIndicator[locale]?.name
                    ? createIndicator[locale].name
                    : I18n.t('cruds.indicators.create.indicator')}
                </Typography>
              </Fragment>
            ) : (
              ''
            )}
          </Box>
          <Box className={classes.stepperContainer}>
            <StepperCreate activeStep={activeStep} steps={steps} />
          </Box>
        </Box>
        {createIndicator.id_unit && !loading ? (
          <>
            <Box className={classes.inputsContainer}>
              <Fade in={activeStep == 0} timeout={500}>
                <Box>
                  {activeStep == 0 && (
                    <InformationGeneralIndicators
                      change={handleChangeCreate}
                      values={createIndicator}
                      locale={locale}
                      data={apiResponse}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 1} timeout={500}>
                <Box>
                  {activeStep == 1 && (
                    <Annotations
                      change={handleChangeCreate}
                      values={createIndicator}
                      locale={locale}
                      setValues={setCreateIndicator}
                      setDisabledStepTwo={setDisabledStepTwo}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 2} timeout={500}>
                <Box>
                  {activeStep == 2 && (
                    <LanguageForm
                      items={fieldsNeedTranslation}
                      values={createIndicator}
                      locale={locale}
                      setCreateData={setCreateIndicator}
                    />
                  )}
                </Box>
              </Fade>
              <Fade in={activeStep == 3} timeout={500}>
                <Box>
                  {activeStep == 3 && (
                    <Box className={classes.finishContainer}>
                      <CheckCircle className={classes.chekCircle} />
                      <Typography variant="h5" color="primary">
                        {I18n.t('components.success')}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {I18n.t('cruds.indicators.edit.edited')}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Fade>
            </Box>

            <Box
              className={
                activeStep < 3
                  ? classes.actionsContainer
                  : classes.actionsContainerEnd
              }
            >
              {activeStep < 3 && (
                <Box>
                  <Button
                    variant="text"
                    color="secondary"
                    className={classes.buttonText}
                    component={Link}
                    to="/dashboard/indicators"
                  >
                    {I18n.t('components.cancel')}
                  </Button>
                </Box>
              )}
              <Box>
                {activeStep < 3 ? (
                  <Fragment>
                    {activeStep > 0 && (
                      <Button
                        disabled={activeStep < 2 ? false : disabled}
                        variant="outlined"
                        className={classes.buttonContained}
                        onClick={handleBackStep}
                      >
                        {I18n.t('components.back')}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={
                        activeStep < 2 ? handleNextStep : checkTraductions
                      }
                      disabled={activeStep === 1 ? disabledStepTwo : disabled}
                    >
                      {I18n.t('components.next')}
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    component={Link}
                    to="/dashboard/indicators"
                  >
                    {I18n.t('cruds.indicators.return')}
                  </Button>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box className={classes.loadingBg}>
            <CircularProgress color="secondary" className={classes.loading} />
          </Box>
        )}
      </Box>
      <AlertModal
        open={openModal}
        close={() => {
          setOpenModal(false)
        }}
        title={I18n.t('components.traductions.missingTraductionTitle')}
        message={I18n.t('components.traductions.missingTraductiontext')}
        SuccessText={I18n.t('components.yes')}
        success={handleSubmitData}
      />
    </Box>
  )
}

export default EditIndicator
