import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const questionariesStyles = makeStyles((theme) => ({
  tableCell: {
    width: '50%',
    borderRight: `8px solid ${colors.greyLighten}`,
    padding: '8px 0 4px 16px',
    verticalAlign: 'top',
  },
 
  tableCellHeader: {
    ['&.MuiTableCell-sizeSmall:last-child']: {
      padding: '0 0px 0 16px',
    },
  },
  doubleCell: {},
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  statusHeader:{
    borderRight: `8px solid ${colors.greyLighten}`,
    padding: '0 16px 0 16px',
  },
  subHeaderTd: {
    width: '15%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  firstTd: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  secondTd: {
    width: '15%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cancel:{
    color: colors.disabled,
    border: colors.disabled+' solid 1px',
    marginLeft: '35%',
    marginRight: '10px',
    borderRadius: '2px',
    '&:hover': {
      color: colors.blueLighten,
    },
    transition: '.2s',
  }
}))

export default questionariesStyles
