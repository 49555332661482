import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  container: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px',
    borderRadius: '15px',
    transition: '.3s',
    cursor: 'pointer',
  },
  enabled: {
    background: colors.primary,
    '&:hover': { boxShadow: `1px 1px 5px ${colors.primary}` },
  },
  disabled: {
    background: colors.greyDarken,
    '&:hover': { boxShadow: `1px 1px 5px ${colors.greyDarken}` },
  },
  indicator: {
    position: 'relative',
    width: '15px',
    height: '15px',
    borderRadius: '90px',
    background: colors.white,
    left: 0,
    transition: 'left  .3s',
    marginRight: 8
  },
  indicatorEnabled: { 
    left: 'calc(100% - 26px)',
    marginRight: 0,
    marginLeft: 12
  },
  text: {
    display: 'inline-block',
    color: colors.white,
    margin: '0 4px',
    transition: '.3s',
  },
  textEnabled: { transform: 'translate(-23px)' },
})

export default useStyles
