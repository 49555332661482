import {
  Grid,
  Typography,
  Box,
  TextField,
  Autocomplete,
  CircularProgress,
} from '@mui/material'
import I18n from 'i18n-js'
import { DinamicForm } from '../../../../components'
import AddImage from '../../../../components/addImage'
import SimpleError from '../../../../components/errors/simple'
import { Icons } from '../../../../utils'
import useStyles from './styles'
import { listCountries, usersWithPermissions } from '../../../../utils/locale'

const Step1 = ({
  patient,
  onChange,
  edit,
  healthConditions,
  countries,
  errors,
  field = null,
  loading = false,
  image,
  removeEmail,
  isSameCountryOfTheDoctor
}) => {
  const classes = useStyles()
  const inputs = [
    {
      name: 'name',
      label: I18n.t('patient.form.name'),
      style: classes.inputWidth2,
      rB: true,
    },
    {
      name: 'lastname',
      label: I18n.t('patient.form.lastname'),
      style: classes.inputWidth2,
      rB: true,
    },
    {
      name: 'birth',
      label: I18n.t('patient.form.birth'),
      type: 'date',
      style: `${classes.inputWidth2} ${classes.date}`,
      rB: true,
    },
    {
      name: 'country_birth',
      label: I18n.t('patient.form.place_birth'),
      type: 'simpleAutocomplete',
      options:
        countries?.sort(function (a, b) {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        }) ?? [],
      style: classes.inputWidth2,
      classAutoComplete: classes.inputAutocomplete,
      fullWidth: true,
      rB: true,
    },
    {
      name: 'sex',
      label: I18n.t('patient.form.sex'),
      type: 'select',
      options: [
        { id: 0, name: I18n.t('patient.form.gender.female') },
        { id: 1, name: I18n.t('patient.form.gender.male') },
      ],
      style: classes.inputWidth2,
      rB: true,
    },
    {
      name: 'height',
      label: I18n.t('patient.form.height'),
      type: 'number',
      style: classes.inputWidth2,
      rB: true,
    },
    {
      name: 'weight',
      label: I18n.t('patient.form.weight'),
      type: 'number',
      style: classes.inputWidth2,
      rB: true,
    },
    {
      name: 'right_hand',
      label: I18n.t('patient.form.right_hand'),
      type: 'select',
      options: [
        { id: 0, name: I18n.t('patient.form.hands.left') },
        { id: 1, name: I18n.t('patient.form.hands.right') },
      ],
      style: classes.inputWidth2,
      rB: true,
    },

    //part two
    {
      name: 'email',
      label: I18n.t('patient.form.email'),
      style: classes.inputWidth,
      disabled: edit,
      onBlur: async () => { },
    },
    {
      name: 'place_birth',
      label: I18n.t('patient.form.address'),
      style: classes.inputWidth,
    },
    {
      name: 'code_insee',
      label: I18n.t('patient.form.codeInsee'),
      style: classes.inputWidth,
    },
    {
      name: 'phone',
      label: I18n.t('patient.form.phone'),
      type: 'phone',
      style: classes.inputWidth,
    },
    {
      name: 'SSN',
      label: I18n.t('patient.form.SSN'),
      style: classes.inputWidth,
    },
    {
      name: 'id_health_conditions',
      label: I18n.t('patient.form.health'),
      type: 'multiple',
      options: [...healthConditions],
      style: classes.bigInput,
    },
    {
      name: 'zip_code',
      label: I18n.t('patient.form.zipCode'),
      style: `${classes.inputWidth} ${classes.texInput}`,
      InputProps: {
        endAdornment: <Box>{loading && <CircularProgress size={15} />}</Box>,
      },
    },
  ]

  const fields = [
    {
      name: 'state',
      label: I18n.t('patient.form.department') + ' (*)',
      type: 'select',
      options:
        field?.states?.length > 0 && usersWithPermissions([listCountries.fr]) && isSameCountryOfTheDoctor
          ? field.states
          : undefined,
      style: classes.inputAutocomplete,
    },
    {
      name: 'communite',
      label: I18n.t('patient.form.community') + ' (*)',
      type: 'select',
      options:
        field?.communities?.length > 0 && usersWithPermissions([listCountries.fr]) && isSameCountryOfTheDoctor
          ? field?.communities
          : undefined,
      style: classes.inputAutocomplete,
    },
    {
      name: 'cc',
      label: I18n.t('patient.form.cc'),
      style: classes.inputWidth,
    },
    {
      name: 'expire_date',
      label: I18n.t('patient.form.validate_to'),
      type: 'date',
      style: `${classes.inputWidth} ${classes.date}`,
      allDate: true,
    },
    {
      name: 'nif',
      label: I18n.t('patient.form.nif'),
      style: classes.inputWidth,
    },
    {
      name: 'niss',
      label: I18n.t('patient.form.niss'),
      style: classes.inputWidth,
    },
    {
      name: 'sns',
      label: I18n.t('patient.form.sns'),
      style: classes.inputWidth,
    },
    {
      name: 'comments',
      label: I18n.t('patient.form.comments'),
      style: classes.inputTextArea,
      type: 'textArea',
      rows: 2,
    },
    {
      name: 'dni',
      label: I18n.t('patient.form.dni') + ' (*)',
      style: classes.inputWidth,
    },
    {
      name: 'nuss',
      label: I18n.t('patient.form.nuss') + ' (*)',
      style: classes.inputWidth,
    },
  ]
  return (
    <Grid item>
      <div>
        <Grid container spacing={2} direction="row">
          <Grid item className={classes.gPLNone} xs={12}>
            <Typography
              variant="h6"
              color="primary"
              className={classes.title}
            >
              {I18n.t('patient.show.personalInfo')}
            </Typography>
          </Grid>

          <Box className={`${classes.firstFields} ${classes.firstFieldCenter}`}>
            <Box className={classes.containerImage}>
              <AddImage image={image} icon={Icons('camera')} name="photo" getFile={onChange} label={I18n.t('patient.form.takePhoto')} />
            </Box>

            <Box className={classes.containerInputs}>
              <DinamicForm
                inputs={inputs.slice(0, 8)}
                handleChange={onChange}
                values={patient}
                errors={errors}
              />
            </Box>
          </Box>

          <Typography
            variant="h6"
            color="primary"
            className={classes.title}
          >
            {I18n.t('patient.show.contactInfo')}
          </Typography>

          <Box className={classes.firstFields}>
            {!removeEmail && (
              <DinamicForm
                inputs={inputs.slice(8, 9)}
                handleChange={onChange}
                values={patient}
                errors={errors}
              />
            )}

            <DinamicForm
              inputs={inputs.slice(11, 12)}
              handleChange={onChange}
              values={patient}
              errors={errors}
            />

            {usersWithPermissions([listCountries.fr]) && isSameCountryOfTheDoctor && (
              <>
                <Autocomplete
                  value={
                    patient.state_id
                      ? fields[0]?.options?.find(
                        (aux) => patient.state_id.id === aux?.id
                      )
                      : null
                  }
                  disablePortal
                  onChange={(e, target) =>
                    onChange({
                      target: {
                        name: 'state_id',
                        value: target,
                      },
                    })
                  }
                  size="medium"
                  name="state_id"
                  id={fields[0]?.name}
                  options={fields[0]?.options || []}
                  getOptionLabel={(option) => option?.name || ''}
                  className={fields[0].style}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        error={errors?.state_id !== undefined && errors?.state_id !== null}
                        label={fields[0].label}
                      />

                      <SimpleError
                        message={errors.state_id}
                        error={errors?.state_id !== undefined && errors?.state_id !== null} 
                      />
                    </>
                  )}
                />

                <Autocomplete
                  value={
                    patient.community_id
                      ? fields[1]?.options?.find(
                        (aux) => patient.community_id.id === aux?.id
                      )
                      : null
                  }
                  disablePortal
                  onChange={(e, target) =>
                    onChange({
                      target: {
                        name: 'community_id',
                        value: target,
                      },
                    })
                  }
                  size="medium"
                  name="community_id"
                  id={fields[1]?.name}
                  options={fields[1]?.options || []}
                  getOptionLabel={(option) => option?.name || ''}
                  className={fields[1].style}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        error={
                          errors?.state_id === undefined &&
                          errors?.community_id !== undefined
                        }
                        label={fields[1].label}
                      />

                      <SimpleError
                        message={errors.community_id}
                        error={
                          errors?.state_id === undefined &&
                          errors?.community_id !== undefined
                        }
                      />
                    </>
                  )}
                />
              </>
            )}

            <DinamicForm
              inputs={inputs.slice(9, 10)}
              handleChange={onChange}
              values={patient}
              errors={errors}
            />

            {usersWithPermissions([listCountries.fr]) && isSameCountryOfTheDoctor && (
              <DinamicForm
                inputs={inputs.slice(14, 15)}
                handleChange={onChange}
                values={patient}
                errors={errors}
                disabledErrorInput
              />
            )}

            {!usersWithPermissions([listCountries.es]) && isSameCountryOfTheDoctor&& (
              <DinamicForm
                inputs={inputs.slice(12, 13)}
                handleChange={onChange}
                values={patient}
                errors={errors}
              />
            )}

            {usersWithPermissions([listCountries.pt])  && isSameCountryOfTheDoctor && (
              <>
                <DinamicForm
                  inputs={fields.slice(2, 6)}
                  handleChange={onChange}
                  values={patient}
                  errors={errors}
                />

                <DinamicForm
                  inputs={fields.slice(6, 8)}
                  handleChange={onChange}
                  values={patient}
                  errors={errors}
                />
              </>
            )}

            {usersWithPermissions([listCountries.es])  && isSameCountryOfTheDoctor && (
              <>
                <DinamicForm
                  inputs={fields.slice(8, 10)}
                  handleChange={onChange}
                  values={patient}
                  errors={errors}
                />
              </>
            )}
          </Box>

          <Grid item xs={12} mb={2}>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subtitle}
            >
              {I18n.t('patient.show.healthInfo')}
            </Typography>
          </Grid>

          <Box className={classes.firstFields}>
            <DinamicForm
              inputs={inputs.slice(13, 14)}
              handleChange={onChange}
              values={patient}
              errors={errors}
            />
          </Box>
        </Grid>
      </div>
    </Grid>
  )
}

export default Step1
