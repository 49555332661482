import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  vdsiSearchContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 20,
  },
  search: {
    flexGrow: 1,
  },
  deleteBtn: {
    '&.MuiButton-root': {
      marginLeft: 10,
      textTransform: 'none',
      boxShadow: 'none',
      minWidth: 30,
    },
  },
  paddingbtn: {
    '&.MuiButton-root': {
      // padding: '7px',
      lineHeight: '1.5',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
})
