export const reorder = (list, start, end) => {
  const result = [...list]

  const [remove] = result.splice(start, 1)

  result.splice(end, 0, remove)

  return result
}

export const existBlock = (block) => {
  if (block !== undefined) {
    return Object.values(block).map((item) => ({
      ...item,
      order: `${item.order}`
    }))
  } else {
    return []
  }
}

export const orderValue = (block) => {
  if (block !== undefined) {
    let may = 0
    Object.values(block).forEach((item) => {
      if (parseInt(item.order) > may) may = parseInt(item.order)
    })
    return parseInt(may) + 1
  } else {
    return 0
  }
}
