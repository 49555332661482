const styles = {
  headerStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { xs: 'column', lg: 'row' },
  },
  titleStyles: {
    fontSize: '1.5rem',
  },
  searchArea: {
    display: 'flex',
    width: { xs: '100%', lg: '50%' },
  },
  stylesBar: {
    flexGrow: 20,
    marginRight: '15px',
  },
}
export default styles
