import { Box, InputAdornment, TextField } from '@mui/material'
import React, { Fragment, useRef, useState } from 'react'
import { Icons } from '../../utils'
import CrudsBtn from '../crudsBtn'
import useStyles from './styles'
import i18n from 'i18n-js'

const InputFile = ({
  value,
  label,
  onChange,
  accept,
  errorMessage,
  fileLink,
  sx,
  type,
  all,
  name,
  externalError,
  startIcon,
  className,
  removeMargins
}) => {
  const [error, setError] = useState(false)
  const classes = useStyles({ error: error || externalError, removeMargins })
  const file = useRef(null)

  const handleFile = (value) => {
    if (
      value.type === 'application/pdf' ||
      (type?.includes(value.type) && type) ||
      all
    ) {
      onChange(value)
      setError(false)
    } else {
      onChange('')
      setError(true)
    }
    file.current.value = ''
  }

  return (
    <Fragment>
      <Box className={classes.containerInputImage}>
        <TextField
          value={value?.name ?? value ?? ' '}
          id="datatel"
          type="text"
          disabled
          fullWidth
          error={error || externalError}
          className={`${classes.file} ${className}`}
          sx={sx ?? {}}
          label={label}
          onClick={() => file.current.click()}
          helperText={
            error || externalError ? externalError || errorMessage : ''
          }
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start">
                {Icons(startIcon, true)}
              </InputAdornment>
            ),
            endAdornment: Icons('folder'),
          }}
        />
        {fileLink && (
          <CrudsBtn
            text={i18n.t('patient.detail.document.view')}
            variant="text"
            href={fileLink}
            target="_blank"
            size="medium"
          />
        )}
      </Box>

      <input
        name={name ?? 'file'}
        type="file"
        onChange={(e) => e.target.files[0] && handleFile(e.target.files[0])}
        hidden
        ref={file}
        accept={accept}
      />
    </Fragment>
  )
}

export default InputFile
