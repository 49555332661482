import I18n from 'i18n-js'
// import { patients } from '../services'
import { isEmail, isEmpty, isName, portugal } from './validator'
export const validatorPatient = async (form, locale, removeEmail, isSameCountryOfTheDoctor) => {
  let errorsAux = {}
  // Validation Step 1
  if (isEmpty(form['name'])) errorsAux.name = I18n.t('validators.required')
  else if (isName(form['name'])) errorsAux.name = I18n.t('validators.name')

  if (isEmpty(form['lastname']))
    errorsAux.lastname = I18n.t('validators.required')
  else if (isName(form['lastname']))
    errorsAux.lastname = I18n.t('validators.lastname')

  if (isEmpty(form['birth'])) errorsAux.birth = I18n.t('validators.required')

  if (isEmpty(form.sex === 0 ? 'F' : form.sex))
    errorsAux.sex = I18n.t('validators.required')

  if (isEmpty(form['height'])) errorsAux.height = I18n.t('validators.required')
  else if (form['height'] < 1 || form['height'] > 999)
    errorsAux.height = I18n.t('validators.invalidHeight')

  if (isEmpty(form['weight'])) errorsAux.weight = I18n.t('validators.required')
  else if (form['weight'] < 1 || form['weight'] > 999)
    errorsAux.weight = I18n.t('validators.invalidWeight')

  if (isEmpty(form.right_hand === 0 ? 'Gaucher' : form.right_hand))
    errorsAux.right_hand = I18n.t('validators.required')

  if (isEmpty(form['place_birth']))
    errorsAux.place_birth = I18n.t('validators.required')

  if (isEmpty(form['country_birth']))
    errorsAux.country_birth = I18n.t('validators.required')

  if (isEmpty(form['phone'])) errorsAux.phone = I18n.t('validators.required')
  else if (form['phone'].length < 11)
    errorsAux.phone = I18n.t('validators.invalidPhone')

  if (!removeEmail) {
    if (isEmpty(form['email'])) errorsAux.email = I18n.t('validators.emplyEmail')
    else if (isEmail(form['email']))
      errorsAux.email = I18n.t('validators.invalidEmail')
  }

  // Protugal
  if (locale === 'pt' && isSameCountryOfTheDoctor) {
    if (form['cc']?.length > 0 && form['cc']?.length < 8)
      errorsAux.cc =
        I18n.t('validators.minLength') + ' 8 ' + I18n.t('validators.num')

    if (form['nif']?.length > 0 && form['nif']?.length < 9)
      errorsAux.nif =
        I18n.t('validators.minLength') + ' 9 ' + I18n.t('validators.num')

    if (form['niss']?.length > 0 && form['niss']?.length < 11)
      errorsAux.niss =
        I18n.t('validators.minLength') + ' 11 ' + I18n.t('validators.num')

    if (form['sns']?.length > 0 && form['sns']?.length < 9)
      errorsAux.sns =
        I18n.t('validators.minLength') + ' 9 ' + I18n.t('validators.num')
  }

  // France
  if (locale === 'fr' && isSameCountryOfTheDoctor) {
    if (form['state_id'] !== undefined && isEmpty(form['state_id']))
      errorsAux.state_id = I18n.t('validators.required')

    if (form['community_id'] !== undefined && isEmpty(form['community_id']))
      errorsAux.community_id = I18n.t('validators.required')
  }

  // Spain
  if (locale === 'es' && isSameCountryOfTheDoctor) {
    if (portugal(form['dni']) && isSameCountryOfTheDoctor) errorsAux.dni = I18n.t('validators.required')

    if (portugal(form['nuss']) && isSameCountryOfTheDoctor) errorsAux.nuss = I18n.t('validators.required')
  }

  // when the country_birth is an specific country (portugal, spain, france) and the new field is empty it will disable the button
  if (
    locale === 'fr' && isSameCountryOfTheDoctor &&
    (isEmpty(form['state_id']) || isEmpty(form['community_id']))
  )
    errorsAux.error = 'error'
  if (
    locale === 'es' && isSameCountryOfTheDoctor &&
    (isEmpty(form['dni']) || isEmpty(form['nuss']))
  )
    errorsAux.error = 'error'
  if (
    locale === 'pt' && isSameCountryOfTheDoctor &&
    (isEmpty(form['cc']) ||
      isEmpty(form['nif']) ||
      isEmpty(form['niss']) ||
      isEmpty(form['sns']) ||
      isEmpty(form['comments']) ||
      isEmpty(form['expire_date']))
  ) {
    errorsAux.error = 'error'
  }
  return errorsAux
}
