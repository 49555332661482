import { Accordion, AccordionDetails, AccordionSummary, Box, CardMedia, IconButton, Typography } from "@mui/material"
import { graphIds } from "../../smartPredict/constants/bioimpedance"
import { Icons } from "../../../../../utils"
import { useGraphicWithPreviewStyles } from "./graphicWithPreviewStyles"
import I18n from "i18n-js"
import { useState } from "react"

const ChooseCardType = ({ expandProps, item }) => {
    const styles = useGraphicWithPreviewStyles()
    const showTitlte = graphIds.includes(item.id)
    const [open, setOpen] = useState(false)
    return (
        <Accordion expanded={open}>
            <AccordionSummary sx={styles.summary}>
                <Box sx={styles.containerSummary}>
                    <Box sx={styles.header}>
                        <Typography variant="subtitle2" sx={[styles.name, styles.headerTitle]}>{item.name}</Typography>
                        {
                            !!showTitlte && (
                                <IconButton onClick={(e) => {
                                    e.stopPropagation()
                                    setOpen(!open)
                                }} >{Icons(open ? 'arrowUp' : 'arrowDown')}</IconButton>

                            )
                        }
                    </Box>


                    <CardMedia
                        sx={styles.image}
                        component="img"
                        height="140"
                        image={item.graph}
                    />
                </Box>
                <IconButton {...expandProps} sx={styles.expand}>{Icons('aspect-ration')}</IconButton>
            </AccordionSummary>

            {!!showTitlte && (
                <AccordionDetails>
                    <Typography variant="body1" sx={styles.title}>
                        {I18n.t('patient.detail.tabSmartPredict.cardTab.description')}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        component="span"
                        sx={styles.name}
                    >
                        {item?.description}
                    </Typography>
                </AccordionDetails>

            )}
        </Accordion>
    )
}

export { ChooseCardType }
