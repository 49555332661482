import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles({
  root: {
    borderRadius: '2px',
    maxWidth: '800px',
    padding: 0,
  },
  //body
  body: {
    display: 'flex',
    ['&.MuiCardContent-root:last-child']: {
      padding: '0px 0px 0 16px',
    },
    height: '80px',
  },

  //icon
  icon: {
    fontSize: '55px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  img: { width: '100%', objectFit: 'cover' },

  //information
  information: {
    flex: 1,
    margin: '0px 0 0px 12px',
    ['& #tugTitle']: { marginLeft: '8px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  centerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  dataContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    marginLeft: '8px',
  },

  iconButtons: {
    marginTop: '2px',
    color: colors.green,
  },

  spaceIcons: {
    marginLeft: '4px',
  },
  //notification

  notification: {
    width: '70px',
    borderRadius: '50px 0px 0px 50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ['& h4']: { color: colors.white },
  },
  success: { background: colors.primary },
  warning: { background: colors.warning },
  danger: { background: colors.danger },
  disabled: { background: colors.disabled },
  ok: { background: colors.success },
  care: { background: colors.care },
})

export default useStyles
