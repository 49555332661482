import { colors } from '../../../../utils'
import { makeStyles } from '@mui/styles'
export const styles = {
  container: {
    width: '100%',
  },
  cardGreen: {
    background: colors.blueInput,
    boxShadow: `1px 1px 2px ${colors.greyPlaceholder}`
  },
  borders: {
    borderWidth: '1px',
    borderColor: colors.greyPlaceholder,
    borderStyle: 'solid',
    margin: '5px 0',
    boxShadow: `1px 1px 2px ${colors.greyPlaceholder}`
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '5px',
  },
  date: {
    flex: 0.75,
    fontSize: '14px',
    marginLeft: '10px',
  },
  pdf: {
    flex: 0.25,
    justifyContent: 'end',
  },
  text: {
    fontSize: '14px',
  },
  colorWhite: {
    color: colors.white,
  },
  modal: {
    padding: '15px 20px',
  },
  textModal: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  buttonModal: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  acceptedBtn: {
    borderRadius: '0',
    marginLeft: '10px',
  },
}

export const useStyles = makeStyles({
  input: {
    width: '100%',
  },
})
