import { Fragment, useEffect, useState } from 'react'
import { Box, Typography, TextField, IconButton } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import I18n from 'i18n-js'
import languages from '../../utils/languages'
import Icons from '../../utils/icons'
import { QuestionViewer, QuestionTranslate } from '..'
import useStyles from './style'

const ItemsLenght = (items, questions) => {
  if (questions) {
    const itemsQuestionary = []
    Object.values(questions).map(() => {
      itemsQuestionary.push({
        name: 'title',
        questionary: true,
      })
    })
    return items.concat(itemsQuestionary)
  } else {
    return items
  }
}
const setInitialvalues = (values, items, translations, locale) => {
  let data = {}
  if (values[locale].questions) {
    //name and instructions
    translations.map((translation) => {
      items.map((item) => {
        data[translation.locale] = {
          ...data[translation.locale],
          [item.name]:
            values.traductions?.[translation.locale]?.[item.name] || '',
        }
      })
      //questions
      Object.values(values[locale].questions).map((question, questionId) => {
        data[translation.locale] = {
          ...data[translation.locale],
          questions: {
            ...data[translation.locale].questions,
            [questionId]: {
              title:
                values.traductions?.[translation.locale]?.questions?.[
                  questionId
                ]?.title || '',
            },
          },
        }
        Object.values(question.options).map((option, optionId) => {
          data[translation.locale] = {
            ...data[translation.locale],
            questions: {
              ...data[translation.locale].questions,
              [questionId]: {
                title:
                  values.traductions?.[translation.locale]?.questions?.[
                    questionId
                  ]?.title || '',

                options: {
                  ...data[translation.locale].questions[questionId].options,
                  [optionId]: {
                    title:
                      values.traductions?.[translation.locale]?.questions?.[
                        questionId
                      ]?.options?.[optionId]?.title || '',
                  },
                },
              },
            },
            locale: translation.locale,
          }
        })
      })
    })
  } else {
    translations.map((translation) => {
      items.map((item) => {
        data[translation.locale] = {
          ...data[translation.locale],
          [item.name]:
            values.traductions?.[translation.locale]?.[item.name] || '',
          locale: translation.locale,
        }
      })
    })
  }

  return data
}
const LanguajeSettingsForm = ({ items, values, locale, setCreateData }) => {
  const translations = languages.filter((language) => language.locale != locale)
  const [data, setData] = useState(
    setInitialvalues(values, items, translations, locale)
  )
  const [itemSelected, setItemSelected] = useState(0)
  const itemsLanguage = ItemsLenght(items, values[locale].questions)

  const questions = Object.values(values[locale].questions || {})
  const [questionSelected, setQuestionSelected] = useState(-items.length)

  const handleNextItem = () => {
    setItemSelected(itemSelected + 1)
    setQuestionSelected(questionSelected + 1)
  }
  const handleBeforeItem = () => {
    setItemSelected(itemSelected - 1)
    setQuestionSelected(questionSelected - 1)
  }
  const handleChangeData = (e) => {
    setData({
      ...data,
      [e.target.name]: {
        ...data[e.target.name],
        [itemsLanguage[itemSelected].name]: e.target.value,
        locale: e.target.name,
      },
    })
  }
  useEffect(() => {
    setCreateData({ ...values, traductions: data })
  }, [data])
  const classes = useStyles()
  return (
    <Fragment>
      <Box className={classes.headerContainer}>
        <Typography variant="body1" color="initial">
          {I18n.t('cruds.profiles.create.translateTitle')}
        </Typography>
        <Box className={classes.selectInputContainer}>
          <Box className={classes.selectInput}>
            <IconButton
              size="small"
              disabled={itemSelected == 0}
              onClick={handleBeforeItem}
            >
              <NavigateBefore />
            </IconButton>
            <Typography variant="h6">
              {`${itemSelected + 1}/${itemsLanguage.length}`}
            </Typography>
            <IconButton
              size="small"
              disabled={itemSelected == itemsLanguage.length - 1}
              onClick={handleNextItem}
            >
              <NavigateNext />
            </IconButton>
          </Box>
          <Typography variant="caption" color="textSecondary">
            {I18n.t('cruds.profiles.create.textToTranslate')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.infoContainer}>
        {itemsLanguage[itemSelected].questionary ? (
          <QuestionViewer
            number={questionSelected}
            question={questions[questionSelected]}
          />
        ) : (
          <TextField
            variant="outlined"
            multiline={itemsLanguage[itemSelected].multiline}
            rows={itemsLanguage[itemSelected].multiline ? 4 : 0}
            label={I18n.t('cruds.profiles.create.translateLabel')}
            value={values[locale]?.[itemsLanguage[itemSelected].name]||''}
            helperText={I18n.t(itemsLanguage[itemSelected].label)}
            disabled
            className={classes.info}
            fullWidth
          />
        )}
      </Box>
      <Box>
        {data &&
          translations.map((translation, index) => {
            if (itemsLanguage[itemSelected].questionary) {
              return (
                <QuestionTranslate
                  translation={translation}
                  question={questions[questionSelected]}
                  data={data}
                  id={questionSelected}
                  setData={setData}
                  key={index}
                />
              )
            } else {
              return (
                <Box key={index} className={classes.inputsContainer}>
                  <Box className={classes.labelContainer}>
                    {Icons(translation.locale)}
                    <Typography
                      className={classes.label}
                      variant="caption"
                      color="textPrimary"
                    >
                      {I18n.t(translation.name)}
                    </Typography>
                  </Box>
                  <Box className={classes.inputContainer}>
                    <TextField
                      // size="small"
                      name={translation.locale}
                      multiline={itemsLanguage[itemSelected].multiline}
                      rows={itemsLanguage[itemSelected].multiline ? 4 : 0}
                      variant="outlined"
                      value={
                        data[translation.locale]?.[
                          itemsLanguage[itemSelected].name
                        ] || ''
                      }
                      onChange={handleChangeData}
                      color="secondary"
                      className={classes.form}
                    />
                  </Box>
                </Box>
              )
            }
          })}
      </Box>
    </Fragment>
  )
}

export default LanguajeSettingsForm
