import { Box, Icon, IconButton, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { Icons } from '../../utils'
import { useStyles as classes } from './styles'

const AddImage = ({
  icon,
  name,
  getFile,
  image,
  label,
  size = {
    width: 100,
    height: 100,
  },
  accept = '.png, .jpg, .jpeg',
  typeAcceptArray = ['image/png', 'image/jpeg', 'image/jpg'],
}) => {
  const ref = useRef(null)
  const [fileCreated, setFileCreated] = useState(image)

  const handleFile = (e) => {
    const file = e?.target?.files[0]

    if (file?.type && typeAcceptArray?.includes(file?.type)) {
      getFile({
        target: {
          name: name,
          value: file,
        },
      })
      const reader = URL.createObjectURL(file)
      setFileCreated(reader)
    } else {
      getFile({
        target: {
          name: name,
          value: null,
        },
      })
      setFileCreated(null)
    }

    ref.current.value = null
  }

  return (
    <Box sx={classes.relative}>
      {fileCreated && (
        <IconButton
          onClick={() => {
            handleFile()
          }}
          sx={classes.iconClose}
        >
          {Icons('close')}
        </IconButton>
      )}
      
      <IconButton
        sx={{
          '&.MuiIconButton-root': {
            ...classes.iconButton,
            ...size,
            ...(fileCreated && classes.existFile),
          },
        }}
        onClick={() => ref.current.click()}
      >
        {fileCreated ? (
          <>
            <Box
              component="img"
              sx={classes.img}
              alt="image patient"
              src={fileCreated}
            />
          </>
        ) : (
          <>
            <Icon sx={classes.iconSvg}>{icon}</Icon>
            {label && (
              <Typography sx={classes.iconText} variant="caption">
                {label}
              </Typography>
            )}
          </>
        )}
      </IconButton>
      <input
        accept={accept}
        type="file"
        hidden={true}
        ref={ref}
        onChange={handleFile}
      />
    </Box>
  )
}

export default AddImage
