import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { Icons } from '../../../utils'
import useStyles from './styles'
import LanguageSelector from '../../languageSelector'
const TranslateSelected = ({
  onChange,
  languageSelected,
  childrens,
  list,
  title,
  languages,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const handleArrow = () => {
    setOpen(!open)
  }

  return (
    <Grid item lg={6}>
      <Box className={classes.containerFlex}>
        <LanguageSelector
          change={onChange}
          language={languageSelected}
          languages={languages}
        />
      </Box>
      <List className={classes.list}>
        <ListItem className={classes.showItemTitle}>
          <Typography className={classes.childrenLength}>
            {childrens ? childrens : 0}
          </Typography>

          <ListItemText
            className={classes.showItemTitleText}
            primary={title ?? ''}
          />

          <ListItemIcon className={classes.arrow} onClick={() => handleArrow()}>
            {Icons(open ? 'arrowDown' : 'arrowUp')}
          </ListItemIcon>
        </ListItem>
        {list.length > 0 &&
          list?.map(
            (value, index) =>
              open && (
                <ListItem key={index} className={classes.showItem}>
                  <ListItemText
                    className={classes.showItemText}
                    primary={value?.name}
                  />
                </ListItem>
              )
          )}
      </List>
    </Grid>
  )
}

export default TranslateSelected
