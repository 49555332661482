import { useEffect, useState, useContext, useRef } from 'react'
import I18n from 'i18n-js'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import {
  Box,
  Grid,
  Typography,
  Hidden,
  Tooltip,
  useMediaQuery,
  IconButton,
  Switch,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { FiberManualRecord } from '@mui/icons-material'
import { withStyles } from '@mui/styles'

import {
  SearchBar,
  CrudsBtn,
  SnackBar,
  AlertMenu,
  Loading,
} from '../../components'
import patients from '../../services/patients'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import { actionKeys, pagesKeys } from '../../utils'
import { Icons } from '../../utils'
import useStyles from '../crudsStyles'
import pageStyles, { stylesPatient } from './style'
import indicatorsStyles from '../../styles/indicators'
import BaseMenu from '../../components/baseMenu'
import { ItemMenu } from './list'
import MultiSelect from '../../components/multipleAutocomplete'
import { CSVLink } from 'react-csv'

const Patients = () => {
  const classes = useStyles()
  const styles = pageStyles()
  const matches = useMediaQuery('(max-width:1480px)')
  const indicatorsColors = indicatorsStyles()
  const refCsv = useRef(null)

  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  // Nombre, apellido, fecha de nacimiento, sexo, dirección, teléfono,
  // Score de fragilidad, fecha del ultimo smartpredict realizado, personal medical que realizo el smartpredict y
  // la respuesta a la pregunta del cuestionario VDSI
  const [optionMenu, setOptionMenu] = useState([])
  const [exports, setExports] = useState([])

  const [menu, setMenu] = useState([])
  //permissions
  const pageId = pagesKeys.patients

  // const refCsv = useRef(null)
  //search
  const [search, setSearch] = useState('')
  const [autocompleteData, setAutoCompleteData] = useState({
    gender: [],
    score: [],
    values: [],
  })
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setOptionMenu(ItemMenu)
    const entrieArray = Object.entries(ItemMenu)
    setExports(
      entrieArray?.map((item) => ({
        label: I18n.t(`general.patientMenu.${item[0]}`),
        key: item[0],
      }))
    )
    let newMenu = entrieArray?.map(([key, item]) => ({
      value: key,
      children: (
        <FormControlLabel
          sx={styles.label}
          control={
            <Checkbox sx={styles.checkbox} name={key} defaultChecked={item} />
          }
          label={I18n.t(`general.patientMenu.${key}`)}
        />
      ),
    }))
    newMenu = [
      ...newMenu,
      {
        children: (
          <Box
            sx={stylesPatient.downloadContainer}
            onClick={() => {
              refCsv?.current?.click?.()
              setAnchorEl(null)
            }}
          >
            <IconButton>{Icons('download')}</IconButton>

            <Typography sx={stylesPatient.csv}>
              {I18n.t('general.generateFile')}
            </Typography>
          </Box>
        ),
      },
    ]
    setMenu(newMenu)
    setAnchorEl(event.currentTarget)
  }
  const click = (item) => {
    const body = {
      ...optionMenu,
      [item.value]: !optionMenu[item.value],
    }
    const filterBody = Object.entries(body)
      ?.filter(([, item]) => item)
      ?.map((item) => ({
        label: I18n.t(`general.patientMenu.${item[0]}`),
        key: item[0],
      }))

    setExports(filterBody)
    setOptionMenu(body)
  }

  const [filters, setFilters] = useState({
    gender: [
      {
        value: 'F',
        label: I18n.t('general.gender.female'),
      },
      {
        value: 'M',
        label: I18n.t('general.gender.male'),
      },
    ],
    score: [],
    values: [],
  })
  //list
  const [data, setData] = useState([])

  //show alert
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState({
    text: '',
    variant: 'success',
  })

  //delete
  const [patientDelete, setPatientDelete] = useState()
  const [anchor, setAnchor] = useState()

  const [titleVDSI, setTitleVDSI] = useState(null)

  const getData = async () => {
    setLoading(true)
    const json = await patients.list({ I18n })
    let responseVdsi = []
    let score = []

    json?.forEach((item) => {
      const valueVdsi = item?.vdsi?.[0]?.answer?.option
      const valueScore = item?.score

      if (
        valueVdsi &&
        !responseVdsi?.some((itemVdsi) => itemVdsi?.value === valueVdsi)
      ) {
        responseVdsi = [
          ...responseVdsi,
          {
            label: valueVdsi,
            value: valueVdsi,
          },
        ]
      }
      if (
        valueScore &&
        !score?.some((itemScore) => itemScore?.value === valueScore)
      ) {
        score = [
          ...score,
          {
            label: valueScore,
            value: valueScore,
          },
        ]
      }
    })

    score = [
      ...score,
      {
        label: I18n.t(`general.fragilityScore.noScore`),
        value: I18n.t(`general.fragilityScore.noScore`),
      },
    ]

    let item = json.find((e) => e.vdsi && e.vdsi[0]?.title)
    if (item) {
      setTitleVDSI(item.vdsi[0].title)
    }

    setFilters((prev) => ({
      ...prev,
      values: responseVdsi,
      score,
    }))
    
    setData(json?.map(item => ({
      ...item,
      answerVDSI: item?.vdsi?.[0]?.answer?.option ?? ""
    })))
    setLoading(false)
  }

  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }

  // change status
  const handleFilters = ({ target: { value, name } }) => {
    setAutoCompleteData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const changeStatus = async (id, status) => {
    const json = await patients.changeStatus(id, { status: !status })
    if (json.data) {
      setData([
        ...data?.map((item) => {
          if (item.id == id)
            return {
              ...item,
              system_status: !item.system_status,
            }
          return item
        }),
      ])
      setMessage({
        text: 'components.changeStatus',
        variant: 'success',
      })
    } else {
      setMessage({
        text: 'components.errorStatus',
        variant: 'warning',
      })
    }
    setShow(true)
  }

  // remove

  const handleDeleteOpen = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
  }

  const remove = async () => {
    handleDeleteClose()
    const json = await patients.destroy(patientDelete)
    if (json.data)
      setData([...data.filter((item) => item.id !== patientDelete)])
    setMessage({
      text: 'components.alertDelete',
      variant: 'success',
    })
    setShow(true)
    setSearch('')
    document.getElementById('search').value = ''
  }

  useEffect(() => {
    const ac = new AbortController()
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
    return () => {
      ac.abort()
    }
  }, [])

  // Components
  const IndicatorDescription = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
      padding: '10px',
    },
  }))(Tooltip)

  const Icon = ({ props }) => {
    const styles = {
      container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      title: {
        flex: 0.6,
      },
      date: {
        flex: 0.4,
        fontSize: '12px',
      },
    }
    const description = (
      <div>
        <Box sx={styles.container}>
          <Typography sx={styles.title} variant="subtitle1">
            {props?.name}
          </Typography>
          <Typography sx={styles.date} variant="subtitle2">
            {props?.date
              ? format(new Date(props?.date), I18n.t('general.dateTimeFormat'))
              : I18n.t('general.dataNotFound')}
          </Typography>
        </Box>
        <hr />

        <Typography variant="body2">{props?.description}</Typography>
      </div>
    )

    if (props?.name && props?.name !== '') {
      return (
        <IndicatorDescription
          className={styles.tooltip}
          title={description}
          placement="top-start"
        >
          <FiberManualRecord
            className={`${indicatorsColors.indicator} ${
              props?.status ? props?.status : ''
            }`}
          />
        </IndicatorDescription>
      )
    }

    return (
      <FiberManualRecord
        className={`${indicatorsColors.indicator} ${
          props?.status ? props?.status : ''
        }`}
      />
    )
  }

  // Table
  const columns = [
    {
      field: 'name',
      headerName: I18n.t('patient.tableheader.fullName'),

      flex: 1,
    },
    {
      field: 'age',
      headerName: I18n.t('patient.tableheader.age'),
      headerAlign: 'center',
      align: 'center',
      width: 75,
      sortable: false,
      hide: matches,
    },
    {
      field: 'fragilityScore',
      headerName: I18n.t('patient.tableheader.fragilityScore'),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'gray',
      cellClassName: 'gray',
      width: 90,
      sortable: false,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div className={styles.circle}>
          <FiberManualRecord
            className={`${indicatorsColors.indicator} ${row?.fragilityScore?.status}`}
          />
        </div>
      ),
    },
    {
      field: 'I210',
      headerName: 'I210',
      headerAlign: 'center',
      headerClassName: 'gray',
      align: 'center',
      width: 75,
      sortable: false,
      renderHeader: () => Icons('grip'),
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Icon
          props={
            row?.fragilityIndicators?.find(
              (item) => item?.id == 210 || item?.id == 373
            ) || ''
          }
        />
      ),
    },
    {
      field: 'I214',
      headerName: 'I214',
      headerAlign: 'center',
      headerClassName: 'gray',
      align: 'center',
      width: 75,
      sortable: false,
      renderHeader: () => Icons('exhaustion'),
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Icon
          props={
            row?.fragilityIndicators?.find(
              (item) => item?.id == 214 || item?.id == 380
            ) || ''
          }
        />
      ),
    },
    {
      field: 'I232',
      headerName: 'I232',
      headerAlign: 'center',
      headerClassName: 'gray',
      align: 'center',
      width: 75,
      sortable: false,
      renderHeader: () => Icons('weight'),
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Icon
          props={
            row?.fragilityIndicators?.find(
              (item) => item?.id == 232 || item?.id == 403
            ) || ''
          }
        />
      ),
    },
    {
      field: 'I233',
      headerName: 'I233',
      headerAlign: 'center',
      headerClassName: 'gray',
      align: 'center',
      width: 75,
      sortable: false,
      renderHeader: () => Icons('slowness'),
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Icon
          props={
            row?.fragilityIndicators?.find(
              (item) => item?.id == 233 || item?.id == 404
            ) || ''
          }
        />
      ),
    },
    {
      field: 'I236',
      headerName: 'I236',
      headerAlign: 'center',
      headerClassName: 'gray',
      align: 'center',
      width: 75,
      sortable: false,
      renderHeader: () => Icons('physical_activity'),
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Icon
          props={
            row?.fragilityIndicators?.find(
              (item) => item?.id == 236 || item?.id == 406
            ) || ''
          }
        />
      ),
    },
    {
      field: 'nextAppointmentDate',
      headerName: I18n.t('patient.tableheader.fragility'),
      headerAlign: 'center',
      align: 'center',
      width: 120,
      hide: matches,
      sortable: false,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) =>
        row?.nextAppointmentDate.value
          ? format(
              new Date(row?.nextAppointmentDate.value),
              I18n.t('general.dateFormat')
            )
          : I18n.t('general.dataNotFound'),
    },

    {
      field: 'kforce',
      headerName: I18n.t('patient.tableheader.stability'),
      headerAlign: 'center',
      align: 'center',
      width: 100,
      hide: matches,
      sortable: false,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <div className={styles.circle2}>
          <FiberManualRecord
            className={`${indicatorsColors.kforce} ${row?.kforce?.status}`}
          />
          <Typography variant="body2">{row?.kforce?.value}</Typography>
        </div>
      ),
    },
    {
      field: 'vdsi',
      headerName: I18n.t('patient.tableheader.vdsi'),
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      renderHeader: () =>
        titleVDSI ? (
          <Tooltip title={<Typography>{titleVDSI}</Typography>}>
            <Box sx={stylesPatient.icons}>
              <Typography
                sx={{
                  fontSize: '14px',
                  color: `#000000DE`,
                  fontWeight: '450',
                }}
              >
                {I18n.t('patient.tableheader.vdsi')}
              </Typography>{' '}
              {Icons('touch')}
            </Box>
          </Tooltip>
        ) : (
          <Typography
            sx={{
              fontSize: '14px',
              color: `#000000DE`,
              fontWeight: '450',
            }}
          >
            {I18n.t('patient.tableheader.vdsi')}
          </Typography>
        ),
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) =>
        canAccess(actionKeys.list, pageId, state) ? (
          row?.vdsi?.[0]?.datetime ? (
            <Tooltip
              title={
                <Typography className={classes.dateTooltip}>
                  {row?.vdsi?.[0]?.datetime
                    ? `${I18n.t('general.dateOfResponse')}: ${format(
                        new Date(row?.vdsi?.[0]?.datetime),
                        I18n.t('general.dateTimeFormat')
                      )}`
                    : ''}
                </Typography>
              }
            >
              <Box sx={stylesPatient.icons}>
                {row.vdsi?.[0]?.answer?.option ?? 'N/A'}{' '}
                {row.vdsi?.[0]?.answer?.option ? Icons('touch') : ''}
              </Box>
            </Tooltip>
          ) : (
            <Box sx={stylesPatient.icons}>
              {row.vdsi?.[0]?.answer?.option ?? 'N/A'}{' '}
              {row.vdsi?.[0]?.answer?.option ? Icons('touch') : ''}
            </Box>
          )
        ) : (
          <div>{row.system_status ? <Icon /> : Icons('point_disabled')}</div>
        ),
    },
    {
      field: 'status',
      headerName: I18n.t('patient.tableheader.status'),
      align: 'center',
      headerAlign: 'center',
      width: 80,
      sortable: false,
      valueFormatter: ({ value }) => value ?? '',
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) =>
        canAccess(actionKeys.edit, pageId, state) ? (
          <Switch
            color="primary"
            size="small"
            checked={row.system_status}
            onChange={() => changeStatus(row.id, row.system_status)}
          />
        ) : (
          <div>{row.system_status ? <Icon /> : Icons('point_disabled')}</div>
        ),
    },
    {
      field: 'actions',
      headerName: I18n.t('general.actions'),
      headerAlign: 'center',
      width: 170,
      sortable: false,
      valueFormatter: ({ value }) => value ?? '',
      hide:
        canAccess(actionKeys.edit, pageId, state) ||
        canAccess(actionKeys.delete, pageId, state)
          ? false
          : true,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Grid container justifyContent="center">
          {canAccess(actionKeys.detail, pageId, state) && (
            <IconButton
              onClick={() =>
                history.push(`${history.location.pathname}/${row.id}`)
              }
            >
              {Icons('description')}
            </IconButton>
          )}
          {canAccess(actionKeys.edit, pageId, state) && (
            <IconButton
              onClick={() =>
                history.push(`${history.location.pathname}/edit/${row.id}`)
              }
              color="secondary"
            >
              {Icons('edit')}
            </IconButton>
          )}
          {canAccess(actionKeys.delete, pageId, state) && (
            <IconButton
              onClick={(e) => {
                setPatientDelete(row.id)
                handleDeleteOpen(e)
              }}
            >
              {Icons('delete')}
            </IconButton>
          )}
        </Grid>
      ),
    },
  ]

  const rows = data

  const filterData = (rows) => {
    const filtered = rows
      ?.filter(
        (item) =>
          (autocompleteData?.score?.length
            ? autocompleteData?.score?.some(
                (itemValue) => itemValue?.value === item.score
              )
            : true) &&
          (autocompleteData?.gender?.length
            ? autocompleteData?.gender?.some(
                (itemValue) =>
                  itemValue?.value?.toLowerCase() === item.gender?.toLowerCase()
              )
            : true) &&
          (autocompleteData?.values?.length
            ? autocompleteData?.values?.some(
                (itemValue) =>
                  itemValue?.value?.toLowerCase() ===
                  item?.vdsi?.[0]?.answer?.option?.toLowerCase()
              )
            : true) &&
          `
        ${item.name.toLowerCase()} 
        ${item?.birthday?.toString()} 
        ${item.phone?.toString()} 
        ${item.age?.toString()?.toLowerCase()} 
        ${item.score?.toString()?.toLowerCase()}
        ${item.gender?.toString()?.toLowerCase()}
        ${item?.vdsi?.[0]?.answer?.option?.toString()?.toLowerCase()}
        `.includes(search?.toLowerCase())
      )
      // ?.map((item) => ({
      //   id: item.id,
      //   name: item?.name,
      //   birthday: item?.birthday,
      //   sex: item?.sex,
      //   address: item?.address,
      //   phone: item?.phone,
      //   score: item?.score,
      //   dateEnd: item?.dateEnd,
      //   medical_staff: item?.medical_staff,
      //   value: item?.vdsi?.[0]?.answer?.option,
      // })) || []
      
    return filtered
  }

  return (
    <Box p={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.patient')} / {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography variant="h5" color="primary" className={classes.title}>
            {I18n.t('patient.title')}
          </Typography>

          {canAccess(actionKeys.list, pageId, state) && (
            <>
              <CSVLink
                data={filterData(rows)}
                headers={exports}
                filename={`${I18n.t('patient.title')}.csv`}
              >
                <IconButton ref={refCsv}></IconButton>
              </CSVLink>

              <CrudsBtn
                sx={stylesPatient.downloadCsv}
                text={I18n.t('general.downloadCsv')}
                size="medium"
                click={handleClick}
              />

              <BaseMenu
                sx={stylesPatient.menu}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                menuItems={menu}
                click={click}
                oncesYouClickDoNotCloseIt
              />

              <CrudsBtn
                text={I18n.t('cruds.patients.button')}
                size="medium"
                click={() =>
                  history.push(`${history.location.pathname}/create`)
                }
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} className={styles.searchContainer}>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <Grid
                container
                spacing={2}
                className={styles.searchContainer}
                justifyContent="end"
              >
                <Grid item xs={3}>
                  <MultiSelect
                    name="score"
                    label={I18n.t('general.patientMenu.score')}
                    onChange={handleFilters}
                    items={filters?.score || []}
                    selected={autocompleteData.score ?? []}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MultiSelect
                    name="gender"
                    label={I18n.t('general.gender.gender')}
                    onChange={handleFilters}
                    items={filters?.gender || []}
                    selected={autocompleteData.gender ?? []}
                  />
                </Grid>
                <Grid item xs={2}>
                  <MultiSelect
                    name="values"
                    label={I18n.t('patient.tableheader.vdsi')}
                    onChange={handleFilters}
                    items={filters?.values || []}
                    selected={autocompleteData.values ?? []}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.search}>
                    <SearchBar change={handleChangeSearchbar} />
                  </div>
                </Grid>
              </Grid>
            </Hidden>
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} />
            </Grid>
          </Hidden>
        )}

        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            {loading ? (
              <Loading />
            ) : (
              <div style={{ width: '100%', background: 'white' }}>
                <DataGrid
                  className={[styles.table, classes.datagrid]}
                  columns={columns}
                  rows={filterData(rows)}
                  autoHeight
                  pagination
                  pageSize={10}
                  initialState={{
                    ...data.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  rowsPerPageOptions={[10]}
                  disableColumnMenu
                  disableSelectionOnClick
                />
              </div>
            )}
          </Grid>
        )}
      </Grid>
      <SnackBar
        text={I18n.t(message.text)}
        severity={message.variant}
        show={show}
        HandleShow={setShow}
        time={1500}
      />
      <AlertMenu anchor={anchor} close={handleDeleteClose} action={remove} />
    </Box>
  )
}

export default Patients
