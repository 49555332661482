import ReactDOM from 'react-dom'
import { LogOutModal } from '.'

const createLogOutElement = () => {
  const logout = document.getElementById('logout')
  if (logout) {
    ReactDOM.render(<LogOutModal />, logout)
  } else {
    const newLogout = document.createElement('div')
    newLogout.setAttribute('id', 'logout')
    document.body.appendChild(newLogout)
    document.body.style.overflow = 'hidden'
    ReactDOM.render(<LogOutModal />, document.getElementById('logout'))
  }
}

const removeLogOutModal = () => {
  localStorage.clear()
  
  setTimeout(() => {
    const logout = document.getElementById('logout')
    logout.remove()
    document.body.removeAttribute('overflow')
    window.location = '/'
  }, 300)
}

export { createLogOutElement, removeLogOutModal }
