import { Fragment, useState, useContext } from 'react'
import { useHistory } from 'react-router'
import I18n from 'i18n-js'
import rol from '../../utils/rols'
import { AuthContext, loginUser } from '../../context/auth'
import FormTwoFactor from '../../components/formTwoFactor'
import { SnackBar } from '../../components'
import { AuthLayout } from '../../components/authLayout'
import { codeVerification } from '../../utils/enum'

const TwoFactor = () => {
  const history = useHistory()
  const { state, dispatch, setUser } = useContext(AuthContext)
  const [data, setData] = useState({ code_verification: '' })
  const [errMsj, setErrMsj] = useState({ error: false, message: '' })
  const [disabled, setDisabled] = useState(false)
  const firstTime = state?.user?.firstTime || false
  const auth = firstTime ? state?.authenticator : {}
  const login_method =
    state?.error?.error?.login_method ?? state?.error?.login_method
  const [codeSms, setCodeSms] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const handleChange = (e) => {
    setErrMsj({ error: false, message: '' })
    setData({
      ...data,
      [e.target.id]: e.target.value,
    })
    if (e.target.value.length === 6) {
      handleSubmit(e.target.value)
    }
  }

  const resendCode = async () => {
    setDisabled(true)
    setErrMsj({ error: false, message: '' })
    const res = await loginUser(
      dispatch,
      {
        email: state.user?.email,
        password: state.user?.password,
      },
      setUser
    )
    if (res?.error?.code_error_login === '02') {
      setCodeSms({
        open: true,
        message: 'auth.codeWasSend',
        severity: 'success',
      })
    } else {
      setCodeSms({
        open: true,
        message: 'auth.codeWasNotSend',
        severity: 'error',
      })
    }
    setDisabled(false)
  }
  
  const handleSubmit = async (code) => {
    setDisabled(true)
    setErrMsj({ error: false, message: '' })
    const body = {
      email: state.user.email,
      password: state.user.password,
      code_verification: code,
      login_method,
    }
    
    const res = await loginUser(dispatch, body, setUser)
    if (res.error) {
      setErrMsj({
        error: true,
        message:
          res.error.code_error_login === '10'
            ? 'auth.invalidSmsCode'
            : 'auth.invalidCode',
      })
    } else if (res.data) {
      if (res.data.user.id_rol === rol.staff)
        history.push('/dashboard/patients')
      else if (res.data.user.id_rol === rol.center) {
        if (res.data.user.id_medical_center) {
          history.push(
            `/dashboard/medical-center/${res.data.user.id_medical_center}`
          )
        } else {
          history.push('/dashboard')
        }
      } else {
        history.push('/dashboard')
      }
    }
    setDisabled(false)
  }
  if (!state.user) {
    history.push('/')
  }

  return (
    <AuthLayout
      subtitle={I18n.t(
        login_method === codeVerification.twilio
          ? 'auth.authTwilio'
          : 'auth.authTwoFactor'
      )}
    >
      <Fragment>
        <SnackBar
          text={I18n.t(codeSms?.message)}
          severity={codeSms?.severity}
          show={codeSms?.open}
          HandleShow={() => {
            setCodeSms((prev) => ({ ...prev, open: false }))
          }}
          time={3000}
        />

        <FormTwoFactor
          resendCode={resendCode}
          login_method={login_method}
          change={handleChange}
          submit={handleSubmit}
          errMsj={errMsj}
          firstTime={firstTime}
          auth={auth}
          disabled={disabled}
        />
      </Fragment>
    </AuthLayout>
  )
}

export default TwoFactor
