import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import { ResetPassword as resetPassword } from '../../context/auth'
import FormResetPassword from '../../components/formResetPassword'
import ResetPasswordValidator from '../../validators/resetPasswordValidator'
import { AuthLayout } from '../../components/authLayout'

const ResetPassword = () => {
  const history = useHistory()
  const [showpassword, setShowpassword] = useState(false)
  const [showpassword2, setShowpassword2] = useState(false)
  const [requirements, setRequirements] = useState(0)
  const [openAlert, setOpenAlert] = useState(false)
  const [success, setsuccess] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [disabled, setDisabled] = useState(true)
  const { token } = useParams()
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value,
    })
  }
  useEffect(() => {
    const info = ResetPasswordValidator(data)
    setRequirements(info.valid)
    if (info.blankSpaces || !info.matches) {
      setDisabled(true)
      setErrors(info)
    }
    if (
      !info.blankSpaces &&
      info.matches &&
      info.valid === 100 &&
      !info.confirmationEmply &&
      !info.invalidCharacter
    ) {
      setDisabled(false)
      setErrors(info)
    }
    if (
      info.blankSpaces ||
      !info.matches ||
      info.valid < 100 ||
      info.confirmationEmply ||
      info.invalidCharacter
    ) {
      setDisabled(true)
      setErrors(info)
    }
  }, [data])
  const handleSubmit = async (e) => {
    e.preventDefault()
    data.token = token
    const res = await resetPassword(data)

    if (res.error?.code_error === '02') {
      setOpenAlert(true)
      setsuccess(false)
    } else if (res.error?.error) {
      history.push('/')
    } else {
      setOpenAlert(true)
      setsuccess(true)
    }
  }
  const dataSend = () => {
    setOpenAlert(false)
    history.push('/')
  }

  return (
    <AuthLayout>
      <FormResetPassword
        change={handleChange}
        submit={handleSubmit}
        showpassword={showpassword}
        showpassword2={showpassword2}
        setShowpassword={setShowpassword}
        setShowpassword2={setShowpassword2}
        requirements={requirements}
        errors={errors}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        success={success}
        dataSend={dataSend}
        disabled={disabled}
      />
    </AuthLayout>
  )
}

export default ResetPassword
