import useStyles from './style'
import DashboardRouter from '../../router/dashboard'
import { Typography } from '@mui/material'
import I18n from 'i18n-js'

const DashboardContainer = ({ drawerWidth }) => {
  const classes = useStyles(drawerWidth)
  return (
    <div className={classes.container}>
      <div className={classes.page}>
        <DashboardRouter />
      </div>
      <div className={classes.footer}>
        <Typography variant="body2" color="textSecondary" align="center">
          {I18n.t('general.copyright')}
        </Typography>
      </div>
    </div>
  )
}

export default DashboardContainer
