import { login, generatePassword, resetPassword } from '../../services/auth'
import contextkeys from '../../utils/contextkeys'
import { rolAdmin } from '../../router/adminRole'
import { rolStaff } from '../../router/staffRole'
import { rolCenter } from '../../router/centerRole'
import { encryptData } from '../../utils/encrypt'
import rol from '../../utils/rols'
export const loginUser = async (dispatch, body, setUser) => {
  dispatch({ type: contextkeys.requestLogin })
  const json = await login(body)
  if (json.data) {
    if (!json.data.authenticator) {
      let state = {
        token: json.data.token,
        locale: json.data.user.locale,
        name: json.data.user.name,
        lastname: json.data.user.lastname,
        email: json.data.user.email,
        phone: json.data.user.phone,
      }
      if (json.data.user.id_rol === rol.admin) {
        state.permissions = encryptData(rolAdmin)
      } else if (json.data.user.id_rol === rol.staff) {
        state.permissions = encryptData(rolStaff)
      } else if (json.data.user.id_rol === rol.center) {
        state.permissions = encryptData(rolCenter)
      }

      localStorage.setItem('state', JSON.stringify(state))
      setUser(state)
      dispatch({
        type: contextkeys.loginSuccess,
        payload: {
          ...state,
          error: null,
        },
      })
      return { data: json.data, error: null }
    } else {
      localStorage.setItem('state', JSON.stringify(json.data))
      dispatch({
        type: contextkeys.loginSuccess,
        payload: {
          ...json.data,
          error: null,
        },
      })
      return { data: json.data, error: null }
    }
  } else {
    dispatch({ type: contextkeys.loginError, payload: { error: json.error, login_method: body?.login_method } })
  }
  return {
    data: null,
    error: { ...json.error, login_method: body?.login_method },
  }
}

export const logout = async (dispatch) => {
  dispatch({ type: contextkeys.logout })
  await localStorage.removeItem('state')
}

export const GeneratePassword = async (body) => {
  const json = await generatePassword(body)
  return json
}

export const ResetPassword = async (body) => {
  const json = await resetPassword(body)
  return json
}
