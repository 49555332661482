// import { useContext } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
} from '@mui/material'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import PhoneIcon from '@mui/icons-material/Phone'
import Map from '../map'
import I18n from 'i18n-js'
// import { AuthContext } from '../../context/auth'
// import { canAccess } from '../../router/permissions'
// import { actionKeys, pagesKeys } from '../../utils'
import useStyles from './style'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const CardMap = ({ center, data }) => {
  //state
  // const { state } = useContext(AuthContext)
  const history = useHistory()
  // const viewDetail = canAccess(
  //   actionKeys.detail,
  //   pagesKeys.medicalcenter,
  //   state
  // )
  const centerXY = {
    lat: parseFloat(
      center?.latitud && center?.latitud !== null
        ? center.latitud
        : '0.00000000000000000000'
    ),
    lng: parseFloat(
      center?.longitud && center?.longitud !== null
        ? center.longitud
        : '0.00000000000000000000'
    ),
  }

  const classes = useStyles()
  return (
    <Card className={classes.card}>
      {/* <CardHeader
        title={center?.name}
        titleTypographyProps={{ className:  }}
        subheader={center?.address}
        component={viewDetail ? Link : 'Link'}
        to={`/dashboard/medical-center/${center.id}`}
        className={viewDetail ? classes.cardLink : classes.cardHeader}
      /> */}
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Typography
              onClick={() => {
                history.push(`/dashboard/medical-center/${center.id}`)
              }}
              className={classes.title}
            >
              <span className="title">{center?.name}</span>
              <br />
              <span className="subtitle">{center?.address}</span>
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Box className={classes.icons}>
              <div className={classes.itemContainer}>
                <SupervisorAccountIcon />
                
                <Typography variant="body1" className={classes.textSeparation}>
                  {center.patients_count + ' ' + I18n.t('components.homeCard')}
                </Typography>
              </div>

              <div className={classes.itemContainer}>
                <PhoneIcon />

                <Typography variant="body1" className={classes.textSeparation}>
                  {center.phone}
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <CardMedia>
        <Map center={centerXY} data={data} />
      </CardMedia>
    </Card>
  )
}

export default CardMap
