import { Fragment, useEffect, useState } from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from '@mui/material'
import I18n from 'i18n-js'
import { Icons } from '../../utils/icons'
import { useStyles, useSxStyles } from './style'
import { useLocation } from 'react-router'

const DrawerList = ({
  drawerOpen,
  sidebarOptions,
  getOptions,
  handleOptions,
}) => {
  const parent = sidebarOptions.filter((option) => option.parentId == null)
  const childrens = sidebarOptions.filter((option) => option.parentId != null)
  const location = useLocation()
  const styles = useSxStyles()
  const classes = useStyles()
  const [currentPath, setCurrentPath] = useState(
    location?.pathname?.split('/')
  )

  const markCurrentPath = (option) => {
    return (
      option?.slug?.includes(currentPath[2]) ||
      option?.childrens?.includes(currentPath[2]) ||
      (option.slug == `/${currentPath[1]}` && !currentPath[2])
    )
  }

  useEffect(() => {
    setCurrentPath(location?.pathname?.split('/'))
  }, [location?.pathname])

  return (
    <div>
      {parent.map((option, index) => {
        return (
          <Fragment key={index}>
            <ListItem
              button
              sx={
                drawerOpen
                  ? styles.iconOpen({ mark: markCurrentPath(option) })
                  : styles.iconClose({ mark: markCurrentPath(option)})
              }
              onClick={() => {
                handleOptions(option)
              }}
            >
              <ListItemIcon
                sx={styles.itemIcon({
                  open: drawerOpen,
                  mark: markCurrentPath(option),
                })}
              >
                {Icons(option.icon)}
              </ListItemIcon>
              <ListItemText primary={drawerOpen ? I18n.t(option.name) : ''} />

              {!option.slug &&
                drawerOpen &&
                (!getOptions(option.id)
                  ? Icons('expandMore')
                  : Icons('expandLess'))}
            </ListItem>

            {!option.slug && drawerOpen && (
              <Collapse
                in={getOptions(option.id)}
                timeout="auto"
                unmountOnExit
                className={classes.collapse}
              >
                {childrens.map((children, index) => {
                  return (
                    <Fragment key={index}>
                      {children.parentId == option.id && (
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            onClick={() => handleOptions(children, true)}
                          >
                            <ListItemText
                              primary={I18n.t(children.name)}
                              className={classes.subitemText}
                            />
                          </ListItem>
                        </List>
                      )}
                    </Fragment>
                  )
                })}
              </Collapse>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}

export default DrawerList
