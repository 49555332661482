const stepEnum = {
  smart_questionnaire: 'cruds.smartTest.smartQuestionnaire',
  xsens: 'cruds.smartTest.xsens',
  questionnaire: 'cruds.smartTest.questionarie',
  smart_test: 'cruds.smartTest.smartTest',
  kforce_strength: 'cruds.smartTest.kforceStrength',
  kforce_balance: 'cruds.smartTest.kforceBalance',
  teleconsultation: 'cruds.smartTest.teleconsultation',
  kinvent_grip: 'cruds.smartTest.kinvent_grip',
  kinvent_gait: 'cruds.smartTest.kinvent_gait',
  kinvent_stance: 'cruds.smartTest.kinvent_stance',
  kinvent_singlemove: 'cruds.smartTest.kinvent_singlemove',
  kinvent_open: 'cruds.smartTest.kinvent_open',
  aminogram_test: 'cruds.smartTest.aminogram_test',
  kinvent_gait_no_cal: 'cruds.smartTest.kinvent_gait_no_cal',
  kinvent_gait_three_no_cal : 'cruds.smartTest.kinvent_gait_three_no_cal',
}

export default stepEnum
