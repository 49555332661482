const useSortForSend = (data, locale, type = 1) => {
  let name, instructions, questions

  if (Object.values(data.traductions).length == 0) {
    name = [{ value: data[locale].name, locale: locale }]

    instructions = [
      {
        value: data[locale].instructions,
        locale: locale,
      },
    ]

    questions = Object.values(data[locale].questions).map((question) => {
      const questionData = {
        title: [{ value: question.title, locale: locale }],
        type: question.type,
        mandatory: question.mandatory || false,
        options: Object.values(question.options).map((option) => {
          const optionData = {
            score: option.score || null,
            title: [{ value: option.title || '', locale: locale }],
          }
          if (type > 1) {
            optionData.id = option.id || null
          }
          return optionData
        }),
      }
      if (type > 1) {
        questionData.id = question.id || null
      }
      return questionData
    })
  } else {
    name = Object.values(data.traductions).map((traduction) => {
      return { value: traduction.name || '', locale: traduction.locale }
    })
    name.push({ value: data[locale].name || '', locale: locale })

    instructions = Object.values(data.traductions).map((traduction) => {
      return {
        value: traduction.instructions || '',
        locale: traduction.locale,
      }
    })

    instructions.push({
      value: data[locale].instructions,
      locale: locale,
    })
    const questionsId = Object.keys(data[locale].questions)

    questions = Object.values(data[locale].questions).map((question, index) => {
      const title = Object.values(data.traductions).map((traduction) => {
        return {
          value: traduction.questions[questionsId[index]]?.title || '',
          locale: traduction.locale,
        }
      })
      title.push({ value: question.title, locale: locale })

      const optionsId = Object.keys(question.options)

      const questionData = {
        title: title,
        type: question.type,
        mandatory: question.mandatory || false,
        options: Object.values(question.options).map((option, num) => {
          /* Search all the titles from traductions */
          const title = Object.values(data.traductions).map((traduction) => {
            return {
              value:
                traduction.questions[questionsId[index]]?.options?.[
                  optionsId[num]
                ]?.title || '',
              locale: traduction.locale,
            }
          })
          /* push the title option from the locale language */
          title.push({ value: option.title || '', locale: locale })
          const optionData = {
            score: option.score || null,
            title: title,
          }
          if (type > 1) {
            optionData.id = option.id || null
          }
          return optionData
        }),
      }
      if (type > 1) {
        questionData.id = question.id || null
      }
      return questionData
    })
  }
  return {
    name: name,
    instructions: instructions,
    questions: questions,
  }
}

export default useSortForSend
