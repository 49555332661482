import { languages } from '../../utils'

const handleInitialLanguages = () => {
  let initialLanguages = {}

  languages.forEach((_, index) => {
    initialLanguages = {
      ...initialLanguages,
      [`item${index}`]: '',
    }
  })

  return initialLanguages
}

export default handleInitialLanguages
