import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles({
  container: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    overflow: 'hidden',
    border: `1px solid ${colors.greyFull}`,
  },
  Big: {
    width: 50,
    height: 50,
  },
  Right: {
    marginRight: 5,
  },
  Left: {
    marginLeft: 5,
  },
  images: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
})

export default useStyles
