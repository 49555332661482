import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles((theme) => ({
  nameContainer: {
    width: '43%',
    marginBottom: '16px',
    minHeight: '61px',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
  },

  form: {
    background: colors.white,
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '2px',
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  formContainer: {
    minHeight: '223px',
  },
}))
export default useStyles
