import { downloadBlob, GET } from '../utils/crud'
// import I18n from 'i18n-js'
const patient = async (patientId) => {
  const res = await GET(`patients-smart-predict/${patientId}`)
  return res
}
export const anonymizedZip = async (id, date) => {
  const res = await downloadBlob(`anonymized/zip?patient_id=${id}&date=${date}`)
  return res
}

const history = async (patientId) => {
  let results = await GET('historical-smart-predict-patient/' + patientId)

  if(results.data !== null) {
    return results.data.map((data) => ({
      ...data,
      infTab: {
        fragilite: [
          ...data.fragilite.fragility_fried,
          ...data.fragilite.fragility_indicators,
        ],
        marche: data.bilan_marche,
        physiologique: data.physiologique,
        psychologique: data.psychologique,
      },
    }))
  }
  
  return []
}

export default {
  patient,
  history,
}
