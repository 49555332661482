import { Fragment, useEffect, useState } from 'react'
import { Box, Typography, TextField, IconButton } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import I18n from 'i18n-js'
import { languages, Icons } from '../../../utils'
import {
  AnnotationViewer,
  AnnotationTranslate,
} from '../../../components'
import useStyles from './style'

const ItemsLenght = (items, annotations) => {
  if (annotations) {
    const itemsAnnotations = []
    Object.values(annotations).map(() => {
      itemsAnnotations.push({
        name: 'title',
        annotation: true,
      })
    })
    return items.concat(itemsAnnotations)
  } else {
    return items
  }
}
const setInitialvalues = (values, items, translations, locale) => {
  let data = {}
  if (values[locale].annotations) {
    //name and instructions
    translations.map((translation) => {
      items.map((item) => {
        data[translation.locale] = {
          ...data[translation.locale],
          [item.name]:
            values.traductions?.[translation.locale]?.[item.name] || '',
          locale: translation.locale,
        }
      })
      //annotations
      Object.values(values[locale].annotations).map(
        (annotation, annotationId) => {
          data[translation.locale] = {
            ...data[translation.locale],
            annotations: {
              ...data[translation.locale].annotations,
              [annotationId]: {
                recomendation:
                  values.traductions?.[translation.locale]?.annotations?.[
                    annotationId
                  ]?.recomendation || '',
                interpretation:
                  values.traductions?.[translation.locale]?.annotations?.[
                    annotationId
                  ]?.interpretation || '',
              },
            },
          }
        }
      )
    })
  } else {
    translations.map((translation) => {
      items.map((item) => {
        data[translation.locale] = {
          ...data[translation.locale],
          [item.name]:
            values.traductions?.[translation.locale]?.[item.name] || '',
          locale: translation.locale,
        }
      })
    })
  }

  return data
}
const LanguageForm = ({ items, values, locale, setCreateData }) => {
  const translations = languages.filter((language) => language.locale != locale)
  const [data, setData] = useState(
    setInitialvalues(values, items, translations, locale)
  )
  const [itemSelected, setItemSelected] = useState(0)
  const itemsLanguage = ItemsLenght(items, values[locale].annotations)
  const annotations = Object.values(values[locale].annotations || {})
  const [annotationSelected, setAnnotationSelected] = useState(-items.length)

  const handleNextItem = () => {
    setItemSelected(itemSelected + 1)
    setAnnotationSelected(annotationSelected + 1)
  }
  const handleBeforeItem = () => {
    setItemSelected(itemSelected - 1)
    setAnnotationSelected(annotationSelected - 1)
  }
  const handleChangeData = (e) => {
    setData({
      ...data,
      [e.target.name]: {
        ...data[e.target.name],
        [itemsLanguage[itemSelected].name]: e.target.value,
        locale: e.target.name,
      },
    })
  }
  useEffect(() => {
    setCreateData({ ...values, traductions: data })
  }, [data])
  const classes = useStyles()
  return (
    <Fragment>
      <Box className={classes.headerContainer}>
        <Typography variant="body1" color="initial">
          {I18n.t('cruds.profiles.create.translateTitle')}
        </Typography>
        <Box className={classes.selectInputContainer}>
          <Box className={classes.selectInput}>
            <IconButton
              size="small"
              disabled={itemSelected == 0}
              onClick={handleBeforeItem}
            >
              <NavigateBefore />
            </IconButton>
            <Typography variant="h6" >
              {`${itemSelected + 1}/${itemsLanguage.length}`}
            </Typography>
            <IconButton
              size="small"
              disabled={itemSelected == itemsLanguage.length - 1}
              onClick={handleNextItem}
            >
              <NavigateNext />
            </IconButton>
          </Box>
          <Typography variant="caption" color="textSecondary">
            {I18n.t('cruds.profiles.create.textToTranslate')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.infoContainer}>
        {itemsLanguage[itemSelected].annotation ? (
          <AnnotationViewer
            number={annotationSelected}
            annotation={annotations[annotationSelected]}
          />
        ) : (
          <TextField
            variant="outlined"
            multiline={itemsLanguage[itemSelected].multiline}
            rows={itemsLanguage[itemSelected].multiline ? 4 : 0}
            label={I18n.t('cruds.profiles.create.translateLabel')}
            value={values[locale]?.[itemsLanguage[itemSelected].name] || ''}
            helperText={I18n.t(itemsLanguage[itemSelected].label)}
            disabled
            className={classes.info}
            fullWidth
          />
        )}
      </Box>
      <Box>
        {data &&
          translations.map((translation, index) => {
            if (itemsLanguage[itemSelected].annotation) {
              return (
                <AnnotationTranslate
                  translation={translation}
                  annotation={annotations[annotationSelected]}
                  data={data}
                  id={annotationSelected}
                  setData={setData}
                  key={index}
                />
              )
            } else {
              return (
                <Box key={index} className={classes.inputsContainer}>
                  <Box className={classes.labelContainer}>
                    {Icons(translation.locale)}
                    <Typography
                      className={classes.label}
                      variant="caption"
                      color="textPrimary"
                    >
                      {I18n.t(translation.name)}
                    </Typography>
                  </Box>
                  <Box className={classes.inputContainer}>
                    <TextField
                      // size="small"
                      name={translation.locale}
                      multiline={itemsLanguage[itemSelected].multiline}
                      rows={itemsLanguage[itemSelected].multiline ? 4 : 0}
                      variant="outlined"
                      value={
                        data[translation.locale]?.[
                          itemsLanguage[itemSelected].name
                        ] || ''
                      }
                      onChange={handleChangeData}
                      color="secondary"
                      className={classes.form}
                    />
                  </Box>
                </Box>
              )
            }
          })}
      </Box>
    </Fragment>
  )
}

export default LanguageForm
