import { Select, MenuItem, ListItemText, ListItemIcon } from '@mui/material'
import I18n from 'i18n-js'
import { Icons } from '../../utils/icons'
import useStyles from './style'

const LanguageSelector = ({ change, language, languages, title }) => {
  const classes = useStyles()
  return (
    <Select
      value={language}
      onChange={change}
      fullWidth
      color="secondary"
      disableUnderline
      className={classes.select}
      variant="standard"
    >
      {languages.map((lang, index) => {
        return (
          <MenuItem key={index} value={lang.locale}>
            <ListItemIcon>{Icons(lang.locale)}</ListItemIcon>
            <ListItemText primary={title ? title : I18n.t(lang.name)} />
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default LanguageSelector
