import { images } from '../../../../utils'

export const dataCard = [
  {
    img: images.imc,
    negativeImg: images.imgGray,
    name: 'IMC',
  },
  {
    img: images.pression,
    negativeImg: images.mmhgGray,
    name: 'PA',
  },
  {
    img: images.pression,
    negativeImg: images.mmhgGray,
    name: 'PA',
  },
  {
    img: images.saturation,
    negativeImg: images.spo2Gray,
    name: 'SpO2',
  },
  {
    img: images.rythmeCardiaque,
    negativeImg: images.bpmGray,
    name: 'HR',
  },
  {
    img: images.bodyTemperature,
    negativeImg: images.tempGray,
    name: 'Temp',
    unit: '°C',
  },
  {
    img: images.cardiacTest,
    negativeImg: images.cardiacGray,
    name: 'Heart',
  },
  {
    img: images.lungTest,
    negativeImg: images.lungGray,
    name: 'Lung',
  },
  {
    img: images.extentTest,
    negativeImg: images.wideRangeGray,
    name: 'Heart/Lung',
  },
  {
    img: images.leftEar,
    negativeImg: images.leftEarGray,
    name: 'left_ear',
  },
  {
    img: images.rightEar,
    negativeImg: images.rightEarGray,
    name: 'right_ear',
  },
]
