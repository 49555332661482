import { Box, Typography } from '@mui/material'
import styles from '../styles'
import I18n from 'i18n-js'
import { CrudsBtn, Loader, SearchBar } from '../../../../../components'
import patientService from '../../../../../services/patients'
import { useEffect, useState } from 'react'
import TableWithoutMargins from '../../../../../components/tableWithoutMargins'
import { colors } from '../../../../../utils'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const ViewOne = ({ create, openModalLogin }) => {
  const [rowsAux, setRowsAux] = useState()
  const { id } = useParams()
  const [rows, setRows] = useState()
  const [isLoad, setIsLoad] = useState(false)
  const columns = [
    {
      headerName: I18n.t('patient.detail.tabTip.tabOne.message'),
      field: 'content',
      sortable: false,
      minWidth: 500,
    },
    {
      headerName: I18n.t('patient.detail.tabTip.tabOne.status'),
      width: 150,
      field: 'sended',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (item) => (
        <Box>
          {item?.row?.sended === '0' ? (
            <Typography sx={{ fontWeight: 600, color: colors.redCanceled }}>
              {I18n.t('patient.detail.tabTip.tabOne.canceled')}
            </Typography>
          ) : (
            <Typography sx={{ fontWeight: 600, color: colors.blueSent }}>
              {I18n.t('patient.detail.tabTip.tabOne.sent')}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      headerName: I18n.t('patient.detail.tabTip.tabOne.startDate'),
      width: 200,
      headerAlign: 'center',
      align: 'center',
      field: 'dateCreation',
      sortable: false,
    },
    {
      headerName: I18n.t('patient.detail.tabTip.tabOne.endDate'),
      width: 200,
      headerAlign: 'center',
      align: 'center',
      field: 'dateShipment',
      sortable: false,
    },
  ]

  const dataQuery = async () => {
    setIsLoad(true)
    const res = await patientService.getMessageHistory(id)
    const newData = res?.data?.sended_conseils?.map((item) => {
      return {
        id: item?.id,
        content: item?.content ?? '-',
        sended: item?.sended ?? '-',
        dateCreation: item?.date
          ? format(new Date(item?.date), I18n.t('general.dateTimeFormat'))
          : '-',
        dateShipment: item?.sended_date
          ? format(
              new Date(item?.sended_date),
              I18n.t('general.dateTimeFormat')
            )
          : '-',
      }
    })
    setRowsAux(newData)
    setRows(newData)
    setIsLoad(false)
  }

  useEffect(() => {
    if (openModalLogin === false) dataQuery()
  }, [openModalLogin])

  const handleChange = (e) => {
    if (e.target.value.length === 0) {
      setRows(rowsAux)
    } else {
      const search = rowsAux?.filter((item) =>
        item?.content?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      )
      setRows(search)
    }
  }
  if (isLoad) return <Loader />
  return (
    <Box>
      <Box sx={styles.headerStyles}>
        <Typography sx={styles.titleStyles}>
          {I18n.t(
            'patient.detail.tabTip.tabOne.adviceAssociatedWithThisPatient'
          )}
        </Typography>
        <Box sx={styles.searchArea}>
          <Box sx={styles.stylesBar}>
            <SearchBar change={handleChange} />
          </Box>

          <CrudsBtn
            text={I18n.t('patient.detail.tabTip.tabOne.newCouncil')}
            size="medium"
            click={create}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: '16px' }}>
        <TableWithoutMargins columns={columns} rows={rows} />
      </Box>
    </Box>
  )
}

export default ViewOne
