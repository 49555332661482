import { languages } from '../../utils'
import { enumSelectSmartest } from '../../utils/enum'

const getBlocksLocales = (locale, blocks) => {
  const localeBlocks = blocks?.map((block) => {
    const body = {
      completed: true,
      id: block.id,
      instructions:
        block.translations.find((translation) => translation.locale == locale)
          ?.instructions || '',

      block_type: block.smart_step_type_id,
      order: block.position.toString(),
    }
    if (block.smart_step_type_id === 3) body.questionarie_id = block.stepable_id
    if (block.smart_step_type_id === 4) body.smartTest_id = block.stepable_id
    if (block.required !== null && block.required !== undefined)
      body.required = block.required
    if (block.data_source_id !== null && block.data_source_id !== undefined) {
      body.data_source_id = block.data_source_id
      body.indicator = true
    }
    return body
  })
  return localeBlocks
}

const useSortResponse = (apiResponse, locale) => {
  const localedata = apiResponse?.translations?.find(
    (info) => info.locale == locale
  )
  
  const localeBlocks = getBlocksLocales(locale, apiResponse?.steps)
  let traductions = {}
  let traductionLocale = {}

  apiResponse?.translations?.map((translation) => {
    if (translation.locale != locale) {
      const blocks = getBlocksLocales(translation.locale, apiResponse?.steps)
      traductions[translation.locale] = {
        name: translation.title ?? '',
        description: translation.description ?? '',
        locale: translation.locale,
        instructional_picture: translation?.url_instructional ?? '',
        data_sensors: translation?.url_sensors ?? '',
        blocks: Object.assign({}, blocks),
      }
    } else {
      traductionLocale = {
        name: translation.title ?? '',
        description: translation.description ?? '',
        locale: translation.locale,
        instructional_picture: translation?.url_instructional ?? '',
        data_sensors: translation?.url_sensors ?? '',
        blocks: Object.assign({}, localeBlocks),
      }
    }
  })
  
  /* if traductions are empty set all possibles traductions objects in the traductions */
  if (Object.values(traductions).length === 0) {
    const lngTraslations = languages.filter(
      (language) => language.locale !== locale
    )
    lngTraslations.map((lngTraslation) => {
      traductions[lngTraslation.locale] = {}
    })
  }
  let data = {
    [locale]: {
      name: localedata?.title || '',
      description: localedata?.description || '',
      locale: localedata?.locale,
      blocks: Object.assign({}, localeBlocks),
      instructional_picture: localedata?.instructional_picture || '',
      data_sensors: localedata?.data_sensors || '',
    },
    traductions: traductions,
    updateProvider: enumSelectSmartest?.find(item => item?.action === apiResponse?.updates_provider)?.id,
    providers_id: apiResponse?.provider_id || '',
    type: apiResponse?.test_type_id,
    active: 1,
    id: apiResponse?.id,
    sensors_quantity: apiResponse?.sensors_quantity || '',
    url_instructional: traductionLocale.instructional_picture || null,
    url_sensors: traductionLocale.data_sensors || null,
    position: apiResponse?.position !== null ? apiResponse?.position : 1,
    ...(apiResponse?.url_image !== null
      ? {}
      : {
          url_image: { name: '' },
        }),
  }
  if (apiResponse?.translations) {
    return data
  } else {
    return {}
  }
}

export default useSortResponse
