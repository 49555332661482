import { Box, Typography } from '@mui/material'
import { useStyles } from './useStyles'
import { useHistory } from 'react-router'
import { images } from '../../utils'
import Loader from '../loader'

const AuthLayout = (props) => {
  const history = useHistory()
  const styles = useStyles()

  return (
    <Box sx={props.container ?? styles.container}>
      {props?.loading ? (
        <Loader />
      ) : (
        <Box sx={[styles.formContainer, props.formContainer]}>
          <Box sx={styles.body}>
            {!!props?.title && (
              <Typography sx={[styles.title, styles.colorText]}>
                {props?.title}
              </Typography>
            )}

            {!!props?.subtitle && (
              <Typography sx={[styles.subtitle, styles.colorText]}>
                {props?.subtitle}
              </Typography>
            )}

            {!!props?.children && (
              <Box sx={styles.containerChildren}>{props?.children}</Box>
            )}
          </Box>

          <Box
            sx={styles.containerLogo({ cursor: !history ? 'default' : null })}
          >
            <img
              onClick={() => {
                if (history) {
                  history.push(props?.navigate ?? '/')
                }
              }}
              src={images.Logo}
              alt="IDP-LOGO"
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export { AuthLayout }
