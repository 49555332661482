import { Box, Typography } from '@mui/material'
import I18n from 'i18n-js'
import React from 'react'
import { DinamicForm } from '../../../../components'
import CSV from '../csv'
import useStyles from '../style'

const Step1 = ({ inputs, handleChange, values, errors, length, current }) => {
  const classes = useStyles()

  return (
    <>
      <DinamicForm
        inputs={inputs.slice(0, 1)}
        handleChange={handleChange}
        values={values}
        errors={errors}
        size="medium"
      />

      <Typography className={classes.instructions}>
        {I18n.t('cruds.healthCondition.instructions')}
      </Typography>

      <CSV errors={errors} handleChange={handleChange} current={current}/>

      <DinamicForm
        inputs={inputs.slice(1, 2)}
        handleChange={({ target }) =>
          handleChange({
            target: {
              name: current,
              value: target.value,
            },
          })
        }
        values={values}
        errors={errors}
        styles={classes.input}
      />

      <Box className={classes.containerMessage}>
        {length > 0 && (
          <Typography className={classes.message}>
            {length} {I18n.t('cruds.healthCondition.listItem')}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default Step1
