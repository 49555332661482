import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('data-sources')
  return res
}

const create = async (body) => {
  const data = { name: body }
  const res = await POST('data-sources', data)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  const data = { name: update }
  const res = await PUT(`data-sources/${id}`, data)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`data-sources/${id}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
}
