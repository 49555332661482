import { makeStyles } from '@mui/styles'
import colors from '../../../utils/colors'
const useStyles = makeStyles((theme) => ({
  textEmail: {},
  textBreadcrum: { color: colors.breadcrumColor, textDecoration: 'none' },
  headerContainer: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
  },
  titleContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  iconLock: {
    marginRight: '16px',
  },
  buttonCancel: {
    textTransform: 'none',
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    borderRadius: '3px',
    border: colors.disabledLighten + ' solid 1px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  resetPasswordContainer: {
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '47%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      border: 'none',
    },
  },
  resetPasswordHeader: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingTop: '24px',
    color: colors.disabledDarken,
  },
  resetPasswordBody: { padding: '0 10px', margin: '16px 0' },
  resetPasswordActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 4px 16px 0',
  },
  textContainer: {
    color: colors.disabledDarken,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10px',
    },
  },
  textContainerTitle: {
    marginBottom: '16px',
  },
  editUserContainer: {
    width: '47%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '24px',
    },
  },
  editUserHeader: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    justifyContent: 'flex-start',
    paddingTop: '24px',

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '16px',
      marginBottom: '16px',
    },
  },
  button: {
    borderRadius: '0 2px 2px 0',
    fontSize: '14px',
    textTransform: 'none',
  },
  submit: {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    padding: '0 4px 16px 0',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        marginRight: '16px',
      },
    },
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  snackbar: {
    position: 'fixed',
    top: '13vh',
    left: '50vw',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    '& .endButton': {
      marginRight: '20px',
    },
  },
  buttonsContainerEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    '& .endButton': {
      marginRight: '20px',
    },
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '150px',
    alignItems: 'center',
    width: '40%',
    margin: '0 auto',
    padding: '32px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  chekCircle: {
    color: colors.lightPrimary,
    '&.MuiSvgIcon-root': {
      fontSize: '50px',
    },
  },
  buttontext: { color: 'blue' },
}))

export default useStyles
