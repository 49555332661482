import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { Icons } from '../../utils'
import I18n from 'i18n-js'
import useStyles from './style'
/* format:
photo
document
*/
const InputFile = ({
  format = 'document',
  value,
  change,
  className,
  disabled = false, 
  accept = "",
  error = false,
  ...rest
}) => {
  const classes = useStyles()
  const [title, setTitle] = useState(value?.name || '')
  const text = format == 'photo' ? I18n.t('components.uploadImage') : ''
  const icon = Icons('folder')
  useEffect(() => {
    setTitle(value?.name || '')
  }, [value])
  
  return (
    <Button
      id={rest.name}
      variant="outlined"
      endIcon={icon}
      size="medium"
      component="label"
      disabled={disabled}
      className={`${classes.input} ${error === true ? classes.inputError : ''} ${className}`}
    >
      <span id="title" className={classes.title}> {title != '' ? title : text}</span>
      <input
        {...rest}
        type="file"
        accept={format == 'photo' ? 'image/*' : accept}
        hidden
        onChange={(e) => {
          if(e.target.files[0]?.name) {
            change({ target: { name: e.target.name, value: e.target.files[0] } })
            setTitle(e.target.files[0]?.name || '')
          }
        }}
      />
    </Button>
  )
}

export default InputFile
