import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  IconButton,
  Snackbar,
  CircularProgress,
  Hidden,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import I18n from 'i18n-js'
import {
  SearchBar,
  ModalForm,
  CrudsBtn,
  LanguageSelector,
  AlertMenu,
} from '../../components'
import units from '../../services/units'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import {
  Icons,
  languages,
  actionKeys,
  pagesKeys,
  rowsPerPage,
} from '../../utils'
import useStyles from '../crudsStyles'
import handleInitialLanguages from '../../hooks/traductions/initialModal'

const Units = () => {
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  //permissions
  const pageId = pagesKeys.units
  //list
  const [data, setData] = useState([])
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [languageColOne, setLanguageColOne] = useState(languages[0].locale)
  const [languageColTwo, setLenguageColTwo] = useState(languages[1].locale)
  //create
  const [newItem, setNewItem] = useState({ item0: '', item1: '' })
  const [showAlert, setShowAlert] = useState(false)
  //edit
  const [editItem, seteditItem] = useState({})
  const [editId, setEditId] = useState()
  //delete
  const [anchor, setAnchor] = useState(null)
  const [deleteItem, setDeleteItem] = useState()
  const [successDelete, setSuccessDelete] = useState(false)
  //modal
  const [openModal, setOpenModal] = useState(false)
  const [create, setCreate] = useState(true)
  const [disabledButton, setDisableButton] = useState(false)

  //list
  const getData = async () => {
    const res = await units.list()
    setData(res.data?.units)
    setItems(res.data?.units)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  useEffect(() => {
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
  }, [])
  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }
  const searchItems = () => {
    if (search.trim() != '') {
      const filter = data?.filter((e) => {
        if (e.translations.length > 0) {
          if (
            e.translations
              .find((lang) => lang.locale == languageColOne)
              .name.toLowerCase()
              .includes(search.toLowerCase()) ||
            e.translations
              .find((lang) => lang.locale == languageColTwo)
              .name.toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return e
          }
        }
      })
      filter != ''
        ? setItems(filter)
        : setItems([{ error: I18n.t('general.notFound') }])
    } else {
      setItems(data)
    }
    setPage(0)
  }
  useEffect(searchItems, [search])
  
  const handleChangeLenguaje1 = (e) => {
    setLanguageColOne(e.target.value)
  }
  const handleChangeLenguaje2 = (e) => {
    setLenguageColTwo(e.target.value)
  }
  //create
  const handleChangeCreate = (e) => {
    setNewItem({
      ...newItem,
      [e.target.id]: e.target.value == '' ? null : e.target.value,
    })
  }

  const handleSubmitCreate = async (e, langs) => {
    e.preventDefault()
    const names = Object.values(newItem)
    const data = langs.map((lang, index) => {
      lang.value = names[index]
      return lang
    })
    setDisableButton(true)
    await units.create(data)
    setOpenModal(false)
    getData()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
  }
  //edit
  const handleChangeEdit = (e) => {
    seteditItem({
      ...editItem,
      [e.target.id]: e.target.value == '' ? null : e.target.value,
    })
  }

  const handleSubmitEdit = async (e, langs) => {
    e.preventDefault()
    const names = Object.values(editItem)
    const update = langs.map((lang, index) => {
      lang.value = names[index]
      return lang
    })
    const data = {
      id: editId,
      update: update,
    }
    setDisableButton(true)
    await units.edit(data)
    setOpenModal(false)
    seteditItem({})
    getData()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
  }
  //delete
  const handleDeleteClick = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
  }
  const handleDeleteItem = async () => {
    await units.destroy(deleteItem)
    setAnchor(null)
    getData()
    setSearch('')
    document.getElementById('search').value = ''
    setShowAlert(true)
    setSuccessDelete(true)
  }
  //modal
  const openModalForm = (e, data = null) => {
    if (data != null) {
      const oldValues = {}
      languages.map((language, index) => {
        oldValues[`item${index}`] = data.translations.find((lang) => lang.locale == language.locale)?.name ?? ''
      })
      setEditId(data.id)
      seteditItem(oldValues)
      setCreate(false)
      setDisableButton(false)
    } else {
      setDisableButton(true)
      setNewItem(handleInitialLanguages())
      setCreate(true)
    }
    setOpenModal(true)
  }
  const closeModal = () => {
    setOpenModal(false)
    setNewItem({})
    seteditItem({})
  }
  useEffect(() => {
    const submit = Object.values(newItem).find((item) => item?.length < 2 || item?.trim() == '' || item === null)
    submit !== undefined || submit === null ? setDisableButton(true) : setDisableButton(false)

    if (Object.values(editItem).length != 0) {
      const submit = Object.values(editItem).find((item) => item?.length < 2 || item?.trim() == '' || item === null)
      submit !== undefined || submit === null ? setDisableButton(true) : setDisableButton(false)
    }
  }, [newItem, editItem])

  const classes = useStyles()
  return (
    <Box p={4} m={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.units')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('breadcrumb.units')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar
                  change={handleChangeSearchbar}
                  search={searchItems}
                />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.units.button')}
              size="medium"
              click={openModalForm}
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} search={searchItems} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} size="small">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguaje1}
                        language={languageColOne}
                        languages={languages}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <LanguageSelector
                        change={handleChangeLenguaje2}
                        language={languageColTwo}
                        languages={languages}
                      />
                    </TableCell>
                    {(canAccess(actionKeys.edit, pageId, state) ||
                      canAccess(actionKeys.delete, pageId, state)) && (
                      <TableCell align="center">
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('general.actions')}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                {items != '' && (
                  <TableBody>
                    {(rowsPerPage.units > 0
                      ? items?.slice(
                          page * rowsPerPage.units,
                          page * rowsPerPage.units + rowsPerPage.units
                        )
                      : items
                    )?.map((item, index) => {
                      const { translations } = item
                      if (item.error) {
                        return (
                          <TableRow className={classes.tableRow} key={index}>
                            <TableCell align="left" colSpan={4}>
                              <Typography variant="h6" color="primary">
                                {item.error}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        return (
                          !item.error && (
                            <TableRow className={classes.tableRow} key={index}>
                              <TableCell className={classes.tableCell}>
                                {
                                  translations.find(
                                    (lang) => lang.locale == languageColOne
                                  )?.name
                                }
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {
                                  translations.find(
                                    (lang) => lang.locale == languageColTwo
                                  )?.name
                                }
                              </TableCell>
                              {(canAccess(actionKeys.edit, pageId, state) ||
                                canAccess(
                                  actionKeys.delete,
                                  pageId,
                                  state
                                )) && (
                                <TableCell
                                  align="right"
                                  className={classes.tableCellActions}
                                >
                                  <div className={classes.divactions}>
                                    {canAccess(
                                      actionKeys.edit,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        color="secondary"
                                        onClick={(e) => {
                                          openModalForm(e, item)
                                        }}
                                      >
                                        {Icons('edit')}
                                      </IconButton>
                                    )}

                                    {canAccess(
                                      actionKeys.delete,
                                      pageId,
                                      state
                                    ) && (
                                      <IconButton
                                        className={classes.delete}
                                        onClick={(e) => {
                                          handleDeleteClick(e)
                                          setDeleteItem(item.id)
                                        }}
                                      >
                                        {Icons('delete')}
                                      </IconButton>
                                    )}
                                  </div>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        )
                      }
                    })}
                    <tr>
                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage.units]}
                        count={items && items?.length}
                        page={page}
                        rowsPerPage={rowsPerPage.units}
                        onPageChange={handleChangePage}
                        className={classes.pagination}
                      />
                    </tr>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <ModalForm
        open={openModal}
        close={closeModal}
        disabled={disabledButton}
        value={create ? newItem : editItem}
        change={create ? handleChangeCreate : handleChangeEdit}
        submit={create ? handleSubmitCreate : handleSubmitEdit}
        tittle={
          create
            ? I18n.t('components.createItem')
            : I18n.t('components.editItem')
        }
        btnLabel={create}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={() => {
          setShowAlert(false)
          setSuccessDelete(false)
        }}
        className={classes.alert}
      >
        <Alert severity="success">
          {successDelete
            ? I18n.t('components.alertDelete')
            : I18n.t('components.alert')}
        </Alert>
      </Snackbar>
      
      <AlertMenu
        anchor={anchor}
        close={handleDeleteClose}
        action={handleDeleteItem}
      />
      {items == '' && (
        <div className={classes.loadingBg}>
          <CircularProgress color="primary" className={classes.loading} />{' '}
        </div>
      )}
    </Box>
  )
}

export default Units
