import { useState, useEffect, useContext } from 'react'
import I18n from 'i18n-js'
import 'date-fns'
import { useHistory, useParams, Link } from 'react-router-dom'
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Card,
  TextField,
  Switch,
  Fade,
} from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

import medicalStaff from '../../../services/medicalStaff'
import { AuthContext } from '../../../context/auth'
import { canAccess } from '../../../router/permissions'
import { actionKeys, dateLocales, Icons, pagesKeys, rol } from '../../../utils'
import MedicalStaffValidator from '../../../validators/medicalStaffValidator'
import {
  InputForm,
  SelectForm,
  CrudsBtn,
  DinamicForm,
} from '../../../components'

// services
import typeStaff from '../../../services/typeStaff'
import medicalCenters from '../../../services/medicalsCenter'
import users from '../../../services/users'
import MaterialUiPhoneNumber from 'material-ui-phone-number'

import useStyles from './style'
import { MobileDatePicker } from '@mui/lab'
import { isEmail, numbersAndCharacters } from '../../../validators/validator'
import { isFirefox } from '../../../hooks/browser'
import { listCountries, transfromLocalePhone } from '../../../utils/locale'
import { CheckCircle } from '@mui/icons-material'
const Form = () => {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const [oldEmail, setOldEmail] = useState()
  const [oldCenter, setOldCenter] = useState(null)
  //permissions
  const pageId = pagesKeys.medicalstaff

  //state
  const { state } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  const [userRolId, setUserRolId] = useState()
  const [typeStaffList, setTypeStaffList] = useState([])
  const [medicalCenterList, setMedicalCenterList] = useState([])
  const [codes, setCodes] = useState({
    code: '',
    code_system: '',
    main_doctor: false,
  })
  // Form
  const [errors, setErrors] = useState({})
  const [disabled, setDisabled] = useState(
    params.id != undefined ? false : true
  )
  const [disableEmail, setdisableEmail] = useState(
    params.id != undefined ? false : true
  )
  const [currentField, setCurrentField] = useState('')
  const [form, setForm] = useState({})
  const [finished, setFinished] = useState(false)
  // Methods
  const handleForm = async (e) => {
    const { name, value } = e.target

    setCurrentField(e.target.name)
    let formCopy = {
      ...form,
    }
    if (name === 'email') {
      formCopy[name] = value.toLowerCase()
    } else if (name === 'rpps') {
      formCopy[name] =
        !numbersAndCharacters(value) || value === '' ? value : formCopy[name]
    } /* else if(['name', 'lastname'].includes(name)) {
      if(isCharacters(value) || value === '') {
        formCopy[name] = value
      }
    } */ else {
      formCopy[name] = value
    }

    if (e.target.name === 'id_type_staff') {
      const codes = typeStaffList.find((item) => item.id == e.target.value)

      setCodes({
        code: codes?.code,
        code_system: codes?.code_system,
        main_doctor: codes?.main_doctor,
      })
    }

    setForm({ ...formCopy })

    if (e.target.name === 'email' && !isEmail(e.target.value)) {
      const json = await users.checkEmail(e.target.value)

      if (json.data && oldEmail !== e.target.value) {
        setErrors({
          ...errors,
          email: I18n.t('validators.emailExist'),
        })
        setdisableEmail(true)
      } else {
        setdisableEmail(false)
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (!disabled) {
      let body = { ...form }

      //edit
      if (!form.id) {
        const json = await medicalStaff.create(body)
        if (json.data) {
          if (form.center_id) {
            await medicalStaff.addCenter(
              json.data.medicalStaff.id,
              form.center_id
            )
          }
          setFinished(true)
        }
      } //create
      else {
        const json = await medicalStaff.update(body.id, body)
        if (json.data) {
          if (form.center_id && oldCenter !== form.center_id) {
            await medicalStaff.removeCenter(body.id, body.centers)
            await medicalStaff.addCenter(body.id, body.center_id)
          }
          setFinished(true)
        }
      }
    }
    setLoading(false)
  }

  // Fetch
  const getMedical = async () => {
    const json = await medicalStaff.getOne(params.id)
    setOldEmail(json?.email)

    setCodes({
      code: json?.type_staff?.code,
      code_system: json?.type_staff?.code_system,
      main_doctor: json?.type_staff?.main_doctor,
    })
    setOldCenter(json?.medical_centers?.[0]?.id)
    return {
      id: json?.id || '',
      email: json?.email || '',
      name: json?.name || '',
      lastname: json?.lastname || '',
      sex: json?.sex ? 1 : 0,
      phone: json?.phone || '',
      center_id: json?.medical_centers[0]?.id || '',
      centers: json?.medical_centers?.map((item) => item.id) || [],
      birth: json?.birth || null,
      id_type_staff: json?.id_type_staff || '',
      code_insee: json?.code_insee || '',
      rpps: json?.rpps || '',
    }
  }

  const getuserRolId = async () => {
    const userResponse = await users.show(state.email)
    setUserRolId(userResponse.data?.user?.id_rol)
  }

  const getSelects = async () => {
    let medicalJson = {
      id: '',
      email: null,
      name: null,
      lastname: null,
      sex: '',
      phone: null,
      center_id: null,
      centers: [],
      birth: null,
      id_type_staff: null,
    }

    const staff = await typeStaff.list()

    if (params.id) {
      medicalJson = await getMedical()
      const typeStaffExist = staff.data.typeStaff.find(
        (item) => item.id === medicalJson.id_type_staff
      )
      medicalJson.id_type_staff = typeStaffExist?.id || ''
    }

    setTypeStaffList([
      ...staff.data.typeStaff.map((item) => ({
        id: item.id,
        name: item.name,
        code: item.code,
        code_system: item.code_system,
        main_doctor: item.main_doctor,
      })),
    ])

    await getuserRolId()

    const centers = await medicalCenters.list()
    setMedicalCenterList([
      ...centers.data.medicalCenters.map((item) => ({
        id: item.id,
        name: item.name,
      })),
    ])

    setForm(medicalJson)
  }

  useEffect(async () => {
    const ac = new AbortController()

    setLoading(true)

    await getSelects()

    setLoading(false)

    return () => {
      ac.abort()
    }
  }, [])

  // Fields
  const fields = [
    {
      name: 'name',
      label: `${I18n.t('medicalstaff.form.first_name')} (*)`,
      validations: ['required'],
      size: { xs: 6, lg: 4 },
    },
    {
      name: 'lastname',
      label: `${I18n.t('medicalstaff.form.last_name')} (*)`,
      validations: ['required'],
      size: { xs: 6, lg: 4 },
    },
    {
      name: 'birth',
      label: `${I18n.t('medicalstaff.form.date_of_birth')} (*)`,
      type: 'date',
      validations: ['required'],
      size: { xs: 6, lg: 4 },
    },
    {
      name: 'sex',
      label: `${I18n.t('medicalstaff.form.gender')} (*)`,
      type: 'select',
      options: [
        { id: 0, name: I18n.t('patient.form.gender.female') },
        { id: 1, name: I18n.t('patient.form.gender.male') },
      ],
      validations: ['required'],
      size: { xs: 6, lg: 4 },
    },
    {
      name: 'email',
      label: `${I18n.t('medicalstaff.form.mail_address')} (*)`,
      validations: ['required', 'email'],
      size: { xs: 6, lg: 4 },
      disabled: false,
      onBlur: async () => {},
    },
    {
      name: 'code_insee',
      label: `${I18n.t('medicalstaff.form.codeInsee')}`,
      size: { xs: 6, lg: 4 },
    },
    {
      name: 'phone',
      label: I18n.t('medicalstaff.form.phone_number') + '(*)',
      validations: ['required'],
      size: { xs: 6, lg: 4 },
      type: 'phone',
    },
    {
      name: 'center_id',
      label: I18n.t('medicalstaff.form.medical_center'),
      type: 'select',
      options: medicalCenterList,
      size: { xs: 12, lg: 4 },
      dontshow: userRolId != rol.admin ? true : false,
    },
    {
      name: 'rpps',
      label: `${I18n.t('medicalstaff.form.rpps')}`,
      size: { xs: 6, lg: 4 },
      maxLength: 190,
    },
    {
      name: 'id_type_staff',
      label: I18n.t('medicalstaff.form.staff_type') + '(*)',
      validations: ['required'],
      type: 'autoComplete',
      options: typeStaffList ?? [],
      size: { xs: 6, lg: 4 },
      classAutoComplete: classes.inputAutocomplete,
    },
  ]

  useEffect(() => {
    if (params.id && canAccess(actionKeys.edit, pageId, state)) {
      const validator = MedicalStaffValidator(form, currentField, errors)

      setErrors(validator.errors)

      setDisabled(!validator.valid)
    } else if (!params.id && canAccess(actionKeys.create, pageId, state)) {
      const validator = MedicalStaffValidator(form, currentField, errors)

      setErrors(validator.errors)

      setDisabled(!validator.valid)
    } else {
      history.push('/dashboard')
    }
  }, [form])

  return (
    <Box p={4}>
      <Grid container spacing={2} direction="column">
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.medicalStaff')} /{' '}
            <Link
              to="/dashboard/medical-staff"
              className={classes.textBreadcrum}
            >
              {I18n.t('breadcrumb.dashboard')}
            </Link>{' '}
            / {!form.id && I18n.t('breadcrumb.add')}
            {form.id && I18n.t('breadcrumb.edit')}
          </Typography>
        </Grid>

        {!finished ? (
          <>
            <Grid item className={classes.itemFlex}>
              <Typography
                variant="h5"
                color="primary"
                className={classes.title}
              >
                {!form.id && I18n.t('medicalstaff.add')}
                {form.id && I18n.t('medicalstaff.edit')}
              </Typography>
            </Grid>

            {loading ? (
              <CircularProgress className={classes.loading} />
            ) : (
              <>
                {canAccess(actionKeys.list, pageId, state) && (
                  <Grid item className={classes.items}>
                    <Card bgcolor="white">
                      <Box p={4}>
                        <form action="" onSubmit={handleSubmit}>
                          <Grid container spacing={3} direction="row">
                            <>
                              {fields.map((item, key) => {
                                if (!item.type) {
                                  return (
                                    <Grid
                                      item
                                      xs={item.size.xs}
                                      lg={item.size.lg}
                                      style={{ padding: '17px 12px' }}
                                      key={key}
                                    >
                                      <InputForm
                                        name={item.name}
                                        // size="small"
                                        color="secondary"
                                        onChange={handleForm}
                                        value={form[item.name]}
                                        label={item.label}
                                        error={
                                          errors[item.name] &&
                                          form[item.name] !== null
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          errors[item.name] &&
                                          form[item.name] !== null
                                            ? errors[item.name]
                                            : ''
                                        }
                                        onBlur={item.onBlur || null}
                                        disabled={item.disabled}
                                        fullWidth
                                        variant="outlined"
                                        style={{
                                          marginBottom:
                                            errors[item.name] &&
                                            form[item.name] !== null
                                              ? '-16px'
                                              : '',
                                        }}
                                        withMaxLength={item.maxLength}
                                        maxLength={item.maxLength}
                                        className={item.classes ?? ''}
                                      />
                                    </Grid>
                                  )
                                }

                                if (item.type == 'date') {
                                  return (
                                    <Grid
                                      item
                                      xs={item.size.xs}
                                      lg={item.size.lg}
                                      key={key}
                                      style={{ padding: '17px 12px' }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={dateLocales[state.locale]}
                                      >
                                        <MobileDatePicker
                                          toolbarTitle=""
                                          renderInput={(props) => {
                                            return (
                                              <TextField
                                                {...props}
                                                fullWidth
                                                // size="small"
                                                color="secondary"
                                                variant="outlined"
                                                label={item.label}
                                                name={item.name}
                                                // helperText={errors[item.name] && form[item.name] !== null ? errors[item.name] : ''}
                                                onClick={() =>
                                                  setForm({
                                                    ...form,
                                                    [item.name]: new Date(),
                                                  })
                                                }
                                                className={classes.input}
                                              />
                                            )
                                          }}
                                          inputFormat={
                                            I18n.t('general.dateFormat') ||
                                            'dd-MM-yyyy'
                                          }
                                          onChange={(value) =>
                                            handleForm({
                                              target: {
                                                name: item.name,
                                                value,
                                              },
                                            })
                                          }
                                          maxDate={new Date()}
                                          value={form[item.name]}
                                          onError={() =>
                                            errors[item.name] &&
                                            form[item.name] !== null
                                              ? true
                                              : false
                                          }
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                  )
                                }

                                if (item.type == 'phone') {
                                  return (
                                    <Grid
                                      item
                                      xs={item.size.xs}
                                      lg={item.size.lg}
                                      key={key}
                                      style={{ padding: '17px 12px' }}
                                    >
                                      <MaterialUiPhoneNumber
                                        disableDropdown={isFirefox()}
                                        color="secondary"
                                        // size="small"
                                        fullWidth
                                        variant="outlined"
                                        label={item.label}
                                        onChange={(event) => {
                                          handleForm({
                                            target: {
                                              name: item.name,
                                              value: event,
                                            },
                                          })
                                        }}
                                        autoFormat={false}
                                        value={form[item.name]}
                                        defaultCountry={
                                          transfromLocalePhone[state.locale] || listCountries.fr
                                        }
                                        inputProps={{ maxLength: 250 }}
                                        helperText={
                                          errors[item.name] &&
                                          form[item.name] !== null
                                            ? errors[item.name]
                                            : ''
                                        }
                                        error={
                                          errors[item.name] &&
                                          form[item.name] !== null
                                            ? true
                                            : false
                                        }
                                      />
                                    </Grid>
                                  )
                                }

                                if (item.type == 'select') {
                                  return (
                                    !item.dontshow && (
                                      <Grid
                                        item
                                        xs={item.size.xs}
                                        lg={item.size.lg}
                                        style={{ padding: '17px 12px' }}
                                        key={key}
                                      >
                                        <SelectForm
                                          name={item.name}
                                          label={item.label}
                                          color="secondary"
                                          variant="outlined"
                                          // size="small"
                                          onChange={handleForm}
                                          value={
                                            form[item.name] < 0 ||
                                            form[item.name] == undefined
                                              ? ''
                                              : form[item.name]
                                          }
                                          fullWidth
                                          options={item.options}
                                        />
                                      </Grid>
                                    )
                                  )
                                }

                                if (item.type == 'autoComplete') {
                                  return (
                                    !item.dontshow && (
                                      <Grid
                                        item
                                        xs={item.size.xs}
                                        lg={item.size.lg}
                                        style={{ padding: '17px 12px' }}
                                        key={key}
                                      >
                                        <DinamicForm
                                          inputs={[
                                            {
                                              ...item,
                                              type: 'simpleAutocomplete',
                                            },
                                          ]}
                                          handleChange={handleForm}
                                          values={form}
                                        />
                                      </Grid>
                                    )
                                  )
                                }
                              })}
                              {form.id_type_staff && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.gridSubtitle}
                                  >
                                    <Typography variant="h6" color="primary">
                                      {I18n.t(
                                        'medicalstaff.personalInformation'
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    lg={4}
                                    className={classes.gridItem}
                                  >
                                    <Box className={classes.detailContainer}>
                                      {Icons('code')}
                                      <Typography
                                        variant="subtitle2"
                                        ml={1}
                                        mb={1}
                                      >
                                        {I18n.t('medicalstaff.form.code')}:{' '}
                                        {codes?.code ??
                                          I18n.t('general.dataNotFound')}
                                      </Typography>
                                    </Box>

                                    <Box className={classes.detailContainer}>
                                      {Icons('code')}
                                      <Typography variant="subtitle2" ml={1}>
                                        {I18n.t('medicalstaff.form.codeSystem')}
                                        :{' '}
                                        {codes?.code_system ??
                                          I18n.t('general.dataNotFound')}
                                      </Typography>
                                    </Box>

                                    <Box
                                      className={`${classes.detailContainer} ${classes.medicalIcon}`}
                                    >
                                      {Icons('medical')}
                                      <Typography variant="subtitle2" ml={1}>
                                        {I18n.t(
                                          'medicalstaff.treatingPhysician'
                                        )}
                                        :{' '}
                                        <Switch
                                          disabled={true}
                                          checked={codes?.main_doctor}
                                        />
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </>
                              )}

                              <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Grid container justifyContent="space-between">
                                  <Button
                                    variant="text"
                                    color="secondary"
                                    style={{ textTransform: 'none' }}
                                    onClick={() => history.goBack()}
                                  >
                                    {I18n.t('components.cancel')}
                                  </Button>
                                  <CrudsBtn
                                    type="submit"
                                    disabled={
                                      !disabled && !disableEmail ? false : true
                                    }
                                    text={I18n.t('medicalstaff.save')}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          </Grid>
                        </form>
                      </Box>
                    </Card>
                  </Grid>
                )}
              </>
            )}
          </>
        ) : (
          <Fade in={finished} timeout={500}>
            <Box className={classes.finishContainer}>
              <CheckCircle className={classes.chekCircle} />
              <Typography variant="h5" color="primary">
                {I18n.t('components.success')}
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {I18n.t(
                  `medicalstaff.form.${params?.id ? 'edited' : 'created'}`
                )}
              </Typography>
              <Box className={classes.footerEnd}>
                <CrudsBtn
                  text={I18n.t(`medicalstaff.form.return`)}
                  component={Link}
                  to="/dashboard/medical-staff"
                />
              </Box>
            </Box>
          </Fade>
        )}
      </Grid>
    </Box>
  )
}

export default Form
