import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import { format } from 'date-fns'
import I18n from 'i18n-js'
import React, { useState } from 'react'
import { DinamicForm, SnackBar } from '../../../../components'
import { formatDateHour } from '../../../../hooks/dates/formatDate'
import { Icons } from '../../../../utils'
import { isEmail } from '../../../../validators/validator'
import { styles, useStyles } from './styles'

const BilansVDSI = ({ list, sendEmail }) => {
  const classes = useStyles()

  const [snackBar, setSnackBar] = useState({})
  const [load, setLoad] = useState(false)
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [bilansData, setBilansData] = useState({
    open: false,
    email: '',
    id: null,
    error: {
      email: '',
    },
  })

  const inputs = [
    {
      name: 'email',
      label: I18n.t('patient.form.email'),
      style: classes.input,
    },
  ]

  const handleChange = ({ target: { name, value } }) => {
    const body = { ...bilansData, [name]: value }

    if (isEmail(value) && value)
      body.error[name] = I18n.t('validators.invalidEmail')
    else if (!value) body.error[name] = I18n.t('validators.required')
    else body.error[name] = ''

    setBilansData(body)
  }

  const handleClose = () =>
    setBilansData((data) => ({ ...data, open: false, email: '', error: {} }))

  const handleDisabled = () => !bilansData.email || bilansData.error.email

  const email = async () => {
    setLoad(true)
    const response = await sendEmail(bilansData.email, bilansData.id)

    if (response.data) {
      setSnackBar({
        text: I18n.t('patient.detail.pdf.success'),
        severity: 'success',
      })
    } else {
      setSnackBar({
        text: I18n.t('patient.detail.pdf.errorSend'),
        severity: 'warning',
      })
    }
    setLoad(false)
    setShowSnackBar(true)
    handleClose()
  }
  return (
    <Box sx={styles.container}>
      {list && list.length > 0 && (
        <>
          {list?.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                ...styles.flex,
                ...(0 === index
                  ? { ...styles.cardGreen, ...styles.colorWhite }
                  : styles.borders),
              }}
            >
              <Typography sx={styles.date}>
                {item.created_at &&
                  formatDateHour(
                    format(
                      new Date(item.created_at + 'Z'),
                      I18n.t('general.dateTimeFormat')
                    )
                  )}
              </Typography>
              <Box sx={{ ...styles.flex, ...styles.pdf }}>
                <IconButton
                  onClick={() =>
                    setBilansData((data) => ({
                      ...data,
                      open: true,
                      id: item.id,
                    }))
                  }
                  sx={{
                    ...styles.icon,
                    ...(0 === index && styles.colorWhite),
                  }}
                >
                  {Icons('sendEmail')}
                </IconButton>

                <IconButton
                  onClick={() => window.open(item.report_url)}
                  sx={{
                    ...styles.icon,
                    ...(0 === index && styles.colorWhite),
                  }}
                >
                  {Icons('pdf')}
                </IconButton>
              </Box>
            </Box>
          ))}
          <Dialog
            open={bilansData.open}
            onClose={() => handleClose()}
            fullWidth
            maxWidth="xs"
          >
            <Box sx={styles.modal}>
              <Typography sx={styles.textModal}>
                {I18n.t('general.sendEmail')}
              </Typography>

              <DinamicForm
                errors={bilansData.error}
                handleChange={handleChange}
                inputs={inputs}
                values={bilansData}
              />

              <Box sx={styles.buttonModal}>
                <Button
                  padding='8px 15px'
                  variant="text"
                  onClick={() => handleClose()}
                >
                  {I18n.t('components.cancel')}
                </Button>
                <LoadingButton
                  onClick={() => email()}
                  loading={load}
                  disabled={handleDisabled()}
                  sx={styles.acceptedBtn}
                  variant="contained"
                >
                  {I18n.t('components.send')}
                </LoadingButton>
              </Box>
            </Box>
          </Dialog>
        </>
      )}
      <SnackBar
        text={snackBar.text}
        severity={snackBar.severity}
        show={showSnackBar}
        HandleShow={() => showSnackBar(false)}
        time={3000}
      />
    </Box>
  )
}

export default BilansVDSI
