import { makeStyles } from '@mui/styles'
import { colors } from '../../../utils'

const useStyles = makeStyles(() => ({
  containerFlex: {
    width: 'calc(100% - 30px)',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px 15px',
    background: colors.white,
    boxShadow: `1px 1px 5px ${colors.greyDarken}`,
  },

  list: {
    '&.MuiList-root': {
      boxShadow: `1px 1px 5px ${colors.greyDarken}`,
      paddingTop: 0,
      paddingBottom: 0,
      overflow: 'hidden',
    },
  },
  showItemTitle: {
    '&.MuiListItem-root': {
      background: colors.white,
    },
  },
  showItemTitleText: {
    '&.MuiListItemText-root': {
      color: colors.greyStrong,
      '& .MuiTypography-root': {
        fontWeight: 500,
      },
    },
  },
  childrenLength: {
    '&.MuiTypography-root': {
      padding: '3px 10px',
      marginRight: 10,
      color: colors.white,
      background: colors.blueLighten,
      borderRadius: '50%',
      fontSize: '.9em',
      fontWeight: 500,
    },
  },
  arrow: {
    '&.MuiListItemIcon-root': {
      color: colors.greyStrong,
      minWidth: 0,
      cursor: 'pointer',
    },
  },
  showItem: {
    '&.MuiListItem-root': {
      borderBottom: `1px solid ${colors.disabledBorder}`,
      background: colors.disabledLighten,
    },
  },
  showItemText: {
    '&.MuiListItemText-root': {
      color: colors.greyStrong,
      marginLeft: 10,
    },
  },
}))

export default useStyles
