import { useEffect, useState, useContext, Fragment } from 'react'
import I18n from 'i18n-js'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography, Hidden, CircularProgress } from '@mui/material'
import { IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

import { SearchBar, CrudsBtn, SnackBar, AlertMenu } from '../../components'
import { smartTests } from '../../services'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import { actionKeys, images, pagesKeys } from '../../utils'
import { Icons } from '../../utils'
import useStyles from '../crudsStyles'

const SmartTest = () => {
  const classes = useStyles()

  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const [flat, setFlat] = useState(false)
  //permissions
  const pageId = pagesKeys.smartTest

  //search
  const [search, setSearch] = useState('')

  //list
  const [data, setData] = useState([])

  //delete
  const [testDelete, setTestDelete] = useState()
  const [anchor, setAnchor] = useState()

  //show alert
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState({
    text: '',
    variant: 'success',
  })

  const getData = async () => {
    const response = await smartTests.list()
    setData(response.data)
    setFlat(true)
  }

  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }

  // remove

  const handleDeleteOpen = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleDeleteClose = () => {
    setAnchor(null)
  }
  const remove = async () => {
    handleDeleteClose()
    const json = await smartTests.destroy(testDelete)
    if (json.data) setData([...data.filter((item) => item.id !== testDelete)])
    setMessage({
      text: 'components.alertDelete',
      variant: 'success',
    })
    setShow(true)
    setSearch('')
    document.getElementById('search').value = ''
  }

  useEffect(() => {
    const ac = new AbortController()
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
    return () => {
      ac.abort()
    }
  }, [])

  // Table
  const columns = [
    {
      field: 'title',
      headerName: I18n.t('cruds.smartTest.tableheader.title'),
      flex: 1,
    },
    {
      field: 'description',
      headerName: I18n.t('cruds.smartTest.tableheader.description'),
      flex: 1,
    },
    {
      field: 'typeTest',
      headerName: I18n.t('cruds.smartTest.tableheader.typeTest'),
      flex: 1,
    },
    {
      field: 'n_sensors',
      headerName: I18n.t('cruds.smartTest.tableheader.numberSensors'),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'type_provider',
      headerName: I18n.t('cruds.smartTest.tableheader.typeProvider'),
      flex: 1,
    },
    {
      field: 'position',
      headerName: I18n.t('cruds.smartTest.tableheader.position'),
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'status',
      headerName: I18n.t('cruds.smartTest.tableheader.status'),
      align: 'center',
      headerAlign: 'center',
      width: 120,
      sortable: false,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <img src={row.status ? images.Active : images.Inactive} />
      ),
    },
    {
      field: 'actions',
      headerName: I18n.t('general.actions'),
      headerAlign: 'center',
      width: 170,
      sortable: false,
      hide:
        canAccess(actionKeys.edit, pageId, state) ||
        canAccess(actionKeys.delete, pageId, state)
          ? false
          : true,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Grid container justifyContent="space-between">
          {canAccess(actionKeys.edit, pageId, state) && (
            <Fragment>
              <IconButton
                onClick={() =>
                  history.push(`/dashboard/smart-test/edit/${row.id}`)
                }
                color="secondary"
              >
                {Icons('edit')}
              </IconButton>
              {row.typeTest === 'Tests de IDP SMART' && (
                <IconButton
                  onClick={() =>
                    history.push(`/dashboard/smart-test/questions/${row.id}`)
                  }
                  color="secondary"
                >
                  {Icons('question')}
                </IconButton>
              )}
              {row.type === 2 && (
                <IconButton
                  onClick={() =>
                    history.push(`/dashboard/smart-test/bilanes-medical_staff/${row.id}`)
                  }
                  color="secondary"
                >
                  {Icons('group')}
                </IconButton>
              )}
            </Fragment>
          )}
          {canAccess(actionKeys.delete, pageId, state) && (
            <IconButton
              onClick={(e) => {
                setTestDelete(row.id)
                handleDeleteOpen(e)
              }}
            >
              {Icons('delete')}
            </IconButton>
          )}
        </Grid>
      ),
    },
  ]

  const rows = [
    ...data?.map((item) => {
      return {
        id: item.id,
        title: item.title || I18n.t('general.dataNotFound'),
        description: item?.description || I18n.t('general.dataNotFound'),
        typeTest: item.test_type.name || I18n.t('general.dataNotFound'),
        n_sensors: item.sensors_quantity && item.test_type_id === 1 ? item.sensors_quantity :  "-",
        type_provider:
          item.provider?.description || I18n.t('general.dataNotFound'),
        status: item.active,
        position: item.position && item.test_type_id === 2 ? item.position : "-",
        type: item.test_type_id
      }
    }) || [],
  ]

  const filterData = (rows) => {
    return rows.filter(
      (item) =>
        item.title?.toLowerCase().includes(search.toLowerCase()) ||
        item.description?.toLowerCase().includes(search.toLowerCase()) ||
        item.type_provider?.toLowerCase().includes(search.toLowerCase())
    )?.sort((a,b) => a.position - b.position)?.sort((a,b) => b.type - a.type)
  }

  return (
    <Box p={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.smartTest')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('sidebar.smartTest')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar change={handleChangeSearchbar} />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.smartTest.button')}
              size="medium"
              click={() => history.push(`/dashboard/smart-test/create`)}
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <div style={{ width: '100%', background: 'white' }}>
              { (rows?.length >= 0 && flat) &&
                (<DataGrid
                  columns={columns}
                  rows={filterData(rows)}
                  autoHeight
                  pagination
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableColumnMenu
                  disableSelectionOnClick
                  className={classes.datagrid}
                />)||
                (<CircularProgress className={classes.circularProgress} />)
              }
            </div>
          </Grid>
        )}
      </Grid>
      <SnackBar
        text={I18n.t(message.text)}
        severity={message.variant}
        show={show}
        HandleShow={setShow}
        time={1500}
      />
      <AlertMenu anchor={anchor} close={handleDeleteClose} action={remove} />
    </Box>
  )
}

export default SmartTest
