import { useContext } from 'react'
import { Box, Snackbar, TextField, Typography } from '@mui/material'
import Alert from '@mui/material/Alert'
import { AuthContext } from '../../context/auth'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import I18n from 'i18n-js'
import useStyles from './style'
import { dateLocales, rol } from '../../utils'
import { isEmail, isEmpty, isPhoneNumber } from '../../validators/validator'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { MobileDatePicker } from '@mui/lab'
import SimpleError from '../errors/simple'
import { isFirefox } from '../../hooks/browser'
import { listCountries, transfromLocalePhone } from '../../utils/locale'
const FormEditProfile = ({
  oldEmail,
  editEmail,
  user,
  change,
  openAlert,
  setOpenAlert,
  setUser,
  setuserInfo,
  editUser = {},
  userRolId,
}) => {
  const { state } = useContext(AuthContext)
  const classes = useStyles()
  const userDate = new Date(user?.birth || '')
  userDate.setMinutes(userDate.getMinutes() + userDate.getTimezoneOffset())
  
  return (
    <div className={classes.profile}>
      <Box className={classes.containers}>
        <TextField
          variant="outlined"
          id="name"
          label={
            userRolId !== rol.center
              ? I18n.t('myProfile.inputs.name') + '(*)'
              : I18n.t('myProfile.inputs.nameCenter') + '(*)'
          }
          defaultValue={user?.name}
          onChange={change}
          error={isEmpty(editUser.name) || false}
          // size="small"
          color="secondary"
          className={
            userRolId !== rol.center
              ? classes.inputText
              : classes.inputText + ' inputLarge'
          }
          inputProps={{ maxLength: 250 }}
        />
        <SimpleError
          error={isEmpty(editUser?.name)}
          message={I18n.t('validators.required')}
        />
      </Box>
      {userRolId !== rol.center && (
        <Box className={classes.containers}>
          <TextField
            variant="outlined"
            id="lastname"
            label={I18n.t('myProfile.inputs.lastname') + '(*)'}
            defaultValue={user?.lastname}
            onChange={change}
            error={isEmpty(editUser.lastname) || false}
            // size="small"
            color="secondary"
            className={classes.inputText}
            inputProps={{ maxLength: 250 }}
          />
          <SimpleError
            error={isEmpty(editUser?.lastname)}
            message={I18n.t('validators.required')}
          />
        </Box>
      )}

      {editEmail && (
        <Box className={classes.containers}>
          <TextField
            variant="outlined"
            id="email"
            label={I18n.t('myProfile.emailDirection') + '(*)'}
            defaultValue={user?.email}
            error={
              isEmpty(editUser?.email) ||
              isEmail(editUser?.email) ||
              (!editUser?.existEmail && oldEmail !== editUser.email)
            }
            onChange={change}
            // size="small"
            color="secondary"
            className={classes.inputText}
            inputProps={{ maxLength: 250 }}
          />

          {!isEmpty(editUser?.email) ? (
            <SimpleError
              error={
                isEmail(editUser?.email) ||
                (!editUser?.existEmail && oldEmail !== editUser.email)
              }
              message={
                editUser?.existEmail
                  ? I18n.t('validators.invalidEmail')
                  : I18n.t('validators.emailRegistered')
              }
            />
          ) : (
            <SimpleError
              error={isEmpty(editUser?.email)}
              message={I18n.t('validators.required')}
            />
          )}
        </Box>
      )}

      <Box className={classes.containers}>
        <MaterialUiPhoneNumber
          // color="secondary"
          className={classes.inputPhone}
          fullWidth
          variant="outlined"
          label={I18n.t('myProfile.inputs.phone') + '(*)'}
          // size="small"
          error={
            editUser?.phone ? (!isPhoneNumber(editUser?.phone)) : false
          }
          disableDropdown={isFirefox()}
          autoFormat={false}
          onChange={change}
          value={user?.phone && user?.phone !== null? user?.phone : ""}
          defaultCountry={transfromLocalePhone[state.locale] || listCountries.fr}
          inputProps={{ maxLength: 250 }}
        />
        {editUser?.phone
          ? !isPhoneNumber(editUser?.phone) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.invalidPhone')}
              </Typography>
            )
          : ''}
      </Box>
      <Box className={classes.containers}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={dateLocales[state.locale]}
        >
          <MobileDatePicker
            onChange={(value) => {
              setUser({ ...user, birth: value })
              setuserInfo({ ...user, birth: value })
            }}
            value={userDate == 'Invalid Date' ? null : userDate}
            maxDate={new Date()}
            toolbarTitle=""
            inputFormat={I18n.t('general.dateFormat') || 'dd-MM-yyyy'}
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  variant="outlined"
                  id="birth"
                  // size="small"
                  color="secondary"
                  className={classes.inputText}
                  label={
                    userRolId !== rol.center
                      ? I18n.t('myProfile.inputs.birth') + '(*)'
                      : I18n.t('myProfile.inputs.birthCenter') + '(*)'
                  }
                />
              )
            }}
          />
        </LocalizationProvider>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openAlert}
        autoHideDuration={4000}
        onClose={() => {
          setOpenAlert(false)
        }}
        className={classes.snackbar}
      >
        <Alert severity={'success'}>{I18n.t('myProfile.dataUpdated')}</Alert>
      </Snackbar>
    </div>
  )
}

export default FormEditProfile
