import { useEffect, useState, useContext, Fragment } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
//material
import {
  Box,
  Typography,
  CircularProgress,
  Fade,
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
  Hidden,
  Grid,
  IconButton,
} from '@mui/material'
import { AccountCircle, ExpandMore } from '@mui/icons-material'
//traductions
import I18n from 'i18n-js'
//services
import { medicalcenters } from '../../../services'
import { canAccess } from '../../../router/permissions'
import { AuthContext } from '../../../context/auth'
//utils
import { pagesKeys, actionKeys, Icons, images } from '../../../utils'
//components
import { CrudsBtn, Map } from '../../../components'
import useStyles from './style'
import GenericModal from '../../../components/genericModal/index.jsx'
import { addList } from './list'

const Items = ({ itemList }) => {
  const classes = useStyles()
  return (
    <Box className={classes.informationCenter}>
      {itemList?.map((data, key) => (
        <Box className={classes.item} key={key}>
          <Typography variant="subtitle2">{data?.name}</Typography>
          <Typography className={classes.itemText} variant="body2">
            {data?.value || I18n.t('general.dataNotFound')}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

const ShowCenter = () => {
  const classes = useStyles()
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const [list, setList] = useState({})
  //center id
  const { id } = useParams()
  const pageId = pagesKeys.medicalcenter

  const [loading, setLoading] = useState(true)
  const [center, setCenter] = useState()
  const [open, setOpen] = useState(false)
  //fetch
  const getData = async () => {
    const response = await medicalcenters.show(id)

    setCenter(response.data.medicalCenter)
    setList(addList(response.data.medicalCenter, classes))
  }

  useEffect(() => {
    const accessAsync = async () => {
      if (!canAccess(actionKeys.detail, pageId, state)) {
        history.push('/dashboard')
      } else {
        await getData()
        setLoading(false)
      }
    }

    accessAsync()
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const showLegalDocument = () => {
    if (center.legal_document?.html) {
      return (
        <>
          <iframe
            className={classes.iframe}
            srcDoc={center.legal_document?.html ?? ''}
          />
          <IconButton className={classes.fullscreen} onClick={handleOpen}>
            {Icons('fullscreen')}
          </IconButton>
        </>
      )
    }
    return (
      <Typography variant="body1" color="textPrimary">
        {I18n.t('general.notFound')}
      </Typography>
    )
  }

  if (loading) {
    return <CircularProgress className={classes.loading} />
  } else {
    return (
      <Fade in={true} timeout={500}>
        <Box p={4}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.mcenter')} /{' '}
            <Link
              to="/dashboard/medical-center"
              className={classes.textBreadcrum}
            >
              {I18n.t('breadcrumb.dashboard')} /{' '}
            </Link>
            {I18n.t('cruds.medicalCenters.show.indicator')}
          </Typography>

          <Box className={classes.bodyContainer}>
            <Box className={classes.titleBarContainer}>
              <Box className={classes.titleContainer}>
                <Typography variant="h5" color="primary">
                  {center?.name
                    ? center.name
                    : I18n.t('cruds.medicalCenters.show.indicator')}
                </Typography>
              </Box>
              {canAccess(actionKeys.edit, pagesKeys.medicalcenter, state) && (
                <CrudsBtn
                  variant="contained"
                  text={I18n.t('breadcrumb.edit')}
                  click={() =>
                    history.push(`/dashboard/medical-center/edit/${id}`)
                  }
                />
              )}
            </Box>
          </Box>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} xs={12} sm={12}>
                <Typography variant="h6" color="primary">
                  {I18n.t('cruds.medicalCenters.show.informationTitle')}
                </Typography>
                <Box my={2}>
                  <Box className={classes.item}>
                    <Typography variant="subtitle2">
                      {I18n.t('cruds.medicalCenters.form.license')}
                    </Typography>
                    <Typography variant="body2">
                      {center.license_key || I18n.t('general.dataNotFound')}
                    </Typography>
                  </Box>
                </Box>
                <Box my={2}>
                  <Box className={classes.item}>
                    <Typography variant="subtitle2">
                      {I18n.t('cruds.medicalCenters.identifiers')}
                    </Typography>
                    <Typography variant="body2">
                      {center.identifier && center.identifier_number
                        ? `${center.identifier}: ${center.identifier_number}`
                        : I18n.t('general.dataNotFound')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={12} xs={12} sm={12} mt={4}>
                <Items itemList={list.detail} />
              </Grid>

              <Grid item lg={6} md={12} xs={12} sm={12}>
                {/* address */}
                <Box className={classes.addressContainer}>
                  <Typography variant="h6" color="primary">
                    {I18n.t('cruds.medicalCenters.show.address')}
                  </Typography>
                  <Items itemList={list.placeDatil} />
                </Box>
              </Grid>
              <Grid item lg={6} md={12} xs={12} sm={12} mt={4}>
                {center.latitud && center.longitud && (
                  <Map
                    center={{
                      lat: parseFloat(center.latitud),
                      lng: parseFloat(center.longitud),
                      name: center.name,
                    }}
                    type={3}
                  />
                )}
              </Grid>
              <Grid item lg={12} md={12} xs={12} sm={12}>
                {/* accordions */}
                <Box className={classes.contactsContainer}>
                  {/* contacts medical */}
                  <Accordion
                    className={classes.completeCard}
                    elevation={2}
                    defaultExpanded
                  >
                    <AccordionSummary
                      className={classes.summary}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        className={classes.cardTitle}
                      >
                        {I18n.t('cruds.medicalCenters.show.contactTitle')}
                      </Typography>
                    </AccordionSummary>
                    <Divider />
                    <div className={classes.listContainer}>
                      {center.contact_medicals &&
                      center.contact_medicals.length > 0 ? (
                        center.contact_medicals.map((contact) => (
                          <Fragment key={contact.id}>
                            <AccordionDetails>
                              <ListItem
                                alignItems="flex-start"
                                className={classes.listItem}
                              >
                                <ListItemIcon>
                                  <AccountCircle />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <strong>
                                      {contact.name + ' ' + contact.lastname}
                                    </strong>
                                  }
                                  secondary={
                                    <Fragment>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {I18n.t(
                                          'cruds.medicalCenters.contacts.form.email'
                                        ) + ': '}
                                      </Typography>
                                      {contact.email}
                                      <br />
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {I18n.t(
                                          'cruds.medicalCenters.contacts.form.phone'
                                        ) + ': '}
                                      </Typography>
                                      {contact.phone}
                                    </Fragment>
                                  }
                                />
                              </ListItem>
                            </AccordionDetails>
                            <Hidden lgUp>
                              <Divider />
                            </Hidden>
                          </Fragment>
                        ))
                      ) : (
                        <AccordionDetails>
                          <Typography variant="body1" color="textPrimary">
                            {I18n.t('general.notFound')}
                          </Typography>
                        </AccordionDetails>
                      )}
                    </div>
                  </Accordion>

                  {/* sensors */}
                  <Accordion
                    className={classes.completeCard}
                    elevation={2}
                    defaultExpanded
                  >
                    <AccordionSummary
                      className={classes.summary}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        className={classes.cardTitle}
                      >
                        {I18n.t('cruds.medicalCenters.sensors.detail')}
                      </Typography>
                    </AccordionSummary>
                    <Divider />
                    <div className={classes.listContainer}>
                      {center.kits && center.kits.length > 0 ? (
                        center.kits.map((kit, index) => (
                          <Fragment key={index}>
                            <AccordionDetails>
                              <ListItem
                                alignItems="flex-start"
                                className={classes.listItem}
                              >
                                <ListItemIcon>
                                  <img
                                    src={images.SensorXSense}
                                    width={216 * 0.1}
                                    height={262 * 0.1}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <strong>
                                      {I18n.t(
                                        'cruds.medicalCenters.sensors.kit'
                                      ) +
                                        ' ' +
                                        (index + 1)}
                                    </strong>
                                  }
                                  secondary={
                                    <Fragment>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {I18n.t(
                                          'cruds.medicalCenters.sensors.form.sensor'
                                        ) + ' 1: '}
                                      </Typography>
                                      {kit.sensorOne?.mac ||
                                        I18n.t('general.dataNotFound')}
                                      <br />
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {I18n.t(
                                          'cruds.medicalCenters.sensors.form.sensor'
                                        ) + ' 2: '}
                                      </Typography>
                                      {kit.sensorTwo?.mac ||
                                        I18n.t('general.dataNotFound')}
                                      <br />
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {I18n.t(
                                          'cruds.medicalCenters.sensors.form.sensor'
                                        ) + ' 3: '}
                                      </Typography>
                                      {kit.sensorThree?.mac ||
                                        I18n.t('general.dataNotFound')}
                                    </Fragment>
                                  }
                                />
                              </ListItem>
                            </AccordionDetails>
                            <Hidden lgUp>
                              <Divider />
                            </Hidden>
                          </Fragment>
                        ))
                      ) : (
                        <AccordionDetails>
                          <Typography variant="body1" color="textPrimary">
                            {I18n.t('general.notFound')}
                          </Typography>
                        </AccordionDetails>
                      )}
                    </div>
                  </Accordion>

                  {/* aminogram */}
                  <Accordion
                    className={classes.completeCard}
                    elevation={2}
                    defaultExpanded
                  >
                    <AccordionSummary
                      className={classes.summary}
                      expandIcon={<ExpandMore />}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        className={classes.cardTitle}
                      >
                        {I18n.t('cruds.medicalCenters.biody.titleShow')}
                      </Typography>
                    </AccordionSummary>
                    <Divider />
                    <div className={classes.listContainer3fr}>
                      {center.license_aminogram &&
                      center.license_aminogram.length > 0 ? (
                        center.license_aminogram.map((amonogram, index) => (
                          <Fragment key={index}>
                            <AccordionDetails>
                              <ListItem
                                alignItems="flex-start"
                                className={classes.listItem}
                              >
                                <ListItemIcon>
                                  <img
                                    src={images.biodyXpert3}
                                    width={216 * 0.1}
                                    height={262 * 0.1}
                                  />
                                </ListItemIcon>

                                <ListItemText
                                  primary={
                                    <strong>
                                      {`${I18n.t('cruds.medicalCenters.biody.biody')} ${index + 1}`}
                                    </strong>
                                  }
                                  secondary={
                                    <Fragment>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {I18n.t('cruds.medicalCenters.biody.form.id')} {`:`}
                                      </Typography>
                                      {amonogram.sensorOne?.id_aminogram ||
                                        I18n.t('general.dataNotFound')}
                                      <br />

                                      <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                      >
                                        {I18n.t('cruds.medicalCenters.biody.form.license')} {`:`}
                                      </Typography>
                                      {amonogram.sensorTwo?.license_aminogram ||
                                        I18n.t('general.dataNotFound')}
                                    </Fragment>
                                  }
                                />
                              </ListItem>
                            </AccordionDetails>
                            <Hidden lgUp>
                              <Divider />
                            </Hidden>
                          </Fragment>
                        ))
                      ) : (
                        <AccordionDetails>
                          <Typography variant="body1" color="textPrimary">
                            {I18n.t('general.notFound')}
                          </Typography>
                        </AccordionDetails>
                      )}
                    </div>
                  </Accordion>

                  <Box className={classes.completeCard}>
                    {/* medical staff */}
                    <Accordion elevation={2} defaultExpanded>
                      <AccordionSummary
                        className={classes.summary}
                        expandIcon={<ExpandMore />}
                      >
                        <Typography
                          variant="h6"
                          color="primary"
                          className={classes.cardTitle}
                        >
                          {I18n.t('patient.show.medicalInfo')}
                        </Typography>
                      </AccordionSummary>
                      <Divider />
                      <div className={classes.listContainer}>
                        {center.medical_staff.length > 0 ? (
                          center.medical_staff.map((medicalStaff) => (
                            <Fragment key={medicalStaff.id}>
                              <AccordionDetails
                                className={classes.accordionDetails}
                              >
                                <ListItem
                                  alignItems="flex-start"
                                  className={classes.listItem}
                                >
                                  <ListItemIcon>
                                    <AccountCircle />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Box className={classes.title}>
                                        <strong>
                                          {(medicalStaff.name || '') +
                                            ' ' +
                                            (medicalStaff.lastname || '')}
                                        </strong>
                                      </Box>
                                    }
                                    secondary={
                                      <Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textPrimary"
                                        >
                                          {I18n.t(
                                            'cruds.medicalCenters.contacts.form.email'
                                          ) + ': '}
                                        </Typography>
                                        {medicalStaff.email}
                                        <br />
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          className={classes.inline}
                                          color="textPrimary"
                                        >
                                          {I18n.t(
                                            'cruds.medicalCenters.contacts.form.phone'
                                          ) + ': '}
                                        </Typography>
                                        {medicalStaff.phone}
                                        <br />
                                        {medicalStaff.type_staff?.name && (
                                          <span className={classes.sticker}>
                                            <Typography
                                              variant="body2"
                                              component="span"
                                              noWrap
                                            >
                                              {medicalStaff.type_staff?.name}
                                            </Typography>
                                          </span>
                                        )}
                                      </Fragment>
                                    }
                                  />
                                </ListItem>
                              </AccordionDetails>
                              <Hidden mdUp>
                                <Divider />
                              </Hidden>
                            </Fragment>
                          ))
                        ) : (
                          <AccordionDetails>
                            <Typography variant="body1" color="textPrimary">
                              {I18n.t('general.notFound')}
                            </Typography>
                          </AccordionDetails>
                        )}
                      </div>
                    </Accordion>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                className={classes.gridLegalDocument}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.cardTitle}
                  mb={1}
                >
                  {I18n.t('cruds.medicalCenters.create.consentDocument')}
                </Typography>
                {center.legal_document?.html && showLegalDocument()}

                {!center.legal_document?.html &&
                  center.legal_document?.document_path && (
                    <Items itemList={list.documentDetail} />
                  )}
              </Grid>
            </Grid>

            {canAccess(actionKeys.list, pageId, state) && (
              <Button
                variant="outlined"
                padding="8px 15px"
                onClick={() => history.goBack()}
                startIcon={Icons('arrow_back')}
              >
                {I18n.t('cruds.medicalCenters.show.back')}
              </Button>
            )}
          </Paper>
          <Box className={classes.footer}></Box>

          <GenericModal open={open} close={handleClose}>
            <>
              <Typography
                variant="h6"
                color="primary"
                className={classes.cardTitle}
                mb={1}
              >
                {I18n.t('cruds.medicalCenters.create.consentDocument')}
              </Typography>
              <iframe
                className={classes.fullIframe}
                srcDoc={center.legal_document?.html ?? ''}
              />
              <IconButton
                className={classes.fullscreenExit}
                onClick={handleClose}
              >
                {Icons('close')}
              </IconButton>
            </>
          </GenericModal>
        </Box>
      </Fade>
    )
  }
}

export default ShowCenter
