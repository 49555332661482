import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles({
  input: {
    textTransform: 'none',
    '&.MuiButton-outlined': {
      borderRadius: '0 2px 2px 0',
      marginTop: -8,
      height: 40,
      background: colors.white,
      padding: '7px 14px',
      borderColor: colors.greyDarken,
      '&:hover': {
        borderColor: colors.greyDarken,
      },
    },
    '&.MuiButton-root': {
      color: colors.greyStrong,
      display: 'flex',
      justifyContent: 'space-between',
    },
    ['& #title']: {
      color: colors.greyStrong,
      fontHeight: '0',
      fontWeight: 'normal',
      textTransform: 'none',
    },

    ['&:focus']: { borderColor: colors.blueLighten },
  },
  title: {
    maxWidth: 'calc(100% - 20px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  inputError: {
    '&.MuiButton-outlined': {
      borderColor: colors.error,
      '&:hover': { borderColor: colors.error },
    },
  },
})

export default useStyles
