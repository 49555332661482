import { Grid, Typography } from "@mui/material"
import { DetailModal } from "./components"
import { useState } from "react"
import I18n from "i18n-js"
import { ChooseCardType } from "./chooseCardType"

const GraphicWithPreview = (props) => {
    const [modal, setModal] = useState({
        open: false,
        graph: '',
        id: null,
        evolution_graph: '',
        description: '',
        title: ''
    })
    const closeModal = () => {
        setModal({ open: false })
    }

    const openModal = (item) => {
        setModal(prev => ({
            ...prev,
            open: true,
            graph: item.graph,
            id: item.id,
            evolution_graph: item.evolution_graph,
            description: item.description,
            title: item.name
        }))
    }
    if (!props.data?.length) {
        return (<Typography variant="body1" color="textSecondary">
            {I18n.t('general.notFound')}
        </Typography>)
    }

    return (
        <>
            <Grid container spacing={2}>
                {
                    props.data?.map((item, index) => (
                        <Grid item xs={6} key={`${item.name}-${index}`}>
                            <ChooseCardType
                                expandProps={{
                                    onClick: () => { openModal(item) }
                                }}
                                item={item}
                            />
                        </Grid>
                    ))
                }
            </Grid>

            <DetailModal
                modalProps={{
                    open: modal.open,
                    onClose: closeModal
                }}
                {...modal}
            />
        </>
    )
}

export { GraphicWithPreview }