import { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import { Menu, MenuItem, IconButton, ListItemText } from '@mui/material'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import useStyles from './style'
import I18n from 'i18n-js'

const ProfileBtn = ({ logout }) => {
  const history = useHistory()
  const [anchor, setAnchor] = useState(null)
  const handleClick = (e) => {
    setAnchor(e.currentTarget)
  }
  const handleClose = () => {
    setAnchor(null)
  }
  const options = [
    {
      text: 'components.profileIcon.myProfile',
      action: () => {
        history.push('/dashboard/my-profile')
        handleClose()
      },
    },
    { text: 'components.profileIcon.logout', action: logout },
  ]
  const classes = useStyles()
  return (
    <Fragment>
      <IconButton
        id="profile"
        aria-label="user"
        onClick={handleClick}
        className={classes.position}
      >
        <AccountCircleTwoToneIcon className={classes.button} />
      </IconButton>

      <Menu
        id="profile"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem onClick={option.action} key={index}>
              <ListItemText secondary={I18n.t(option.text)} />
            </MenuItem>
          )
        })}
      </Menu>
    </Fragment>
  )
}

export default ProfileBtn
