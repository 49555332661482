import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'
const useStyles = makeStyles({
  marker: {
    backgroundColor: colors.secondary,
    color: 'white',
    padding: '5px',
    display: 'inline-flex',
    textAlign: 'left',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'translate(-50%, -50%)',
  },
})

export default useStyles
