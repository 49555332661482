import Typography from '@mui/material/Typography'
import I18n from 'i18n-js'
import { stepEnum } from '../../utils'
import useStyles from './style'


const getKeyStep = {
  1: 'smart_questionnaire',
  2: 'xsens',
  3: 'questionnaire',
  4: 'smart_test',
  5: 'kforce_strength',
  6: 'kforce_balance',
  7: 'teleconsultation',
  15: 'kinvent_grip',
  16: 'kinvent_gait',
  17: 'kinvent_stance',
  18: 'kinvent_singlemove',
  19: 'kinvent_open',
  20: 'aminogram_test',
  21: 'kinvent_gait_no_cal',
  22: 'kinvent_gait_three_no_cal',
}
const BlockViewver = ({ block }) => {
  const classes = useStyles()
  
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.headertitle}
        >
          {I18n.t('cruds.profiles.create.translateLabel')}
        </Typography>
      </div>
      <div className={classes.textArea}>
        <Typography variant="body1" className={classes.instructions}>
          {block.instructions}
        </Typography>
        {/* <DinamicForm values={block} inputs={input} /> */}
      </div>

      <div className={classes.footerContainer}>
        <Typography variant="caption">
          {I18n.t('cruds.smartTest.form.instructions') +
            ': ' +
            I18n.t(stepEnum[getKeyStep[block.block_type]])}
        </Typography>
      </div>
    </div>
  )
}

export default BlockViewver
