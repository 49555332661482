import { makeStyles } from '@mui/styles'
import { colors } from '../../utils'

const useStyles = makeStyles(() => ({
  containerInputImage: ({ removeMargins }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    ...(!removeMargins && {
      marginTop: '10px',
      marginBottom: 5,
    }),
    position: 'relative',
  }),
  file: ({ error }) => ({
    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
      '-webkit-text-fill-color': colors.greyStrong,
      fontSize: '.9em',
      // padding: '9px 15px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '&.MuiFormControl-root.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      width: '100%',
      // background: 'transparent',
      border: error ? `1px solid ${colors.orangeStrong}` : '',
      borderRadius: 3,
      '& svg': {
        color: colors.greyStrong,
      },
    },
    '& .MuiFormLabel-root.MuiInputLabel-root': {
      background: error
        ? 'linear-gradient(transparent 5%, white 60%, transparent 5%)'
        : 'transparent',
    },
  }),
  buttonFile: {
    '&.MuiButton-root': {
      width: 400,
    },
  },
}))

export default useStyles
