import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import { TextField, Typography } from '@mui/material'
import useStyles from '../style'
import I18n from 'i18n-js'
import { Icons /* , languages */ } from '../../../../utils'
import { Box } from '@mui/system'
import CSV from '../csv'
import CrudsBtn from '../../../../components/crudsBtn'
import { useState } from 'react'
import { useEffect } from 'react'
import clsx from 'clsx'
import DinamicForm from '../../../../components/dinamicForm'

const Steps2 = ({
  inputs,
  errors,
  values,
  file,
  onChange,
  length,
  locale,
  current,
}) => {
  const classes = useStyles()
  const [data, setData] = useState({
    text: '',
    arr: [],
  })

  const [id, setId] = useState(0)

  const [edit, setEdit] = useState(false)

  const deleteItems = (ind, edit = null) => {
    const newArr = [...(edit === null ? file : data.arr)]
    const name = `${edit === null ? locale : current}File`
    const modify = edit === null ? { index: ind } : false

    onChange(
      {
        target: {
          name,
          value: newArr?.filter((_, i) => i !== ind).join(','),
        },
      },
      modify
    )
  }

  const editItems = (i) => {
    let newArr = [...data.arr]

    setId(i)

    setData({
      ...data,
      text: newArr[i],
    })

    setEdit(true)
  }

  const handleAdd = () => {
    if (data.text.trim() !== '') {
      if (!edit) {
        const translate = values?.[`${current}File`]

        onChange({
          target: {
            name: `${current}File`,
            value: translate
              ? `${translate},${data.text.trim()}`
              : data.text.trim(),
          },
        })
      } else {
        let newArr = [...data.arr]
        newArr[id] = data.text.trim()

        onChange({
          target: {
            name: `${current}File`,
            value: newArr.join(','),
          },
        })

        setEdit(false)
      }
    }

    setData({
      ...data,
      text: '',
    })
  }

  const handleChange = ({ target }) => {
    setData({
      ...data,
      text: target.value,
    })
  }
  
  const handletranslateChange = () => {
    setData({
      ...data,
      arr: values?.[`${current}File`]
        ? values?.[`${current}File`]?.split(',')?.filter((item) => item !== '')
        : [],
      text: '',
    })

    setEdit(false)
  }

  useEffect(() => {
    handletranslateChange()
    
  }, [values?.[`${current}File`]])

  const customList = (items, edit = null) => (
    <Paper sx={{ minHeight: '2.5rem', maxHeight: '20rem', overflow: 'auto' }}>
      <List dense component="div" role="list" className={classes.containerList}>
        {items?.map((value, ind) => {
          const labelId = `transfer-list-item-${value}-label`

          return (
            <ListItem
              key={ind}
              role="listitem"
              button
              className={clsx({
                [classes.item]: ind < items.length - 1,
              })}
            >
              <ListItemText
                className={classes.itemText}
                id={labelId}
                primary={value}
              />

              {edit !== null && (
                <ListItemIcon
                  className={classes.edit}
                  onClick={() => editItems(ind)}
                >
                  {Icons('edit')}
                </ListItemIcon>
              )}

              <ListItemIcon
                className={classes.delete}
                onClick={() => deleteItems(ind, edit)}
              >
                {Icons('clear')}
              </ListItemIcon>
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item lg={12}>
      <DinamicForm
          inputs={inputs}
          handleChange={onChange}
          values={values}
          size="medium"
        />

        <Typography className={classes.instructions}>
          {I18n.t('cruds.healthCondition.translateInstruction')}
        </Typography>
      </Grid>

      {(length?.[locale] - length?.[current] > 0 || edit || length?.[current] === undefined) && (
        <Grid item lg={12}>
          <CSV
            handleChange={onChange}
            errors={errors}
            current={`${current}File`}
          />

          <Box className={classes.flex}>
            <Box className={classes.flexGrow} />

            <TextField
              value={data.text}
              label={I18n.t('cruds.healthCondition.translate')}
              onChange={handleChange}
              // size="small"
              className={classes.MR}
            />

            <CrudsBtn
              text={
                !edit
                  ? I18n.t('cruds.healthCondition.addTranslate')
                  : I18n.t('cruds.healthCondition.editTranslate')
              }
              click={() => handleAdd()}
            />
          </Box>
        </Grid>
      )}

      <Grid item lg={6}>
        {customList(file)}
      </Grid>

      <Grid item lg={6}>
        {customList(data?.arr, true)}
      </Grid>

      <Grid item lg={12}>
        <Box className={classes.containerMessage}>
          {length?.[locale] - length?.[current] > 0 && (
            <Typography
              className={`${classes.message} ${classes.bgMissingText}`}
            >
              {length?.[locale] - length?.[current]}{' '}
              {I18n.t('cruds.healthCondition.missingItem')}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Steps2
