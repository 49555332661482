import { makeStyles } from '@mui/styles'
import { colors } from '../../utils'

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    margin: '50px 0',
    position:'relative'
  },
  img: {
    width: 80,
  },
  graphic: {
    '&.MuiIconButton-root': {
      fontSize: '1.2rem',
      padding: '4px',
      '& .MuiSvgIcon-root': {
        fontSize: '1em'
      }
    }
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    width: '80%',
    maxWidth: 250,
    '& .MuiTypography-root': {
      fontSize: '.7em',
      color: colors.greySoft,
    },
  },
  stadistics: {
    width: '80%',
    height: 30,
    maxWidth: 250,
  },
  title: {
    '&.MuiTypography-root': {
      color: colors.greySoft,
      textTransform: 'uppercase',
      fontSize: '.94em',
    },
  },
}))

export default useStyles
