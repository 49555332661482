import Typography from '@mui/material/Typography'
import I18n from 'i18n-js'
import { InputForm } from '../index'
import useStyles from './style'

const AnnotationViewer = ({ annotation, number }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.headertitle}
        >
          {I18n.t('cruds.profiles.create.translateLabel')}
        </Typography>
      </div>
      <div className={classes.bodyContainer}>
        <Typography variant="subtitle2" color="textSecondary">
          {I18n.t('cruds.indicators.create.valueLabel') + ': '}
          {annotation.value}
        </Typography>
      </div>
      <div className={classes.body}>
        <InputForm
          label={I18n.t('cruds.indicators.create.interpretation')}
          name="interpretation"
          variant="outlined"
          color="secondary"
          // size="small"
          rows={4}
          withMaxLength={false}
          value={annotation.interpretation || ''}
          className={classes.textArea}
          disabled
          multiline
        />
        <InputForm
          label={I18n.t('cruds.indicators.create.recomendationLabel')}
          name="recomendation"
          variant="outlined"
          color="secondary"
          // size="small"
          rows={4}
          withMaxLength={false}
          value={annotation.recomendation || ''}
          className={classes.textArea}
          disabled
          multiline
        />
      </div>
      <div className={classes.footerContainer}>
        <Typography variant="caption">
          {I18n.t('cruds.indicators.create.recomendationLabel') +
            ' ' +
            (number + 1)}
        </Typography>
      </div>
    </div>
  )
}

export default AnnotationViewer
