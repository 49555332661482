import { useContext } from 'react'
import { Box, MenuItem, TextField, Typography } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import I18n from 'i18n-js'
import { AuthContext } from '../../context/auth'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import useStyles from './style'
import { actionKeys, dateLocales, languages, pagesKeys, rol } from '../../utils'
import { MobileDatePicker } from '@mui/lab'
import { isEmail, isEmpty, isPhoneNumber } from '../../validators/validator'
import { canAccess } from '../../router/permissions'
import SimpleError from '../errors/simple'
import { isFirefox } from '../../hooks/browser'
import { listCountries, transfromLocalePhone } from '../../utils/locale'

const FormEditUser = ({ oldEmail, existEmail, user, change, setUser, setuserInfo, userRolId }) => {
  const { state } = useContext(AuthContext)
  const classes = useStyles()
  const userDate = new Date(user?.birth || '')
  userDate.setMinutes(userDate.getMinutes() + userDate.getTimezoneOffset())
  return (
    <div className={classes.form}>
      <div className={classes.inputSmall}>
        <TextField
          variant="outlined"
          name="name"
          label={
            userRolId !== rol.center
              ? I18n.t('myProfile.inputs.name') + '(*)'
              : I18n.t('myProfile.inputs.nameCenter') + '(*)'
          }
          defaultValue={user?.name}
          error={user.name === undefined ? false : isEmpty(user?.name) || false}
          onChange={change}
          // size="small"
          color="secondary"
          className={
            userRolId !== rol.center
              ? classes.inputText + ' ' + classes.inputBig
              : classes.inputText + ' ' + classes.inputLarge
          }
          inputProps={{ maxLength: 250 }}
        />
        
          <SimpleError error={isEmpty(user?.name)} message={I18n.t('validators.required')}/>
      </div>
      {userRolId !== rol.center && (
        <Box className={classes.inputSmall}>
          <TextField
            variant="outlined"
            name="lastname"
            label={I18n.t('myProfile.inputs.lastname') + '(*)'}
            defaultValue={user?.lastname}
            error={
              user.lastname === undefined
                ? false
                : isEmpty(user?.lastname) || false
            }
            onChange={change}
            // size="small"
            color="secondary"
            className={classes.inputText + ' ' + classes.inputBig}
            inputProps={{ maxLength: 250 }}
          />
          
            <SimpleError error={isEmpty(user?.lastname)} message={I18n.t('validators.required')}/>
        </Box>
      )}
      {
        canAccess(actionKeys.edit, pagesKeys.users, state) && (
          <Box className={classes.inputSmall}>
          <TextField
            variant="outlined"
            name="email"
            label={I18n.t('myProfile.emailDirection') + '(*)'}
            defaultValue={user?.email}
            error={ isEmpty(user?.email) || isEmail(user?.email) || (!existEmail && oldEmail !== user.email)}
            onChange={change}
            // size="small"
            color="secondary"
            className={classes.inputText + ' ' + classes.inputBig}
            inputProps={{ maxLength: 250 }}
          />
          
          {
            !isEmpty(user?.email) ? 
            <SimpleError 
            error={
              isEmail(user?.email) || 
              (!existEmail && oldEmail !== user.email)
            } 
            message={
              (existEmail ) ? 
                I18n.t('validators.invalidEmail') : 
                I18n.t('validators.emailRegistered')
            }
          />
              :
              <SimpleError error={isEmpty(user?.email)} message={I18n.t('validators.required')}/>
          }
        </Box>
        )
      }
      <Box className={classes.phoneContainer + ' ' + classes.inputSmall}>
        <MaterialUiPhoneNumber
          color="secondary"
          disableDropdown={isFirefox()}
          className={classes.inputText}
          variant="outlined"
          label={I18n.t('myProfile.inputs.phone') + '(*)'}
          // size="small"
          error={user?.phone ? (!isPhoneNumber(user?.phone) ? true : false) : false}
          autoFormat={false}
          onChange={change}
          defaultCountry={transfromLocalePhone[state.locale] || listCountries.fr}
          value={user?.phone}
          inputProps={{ maxLength: 250 }}
        />
        {user?.phone
          ? !isPhoneNumber(user?.phone) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.invalidPhone')}
              </Typography>
            )
          : ''}
      </Box>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={dateLocales[state.locale]}
      >
        <MobileDatePicker
          value={userDate == 'Invalid Date' ? new Date() : userDate}
          toolbarTitle=''
          renderInput={(props) => {
            return (
              <TextField
                {...props}
                name="birth"
                // size="small"
                color="secondary"
                className={classes.inputText + ' ' + classes.inputSmall}
                label={
                  userRolId !== rol.center
                    ? I18n.t('myProfile.inputs.birth') + '(*)'
                    : I18n.t('myProfile.inputs.birthCenter') + '(*)'
                }
                variant="outlined"
              />
            )
          }}
          inputFormat={I18n.t('general.dateFormat') || 'dd-MM-yyyy'}
          maxDate={new Date()}
          onChange={(value) => {
            setUser({ ...user, birth: value })
            setuserInfo({ ...user, birth: value })
          }}
        />
        <TextField
          variant="outlined"
          color="secondary"
          name="locale"
          label={I18n.t('cruds.users.inputs.locale')}
          value={!user.locale ? '' : user.locale}
          className={classes.inputText + ' ' + classes.inputSmall}
          onChange={change}
          // size="small"
          select
        >
          {languages.map((language, index) => {
            return (
              <MenuItem key={index} value={language.locale}>
                {I18n.t(language.name)}
              </MenuItem>
            )
          })}
        </TextField>
      </LocalizationProvider>
    </div>
  )
}

export default FormEditUser
