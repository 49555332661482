import I18n from 'i18n-js'

export const enumSelectSmartest = [
  {
    id: 1,
    name: I18n.t('cruds.smartTest.enum.create'),
    action: 'create',
  },
  {
    id: 2,
    name: I18n.t('cruds.smartTest.enum.update'),
    action: 'update',
  },
  {
    id: 3,
    name: I18n.t('cruds.smartTest.enum.recordless'),
    action: 'recordless',
  },
]

export const codeVerification = {
  twilio: 'twilio',
  google2factor: 'google2factor',
}

export const enumTypeMessage = {
  simple: { key: 'simple' },
  pack: { key: 'pack' },
}
