import { Box, Grid, IconButton, Typography } from "@mui/material"
import BaseModal from "../../../../../../components/baseModal"
import { Icons } from "../../../../../../utils"
import useStyle from "../../style"

const ImageModal = (props) => {
    const classes = useStyle()
    return (
        <BaseModal {...props.modalProps}>
            <Grid container>
                <Grid item xs={12}>
                    <Box className={classes.slider}>
                        {props.steps >
                            0 ? (
                            <IconButton
                                onClick={() => {
                                    props.setSteps(props.steps - 1)
                                }}
                            >
                                {Icons('left')}
                            </IconButton>
                        ) : (
                            <Box className={classes.empty} />
                        )}

                        <Typography>
                            {props.steps + 1} / {props.imgList?.length}
                        </Typography>

                        {props.steps < props.imgList?.length - 1 ? (
                            <IconButton
                                onClick={() => {
                                    props.setSteps(props.steps + 1)
                                }}
                            >
                                {Icons('right')}
                            </IconButton>
                        ) : (
                            <Box className={classes.empty} />
                        )}
                    </Box>
                </Grid>

                <Grid item xs={12} className={classes.imgItems}>
                    <img
                        className={classes.imgModal}
                        src={props.imgList?.[props.steps]}
                        alt=""
                        loading="lazy"
                    />
                </Grid>
            </Grid>
        </BaseModal>
    )
}

export { ImageModal }