import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '33px',
    boxSizing: 'border-box',
    padding: '24px',
  },
  sections: {
    display: 'flex',
    columnGap: '24px',
    '&:first-child': { justifyContent: 'space-between' },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      rowGap: '24px',
    },
  },
  textArea: { flex: 1 },
  input: { flex: 0.515 },
}))

export default useStyles
