import { useEffect, useState, useContext } from 'react'
import I18n from 'i18n-js'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography, Hidden, CircularProgress } from '@mui/material'
import { IconButton } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

import { SearchBar, CrudsBtn } from '../../components'
import users from '../../services/users'
import { AuthContext } from '../../context/auth'
import { canAccess } from '../../router/permissions'
import { actionKeys, pagesKeys, images } from '../../utils'
import { Icons } from '../../utils'
import useStyles from '../crudsStyles'
import useStyle from './style'

const Users = () => {
  const classes = useStyles()
  const classesUser = useStyle()

  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()

  //permissions
  const pageId = pagesKeys.users

  //search
  const [search, setSearch] = useState('')

  //list
  const [data, setData] = useState([])

  const getData = async () => {
    const data = await users.list()
    setData(data.data?.users || [])
  }

  const handleChangeSearchbar = (e) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const ac = new AbortController()
    if (!canAccess(actionKeys.view, pageId, state)) {
      history.push('/dashboard')
    } else {
      getData()
    }
    return () => {
      ac.abort()
    }
  }, [])

  // Table
  const columns = [
    {
      field: 'fullName',
      headerName: I18n.t('cruds.users.tableheader.fullName'),
      flex: 1,
    },
    {
      field: 'rol',
      headerName: I18n.t('cruds.users.tableheader.rol'),
      width: 140,
    },
    {
      field: 'age',
      headerName: I18n.t('cruds.users.tableheader.age'),
      width: 105,
    },
    {
      field: 'email',
      headerName: I18n.t('cruds.users.tableheader.email'),
      flex: 1,
    },
    {
      field: 'verified',
      headerName: I18n.t('cruds.users.tableheader.verified'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <img src={row.verified === 'Actif' ? images.Active : images.Inactive} />
      ),
    },
    {
      field: 'actions',
      headerName: I18n.t('general.actions'),
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 105,
      hide:
        canAccess(actionKeys.edit, pageId, state) ||
        canAccess(actionKeys.delete, pageId, state)
          ? false
          : true,
      // eslint-disable-next-line react/display-name
      renderCell: ({ row }) => (
        <Grid container justifyContent="center">
          {canAccess(actionKeys.edit, pageId, state) && (
            <IconButton
              onClick={() =>
                history.push(`${history.location.pathname}/edit/${row.id}`)
              }
              color="secondary"
            >
              {Icons('edit')}
            </IconButton>
          )}
        </Grid>
      ),
    },
  ]

  const rows = [
    ...data.map((item) => {
      return {
        id: item.email,
        fullName:
          item.name + ' ' + (item.lastname != null ? item.lastname : ''),
        rol: item?.rol?.name,
        age: item?.age || I18n.t('general.dataNotFound'),
        email: item.email || '',
        verified: item.verified,
      }
    }),
  ]

  const filterData = (rows) => {
    return rows.filter(
      (item) =>
        item.fullName.toLowerCase().includes(search.toLowerCase()) ||
        item.rol.toLowerCase().includes(search.toLowerCase()) ||
        item.age == search ||
        item.email.toLowerCase().includes(search.toLowerCase())
    )
  }
  return (
    <Box p={4}>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item className={classes.items}>
          <Typography variant="body2" color="textSecondary">
            {I18n.t('breadcrumb.users')} /{' '}
            {I18n.t('breadcrumb.dashboard')}
          </Typography>
        </Grid>
        <Grid item className={classes.itemFlex}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.title}
          >
            {I18n.t('breadcrumb.users')}
          </Typography>
          {canAccess(actionKeys.list, pageId, state) && (
            <Hidden smDown>
              <div className={classes.search}>
                <SearchBar change={handleChangeSearchbar} />
              </div>
            </Hidden>
          )}

          {canAccess(actionKeys.create, pageId, state) && (
            <CrudsBtn
              text={I18n.t('cruds.users.crateButton')}
              size="medium"
              click={() => history.push(`${history.location.pathname}/create`)}
            />
          )}
        </Grid>
        {canAccess(actionKeys.list, pageId, state) && (
          <Hidden mdUp>
            <Grid item className={classes.items}>
              <SearchBar change={handleChangeSearchbar} />
            </Grid>
          </Hidden>
        )}
        {canAccess(actionKeys.list, pageId, state) && (
          <Grid item className={classes.items}>
            <div style={{ width: '100%', background: 'white' }}>
              { (rows?.length > 0) &&
                (
                  <DataGrid
                    columns={columns}
                    rows={filterData(rows)}
                    autoHeight
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    className={classesUser.root}
                  />
                ) ||
                (
                  <CircularProgress className={classes.circularProgress} />
                )
              }
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Users
