const getQuestions = (traductions, data) => {
  let questions = []
  let options = []
  let localQuestions = {}
  Object.values(data.questions)?.map((item, index) => {
    localQuestions[index] = item
  })
  Object.values(traductions).map((traduction) => {
    Object.values(traduction.questions).map((question, index) => {
      //get the questions of all traductions
      questions = questions.concat(question)
      /* filter the free questions */
      if (localQuestions?.[index]?.type !== 3) {
        Object.values(question.options).map((option) => {
          options = options.concat(option)
        })
      }
    })
  })
  return questions.concat(options)
}

const getAnnotations = (traductions) => {
  let annotations = []
  Object.values(traductions).map((traduction) => {
    Object.values(traduction.annotations).map((annotation) => {
      //get the questions of all traductions
      annotations = annotations.concat(annotation)
    })
  })
  return annotations
}

const getBlocks = (traductions) => {
  let blocks = []
  Object.values(traductions).map((traduction) => {
    Object.values(traduction.blocks).map((block) => {
      //get the blocks of all traductions
      blocks = blocks.concat({ instructons: block.instructions })
    })
  })
  return blocks
}

const notAllHaveTraductions = (
  traductions,
  isQuestionarie = false,
  isIndicator = false,
  isSmartTest = false,
  data = {}
) => {
  let allFields = traductions
  if (isQuestionarie) {
    allFields = getQuestions(traductions, data)
    Object.values(traductions).map((traduction) => {
      allFields = allFields.concat(traduction)
    })
  }
  if (isIndicator) {
    allFields = getAnnotations(traductions)
    Object.values(traductions).map((traduction) => {
      allFields = allFields.concat(traduction)
    })
  }
  if (isSmartTest) {
    allFields = getBlocks(traductions)
    Object.values(traductions).map((traduction) => {
      allFields = allFields.concat(traduction)
    })
  }

  const emptyFields = Object.values(allFields).map((traduction) => {
    //search in all values one whith nothing inside
    if (Object.values(traduction).find((value) => value == '') == '') {
      return true
    } else {
      return false
    }
  })
  //if empty field have a true return true else return false
  if (emptyFields.find((field) => field == true)) {
    return true
  } else {
    return false
  }
}

export default notAllHaveTraductions
