import { Box, Button } from '@mui/material'
import I18n from 'i18n-js'
import { DinamicForm } from '../../../../../components'
import { Icons } from '../../../../../utils'
import { useStyles } from './styles'

const FilterDate = ({
  input,
  dateselected,
  onChange,
  filterDate,
  clearDate,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.vdsiSearchContainer}>
      <Box className={classes.search} />

      <Box className={classes.container}>
        <DinamicForm
          handleChange={() => {}}
          inputs={input.slice(0, 1)}
          values={dateselected}
          errorDate={false}
          onAccept={(e) => {
            onChange({
              target: { name: 'startDate', value: e },
            })
          }}
        />

        <DinamicForm
          handleChange={() => {}}
          inputs={input.slice(1, 2)}
          values={dateselected}
          errorDate={false}
          onAccept={(e) => {
            onChange({
              target: { name: 'endDate', value: e },
            })
          }}
        />
        <Button
          padding="14px 10px"
          variant="outlined"
          className={`${classes.deleteBtn} ${classes.paddingbtn}`}
          onClick={filterDate}
          disabled={!dateselected.startDate}
        >
          {Icons('filter')}
        </Button>
        <Button
          padding="14px 10px"
          variant="outlined"
          className={`${classes.deleteBtn} ${classes.paddingbtn}`}
          onClick={clearDate}
          disabled={!dateselected.startDate && !dateselected.endDate}
        >
          {I18n.t('patient.form.clearDate')}
          {Icons('filterOff')}
        </Button>
      </Box>
    </Box>
  )
}

export default FilterDate
