import { GET, POST, PUT, DELETE } from '../utils/crud'

const list = async () => {
  const res = await GET('type-staff')
  return res
}

const create = async (body) => {
  const data =  body 
  const res = await POST('type-staff', data)
  return res
}

const edit = async (body) => {
  const { id, update } = body
  const data = update 
  const res = await PUT(`type-staff/${id}`, data)
  return res
}

const destroy = async (id) => {
  const res = await DELETE(`type-staff/${id}`)
  return res
}

export default {
  list,
  create,
  edit,
  destroy,
}
