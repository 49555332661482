import {
  Grid,
  Typography,
} from '@mui/material'
import I18n from 'i18n-js'
import { useEffect, useState } from 'react'
import useStyle from './style'
import { HandleTypeCard, IconCard, ImageModal, InformativeCard } from './components'

const CardTab = ({
  data,
  form = false,
  value = true,
  mt = 0,
  lg,
  md,
  removeArrow,
  removeStatusPoint,
  titleProps,
  removeHeader,
  gridItemProps

}) => {
  const dataList = Object.values(data)
  const classes = useStyle()
  const [openModal, setOpenModal] = useState(false)
  const [imgList, setImgList] = useState({
    title: '',
    list: [],
  })
  const [steps, setSteps] = useState(0)
  const [listArrow, setListArrow] = useState([])
  const handleArrow = (id) => {
    const listInd = listArrow?.indexOf(id)

    const newTrackingList = [...listArrow]

    if (listInd === -1) {
      newTrackingList?.push(id)
    } else {
      newTrackingList?.splice(listInd, 1)
    }

    setListArrow(newTrackingList)
  }

  const handleValues = (type, val) => {
    if (type === 'numeric' || type === 'boolean') {
      return (
        <Grid item xs={!val?.vc ? 6 : 10} mt={!val?.vc ? 0 : 2}>
          {!val?.vc ? (
            <>
              {val?.value !== '' || val?.unit !== '' ? (
                <Typography
                  variant="subtitle2"
                  component="span"
                  className={classes.name}
                >
                  {val?.value !== '' ? `${val?.value} ${val?.unit}` : 'N/A'}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle2"
                  component="span"
                  className={classes.name}
                >
                  N/A
                </Typography>
              )}
            </>
          ) : (
            <Typography
              variant="subtitle2"
              component="span"
              className={classes.name}
            >
              {val?.interpretation !== '' ? val?.interpretation : 'N/A'}
            </Typography>
          )}
        </Grid>
      )
    }
  }
  useEffect(() => {
    setListArrow([])
  }, [data])

  const handleClose = () => {
    setOpenModal(false)
    setSteps(0)
    setImgList({
      list: [],
      title: '',
    })
  }

  if (dataList.length > 0) {
    return (
      <Grid container spacing={2} mt={mt}>
        <ImageModal
          modalProps={{
            open: openModal,
            title: imgList.title,
            onClose: handleClose
          }}
          steps={steps}
          setSteps={setSteps}
          imgList={imgList?.list}
        />

        {!!form && (
          <IconCard
            list={dataList}
            lg={lg}
            md={md}
            handleArrow={handleArrow}
            handleTypeCard={(target) => <HandleTypeCard target={target} setImgList={setImgList} setOpenModal={setOpenModal} />}
            listArrow={listArrow}
          />)
        }

        {
          !form && (
            <InformativeCard
              removeHeader={removeHeader}
              list={dataList}
              titleProps={titleProps}
              value={value}
              handleValues={handleValues}
              removeArrow={removeArrow}
              handleArrow={handleArrow}
              listArrow={listArrow}
              removeStatusPoint={removeStatusPoint}
              gridItemProps={gridItemProps}
            />
          )
        }
      </Grid>
    )
  } else {
    return (
      <Typography variant="body1" color="textSecondary">
        {I18n.t('general.notFound')}
      </Typography>
    )
  }
}

export default CardTab
