import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Fade, Typography } from '@mui/material'
import { Refresh } from '@mui/icons-material'
import I18n from 'i18n-js'
import { smartTests } from '../../../services'
import { CrudsBtn, Loading, ShowSmartStepCard } from '../../../components'
import useStyles from './styles'

const Questions = () => {
  const classes = useStyles()
  const { id } = useParams()

  /* data */
  const [test, setTest] = useState([])
  const [steps, setSteps] = useState({})
  const [loading, setLoading] = useState(true)

  /* fetch */
  const getSteps = async () => {
    const testResponse = await smartTests.show(id)
    setTest(testResponse.data || {})
    setSteps(testResponse.data?.steps || [])
    setLoading(false)
  }

  useEffect(() => {
    getSteps()
  }, [])
  if (loading) {
    return <Loading />
  } else
    return (
      <Fade in={!loading} timeout={500}>
        <Box className={classes.root}>
          <Box>
            <Typography variant="body2" color="textSecondary">
              {I18n.t('breadcrumb.smartTest')} /{' '}
              <Link
                to="/dashboard/smart-test"
                className={classes.textBreadcrum}
              >
                {I18n.t('breadcrumb.dashboard')} /{' '}
              </Link>
              {I18n.t('cruds.smartTest.questions.indicator')}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" color="primary">
              {I18n.t('cruds.smartTest.questions.title')}
            </Typography>
            <Box className={classes.subtitle}>
              <Typography variant="h6" color="primary">
                {test.title}
              </Typography>

              <CrudsBtn
                startIcon={<Refresh />}
                variant="outlined"
                click={getSteps}
                text={I18n.t('components.refresh')}
              />
            </Box>
            <Typography
              className={classes.description}
              variant="subtitle1"
              color="textSecondary"
            >
              {test.description}
            </Typography>
          </Box>

          <Box mt={2} className={classes.body}>
            {steps.map((step) => (
              <ShowSmartStepCard step={step} key={step.id} />
            ))}
          </Box>
        </Box>
      </Fade>
    )
}

export default Questions
