import JSZip from 'jszip'
import colors from './colors'
import keys from './statusKeys'
import { format, parse } from 'date-fns'
import { saveAs } from 'file-saver'
function joinStyles(arr) {
  return arr.join(' ')
}

function getPercentage(value, total) {
  return total === 0
    ? '0%'
    : ((parseFloat(value) / parseFloat(total)) * 100).toFixed(1) + '%'
}

export const download = async (url, filename, downloadFast) => {
  if (!downloadFast) {
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  } else {
    const urlPublic = url.replace('storage', 'public-storage')
    const typeI = urlPublic.split('.')

    var image = await fetch(urlPublic)
    var imageBlog = await image.blob()
    saveAs(imageBlog, `${filename}.${typeI[typeI?.length - 1]}`)
  }
}

export const saveArrayZip = async (zipName, urls) => {
  if (!urls) return

  const zip = new JSZip()

  var count = 0
  var zipFilename = `${zipName}.zip`
  return await new Promise((resolve) => {
    urls.forEach(async function (imgURL, i) {
      
    const urlPublic = imgURL.replace('storage', 'public-storage')
      var filename = zipName + i + '.jpg'
      var image = await fetch(urlPublic)
      var imageBlog = await image.blob()
      var img = zip.folder(zipName)
      // loading a file and add it in a zip file
      img.file(filename, imageBlog, { binary: true })
      count++
      if (count == urls.length) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          saveAs(content, zipFilename)
        })
        resolve(true)
      }
    })
  })
}

export const downloadZip = (blob) => {
  var file = new Blob([blob], { type: 'application/zip' })

  var fileURL = URL.createObjectURL(file)

  var link = document.createElement('a')

  link.href = fileURL

  link.click()
}
function getColor(k) {
  switch (k) {
    case keys.good:
      return colors.success
    case keys.warning:
      return colors.warning
    case keys.danger:
      return colors.danger
    default:
      return colors.disabled
  }
}

function getColoredFont(k) {
  switch (k) {
    case keys.good:
      return 'fontColorSuccess'
    case keys.warning:
      return 'fontColorWarning'
    case keys.danger:
      return 'fontColorDanger'
    default:
      return 'fontColorDisabled'
  }
}

function getColoredBorderBottom(k) {
  switch (k) {
    case keys.good:
      return 'borderBottomSuccess'
    case keys.warning:
      return 'borderBottomWarning'
    case keys.danger:
      return 'borderBottomDanger'
    default:
      return 'borderBottomDisabled'
  }
}

function getColoredFontForPercentNumber(k) {
  if (k >= 65) {
    return 'fontColorSuccess'
  }
  if (k >= 35) {
    return 'fontColorWarning'
  }
  return k ? 'fontColorDanger' : 'fontColorDisabled'
}

function getBackgroundColorForNumber(k) {
  if (k <= 3) {
    return 'backgroundColorSuccess'
  }
  if (k <= 7) {
    return 'backgroundColorWarning'
  }
  return 'backgroundColorDanger'
}

function getBackgroundColorForFont(k) {
  switch (k) {
    case keys.good:
      return 'backgroundColorSuccess'
    case keys.warning:
      return 'backgroundColorWarning'
    case keys.danger:
      return 'backgroundColorDanger'
    default:
      return 'backgroundColorDisabled'
  }
}

function getFontColorForNumber(k) {
  if (k <= 3) {
    return 'fontColorSuccess'
  }
  if (k <= 7) {
    return 'fontColorWarning'
  }
  return k ? 'fontColorDanger' : 'fontColorDisabled'
}

function getColoredCard(k) {
  switch (k) {
    case keys.good:
      return 'cardColorSuccess'
    case keys.warning:
      return 'cardColorWarning'
    case keys.danger:
      return 'cardColorDanger'
    default:
      return 'cardColorDisabled'
  }
}

function getColoredKforce(k) {
  switch (k) {
    case keys.good:
      return 'kforceGood'
    case keys.ok:
      return 'kforceOk'
    case keys.warning:
      return 'kforceWarning'
    case keys.care:
      return 'kforceCare'
    case keys.danger:
      return 'kforceDanger'
    default:
      return 'cardColorDisabled'
  }
}

function dateToHour(date) {
  return format(parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()), 'h:mm a')
}

function toSeconds(time) {
  let arr = time.split(':')
  return parseInt(arr[0]) * 3600 + parseInt(arr[1]) * 60 + parseInt(arr[2])
}

function toMinutes(time) {
  let arr = time.split(':')
  return parseInt(arr[0]) * 60 + parseInt(arr[1]) + parseInt(arr[2] / 60)
}

function concatDateTime(date, time) {
  let dateArr = date.split('-')
  let timeArr = time.split(':')
  return new Date(
    dateArr[0],
    dateArr[1] - 1,
    dateArr[2],
    timeArr[0],
    timeArr[1],
    timeArr[2]
  )
}

export {
  joinStyles,
  getPercentage,
  getColor,
  getColoredFont,
  getColoredBorderBottom,
  getColoredFontForPercentNumber,
  getBackgroundColorForNumber,
  getBackgroundColorForFont,
  getFontColorForNumber,
  getColoredKforce,
  dateToHour,
  getColoredCard,
  toSeconds,
  toMinutes,
  concatDateTime,
}
