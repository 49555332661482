import { Fragment, useEffect, useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import I18n from 'i18n-js'
import { languages } from '../../../../../../utils'
import { QuestionViewer, QuestionTranslate } from '../../../../../../components'
import useStyles from './style'

const ItemsLenght = (questions) => {
  if (questions) {
    const itemsQuestionary = []
    Object.values(questions).map(() => {
      itemsQuestionary.push({
        name: 'title',
        questionary: true,
      })
    })

    return itemsQuestionary
  }
}
const setInitialvalues = (values, translations, locale) => {
  let data = {}
  const lngTraslations = languages.filter(
    (language) => language.locale !== locale
  )
  lngTraslations.map((lngTraslation) => {
    data[lngTraslation.locale] = {}
  })
  if (values[locale].questions) {
    translations.map((translation) => {
      //questions
      Object.values(values[locale].questions).map((question, questionId) => {
        data[translation.locale] = {
          ...data[translation.locale],
          questions: {
            ...data[translation.locale].questions,
            [questionId]: {
              title:
                values.traductions?.[translation.locale]?.questions?.[
                  questionId
                ]?.title || '',
            },
          },
        }
        Object.values(question.options).map((option, optionId) => {
          data[translation.locale] = {
            ...data[translation.locale],
            questions: {
              ...data[translation.locale].questions,
              [questionId]: {
                title:
                  values.traductions?.[translation.locale]?.questions?.[
                    questionId
                  ]?.title || '',

                options: {
                  ...data[translation.locale].questions[questionId].options,
                  [optionId]: {
                    title:
                      values.traductions?.[translation.locale]?.questions?.[
                        questionId
                      ]?.options?.[optionId]?.title || '',
                  },
                },
              },
            },
            locale: translation.locale,
          }
        })
      })
    })
  }

  return data
}
const LanguajeSettingsForm = ({ values, locale, setCreateData }) => {
  const translations = languages.filter((language) => language.locale != locale)
  const [data, setData] = useState(
    setInitialvalues(values, translations, locale)
  )
  const [itemSelected, setItemSelected] = useState(0)
  const itemsLanguage = ItemsLenght(values[locale].questions)

  const questions = Object.values(values[locale].questions || {})
  const [questionSelected, setQuestionSelected] = useState(0)

  const handleNextItem = () => {
    setItemSelected(itemSelected + 1)
    setQuestionSelected(questionSelected + 1)
  }
  const handleBeforeItem = () => {
    setItemSelected(itemSelected - 1)
    setQuestionSelected(questionSelected - 1)
  }

  useEffect(() => {
    setCreateData({ ...values, traductions: data })
  }, [data])
  const classes = useStyles()
  return (
    <Fragment>
      <Box className={classes.headerContainer}>
        <Typography variant="body1" color="initial">
          {I18n.t('cruds.profiles.create.translateTitle')}
        </Typography>
        <Box className={classes.selectInputContainer}>
          <Box className={classes.selectInput}>
            <IconButton
              size="small"
              disabled={itemSelected == 0}
              onClick={handleBeforeItem}
            >
              <NavigateBefore />
            </IconButton>
            <Typography variant="h6">
              {`${itemSelected + 1}/${itemsLanguage.length}`}
            </Typography>
            <IconButton
              size="small"
              disabled={itemSelected == itemsLanguage.length - 1}
              onClick={handleNextItem}
            >
              <NavigateNext />
            </IconButton>
          </Box>
          <Typography variant="caption" color="textSecondary">
            {I18n.t('cruds.profiles.create.textToTranslate')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.infoContainer}>
        {itemsLanguage[itemSelected].questionary && (
          <QuestionViewer
            number={questionSelected}
            question={questions[questionSelected]}
            type={2}
          />
        )}
      </Box>
      <Box>
        {data &&
          translations.map((translation, index) => {
            if (itemsLanguage[itemSelected].questionary) {
              return (
                <QuestionTranslate
                  translation={translation}
                  question={questions[questionSelected]}
                  data={data}
                  id={questionSelected}
                  setData={setData}
                  key={index}
                />
              )
            }
          })}
      </Box>
    </Fragment>
  )
}

export default LanguajeSettingsForm
