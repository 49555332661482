import { makeStyles } from '@mui/styles'
import colors from '../../utils/colors'

const useStyles = makeStyles({
  resetContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  formContent: {
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputText: {
    background: 'white',
    borderRadius: '2px',
    // height: '35px',
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
    '&.MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
    },
  },
  sliderContainer: {
    margin: '0 auto',
    width: '90%',
  },

  submitContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'start',
    justifySelf: '',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: colors.danger,
  },
})

export default useStyles
