import { useContext } from 'react'
import { Box, MenuItem, TextField, Typography } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import I18n from 'i18n-js'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import { AuthContext } from '../../context/auth'
import { dateLocales, languages } from '../../utils'
import { InputForm } from '..'
import useStyles from './style'
import { isEmail, isPhoneNumber } from '../../validators/validator'
import { isEmpty } from '../../validators/validator'
import { MobileDatePicker } from '@mui/lab'
import { isFirefox } from '../../hooks/browser'
import { listCountries, transfromLocalePhone } from '../../utils/locale'

const FormCreateUser = ({ user, change, checkEmail, setUser }) => {
  const classes = useStyles()
  //state
  const { state } = useContext(AuthContext)
  const userDate = new Date(user?.birth || '')
  userDate.setMinutes(userDate.getMinutes() + userDate.getTimezoneOffset())

  return (
    <div className={classes.form}>
      <Box className={classes.inputsContainer}>
        <TextField
          variant="outlined"
          name="name"
          label={I18n.t('cruds.users.inputs.name')}
          defaultValue={user?.name}
          onChange={change}
          error={user.name === undefined ? false : isEmpty(user?.name) || false}
          color="secondary"
          className={classes.inputText}
          inputProps={{ maxLength: 250 }}
        />
        {user.name === undefined
          ? false
          : isEmpty(user?.name) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.required')}
              </Typography>
            )}
      </Box>
      <Box className={classes.inputsContainer}>
        <TextField
          variant="outlined"
          name="lastname"
          label={I18n.t('cruds.users.inputs.lastname')}
          defaultValue={user?.lastname}
          onChange={change}
          error={user.lastname === undefined ? false : isEmpty(user.lastname)}
          color="secondary"
          className={classes.inputText}
          inputProps={{ maxLength: 250 }}
        />
        {user.lastname === undefined
          ? false
          : isEmpty(user?.lastname) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.required')}
              </Typography>
            )}
      </Box>
      <Box className={classes.inputsContainer}>
        <MaterialUiPhoneNumber
          disableDropdown={isFirefox()}
          color="secondary"
          className={classes.inputText}
          variant="outlined"
          label={I18n.t('cruds.users.inputs.phone')}
          error={user?.phone ? !isPhoneNumber(user.phone) : false}
          onChange={change}
          value={user?.phone}
          autoFormat={false}
          defaultCountry={transfromLocalePhone[state.locale] || listCountries.fr}
          inputProps={{ maxLength: 250 }}
        />
        {user?.phone
          ? !isPhoneNumber(user.phone) && (
              <Typography variant="caption" color="error">
                {I18n.t('validators.invalidPhone')}
              </Typography>
            )
          : ''}
      </Box>
      <Box className={classes.inputsContainer}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={dateLocales[state.locale]}
        >
          <MobileDatePicker
            onChange={(value) => {
              setUser({ ...user, birth: value })
            }}
            value={userDate == 'Invalid Date' ? null : userDate}
            inputvariant="outlined"
            maxDate={new Date()}
            toolbarTitle=""
            inputFormat={I18n.t('general.dateFormat') || 'dd-MM-yyyy'}
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  variant="outlined"
                  className={classes.inputText}
                  color="secondary"
                  id="birth"
                  label={I18n.t('myProfile.inputs.birth') + '(*)'}
                  onClick={() => setUser({ ...user, birth: new Date() })}
                />
              )
            }}
          />
        </LocalizationProvider>
      </Box>

      <Box className={classes.inputsContainer}>
        <InputForm
          variant="outlined"
          color="secondary"
          name="email"
          label={I18n.t('cruds.users.inputs.email')}
          onBlur={checkEmail}
          error={
            user?.email !== undefined &&
            (isEmpty(user?.email) || isEmail(user?.email) || !user.existEmail)
          }
          defaultValue={user?.email}
          className={classes.inputText}
          onChange={change}
        />
        {!isEmpty(user?.email) ? (
          (isEmail(user?.email) || !user.existEmail) && (
            <Typography variant="caption" color="error">
              {user.existEmail
                ? I18n.t('validators.invalidEmail')
                : I18n.t('validators.emailRegistered')}
            </Typography>
          )
        ) : (
          <Typography variant="caption" color="error">
            {user?.email !== undefined ? I18n.t('validators.required') : ''}
          </Typography>
        )}
      </Box>

      <Box className={classes.inputsContainer}>
        <TextField
          variant="outlined"
          color="secondary"
          name="locale"
          label={I18n.t('cruds.users.inputs.locale')}
          value={!user.locale ? '' : user.locale}
          className={classes.inputText}
          onChange={change}
          select
        >
          {languages.map((language, index) => {
            return (
              <MenuItem key={index} value={language.locale}>
                {I18n.t(language.name)}
              </MenuItem>
            )
          })}
        </TextField>
      </Box>
    </div>
  )
}

export default FormCreateUser
