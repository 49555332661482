import { useEffect, useState, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Box, Typography, Fade } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import I18n from 'i18n-js'
import { AuthContext } from '../../../context/auth'
import {
  healthConditions,
  country,
  medicalStaff,
  patients,
  observations,
  users,
} from '../../../services'
import { formatDate, formatDateHour } from '../../../hooks/dates/formatDate'
import Step1 from '../form/steps/step1'
import Step2 from '../form/steps/step2'
import { StepperCreate, CrudsBtn, Loading } from '../../../components'
import { canAccess } from '../../../router/permissions'
import { validatorPatient } from '../../../validators/patientValidator'
import { pagesKeys, actionKeys } from '../../../utils'
import useStyles from './style'
import {
  isEmail,
  isNumber,
  numbersAndCharacters,
} from '../../../validators/validator'
import {
  detailCommunities,
  listStates,
  detailState,
  detailCommunitiesId,
  zipCodeCommunity,
} from '../../../services/states'
import { reverseDate } from '../../../utils/date'
import { format } from 'date-fns'
import { extraFieldPt } from '../list'
import { toBase64 } from '../../../hooks/base64'

const EditPatient = () => {
  const classes = useStyles()
  //state
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const { id } = useParams()
  //loading
  const [loading, setLoading] = useState(true)

  //data
  const [healthCondition, setHealthConditions] = useState([])
  const [countries, setCountries] = useState([])
  const [medicalStaffsDoctors, setMedicalstaffsDoctors] = useState([])
  const [patient, setPatient] = useState({})
  const [editEmail, setEditEmail] = useState('true')
  const [loadZipCode, setLoadZipCode] = useState(false)
  const [image, setImage] = useState()

  //validations
  const [currentField, setCurrentField] = useState('')
  const [errors, setErrors] = useState({})
  const [disableEmail, setdisableEmail] = useState(false)
  const [existObservation, setExistObservation] = useState(undefined)
  //stepper
  const [currentStep, setCurrentStep] = useState(0)
  const [newFields, setNewFields] = useState(null)
  const [countriesWithLocale, setCountriesWithLocale] = useState([])
  const [disabled, setDisabled] = useState(true)
  const isSameCountryOfTheDoctor = countriesWithLocale.find(item => item.id == patient.country_birth)?.locale == state.locale
  //methods
  const handleNext = () => {
    if (currentStep == 1) {
      SendPatient()
    } else {
      setCurrentStep(currentStep + 1)
    }
  }
  const handleBack = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleChangePatient = async ({ target }) => {
    const { name, value } = target
    setCurrentField(name)

    if (name === 'photo') {
      if (value) {
        toBase64(value).then((base64) => {
          setPatient(prev => ({ ...prev, [name]: base64 }))
        })
      } else {
        setPatient(prev => ({ ...prev, [name]: null }))
      }
    } else if (name === 'state_id') {
      if (value) {
        setPatient(prev => ({
          ...prev,
          [name]: value,
          zip_code: null,
          community_id: null,
        }))
        let communities = await detailCommunities(value.id)

        communities = await communities?.data?.commnunities?.sort(
          (a, b) => a.name - b.name
        )

        setNewFields({
          ...newFields,
          communities: communities,
        })
      } else {
        setNewFields({
          ...newFields,
          communities: undefined,
        })
        setPatient(prev => ({
          ...prev,
          [name]: null,
          zip_code: null,
          community_id: null,
        }))
        let error = { ...errors }

        delete error.community_id

        setErrors({ ...error, zip_code: undefined })
      }
    } else if (name === 'community_id') {
      setPatient(prev => ({
        ...prev,
        [name]: value,
        zip_code: value?.zip_code ?? '',
      }))
      setErrors({ ...errors, zip_code: undefined })
    } else if (name === 'zip_code') {
      if ((isNumber(value) && value.length <= 5) || value === '') {
        searchZipCode(value)
      }
    } else if (extraFieldPt.includes(name)) {
      if (
        (isNumber(value) &&
          ((name === 'cc' && value.length <= 8) ||
            (name === 'nif' && value.length <= 9) ||
            (name === 'niss' && value.length <= 11) ||
            (name === 'sns' && value.length <= 9) ||
            (name === 'nuss' && value.length <= 12))) ||
        (name === 'dni' && value.length <= 9 && !numbersAndCharacters(value))
      ) {
        setPatient(prev => ({
          ...prev,
          [name]: value,
        }))
      } else if (value === '') {
        setPatient(prev => ({
          ...prev,
          [name]: '',
        }))
      }
    } else if (name === 'phone') {
      if (value.length < 16) setPatient(prev => ({ ...prev, [name]: value }))
    } else {
      let newPatient = {
        [name]: name === 'email' ? value.toLowerCase() : value,
      }

      setPatient(prev => ({ ...prev, ...newPatient }))

      if (target.name === 'email') {
        if (!isEmail(value)) {
          const json = await users.checkEmail(target.value)
          if (json.data) {
            setErrors({
              ...errors,
              email: I18n.t('validators.emailExist'),
            })
            setdisableEmail(true)
          } else {
            setErrors({
              ...errors,
              email: undefined,
            })
            setdisableEmail(false)
          }
        } else {
          setdisableEmail(false)
        }
      }
    }
  }

  const searchZipCode = async (value) => {
    let errorsCopy = { ...errors }
    let patientCopy = { ...patient, zip_code: value }
    setPatient(patientCopy)

    if (value.length === 5) {
      setLoadZipCode(true)
      const zipCode = newFields?.communities?.find(
        (item) => `${item?.zip_code}` === value
      )

      if (zipCode) {
        delete errorsCopy.state_id
        delete errorsCopy.community_id
        delete errorsCopy.zip_code

        patientCopy = {
          ...patient,
          zip_code: value,
          community_id: zipCode,
        }
      } else if (!patient.state_id?.id) {
        delete errorsCopy.zip_code
        setErrors(errorsCopy)

        const zipCodeCommunities = await zipCodeCommunity(value)

        if (zipCodeCommunities.data?.commnunities?.length) {
          let zipcodeData = zipCodeCommunities.data?.commnunities[0]

          delete errorsCopy.state_id
          delete errorsCopy.community_id
          patientCopy = {
            ...patient,
            state_id: newFields.states.find(
              (item) => item.id === zipcodeData.state_id
            ),
            community_id: zipcodeData,
            zip_code: value,
          }

          const communitiesList = await detailCommunities(zipcodeData.state_id)

          setNewFields({
            ...newFields,
            communities: communitiesList.data?.commnunities,
          })
        } else errorsCopy.zip_code = I18n.t('validators.zipCode')
      } else errorsCopy.zip_code = I18n.t('validators.zipCodeNotAssociate')
      setLoadZipCode(false)
    } else if (value.length > 0)
      errorsCopy.zip_code = I18n.t('validators.invalidZipCode')
    else errorsCopy.zip_code = undefined

    setErrors(errorsCopy)
    setPatient(patientCopy)
  }

  const SendPatient = async () => {
    setLoading(true)

    const idPatient = patient.id
    let old_email = patient.old_email
    let newPatient = { ...patient }

    if (!isSameCountryOfTheDoctor) {
      delete newPatient.state_id
      delete newPatient.community_id
      delete newPatient.zip_code
      delete newPatient.SSN
      delete newPatient.cc
      delete newPatient.expire_date
      delete newPatient.nif
      delete newPatient.niss
      delete newPatient.sns
      delete newPatient.comments
      delete newPatient.dni
      delete newPatient.nuss
    }
    delete newPatient.observation

    delete newPatient.old_email

    if (newPatient.photo === null) newPatient.photo = null
    else if (newPatient.photo === undefined) newPatient.photo = ''

    if (old_email === newPatient.email) delete newPatient.email

    if (newPatient.state_id && newPatient.state_id !== null) {
      newPatient = {
        ...newPatient,
        state_id: newPatient.state_id.id,
      }
    }

    if (newPatient?.expire_date && newPatient?.expire_date !== null) {
      newPatient = {
        ...newPatient,
        expire_date: reverseDate(
          format(new Date(newPatient.expire_date), I18n.t('general.dateFormat'))
        ),
      }
    }

    if (newPatient?.community_id && newPatient?.community_id !== null) {
      newPatient = {
        ...newPatient,
        community_id: newPatient?.community_id?.id,
      }
    }
    if (newPatient?.zip_code?.length < 5) newPatient.zip_code = ''

    newPatient.expire_date = patient.expire_date || null
    const updatePatient = await patients.update(idPatient, { ...newPatient })

    if (updatePatient.data) {
      if (
        patient.id_medical_center !== null &&
        patient.id_medical_center !== undefined
      ) {
        await patients.addCenter(idPatient, patient.id_medical_center)
      }

      if (
        patient.main_doctor !== undefined &&
        patient.main_doctor?.length > 0
      ) {
        await patients.addMedicalStaff(idPatient, {
          main_doctor: patient.main_doctor[0],
        })
      }

      if (
        patient.observation !== undefined &&
        patient.observation?.length > 0
      ) {
        let body = {
          patient_id: idPatient,
          observations: patient.observation,
        }

        await observations.create(body)
      }

      await patients.associateAll(idPatient)
      if (patient.id_health_conditions?.length > 0) {
        await patients.addHealthConditions(
          idPatient,
          patient.id_health_conditions
        )
      }

      setCurrentStep(currentStep + 1)
    }
    setLoading(false)
  }

  const getHealthConditions = () => {
    healthConditions.list().then((response) => {
      let arrayHealthConditions = []

      response?.data?.healthConditions?.forEach((element) => {
        element.children.forEach((item) => {
          arrayHealthConditions.push(item)
        })
      })

      setHealthConditions(arrayHealthConditions)
    })
  }

  const getCountries = () => {
    country.list().then((response) => {
      setCountriesWithLocale(response?.data?.countries?.filter(item => !!item.locale))
      setCountries(response?.data?.countries)
    })
  }

  const getMedicalStaff = () => {
    medicalStaff.doctors().then((response) => {
      setMedicalstaffsDoctors(response)
    })
  }

  const getObservations = () => {
    observations.detail(id).then((response) => {
      setExistObservation(
        response?.data?.observations
          ?.map((item) => {
            return {
              name: item.name,
              date: item.created_at && new Date(item.created_at).getTime(),
              medical:
                (item.medical_staff.name ?? '') +
                ' ' +
                (item.medical_staff.lastname ?? ''),
            }
          })
          .sort((a, b) => b.date - a.date)
          .map((item) => ({
            ...item,
            date:
              item?.date &&
              formatDateHour(
                format(
                  new Date(new Date(item?.date) + 'Z'),
                  I18n.t('general.dateTimeFormat')
                )
              ),
          }))
      )
    })
  }

  const getData = () => {
    getHealthConditions()
    getCountries()
    getMedicalStaff()
    getObservations()
  }
  //fetch
  const getSelects = async () => {
    getData()

    const { data } = await patients.detail(id)

    let newData = {}
    let communities = []
    if (state.locale === 'pt') {
      newData = {
        cc: data.patient.cc ?? '',
        nif: data.patient.nif ?? '',
        niss: data.patient.niss ?? '',
        sns: data.patient.sns ?? '',
        comments: data.patient.comments ?? '',
        expire_date: data.patient.expire_date ?? '',
      }
    } else if (state.locale === 'es') {
      newData = {
        dni: data.patient.dni,
        nuss: data.patient.nuss,
      }
    } else if (
      state.locale === 'fr' &&
      data.patient.state_id &&
      data.patient.community_id
    ) {
      const stateList = await detailState(data.patient.state_id)
      const communityList = await detailCommunitiesId(data.patient.community_id)

      if (stateList.data) {
        communities = stateList.data.state.communities

        delete stateList.data.state.communities
        delete stateList.data.state.country

        newData = {
          ...newData,
          state_id: stateList.data.state,
        }
      }

      if (communityList.data) {
        delete communityList.data.community.state

        newData = {
          ...newData,
          community_id: communityList.data.community,
          zip_code: communityList.data.community.zip_code ?? '',
        }
      }
    }
    setImage(data.patient?.photo_url)
    setPatient(prev => ({
      ...prev,
      id: data.patient.id,
      name: data.patient.name,
      lastname: data.patient.lastname,
      birth: formatDate(data.patient.birth),
      sex: data.patient.sex ? 1 : 0,
      height: data.patient.height,
      weight: data.patient.weight,
      right_hand: data.patient.right_hand,
      place_birth: data.patient.place_birth,
      country_birth: data.patient.country_birth,
      phone: data.patient.phone,
      email: data.patient.email,
      SSN: data.patient.SSN ?? '',
      id_medical_center: data.patient.medical_centers?.[0]?.id,
      old_email: data?.patient?.email,
      main_doctor:
        [
          data.patient.medical_staff.find((doctor) => doctor.pivot.main_doctor)
            ?.id,
        ] || [],
      id_medical_staff: data.patient.medical_staff
        .filter((item) => !item.pivot.main_doctor)
        .map((item) => item.id),
      id_health_conditions: data.patient.health_conditions.map(
        (item) => item.id
      ),
      ...newData,
    }))

    if (state.locale === 'fr') {
      const states = await listStates()

      if (states.data !== null) {
        setNewFields({
          states: states.data.states.sort((a, b) => a.name - b.name),
          locale: 'fr',
          communities: communities.sort((a, b) => a.name - b.name),
        })
      }
    } else if (['pt', 'es'].includes(state.locale))
      setNewFields({ locale: state.locale })
  }

  useEffect(() => {
    const accessAsync = async () => {
      if (!canAccess(actionKeys.create, pagesKeys.patients, state)) {
        return history.push('/dashboard')
      } else {
        await getSelects()
        if (canAccess(actionKeys.editEmail, pagesKeys.patients, state))
          setEditEmail(false)
        setLoading(false)
      }
    }

    accessAsync()
  }, [])

  // Validations
  useEffect(() => {
    const validation = async () => {
      let zip_code = errors.zip_code
      const errorsAux = await validatorPatient(patient, state.locale, false, isSameCountryOfTheDoctor)
      setDisabled(Object.values({ ...errorsAux, zip_code })?.some(item => item))
      if (currentField)
        setErrors({
          ...errors,
          [currentField]: errorsAux[currentField],
          ...({ zip_code } ?? {}),
        })
    }

    validation()
  }, [patient])


  const steps = [
    {
      label: 'cruds.patients.labels.stepOne',
      step: 'cruds.patients.steps.stepOne',
    },
    {
      step: 'cruds.patients.steps.stepTwo',
      label: 'cruds.patients.labels.stepTwo',
    },
  ]
  return (
    <Box p={4}>
      {/* breadcrumb */}
      <Typography variant="body2" color="textSecondary">
        {I18n.t('breadcrumb.patient')} /{' '}
        <Link to="/dashboard/patients" className={classes.textBreadcrum}>
          {I18n.t('breadcrumb.dashboard')} /{' '}
        </Link>
        {I18n.t('cruds.patients.edit.indicator')}
      </Typography>
      <Box className={classes.header}>
        {/* title */}
        <Box className={classes.title}>
          <Typography variant="h5" >
            {I18n.t('cruds.patients.edit.title')}
          </Typography>
          <Typography variant="h6" color="primary">
            {patient.name || patient.lastname
              ? (patient.name || '') + ' ' + (patient.lastname || '')
              : I18n.t('cruds.patients.edit.indicator')}
          </Typography>
        </Box>
        {/* stepper */}
        <Box className={classes.stepper}>
          <StepperCreate activeStep={currentStep} steps={steps} />
        </Box>
      </Box>
      <Box className={classes.root}>
        {loading ? (
          <Loading />
        ) : (
          <Fade in={!loading} timeout={500}>
            <Box>
              {/* body */}
              <Box className={classes.body}>
                <Fade in={currentStep == 0} timeout={500}>
                  <Box>
                    {currentStep == 0 && (
                      <Step1
                        setDisabled={null}
                        healthConditions={healthCondition}
                        countries={countries}
                        onChange={handleChangePatient}
                        patient={patient}
                        errors={errors}
                        setErrors={setErrors}
                        setdisableEmail={setdisableEmail}
                        edit={editEmail}
                        field={newFields}
                        loading={loadZipCode}
                        image={image}
                        isSameCountryOfTheDoctor={isSameCountryOfTheDoctor}
                      />
                    )}
                  </Box>
                </Fade>
                <Fade in={currentStep == 1} timeout={500}>
                  <Box>
                    {currentStep == 1 && (
                      <Step2
                        handleForm={handleChangePatient}
                        form={patient}
                        doctors={medicalStaffsDoctors}
                        errors={errors}
                        onChange={handleChangePatient}
                        existObservation={existObservation}
                      />
                    )}
                  </Box>
                </Fade>
                <Fade in={currentStep == 2} timeout={500}>
                  <Box>
                    {currentStep == 2 && (
                      <Box className={classes.finishContainer}>
                        <CheckCircle className={classes.chekCircle} />
                        <Typography variant="h5" color="textPrimary">
                          {I18n.t('components.success')}
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          {I18n.t('cruds.patients.edit.created')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Fade>
              </Box>

              <Box
                className={
                  classes.footer +
                  ' ' +
                  (currentStep < 2
                    ? classes.justifyBetween
                    : classes.justifycenter)
                }
              >
                {currentStep < 2 && (
                  <CrudsBtn
                    text={I18n.t('components.cancel')}
                    variant="text"
                    component={Link}
                    to="/dashboard/patients"
                  />
                )}

                <Box className={classes.actions}>
                  {currentStep > 0 && currentStep < 2 && (
                    <CrudsBtn
                      id="back"
                      text={I18n.t('patient.back')}
                      variant="outlined"
                      click={handleBack}
                    />
                  )}
                  <CrudsBtn
                    size="large"
                    text={
                      currentStep < 2
                        ? I18n.t('patient.next')
                        : I18n.t('cruds.patients.return')
                    }
                    click={
                      currentStep < 2
                        ? handleNext
                        : () => history.push('/dashboard/patients')
                    }
                    variant="contained"
                    disabled={
                      !disabled && !disableEmail ? false : true
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Fade>
        )}
      </Box>
    </Box>
  )
}

export default EditPatient
