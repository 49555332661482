import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    borderRadius: '4px',
    display: 'flex',
    padding: '16px 16px 16px 0',
    boxSizing: 'border-box',
    rowGap: '8px',
    columnGap: '6px',
  },
  sections: {
    '&:first-child': {
      flex: 0.06,
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '4px',
    },
    '&:last-child': {
      flex: 0.94,
      display: 'flex',
    },
  },
  dragIcon: {
    marginTop: '4px',
    marginRight: '4px',
  },
  subSections: {
    flex: 1,
  },
  flex: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexGrow: {
    flexGrow: 1,
  },
  indicator: {
    '&.MuiTypography-root': {
      width: '100%',
      fontSize: '.9em',
      marginBottom: '4px',
      marginTop: '4px',
    }
  },
  divider: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    alignItems: 'center',
    bottom: 4,
  },
  blockInput: {
    width: '100%',
  },
  selectInput: {
    width: '100%',
  },
})

export default useStyles
